export const cyan = {
  cyan12: "#01C9D51F",
  cyan25: "#E0F6F8",
  cyan32: "#01C9D552",
  cyan50: "#C0F9FC",
  cyan75: "#91E8ED",
  cyan100: "#23DEEA",
  cyan200: "#1FD1DD",
  cyan300: "#1AC3CF",
  cyan400: "#16B3BE",
  cyan500: "#0F9CA6",
  cyan600: "#016067",
};

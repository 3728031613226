import styles from "./PingLabel.module.css";
import cx from "classnames";

type PingLabelKind = "OFF_TRACK" | "ON_TRACK" | "AT_RISK" | "LAST_EDITED" | "NO_EFFECT";

interface Props {
  className?: string;
  kind: PingLabelKind;
  label?: string;
}

export const PingLabel = ({ className, kind, label }: Props) => {
  return (
    <div className="d-flex align-items-center">
      <span className={cx(styles.pingLabel, pingLabelKindConfig[kind], className, "fw-700")}>
        <span className={styles.diode} />
        {label ? label : null}
      </span>
    </div>
  );
};

const pingLabelKindConfig: Record<Props["kind"], string> = {
  OFF_TRACK: styles.offTrack,
  ON_TRACK: styles.onTrack,
  AT_RISK: styles.atRisk,
  LAST_EDITED: styles.lastEdited,
  NO_EFFECT: styles["bg-black5"],
};

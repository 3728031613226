import { MutateOptions, UseMutationResult } from "react-query";
import { useConfirmModal } from "./useConfirmModal";

export function withDeleteConfirmation<T, U, W, Z>(
  mutationResult: UseMutationResult<T, U, W, Z>,
  text?: string,
) {
  return () => {
    const confirmModal = useConfirmModal();
    const baseMessage = "Czy na pewno chcesz to usunąć?";

    const customAsyncMutate = (args: W, options?: MutateOptions<T, U, W, Z>) => {
      confirmModal.open({
        text: text || baseMessage,
        confirmText: "Usuń",
        callback: () => mutationResult.mutateAsync(args, options),
      });
    };

    const customMutate = (args: W, options?: MutateOptions<T, U, W, Z>) => {
      confirmModal.open({
        text: text || baseMessage,
        confirmText: "Usuń",
        callback: () => mutationResult.mutate(args, options),
      });
    };

    return {
      ...mutationResult,
      mutate: customMutate,
      mutateAsync: customAsyncMutate,
    };
  };
}

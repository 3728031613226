import { useRef } from "react";
import * as React from "react";
import cx from "classnames";
import styles from "./ProxyInput.module.css";
import { StateProxy, ErrorMessage, Spinner } from "components/utils";
import cuid from "cuid";
import { AsyncUpdateConnectorFieldState } from "utilities";

interface Props<State> {
  value: State;
  onChange: (arg: State) => void;
  placeholder?: string;
  type?: "text" | "number" | "date" | "time";
  className?: string;
  label?: string;
  debounce?: number;
  fieldState: AsyncUpdateConnectorFieldState;
  mode?: "editable" | "common";
  style?: React.CSSProperties;
  inputProxy?: (state: State) => State;
  outputProxy?: (state: State, prevState: State) => State;
  step?: string;
  validate?: (state: State) => string;
  disabled?: boolean;
}

const spinnerStyle: React.CSSProperties = { position: "absolute", right: -16, top: -16 };

export function ProxyInput<State extends string>({
  value,
  onChange,
  placeholder,
  className = "",
  type = "text",
  label,
  debounce = 3000,
  fieldState,
  mode = "common",
  style,
  inputProxy,
  outputProxy,
  step,
  validate,
  disabled = false,
}: Props<State>) {
  const name = useRef(cuid());
  const { error, setError, isBusy, setBusy } = fieldState;
  const editableClassName = mode === "editable" ? styles.editableMode : styles.commonMode;
  const Wrapper = mode === "editable" ? EditableWrapper : InputWrapper;
  const wrapperClassName = `${className} ${editableClassName}`;
  const nameString = typeof fieldState.name === "string" ? fieldState.name : "";
  return (
    <StateProxy
      state={value}
      debounce={debounce}
      onChange={onChange}
      inputProxy={inputProxy}
      outputProxy={outputProxy}
      setError={setError}
      validate={validate}
      setBusy={setBusy}
    >
      {({ spreadProps }) => (
        <>
          <Wrapper className={wrapperClassName} error={error} label={label}>
            <input
              {...spreadProps({ onBlur: fieldState.clearFocused, onFocus: fieldState.setFocused })}
              placeholder={placeholder}
              className={styles.input}
              name={nameString + "-" + name.current}
              type={type}
              autoComplete="off"
              style={style}
              step={step}
              disabled={disabled}
            />
            <Spinner on={isBusy} color="blue" style={spinnerStyle} />
            {error && <ErrorMessage type="text" text={error} />}
          </Wrapper>
        </>
      )}
    </StateProxy>
  );
}

const InputWrapper = ({ children, className, error, label }: any) => (
  <div
    className={cx(className, styles.inputWrapper, {
      "was-validated": Boolean(error),
    })}
  >
    <label className={styles.inputBox}>
      {children}
      {label && <span className={styles.labelFloating}>{label}</span>}
    </label>
  </div>
);

const EditableWrapper = ({ children, className, error, label }: any) => (
  <div className={cx(className, styles.inputBox, { "was-validated": Boolean(error) })}>
    {children}
  </div>
);

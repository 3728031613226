import { ProductValue } from "api/products/models";
import { Checkbox } from "components/utils";

interface Props {
  addAttributeValue: (productId: number, value: number) => void;
  areAllChecked: boolean;
  productId: number;
  toggleAttributeValue: (productId: number, value: number) => void;
  values: ProductValue[];
}

export const PickAllAttributes = ({
  addAttributeValue,
  areAllChecked,
  productId,
  toggleAttributeValue,
  values,
}: Props) => {
  return (
    <Checkbox
      name=""
      label={areAllChecked ? "Odznacz wszystkie wartości" : "Zaznacz wszystkie wartości"}
      checked={areAllChecked}
      onChange={() => {
        if (areAllChecked) {
          values.map(value => toggleAttributeValue(productId, value.id));
        } else {
          values.map(value => addAttributeValue(productId, value.id));
        }
      }}
    />
  );
};

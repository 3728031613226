import { ApiFetcher } from "hooks/createApiQuery";
import { routesKeys } from "./keys";
import { queryFetch } from "apiConnectors/queryFetch";
import { RouteSynchronize } from "./models";

const getRouteSynchronizeDetails = (id: number): ApiFetcher<RouteSynchronize[]> => ({
  key: routesKeys.routeSynchronize(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/synchronize/" + id,
    }),
  globalOptions: {
    enabled: Boolean(id),
  },
});

const postRouteSynchronize = (id: number) =>
  queryFetch<void>({
    method: "POST",
    url: "/routes/synchronize/" + id,
  });

export const routeApi = {
  getRouteSynchronizeDetails,
  postRouteSynchronize,
};

import { Pagination } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { carrierOrdersKeys } from "./keys";
import { CarrierOrder } from "./models";

export const getCarrierOrders = (search: string = ""): ApiFetcher<Pagination<CarrierOrder>> => ({
  key: carrierOrdersKeys.carrierOrders.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/carrier-orders/items",
    }),
});

export const getCarrierOrder = (id: string | number): ApiFetcher<CarrierOrder> => ({
  key: carrierOrdersKeys.carrierOrders.details(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/carrier-orders/items/" + id,
    }),
});

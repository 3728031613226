import logisticsIcon from "assets/images/transport.svg";
import shippingIcon from "assets/images/localShippingLight.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { dashboard } from "./routes/dashboard";
import { routes } from "./routes/routes";
import { route } from "./routes/route";
import { carriers } from "./routes/carriers";
import { carrierOrders } from "./routes/carrierOrders";
import favicon from "assets/images/favicons/fv_logistic.svg";
import { shippingShipments } from "./routes/shippingShipments";
import { shippingCouriers } from "./routes/shippingCouriers";
import { routeLineItems } from "./routes/routeLineItems";

export const logistics: ModuleNavigation = {
  favicon,
  labelBackground: "#8c73ff",
  label: "Logistyka",
  iconBackground: "#352a68",
  icon: shippingIcon,
  menuIcon: logisticsIcon,
  name: "LOGISTICS",
  url: "/logistics",
  navigationSections: [
    {
      navigationList: [
        dashboard,
        routes,
        route,
        carriers,
        carrierOrders,
        shippingShipments,
        shippingCouriers,
        routeLineItems,
      ],
    },
  ],
};

import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import styles from "./RightPanel.module.css";

interface Props {
  internalId: string;
  title: string;
}

export const TitleSection = ({ internalId, title }: Props) => {
  return (
    <RightPanelSection>
      <div className="d-flex align-items-center gap-2">
        <div className={styles.titleSignature}>{title}</div>
      </div>
      <InfoLabel title="kod paczki">
        <div className="body-14 fw-700">
          {internalId && internalId.length > 0 ? internalId : "---"}
        </div>
      </InfoLabel>
    </RightPanelSection>
  );
};

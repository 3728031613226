import { VisiblePreviousStage } from "api/manufacturingNew/models";
import { Button } from "components/common";
import binIcon from "assets/images/trashBin.svg";
import { CustomSwitch } from "components/utils/customSwitch";
import ReactTooltip from "react-tooltip";
import styles from "../../ManufacturingStagesList.module.css";
import cx from "classnames";
import { useRemovePreviousStage } from "../../hooks/useRemovePreviousStage";
import { UUID } from "api/types";
import { useTogglePreviousStageVisibility } from "../../hooks/useTogglePreviousStageVisibility";

interface Props {
  manufacturingStageId: UUID;
  previousStage: VisiblePreviousStage;
}

export const PreviousStage = ({ manufacturingStageId, previousStage }: Props) => {
  const removeStageMutation = useRemovePreviousStage();
  const toggleStageVisibilityMutation = useTogglePreviousStageVisibility();

  return (
    <div className="d-flex align-items-center gap-2 pb-2">
      <div className="col-3 px-0">
        <div className="position-relative">
          <div className="d-flex align-items-center">
            <CustomSwitch
              checked={previousStage.isVisible}
              disabled={toggleStageVisibilityMutation.isLoading}
              label={previousStage.stage.name}
              name="isVisible"
              onChange={() =>
                toggleStageVisibilityMutation.mutate({
                  toUpdate: {
                    isVisible: !previousStage.isVisible,
                  },
                  stage: previousStage.id,
                  manufacturingStageId,
                })
              }
            />
          </div>
        </div>
      </div>
      <div>
        <Button
          kind="transparent-black"
          onClick={() =>
            removeStageMutation.mutate({
              manufacturingStageId,
              stage: previousStage.id,
            })
          }
          size="square-s"
        >
          <div
            className="btnBase btnBaseSmall btnBase16"
            data-tip
            data-for={`remove-previous-stage-${previousStage.id}`}
          >
            <img alt="Usuń" src={binIcon} />
          </div>
        </Button>
        <ReactTooltip
          className={cx(styles.customTooltipSize, "customTooltip")}
          id={`remove-previous-stage-${previousStage.id}`}
          place="top"
          effect="solid"
          arrowColor="transparent"
          offset={{ top: -10 }}
        >
          Usuń etap
        </ReactTooltip>
      </div>
    </div>
  );
};

import styles from "../../EditTradingDocument.module.css";
import cx from "classnames";
import { TradingDocument } from "api/trading-documents/models";
import { useState } from "react";
import { Select } from "components/utils";
import { TradingDocumentItem } from "./TradingDocumentItem";

interface Props {
  tradingDocument: TradingDocument;
}

export const OrdersSection = ({ tradingDocument }: Props) => {
  const [selectedOrder, setSelectedOrder] = useState(
    tradingDocument.items[0].order ? tradingDocument.items[0].order?.id : 0,
  );

  return (
    <div className={cx(styles.ordersSection, "m-0 pl-0 pr-0 pb-0 w-100")}>
      <div className="w-50">
        <Select
          items={tradingDocument.items.map(item => {
            if (item.order) {
              return {
                id: item.order!.id,
                name: item.order!.signature,
              };
            }
            return {
              id: 0,
              name: "Pozostałe",
            };
          })}
          onChange={value => {
            value && setSelectedOrder(value.id);
          }}
          selectedItem={selectedOrder ?? null}
          width="full"
        />
      </div>
      <div className={cx(styles.itemsTable, "container m-0 pb-0 w-100")}>
        <div className={cx(styles.itemsTableHeader, "row text-color-grey fs-12 border-bottom")}>
          <div className="col-1">Lp.</div>
          <div className="col-4 d-flex">Nazwa|cechy</div>
          <div className="col-2 pl-0">Numer</div>
          <div className="col-1 d-flex justify-content-center">Liczba</div>
          <div className="col-2 pl-2">Cena netto</div>
          <div className="col-1 pl-2">VAT</div>
          <div className="col-1 pl-1">Cena brutto</div>
        </div>
        <div className={cx(styles.itemsRow, "row")}>
          {tradingDocument.items.map(item => {
            if ((item.order && item.order.id === selectedOrder) || !item.order) {
              return item.tradingDocumentItems.map((item, index) => (
                <TradingDocumentItem counter={index} item={item} key={index} />
              ));
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

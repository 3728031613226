import { Modal, Spinner, StatusHandler, Switch } from "components/utils";
import { Product } from "api/products/models";
import { useState } from "react";
import { Button } from "components/common";
import { ProductForm } from "./ProductForm";
import { isFabric } from "typePredicates";
import cx from "classnames";
import filterIcon from "assets/images/38.svg";
import editIcon from "assets/images/1.svg";
import binIcon from "assets/images/81.svg";
import styles from "./ProductFilterModal.module.css";
import { useHistory } from "react-router";
import { useQuery } from "hooks";
import { queryString } from "utilities";
import { useProductAttributesFilter } from "hooks/apiPrimitives";

interface Props {
  close: () => void;
}

export type PickedProducts = Record<
  number,
  { selectedValues: number[]; isProductExcluded: boolean; product: Product }
>;

export interface CombinedProductFilter {
  operator: "AND" | "OR";
  query: ProductFilter[];
}

interface ProductFilter {
  product: number;
  values: number[];
  exclude: boolean;
}

export const ProductFilterModal = ({ close }: Props) => {
  const [product, setProduct] = useState<Product | null>(null);
  const [operatorType, setOperatorType] = useState<CombinedProductFilter["operator"]>("OR");
  const [pickedProducts, setPickedProducts] = useState<PickedProducts>({});
  const [editMode, setEditMode] = useState(false);
  const history = useHistory();
  const { query, setQuery } = useQuery();
  const productsIds =
    query?.productsWithValues &&
    JSON.parse(query.productsWithValues)
      .query.map((el: ProductFilter) => el.product)
      .join(",");
  const { isLoading } = useProductAttributesFilter(`?productsIds=${productsIds}&pageSize=999`, {
    enabled: Boolean(query?.productsWithValues),
    // TODO: Check paginated list types (useQuery)
    // @ts-ignore
    onSuccess: (payload: Product[]) => {
      const operator: CombinedProductFilter["operator"] = query?.productsWithValues
        ? JSON.parse(query?.productsWithValues).operator
        : "OR";
      const pickedProducts: PickedProducts = JSON.parse(query?.productsWithValues).query.reduce(
        // @ts-ignore
        (acc, el) => {
          const product = payload.find(prod => prod.id === el.product);
          Object.assign(acc, {
            ...acc,
            [el.product]: {
              selectedValues: el.values,
              isProductExcluded: el.exclude,
              product: product,
            },
          });
          return acc;
        },
        {},
      );
      setOperatorType(operator);
      setPickedProducts(pickedProducts);
    },
  });

  const prepareQuery = () => {
    const query = Object.entries(pickedProducts).map(([productId, values]) => ({
      product: parseInt(productId),
      values: values.selectedValues,
      exclude: values.isProductExcluded,
    }));
    const json = {
      operator: operatorType,
      query: query,
    };

    return { productsWithValues: JSON.stringify(json) };
  };

  const clearFilters = () => {
    setQuery({});
    setPickedProducts({});
  };

  const addAttributeValue = (productId: number, value: number) => {
    setPickedProducts(prevProducts => {
      const prevProduct = prevProducts[productId];
      const prevList = prevProduct.selectedValues || [];
      const valueIndex = prevList?.findIndex(el => el === value);

      if (valueIndex > -1) {
        return prevProducts;
      }

      const newList = [...prevList, value];
      return { ...prevProducts, [productId]: { ...prevProduct, selectedValues: newList } };
    });
  };

  function deleteProduct(key: number): void {
    if (product?.id === key) {
      setProduct(null);
    }
    setPickedProducts(prevState => {
      const { [key]: deletedProduct, ...restOfProducts } = prevState;
      return restOfProducts;
    });
  }

  const addProduct = (product: Product) => {
    setPickedProducts(prevProduct => ({
      ...prevProduct,
      [product.id]: { selectedValues: [], isProductExcluded: false, product: product },
    }));
  };

  const toggleIsProductExcluded = (productId: number) => {
    setPickedProducts(prevProducts => {
      const prevProduct = prevProducts[productId];
      return {
        ...prevProducts,
        [productId]: { ...prevProduct, isProductExcluded: !prevProduct.isProductExcluded },
      };
    });
  };

  const toggleAttributeValue = (productId: number, value: number) => {
    setPickedProducts(prevProducts => {
      const prevProduct = prevProducts[productId];
      const prevList = prevProduct.selectedValues || [];
      const valueIndex = prevList?.findIndex(el => el === value);

      let newList = [];
      // when value is a part of pickedProduct's selectedValues - remove it
      // if it is not - add it
      if (valueIndex > -1) {
        newList = [...prevList.slice(0, valueIndex), ...prevList.slice(valueIndex + 1)];
      } else {
        newList = [...prevList, value];
      }
      return { ...prevProducts, [productId]: { ...prevProduct, selectedValues: newList } };
    });
  };

  const editProduct = (product: Product) => {
    setEditMode(true);
    setProduct(product);
  };

  const renderProductAttributes = () => {
    return (
      <>
        {Object.keys(pickedProducts).map(productId => {
          const { product, selectedValues, isProductExcluded } = pickedProducts[
            parseInt(productId)
          ];

          return (
            <div key={productId} className={`mb-3 ${styles.renderedProducts}`}>
              <div>
                <div className="d-flex align-items-center">
                  <strong className="fs-16 mr-2">{product.name}</strong>
                  <span className="fs-12 text-muted">
                    {isProductExcluded ? "(Produkt wykluczony)" : "(Zawiera produkt)"}
                  </span>
                </div>
                <div className={styles.attributesContainer}>
                  {product.attributes.map(attribute => {
                    if (isFabric(attribute)) {
                      return attribute.categories
                        .filter(category => category.values.some(val => val.isAssignableToIndex))
                        .map(category => (
                          <div key={category.name}>
                            {category.values.filter(value => selectedValues.includes(value.id))
                              .length > 0 ? (
                              category.values
                                .filter(value => selectedValues.includes(value.id))
                                .map(value => (
                                  <div className={styles.attribute} key={value.id}>
                                    <span>{attribute.name}: </span>
                                    <strong>{value.name}</strong>
                                  </div>
                                ))
                            ) : (
                              <div className={styles.attribute} key={attribute.id}>
                                <span>{attribute.name}: </span>
                                <strong>Nie wybrano</strong>
                              </div>
                            )}
                          </div>
                        ));
                    }

                    return (
                      <div className={`d-flex ${styles.selectedProductsList}`} key={attribute.name}>
                        {attribute.values.filter(value => selectedValues.includes(value.id))
                          .length > 0 ? (
                          attribute.values
                            .filter(value => selectedValues.includes(value.id))
                            .map(value => (
                              <div className={styles.attribute} key={value.id}>
                                <span>{attribute.name}: </span>
                                <strong>{value.name}</strong>
                              </div>
                            ))
                        ) : (
                          <div className={styles.attribute} key={attribute.id}>
                            <span>{attribute.name}: </span>
                            <strong>Nie wybrano</strong>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <Button
                  kind="secondary"
                  size="round-s"
                  title="Usuń product"
                  onClick={() => deleteProduct(product.id)}
                >
                  <img alt="" src={binIcon} />
                </Button>
                <Button
                  kind="secondary"
                  size="round-s"
                  title="Edytuj product"
                  onClick={() => editProduct(product)}
                >
                  <img alt="" src={editIcon} />
                </Button>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <Modal isOpen={true} close={close} overrides={{ container: { className: styles.modal } }}>
      <div>
        <div className={cx("mt-2 mb-4", styles.title)}>Filtruj po produktach / indeksach</div>
        <div className="mb-3 d-flex justify-content-between">
          <div>
            <Switch
              name="operatorType"
              checked={operatorType === "OR"}
              label={operatorType === "OR" ? "Alternatywa (lub)" : "Koniunkcja (i)"}
              onChange={() => {
                if (operatorType === "OR") {
                  setOperatorType("AND");
                } else if (operatorType === "AND") {
                  setOperatorType("OR");
                }
              }}
            />
          </div>
          <div>
            <Button kind="secondary-stroke" size="small" onClick={clearFilters}>
              Wyczyść filtry
            </Button>
          </div>
        </div>
        {Object.keys(pickedProducts).length === 0 && isLoading && (
          <div className="d-flex align-items-center justify-content-center">
            <Spinner on={true} color="blue" size="small" />
          </div>
        )}
        {pickedProducts && renderProductAttributes()}

        <ProductForm
          addAttributeValue={addAttributeValue}
          addProduct={addProduct}
          editMode={editMode}
          product={product}
          setEditMode={setEditMode}
          setProduct={setProduct}
          toggleAttributeValue={toggleAttributeValue}
          toggleIsProductExcluded={toggleIsProductExcluded}
          pickedProducts={pickedProducts}
        />

        {Object.keys(pickedProducts).length > 0 && (
          <div className="d-flex justify-content-start align-items-center">
            <StatusHandler>
              {helpers => (
                <Button
                  kind="primary"
                  size="small"
                  disabled={helpers.isFetching}
                  onClick={() => {
                    history.replace({
                      search: queryString.stringify({ ...query, ...prepareQuery() }),
                    });
                    close();
                  }}
                  className={styles.filtersBtn}
                >
                  <img src={filterIcon} alt="" className="mr-1" />
                  Filtruj
                </Button>
              )}
            </StatusHandler>
          </div>
        )}
      </div>
    </Modal>
  );
};

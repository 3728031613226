import { Product } from "api/products/models";
import { useEffect, useMemo } from "react";
import styles from "./AddIndexToUnloadingModal.module.css";
import { cx } from "utilities";
import productPlaceholderPhoto from "assets/images/53.svg";
import { AddLineItemToUnloadingPayload } from "api/wms/models";
import cuid from "cuid";
import { AttributeSection } from "pages/orders/shared/rightPanel/productsSection/productForm/attributeSection/AttributeSection";
import { OrderProductInstance } from "api/orders/models";
import { findPreselectedValues } from "pages/orders/shared/rightPanel/productsSection/productForm/utils/findPreselectedValues";
import { getProductIndexBasedOnAttributesState } from "pages/orders/shared/rightPanel/productsSection/productForm/utils/getProductIndexBasedOnAttributesState";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  changeAttribute: (attributeId: number, value: number | null, cuid: string) => void;
  orderProduct: OrderProductInstance | undefined;
  product: Product | null;
  setFieldValue: (
    name: keyof AddLineItemToUnloadingPayload["productElements"][number],
    value: any,
  ) => void;
  values: AddLineItemToUnloadingPayload["productElements"][number];
}

export const SingleProductForm = ({
  changeAttribute,
  orderProduct,
  product,
  setFieldValue,
  values,
}: Props) => {
  const preselectedAttributes = findPreselectedValues(orderProduct);

  useEffect(() => {
    const index = getProductIndexBasedOnAttributesState(product, values.attributesState);
    setFieldValue("index", index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, product]);

  const attributesToDisplay = useMemo(
    () => product?.attributes.map(attribute => ({ ...attribute, cuid: cuid() })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div className={styles.section}>
      <div className={cx(styles.miniature, "mt-2 mb-2 d-flex align-items-center")}>
        <img src={product?.picture || productPlaceholderPhoto} alt="" />
        <div className="ml-1">
          <Typography fontSize="16" fontWeight="700">
            {values.name}
          </Typography>
        </div>
      </div>
      <AttributeSection
        productIndexes={product?.indexes || null}
        attributesState={values.attributesState}
        attributesToDisplay={attributesToDisplay || []}
        preselectedAttributes={preselectedAttributes}
        changeAttribute={(attributeId, value) =>
          product && changeAttribute(attributeId, value, values.cuid)
        }
      />
    </div>
  );
};

import { useMutation } from "hooks/useMutation";
import { proformasApi } from "./api";
import { getAnyErrorKey } from "utilities";
import { tradingDocumentsKeys } from "../keys";

const useCreateProforma = () => {
  return useMutation(proformasApi.postProformaForOrder, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(tradingDocumentsKeys.lightTradingDocument.list());
      toastr.open({
        type: "success",
        title: "Udało się",
        text: "Utworzono proformę",
      });
    },
    onError: error =>
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      }),
  }));
};

const useCreateSalesInvoiceFromProforma = () => {
  return useMutation(proformasApi.createSalesInvoiceFromProforma, ({ toastr }) => ({
    onSuccess: () =>
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono fakturę sprzedażową na podstawie proformy",
      }),
    onError: error =>
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      }),
  }));
};

export const proformasActions = {
  useCreateProforma,
  useCreateSalesInvoiceFromProforma,
};

import styles from "./FilterDropdown.module.css";
import cx from "classnames";
import { useAsyncModalController, useQuery, useToggle } from "hooks";
import { ClickOutsideHandler, DisabledOpacity } from "components/utils";
import { clickOutsideIgnoreClass } from "../constants";
import { ToolbarDateAsyncModal } from "components/common/ToolbarDateAsyncModal";
import { dateFns } from "utilities";

interface Props {
  label: string;
  name: [string, string];
  options: {
    value: [string, string];
    label: string;
  }[];
  defaultValue: [string, string];
  disabled?: boolean;
}

const modalId = Symbol("modal");

export function FilterDropdown({ options, name, label, defaultValue, disabled = false }: Props) {
  const { isOpen, close, toggle } = useToggle(false);
  const { query, updateQuery } = useQuery();
  const asyncModal = useAsyncModalController(modalId);
  const selectedOption = options.find(
    option => option.value[0] === query[name[0]] && option.value[1] === query[name[1]],
  );

  const defaultOption = options.find(
    option => option.value[0] === defaultValue[0] && option.value[1] === defaultValue[1],
  );

  const displayLabel = (() => {
    const areBothSelected = query[name[0]] && query[name[1]];

    return (
      selectedOption?.label ||
      (areBothSelected && formatLabel([query[name[0]], query[name[1]]])) ||
      defaultOption?.label
    );
  })();

  return (
    <ClickOutsideHandler onClickOutside={close} outsideClickIgnoreClass={clickOutsideIgnoreClass}>
      <div className={cx(" mr-1", styles.filter)}>
        <DisabledOpacity
          disabled={disabled}
          title="Ta funkcjonalność jest w przygotowaniu"
          className="d-flex align-items-center"
        >
          <button
            type="button"
            className="d-flex align-items-center"
            aria-haspopup="menu"
            onClick={toggle}
          >
            <strong className={cx("mr-1", styles.filterLabel)}>{label}:</strong>
            <strong className={styles.selected}>{displayLabel}</strong>
          </button>
        </DisabledOpacity>
        <div
          className={styles.optionsList}
          role="menu"
          style={{ display: isOpen ? "block" : "none" }}
        >
          {options.map(option => (
            <div
              key={option.label}
              className={styles.optionsItem}
              role="menuitem"
              onClick={async () => {
                updateQuery({ [name[0]]: option.value[0], [name[1]]: option.value[1] });
                close();
              }}
            >
              <strong>{option.label}</strong>
            </div>
          ))}
          <div
            className={styles.optionsItem}
            role="menuitem"
            onClick={async () => {
              const value = await asyncModal.open();
              updateQuery({ [name[0]]: value[0], [name[1]]: value[1] });
              close();
            }}
          >
            <strong>Własna data</strong>
          </div>
        </div>
        <ToolbarDateAsyncModal id={modalId} />
      </div>
    </ClickOutsideHandler>
  );
}

function formatLabel(values: [string, string]) {
  function format(date: string) {
    if (!date) return "";
    try {
      return dateFns.format(new Date(date), "dd.MM.yyyy");
    } catch (err) {
      return "???";
    }
  }
  const from = format(values[0]);
  const to = format(values[1]);
  return `${from ? "od" : ""} ${from} ${to ? "do" : ""} ${to}`;
}

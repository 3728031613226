import * as React from "react";
import {
  ErrorGate,
  Initializer,
  ConfirmModalController,
  ToastrController,
  AsyncModalController,
} from "components/common";
import { persistor, store } from "store/storeConfig";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { RoutesRoot } from "routes";
import { AlertModalController } from "components/utils/AlertModalController";
import { LoadScript } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "CONSTANTS";
import { QueryProvider } from "components/utils/queryProvider";
import { DownloadFeedbackController } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false, staleTime: 3000 } },
});

const App: React.FC = () => {
  return (
    <div>
      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>
                <ErrorGate>
                  <QueryProvider>
                    <ConfirmModalController>
                      <AlertModalController>
                        <ToastrController>
                          <DownloadFeedbackController>
                            <AsyncModalController>
                              <Initializer />
                              <RoutesRoot />
                            </AsyncModalController>
                          </DownloadFeedbackController>
                        </ToastrController>
                      </AlertModalController>
                    </ConfirmModalController>
                  </QueryProvider>
                </ErrorGate>
              </BrowserRouter>
            </PersistGate>
            {process.env.NODE_ENV === "development" && (
              <ReactQueryDevtools
                initialIsOpen={false}
                toggleButtonProps={{ style: { width: "20px", height: "20px" } }}
              />
            )}
          </QueryClientProvider>
        </Provider>
      </LoadScript>
    </div>
  );
};

// expose store when run in Cypress
//@ts-ignore
if (window.Cypress) {
  //@ts-ignore
  window.store = store;
}
export default App;

import { useQuery } from "hooks";
import { FilterAsyncSearchType } from "../../types";
import { Autocomplete, AutocompleteAsyncHandler } from "components/utils";
import styles from "./FilterAsyncSearch.module.css";
import { ApiFetcher } from "hooks/createApiQuery";
import { Pagination } from "api/types";
import { ApiMiddlewareResult } from "apiConnectors/fetchConnector";
import { useEffect, useState } from "react";
import { queryString } from "utilities";

export const FilterAsyncSearch = <
  Item,
  FetchFrom extends (
    arg: string,
    abortToken?: string,
  ) => ApiFetcher<Pagination<Item>> | ApiMiddlewareResult<Pagination<Item>>,
  T extends string
>({
  fetchFrom,
  multiple = true,
  name,
  placeholder,
  value,
  display,
}: FilterAsyncSearchType<FetchFrom, T>) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const [hasBeenPicked, setHasBeenPicked] = useState(false);
  const [shouldBeEmpty, setShouldBeEmpty] = useState(false);

  useEffect(() => {
    if (
      hasBeenPicked &&
      (!Boolean(queryString.stringify(query)?.length) || !Boolean(query[name]?.length))
    ) {
      setShouldBeEmpty(true);
    }
  }, [hasBeenPicked, query, name]);

  return (
    <div>
      <AutocompleteAsyncHandler
        fetchFrom={fetchFrom}
        transform={(item: any) => ({
          ...item,
          name: item[display],
        })}
      >
        <Autocomplete
          multiple={multiple}
          onChange={(items: any) => {
            if (Boolean(items.length)) {
              updateQuery({
                [name]: items.map((_item: any) => _item[value]).join(","),
                page: 1,
              });
              setHasBeenPicked(true);
              setShouldBeEmpty(false);
              return;
            }
            return updateQuery({ [name]: "", page: 1 });
          }}
          placeholder={placeholder ?? "Szukaj"}
          overrides={{
            input: { className: styles.input },
            searchBar: { className: styles.searchBar },
            selectedItem: { className: styles.selectedItem },
          }}
          selectedItems={shouldBeEmpty ? [] : undefined}
        />
      </AutocompleteAsyncHandler>
    </div>
  );
};

/**
 *
 * @param obj
 * @param keys
 * @example
 * // {something: 'boo'}
 * const omitted = omit({name: 'test', address: 'foo', something: 'boo'}, 'name', 'address')
 */
export function omit<T, K extends keyof T>(obj: T, key: K | K[]): Omit<T, K> {
  const newObj = { ...obj };
  const fieldsToDelete = Array.isArray(key) ? key : [key];
  fieldsToDelete.forEach(field => {
    delete newObj[field];
  });
  return newObj;
}

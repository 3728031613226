import { Order } from "api/orders/models";

export const getOrderRedirectUrl = (
  order: Pick<Order, "signature" | "type" | "isSettled" | "isCanceled" | "id">,
) => {
  const tab = (() => {
    if (order.isCanceled) {
      return "canceled";
    }
    if (order.isSettled) {
      return "archive";
    }
    if (order.type === "STANDARD") {
      return "active";
    } else {
      return "complaint";
    }
  })();
  return `/orders/list/${tab}/all?panelId=${order.id}&search=${order.signature}`;
};

import { useRef } from "react";
import prevIcon from "assets/images/27.svg";
import nextIcon from "assets/images/28.svg";
import styles from "./Gallery.module.css";
import { Button } from "..";

interface Props {
  children: any;
  width?: number;
}

export const Gallery = ({ children, width }: Props) => {
  const scrollContainer = useRef<HTMLDivElement | null>(null);

  function handleScrollLeft() {
    scrollContainer.current!.scrollBy({ left: -219, top: 0, behavior: "smooth" });
  }

  function handleScrollRight() {
    scrollContainer.current!.scrollBy({ left: 219, top: 0, behavior: "smooth" });
  }

  return (
    <div className={styles.galleryContainer} style={{ maxWidth: width, width }}>
      <Button kind="secondary" size="round-s" onClick={handleScrollLeft} className={styles.prevBtn}>
        <img src={prevIcon} alt="" />
      </Button>
      <div className={styles.gallery} ref={scrollContainer}>
        {children}
      </div>
      <Button
        kind="secondary"
        size="round-s"
        onClick={handleScrollRight}
        className={styles.nextBtn}
      >
        <img src={nextIcon} alt="" />
      </Button>
    </div>
  );
};

import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import trolley from "assets/images/trolley.svg";
import { Dashboard } from "routes/manufacturingNew/Dashboard";

export const deliveryOrders: ModuleLink = {
  url: "delivery-orders",
  label: "Zlecenia do dostawców",
  icon: trolley,
  subSection: <></>,
  routing: Dashboard,
  isAccessible: false,
};

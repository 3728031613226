import timeIcon from "assets/images/46.svg";
import styles from "../InfoWindowContent.module.css";
import cx from "classnames";
import { PinToRoute } from "api/routes/models";
import { dateFns } from "utilities";

interface Props {
  pinnedLog: PinToRoute;
}

export const PinnedOrderInfo = ({ pinnedLog }: Props) => {
  return (
    <div className="d-flex flex-wrap m-0 row border-top pt-2 pb-2">
      <div className={cx(styles["map-cloud_data-group"], "d-flex align-items-center col-12")}>
        <div className={cx(styles["map-cloud_group-label"])}>
          <img src={timeIcon} alt="Data przypięcia" />
          <span>dodano do trasy</span>
        </div>
        <div className={cx(styles["map-cloud_group-data"])}>
          {pinnedLog.actionAt ? (
            <div className="d-flex align-items-center gap-2">
              {dateFns.format(new Date(pinnedLog.actionAt), "dd.MM.yyyy, HH:mm").split(",")[0]}
              <div className="text-color-grey">
                {dateFns.format(new Date(pinnedLog.actionAt), "dd.MM.yyyy, HH:mm").split(",")[1]}
              </div>
            </div>
          ) : (
            <span className={styles.noComment}>--:--</span>
          )}
        </div>
      </div>
    </div>
  );
};

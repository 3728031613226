import { PageHeader } from "components/common";
import { getTabs } from "pages/tradingDocuments/shared/utils/getTabs";
import { salesInvoices } from "components/common/moduleNavigation/moduleConfig/finances/routes/salesInvoices";
import { BottomBarOptions } from "./BottomBarOptions";
import { useQuery, useToggle } from "hooks";
import { CurrentlyPrintedReceiptsModal } from "./currentlyPrintedReceiptsModal/CurrentlyPrintedReceiptsModal";
import { LastPrintedReceiptsModal } from "./lastPrintedReceiptsModal/LastPrintedReceiptsModal";
import { AmountSummaryModal } from "./amountSummaryModal/AmountSummaryModal";
import { useFinancesAmountSummary } from "api/trading-documents/hooks";
import { Tab } from "api/trading-documents/models";
import { getSearch } from "pages/tradingDocuments/shared/utils/getSearch";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiUploadFile } from "components/miloDesignSystem/atoms/icons/MdiUploadFile";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { MdiDownloadFile } from "components/miloDesignSystem/atoms/icons/MdiDownloadFile";
import { MdiVisibilityOff } from "components/miloDesignSystem/atoms/icons/MdiVisibilityOff";
import { MdiAreaChart } from "components/miloDesignSystem/atoms/icons/MdiAreaChart";
import { FinancialReportModal } from "./financialReportModal/FinancialReportModal";

interface Props {
  additionalListParams: {
    [x: string]: string;
  };
  tab: Tab;
}

export const ListHeader = ({ additionalListParams, tab }: Props) => {
  const { query } = useQuery({ exclude: ["panelId", "page"] });
  const type = "INVOICE";
  const invoiceType = "SALES";
  const currentlyPrintedReceiptsModal = useToggle();
  const lastPrintedReceiptsModal = useToggle();
  const amountSummaryModal = useToggle();
  const search = getSearch({ query, type, invoiceType, tab });
  const financialReportModal = useToggle();

  const { data: amountSummary, isLoading } = useFinancesAmountSummary(search);

  return (
    <div>
      <PageHeader
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <IconButton icon={MdiUploadFile} variant="transparent" />
            <Tooltip title="Pobierz zestawienie dokumentów handlowych">
              <IconButton
                icon={MdiDownloadFile}
                onClick={financialReportModal.open}
                variant="transparent"
              />
            </Tooltip>
            <span className="line-divider lineDividerMargin" />
            <IconButton icon={MdiVisibilityOff} variant="transparent" />
            <IconButton icon={MdiAreaChart} variant="transparent" />
          </div>
        }
        additionalListParams={additionalListParams}
        bottomBarButtons={
          <BottomBarOptions
            amountSummary={amountSummary}
            amountSummaryModal={amountSummaryModal}
            currentlyPrintedReceiptsModal={currentlyPrintedReceiptsModal}
            isLoading={isLoading}
            lastPrintedReceiptsModal={lastPrintedReceiptsModal}
          />
        }
        searchInput={{
          label: "Szukaj wśród faktur sprzedażowych",
          tags: [
            {
              name: "issueDateFrom",
              label: "data wystawienia (od)",
              value: "2023-08-01",
            },
            {
              name: "issueDateTo",
              label: "data wystawienia (do)",
              value: "2023-08-04",
            },
          ],
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `finances/${salesInvoices.url}`,
          urlSpan: "list",
        }}
        viewLabel="SALES_INVOICES"
      />
      {currentlyPrintedReceiptsModal.isOpen && (
        <CurrentlyPrintedReceiptsModal close={currentlyPrintedReceiptsModal.close} />
      )}
      {lastPrintedReceiptsModal.isOpen && (
        <LastPrintedReceiptsModal close={lastPrintedReceiptsModal.close} />
      )}
      {amountSummaryModal.isOpen && amountSummary && (
        <AmountSummaryModal amountSummary={amountSummary} close={amountSummaryModal.close} />
      )}
      {financialReportModal.isOpen && <FinancialReportModal close={financialReportModal.close} />}
    </div>
  );
};

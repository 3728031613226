import { RightPanelSection } from "components/utils/drawer";
import styles from "../../WarehouseFinancialState.module.css";
import cx from "classnames";
import { SummaryPerInPackage, SummaryPerOutPackage } from "api/trading-documents/models";
import { SummaryPerIn } from "../../components/packagesInList/rightPanel/components/SummaryPerIn";
import { SummaryPerOut } from "../../components/packagesOutList/rightPanel/components/SummaryPerOut";

interface Props {
  summary: SummaryPerInPackage | SummaryPerOutPackage;
  title: "Przyjęcia" | "Wydania";
}

export const WhEntriesSection = ({ summary, title }: Props) => {
  return (
    <RightPanelSection title={title}>
      <div className="pb-3">
        <div className={cx(styles.tableHeader, styles.tableHeaderWarehouseAction)}>
          <div>#</div>
          <div>{title.charAt(0).toLowerCase() + title.slice(1, -1) + "e"}</div>
          <div className="d-flex align-items-center justify-content-end textAlignRight">cena</div>
          <div>liczba</div>
          <div>data</div>
        </div>
        {title === "Przyjęcia" ? (
          <SummaryPerIn summary={summary as SummaryPerInPackage} />
        ) : (
          <SummaryPerOut summary={summary as SummaryPerOutPackage} />
        )}
      </div>
    </RightPanelSection>
  );
};

import { Button } from "components/miloDesignSystem/atoms/button";
import styles from "./GenericTable.module.css";
import { RowBy } from "./useGenericTableFilters";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { SelectProps } from "components/miloDesignSystem/molecules/select/types";

interface FooterProps {
  rowBy: RowBy;
  setDateFrom: () => void;
}

interface RowBySelectProps {
  rowBy: RowBy;
  size?: "small" | "medium";
  setRowBy: (value: RowBy) => void;
}

enum RowByNormalized {
  DAY = "Dzień",
  WEEK = "Tydzień",
  MONTH = "Miesiąc",
}

const rowByToSelect: SelectProps["items"] = [
  { value: "DAY", text: RowByNormalized.DAY, type: MenuItemType.TEXT },
  { value: "WEEK", text: RowByNormalized.WEEK, type: MenuItemType.TEXT },
  { value: "MONTH", text: RowByNormalized.MONTH, type: MenuItemType.TEXT },
];

export const GenericTable = () => {
  return <div></div>;
};

GenericTable.RowBySelect = ({ rowBy, setRowBy }: RowBySelectProps) => {
  return (
    <Select
      items={rowByToSelect}
      className={styles.dropdownList}
      label="Grupuj po"
      onChange={id => {
        if (id) setRowBy(id as RowBy);
      }}
      selected={rowBy}
    />
  );
};

GenericTable.Footer = ({ rowBy, setDateFrom }: FooterProps) => {
  return (
    <div className={styles.btnContainer}>
      <Button
        className="text-uppercase"
        onClick={setDateFrom}
        startIcon={MdiAdd}
        size="small"
        variant="gray"
      >
        {rowBy === "MONTH"
          ? "Dodaj kolejny miesiąc"
          : rowBy === "WEEK"
          ? "Dodaj kolejny tydzień"
          : "Dodaj kolejny dzień"}
      </Button>
    </div>
  );
};

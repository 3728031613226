import { rejectOrderByMiloUser } from "api/orders/calls";
import { RouteMessages } from "api/routes/models";
import redCrossIcon from "assets/images/11r.svg";
import cx from "classnames";
import { Button } from "components/common/button";
import { useQuery } from "hooks";
import { useRouteSmsMessages } from "hooks/apiPrimitives";
import { useMutation } from "hooks/useMutation";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "./ConversationModal.module.css";

interface Props {
  order: RouteMessages["orders"][number];
}

export const RejectOrder = ({ order }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { handleMutate } = useRouteSmsMessages(panelId);

  const rejectOrderMutation = useMutation(rejectOrderByMiloUser, {
    onMutate: () => {
      return handleMutate(draft => {
        const orderToUpdate = draft.orders.find(el => el.id === order.id);
        assertIsDefined(orderToUpdate);
        orderToUpdate.isDeliveryDateRejected = true;
      });
    },
    onError: (error, _, rollback) => {
      //@ts-ignore
      rollback(error);
    },
  });

  return (
    <div className={cx(styles.statusContainer, styles.errorMsg)}>
      <img src={redCrossIcon} alt="Odrzuć" />
      {!order.isDeliveryDateRejected && (
        <Button className={styles.errorBtn} onClick={() => rejectOrderMutation.mutate(order.id)}>
          Odrzuć
        </Button>
      )}
      {order.isDeliveryDateRejected && <div className={styles.rejectedMsg}>Odrzucono</div>}
    </div>
  );
};

type Attribute = {
  id: string | number;
  name: string;
  value: string;
};

export const useTrimmedAttributes = (counter: number) => {
  const getVisibleAttributes = (attributes: Attribute[]): Attribute[] => {
    return attributes.slice(0, counter);
  };
  const getTrimmedAttributes = (attributes: Attribute[]): Attribute[] => {
    return attributes.slice(counter, attributes.length);
  };

  return { getVisibleAttributes, getTrimmedAttributes };
};

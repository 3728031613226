import { ManufacturingStage } from "api/manufacturingNew/models";
import { RightPanelSection } from "components/utils/drawer";
import { AdvancedSelect } from "components/common/advancedSelect/AdvancedSelect";
import { patchManufacturingStage } from "api/manufacturingNew/calls";
import { useMutation } from "hooks/useMutation";
import { useManufacturingStage } from "api/manufacturingNew/hooks";
import { useSelector } from "hooks";

interface Props {
  manufacturingStage: ManufacturingStage;
}

export const StageColumnSets = ({ manufacturingStage }: Props) => {
  const { handleMutate } = useManufacturingStage(manufacturingStage.id, {
    enabled: false,
  });

  const stageColumnSets = useSelector(state => state.partials.stageColumnSets);
  const patchMutation = useMutation(patchManufacturingStage, {
    onMutate: toUpdate => {
      return handleMutate(draft => {
        Object.assign(draft, toUpdate.data);
      });
    },
    onError: (error, { id }, rollback) => {
      // @ts-ignore
      rollback(error);
    },
  });

  return (
    <RightPanelSection>
      <AdvancedSelect
        enableClear={false}
        itemsWidth={340}
        width={360}
        inputPlaceholder="Szukaj schematu..."
        items={stageColumnSets.map(stageColumnSet => {
          return {
            id: stageColumnSet.id,
            name: stageColumnSet.name,
            filters: {
              name: stageColumnSet.name,
            },
          };
        })}
        onChange={item => {
          if (item) {
            patchMutation.mutate({
              data: { stageColumnSet: item.id },
              id: manufacturingStage.id,
            });
          }
        }}
        selectedItem={manufacturingStage?.stageColumnSet ?? null}
        itemToDisplaySelected={item => {
          return (
            <div className="d-flex align-items-center body-14 fw-500">
              {item ? item.name : "Przypisz"}
            </div>
          );
        }}
        overrides={{
          toggleButton: { style: { minHeight: "32px", border: "1px solid #ccc" } },
        }}
      />
    </RightPanelSection>
  );
};

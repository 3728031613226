import { createPaginatedKey } from "api/keys";

export const callCenterRoutesKeys = {
  route: {
    list: createPaginatedKey("callCenterRoutes"),
    details: (id: string) => ["callCenterRoute", id],
  },
  routeOrder: {
    list: createPaginatedKey("callCenterRouteOrders"),
    details: (id: number) => ["callCenterRouteOrder", id],
  },
  routeTracking: {
    details: (id: string) => ["callCenterRouteTracking", id],
  },
  dashboardDepartedRoute: {
    list: createPaginatedKey("dashboardDepartedRoute"),
  },
  dashboardIncompleteRoute: {
    list: createPaginatedKey("dashboardIncompleteRoute"),
  },
  dashboardLatestSmsMessage: {
    list: createPaginatedKey("dashboardLatestSmsMessages"),
  },
};

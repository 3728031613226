import invoiceIcon from "assets/images/invoice.svg";
import { EmptySection } from "../../shared/EmptySection";

export const EmptySalesSection = () => {
  // add invoice

  return (
    <EmptySection
      btnLabel="Utwórz fakturę"
      img={invoiceIcon}
      subtitle="twórz automatycznie faktury sprzedażowe i bądź na bieżąco z zaległymi płatnościami dzięki śledzeniu faktur online."
      title="Fakturowanie, dzięki któremu szybko otrzymasz zapłatę"
    />
  );
};

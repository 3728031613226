import { formatMonth } from "components/common/filters/filterComponents/filterDateRange/utils/formatMonth";
import { subWeeks } from "date-fns";
import { dateFns } from "utilities";

export const getPeriodsToDisplay = (): {
  label: string;
  startDate: string;
  endDate: string;
}[] => {
  const today = new Date();
  const months = [today, dateFns.subMonths(today, 1), dateFns.subMonths(today, 2)];

  return [
    {
      label: "poprzedni tydzień",
      startDate: dateFns.format(
        dateFns.startOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }),
        "yyyy-MM-dd",
      ),
      endDate: dateFns.format(
        dateFns.endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }),
        "yyyy-MM-dd",
      ),
    },
    {
      label: "obecny tydzień",
      startDate: dateFns.format(dateFns.startOfWeek(today, { weekStartsOn: 1 }), "yyyy-MM-dd"),
      endDate: dateFns.format(dateFns.endOfWeek(today, { weekStartsOn: 1 }), "yyyy-MM-dd"),
    },
  ].concat(
    months.map(month => {
      const normalizedMonth = formatMonth(month);
      return {
        label: normalizedMonth.displayValue,
        startDate: normalizedMonth.firstDay,
        endDate: normalizedMonth.lastDay,
      };
    }),
  );
};

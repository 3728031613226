import { wmsFileFactory } from "api/wms/calls";
import { Unloading, UnloadingDetails } from "api/wms/models";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";

export const useDownloadUnloadingPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();

  async function handleDownloadUnloadingPdf(unloading: Unloading | UnloadingDetails) {
    if (!unloading) throw new Error("An error occurred");
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = wmsFileFactory.unloadingPdf(unloading);
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    }
  }

  return handleDownloadUnloadingPdf;
};

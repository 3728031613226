import { Route } from "components/utils";
import { ImportPurchaseInvoiceHistory } from "pages/tradingDocuments/importPurchaseInvoices/history/ImportPurchaseInvoiceHistory";
import { ImportPurchaseInvoices } from "pages/tradingDocuments/importPurchaseInvoices/ImportPurchaseInvoices";
import { MyImportPurchaseInvoicesList } from "pages/tradingDocuments/importPurchaseInvoices/myImportPurchaseInvoicesList/MyImportPurchaseInvoicesList";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const ImportInvoices = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={ImportPurchaseInvoiceHistory}
        exact={true}
        path={`${match.path}/:purchaseInvoiceId/history`}
        title="Milo - historia faktury zakupowej"
      />
      <Route
        component={MyImportPurchaseInvoicesList}
        exact={true}
        path={`${match.path}/list/:tab`}
        title="Milo - moje faktury do zatwierdzenia"
      />
      <Route
        component={ImportPurchaseInvoices}
        exact={true}
        path={`${match.path}/check`}
        title="Milo - import faktur"
      />
    </Switch>
  );
};

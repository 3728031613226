import { TradingDocument } from "api/trading-documents/models";
import { InfoLabel } from "components/common/infoLabel";
import { StateLabel } from "components/common/stateLabel";
import { RightPanelSection } from "components/utils/drawer";
import { dateFns, getIsoDateFormat, handleDateField, pluralize } from "utilities";
import retailIcon from "assets/images/accountBox.svg";
import styles from "../RightPanel.module.css";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import { MoreDataCounter } from "components/common/moreDataCounter";
import { useState } from "react";
import { Button } from "components/common";
import cx from "classnames";
import { CustomerSection } from "./CustomerSection";
import { UserAvatar } from "components/utils/userAvatar";
import ReactTooltip from "react-tooltip";
import { useToggle } from "hooks";
import { EditRecipientNamesModal } from "../../editRecipientNamesModal/EditRecipientNamesModal";
import { openEditRecipientNamesModal } from "./utils/openEditRecipientNamesModal";
import { AssignedUser } from "components/common/assignUser/AssignUser";
import { LinkToPage } from "components/utils/linkToPage";
import { DatePicker } from "components/utils/datePicker";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  tradingDocument: TradingDocument;
}

export const GeneralInfoSection = ({ tradingDocument }: Props) => {
  const [showAllOrders, setShowAllOrders] = useState(false);
  const editRecipientNamesModal = useToggle();
  const [editRecipientModalPosition, setEditRecipientModalPosition] = useState<{
    top: number;
    left: number;
  }>({
    top: 0,
    left: 0,
  });
  const changeAssignedUserMutation = usePatchTradingDocumentMutation();
  const invoiceIssueDateMutation = usePatchTradingDocumentMutation();
  const invoiceDeliveryDateMutation = usePatchTradingDocumentMutation();
  const paymentDeadlineMutation = usePatchTradingDocumentMutation();
  const isNotSalesAndNotProformaAndNotAdvance =
    tradingDocument.invoiceType !== "SALES" &&
    tradingDocument.invoiceType !== "PROFORMA" &&
    tradingDocument.invoiceType !== "ADVANCE";
  const isSalesOrProformaOrAdvance =
    tradingDocument.invoiceType === "SALES" ||
    tradingDocument.invoiceType === "PROFORMA" ||
    tradingDocument.invoiceType === "ADVANCE";

  return (
    <RightPanelSection>
      <div className="d-flex align-items-start justify-content-between">
        <div>
          <InfoLabel title="przypisano do">
            <AssignedUser
              value={tradingDocument?.assignedTo}
              onChange={value =>
                changeAssignedUserMutation.mutate({
                  assignedTo: value,
                  id: tradingDocument.id,
                })
              }
            />
          </InfoLabel>
          <InfoLabel title="XML pobrano">
            {tradingDocument.xmlOptimaDownloadBy?.firstName ? (
              <>
                {tradingDocument.xmlOptimaDownloadBy.avatar ? (
                  <UserAvatar
                    className="mr-1"
                    avatarSrc={tradingDocument.xmlOptimaDownloadBy.avatar}
                  />
                ) : null}
                &nbsp;
                {tradingDocument.xmlOptimaDownloadBy.firstName}&nbsp;
                {tradingDocument.xmlOptimaDownloadBy.lastName} &nbsp; &nbsp;
              </>
            ) : null}

            {tradingDocument.xmlOptimaDownloadAt
              ? dateFns.format(new Date(tradingDocument.xmlOptimaDownloadAt), "dd.MM.yyyy")
              : "---"}
          </InfoLabel>
          <InfoLabel title="data wystawienia">
            {isNotSalesAndNotProformaAndNotAdvance ? (
              <Typography fontSize="14" fontWeight="500">
                {handleDateField(tradingDocument.invoiceIssueDate)}
              </Typography>
            ) : (
              <DatePicker
                disabled={invoiceIssueDateMutation.isLoading}
                look="common"
                onChange={date => {
                  if (!date) {
                    return invoiceIssueDateMutation.mutate({
                      id: tradingDocument.id,
                      invoiceIssueDate: null,
                      pitTaxObligationDate: null,
                      vatTaxObligationDate: null,
                    });
                  }
                  return invoiceIssueDateMutation.mutate({
                    id: tradingDocument.id,
                    invoiceIssueDate: getIsoDateFormat(date),
                    pitTaxObligationDate: getIsoDateFormat(date),
                    vatTaxObligationDate: getIsoDateFormat(date),
                  });
                }}
                overwrites={{
                  popup: { className: styles.datePickerPopup },
                  input: { className: styles.datePickerInput },
                  container: { className: styles.datePickerInput },
                }}
                value={tradingDocument.invoiceIssueDate ?? ""}
              />
            )}
          </InfoLabel>
          <InfoLabel title="data sprzedaży">
            {isNotSalesAndNotProformaAndNotAdvance ? (
              <Typography fontSize="14" fontWeight="700">
                {handleDateField(tradingDocument.invoiceDeliveryDate)}
              </Typography>
            ) : (
              <DatePicker
                disabled={invoiceDeliveryDateMutation.isLoading}
                look="common"
                onChange={date => {
                  if (!date) {
                    return invoiceDeliveryDateMutation.mutate({
                      id: tradingDocument.id,
                      invoiceDeliveryDate: null,
                      pitTaxObligationDate: null,
                      vatTaxObligationDate: null,
                    });
                  }
                  return invoiceDeliveryDateMutation.mutate({
                    id: tradingDocument.id,
                    invoiceDeliveryDate: getIsoDateFormat(date),
                    pitTaxObligationDate: getIsoDateFormat(date),
                    vatTaxObligationDate: getIsoDateFormat(date),
                  });
                }}
                overwrites={{
                  popup: { className: styles.datePickerPopup },
                  input: { className: styles.datePickerInput },
                  container: { className: styles.datePickerInput },
                }}
                value={tradingDocument.invoiceDeliveryDate ?? ""}
              />
            )}
          </InfoLabel>
          <InfoLabel title="data utworzenia">
            <Typography fontSize="14" fontWeight="700">
              {handleDateField(tradingDocument.createdAt)}
            </Typography>
          </InfoLabel>
          <InfoLabel title="data płatności">
            <Typography fontSize="14" fontWeight="700">
              {handleDateField(tradingDocument.invoicePaymentDate)}
            </Typography>
          </InfoLabel>
          <InfoLabel title="sprzedawca">
            <StateLabel kind="orangeDark">
              {tradingDocument.sellerCompanyName ? (
                <div>{tradingDocument.sellerCompanyName}</div>
              ) : (
                <div>
                  {tradingDocument.sellerFirstName}&nbsp;{tradingDocument.sellerLastName}
                </div>
              )}
            </StateLabel>
            <div className="body-14-600 d-flex align-items-center">
              <div className="text-grey-35">NIP: &nbsp;</div>
              {tradingDocument.seller && tradingDocument.seller.taxId ? (
                <div>{tradingDocument.seller.taxId}</div>
              ) : (
                <div>---</div>
              )}
            </div>
          </InfoLabel>
          {isSalesOrProformaOrAdvance &&
          (tradingDocument.kind === "DROPSHIPPING" || tradingDocument.kind === "WHOLESALE") ? (
            <CustomerSection
              editRecipientModalPosition={editRecipientModalPosition}
              salesInvoice={tradingDocument}
              setEditRecipientModalPosition={setEditRecipientModalPosition}
            />
          ) : (
            <InfoLabel title="nabywca">
              <div>
                <div className="d-flex align-items-center gap-2">
                  <div
                    className={cx(styles.filterChip, styles.filterRetail)}
                    data-tip
                    data-for={`retail-${tradingDocument.id}`}
                    onClick={e =>
                      openEditRecipientNamesModal(
                        e,
                        setEditRecipientModalPosition,
                        editRecipientNamesModal.toggle,
                      )
                    }
                  >
                    <img alt="Ikona nabywcy" src={retailIcon} />
                    <div>
                      {tradingDocument.recipientCompanyName ? (
                        <div className="d-flex align-items-center gap-1">
                          {tradingDocument.recipientCompanyName}
                        </div>
                      ) : tradingDocument.recipientFirstName.length > 0 ||
                        tradingDocument.recipientLastName.length > 0 ? (
                        <div className="d-flex align-items-center gap-1">
                          {tradingDocument.recipientFirstName} &nbsp;
                          {tradingDocument.recipientLastName}
                        </div>
                      ) : (
                        "---"
                      )}
                    </div>
                  </div>
                  {isSalesOrProformaOrAdvance ? (
                    <>
                      {!tradingDocument.canBeFiscalized ? (
                        <div className="body-14-600 d-flex align-items-center">
                          <div className="text-grey-35">NIP: &nbsp;</div>
                          {tradingDocument.recipientTaxId ? (
                            <div>{tradingDocument.recipientTaxId}</div>
                          ) : (
                            <div>---</div>
                          )}
                        </div>
                      ) : (
                        <span className="body-14-600">
                          <span className="text-grey-35">Potrzebny paragon</span>
                        </span>
                      )}
                    </>
                  ) : (
                    <div className="body-14-600 d-flex align-items-center">
                      <div className="text-grey-35">NIP: &nbsp;</div>
                      {tradingDocument.recipientTaxId ? (
                        <div>{tradingDocument.recipientTaxId}</div>
                      ) : (
                        <div>---</div>
                      )}
                    </div>
                  )}
                </div>
                <ReactTooltip
                  className={styles.customTooltip}
                  id={`retail-${tradingDocument.id}`}
                  place="top"
                  effect="solid"
                  arrowColor="transparent"
                  offset={{ top: -10 }}
                >
                  Klient detaliczny
                </ReactTooltip>
                {editRecipientNamesModal.isOpen && (
                  <EditRecipientNamesModal
                    close={editRecipientNamesModal.close}
                    modalPosition={editRecipientModalPosition}
                    tradingDocument={tradingDocument}
                  />
                )}
              </div>
            </InfoLabel>
          )}
          <InfoLabel title="termin płatności">
            <div className="d-flex gap-2">
              {tradingDocument.invoiceType !== "SALES" ? (
                <Typography fontSize="16" fontWeight="700">
                  {handleDateField(tradingDocument.paymentDeadline)}
                </Typography>
              ) : (
                <DatePicker
                  disabled={paymentDeadlineMutation.isLoading}
                  look="common"
                  onChange={date => {
                    if (!date) {
                      return paymentDeadlineMutation.mutate({
                        id: tradingDocument.id,
                        paymentDeadline: null,
                      });
                    }
                    return paymentDeadlineMutation.mutate({
                      id: tradingDocument.id,
                      paymentDeadline: getIsoDateFormat(date),
                    });
                  }}
                  overwrites={{
                    popup: { className: styles.datePickerPopup },
                    input: { className: styles.datePickerInput },
                    container: { className: styles.datePickerInput },
                  }}
                  value={tradingDocument.paymentDeadline ?? ""}
                />
              )}
              {tradingDocument.basePaymentDeadlineDelta && (
                <div className="d-flex align-items-center">
                  <StateLabel kind="cyan">
                    <span>
                      {tradingDocument.basePaymentDeadlineDelta}{" "}
                      {pluralize.pl(tradingDocument.basePaymentDeadlineDelta, {
                        singular: "dzień",
                        plural: "dni",
                        other: "dni",
                      })}
                    </span>
                  </StateLabel>
                </div>
              )}
            </div>
          </InfoLabel>
          <InfoLabel title="zamówienia">
            {tradingDocument.orders &&
              tradingDocument.orders.length > 0 &&
              !showAllOrders &&
              tradingDocument.orders.slice(0, 2).map((order, index) => {
                if (order !== null) {
                  return (
                    <LinkToPage
                      content={
                        <>
                          <div className="fs-14 fw-500">{order.signature}</div>
                          {index + 1 !== tradingDocument.orders.length &&
                            tradingDocument.orders[index + 1] !== null &&
                            index < 1 && <div>,</div>}
                        </>
                      }
                      key={order.id}
                      url={getOrderRedirectUrl(order)}
                    />
                  );
                }
                return null;
              })}
            {tradingDocument.orders && tradingDocument.orders.length > 0 && showAllOrders && (
              <div className={cx(styles.showAllOrders)}>
                <div className="d-flex align-items-center flex-wrap">
                  {tradingDocument.orders.map((order, index) => {
                    if (order !== null) {
                      return (
                        <LinkToPage
                          content={
                            <>
                              <div className="fs-14 fw-500">{order.signature}</div>
                              {index + 1 !== tradingDocument.orders.length &&
                                tradingDocument.orders[index + 1] !== null && <div>,&nbsp;</div>}
                            </>
                          }
                          url={getOrderRedirectUrl(order)}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
                <Button
                  className="mt-2"
                  kind="create"
                  onClick={() => setShowAllOrders(prev => !prev)}
                >
                  <div className="btnBase btnBaseSmall">Ukryj</div>
                </Button>
              </div>
            )}
            {tradingDocument.orders.filter(order => order).length - 2 > 0 && !showAllOrders && (
              <div onClick={() => setShowAllOrders(prev => !prev)}>
                <MoreDataCounter
                  className={styles.moreDataCounterOverride}
                  counter={tradingDocument.orders.filter(order => order).length - 2}
                />
              </div>
            )}
          </InfoLabel>
          {tradingDocument.invoiceType === "SALES" && (
            <InfoLabel title="proforma">
              {tradingDocument.proforma ? (
                <LinkToPage
                  content={
                    <Typography color="inherit" fontSize="14" fontWeight="600">
                      {tradingDocument.proforma.signature}
                    </Typography>
                  }
                  url={`/finances/proformas/list/all?panelId=${tradingDocument.proforma.id}`}
                />
              ) : (
                <EmptyValue />
              )}
            </InfoLabel>
          )}
        </div>
      </div>
    </RightPanelSection>
  );
};

import { FormikHelpers } from "formik";
import { AddOrdersToUnloadingForm } from "./AddOrdersBySignaturesModal";
import { useMutation } from "hooks/useMutation";
import { addOrdersToUnloadingBaseOnExternalIds } from "api/wms/calls";
import { wmsKeys } from "api/wms/keys";
import { AddOrdersToUnloadingResponse, UnloadingDetails } from "api/wms/models";
import { getAnyErrorKey, queryString } from "utilities";

export const useAddOrdersToUnloading = (
  responseModal: {
    isOpen: boolean;
    open: (stateToSet: AddOrdersToUnloadingResponse) => void;
    close: () => void;
    state: AddOrdersToUnloadingResponse | null;
  },
  unloading: UnloadingDetails,
) => {
  const addOrdersToUnloadingMutation = useMutation(
    addOrdersToUnloadingBaseOnExternalIds,
    ({ toastr, queryClient }) => ({
      onSuccess: payload => {
        queryClient.invalidateQueries(wmsKeys.unloads.list());
        queryClient.invalidateQueries(wmsKeys.unloads.details(unloading.id));
        queryClient.invalidateQueries(
          wmsKeys.unloads.lineItems(
            queryString.stringify({
              page: 1,
              unloading: unloading.id,
            }),
          ),
        );
        responseModal.open(payload);
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: AddOrdersToUnloadingForm,
    actions: FormikHelpers<AddOrdersToUnloadingForm>,
  ) => {
    addOrdersToUnloadingMutation.mutate(
      {
        ordersIds: values.orders.split(",").map(order => order.trim()),
        unloadingId: unloading.id,
      },
      {
        onSuccess: () => {
          actions.setSubmitting(false);
        },
        onError: error => {
          actions.setSubmitting(false);
          actions.setErrors(error.response?.data);
        },
      },
    );
  };

  return handleSubmit;
};

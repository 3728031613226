export const indigo = {
  indigo12: "#3965FF1F",
  indigo25: "#DFE6FF",
  indigo32: "#3965FF52",
  indigo50: "#C9D5FF",
  indigo75: "#A1B7FF",
  indigo100: "#7694FF",
  indigo200: "#647DFF",
  indigo300: "#4761DD",
  indigo400: "#3A50CB",
  indigo500: "#31409B",
  indigo600: "#101C6E",
};

import { AssignedWhEntry, InvoiceAdmissionLinkLine } from "api/trading-documents/models";
import cx from "classnames";
import { Button } from "components/common";
import { AsyncInput } from "components/utils";
import crossIcon from "assets/images/close.svg";
import styles from "./LinkLineSection.module.css";
import { useState } from "react";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import {
  deleteInvoiceAdmissionPackage,
  patchInvoiceAdmissionPackage,
} from "api/trading-documents/calls";
import { Pagination, UUID } from "api/types";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { getIsTotalPackagesAmountInvalid } from "pages/tradingDocuments/connectInvoice/utils/isTotalPackagesAmountInvalid";
import { getAnyErrorKey, queryString } from "utilities";
import { QueryClient } from "react-query";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { Droppable } from "react-beautiful-dnd";
import { getMissingAmountForPackages } from "pages/tradingDocuments/connectInvoice/utils/getMissingAmountForPackages";

interface Props {
  linkLine: InvoiceAdmissionLinkLine;
  position: number;
  search: string;
  tradingDocument: UUID;
}

export const LinkLine = ({ linkLine, position, search, tradingDocument }: Props) => {
  const sumAmountWithoutTax = (linkLine.quantity * Number(linkLine.amount)).toFixed(2);
  const [isTotalPackagesAmountInvalid, setIsTotalPackagesAmountInvalid] = useState(
    getIsTotalPackagesAmountInvalid(Number(sumAmountWithoutTax), linkLine.packages),
  );
  const [missingAmountForPackages, setMissingAmountForPackages] = useState(
    getMissingAmountForPackages(Number(sumAmountWithoutTax), linkLine.packages),
  );
  const whEntrySearch = queryString.stringify({
    invoiceAdmissionLink: linkLine.invoiceAdmissionLink,
    pageSize: 999,
  });

  const handleRollback = (context: unknown, queryClient: QueryClient) => {
    const previousList = queryClient.setQueryData<Pagination<InvoiceAdmissionLinkLine>>(
      tradingDocumentsKeys.invoiceAdmission.linkLines(search),
      currentList => {
        assertIsDefined(currentList);
        return {
          ...currentList,
          // @ts-ignore
          results: context.results,
        };
      },
    );

    const currentLinkLine = previousList.results.find(_linkLine => _linkLine.id === linkLine.id);

    if (currentLinkLine) {
      setIsTotalPackagesAmountInvalid(
        getIsTotalPackagesAmountInvalid(Number(sumAmountWithoutTax), currentLinkLine.packages),
      );
      setMissingAmountForPackages(
        getMissingAmountForPackages(Number(sumAmountWithoutTax), currentLinkLine.packages),
      );
    }
    queryClient.invalidateQueries(tradingDocumentsKeys.invoiceAdmission.linkLines(search));
  };

  const updatePackageOptions = useMutationOptions(
    patchInvoiceAdmissionPackage,
    ({ queryClient, toastr }) => ({
      onMutate: args => {
        const prevList = queryClient.getQueryData(
          tradingDocumentsKeys.invoiceAdmission.linkLines(search),
        );

        const updatedPackagesList = queryClient.setQueryData<Pagination<InvoiceAdmissionLinkLine>>(
          tradingDocumentsKeys.invoiceAdmission.linkLines(search),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(_linkLine => {
                if (_linkLine.id === linkLine.id) {
                  return {
                    ..._linkLine,
                    packages: _linkLine.packages.map(_package => {
                      if (_package.id === args.id) {
                        if (args.amount) return { ..._package, amount: args.amount };
                        if (args.quantity) return { ..._package, quantity: args.quantity };
                      }
                      return _package;
                    }),
                  };
                }
                return _linkLine;
              }),
            };
          },
        );

        const currentLinkLine = updatedPackagesList.results.find(
          _linkLine => _linkLine.id === linkLine.id,
        );

        if (currentLinkLine) {
          setIsTotalPackagesAmountInvalid(
            getIsTotalPackagesAmountInvalid(Number(sumAmountWithoutTax), currentLinkLine.packages),
          );
          setMissingAmountForPackages(
            getMissingAmountForPackages(Number(sumAmountWithoutTax), currentLinkLine.packages),
          );
        }

        return prevList;
      },
      onSuccess: payload => {
        queryClient.setQueryData<Pagination<AssignedWhEntry>>(
          tradingDocumentsKeys.invoiceAdmission.assignedWhEntries(whEntrySearch),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(_whEntry => {
                const whEntryPackage = _whEntry.items.find(
                  _package => _package.id === payload.itemUsed.id,
                );
                if (whEntryPackage !== undefined) {
                  return {
                    ..._whEntry,
                    items: _whEntry.items.map(_package => {
                      if (_package.id === whEntryPackage.id) {
                        return {
                          ..._package,
                          leftQuantity: payload.itemUsed.leftQuantity,
                          usedQuantity: payload.itemUsed.usedQuantity,
                        };
                      }
                      return _package;
                    }),
                  };
                }
                return _whEntry;
              }),
            };
          },
        );
        queryClient.invalidateQueries(
          tradingDocumentsKeys.invoiceAdmission.details(tradingDocument),
        );
        queryClient.invalidateQueries(tradingDocumentsKeys.invoiceAdmission.linkLines(search));
        queryClient.invalidateQueries(
          tradingDocumentsKeys.invoiceAdmission.assignedWhEntries(whEntrySearch),
        );
      },
      onError: (error, _, context) => {
        handleRollback(context, queryClient);
        toastr.open({
          type: "warning",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const deletePackageMutation = withDeleteConfirmation(
    useMutation(deleteInvoiceAdmissionPackage, ({ queryClient, toastr }) => ({
      onMutate: id => {
        const prevList = queryClient.getQueryData(
          tradingDocumentsKeys.invoiceAdmission.linkLines(search),
        );

        const updatedPackagesList = queryClient.setQueryData<Pagination<InvoiceAdmissionLinkLine>>(
          tradingDocumentsKeys.invoiceAdmission.linkLines(search),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(_linkLine => {
                if (_linkLine.id === linkLine.id) {
                  return {
                    ..._linkLine,
                    packages: _linkLine.packages.filter(_package => _package.id !== id),
                  };
                }
                return _linkLine;
              }),
            };
          },
        );

        const currentLinkLine = updatedPackagesList.results.find(
          _linkLine => _linkLine.id === linkLine.id,
        );

        if (currentLinkLine) {
          setIsTotalPackagesAmountInvalid(
            getIsTotalPackagesAmountInvalid(Number(sumAmountWithoutTax), currentLinkLine.packages),
          );
          setMissingAmountForPackages(
            getMissingAmountForPackages(Number(sumAmountWithoutTax), currentLinkLine.packages),
          );
        }

        return prevList;
      },
      onSuccess: () => {
        queryClient.invalidateQueries(
          tradingDocumentsKeys.invoiceAdmission.details(tradingDocument),
        );
        queryClient.invalidateQueries(tradingDocumentsKeys.invoiceAdmission.linkLines(search));
        queryClient.invalidateQueries(
          tradingDocumentsKeys.invoiceAdmission.assignedWhEntries(whEntrySearch),
        );
      },
      onError: (error, _, context) => {
        handleRollback(context, queryClient);
        toastr.open({
          type: "warning",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      },
    })),
  )();

  const updatePackageAmountMutation = useMutation(
    updatePackageOptions.mutationFn,
    updatePackageOptions,
  );
  const updatePackageQuantityMutation = useMutation(
    updatePackageOptions.mutationFn,
    updatePackageOptions,
  );

  return (
    <div
      className={cx(styles.tableRow, styles.tableRowMatchPackages, {
        [styles.rowSuccess]: !isTotalPackagesAmountInvalid && linkLine.confidence === 2,
        [styles.rowWarning]: !isTotalPackagesAmountInvalid && linkLine.confidence === 1,
        [styles.rowMissing]: !isTotalPackagesAmountInvalid && linkLine.packages.length === 0,
        [styles.rowDanger]: isTotalPackagesAmountInvalid,
      })}
    >
      <div className="pt-1">{position}.</div>
      <div className="pt-1">
        <div className={styles.productName}>{linkLine.name}</div>
        {!isTotalPackagesAmountInvalid && linkLine.confidence === 2 && linkLine.isEdited && (
          <span className={cx(styles.helperText, "text-black-6")}>&#40;edytowano&#41;</span>
        )}
        {!isTotalPackagesAmountInvalid && linkLine.confidence === 1 && (
          <span className="body-10 text-orange-4">paczki na podstawie podobnego produktu</span>
        )}
        {!isTotalPackagesAmountInvalid &&
          linkLine.packages.length === 0 &&
          linkLine.confidence !== 1 &&
          linkLine.confidence !== 2 && (
            <span className="body-10 text-black-6">brakuje dodanych paczek</span>
          )}
        {isTotalPackagesAmountInvalid && (
          <span className="body-10 text-red-6">suma cen paczki wyższa niż spodziewana</span>
        )}
        {linkLine.packages.length > 0 && missingAmountForPackages > 0 && (
          <div className="body-10 text-black-6">
            brakująca kwota: {missingAmountForPackages.toFixed(2)}
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end pt-1">{linkLine.quantity}</div>
      <div
        className={cx("d-flex align-items-center justify-content-end pt-1", {
          "text-red-6": isTotalPackagesAmountInvalid,
        })}
      >
        {linkLine.amount}
      </div>
      <div className="d-flex align-items-center justify-content-end pt-1">
        {sumAmountWithoutTax}
      </div>
      <Droppable droppableId={linkLine.id}>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {linkLine.packages.map(_package => (
              <div
                className={cx(styles.packagesGroup, styles.productNameContainer)}
                key={_package.id}
              >
                <div className={cx(styles.productName, "pt-1")}>{_package.name}</div>
                <div>
                  <div className="d-flex align-items-center justify-content-end textAlignRight">
                    <div className="d-flex align-items-center justify-content-end gap-1">
                      <div className={cx(styles.inputBox, "w-100")}>
                        <AsyncInput
                          disabled={updatePackageQuantityMutation.isLoading}
                          error={getAnyErrorKey(updatePackageQuantityMutation.error)}
                          ignoreFocus={true}
                          onClick={e => {
                            e.stopPropagation();
                          }}
                          onChange={value =>
                            updatePackageQuantityMutation.mutate({
                              quantity: value,
                              id: _package.id,
                            })
                          }
                          overwrites={{ input: { className: styles.quantityInput } }}
                          type="number"
                          value={_package.quantity}
                        />
                      </div>
                      <div className="body-12">szt.</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end textAlignRight">
                  <div className="d-flex align-items-center justify-content-end gap-1">
                    <div className={cx(styles.inputBox, "w-100")}>
                      <AsyncInput
                        disabled={updatePackageAmountMutation.isLoading}
                        ignoreFocus={true}
                        onClick={e => {
                          e.stopPropagation();
                        }}
                        onChange={value =>
                          updatePackageAmountMutation.mutate({ amount: value, id: _package.id })
                        }
                        overwrites={{ input: { className: styles.quantityInput } }}
                        type="number"
                        error={getAnyErrorKey(updatePackageAmountMutation.error)}
                        value={_package.amount}
                      />
                    </div>
                    <div
                      className={cx("body-12", {
                        "text-red-6": isTotalPackagesAmountInvalid,
                      })}
                    >
                      PLN/szt.
                    </div>
                  </div>
                </div>
                <Button
                  kind="transparent-black"
                  onClick={() => deletePackageMutation.mutate(_package.id)}
                  size="square-s"
                >
                  <div className="btnBase btnBaseSmall">
                    <img alt="Usuń" src={crossIcon} style={{ height: "16px", width: "16px" }} />
                  </div>
                </Button>
              </div>
            ))}
            {/* {provided.placeholder} */}
            <div
              className={cx(styles.packagesGroupPlaceholder, {
                [styles.packagesGroupPlaceholderDrag]: snapshot.isDraggingOver,
              })}
            >
              <div className="body-12">Przeciągnij i upuść, aby dodać paczkę</div>
            </div>
          </div>
        )}
      </Droppable>
    </div>
  );
};

import { TradingDocument } from "api/trading-documents/models";
import { RightPanelSection } from "components/utils/drawer";
import styles from "./RemindersSection.module.css";
import { Reminder } from "./components/Reminder";
import cx from "classnames";
import { EmptyRemindersSection } from "./components/EmptyRemindersSection";

interface Props {
  tradingDocument: TradingDocument;
}

export const RemindersSection = ({ tradingDocument }: Props) => {
  const reminders = tradingDocument.remindersSent;

  return (
    <RightPanelSection title="Przypomnienia">
      {reminders.length === 0 ? (
        <EmptyRemindersSection />
      ) : (
        <div className="pb-3">
          <div className="rightPanelSectionHeader">
            <div className={cx(styles.tableHeader, styles.tableHeaderReminder)}>
              <div>#</div>
              <div>przypomnienie</div>
              <div>kiedy wysłano</div>
              <div></div>
              <div></div>
              <div>wysyłka</div>
            </div>
            <div className={styles.tableBody}>
              {reminders.map((reminder, index) => (
                <Reminder {...reminder} index={index + 1} key={index} />
              ))}
            </div>
          </div>
        </div>
      )}
    </RightPanelSection>
  );
};

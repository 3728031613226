import { RightPanelHeader } from "components/utils/drawer";
import { Button } from "components/common";
import hidePanelIcon from "assets/images/keyboardTab.svg";
import deleteRed from "assets/images/delete_red.svg";
import cx from "classnames";
import { ManufacturingSchema } from "api/manufacturingNew/models";
import popUpStyles from "components/common/moreInfoButton/MoreInfoButton.module.css";
import { MoreInfoButton } from "components/common/moreInfoButton/MoreInfoButton";
import { useQueryClient } from "react-query";
import { useMutation } from "hooks/useMutation";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";
import { useConfirmModal } from "hooks";
import { deleteManufacturingSchema } from "api/manufacturingNew/calls";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
  panelId: string;
  manufacturingSchema: ManufacturingSchema;
}

export const PanelHeader = ({ close, isScrolledOver, panelId, manufacturingSchema }: Props) => {
  const queryClient = useQueryClient();
  const confirmModal = useConfirmModal();
  const deleteMutation = useMutation(deleteManufacturingSchema, {
    onSuccess: () => {
      queryClient.invalidateQueries(manufacturingNewKeys.manufacturingSchemas());
      close();
    },
  });

  return (
    <RightPanelHeader
      className={cx({
        slidingPanelHeaderShadow: isScrolledOver,
      })}
    >
      <div className="d-flex w-100 align-items-center">
        {isScrolledOver && (
          <div className="slidingPanelHeader">
            <div className="slidingPanelHeaderContent nowrap">{manufacturingSchema.name}</div>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <span className="divider line-divider" />
          <MoreInfoButton
            children={
              <>
                <button
                  disabled={deleteMutation.isLoading}
                  onClick={() => {
                    confirmModal.open({
                      text: `Czy na pewno chcesz usunąć schemat ${manufacturingSchema.signature}?`,
                      confirmText: "Usuń",
                      callback: () => deleteMutation.mutate(manufacturingSchema.id),
                    });
                  }}
                  className={cx("px-3 py-2 w-100", popUpStyles.optionRow)}
                >
                  <div className="d-flex align-items-center justify-content-between gap-2">
                    <div className="d-flex align-items-center gap-2">
                      <img src={deleteRed} alt="Usuń" />
                      <div className="body-14-600 text-red-4">Usuń</div>
                    </div>
                  </div>
                </button>
              </>
            }
          />
          <span className="divider line-divider" />
          <Button kind="transparent-black" onClick={close} size="square-s">
            <div className="btnBase btnBaseSmall">
              <img
                alt="Zamknij panel"
                src={hidePanelIcon}
                style={{ height: "16px", width: "16px" }}
              />
            </div>
          </Button>
        </div>
      </div>
    </RightPanelHeader>
  );
};

import { EmployeesTab as Tab } from "api/manufacturingNew/models";
import { queryString } from "utilities";

type GetSearchProps = {
  tab: Tab;
  query: {
    [x: string]: string;
  };
};

export const getSearch = ({ query, tab }: GetSearchProps): string => {
  const tabUpper = tab.toUpperCase();
  const tabsQuery = {
    kind: tabUpper !== "ALL" ? tabUpper : "",
  };
  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

import { InvoiceAdmission } from "api/trading-documents/models";
import { dateFns, queryString } from "utilities";
import listEmptyIcon from "assets/images/el2.png";
import styles from "../../ConnectInvoice.module.css";
import localStyles from "./InvoiceSection.module.css";
import cx from "classnames";
import { useQuery } from "hooks";
import { useInvoiceAdmissionLinkLines } from "api/trading-documents/hooks";
import { LinkLineSection } from "./components/linkLineSection/LinkLineSection";
import { NoContent } from "components/utils/noContent";
import { PanelPagination } from "components/utils/panelPagination";
import { SearchField } from "components/utils/searchField/SearchField";

interface Props {
  invoiceAdmission: InvoiceAdmission;
}

export const InvoiceSection = ({ invoiceAdmission }: Props) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify({
    ...query,
    tradingDocument: invoiceAdmission.tradingDocument,
  });
  const { data: lineItems, error, isLoading, pagination } = useInvoiceAdmissionLinkLines(search);

  const noResults = !lineItems.length && !isLoading && !error;

  const missingAmount = invoiceAdmission.currentAmount
    ? Number(invoiceAdmission.amount) - Number(invoiceAdmission.currentAmount)
    : invoiceAdmission.amount;

  return (
    <div className="px-0 col-8">
      <div className="pl-3 pr-3 py-2 borderBottom">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center flex-wrap gap-2 mr-2">
            <h2 className="fw-800 mb-0">{invoiceAdmission.tdSignature}</h2>
            <span className="separator" />
            <span className="body-12 text-black-6">data utworzenia faktury: </span>
            <span className={cx(styles.date, "fw-700")}>
              {dateFns.format(new Date(invoiceAdmission.tdCreatedAt), "dd.MM.yyyy")}
            </span>
            <span className="separator" />
            <span className="body-12 text-black-6">firma: </span>
            <span className="body-16">{invoiceAdmission.businessEntityName}</span>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <div className="d-flex align-items-center justify-content-end gap-3">
                <div className="d-flex align-items-center justify-content-end gap-1">
                  <div className={localStyles.labelText}>suma:</div>
                  <strong className="body-18-800">
                    {invoiceAdmission.amount}&nbsp;{invoiceAdmission.currency}
                  </strong>
                </div>
                <div className="d-flex align-items-center justify-content-end gap-1">
                  <div className={localStyles.labelText}>brakuje/różnica:</div>
                  <strong className="body-18-800 text-red-6">
                    {String(Number(missingAmount).toFixed(2)).replace(/\./g, ",")}&nbsp;
                    {invoiceAdmission.currency}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between px-3 pt-2 gap-2 flex-wrap">
        <div className="col-12 col-md-5 px-0">
          <SearchField
            value={query.search || ""}
            onUpdate={value => updateQuery({ search: value, page: 1 })}
            isNewLayout={true}
          />
        </div>
        <div className="d-flex align-items-center gap-2">
          <PanelPagination pagination={pagination} />
        </div>
      </div>
      {noResults ? (
        <NoContent on={noResults} header="Brak danych" img={listEmptyIcon} />
      ) : (
        <LinkLineSection
          linkItems={lineItems}
          search={search}
          tradingDocument={invoiceAdmission.tradingDocument}
        />
      )}
    </div>
  );
};

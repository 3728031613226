import cx from "classnames";
import styles from "../InvoicePagination.module.css";

interface ArrowButtonProps {
  src: string;
  alt: string;
  onClick: () => void;
  disabled: boolean;
}
export const ArrowButton = ({ src, alt, onClick, disabled }: ArrowButtonProps) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={cx(
        styles.btn,
        styles.btnSizeSX,
        styles.btnBgTransparentTextBlack,
        styles.nonfocusable,
      )}
    >
      <div className={cx(styles.btnBase, styles.btnBaseSmall)}>
        <img src={src} alt={alt} />
      </div>
    </button>
  );
};

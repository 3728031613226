import markerImg from "assets/images/42.svg";
import penImg from "assets/images/1g.svg";
import dragImg from "assets/images/303.svg";
import binImg from "assets/images/81g.svg";
import cx from "classnames";
import { Button, useToastr } from "components/common";
import { useRoutePutMutation } from "hooks/apiPrimitives";
import { useRouteViewState } from "pages/routes/creator/routeCreatorState";
import styles from "./Passpoint.module.css";
import { OrderPositionTypes, Route } from "api/routes/models";
import { ClickOutsideHandler } from "components/utils";
import { Draggable } from "react-beautiful-dnd";
import {
  getFullRouteCoords,
  getOrdersPositionsBasedOnGraphhopper,
  getPoints,
} from "pages/routes/creator/utils";
import { graphhopper } from "api/graphhopper/graphhopper";

interface Props {
  isLoading: boolean;
  passpoint: OrderPositionTypes["passpoint"];
  route: Route;
  index: number;
}
export const passpointClickOutsideIgnoreClass = "passpoint-click-outside-ignore-class";

export const Passpoint = ({ isLoading, route, passpoint, index }: Props) => {
  const updateMutation = useRoutePutMutation();
  const actions = useRouteViewState("slave", state => state.actions);
  const editingPasspointId = useRouteViewState("slave", state => state.editingPasspointId);
  const toastr = useToastr();

  const handleRemove = async () => {
    actions.openLoader("Trwa usuwanie punktu przelotowego");
    const newOrdersPositions: Route["ordersPositions"] = route.ordersPositions.filter(
      el => el.id !== passpoint.id,
    );
    const startingPointLngLat = [route.startingPoint.point.lng, route.startingPoint.point.lat];

    const newPoints = getPoints(newOrdersPositions);

    const points = getFullRouteCoords(route, newPoints, startingPointLngLat);

    const payload = await graphhopper.route({
      points,
      vehicle: route.vehicleType,
      includeLastPointInOptimization: route.includeLastPointInOptimization,
    });

    if (payload) {
      const returnToStartingPointDistance = String(payload.points[newPoints.length].distance);
      const returnToStartingPointTime = String(payload.points[newPoints.length].time);

      const ordersPositions = getOrdersPositionsBasedOnGraphhopper(
        newOrdersPositions,
        payload.points,
      );

      updateMutation.mutate({
        data: {
          length: payload.distance,
          operation: null,
          ordersPositions,
          returnToStartingPointDistance,
          returnToStartingPointTime,
          shouldCalculateAverageSpeed: true,
        },
        route: route.id,
      });
    } else {
      actions.closeLoader();
      toastr.open({
        type: "failure",
        title: "Nie udało się przypiąć zamówienia",
        text: "",
      });
    }
  };

  const text = (() => {
    if (editingPasspointId === passpoint.id) return "Kliknij w miejsce na mapie aby wybrać";
    if (!passpoint.meta.point) return "Kliknij w miejsce na mapie aby wybrać";
    return passpoint.meta.delivery.address;
  })();

  return (
    <ClickOutsideHandler
      onClickOutside={() => actions.setState({ editingPasspointId: null })}
      outsideClickIgnoreClass={passpointClickOutsideIgnoreClass}
    >
      <Draggable draggableId={String(passpoint.id)} index={index}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div className="d-flex">
              <div className={styles.dotContainer}>
                <div className={cx(styles.greyDot, { invisible: snapshot.isDragging })}></div>
              </div>

              <div
                className={cx(styles.wrapper, {
                  [styles.active]: editingPasspointId === passpoint.id,
                  [styles.dragging]: snapshot.isDragging,
                })}
              >
                <div className="d-flex w-100">
                  <div className="d-flex align-items-center w-100">
                    <img src={markerImg} className={cx("mr-2", styles.icon)} alt="" />
                    <label htmlFor="" className={cx("mr-2", styles.label)}>
                      punkt przelotowy:
                    </label>
                    <div className={styles.address}>{text}</div>
                  </div>
                  <div className={styles.buttons}>
                    <Button
                      kind="secondary"
                      size="round-s"
                      disabled={isLoading}
                      onClick={handleRemove}
                    >
                      <img src={binImg} alt="" />
                    </Button>
                    {editingPasspointId !== passpoint.id && (
                      <Button
                        kind="secondary"
                        size="round-s"
                        disabled={isLoading}
                        onClick={() => {
                          actions.setState({ editingPasspointId: passpoint.id });
                        }}
                      >
                        <img src={penImg} alt="" />
                      </Button>
                    )}
                  </div>

                  <div className={styles.dragImg}>
                    <img src={dragImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </ClickOutsideHandler>
  );
};

import { pluralize, yup } from "utilities";

const requiredMsg = (field: string): string => {
  return `Pole ${field} jest wymagane`;
};

const maxLengthMsg = (field: string, chars: number): string => {
  return `Pole ${field} powinno zawierać maksymalnie ${chars} ${pluralize.pl(chars, {
    singular: "znak",
    plural: "znaki",
    other: "znaków",
  })}`;
};

export const validationSchema = yup.object().shape({
  code: yup
    .string()
    .required(requiredMsg("Kod rampy"))
    .max(2, maxLengthMsg("Kod rampy", 2)),
  hall: yup
    .string()
    .required(requiredMsg("Hala"))
    .max(200, maxLengthMsg("Hala", 200)),
  name: yup
    .string()
    .required(requiredMsg("Nazwa"))
    .max(200, maxLengthMsg("Nazwa", 200)),
});

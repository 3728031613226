import { createColumnHelper } from "@tanstack/react-table";
import { PackagesReceivedAndReleasedPerMonthIdentifiers } from "api/wms/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useMemo } from "react";
import { GenericTable } from "typeUtilities";
import { getMonthName } from "../../utils/getMonthName";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { isCurrentMonth } from "../../utils/isCurrentMonth";

const columnHelper = createColumnHelper<
  GenericTable<PackagesReceivedAndReleasedPerMonthIdentifiers>["rows"][number]
>();

export const useReceivedAndReleasedPackagesPerMonth = (
  tableData: GenericTable<PackagesReceivedAndReleasedPerMonthIdentifiers> | null,
) => {
  return useMemo(() => {
    if (!tableData) return [];
    if (!tableData.rows.length) return [];
    if (tableData) {
      return [
        columnHelper.accessor(row => row.DATEPOINTER.value, {
          header:
            tableData.columns.find(
              column =>
                column.identifier === PackagesReceivedAndReleasedPerMonthIdentifiers.DATEPOINTER,
            )?.name || "",
          size: 145,
          cell: info => (
            <div className="d-flex align-items-center gap-1">
              <Typography fontSize="12" fontWeight="600">
                {getMonthName(info.getValue()).month} {getMonthName(info.getValue()).year}
              </Typography>
              {isCurrentMonth(info.getValue()) && <Tag label="aktualny" variant="deepPurple50" />}
            </div>
          ),
        }),
        columnHelper.group({
          id: "Received",
          header: () => (
            <Typography fontSize="14" fontWeight="700">
              Przyjęcia
            </Typography>
          ),
          columns: [
            columnHelper.accessor(row => row.RECEIVED_INDEXES.value, {
              id: "RECEIVED_INDEXES",
              header: () => (
                <Typography
                  className="text-right w-100"
                  color="neutralBlack48"
                  fontSize="12"
                  fontWeight="400"
                >
                  {tableData.columns.find(
                    column =>
                      column.identifier ===
                      PackagesReceivedAndReleasedPerMonthIdentifiers.RECEIVED_INDEXES,
                  )?.name || ""}
                </Typography>
              ),
              size: 65,
              cell: info => (
                <Typography className="text-right w-100" fontSize="12" fontWeight="600">
                  {info.getValue() || "---"}
                </Typography>
              ),
            }),
            columnHelper.accessor(row => row.RECEIVED_PACKAGES.value, {
              id: "RECEIVED_PACKAGES",
              header: () => (
                <Typography
                  className="text-right w-100"
                  color="neutralBlack48"
                  fontSize="12"
                  fontWeight="400"
                >
                  {tableData.columns.find(
                    column =>
                      column.identifier ===
                      PackagesReceivedAndReleasedPerMonthIdentifiers.RECEIVED_PACKAGES,
                  )?.name || ""}
                </Typography>
              ),
              size: 65,
              cell: info => (
                <Typography className="text-right w-100" fontSize="12" fontWeight="600">
                  {info.getValue() || "---"}
                </Typography>
              ),
            }),
            columnHelper.accessor(row => row.RECEIVED_PACKAGES_PER_DAY_AVERAGE.value, {
              id: "RECEIVED_PACKAGES_PER_DAY_AVERAGE",
              header: () => (
                <Typography
                  className="text-right w-100"
                  color="neutralBlack48"
                  fontSize="12"
                  fontWeight="400"
                >
                  {tableData.columns.find(
                    column =>
                      column.identifier ===
                      PackagesReceivedAndReleasedPerMonthIdentifiers.RECEIVED_PACKAGES_PER_DAY_AVERAGE,
                  )?.name || ""}
                </Typography>
              ),
              size: 80,
              cell: info => (
                <Typography className="text-right w-100" fontSize="14" fontWeight="700">
                  {info.getValue() || "---"}
                </Typography>
              ),
            }),
          ],
        }),
        columnHelper.group({
          id: "Released",
          header: () => (
            <Typography fontSize="14" fontWeight="700">
              Wydania
            </Typography>
          ),
          columns: [
            columnHelper.accessor(row => row.RELEASED_INDEXES.value, {
              id: "RELEASED_INDEXES",
              header: () => (
                <Typography
                  className="text-right w-100"
                  color="neutralBlack48"
                  fontSize="12"
                  fontWeight="400"
                >
                  {tableData.columns.find(
                    column =>
                      column.identifier ===
                      PackagesReceivedAndReleasedPerMonthIdentifiers.RELEASED_INDEXES,
                  )?.name || ""}
                </Typography>
              ),
              size: 65,
              cell: info => (
                <Typography className="text-right w-100" fontSize="12" fontWeight="600">
                  {info.getValue() || "---"}
                </Typography>
              ),
            }),
            columnHelper.accessor(row => row.RELEASED_PACKAGES.value, {
              id: "RELEASED_PACKAGES",
              header: () => (
                <Typography
                  className="text-right w-100"
                  color="neutralBlack48"
                  fontSize="12"
                  fontWeight="400"
                >
                  {tableData.columns.find(
                    column =>
                      column.identifier ===
                      PackagesReceivedAndReleasedPerMonthIdentifiers.RELEASED_PACKAGES,
                  )?.name || ""}
                </Typography>
              ),
              size: 65,
              cell: info => (
                <Typography className="text-right w-100" fontSize="12" fontWeight="600">
                  {info.getValue() || "---"}
                </Typography>
              ),
            }),
            columnHelper.accessor(row => row.RELEASED_PACKAGES_PER_DAY_AVERAGE.value, {
              id: "RELEASED_PACKAGES_PER_DAY_AVERAGE",
              header: () => (
                <Typography
                  className="text-right w-100"
                  color="neutralBlack48"
                  fontSize="12"
                  fontWeight="400"
                >
                  {tableData.columns.find(
                    column =>
                      column.identifier ===
                      PackagesReceivedAndReleasedPerMonthIdentifiers.RELEASED_PACKAGES_PER_DAY_AVERAGE,
                  )?.name || ""}
                </Typography>
              ),
              size: 80,
              cell: info => (
                <Typography className="text-right w-100" fontSize="14" fontWeight="700">
                  {info.getValue() || "---"}
                </Typography>
              ),
            }),
          ],
        }),
        columnHelper.accessor(row => row.REST_DAYS_COUNT.value, {
          id: "REST_DAYS_COUNT",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              {tableData.columns.find(
                column =>
                  column.identifier ===
                  PackagesReceivedAndReleasedPerMonthIdentifiers.REST_DAYS_COUNT,
              )?.name || ""}
            </Typography>
          ),
          size: 60,
          cell: info => (
            <Typography className="text-right w-100" fontSize="12" fontWeight="400">
              {info.getValue() || "---"}
            </Typography>
          ),
        }),
      ];
    }
  }, [tableData]);
};

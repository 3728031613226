import { useEffect, useState } from "react";
import { usePrevious } from "./hooks";

/**
 * Hook returns an integer, which increments when the condition function returns true.
 * It can be used to reset a component when some condition occurs by giving the result
 * of this function as a [key] prop.
 * @example
 * const resetKey = useTrigger(products.length, (prev: number, current: number) => prev < current);
 */
export function useTrigger<Val extends any>(
  value: Val,
  condition: (oldValue: Val, newValue: Val) => boolean = (a, b) => a !== b,
) {
  const previousValue = usePrevious<Val>(value);
  const [resetKey, setResetKey] = useState(0);
  useEffect(() => {
    if (condition(previousValue, value)) {
      setResetKey(s => s + 1);
    }
    // eslint-disable-next-line
  }, [previousValue, value]);
  return resetKey;
}

import { addManufacturingWorkingUnit } from "api/new-production-plans/calls";
import { productionPlansKeys } from "api/new-production-plans/keys";
import {
  AddManufacturingWorkingUnit,
  ProductionPlan,
  ShelfManufacturingItem,
} from "api/new-production-plans/models";
import { Pagination } from "api/types";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Assign } from "utility-types";
import { handleManufacturingWorkingUnitAssignmentRollback } from "../utils/handleManufacturingWorkingUnitAssignmentRollback";
import { isCategorySummaryAlreadyAdded } from "../utils/isCategorySummaryAlreadyAdded";
import { isSourceAlreadyInSourceBar } from "../utils/isSourceAlreadyInSourceBar";

export const useAddManufacturingWorkingUnit = (keyStrings: Record<string, string>) => {
  return useMutation(
    ({
      employeeWorkingDay,
      manufacturingWorkingUnit,
      stageManufacturingPlan,
      sourceId,
    }: Assign<AddManufacturingWorkingUnit, { sourceId: string | number }>) => {
      return addManufacturingWorkingUnit({
        employeeWorkingDay,
        manufacturingWorkingUnit,
        stageManufacturingPlan,
      });
    },
    ({ queryClient, toastr }) => ({
      onMutate: args => {
        const prevProductionPlanSchedule = queryClient.getQueryData<ProductionPlan>(
          productionPlansKeys.productionPlanSchedule(String(args.stageManufacturingPlan)),
        );

        const shelfGroupKeyString =
          keyStrings && keyStrings[args.sourceId] ? keyStrings[args.sourceId] : "";
        const prevSourceShelfGroupList = queryClient.getQueryData<
          Pagination<ShelfManufacturingItem>
        >(productionPlansKeys.shelfItemDetails(shelfGroupKeyString));

        if (args.stageManufacturingPlan && prevSourceShelfGroupList) {
          const draggableItemToAdd = prevSourceShelfGroupList.results.find(
            item => item.id === args.manufacturingWorkingUnit,
          );
          // Add MWU to EmployeeWorkingDay's list of MWU's
          queryClient.setQueryData<ProductionPlan>(
            productionPlansKeys.productionPlanSchedule(String(args.stageManufacturingPlan)),
            currentPlan => {
              assertIsDefined(currentPlan);
              return {
                ...currentPlan,
                employees: currentPlan.employees.map(employee => {
                  const destinationEmployee = employee.employeeWorkingDays.find(
                    employeeWorkingDay => employeeWorkingDay.id === args.employeeWorkingDay,
                  );
                  if (destinationEmployee) {
                    return {
                      ...employee,
                      employeeWorkingDays: employee.employeeWorkingDays.map(employeeWorkingDay => {
                        if (employeeWorkingDay.id === args.employeeWorkingDay) {
                          const alreadyAddedManufacturingWorkingUnit = employeeWorkingDay.manufacturingWorkingUnits.find(
                            manufacturingWorkingUnit =>
                              manufacturingWorkingUnit.id === args.manufacturingWorkingUnit,
                          );

                          if (alreadyAddedManufacturingWorkingUnit) {
                            toastr.open({
                              type: "warning",
                              title: "Wymagane działanie",
                              text: "Pozycja została już dodana",
                            });
                          }

                          if (alreadyAddedManufacturingWorkingUnit === undefined) {
                            const draggableItemToAdd = prevSourceShelfGroupList.results.find(
                              item => item.id === args.manufacturingWorkingUnit,
                            );

                            if (draggableItemToAdd) {
                              return {
                                ...employeeWorkingDay,
                                categorySummary: isCategorySummaryAlreadyAdded(
                                  draggableItemToAdd.index.category,
                                  employeeWorkingDay.categorySummary,
                                )
                                  ? employeeWorkingDay.categorySummary.map(categorySummary => {
                                      if (
                                        categorySummary.code ===
                                          draggableItemToAdd.index.category.code &&
                                        categorySummary.color ===
                                          draggableItemToAdd.index.category.color &&
                                        categorySummary.name ===
                                          draggableItemToAdd.index.category.name
                                      ) {
                                        return {
                                          ...categorySummary,
                                          slots: categorySummary.slots + 1,
                                        };
                                      }
                                      return categorySummary;
                                    })
                                  : [
                                      ...employeeWorkingDay.categorySummary,
                                      {
                                        code: draggableItemToAdd.index.category.code,
                                        color: draggableItemToAdd.index.category.color,
                                        name: draggableItemToAdd.index.category.name,
                                        slots: 1,
                                      },
                                    ],
                                manufacturingWorkingUnits: [
                                  ...employeeWorkingDay.manufacturingWorkingUnits,
                                  {
                                    ...draggableItemToAdd,
                                    manufacturingWorkingUnitEmployeeWorkingDayId: "",
                                  },
                                ],
                              };
                            }
                          }
                        }
                        return employeeWorkingDay;
                      }),
                    };
                  }
                  return employee;
                }),
                slotsSummary: {
                  total: currentPlan.slotsSummary.total,
                  taken: currentPlan.slotsSummary.taken + 1,
                },
                sourcesSummary:
                  draggableItemToAdd &&
                  !isSourceAlreadyInSourceBar(draggableItemToAdd.source, currentPlan.sourcesSummary)
                    ? [
                        ...currentPlan.sourcesSummary,
                        {
                          color: draggableItemToAdd.source.color,
                          id: draggableItemToAdd.source.id,
                          signature: draggableItemToAdd.source.signature,
                        },
                      ]
                    : currentPlan.sourcesSummary,
              };
            },
          );

          // Remove MWU from shelf's group
          queryClient.setQueryData<Pagination<ShelfManufacturingItem>>(
            productionPlansKeys.shelfItemDetails(shelfGroupKeyString),
            currentList => {
              assertIsDefined(currentList);
              return {
                ...currentList,
                results: currentList.results.filter(
                  shelfManufacturingItem =>
                    shelfManufacturingItem.id !== args.manufacturingWorkingUnit,
                ),
              };
            },
          );
        }

        return { prevProductionPlanSchedule, prevSourceShelfGroupList, shelfGroupKeyString };
      },
      onSuccess: (payload, variables, context) => {
        const { shelfGroupKeyString } = context as {
          shelfGroupKeyString: string;
        };

        if (variables.stageManufacturingPlan) {
          queryClient.setQueryData<ProductionPlan>(
            productionPlansKeys.productionPlanSchedule(String(variables.stageManufacturingPlan)),
            currentPlan => {
              assertIsDefined(currentPlan);
              return {
                ...currentPlan,
                employees: currentPlan.employees.map(employee => {
                  const destinationEmployee = employee.employeeWorkingDays.find(
                    employeeWorkingDay => employeeWorkingDay.id === variables.employeeWorkingDay,
                  );
                  if (destinationEmployee) {
                    return {
                      ...employee,
                      employeeWorkingDays: employee.employeeWorkingDays.map(employeeWorkingDay => {
                        if (employeeWorkingDay.id === variables.employeeWorkingDay) {
                          return {
                            ...employeeWorkingDay,
                            manufacturingWorkingUnits: employeeWorkingDay.manufacturingWorkingUnits.map(
                              manufacturingWorkingUnit => {
                                if (
                                  manufacturingWorkingUnit.id === variables.manufacturingWorkingUnit
                                ) {
                                  return {
                                    ...manufacturingWorkingUnit,
                                    manufacturingWorkingUnitEmployeeWorkingDayId: payload.id,
                                  };
                                }
                                return manufacturingWorkingUnit;
                              },
                            ),
                          };
                        }
                        return employeeWorkingDay;
                      }),
                    };
                  }
                  return employee;
                }),
              };
            },
          );
        }

        queryClient.invalidateQueries(
          productionPlansKeys.productionPlanSchedule(String(variables.stageManufacturingPlan)),
        );

        queryClient.invalidateQueries(shelfGroupKeyString);
      },
      onError: (error, variables, context) => {
        const {
          prevProductionPlanSchedule,
          prevSourceShelfGroupList,
          shelfGroupKeyString,
        } = context as {
          prevProductionPlanSchedule: ProductionPlan;
          prevSourceShelfGroupList: Pagination<ShelfManufacturingItem>;
          shelfGroupKeyString: string;
        };
        handleManufacturingWorkingUnitAssignmentRollback(
          prevProductionPlanSchedule,
          prevSourceShelfGroupList,
          variables.stageManufacturingPlan,
          shelfGroupKeyString,
          queryClient,
        );
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

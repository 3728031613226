import { ManufacturingPlan } from "api/manufacturingNew/models";
import { RightPanelSection, RightPanelTableRow } from "components/utils/drawer";
import { daysListConfig } from "../../utils/panelTablesConfig";
import { daysOfTheWeek } from "CONSTANTS";

export const Days = ({ manufacturingPlan }: { manufacturingPlan: ManufacturingPlan }) => {
  return (
    <RightPanelSection className="fs-12 fw-700" title="Dni">
      <RightPanelTableRow className="tableHeaderGreyBg" hover={false} grid={daysListConfig.grid}>
        <div>#</div>
        <div>dzień</div>
        <div>liczba zleceń</div>
        <div>pozostało</div>
        <div>kompletnych zamówień</div>
      </RightPanelTableRow>

      {manufacturingPlan.days.map((day, index) => {
        return (
          <RightPanelTableRow key={day.date + index} grid={daysListConfig.grid}>
            <div className="fs-12 fw-700">{index + 1}.</div>
            <div className="fs-12 fw-700 lowercase">{daysOfTheWeek[day.dayOfTheWeek]}</div>
            <div className="fs-12 fw-700">{day.slots.total}</div>
            <div className="fs-12 fw-700">{day.slots.total - day.slots.taken} miejsc</div>
            <div className="fs-12 fw-700">{day.slots.finished}</div>
          </RightPanelTableRow>
        );
      })}
    </RightPanelSection>
  );
};

import { useReleasedPerCategory } from "api/wms/hooks";
import { WarehouseWorkerDetails } from "api/wms/models";
import { Table } from "components/miloDesignSystem/molecules/table";
import { RightPanelSection } from "components/utils/drawer";
import { queryString } from "utilities";
import styles from "../../RightPanel.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useReleasedPerCategory as useReleasedPerCategoryColumns } from "./useReleasedPerCategory";
import { useWarehouseWorkerFilters } from "../../hooks/useWarehouseWorkerFilters";
import { useGenericTableFilters } from "components/common/genericTable/useGenericTableFilters";
import { GenericTable } from "components/common/genericTable/GenericTable";
import { detailsListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { parseTableRowData } from "components/common/genericTable/utils";

interface Props {
  warehouseWorker: WarehouseWorkerDetails;
}

export const ReleasedPerCategory = ({ warehouseWorker }: Props) => {
  const warehouseWorkerFilters = useWarehouseWorkerFilters(warehouseWorker);
  const { setDateFrom, setRowBy, filters } = useGenericTableFilters();
  const search = queryString.stringify({ ...filters, ...warehouseWorkerFilters.filters });
  const { data: releasedPerCategory, error, isLoading, isFetching } = useReleasedPerCategory(
    search,
    { keepPreviousData: true },
  );

  const columns = useReleasedPerCategoryColumns(releasedPerCategory, filters);
  const normalizedRows = parseTableRowData(releasedPerCategory);

  return (
    <RightPanelSection padding="pl-0 pr-0">
      <div className={styles.sectionTitle}>
        <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
          Wydania w podziale na kategorie
        </Typography>
        <GenericTable.RowBySelect rowBy={filters.rowBy} setRowBy={setRowBy} />
      </div>
      <div className="pt-1">
        <Table
          rows={normalizedRows}
          //@ts-ignore
          columns={columns}
          error={error}
          isLoading={isLoading || isFetching}
          uiSchema={detailsListUiSchema}
        />
      </div>
      <GenericTable.Footer rowBy={filters.rowBy} setDateFrom={setDateFrom} />
    </RightPanelSection>
  );
};

import styles from "./SearchInput.module.css";
import removeIcon from "assets/images/11.svg";
import cx from "classnames";

interface Props {
  onChange: (value: string) => void;
  className?: string;
  value: string;
}

export const SearchInput: React.FC<Props> = ({ onChange, value, className }) => {
  return (
    <div className={cx(styles.inputContainer, className)}>
      <input
        value={value}
        onChange={e => onChange(e.target.value)}
        className={styles.input}
        placeholder="Szukaj..."
      />
      {value && (
        <img className={styles.inputIcon} src={removeIcon} alt="" onClick={() => onChange("")} />
      )}
    </div>
  );
};

export const grey = {
  grey25: "#F5F5F5",
  grey50: "#EDEDED",
  grey75: "#E0E0E0",
  grey100: "#D6D6D6",
  grey200: "#CCCCCC",
  grey300: "#C2C2C2",
  grey400: "#ADADAD",
  grey500: "#858585",
  grey600: "#5C5C5C",
  grey700: "#3D3D3D",
  grey800: "#1F1F1F",
};

import {
  ReminderType,
  statusColorDictionary,
  statusDictionary,
  whenStatusDictionary,
} from "api/reminders/models";
import { dateFns } from "utilities";
import styles from "../RemindersSection.module.css";
import cx from "classnames";

export const Reminder = ({
  index,
  date,
  daysDelta,
  status,
  when,
}: { index: number } & ReminderType) => {
  const dateFormatted = dateFns.format(new Date(date), "dd.MM.yyyy", {});
  return (
    <div className={cx(styles.tableRow, styles.tableRowReminder)}>
      <div>{index}.</div>
      <div>
        <div className={styles.reminderNo}>przypomnienie nr {index}</div>
      </div>
      <div className={styles.date}>{dateFormatted}</div>
      <div>{daysDelta} dni</div>
      <div className="nowrap">{whenStatusDictionary[when]}</div>

      <div className={statusColorDictionary[status]}>{statusDictionary[status]}</div>
    </div>
  );
};

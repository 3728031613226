import { useState, useEffect } from "react";
import { ApiMiddlewareResult } from "apiConnectors/fetchConnector";
import { Checkbox } from "components/utils";
// import styles from "./EditableCheckbox.module.css";
import cx from "classnames";

interface Props<N> {
  name: keyof N;
  submitFunc: (data: { [P in keyof N]: boolean }) => ApiMiddlewareResult<any>;
  initialValue: boolean;
  label?: string;
  disabled?: boolean;
}

export function EditableCheckbox<N>({
  name,
  label = "",
  submitFunc,
  initialValue,
  disabled,
}: Props<N>) {
  const [fetching, setFetching] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [lastSavedValue, setLastSavedValue] = useState(initialValue);

  const [error, setError] = useState<null | { [key: string]: any }>(null);

  useEffect(() => {
    if (fetching === true) {
      setError(null);
    }
  }, [fetching]);

  const handleChange = async (value: boolean) => {
    setValue(value);
    setFetching(true);
    const [, error] = await submitFunc({ [name]: value } as any);
    if (error) {
      setError(error);
      setValue(lastSavedValue);
    } else {
      setLastSavedValue(value);
    }
    setFetching(false);
  };

  return (
    <div className={cx({ "was-validated": Boolean(error) })}>
      <Checkbox
        name={name as string}
        label={label}
        checked={value}
        onChange={handleChange}
        disabled={fetching || disabled}
      />

      <span className="invalid-feedback">
        {error && (error[name as string] || "Nie udało się zapisać")}
      </span>
    </div>
  );
}

import { queryString } from "utilities";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  tab: string;
};

export const getSearch = ({ query, tab }: GetSearchProps) => {
  const tabsQuery = {
    isActive: tab === "active" ? true : tab === "deactivated" ? false : "",
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

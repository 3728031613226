import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import styles from "../../RightPanel.module.css";
import {
  ExpectedPaymentForm,
  InvoiceType,
  TradingDocumentType,
  expectedPaymentFormDict,
} from "api/trading-documents/models";
import { UUID } from "api/types";
import { cx } from "utilities";
import { AsyncInput } from "components/utils";

interface Props {
  expectedPaymentForm: ExpectedPaymentForm;
  id: UUID;
  invoiceType: InvoiceType;
  type: TradingDocumentType;
}

export const PaymentForm = ({ expectedPaymentForm, id, invoiceType, type }: Props) => {
  const expectedPaymentFormMutation = usePatchTradingDocumentMutation();

  if (invoiceType !== "SALES" && type !== "RECEIPT" && Boolean(expectedPaymentForm))
    return (
      <div>
        <span
          className={cx("body-14-500", {
            [styles.fontStyle]: expectedPaymentForm !== "CASH" && expectedPaymentForm !== "ONLINE",
          })}
        >
          {expectedPaymentForm === "CASH" || expectedPaymentForm === "ONLINE"
            ? expectedPaymentFormDict[expectedPaymentForm]
            : expectedPaymentForm}
        </span>
      </div>
    );

  if (invoiceType === "SALES")
    return (
      <div>
        <AsyncInput
          disabled={expectedPaymentFormMutation.isLoading}
          inProgress={expectedPaymentFormMutation.isLoading}
          onChange={value =>
            expectedPaymentFormMutation.mutate({
              id,
              expectedPaymentForm: value,
            })
          }
          overwrites={{
            container: { className: styles.input },
            input: {
              className: cx(styles.input, {
                italic: expectedPaymentForm !== "CASH" && expectedPaymentForm !== "ONLINE",
              }),
            },
          }}
          placeholder="forma płatności"
          value={
            expectedPaymentForm === "CASH" || expectedPaymentForm === "ONLINE"
              ? expectedPaymentFormDict[expectedPaymentForm]
              : expectedPaymentForm
          }
        />
      </div>
    );

  return <EmptyValue className="italic" fontSize="14" fontWeight="500" />;
};

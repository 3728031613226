import { createContext, useContext } from "react";
import PropTypes from "prop-types";
import styles from "./radioLabels.module.css";
import cx from "classnames";

const Context = createContext(false);

/**
 * @callback onChange
 * @param {{name: string, value: number | string | null}} val
 */

/**
 * @callback isDisabled
 * @param {any} val
 */

/**
 *
 * @param {Object} props
 * @param {string=} props.label
 * @param {string | number | null=} props.value
 * @param {any[]=} props.values
 * @param {onChange} props.onChange
 * @param {array} props.items
 * @param {string} props.name
 * @param {element=} props.children
 * @param {isDisabled=} props.isDisabled
 * @param {boolean=} props.isNewLayout
 * @param {boolean=} props.allowUncheck
 * @param {Object=} props.overrides
 * @param {Object=} props.overrides.wrapper
 * @param {string=} props.overrides.wrapper.className
 * @param {Object=} props.overrides.option
 * @param {string=} props.overrides.option.className
 * @param {Object=} props.overrides.label
 * @param {string=} props.overrides.label.className
 * @example
 *
<RadioLabels
  label={trans.brand}
  items={state.brands}
  name="brand"
  onChange={val => handle(val)}
  value={Number(query.brand)}
/>
OR
<RadioLabels label={trans.category}>
  {state.categories.map(category => (
    <Label
      key={category.id}
      label={category.name}
      name="category"
      value={category.id}
      checked={Number(query.category) === category.id}
      onChange={val => handle(val)}
    />
  ))}
</RadioLabels>
 */
const RadioLabels = ({
  label,
  value,
  values,
  onChange,
  items,
  name,
  children,
  multiple,
  isNewLayout = false,
  isDisabled,
  allowUncheck = true,
  overrides = {},
}) => {
  function checkIfChecked(id) {
    return values ? values.includes(id) : id === value;
  }
  return (
    <div className="fieldset">
      {label && <div className={styles.fieldsetLabel}>{label}</div>}
      <div className={cx("d-flex flex-wrap", overrides?.wrapper?.className, {
        "mt-2": overrides?.wrapper === undefined,
      })}>
        <Context.Provider value={multiple}>
          {children}
          {items.map(item => (
            <Label
              key={item.id}
              name={name}
              label={item.name}
              value={item.id}
              checked={checkIfChecked(item.id)}
              isNewLayout={isNewLayout}
              onChange={onChange}
              item={item}
              disabled={isDisabled ? isDisabled(item) : undefined}
              allowUncheck={allowUncheck}
              overrides={overrides}
            />
          ))}
        </Context.Provider>
      </div>
    </div>
  );
};
RadioLabels.defaultProps = {
  items: [],
  multiple: false,
};
RadioLabels.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  multiple: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  items: PropTypes.array.isRequired,
  isNewLayout: PropTypes.bool,
  name: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  isDisabled: PropTypes.func,
  allowUncheck: PropTypes.bool,
};

export const Label = ({
  label,
  name,
  value,
  checked,
  onChange,
  allowUncheck,
  item,
  isNewLayout,
  disabled,
  type,
  img,
  className,
  variant = "fill",
  overrides = {},
}) => {
  const multiple = useContext(Context);
  
  return (
    <label
      className={cx(className, "option d-inline-flex p0", styles.label, overrides?.option?.className, styles[variant], {
        "mr-2": overrides?.option === undefined,
        [styles.disabled]: disabled,
      })}
    >
      <input
        type={multiple ? "checkbox" : "radio"}
        name={name}
        value={value ?? ""}
        checked={checked}
        disabled={disabled}
        onClick={() => {
          if (multiple || disabled) return;
          if (checked && !allowUncheck) return;
          if (checked) {
            onChange({ name, value: null }, item);
          }
        }}
        onChange={() => onChange({ name, value }, item)}
        className={isNewLayout ? styles.newInput : styles.input}
      />
      {type === "image" ? (
        <div className={cx(styles.radiomark, overrides?.label?.className)}>
          <img src={img} alt="" title={label} />
          <span>{label}</span>
        </div>
      ) : (
        <span className={cx(styles.radiomark, overrides?.label?.className)}>{label}</span>
      )}
    </label>
  );
};

Label.defaultProps = {
  checked: false,
  allowUncheck: true,
};
Label.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  allowUncheck: PropTypes.bool.isRequired,
  isNewLayout: PropTypes.bool,
  item: PropTypes.shape(),
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(["text", "image"]),
  img: PropTypes.string,
  className: PropTypes.string,
};
RadioLabels.Label = Label;
export default RadioLabels;

import { useGeocoder } from "./useGeocoder";
import { useState, useEffect, useRef } from "react";
import { tuplify } from "utilities";
import { throttle as throttleFunc } from "throttle-debounce";

export type GoogleGeocodeStatus =
  | "OK"
  | "ZERO_RESULTS"
  | "OVER_DAILY_LIMIT"
  | "OVER_QUERY_LIMIT"
  | "REQUEST_DENIED"
  | "INVALID_REQUEST"
  | "UNKNOWN_ERROR";

export type GoogleGeocodeResult = {
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
  formatted_address: string;
  geometry: {
    location: {
      lat(): number;
      lng(): number;
    };
  };
};
type State =
  | { result: GoogleGeocodeResult; status: "OK"; inProgress: boolean }
  | { result: null; status: Exclude<GoogleGeocodeStatus, "OK"> | ""; inProgress: boolean };

export function useGeocodedAddress(
  search: { address?: string; latLng?: { lat: number; lng: number } },
  callback?: (payload: State) => void,
) {
  const [result, setResult] = useState<State>({ result: null, status: "", inProgress: false });
  const geocoder = useGeocoder();
  const callbackRef = useRef(callback);
  const throttled = useRef<any>(throttleFunc(2000, geocoder.geocode));

  useEffect(() => {
    if (!search) return;
    if (!search.address && !search.latLng) return;
    setResult(s => ({ ...s, inProgress: true }));
    throttled.current(search, (payload: any, status: GoogleGeocodeStatus) => {
      const result = { result: payload?.[0] || null, status, inProgress: false };
      setResult(result);
      if (callbackRef.current) {
        callbackRef.current(result);
      }
    });
  }, [search, geocoder]);

  return tuplify(result.result, { status: result.status, inProgress: result.inProgress });
}

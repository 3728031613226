import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";
import { Tab } from "./models";

export const settingsKeys = Object.keys(Tab).reduce((acc, tab) => {
  Object.assign(acc, { [tab]: [tab + "Settings"] });
  return acc;
}, {} as Record<keyof typeof Tab, string[]>);

export const exchangeRatesSettingsKeys = {
  exchangeRates: {
    list: createPaginatedKey("currenciesToWatch"),
    details: (id: string) => ["exchangeRatesSettings", id],
  },
};

export const wmsSettingsKeys = {
  categoriesVisibleInTable: {
    list: createPaginatedKey("categoriesVisibleInTable"),
  },
};

export const pdfSettingsKeys = {
  pdfs: {
    details: () => ["rootPdfs"],
  },
};

export const tradingDocumentsSettings = {
  tradingDocuments: {
    details: (id: UUID) => ["tradingDocumentsSettings", id],
  },
};

export const communicationSettingsKeys = {
  dedicatedNumber: {
    list: createPaginatedKey("dedicatedPhoneNumbers"),
  },
};

export const remindersSettingsKeys = {
  reminders: {
    list: createPaginatedKey("remindersSettings"),
    customersToRemind: createPaginatedKey("customersToRemind"),
    remindersSchedule: createPaginatedKey("remindersSchedule"),
  },
};

import { CommonError, Modal, Spinner } from "components/utils";
import { useIndex } from "api/warehouse/hooks";
import styles from "./AvailabilityDetailsModal.module.css";
import { useStateModal } from "hooks";
import stateZeroIcon from "assets/images/165.svg";
import stateBelowIcon from "assets/images/166.svg";
import stateMinIcon from "assets/images/167.svg";
import stateSafeIcon from "assets/images/168.svg";
import stateMaxIcon from "assets/images/169.svg";
import cx from "classnames";

interface Props {
  stateModal: ReturnType<typeof useStateModal>;
}

const iconsMap = {
  ZERO: stateZeroIcon,
  BELOW: stateBelowIcon,
  MIN: stateMinIcon,
  SAFE: stateSafeIcon,
  MAX: stateMaxIcon,
};

export const AvailabilityDetailsModal = ({ stateModal }: Props) => {
  const [index, { error, httpStatus }] = useIndex(Number(stateModal.state), {
    skip: !stateModal.state,
    clearSkip: true,
  });

  if (error) {
    return <CommonError status={httpStatus} />;
  }

  return (
    <Modal isOpen={stateModal.isOpen} close={stateModal.close} size={{ all: { width: "50vw" } }}>
      <div className={styles.container}>
        {!index ? (
          <Spinner
            style={{ height: "90%", width: "92%" }}
            color="blue"
            size="big"
            text="trwa wczytywanie"
            position="absolute"
          />
        ) : (
          <>
            <h4 className="mb-5 font-weight-bold">Dostępność produktu</h4>
            <div className="d-flex ">
              <div className={styles.miniature}>
                <img src={index.picture} alt="" />
              </div>
              <div>
                <div className={styles.indexName}>{index.name}</div>
                <div className="d-flex flex-wrap mb-2">
                  {index.attributes &&
                    index.attributes.map(({ value, attribute }, index) => (
                      <div className={styles.attribute} key={index}>
                        <span>{attribute}: </span>
                        <strong>{value}</strong>
                      </div>
                    ))}
                </div>
                <div className="d-flex align-items-center mb-2">
                  <div className={styles.indexCode}>{index.internalId}</div>
                  <div className={styles.producer}>{index.manufacturerName}</div>
                </div>
              </div>
            </div>
            <div className={cx(styles.table)}>
              <div className={cx(styles.tableRow, "py-3 align-items-start")}>
                <div className={styles.tableCell}></div>
                <div className={cx(styles.tableCell, "justify-content-end")}>
                  <div>
                    <div className={cx(styles.label, "d-flex justify-content-end")}>stan</div>
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <strong className={cx(styles.totalQuantity, "mr-1")}>
                          {index.currentState}
                        </strong>
                        <span className={styles.unit}>{index.unit}</span>
                      </div>
                      <div className={styles.stateImg}>
                        <img src={iconsMap[index.availabilityStatus]} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cx(styles.tableCell, "justify-content-end")}>
                  <div>
                    <div className={cx(styles.label, "d-flex justify-content-end")}>dostępne</div>
                    <div className={styles.subTotalQuantity}>
                      <strong className="mr-1">{index.available}</strong>
                      <span className={styles.unit}>{index.unit}</span>
                    </div>
                  </div>
                </div>
                <div className={cx(styles.tableCell, "justify-content-end")}>
                  <div>
                    <div className={cx(styles.label, "d-flex justify-content-end")}>zarezerw.</div>
                    <div className={styles.subTotalQuantity}>
                      <strong className="mr-1">{index.reserved}</strong>
                      <span className={styles.unit}>{index.unit}</span>
                    </div>
                  </div>
                </div>
                <div className={cx(styles.tableCell, "justify-content-end")}>
                  <div>
                    <div className={cx(styles.label, "d-flex justify-content-end")}>
                      liczba paczek
                    </div>
                    <div className={styles.subTotalQuantity}>
                      <strong className="mr-1">{index.numberOfPackages}</strong>
                      <span className={styles.unit}>{index.unit}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

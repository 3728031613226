import {
  ManufacturingWorkingUnit,
  SelectedSource,
  ShelfManufacturingItem,
} from "api/new-production-plans/models";
import cx from "classnames";
import { Button } from "components/common";
import styles from "pages/productionPlans/productionPlanDetails/ProductionPlanDetails.module.css";
import localStyles from "./ManufacturingItem.module.css";
import threeDotsIcon from "assets/images/more_horiz.svg";
import awaitingIcon from "assets/images/awaits.svg";
import doneIcon from "assets/images/done.svg";
import removeIcon from "assets/images/x.svg";
import inProgressIcon from "assets/images/inprogress.svg";
import { Draggable } from "react-beautiful-dnd";
import { useRemoveManufacturingWorkingUnit } from "pages/productionPlans/productionPlanDetails/hooks/useRemoveManufacturingWorkingUnit";
import { UUID } from "api/types";
import { noop } from "utilities";
import { isSourceSelected } from "pages/productionPlans/productionPlanDetails/utils/isSourceSelected";
import ReactTooltip from "react-tooltip";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";

interface Props {
  employeeWorkingDayId?: UUID;
  index: number;
  keyStrings?: Record<string, string>;
  manufacturingItem: ManufacturingWorkingUnit | ShelfManufacturingItem;
  manufacturingWorkingUnitEmployeeWorkingDayId?: UUID;
  productionPlanId?: UUID;
  selectedSources: SelectedSource[];
  source: "SHELF" | "EMPLOYEE_WORKING_DAY";
}

const stageStatus = {
  NOT_STARTED: "nierozpoczęty",
  IN_PROGRESS: "w toku",
  FINISHED: "zakończony",
};

export const ManufacturingItem = ({
  employeeWorkingDayId,
  index,
  keyStrings,
  manufacturingItem,
  manufacturingWorkingUnitEmployeeWorkingDayId,
  productionPlanId,
  selectedSources,
  source,
}: Props) => {
  const deleteManufacturingWorkingUnit = useRemoveManufacturingWorkingUnit(keyStrings ?? null);

  const attributesList = manufacturingItem.index.attributesValues
    ? formatAttributes(manufacturingItem.index.attributesValues)
    : [];

  const categoryCode = () => {
    if (manufacturingItem.index.category.code) {
      return <>{manufacturingItem.index.category.code}</>;
    } else if (!manufacturingItem.index.category.code && manufacturingItem.index.category.name) {
      return <>{manufacturingItem.index.category.name.slice(0, 2).toUpperCase()}</>;
    }
    return <>BK</>;
  };

  return (
    <Draggable
      draggableId={manufacturingItem.id}
      key={manufacturingItem.id}
      index={index}
      isDragDisabled={false}
    >
      {(provided, snapshot) => {
        return (
          <div
            className={cx(localStyles.item, {
              [localStyles.itemStatusInProgress]: manufacturingItem.status === "IN_PROGRESS",
              [localStyles.itemStatusDone]: manufacturingItem.status === "DONE",
              [localStyles.packageIsDragged]: snapshot.isDragging,
            })}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              width: snapshot.isDragging ? "531px" : "100%",
              borderColor: isSourceSelected(
                manufacturingItem.source.id,
                selectedSources,
                manufacturingItem.source.signature,
              )
                ? `${manufacturingItem.source.color}`
                : "",
              ...provided.draggableProps.style,
            }}
          >
            <div className={cx(localStyles.itemInnerBox)}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center w-100 mr-2">
                  <div
                    className={cx(localStyles.itemSlot, "mr-1")}
                    style={{
                      background: manufacturingItem.index.category.color
                        ? manufacturingItem.index.category.color
                        : "#000",
                      color: "#fff",
                    }}
                  >
                    {categoryCode()}
                  </div>
                  <div className={localStyles.itemNameBox}>
                    <div
                      className={localStyles.itemName}
                      data-tip
                      data-for={`manufacturing-working-unit-item-name-${manufacturingItem.id}`}
                    >
                      {manufacturingItem.index.name}
                    </div>
                    <ReactTooltip
                      className={styles.customTooltip}
                      id={`manufacturing-working-unit-item-name-${manufacturingItem.id}`}
                      place="top"
                      effect="solid"
                      arrowColor="transparent"
                      offset={{ top: -10 }}
                    >
                      {manufacturingItem.index.name}
                    </ReactTooltip>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-1">
                  {manufacturingItem.visiblePreviousStages.slice(0, 5).map(stage => {
                    return (
                      <Tooltip title={`${stage.name}, ${stageStatus[stage.status]}`}>
                        <div
                          className={cx(localStyles.stageStatusLabel)}
                          style={{
                            background:
                              stage.status !== "NOT_STARTED"
                                ? stage.backgroundColor
                                : "transparent",
                            color: stage.textColor,
                            border:
                              stage.status === "NOT_STARTED" ? `1px solid ${stage.textColor}` : "",
                          }}
                        >
                          {stage.code ? (
                            <span>{stage.code}</span>
                          ) : (
                            <span>{stage.name.slice(0, 2).toUpperCase()}</span>
                          )}
                          {stage.status === "NOT_STARTED" && (
                            <img alt="nierozpoczęty" src={awaitingIcon} />
                          )}
                          {stage.status === "IN_PROGRESS" && (
                            <img alt="w toku" src={inProgressIcon} />
                          )}
                          {stage.status === "FINISHED" && <img alt="zakończony" src={doneIcon} />}
                        </div>
                      </Tooltip>
                    );
                  })}
                  {manufacturingItem.visiblePreviousStages.length > 5 && (
                    <div className={localStyles.moreStagesCounter}>
                      +{manufacturingItem.visiblePreviousStages.length - 5}
                    </div>
                  )}
                  <div className={localStyles.deliveryGroup}>
                    {manufacturingItem.source.color ? (
                      <span
                        className={cx(localStyles.color)}
                        style={{ background: `${manufacturingItem.source.color}` }}
                      ></span>
                    ) : (
                      <span className={cx(localStyles.color, localStyles.bgViolet)}></span>
                    )}
                    <div
                      className={localStyles.sourceName}
                      data-tip
                      data-for={`manufacturing-working-unit-source-${manufacturingItem.id}`}
                    >
                      {manufacturingItem.source.signature}
                    </div>
                    <ReactTooltip
                      className={styles.customTooltip}
                      id={`manufacturing-working-unit-source-${manufacturingItem.id}`}
                      place="top"
                      effect="solid"
                      arrowColor="transparent"
                      offset={{ top: -10 }}
                    >
                      {manufacturingItem.source.signature}
                    </ReactTooltip>
                  </div>
                  <div
                    className={cx(localStyles.priorityPlanLabel, {
                      [localStyles.priorityPlanA]: manufacturingItem.priority === "A",
                      [localStyles.priorityPlanB]: manufacturingItem.priority === "B",
                      [localStyles.priorityPlanC]: manufacturingItem.priority === "C",
                    })}
                  >
                    {manufacturingItem.priority}
                  </div>
                </div>
              </div>
              <div
                className={cx(localStyles.attributesContainer, "d-flex align-items-center gap-1")}
              >
                {attributesList.map(attribute => (
                  <div key={attribute.id} className={localStyles.attribute}>
                    <span className="body-10">{attribute.name}: </span>
                    <span className="body-10-900">{attribute.value}</span>
                  </div>
                ))}
                {attributesList.length > 4 && (
                  <div className={styles.moreDataCounter}>+{attributesList.length - 4}</div>
                )}
              </div>
            </div>
            {source === "SHELF" ? (
              <Button kind="transparent-black" onClick={noop} size="square-s">
                <div className="btnBase btnBaseSmall btnBase16">
                  <img alt="Więcej" src={threeDotsIcon} />
                </div>
              </Button>
            ) : (
              <Button
                disabled={
                  manufacturingWorkingUnitEmployeeWorkingDayId !== undefined &&
                  manufacturingWorkingUnitEmployeeWorkingDayId.length === 0
                }
                kind="transparent-black"
                onClick={() => {
                  if (employeeWorkingDayId && productionPlanId) {
                    deleteManufacturingWorkingUnit.mutate({
                      employeeWorkingDay: employeeWorkingDayId,
                      manufacturingWorkingUnit: manufacturingItem.id,
                      stageManufacturingPlan: productionPlanId,
                      sourceId: String(manufacturingItem.source.id),
                      source: manufacturingItem.source,
                    });
                  }
                }}
                size="square-s"
              >
                <div className="btnBase btnBaseSmall btnBase16">
                  <img alt="Usuń pozycję" src={removeIcon} />
                </div>
              </Button>
            )}
          </div>
        );
      }}
    </Draggable>
  );
};

function formatAttributes(
  attributes: string,
): { name: string; value: string; id: number | string }[] {
  const stringAttributes = attributes.split(";");
  return stringAttributes.map(attribute => {
    const [name, value] = attribute.split(":");
    return { name, value, id: name + value };
  });
}

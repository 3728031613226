import { createColumnHelper, AccessorFn, DisplayColumnDef, ColumnDef } from "@tanstack/react-table";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { HeaderCell } from "components/miloDesignSystem/molecules/table/components/header/Header";
import { Assign } from "utility-types";

export const numberColumn = <T,>(
  accessorFn: AccessorFn<T, number>,
  columnDef: Assign<
    Pick<DisplayColumnDef<T>, "size">,
    { typographyProps?: TypographyProps; header: string }
  >,
): ColumnDef<T> => {
  const baseColumnHelper = createColumnHelper<T>();
  return baseColumnHelper.accessor(accessorFn, {
    id: columnDef.header,
    size: columnDef.size,
    header: () => <HeaderCell className="text-right ">{columnDef.header}</HeaderCell>,
    cell: info => (
      <Typography className="text-right w-100" fontSize="12" fontWeight="700">
        {info.getValue() || emptyValuePlaceholder}
      </Typography>
    ),
  }) as ColumnDef<T>;
};

const emptyValuePlaceholder = "---";

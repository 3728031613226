import { SelectedSource } from "api/new-production-plans/models";

export const isSourceSelected = (
  id: string | number,
  selectedSources: SelectedSource[],
  signature: string,
): boolean => {
  return selectedSources.some(
    prevSelectedSource =>
      prevSelectedSource.id === id && prevSelectedSource.signature === signature,
  );
};

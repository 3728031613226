import { CategorySummary, IndexCategorySummary } from "api/new-production-plans/models";

export const isCategorySummaryAlreadyAdded = (
  draggedCategorySummary: IndexCategorySummary,
  categorySummaries: CategorySummary[],
): boolean => {
  return categorySummaries.some(categorySummary => {
    if (
      categorySummary.code === draggedCategorySummary.code &&
      categorySummary.color === draggedCategorySummary.color &&
      categorySummary.name === draggedCategorySummary.name
    ) {
      return true;
    }
    return false;
  });
};

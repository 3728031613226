export const neutralWhite = {
  neutralWhite4: "#FFFFFF0A",
  neutralWhite7: "#FFFFFF12",
  neutralWhite12: "#FFFFFF1F",
  neutralWhite16: "#FFFFFF29",
  neutralWhite20: "#FFFFFF33",
  neutralWhite24: "#FFFFFF3D",
  neutralWhite32: "#FFFFFF52",
  neutralWhite48: "#FFFFFF7A",
  neutralWhite64: "#FFFFFFA3",
  neutralWhite76: "#FFFFFFC2",
  neutralWhite88: "#FFFFFFE0",
  neutralWhite100: "#FFFFFF",
};

import { Button } from "components/miloDesignSystem/atoms/button";
import { useState } from "react";

interface Props {
  options: { id: string; name: string }[];
  selectedOptions: string[];
  children: (options: Props["options"]) => JSX.Element;
}
export const CollapsibleSection = ({ children, options, selectedOptions }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggleCollapsed = () => setIsCollapsed(prev => !prev);

  const customOptions = options.filter(option => {
    if (isCollapsed) {
      return selectedOptions.includes(option.id);
    }
    return option;
  });

  return (
    <div>
      {children(customOptions)}
      <Button
        size="small"
        variant="gray"
        onClick={handleToggleCollapsed}
        className="text-uppercase"
      >
        {isCollapsed ? "pokaż więcej usług" : "ukryj usługi dodatkowe"}
      </Button>
    </div>
  );
};

import { endOfMonth, endOfYear, startOfMonth, startOfYear } from "date-fns";
import { dateFns } from "utilities";

export function createDateRanges() {
  function daysInMonth(month: number, year: number) {
    return addZero(new Date(year, month, 0).getDate());
  }

  function addDays(days: number) {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  }
  const now = new Date();

  function dateToString(date: Date) {
    return date.toISOString().split("T")[0]; // "yyyy-mm-dd"
  }

  function getPrevMonth(monthsToSubtract: number) {
    const monthsBefore = dateFns.subMonths(now, monthsToSubtract);
    const year = monthsBefore.getFullYear();
    const month = addZero(monthsBefore.getMonth() + 1);
    const day = addZero(monthsBefore.getDate());
    const days = daysInMonth(Number(month), year);
    return { year, month, day, days };
  }

  // 2020-04-30 returns 30 of april 00:00, so we need to add one day
  function addOneDayAndFormat(dateString: string) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return dateToString(date);
  }

  function formatDate(date: Date) {
    return dateFns.format(date, "yyyy-MM-dd");
  }

  const year = now.getFullYear();
  const month = addZero(now.getMonth() + 1);
  const day = addZero(now.getDate());
  const lastMonth = getPrevMonth(1);
  const sixMonthsBefore = getPrevMonth(6);

  return {
    today: [dateToString(now), dateToString(addDays(1))] as [string, string],
    thisWeek: [
      formatDate(dateFns.startOfWeek(now, { weekStartsOn: 1 })),
      formatDate(dateFns.endOfWeek(now, { weekStartsOn: 1 })),
    ] as [string, string],
    thisYear: [formatDate(startOfYear(now)), formatDate(endOfYear(now))] as [string, string],
    thisMonth: [formatDate(startOfMonth(now)), formatDate(endOfMonth(now))] as [string, string],
    lastFullMonth: [
      `${lastMonth.year}-${lastMonth.month}-01`,
      addOneDayAndFormat(`${lastMonth.year}-${lastMonth.month}-${lastMonth.days}`),
    ] as [string, string],
    lastMonth: [
      `${lastMonth.year}-${lastMonth.month}-${lastMonth.day}`,
      addOneDayAndFormat(`${year}-${month}-${day}`),
    ] as [string, string],
    lastSixFullMonths: [
      `${sixMonthsBefore.year}-${sixMonthsBefore.month}-01`,
      addOneDayAndFormat(`${lastMonth.year}-${lastMonth.month}-${lastMonth.days}`),
    ] as [string, string],
    lastSixMonths: [
      `${sixMonthsBefore.year}-${sixMonthsBefore.month}-${sixMonthsBefore.day}`,
      addOneDayAndFormat(`${year}-${month}-${day}`),
    ] as [string, string],
  };
}

function addZero(num: number) {
  if (num > 9) return String(num);
  return `0${num}`;
}

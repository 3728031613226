import { Route } from "components/utils";
import { ExternalManufacturingOrders } from "pages/externalManufacturing/externalManufacturingOrders/ExternalManufacturingOrders";
import { NotFound } from "pages/notFound";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const ExternalManufacturingOrdersRouting = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={ExternalManufacturingOrders}
        exact={true}
        path={`${match.path}/list/:tab`}
        title="Milo - lista zleceń do producentów"
      />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};

import { TradingDocument } from "api/trading-documents/models";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { countryToFlagDict } from "constants/countriesFlags";
import { getStandardDateFormat } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  correctionInvoice: TradingDocument;
}

export const AmountSection = ({ correctionInvoice }: Props) => {
  return (
    <RightPanelSection>
      <InfoLabel title="kraj">
        {Boolean(correctionInvoice.countryCode.length) ? (
          <img alt="Flaga kraju" src={countryToFlagDict[correctionInvoice.countryCode]} />
        ) : (
          <EmptyValue fontSize="14" fontWeight="700" />
        )}
      </InfoLabel>
      <InfoLabel title="wartość netto">
        <FinanceAmountFormat
          decimalClassName="body-14-600"
          integerClassName="body-14-600"
          value={correctionInvoice.amountSummary.totalWithoutTax}
        />
        <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
          {correctionInvoice.currency}
        </Typography>
      </InfoLabel>
      <InfoLabel title="kwota VAT">
        <FinanceAmountFormat
          decimalClassName="body-14-600"
          integerClassName="body-14-600"
          value={correctionInvoice.amountSummary.totalTax}
        />
        <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
          {correctionInvoice.currency}
        </Typography>
      </InfoLabel>
      <InfoLabel
        title={`razem do ${
          correctionInvoice.amountSummary.totalWithTax > 0 ? "zapłaty" : "zwrotu"
        }`}
      >
        <FinanceAmountFormat
          decimalClassName="body-18"
          integerClassName="body-18"
          value={Math.abs(correctionInvoice.amountSummary.totalWithTax)}
        />
        <Typography color="neutralBlack48" fontSize="16" fontWeight="700">
          {correctionInvoice.currency}
        </Typography>
      </InfoLabel>
      <InfoLabel title="waluta">
        <Typography fontSize="14" fontWeight="500">
          {correctionInvoice.currency}
        </Typography>
      </InfoLabel>
      <InfoLabel title="Kurs wymiany">
        {correctionInvoice.lastValidExchangeRate ? (
          <Typography fontSize="14" fontWeight="500">
            {correctionInvoice.lastValidExchangeRate}
          </Typography>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
      <InfoLabel title="Data wymiany">
        {correctionInvoice.lastValidExchangeRateDate ? (
          <Typography fontSize="14" fontWeight="500">
            {correctionInvoice.lastValidExchangeRateDate}
          </Typography>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
      <InfoLabel title="rejestr VAT">
        {correctionInvoice.vatTaxObligationDate ? (
          <Typography fontSize="14" fontWeight="500">
            {getStandardDateFormat(correctionInvoice.vatTaxObligationDate)}
          </Typography>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
      <InfoLabel title="obowiązek podatkowy">
        {correctionInvoice.pitTaxObligationDate ? (
          <Typography fontSize="14" fontWeight="500">
            {getStandardDateFormat(correctionInvoice.pitTaxObligationDate)}
          </Typography>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
      <InfoLabel
        title={`${correctionInvoice.sellerBankName ? correctionInvoice.sellerBankName : "bank"} [${
          correctionInvoice.currency ? correctionInvoice.currency : "---"
        }]`}
      >
        {correctionInvoice.sellerBankAccountNumber ? (
          <div>****&nbsp;{correctionInvoice.sellerBankAccountNumber.slice(-4)}</div>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
    </RightPanelSection>
  );
};

import { AddLineItemToUnloadingPayload, UnloadingDetails } from "api/wms/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./AddIndexToUnloadingModal.module.css";
import { ErrorMessage, Formik } from "formik";
import { useSelector } from "hooks";
import { cx } from "utilities";
import {
  Autocomplete,
  AutocompleteAsyncHandler,
  FormInput,
  FormSelect,
  Spinner,
} from "components/utils";
import { Product, ProductEntity, ProductLight, ProductToFetch } from "api/products/models";
import { SearchItem } from "pages/orders/shared/rightPanel/productsSection/productForm/SearchProduct";
import { getProductsLight } from "api/products/calls";
import { useMemo, useState } from "react";
import immer from "immer";
import { useProductEntity } from "api/products/hooks";
import cuid from "cuid";
import { AttributeSection } from "pages/orders/shared/rightPanel/productsSection/productForm/attributeSection/AttributeSection";
import { generateCommonAttributesState } from "pages/orders/shared/rightPanel/productsSection/productForm/utils/generateCommonAttributesState";
import { initialProductElement } from "pages/orders/shared/rightPanel/productsSection/productForm/utils/initialState";
import { assertIsDefined } from "utilities/assertIsDefined";
import { SingleProductForm } from "./SingleProductForm";
import { getInitialStateForCreate } from "./getInitialStateForCreate";
import { useAddIndexToUnloading } from "api/wms/hooks";
import { isMoreThanOneAttributeValue } from "pages/orders/shared/rightPanel/productsSection/productForm/utils/isMoreThanOneAttributeValue";

interface Props {
  close: () => void;
  unloading: UnloadingDetails;
}

export type Action = {
  type: "ADD_PRODUCT_INDEX";
  payload: { newIndex: Product["indexes"]; productId: number };
};

function reducer(state: ProductEntity, action: Action) {
  switch (action.type) {
    case "ADD_PRODUCT_INDEX": {
      return immer(state, draft => {
        draft.products.forEach(product => {
          if (product.id === action.payload.productId) {
            product.indexes = { ...product.indexes, ...action.payload.newIndex };
          }
        });
      });
    }
    default:
      return state;
  }
}

export const AddIndexToUnloadingModal = ({ close, unloading }: Props) => {
  const customers = useSelector(store => store.partials.customers);
  const canUseAllProductsWithCustomer = useSelector(
    state => state.partials.canUseAllProductsWithCustomer,
  );
  const [productToFetch, setProductToFetch] = useState<ProductToFetch | null>(null);
  const handleSubmit = useAddIndexToUnloading(close);

  const customersOptions: { id: number; name: string }[] = customers.map(customer => ({
    id: customer.id,
    name: customer.name,
  }));
  const [selectedCustomer, setSelectedCustomer] = useState(customersOptions[0].id);
  const [selectedQuantity, setSelectedQuantity] = useState(0);

  // @ts-ignore
  const [productEntity, { inProgress: isFetchingProduct }] = useProductEntity(productToFetch?.id, {
    reducer,
    skip: !productToFetch,
    clearSkip: true,
  });

  const attributesToDisplay = useMemo(
    () => productEntity?.commonAttributes.map(attribute => ({ ...attribute, cuid: cuid() })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productEntity],
  );

  const initialValues: AddLineItemToUnloadingPayload = useMemo(() => {
    if (!productEntity?.id) {
      return {
        unloadingId: unloading.id,
        id: unloading.id,
        customerId: selectedCustomer,
        indexId: null,
        quantity: selectedQuantity,
        productElements: [
          {
            ...initialProductElement,
            product: 0,
            cuid: cuid(),
            attributesState: [],
            productSetCode: "",
            id: 0,
            name: "",
            amount: "0",
            currency: "PLN",
          },
        ],
      };
    }

    return {
      unloadingId: unloading.id,
      id: unloading.id,
      customerId: selectedCustomer,
      indexId: null,
      quantity: selectedQuantity,
      productElements: getInitialStateForCreate({
        products: productEntity.products,
        productSetCode: productEntity.productSetCode,
      }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productEntity?.id, customers]);

  const validate = (values: AddLineItemToUnloadingPayload) => {
    const errors: Partial<{
      productElements: string;
      quantity: string;
    }> = {};
    if (!Boolean(values.quantity.toString().length)) {
      errors.quantity = "Pole Liczba sztuk jest wymagane";
    }
    if (values.quantity <= 0) {
      errors.quantity = "Liczba sztuk musi być większa od 0";
    }
    if (!values.productElements.every(productElement => productElement.index)) {
      errors.productElements = productEntity?.productSetCode
        ? "Wybierz wszystkie warianty produktów"
        : "Wybierz istniejący wariant produktu";
    }

    return errors;
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj mebel
        </Typography>
      }
      width={741}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validate={validate}
      >
        {({ handleSubmit, isSubmitting, isValid, values, setValues, setFieldValue }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className={cx(styles.modalForm, "d-flex flex-column p-3")}>
              <FormSelect
                items={customersOptions}
                itemToSelection={item => (item ? item.id : null)}
                label="Kontrahent"
                name="customerId"
                placeholder="Wybierz kontrahenta"
              />
              <div className="py-3">
                <AutocompleteAsyncHandler
                  transform={(item: ProductLight) => {
                    return {
                      ...item,
                      name: (
                        <SearchItem
                          item={item}
                          canUseAllProductsWithCustomer={canUseAllProductsWithCustomer}
                        />
                      ),
                    };
                  }}
                  transformQuery={query => ({
                    ...query,
                    pageSize: "999",
                    customer: String(values.customerId),
                  })}
                  fetchFrom={getProductsLight}
                >
                  <Autocomplete
                    placeholder="Szukaj produktu"
                    overrides={{
                      selectedItem: { className: styles.searchItem },
                      searchBar: { className: styles.searchBar },
                    }}
                    onChange={products => {
                      setProductToFetch(products[0] || null);
                      setSelectedCustomer(values.customerId);
                      setSelectedQuantity(values.quantity);
                    }}
                    isItemDisabled={(item: any) =>
                      !item.isInProductSet && !canUseAllProductsWithCustomer
                    }
                    multiple={false}
                  />
                </AutocompleteAsyncHandler>

                {productEntity && Boolean(productEntity.commonAttributes.length) && (
                  <div className={cx(styles.section, styles.commonAttributes)}>
                    <h6>Cechy wspólne</h6>
                    <small className="text-color-grey">
                      <i>Wybrane opcje zostaną zastosowane do wszystkich produktów z tą cechą</i>
                    </small>
                    <AttributeSection
                      isCommonAttributes
                      attributesState={generateCommonAttributesState(
                        productEntity,
                        values.productElements,
                      )}
                      attributesToDisplay={attributesToDisplay || []}
                      productIndexes={productEntity.products.reduce((acc, el) => {
                        acc = { ...acc, ...el.indexes };
                        return acc;
                      }, {})}
                      preselectedAttributes={[]}
                      changeAttribute={(attributeId, value) =>
                        setValues(
                          immer(values, draft => {
                            draft.productElements.forEach(productElement => {
                              const product = productEntity.products.find(
                                _product => _product.id === productElement.id,
                              );
                              if (product && isMoreThanOneAttributeValue(product, attributeId)) {
                                const productToUpdate = productElement.attributesState.find(
                                  attribute => attribute.attributeId === attributeId,
                                );

                                const possibleValues = (() => {
                                  assertIsDefined(product);
                                  const indexes = Object.keys(product.indexes || {});

                                  const values: string[][] = [];
                                  indexes.forEach(index => {
                                    const indexArray = index.split("-");
                                    values.push(indexArray);
                                  });

                                  return [...new Set(values.flat())].map(Number);
                                })();

                                if (productToUpdate) {
                                  if (!value) return (productToUpdate.valueId = value);

                                  if (possibleValues?.includes(value)) {
                                    productToUpdate.valueId = value;
                                  }
                                }
                              }
                            });
                          }),
                        )
                      }
                    />
                  </div>
                )}
                <div className="d-flex justify-content-center">
                  {isFetchingProduct && <Spinner color="blue" />}
                </div>
                {productEntity &&
                  values.productElements.map((value, index) => (
                    <div className={cx({ "bg-white": index % 2 !== 0 })} key={value.cuid}>
                      <SingleProductForm
                        product={productEntity.products[index]}
                        orderProduct={undefined}
                        setFieldValue={(name, value) => {
                          setFieldValue(`productElements[${index}][${name}]`, value);
                        }}
                        values={values.productElements[index]}
                        changeAttribute={(attributeId, value, cuid) =>
                          setValues(
                            immer(values, draft => {
                              const productToUpdate = draft.productElements.find(
                                productElement => productElement.cuid === cuid,
                              );
                              assertIsDefined(productToUpdate);

                              const toChange = productToUpdate.attributesState.find(
                                attribute => attribute.attributeId === attributeId,
                              );

                              if (toChange) {
                                toChange.valueId = value;
                              }
                            }),
                          )
                        }
                      />
                    </div>
                  ))}
                <div className={styles.errorMessage}>
                  <ErrorMessage name="productElements" />
                </div>
              </div>
              <div className="py-3">
                <FormInput
                  label="Liczba sztuk"
                  name="quantity"
                  placeholder="Liczba sztuk"
                  type="number"
                />
              </div>
            </div>
            <div className="d-flex align-items-center gap-3 p-3">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Dodaj do rozładunku{isSubmitting && "..."}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

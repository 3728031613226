import { Button } from "components/common";
import { StateProxy } from "../StateProxy";
import styles from "./SearchField.module.css";
import closeIcon from "assets/images/11.svg";
import cx from "classnames";

interface Props {
  value: string;
  debounce?: number;
  onUpdate: (value: string) => void;
  isNewLayout?: boolean;
  overrides?: {
    wrapper?: { className?: string };
    input?: { className?: string };
  };
  style?: React.CSSProperties;
}

export function SearchField({
  value,
  onUpdate,
  debounce = 200,
  isNewLayout = false,
  overrides = {},
  style,
}: Props) {
  return (
    <div
      className={cx(overrides.wrapper?.className, {
        [styles.searchBoxField]: !isNewLayout,
        [styles.newLayoutSearch]: isNewLayout,
      })}
      style={style}
    >
      <StateProxy state={value} onChange={onUpdate} debounce={debounce}>
        {({ state, setState, setBlur, setFocus }) => (
          <>
            <input
              type="search"
              className={cx("search-field", overrides.input?.className)}
              placeholder="Szukaj..."
              value={state}
              onFocus={setFocus}
              onBlur={setBlur}
              onChange={e => setState(e.target.value)}
            />
            {state && !isNewLayout && (
              <Button
                kind="secondary"
                size="round-s"
                onClick={() => setState("")}
                className={styles.resetBtn}
              >
                <img src={closeIcon} alt="reset" />
              </Button>
            )}
          </>
        )}
      </StateProxy>
    </div>
  );
}

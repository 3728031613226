export const danger = {
  danger12: "#FF80801F",
  danger25: "#FFF9F9",
  danger32: "#FF808052",
  danger50: "#FFF1F1",
  danger75: "#FFE0E0",
  danger100: "#FFC7C7",
  danger200: "#FFA7A7",
  danger300: "#FF8080",
  danger400: "#F95E5E",
  danger500: "#F03D3D",
  danger600: "#CF2A2A",
  danger700: "#A41F1F",
  danger800: "#591A1A",
  danger900: "#271111",
};

import { useEffect } from "react";
import * as React from "react";
import MultiDownshift from "./MultiDownshift";
import matchSorter from "match-sorter";
import styles from "./Autocomplete.module.css";
import { context } from "./AutocompleteAsyncHandler";
import { Menu, Item, ConnectInvoiceBaseMenu } from "./Styled";
import xIcon from "assets/images/11.svg";
import { noop } from "utilities";
import cx from "classnames";

interface Item {
  name: string;
  id: number | string;
  key?: number;
  display?: React.ReactNode;
}

interface Props {
  items: Item[];
  initialItems?: Item[];
  isConnectingInvoice?: boolean;
  labelColor: "white" | "grey";
  selectedItems?: Item[];
  placeholder?: string;
  showSelectedItems?: boolean;
  onChange: (arg: any) => void;
  multiple: boolean;
  orientation?: "top" | "bottom";
  inputRef?: (input: React.MutableRefObject<HTMLInputElement>["current"]) => void;
  disabled?: boolean;
  onBlur?: () => void;
  removeMode?: boolean;
  noResultsTemplate?: JSX.Element;
  disabledItems?: (number | string)[];
  autoFocus?: boolean;
  itemToRender?: (item: Item) => any;
  isItemDisabled?: (item: Item) => boolean;
  overrides?: {
    searchBar?: { className: string };
    input?: { className: string };
    list?: { className: string };
    item?: { className: string };
    selectedItem?: { className: string };
  };
}

const getItems = (filter: string, items: Item[]) => {
  return filter
    ? matchSorter(items, filter, {
        keys: ["name"],
      })
    : items;
};

const getLabelColorClassName = {
  grey: "label-65",
  white: "label-70",
};
/**
 * For async data use with AsyncHandler
 * @example
 * <Autocomplete
 *   onChange={values => void}
 *   transform={el => ({...el, name:el.something.stringToDisplay})} // optional
 *   initialItems={[{...}]}
 * />
 */
export function Autocomplete({
  items,
  initialItems,
  isConnectingInvoice = false,
  labelColor,
  selectedItems,
  placeholder,
  showSelectedItems = true,
  onChange,
  orientation = "bottom",
  multiple = true,
  inputRef,
  disabled = false,
  onBlur = noop,
  removeMode = true,
  noResultsTemplate,
  disabledItems = [],
  autoFocus,
  itemToRender = item => item.name,
  isItemDisabled = () => false,
  overrides = {},
}: Props) {
  const input = React.useRef<HTMLInputElement | undefined>();

  useEffect(() => {
    if (inputRef && input.current) {
      inputRef(input.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trans = {
    noResults: "Brak wyników",
    placeholder: "Szukaj",
  };

  const asyncHandler = React.useContext(context);
  const itemToString = (item: Item | null) => (item ? item.name : "");

  const handleChange = (selectedItems: Item[]) => {
    onChange(selectedItems);
    asyncHandler.updateSearch("");
  };

  const handleAsync = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (asyncHandler && e.target) {
      asyncHandler.updateSearch(e.target.value);
    }
  };

  /**
   * Prevents form submitting when pressing Enter; we want just
   * open the list
   */
  const preventSubmit = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const allItems = items || asyncHandler.items;
  const renderItems = (
    {
      inputValue,
      getItemProps,
      highlightedIndex,
      selectedItems,
    }: { selectedItems: Item[]; [key: string]: any },
    allItems: Item[],
  ) => {
    // do not filter items when async
    const itemsToRender = asyncHandler.async ? allItems : getItems(inputValue || "", allItems);
    if (!itemsToRender.length) {
      if (Boolean(noResultsTemplate)) {
        return (
          <Item className={cx(styles.searchSuggestionItem, overrides.item?.className)}>
            {noResultsTemplate}
          </Item>
        );
      }

      return (
        <Item className={cx(styles.searchSuggestionItem)}>
          <strong>{trans.noResults}</strong>
        </Item>
      );
    }
    return itemsToRender.map((item, index) => {
      return (
        <Item
          key={item.key ? item.key : item.id}
          {...getItemProps({
            item,
            index,
            isActive: highlightedIndex === index,
            isSelected: selectedItems.includes(item),
            isDisabled:
              selectedItems.some(el => (item.key ? el.key === item.key : el.id === item.id)) ||
              disabledItems.includes(item.id) ||
              isItemDisabled(item),
          })}
          className={cx(styles.searchSuggestionItem, overrides.item?.className)}
          data-testid="autocompleteItem"
        >
          {itemToRender(item)}
        </Item>
      );
    });
  };
  return (
    <MultiDownshift
      onChange={handleChange}
      itemToString={itemToString}
      initialItems={initialItems}
      selectedItems={selectedItems}
      multiple={multiple}
      removeMode={removeMode}
      onOuterClick={() => {
        asyncHandler.updateSearch("");
        asyncHandler.filterManufacturer("");
        asyncHandler.filterCreatedAt("");
      }}
    >
      {({
        getInputProps,
        getMenuProps,
        getRemoveButtonProps,
        isOpen,
        inputValue,
        selectedItems,
        getItemProps,
        highlightedIndex,
      }) => (
        <div>
          <div className={cx(styles.searchBar, overrides.searchBar?.className)}>
            <input
              autoFocus={autoFocus}
              className={cx(styles.search, overrides.input?.className)}
              type="search"
              placeholder={placeholder || trans.placeholder}
              {...getInputProps({
                ref: input,
                onChange: handleAsync,
                onKeyPress: preventSubmit,
                onBlur,
                disabled,
              })}
            />
            {!isConnectingInvoice ? (
              <Menu
                {...getMenuProps()}
                className={cx(styles.searchSuggestionList, overrides.list?.className)}
                style={orientation === "top" ? { bottom: "100%", top: "auto" } : undefined}
                isOpen={isOpen}
              >
                {isOpen
                  ? renderItems(
                      { inputValue, getItemProps, highlightedIndex, selectedItems },
                      allItems,
                    )
                  : null}
              </Menu>
            ) : (
              <ConnectInvoiceBaseMenu
                {...getMenuProps()}
                asyncHandler={asyncHandler}
                className={cx(styles.searchSuggestionList, overrides.list?.className)}
                style={orientation === "top" ? { bottom: "100%", top: "auto" } : undefined}
                isOpen={isOpen}
              >
                {isOpen
                  ? renderItems(
                      { inputValue, getItemProps, highlightedIndex, selectedItems },
                      allItems,
                    )
                  : null}
              </ConnectInvoiceBaseMenu>
            )}
          </div>
          <div className="pt-3 pb-0" style={{ display: selectedItems.length ? "block" : "none" }}>
            {showSelectedItems &&
              selectedItems.map(item => (
                <div
                  key={item.id}
                  className={`${styles.chip} ${getLabelColorClassName[labelColor]} ${overrides
                    .selectedItem?.className || ""} ${removeMode ? "" : "pr-3"}`}
                >
                  <strong>{item.name}</strong>
                  {removeMode && (
                    <button {...getRemoveButtonProps({ item, disabled })} type="button">
                      <img src={xIcon} alt="" />
                    </button>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </MultiDownshift>
  );
}

Autocomplete.defaultProps = {
  items: null,
  labelColor: "white",
};

import styles from "../../Dashboard.module.css";
import cx from "classnames";
import { EmptyCashFlowSection } from "./components/EmptyCashFlowSection";

export const CashFlowSection = () => {
  return (
    <div className={cx(styles.card, "pb-2")}>
      <EmptyCashFlowSection />
    </div>
  );
};

import { InputQuery } from "components/utils/queryProvider";
import { setMonthCheck } from "./setMonthCheck";

export const handleNotCheckedMonth = (
  dateRanges: string[] | string | null,
  startDateName: string,
  endDateName: string,
  name: string,
  queryStartDate: string,
  queryEndDate: string,
  setMonthsToDisplay: React.Dispatch<
    React.SetStateAction<
      {
        checked: boolean;
        key: string;
        label: string;
        name: string;
        position: number;
        value: string[];
      }[]
    >
  >,
  updateQuery: (arg: InputQuery) => void,
  additionalKeys: [string, string] | undefined,
) => {
  const [additionalStartDate, additionalEndDate] = additionalKeys ? additionalKeys : ["", ""];

  if (!dateRanges && additionalKeys === undefined) {
    updateQuery({
      [startDateName]: "",
      [endDateName]: "",
      page: 1,
    });
    return;
  }

  if (!dateRanges && additionalKeys !== undefined) {
    updateQuery({
      [startDateName]: "",
      [endDateName]: "",
      [additionalStartDate]: "",
      [additionalEndDate]: "",
      page: 1,
    });
    return;
  }

  if (!dateRanges) {
    updateQuery({
      [startDateName]: "",
      [endDateName]: "",
      page: 1,
    });
    return;
  }

  const [startDate, endDate] = typeof dateRanges === "string" ? dateRanges.split(",") : dateRanges;

  setMonthCheck(true, name, setMonthsToDisplay);

  if (additionalKeys) {
    if (queryStartDate === undefined && queryEndDate === undefined) {
      updateQuery({
        [startDateName]: startDate,
        [endDateName]: endDate,
        [additionalStartDate]: startDate,
        [additionalEndDate]: endDate,
        page: 1,
      });
    }

    if (queryStartDate === undefined && queryEndDate !== undefined) {
      updateQuery({
        [startDateName]: startDate,
        [additionalStartDate]: startDate,
        page: 1,
      });
    }

    if (queryStartDate !== undefined && queryEndDate === undefined) {
      updateQuery({
        [endDateName]: endDate,
        [additionalEndDate]: endDate,
        page: 1,
      });
    }

    if (new Date(startDate) < new Date(queryStartDate))
      updateQuery({
        [startDateName]: startDate,
        [additionalStartDate]: startDate,
        page: 1,
      });

    if (new Date(endDate) > new Date(queryEndDate))
      updateQuery({
        [endDateName]: endDate,
        [additionalEndDate]: endDate,
        page: 1,
      });
  } else {
    if (queryStartDate === undefined && queryEndDate === undefined) {
      updateQuery({
        [startDateName]: startDate,
        [endDateName]: endDate,
        page: 1,
      });
    }

    if (queryStartDate === undefined && queryEndDate !== undefined) {
      updateQuery({
        [startDateName]: startDate,
        page: 1,
      });
    }

    if (queryStartDate !== undefined && queryEndDate === undefined) {
      updateQuery({
        [endDateName]: endDate,
        page: 1,
      });
    }

    if (new Date(startDate) < new Date(queryStartDate))
      updateQuery({
        [startDateName]: startDate,
        page: 1,
      });

    if (new Date(endDate) > new Date(queryEndDate))
      updateQuery({
        [endDateName]: endDate,
        page: 1,
      });
  }
};

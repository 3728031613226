import { useEffect, useState } from "react";
import { ReactNode } from "react";
import { TriggerProps, useLayer } from "react-laag";

interface Props {
  children: ReactNode | ((state: { isOpen: boolean; close: () => void }) => ReactNode);
  target: (
    btnProps: TriggerProps & { onClick: () => void },
    state: { isOpen: boolean }
  ) => ReactNode;
  placement?: `${"top" | "bottom" | "left" | "right"}-${"start" | "end" | "center"}`;
  isOpen?: boolean;
  overflowContainer?: boolean;
  triggerOffset?: number;
}

export function PopoverMenu({ target, children, placement = "bottom-end",
isOpen: isOpenExternal,
overflowContainer = false,
triggerOffset = 5,
}: Props) {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isOpenExternal !== undefined) {
      setOpen(isOpenExternal);
    }
  }, [isOpenExternal, setOpen]);

  // helper function to close the menu
  function close() {
    setOpen(false);
  }

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    onOutsideClick: close, // close the menu when the user clicks outside
    onDisappear: close, // close the menu when the menu gets scrolled out of sight
    overflowContainer, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement, // we prefer to place the menu "top-end"
    triggerOffset, // keep some distance to the trigger
    containerOffset: 0, // give the menu some room to breath relative to the container
    arrowOffset: 0, // let the arrow have some room to breath also
  });

  function renderChildren() {
    if (typeof children === "function") return children({ isOpen, close: () => setOpen(false) });
    return children;
  }
  return (
    <>
      {target({ ...triggerProps, onClick: () => setOpen(!isOpen) }, { isOpen })}
      {renderLayer(<div>{isOpen && <div {...layerProps}>{renderChildren()}</div>}</div>)}
    </>
  );
}

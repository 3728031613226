import { useState, useCallback } from "react";

export type StateModalHookState<State> = {
  isOpen: boolean;
  open: (stateToSet: State) => void;
  close: () => void;
  state: State | null;
};

export function useStateModal<State>() {
  const [state, setState] = useState<State | null>(null);
  const close = useCallback(() => setState(null), []);
  const open = useCallback((stateToSet: State) => setState(stateToSet), []);
  const isOpen = Boolean(state);
  return { isOpen, open, close, state };
}

export const StateModalHandler = <State>({
  children,
}: {
  children: (arg: StateModalHookState<State>) => JSX.Element;
}) => {
  const statusBag = useStateModal<State>();

  return children(statusBag);
};

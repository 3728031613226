import { RampListItem } from "api/wms/ramps/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useRampsColumns = () =>
  useCreateTableColumns<RampListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: "nazwa",
        size: 250,
      }),
      columnHelper.text(row => row.code, {
        header: "kod rampy",
        size: 250,
      }),
      columnHelper.text(row => row.hall, {
        header: "hala",
        size: 250,
      }),
    ];
  });

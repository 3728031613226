import { Header } from "./components/Header";
import localStyles from "./Dashboard.module.css";
import cx from "classnames";
import { CargoUnloadsSection } from "./components/cargoUnloadsSection/CargoUnloadsSection";
import { LastLoadedOrdersList } from "./components/lastLoadedOrdersList/LastLoadedOrdersList";
import { LoadingsList } from "./components/loadingsList/LoadingsList";
import { LastPickedOrdersList } from "./components/lastPickedOrdersList/LastPickedOrdersList";
import { LastUnloadedOrders } from "./components/lastUnloadedOrders/LastUnloadedOrders";
import { PickingList } from "./components/pickingList/PickingList";

export const Dashboard = () => {
  return (
    <div className="position-relative">
      <Header />
      <div className={localStyles.mainContent}>
        <div className={cx(localStyles.mainContentInner, "flex-wrap")}>
          <div
            className={cx(
              localStyles.dashboardScrollableContent,
              "d-flex flex-column align-items-start overflow-scroll w-100 px-3 py-3",
            )}
          >
            <div className="d-flex align-items-start w-100 gap-4">
              <div className={localStyles.mainTable}>
                <CargoUnloadsSection />
              </div>
              <div className={localStyles.ordersTable}>
                <LastUnloadedOrders />
              </div>
            </div>
            <div className="d-flex align-items-start w-100 gap-4">
              <div className={localStyles.mainTable}>
                <PickingList />
              </div>
              <div className={localStyles.ordersTable}>
                <LastPickedOrdersList />
              </div>
            </div>
            <div className="d-flex align-items-start w-100 gap-4">
              <div className={localStyles.mainTable}>
                <LoadingsList />
              </div>
              <div className={localStyles.ordersTable}>
                <LastLoadedOrdersList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { CallCenterRouteDetails } from "api/call-center/routes/models";
import {
  externalManufacturerModuleConfig,
  moduleConfig,
} from "components/common/moduleNavigation/moduleConfig";
import {
  ExternalManufacturerModule,
  Module,
  ModuleLink,
} from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import React from "react";
import { match, NavLink, useHistory, useLocation } from "react-router-dom";
import { assertIsDefined } from "utilities/assertIsDefined";
import { FiltersSection } from "../../filtersSection/FiltersSection";
import arrowBackIcon from "assets/images/arrowBackLight.svg";
import styles from "../ModuleNavigation.module.css";
import { Button } from "components/common";
import { useEffect } from "react";
import { cx } from "utilities";
import { FLAVOR } from "CONSTANTS";

interface Props {
  currentModule: Module | ExternalManufacturerModule | undefined;
  currentModuleUrl: string | undefined;
  isCallCenterRoute: match<{ routeId: string }> | null;
  isLogisticsRouteLineItems: match<{ routeId: string }> | null;
  isDashboard: match<{}> | null;
  isPickingsList: match<{}> | null;
  isUnloadingsList: match<{}> | null;
  isLoadingsList: match<{}> | null;
  isWarehouseSchema: match<{}> | null;
  isLogisticsRoute: match<{ routeId: string }> | null;
  isSubSectionOpened: boolean;
  setIsSubSectionOpened: React.Dispatch<React.SetStateAction<boolean>>;
  shouldBeOpen: boolean;
  setShouldBeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type CallCenterRouteLocationState = { route: CallCenterRouteDetails } | undefined;

export const NavigationBody = ({
  currentModule,
  currentModuleUrl,
  isCallCenterRoute,
  isLogisticsRouteLineItems,
  isDashboard,
  isLogisticsRoute,
  isPickingsList,
  isUnloadingsList,
  isLoadingsList,
  isWarehouseSchema,
  isSubSectionOpened,
  setIsSubSectionOpened,
  shouldBeOpen,
  setShouldBeOpen,
}: Props) => {
  const location = useLocation<CallCenterRouteLocationState>();
  const history = useHistory();

  assertIsDefined(currentModule);
  assertIsDefined(currentModuleUrl);

  const shouldSectionBeShown = (navigationList: ModuleLink[]) => {
    const strippedLocation = location.pathname.split("/").filter(char => char !== "");
    const indexOfModuleInUrl = strippedLocation.indexOf(currentModuleUrl.replaceAll("/", ""));
    const openedSubModuleUrl = strippedLocation[indexOfModuleInUrl + 1];
    const submodulesInMappedModule = navigationList.map(navigation => navigation.url);
    return submodulesInMappedModule.includes(openedSubModuleUrl);
  };

  const isMain = FLAVOR === "main";

  useEffect(() => {
    if (
      (isCallCenterRoute ||
        isLogisticsRouteLineItems ||
        isLogisticsRoute ||
        isPickingsList ||
        isUnloadingsList ||
        isLoadingsList) &&
      shouldBeOpen
    ) {
      setIsSubSectionOpened(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCallCenterRoute,
    isLogisticsRouteLineItems,
    isLogisticsRoute,
    isPickingsList,
    isUnloadingsList,
    isLoadingsList,
  ]);

  const navigationSections = isMain
    ? moduleConfig[currentModule as Module].navigationSections
    : externalManufacturerModuleConfig[currentModule as ExternalManufacturerModule]
        .navigationSections;

  return (
    <div className={styles.navLeftBody}>
      {navigationSections.map(section => {
        const keyGen = section.navigationList.map(nav => nav.url).join("");

        return isSubSectionOpened && !isDashboard ? (
          shouldSectionBeShown(section.navigationList) && (
            <div key={keyGen} className={styles.linkList}>
              {section.subTitle && shouldBeOpen && (
                <h5 className="text-color-white uppercase fs-12 fw-700 mb-0">{section.subTitle}</h5>
              )}
              {section.navigationList.map(subModule => {
                return (
                  <FiltersSection
                    currentModuleUrl={currentModuleUrl}
                    key={subModule.url}
                    isCallCenterRoute={isCallCenterRoute}
                    isLogisticsRouteLineItems={isLogisticsRouteLineItems}
                    isLogisticsRoute={isLogisticsRoute}
                    subModule={subModule}
                    setAreFiltersOpened={setIsSubSectionOpened}
                  />
                );
              })}
            </div>
          )
        ) : (
          <div className={styles.linkList} key={keyGen}>
            {section.subTitle && shouldBeOpen && (
              <h5 className="text-color-white uppercase fs-12 fw-700 mb-0">{section.subTitle}</h5>
            )}
            {section.navigationList
              .filter(
                subModule =>
                  subModule.label !== "Pulpit" &&
                  subModule.label !== "Trasa" &&
                  subModule.label !== "Zlecenia na trasie" &&
                  !isCallCenterRoute &&
                  !isLogisticsRouteLineItems &&
                  !isLogisticsRoute,
              )
              .map(route => (
                <NavLink
                  className={cx(
                    styles.link,
                    route.isAccessible === false ? styles.linkDisabled : "",
                  )}
                  key={route.url}
                  onClick={evt => {
                    if (route.isAccessible === false) {
                      evt.preventDefault();
                      return;
                    }
                    if (shouldBeOpen && !route.collapseMenuOnClick) {
                      setIsSubSectionOpened(true);
                    }
                    if (route.collapseMenuOnClick) {
                      setShouldBeOpen(false);
                    }
                  }}
                  to={`${currentModuleUrl}/${route.url}`}
                  activeClassName={styles.linkActive}
                >
                  <div className={cx("btnBase16", styles.icon)}>
                    {typeof route.icon === "function" ? (
                      <route.icon size="17" color="neutralWhite48" />
                    ) : (
                      <img
                        alt=""
                        src={route.icon!}
                        style={{ height: "17px", width: "16px", marginBottom: "1px" }}
                      />
                    )}
                  </div>
                  <div className={styles.linkName}>{route.label}</div>
                </NavLink>
              ))}
            {isCallCenterRoute && (
              <div className={styles.filtersSectionTitle}>
                <Button
                  className={styles.goBackBtn}
                  kind="transparent-white"
                  onClick={() => {
                    if (location.state) {
                      return history.push(
                        `${currentModuleUrl}/routes/list/all?panelId=${location.state?.route.id}`,
                      );
                    } else {
                      return history.push(`${currentModuleUrl}/routes/list/all`);
                    }
                  }}
                  size="square-s"
                >
                  <img alt="" src={arrowBackIcon} style={{ height: "16px", width: "16px" }} />
                </Button>
              </div>
            )}
            {isLogisticsRouteLineItems && (
              <div className={styles.filtersSectionTitle}>
                <Button
                  className={styles.goBackBtn}
                  kind="transparent-white"
                  onClick={() => {
                    if (location.state && location.state.route) {
                      return history.push(
                        `/routes/list/active?panelId=${location.state?.route.id}`,
                      );
                    } else {
                      return history.push(
                        `/routes/list/active?panelId=${isLogisticsRouteLineItems!.params.routeId}`,
                      );
                    }
                  }}
                  size="square-s"
                >
                  <img alt="" src={arrowBackIcon} style={{ height: "16px", width: "16px" }} />
                </Button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

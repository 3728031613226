import styles from "./MockupLoader.module.css";
import cx from "classnames";

interface Props {
  on?: boolean;
  className?: string;
  type: "list" | "smallList" | "form" | "drawer" | "map";
}

function getChildrenByType(type: Props["type"]) {
  switch (type) {
    case "list":
      return <ListMockup />;
    case "smallList":
      return <SmallListMockup />;
    case "form":
      return <FormMockup />;
    case "drawer":
      return <DrawerMockup />;
    case "map":
      return <MapMockup />;
    default:
      throw new Error(`Unexpected MockupLoader children type: ${type}`);
  }
}

function iterateXTimes(howMany: number, what: (index: number) => any) {
  const list: typeof what[] = [];
  for (let i = 0; i < howMany; i++) {
    const element = typeof what === "function" ? what(i) : what;
    list.push(element);
  }
  return list;
}

export const MockupLoader = ({ on = true, type, className = "" }: Props) => {
  if (on) {
    return (
      <div
        className={cx(
          {
            [styles.body]: ["list", "form", "drawer", "map"].some(el => el === type),
            [styles.drawerBody]: type === "drawer",
          },
          className,
        )}
      >
        <div className={styles.container}>
          <div className={styles.list}>{getChildrenByType(type)}</div>
        </div>
      </div>
    );
  }
  return null;
};

const MapMockup = () => {
  return (
    <div>
      <div className={cx("d-flex justify-content-between", styles.mapContent)}>
        <div className={styles.blocks}>
          <div className={cx(styles.item, styles.block, styles.block1)}></div>
          <div className={cx(styles.item, styles.block, styles.block2)}></div>
          <div className={cx(styles.item, styles.block, styles.block3)}></div>
        </div>
        <div className="d-flex justify-content-between">
          <div className={cx(styles.item, styles.panel, "mr-3")}></div>
          <div className={cx(styles.item, styles.strip)}></div>
        </div>
      </div>
    </div>
  );
};

const FormMockup = () => {
  return (
    <div>
      <div className={cx("d-flex justify-content-between", styles.formContainer)}>
        <div className="w-100 h-100 mr-1">
          <div className={cx(styles.item, styles.section, styles.section1)}></div>
          <div className={cx(styles.item, styles.section, styles.section2)}></div>
          <div className={cx(styles.item, styles.section, styles.section3)}></div>
        </div>
        {/* <div className={cx(styles.item, styles.panel)}></div> */}
      </div>
    </div>
  );
};

const ListMockup = () => {
  return (
    <div>
      <div className={cx("d-flex", styles.content)}>
        <div className={styles.rows}>
          {iterateXTimes(20, index => (
            <div key={index} className={cx(styles.item, styles.row)}></div>
          ))}
        </div>
        {/* {hasPanel && <div className={cx(styles.item, styles.drawer)}></div>} */}
      </div>
    </div>
  );
};

const SmallListMockup = () => {
  return (
    <div>
      <div className={cx("d-flex")}>
        <div className={cx(styles.rows, "w-100")}>
          {iterateXTimes(3, index => (
            <div key={index} className={cx(styles.item, styles.row)}></div>
          ))}
        </div>
      </div>
    </div>
  );
};

const DrawerMockup = () => {
  return (
    <div>
      <div className={cx(styles.item, styles.drawerHeader)}></div>
      <div className={styles.drawerSections}>
        <div className={cx(styles.item, styles.section, styles.section1)}></div>
        <div className={cx(styles.item, styles.section, styles.section2)}></div>
        <div className={cx(styles.item, styles.section, styles.section3)}></div>
      </div>
      <div className={cx(styles.item, styles.drawerBottom)}></div>
    </div>
  );
};

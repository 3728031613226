export const convertMinutesToHoursAndMinutes = (
  minutes: string | number,
  minutesAbbreviation?: boolean,
): string => {
  const min = Number(minutes);
  const hours = Math.floor(min / 60);
  const remainingMinutes = min % 60;

  let result = "";

  if (hours > 0) {
    result += `${hours}h `;
  }

  if (remainingMinutes > 0 && !minutesAbbreviation) {
    result += `${remainingMinutes}min`;
  }

  if (remainingMinutes > 0 && minutesAbbreviation) {
    result += `${remainingMinutes}m`;
  }

  return result;
};

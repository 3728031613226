import { useCarrier } from "api/logistics/carriers/hooks";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useEffect, useState } from "react";
import { assertIsDefined } from "utilities/assertIsDefined";
import { PanelHeader } from "./panelHeader/PanelHeader";
import { GeneralInfoSection } from "./generalInfoSection/GeneralInfoSection";

export const RightPanel = () => {
  const { query, setQuery } = useQuery();
  const { panelId } = query;
  const close = () => setQuery({ ...(query ?? {}), panelId: "" });
  const [isScrolledOver, setIsScrolledOver] = useState(false);

  const { data: carrier, error, isLoading } = useCarrier(panelId, {
    enabled: Boolean(panelId),
  });

  useEffect(() => {
    setIsScrolledOver(false);
  }, [panelId]);

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(carrier);

  return (
    <DrawerRightPanel className="position-relative" key={panelId}>
      <PanelHeader carrier={carrier} close={close} isScrolledOver={isScrolledOver} />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody setIsScrolledOver={setIsScrolledOver}>
          <GeneralInfoSection carrier={carrier} />
        </RightPanelBody>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};

export const deepPurple = {
  deepPurple12: "#866bff1f",
  deepPurple25: "#F9F8FF",
  deepPurple32: "#866bff52",
  deepPurple50: "#F1EEFF",
  deepPurple75: "#E2DCFF",
  deepPurple100: "#C9BDFF",
  deepPurple200: "#B7A8FC",
  deepPurple300: "#9F8AFF",
  deepPurple400: "#8C73FF",
  deepPurple500: "#7357FF",
  deepPurple600: "#6449F1",
  deepPurple700: "#593EE5",
  deepPurple800: "#412EA6",
  deepPurple900: "#21194D",
};

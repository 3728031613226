import { useQuery } from "hooks";
import { useRoute, useSettledRoute } from "hooks/apiPrimitives";
import { useParams } from "react-router";
import { queryString } from "utilities";
import { defaultOrdering, Tab } from "./RoutesList";

type GetSearchProps = {
  tab: Tab;
  query: {
    [x: string]: string;
  };
  filterByUser: boolean;
};

function getSearch({ tab, query, filterByUser }: GetSearchProps) {
  const tabsQuery = {
    isSettled: tab === "archive" ? true : false,
    status: tab === "departed" ? "DEPARTED" : "",
  };
  return queryString.stringify({
    ...query,
    ...tabsQuery,
    page: query.page || "1",
    ordering: query.ordering || defaultOrdering,
    createdBy: filterByUser === true && query.createdBy ? query.createdBy : "",
  });
}

export const useSearch = () => {
  const { query } = useQuery({ exclude: ["panelId", "filterByUser"] });
  const filterByUser = useQuery().query.filterByUser === "true";
  const { tab } = useParams<{ tab: "active" | "departed" | "archive" }>();
  return getSearch({ tab, query, filterByUser });
};

export const useRouteHook = (id: string) => {
  const { tab } = useParams<{ tab: "active" | "departed" | "archive" }>();
  const route = useRoute(id, { enabled: (tab === "active" || tab === "departed") && Boolean(id) });
  const settledRoute = useSettledRoute(id, { enabled: tab === "archive" && Boolean(id) });

  return tab === "active" || tab === "departed" ? route : settledRoute;
};

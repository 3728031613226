import { FieldDetails, FieldKind } from "api/wms/layout/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import styles from "./LevelSection.module.css";
import { cx } from "utilities";
import { useState } from "react";
import { PackagesFilters } from "../packagesInLocationSection/PackagesInLocationSection";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";

interface Props {
  field: FieldDetails;
  setFilter: <T extends keyof PackagesFilters, U extends PackagesFilters[T]>(
    name: T,
    value: U,
  ) => void;
}

const rackColorDict: Record<number, string> = {
  5: colorPalette.deepPurple300,
  4: colorPalette.magenta300,
  3: "rgb(249, 166, 22)",
  2: "rgb(42, 180, 255)",
  1: "rgb(22, 195, 132)",
};

export const LevelSection = ({ field, setFilter }: Props) => {
  const [selectedLevel, setSelectedLevel] = useState<string | null>(null);

  if (field.wmsKind === FieldKind.RAMP) return null;

  return (
    <RightPanelSection>
      <div className="d-flex gap-4 pt-2">
        <div className="d-flex align-items-start">
          {field.levels.length === 0 && <div className={styles.emptyFill} />}
          {field.wmsKind === FieldKind.RACK &&
            field.levels.map((level, index) => (
              <div
                className="d-flex align-items-center cursor-pointer"
                key={level.id}
                onClick={() => {
                  if (!selectedLevel) {
                    setSelectedLevel(String(level.id));
                    setFilter("level", String(level.id));
                  } else if (selectedLevel && selectedLevel === String(level.id)) {
                    setSelectedLevel(null);
                    setFilter("level", "");
                  } else {
                    setSelectedLevel(String(level.id));
                    setFilter("level", String(level.id));
                  }
                }}
              >
                <div
                  className={cx(styles.level, {
                    [styles.levelPointed]: selectedLevel && String(level.id) === selectedLevel,
                  })}
                >
                  {field.levels.length - index}
                </div>
                <div
                  className={cx("d-flex align-items-center", styles.rackLevel, {
                    [styles.onClick]: selectedLevel && String(level.id) === selectedLevel,
                  })}
                  style={{ background: rackColorDict[field.levels.length - index] }}
                >
                  <div className={cx(styles.rackCell, "d-flex")}>{level.name}</div>
                </div>
              </div>
            ))}
          {field.wmsKind === FieldKind.FIELD &&
            field.levels.map((level, index) => (
              <div className="d-flex align-items-center" key={level.id}>
                <div className={cx(styles.level, styles.shelfSchemeInactive)}>
                  {field.levels.length - index}
                </div>
                <div
                  className={cx(
                    "d-flex align-items-center",
                    styles.rackLevel,
                    styles.rackLevelsInactive,
                  )}
                >
                  <div className={cx(styles.rackCell, "d-flex")}>{level.name}</div>
                </div>
              </div>
            ))}
        </div>
        <div>
          <div className="d-flex align-items-center gap-2 pb-2">
            <Typography color="grey500" fontSize="12" fontWeight="600">
              paczek łącznie w lokalizacji:
            </Typography>
            <Typography fontSize="16" fontWeight="700">
              {field.packagesSummary.totalPackagesCount} szt.
            </Typography>
          </div>
          <div className="d-flex align-items-center gap-2 pb-2">
            <Typography color="grey500" fontSize="12" fontWeight="600">
              łączna waga paczek:
            </Typography>
            <Typography fontSize="14" fontWeight="600">
              {field.packagesSummary.totalWeight ?? 0} kg
            </Typography>
          </div>
        </div>
      </div>
    </RightPanelSection>
  );
};

import { Route } from "components/utils";
import { Dashboard as ReportsDashboard } from "pages/reports/dashboard/Dashboard";
import { NotFound } from "pages/notFound";
import { RouteComponentProps, Switch } from "react-router-dom";

export const Dashboard = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        component={ReportsDashboard}
        exact={true}
        path={`${match.path}`}
        title="Milo - raporty"
      />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};

import { pluralize, yup } from "utilities";

const requiredMsg = (field: string): string => {
  return `Pole ${field} jest wymagane`;
};

const maxLengthMsg = (field: string, chars: number): string => {
  return `Pole ${field} powinno zawierać maksymalnie ${chars} ${pluralize.pl(chars, {
    singular: "znak",
    plural: "znaki",
    other: "znaków",
  })}`;
};

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required(requiredMsg("E-mail"))
    .email("Niepoprawny adres e-mail"),
  firstName: yup
    .string()
    .required(requiredMsg("Imię"))
    .max(20, maxLengthMsg("Imię", 20)),
  lastName: yup
    .string()
    .required(requiredMsg("Nazwisko"))
    .max(50, maxLengthMsg("Nazwisko", 50)),
  phone: yup
    .string()
    .min(9, "Wprowadź poprawny numer telefonu")
    .matches(/^\+?[0-9]+$/, "Numer telefonu powinien zawierać jedynie cyfry")
    .required("Numer telefonu jest wymagany"),
});

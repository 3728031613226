import { TradingDocument } from "api/trading-documents/models";
import { RightPanelSection, RightPanelTableRow } from "components/utils/drawer";
import { notificationSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { EmptyNotificationSection } from "./components/EmptyNotificationSection";
import { Notification } from "./components/Notification";
import { useToggle } from "hooks";
import { SendToSpecificAddressModal } from "./components/SendToSpecificAddressModal";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiSend } from "components/miloDesignSystem/atoms/icons/MdiSend";
import { MdiRestartAlt } from "components/miloDesignSystem/atoms/icons/MdiRestartAlt";
import { tradingDocumentsActions } from "api/trading-documents/actions";

interface Props {
  tradingDocument: TradingDocument;
}

export const NotificationSection = ({ tradingDocument }: Props) => {
  const sendEmailNotificationMutation = tradingDocumentsActions.useSendEmailNotification();
  const modalController = useToggle();

  return (
    <RightPanelSection title="Wysyłka">
      {tradingDocument.notificationsSent.length === 0 ? (
        <EmptyNotificationSection open={modalController.open} tradingDocument={tradingDocument} />
      ) : (
        <div className="pb-3">
          <RightPanelTableRow grid={notificationSectionListConfig.grid} hover={false}>
            <div>#</div>
            <div>akcja</div>
            <div>data</div>
            <div>na adres</div>
            <div>wysyłka</div>
            <div>przez kogo</div>
          </RightPanelTableRow>
          <div>
            {tradingDocument.notificationsSent.map((notification, index) => (
              <Notification key={index} notification={notification} position={index + 1} />
            ))}
          </div>
          <div className="py-2 d-flex align-items-center gap-1">
            <Button
              className="text-uppercase"
              isLoading={sendEmailNotificationMutation.isLoading}
              onClick={() =>
                sendEmailNotificationMutation.mutate({
                  tradingDocumentId: tradingDocument.id,
                })
              }
              size="small"
              startIcon={MdiRestartAlt}
              variant="gray"
            >
              Wyślij ponownie
            </Button>
            <Button
              className="text-uppercase"
              onClick={modalController.open}
              size="small"
              startIcon={MdiSend}
              variant="gray"
            >
              Wskaż adres i wyślij
            </Button>
          </div>
        </div>
      )}
      {modalController.isOpen && (
        <SendToSpecificAddressModal
          close={modalController.close}
          tradingDocumentId={tradingDocument.id}
        />
      )}
    </RightPanelSection>
  );
};

import { Picking } from "api/wms/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { EstimatedTime } from "../../shared/components/EstimatedTime";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { UserWithInitials } from "api/users/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import styles from "./PickingList.module.css";
import { PickingStatus } from "./components/PickingStatus";
import { LinkToPage } from "components/utils/linkToPage";

export const usePickingsColumns = () =>
  useCreateTableColumns<Picking>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "sygnatura",
        size: 130,
        cell: info => {
          const picking = info.getValue();
          return (
            <LinkToPage
              content={picking.signature}
              url={`/wms/pickings/list/all?panelId=${picking.id}`}
            />
          );
        },
      }),
      columnHelper.text(row => row.suppliers.join(", "), {
        header: "kontrahent/produc.",
        size: 110,
      }),
      columnHelper.text(row => "", {
        header: "nazwa",
        size: 110,
      }),
      columnHelper.accessor(row => row, {
        header: "status",
        size: 110,
        cell: info => {
          return <PickingStatus picking={info.getValue()} />;
        },
      }),
      columnHelper.accessor(row => row.estimatedTimeOfLoading, {
        header: "załadunek",
        size: 100,
        cell: info => {
          return <EstimatedTime date={info.getValue()} />;
        },
      }),
      columnHelper.text(row => (row.ramp ? row.ramp.name : null), {
        header: "rampa",
        size: 40,
      }),
      columnHelper.text(row => `${row.progress.total}%`, {
        header: "postęp",
        size: 45,
      }),
      columnHelper.text(row => `${row.progress.onTheWay}%`, {
        header: "skanowanie",
        size: 80,
        typographyProps: {
          color: "deepPurple400",
        },
      }),
      columnHelper.accessor(row => row.numberOfPickedUp, {
        header: "na rampie",
        size: 60,
        cell: info => {
          const numberOfPickedUp = info.getValue();
          return (
            <div className="d-flex align-items-center">
              <Typography color="success500" fontSize="12" fontWeight="700">
                {Number(numberOfPickedUp.onRamp)}
              </Typography>
              <Typography fontSize="12" fontWeight="700">
                /{Number(numberOfPickedUp.total)}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.collaborators, {
        header: "zaangażowani",
        size: 105,
        cell: info => {
          const collaborators: UserWithInitials[] = info.getValue();
          return (
            <div className="d-flex align-items-center">
              {Boolean(collaborators.length) ? (
                collaborators.map(user => (
                  <div className={styles.avatarCover} key={user.id}>
                    <Avatar size="xs" theme="light" user={user} />
                  </div>
                ))
              ) : (
                <Typography fontSize="12" fontWeight="700">
                  ---
                </Typography>
              )}
            </div>
          );
        },
      }),
    ];
  });

import { Unloading, UnloadingDetails } from "api/wms/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { isToday, isTomorrow, isYesterday } from "date-fns";
import { calculateTimeDifference } from "../utils/calculateTimeDifference";
import { dateFns } from "utilities";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { combineDateFormat } from "../utils/combineDateFormat";

interface Props {
  fontSize: TypographyProps["fontSize"];
  fontWeight: TypographyProps["fontWeight"];
  unloading: Unloading | UnloadingDetails;
}

export const UnloadingStart = ({ fontSize, fontWeight, unloading }: Props) => {
  if (unloading.startedAt) {
    if (isToday(new Date(unloading.startedAt))) {
      return (
        <Typography fontSize={fontSize} fontWeight={fontWeight}>
          {dateFns.format(new Date(unloading.startedAt), "H:mm")}
        </Typography>
      );
    }
    if (isYesterday(new Date(unloading.startedAt))) {
      return (
        <Typography fontSize={fontSize} fontWeight={fontWeight}>
          wczoraj, {dateFns.format(new Date(unloading.startedAt), "H:mm")}
        </Typography>
      );
    }
    return (
      <Typography fontSize={fontSize} fontWeight={fontWeight}>
        {dateFns.format(new Date(unloading.startedAt), "d.MM.yyyy")}
      </Typography>
    );
  }

  const combinedDate = combineDateFormat(
    unloading.estimatedDateOfArrivalDate,
    unloading.estimatedDateOfArrivalTime,
  );

  if (!combinedDate)
    return (
      <Typography fontSize={fontSize} fontWeight={fontWeight}>
        ---
      </Typography>
    );

  if (isTomorrow(new Date(combinedDate))) return <Tag label="jutro" variant="outline" />;

  return (
    <Tag
      label={calculateTimeDifference(String(combinedDate)).label}
      variant={calculateTimeDifference(String(combinedDate)).variant}
    />
  );
};

import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx } from "utilities";
import styles from "./Area.module.css";
import { PropsWithChildren } from "react";
import { LayoutSchema } from "api/wms/layout/models";
import { MARGIN_TOP } from "../../WarehouseSchema";

export const Area = ({
  area,
  viewScale,
  children,
}: PropsWithChildren<{ area: LayoutSchema["areas"][number]; viewScale: number }>) => {
  return (
    <div
      className={cx(styles.area, {
        [styles.areaWithoutBorder]: area.outline === "NOT_VISIBLE",
      })}
      style={{
        top: area.coords.y + viewScale + MARGIN_TOP,
        left: area.coords.x + viewScale,
        width: area.width + viewScale,
        height: area.height + viewScale,
      }}
    >
      <div className={styles.areaTitle}>
        <Typography fontSize="100" fontWeight="400">
          {area.verboseName}
        </Typography>
      </div>

      {children}
    </div>
  );
};

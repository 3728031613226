import { polishMonthsAbbreviations } from "CONSTANTS";
import { pl } from "date-fns/locale";
import { dateFns } from "./utilities";

export const convertDateToPolishMonthAbbreviations = (inputDate: string): string => {
  const date: Date = new Date(inputDate);

  const monthIndex = date.getMonth();
  const polishMonthAbbreviation = polishMonthsAbbreviations[monthIndex];

  const formattedDate = dateFns.format(date, "d MMM yyyy", { locale: pl });

  return `${formattedDate.slice(0, 2)} ${polishMonthAbbreviation} ${formattedDate.slice(6)}`;
};

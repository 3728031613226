import exchangeImg from "assets/images/64.svg";
import warrantyImg from "assets/images/308.svg";
import resignationImg from "assets/images/58.svg";
import screwImg from "assets/images/63.svg";
import styles from "./ComplaintsStatusFlow.module.css";
import wrenchImg from "assets/images/57.svg";
import { Order } from "api/orders/models";

interface Props {
  kind: Order["kind"];
  warrantyStatus: Order["warrantyStatus"];
}

const kindDict: Record<Order["kind"], { name: string; img: string }> = {
  RESIGNATION: { name: "Rezygnacja", img: resignationImg },
  REPAIR: { name: "Naprawa", img: wrenchImg },
  SUPPLEMENT: { name: "Uzupełnienie", img: screwImg },
  REPLACEMENT: { name: "Wymiana", img: exchangeImg },
};

const warrantyDict: Record<Order["warrantyStatus"], { name: string; img: string }> = {
  WITHOUT_WARRANTY: { name: "Po gwarancji", img: warrantyImg },
  WITH_WARRANTY: { name: "Na gwarancji", img: warrantyImg },
  "": { name: "", img: "" },
};

export const ComplaintsStatusFlow = ({ kind, warrantyStatus }: Props) => {
  const kindObj = kindDict[kind];
  const warrantyObj = warrantyDict[warrantyStatus];

  return (
    <div className={styles.statuses}>
      {warrantyStatus && (
        <div className={styles.status} title={warrantyObj.name}>
          <div className="mr-2">
            <img src={warrantyObj.img} alt="" />
          </div>
          <div className="text-color-blue fs-12 nowrap">{warrantyObj.name}</div>
        </div>
      )}
      <div className={styles.status} title={kindObj.name}>
        <div className="mr-2">
          <img src={kindObj.img} alt="" />
        </div>
        <div className="text-color-blue fs-12 nowrap">{kindObj.name}</div>
      </div>
    </div>
  );
};

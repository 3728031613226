import { Pagination } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { uniquePackagesKeys } from "./keys";
import { Fee, LongHeldPackage } from "./models";
import { PartialOf } from "typeUtilities";

const getLongHeldPackages = (search: string = ""): ApiFetcher<Pagination<LongHeldPackage>> => {
  const [tab, url, queryParams] = search.split("$");
  return {
    key:
      tab === "withOrders"
        ? uniquePackagesKeys.longHeldPackagesWithOrders(queryParams)
        : uniquePackagesKeys.longHeldPackagesWithoutOrders(queryParams),
    fetcher: () =>
      queryFetch({
        method: "GET",
        url: url + queryParams,
      }),
  };
};

const getFees = (id: number): ApiFetcher<Fee> => ({
  key: uniquePackagesKeys.fee(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `unique-packages/fees/${id}`,
    }),
});

const patchFee = (data: PartialOf<Fee>, id: number) =>
  queryFetch<Fee>({
    method: "PATCH",
    url: `unique-packages/fees/${id}`,
    data,
  });

export const uniquePackagesApi = {
  getFees,
  getLongHeldPackages,
  patchFee,
};

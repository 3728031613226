import styles from "../../Dashboard.module.css";
import { EmptyPurchaseSection } from "./components/EmptyPurchaseSection";

export const PurchaseSection = () => {
  return (
    <div className={styles.card}>
      <EmptyPurchaseSection />
    </div>
  );
};

import { TradingDocument } from "api/trading-documents/models";
import { Button } from "components/common";
import { AsyncInput } from "components/utils";
import { useMutation } from "hooks/useMutation";
import React from "react";
import { getAnyErrorKey } from "utilities";
import styles from "../../EditTradingDocument.module.css";
import companyIcon from "assets/images/factory.svg";
import personIcon from "assets/images/accountCircle.svg";
import addressIcon from "assets/images/locationOn.svg";
import emailIcon from "assets/images/mail.svg";
import phoneIcon from "assets/images/call.svg";
import taskDoneIcon from "assets/images/taskDone.svg";
import cx from "classnames";

interface Props {
  setIsClientEditing: React.Dispatch<React.SetStateAction<boolean>>;
  tradingDocument: TradingDocument;
  updateOptions: any;
}

export const ClientEdit = ({ setIsClientEditing, tradingDocument, updateOptions }: Props) => {
  const recipientCompanyNameMutation = useMutation(updateOptions.mutationFn, updateOptions);
  const recipientFirstNameMutation = useMutation(updateOptions.mutationFn, updateOptions);
  const recipientLastNameMutation = useMutation(updateOptions.mutationFn, updateOptions);
  const recipientStreetMutation = useMutation(updateOptions.mutationFn, updateOptions);
  const recipientPostCodeMutation = useMutation(updateOptions.mutationFn, updateOptions);
  const recipientCityMutation = useMutation(updateOptions.mutationFn, updateOptions);
  const recipientTaxIdMutation = useMutation(updateOptions.mutationFn, updateOptions);
  const recipientEmailMutation = useMutation(updateOptions.mutationFn, updateOptions);
  const recipientPhoneMutation = useMutation(updateOptions.mutationFn, updateOptions);

  return (
    <div>
      <div>
        <div className="d-flex align-items-center mb-2">
          <img alt="Firma" className="mr-2" src={companyIcon} />
          <AsyncInput
            disabled={recipientCompanyNameMutation.isLoading}
            error={getAnyErrorKey(recipientCompanyNameMutation.error)}
            inProgress={recipientCompanyNameMutation.isLoading}
            look="editable"
            onChange={value =>
              recipientCompanyNameMutation.mutate({
                id: tradingDocument.id,
                recipientCompanyName: value,
              })
            }
            placeholder="Wpisz nazwę firmy"
            value={tradingDocument.recipientCompanyName}
          />
        </div>
        <div className="d-flex align-items-center mb-2 w-100 mr-1">
          <div className="d-flex align-items-center w-30">
            <img alt="Osoba" className="mr-2" src={personIcon} />
            <AsyncInput
              disabled={recipientFirstNameMutation.isLoading}
              error={getAnyErrorKey(recipientFirstNameMutation.error)}
              inProgress={recipientFirstNameMutation.isLoading}
              look="editable"
              onChange={value =>
                recipientFirstNameMutation.mutate({
                  id: tradingDocument.id,
                  recipientFirstName: value,
                })
              }
              placeholder="Wpisz imię"
              value={tradingDocument.recipientFirstName}
            />
          </div>
          <div className="d-flex align-items-center w-30 ml-1">
            <img alt="Osoba" className="mr-2" src={personIcon} />
            <AsyncInput
              disabled={recipientLastNameMutation.isLoading}
              error={getAnyErrorKey(recipientLastNameMutation.error)}
              inProgress={recipientLastNameMutation.isLoading}
              look="editable"
              onChange={value =>
                recipientLastNameMutation.mutate({
                  id: tradingDocument.id,
                  recipientLastName: value,
                })
              }
              placeholder="Wpisz nazwisko"
              value={tradingDocument.recipientLastName}
              width="fit-content"
            />
          </div>
        </div>
        <div className="d-flex align-items-center mb-2">
          <img alt="Adres - ulica" className="mr-2" src={addressIcon} />
          <AsyncInput
            disabled={recipientStreetMutation.isLoading}
            error={getAnyErrorKey(recipientStreetMutation.error)}
            inProgress={recipientStreetMutation.isLoading}
            look="editable"
            onChange={value =>
              recipientStreetMutation.mutate({
                id: tradingDocument.id,
                recipientStreet: value,
              })
            }
            placeholder="Wpisz adres"
            value={tradingDocument.recipientStreet}
          />
        </div>
        <div className="d-flex align-items-center mb-2 w-100">
          <div className="d-flex align-items-center w-30 mr-1">
            <img alt="Adres - kod pocztowy" className="mr-2" src={addressIcon} />
            <AsyncInput
              disabled={recipientPostCodeMutation.isLoading}
              error={getAnyErrorKey(recipientPostCodeMutation.error)}
              inProgress={recipientPostCodeMutation.isLoading}
              look="editable"
              onChange={value =>
                recipientPostCodeMutation.mutate({
                  id: tradingDocument.id,
                  recipientPostCode: value,
                })
              }
              placeholder="Wpisz kod pocztowy"
              value={tradingDocument.recipientPostCode}
            />
          </div>
          <div className="d-flex align-items-center w-70 ml-1">
            <img alt="Adres - miasto" className="mr-2" src={addressIcon} />
            <AsyncInput
              disabled={recipientCityMutation.isLoading}
              error={getAnyErrorKey(recipientCityMutation.error)}
              inProgress={recipientCityMutation.isLoading}
              look="editable"
              onChange={value =>
                recipientCityMutation.mutate({
                  id: tradingDocument.id,
                  recipientCity: value,
                })
              }
              placeholder="Wpisz miasto"
              value={tradingDocument.recipientCity}
            />
          </div>
        </div>
        <div className="d-flex align-items-center mb-2">
          <span className="mr-2">NIP</span>
          <AsyncInput
            disabled={recipientTaxIdMutation.isLoading}
            error={getAnyErrorKey(recipientTaxIdMutation.error)}
            inProgress={recipientTaxIdMutation.isLoading}
            look="editable"
            onChange={value =>
              recipientTaxIdMutation.mutate({
                id: tradingDocument.id,
                recipientTaxId: value,
              })
            }
            placeholder="Wpisz NIP"
            value={tradingDocument.recipientTaxId}
          />
        </div>
        <div className="d-flex align-items-center mb-2">
          <img alt="Email" className="mr-2" src={emailIcon} />
          <AsyncInput
            disabled={recipientEmailMutation.isLoading}
            error={getAnyErrorKey(recipientEmailMutation.error)}
            inProgress={recipientEmailMutation.isLoading}
            look="editable"
            onChange={value =>
              recipientEmailMutation.mutate({
                id: tradingDocument.id,
                recipientEmail: value,
              })
            }
            placeholder="Wpisz adres email"
            value={tradingDocument.recipientEmail}
          />
        </div>
        <div className="d-flex align-items-center mb-2">
          <img alt="Telefon" className="mr-2" src={phoneIcon} />
          <AsyncInput
            disabled={recipientPhoneMutation.isLoading}
            error={getAnyErrorKey(recipientPhoneMutation.error)}
            inProgress={recipientPhoneMutation.isLoading}
            look="editable"
            onChange={value =>
              recipientPhoneMutation.mutate({
                id: tradingDocument.id,
                recipientPhone: value,
              })
            }
            placeholder="Wpisz numer telefonu"
            value={tradingDocument.recipientPhone}
          />
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-end">
        <Button
          className={styles.editDataBtn}
          kind="secondary-grey"
          onClick={() => setIsClientEditing(prev => !prev)}
          size="small"
        >
          <div className={cx(styles.editDataBtnContent, "d-flex align-items-center")}>
            <img alt="Edytuj" src={taskDoneIcon} style={{ height: "16px", width: "16px" }} />
            <span>Zakończ edycję</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

import { Button } from "components/common";
import menuIcon from "assets/images/menu.svg";
import whiteFiltersIcon from "assets/images/filtersWhite.svg";
import whitePdfDownloadIcon from "assets/images/downloadPdfWhite.svg";
import greenCheckIcon from "assets/images/checkSmallTeal.svg";
import blackCheckIcon from "assets/images/check_small.svg";
import localStyles from "./Header.module.css";
import cx from "classnames";
import { Link } from "react-router-dom";
import { ProductionPlan } from "api/new-production-plans/models";
import { useProductionPlanDetailsPatchMutation } from "api/new-production-plans/hooks";
import { AsyncInput } from "components/utils";

interface Props {
  productionPlan: ProductionPlan;
}

export const Header = ({ productionPlan }: Props) => {
  const confirmMutation = useProductionPlanDetailsPatchMutation();
  const nameMutation = useProductionPlanDetailsPatchMutation();

  return (
    <div className={localStyles.header}>
      <div>
        <Button kind="transparent-white" size="square-s" style={{ borderRadius: "4px" }}>
          <div className="btnBase btnBaseSmall btnBase16">
            <img alt="menu" src={menuIcon} />
          </div>
        </Button>
      </div>
      <div className="d-flex align-items-center justify-content-center gap-2">
        <Link
          to="/manufacturing/manufacturing-plans/list"
          className={cx(localStyles.link, "Link text-color-grey")}
        >
          <div className="fw-700">Plany Produkcji / </div>
        </Link>
        <div>{productionPlan.signature}</div>
        <div className="text-deep-purple-4">
          {productionPlan.masterStage ? productionPlan.masterStage.name : "---"}
        </div>
        <AsyncInput
          disabled={nameMutation.isLoading}
          inProgress={nameMutation.isLoading}
          onChange={value =>
            nameMutation.mutate({
              id: productionPlan.id,
              toUpdate: {
                name: value,
              },
            })
          }
          overwrites={{
            container: { className: localStyles.nameInputContainer },
            input: {
              className: cx(
                localStyles.input,
                localStyles.inputSmall,
                localStyles.inputOnDarkBg,
                localStyles.borderTransparent,
                "w-100 max-w-100",
              ),
            },
          }}
          placeholder="Nadaj nazwę"
          value={productionPlan.name}
        />
      </div>
      <div className="d-flex justify-content-end align-items-center gap-1">
        <Button
          kind={productionPlan.isConfirmed ? "tealGreen" : "transparentTealGreen2"}
          onClick={() =>
            confirmMutation.mutate({
              id: productionPlan.id,
              toUpdate: {
                isConfirmed: productionPlan.isConfirmed ? false : true,
              },
            })
          }
          size="small"
          style={{ width: "79px" }}
        >
          <div className="btnBase btnBaseSmall btnBase16">
            <img alt="status" src={productionPlan.isConfirmed ? blackCheckIcon : greenCheckIcon} />
            Gotowy
          </div>
        </Button>

        <div className="dividerOnDark" />

        <Button kind="transparent-white" size="square-s" style={{ borderRadius: "4px" }}>
          <div className="btnBase btnBaseSmall btnBase16">
            <img alt="filtry" src={whiteFiltersIcon} />
          </div>
        </Button>
        <Button kind="transparent-white" size="square-s" style={{ borderRadius: "4px" }}>
          <div className="btnBase btnBaseSmall btnBase16">
            <img alt="pdf" src={whitePdfDownloadIcon} />
          </div>
        </Button>
      </div>
    </div>
  );
};

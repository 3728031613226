import { Route } from "api/routes/models";
import { Button } from "components/common";
import checkIcon from "assets/images/check_small.svg";
import { useRouteConfirmMutation } from "api/logistics/routes/hooks";
import styles from "../../RightPanel.module.css";
import cx from "classnames";

interface Props {
  route: Route;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MarkRouteReadyBtn = ({ route, setShowLoader }: Props) => {
  const confirmRouteMutation = useRouteConfirmMutation(setShowLoader);

  return (
    <div>
      <Button
        className={cx("pb-0 pt-0", {
          [styles.btnReady]: !route.isDraft,
        })}
        disabled={route.status === "SETTLED" || confirmRouteMutation.isLoading}
        kind="confirm"
        onClick={() => {
          confirmRouteMutation.mutate(
            {
              id: route.id,
              toUpdate: {
                isDraft: !route.isDraft,
              },
            },
            {
              onSettled: () => {
                setShowLoader(false);
              },
            },
          );
        }}
        size="small"
      >
        <div className="btnBase btnBaseSmall btnBase16">
          <img alt="Gotowe" src={checkIcon} />
          <div className="fs-10">{route.isDraft ? "Oznacz jako gotową" : "Gotowa"}</div>
        </div>
      </Button>
    </div>
  );
};

import { PropsWithChildren } from "react";
import { cx } from "utilities";
import { TypographyProps } from "./types";
import styles from "./Typography.module.css";
import { colorPalette } from "../colorsPalette";
import { Assign } from "utility-types";

export const Typography = (props: PropsWithChildren<TypographyProps>) => {
  return (
    <div
      style={{
        fontWeight: Number(props.fontWeight),
        color:
          props.color === "inherit" ? "inherit" : colorPalette[props.color || "neutralBlack100"],
      }}
      className={cx(styles.typography, fontSizeToStyleDict[props.fontSize], props.className, {
        [styles.noWrap]: props.noWrap,
      })}
    >
      {props.children}
    </div>
  );
};

const fontSizeToStyleDict: Record<TypographyProps["fontSize"], string> = {
  "10": styles.fs10,
  "12": styles.fs12,
  "14": styles.fs14,
  "16": styles.fs16,
  "18": styles.fs18,
  "20": styles.fs20,
  "26": styles.fs26,
  "64": styles.fs64,
  "100": styles.fs100,
};

Typography.WithCustomColor = (
  props: PropsWithChildren<Assign<TypographyProps, { color: string }>>,
) => {
  return (
    <div
      style={{
        fontWeight: Number(props.fontWeight),
        color: props.color,
      }}
      className={cx(styles.typography, styles[`fs${props.fontSize}`], props.className, {
        [styles.noWrap]: props.noWrap,
      })}
    >
      {props.children}
    </div>
  );
};

import { useSelector } from "hooks";
import { Props } from "./types";
import { Route } from "./Route";
import { Login } from "pages/login";

export function AuthRoute(props: Props) {
  const authenticated = useSelector(state => state.auth.isAuthenticated);
  if (!authenticated) {
    return <Login />;
  }
  return <Route {...props} />;
}

export type DateOptions = { label: string; value: string }[];

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date
    .getDate()
    .toString()
    .padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const createDepartureDateFilterOptions = (): DateOptions => {
  const currentDate = new Date();
  const newDates: DateOptions = [];

  for (let it = 3; it > -3; it--) {
    const date = new Date();
    date.setDate(currentDate.getDate() - it);

    let dayLabel = "";

    switch (it) {
      case 3:
        dayLabel = "3 dni temu";
        break;
      case 2:
        dayLabel = "2 dni temu";
        break;
      case 1:
        dayLabel = "wczoraj";
        break;
      case 0:
        dayLabel = "dzisiaj";
        break;
      case -1:
        dayLabel = "jutro";
        break;
      case -2:
        dayLabel = "pojutrze";
        break;
    }

    const dateKey = formatDate(date);

    newDates.push({ label: dayLabel, value: String(dateKey) });
  }

  return newDates;
};

import { Modal } from "components/utils";
import { Button } from "components/common";
import closeIcon from "assets/images/x.svg";
import arrowLeft from "assets/images/keyboardArrowLeft.svg";
import arrowRight from "assets/images/keyboardArrowRight.svg";
import arrowOutward from "assets/images/arrowOutward.svg";
import styles from "./SharedStyles.module.css";
import messageStyles from "./messagesWithCustomer/MessagesWithCustomer.module.css";
import { AvatarWithName } from "components/common/avatarWithName";
import { useMutation } from "hooks/useMutation";
import { useEffect } from "react";
import { patchMessage } from "api/messages/calls";
import { useQueryClient } from "react-query";
import { callCenterRoutesKeys } from "api/call-center/routes/keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Pagination } from "api/types";
import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import cx from "classnames";
import { OrderMessagesSchema } from "api/orders/models";

interface ModalProps {
  close: () => void;
  orderMessagesSchema: OrderMessagesSchema;
  search: string;
  orderId: number;
}

export const UnreadSmsModal = ({ close, orderMessagesSchema, search, orderId }: ModalProps) => {
  const queryClient = useQueryClient();
  const patchMessageMutation = useMutation(patchMessage);

  const unreadMessages = orderMessagesSchema.messages.filter(
    message => !message.isRead && message.wayOfCommunication === "INCOMING",
  );

  useEffect(() => {
    const unreadMessagesHandler = setTimeout(() => {
      unreadMessages
        .map(message => message.id)
        .forEach(messageId => {
          patchMessageMutation.mutate({ id: String(messageId), data: { isRead: true } });
        });
      queryClient.setQueryData<Pagination<CallCenterRouteOrderListItem>>(
        callCenterRoutesKeys.routeOrder.list(search),
        currentList => {
          assertIsDefined(currentList);
          return {
            ...currentList,
            results: currentList.results.map(result => {
              if (result.id === orderMessagesSchema.order.id) {
                result.hasUnreadMessages = false;
              }
              return result;
            }),
          };
        },
      );
    }, 5000);

    return () => {
      clearTimeout(unreadMessagesHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      overrides={{ container: { className: styles.modalContainer } }}
      isOpen
      close={close}
      showCloseBtn={false}
    >
      <>
        <div className="d-flex align-items-center justify-content-between p-3">
          <h2>Nieodczytane wiadomości od:</h2>
          <Button onClick={close} kind="transparent" size="square-xs">
            <div className="btnBase btnBase16 btnBaseSmall">
              <img src={closeIcon} alt="" />
            </div>
          </Button>
        </div>
        <div className="px-3 pb-2">
          <div className="d-flex align-items-center gap-2 pb-2">
            <div className="d-flex align-items-center gap-2">
              <AvatarWithName
                user={{
                  firstName: orderMessagesSchema.client.firstName,
                  lastName: orderMessagesSchema.client.lastName,
                  avatar: orderMessagesSchema.client.avatar,
                }}
              />
            </div>
            <div className="body-14-600">{orderMessagesSchema.order.signature}</div>
            <div className=" fs-12 text-black-6">Brak daty</div>
          </div>
          <div className="pb-2">
            <div className="d-flex flex-column w-100 align-items-start">
              {unreadMessages.map(message => (
                <div
                  className={cx(messageStyles.cloud, messageStyles.cloudClient)}
                  key={message.id}
                >
                  {message.body}
                </div>
              ))}
            </div>

            <div className="pt-3">
              <Button size="btnSizeSmall" className="btn" kind="btnBgBlack5">
                <div className="uppercase btnBase btnBase16 btnBaseSmall">
                  Pokaż całą konwersację
                  <img src={arrowOutward} alt="" />
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between p-3 borderTop">
          <Button size="medium" kind="transparent-black" onClick={close}>
            <div className="btnBase btnBaseMedium">ZAMKNIJ</div>
          </Button>

          <div className=" d-flex align-items-center gap-3">
            <Button size="btnSizeSmall" kind="btnBgBlack5">
              <div className="uppercase btnBase btnBase16 btnBaseSmall">
                <img src={arrowLeft} alt="" />
                Poprzednie
              </div>
            </Button>

            <Button size="btnSizeSmall" kind="btnBgBlack5">
              <div className="uppercase btnBase btnBase16 btnBaseSmall">
                Następne
                <img src={arrowRight} alt="" />
              </div>
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};

import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { Pagination, UUID } from "api/types";
import {
  communicationSettingsKeys,
  exchangeRatesSettingsKeys,
  pdfSettingsKeys,
  remindersSettingsKeys,
  settingsKeys,
  wmsSettingsKeys,
} from "api/milo-settings/keys";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { Assign, DeepPartial } from "utility-types";
import {
  ConfirmationDateOptions,
  CreateCurrencyToWatch,
  CreateCustomersToRemind,
  CustomerToRemind,
  CreateDedicatedPhoneNumber,
  DedicatedPhoneNumber,
  DeliveryHoursTestPayload,
  ExchangeRates,
  MiloSettings,
  PostConfirmationDateOptions,
  Reminders,
  Tab,
  TradingDocument,
  ReminderSchedule,
  ModifyReminderSchedule,
  CreateScheduleDTO,
  AddDefaultReviewer,
  AddDefaultReviewerPayload,
  CategoryVisibleInWmsTablePayload,
  CategoryVisibleInWmsTable,
  PdfSettings,
} from "./models";

export const getMiloSettings = <T extends keyof typeof Tab>(
  tab: T,
): ApiFetcher<MiloSettings[T]> => ({
  key: settingsKeys[tab],
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/milo-settings/${tab}`,
    }),
});

export const getPdfSettings = (): ApiFetcher<PdfSettings> => ({
  key: pdfSettingsKeys.pdfs.details(),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/milo-settings/root/pdf",
    }),
});

export const getExchangeRatesSettings = (
  search: string = "",
): ApiFetcher<Pagination<ExchangeRates>> => ({
  key: exchangeRatesSettingsKeys.exchangeRates.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/exchange-rates/settings/currencies-to-watch" + search,
    }),
});

export const getCategoriesVisibleInWmsTable = (
  search: string = "",
): ApiFetcher<Pagination<CategoryVisibleInWmsTable>> => ({
  key: wmsSettingsKeys.categoriesVisibleInTable.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "wms/settings/category-visible-in-wms-table" + search,
    }),
});

export const getRemindersSettings = (search: string = ""): ApiFetcher<Pagination<Reminders>> => ({
  key: remindersSettingsKeys.reminders.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/settings/reminder" + search,
    }),
});

export const getCustomersToRemind = (
  search: string = "",
): ApiFetcher<Pagination<CustomerToRemind>> => ({
  key: remindersSettingsKeys.reminders.customersToRemind(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/settings/customers-to-remind" + search,
    }),
});

export const getTradingDocumentsSettings = ({ id }: { id: UUID }): ApiFetcher<TradingDocument> => ({
  key: tradingDocumentsKeys.tradingDocument.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/settings/trading-document/" + id,
    }),
});

export const getDedicatedPhoneNumbers = (
  search: string = "",
): ApiFetcher<Pagination<DedicatedPhoneNumber>> => ({
  key: communicationSettingsKeys.dedicatedNumber.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/milo-settings/communication/dedicated-phone-numbers",
    }),
});

export const patchPdfSettings = (data: DeepPartial<PdfSettings>) =>
  queryFetch<PdfSettings>({
    method: "PATCH",
    url: `/milo-settings/root/pdf`,
    data,
  });

export const postDedicatedPhoneNumber = (data: CreateDedicatedPhoneNumber) =>
  queryFetch<DedicatedPhoneNumber>({
    method: "POST",
    url: "/milo-settings/communication/dedicated-phone-numbers/",
    data,
  });

export const postCategoryVisibleInWmsTable = (data: CategoryVisibleInWmsTablePayload) =>
  queryFetch<CategoryVisibleInWmsTable>({
    method: "POST",
    url: "wms/settings/category-visible-in-wms-table",
    data,
  });

export const deleteCategoryVisibleInWmsTable = (id: string | number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "wms/settings/category-visible-in-wms-table/" + id,
  });

export const deleteDedicatedPhoneNumber = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/milo-settings/communication/dedicated-phone-numbers/" + id,
  });

export const postCurrencyToWatch = (data: CreateCurrencyToWatch) =>
  queryFetch<ExchangeRates>({
    method: "POST",
    url: "/exchange-rates/settings/currencies-to-watch",
    data,
  });

export const deleteCurrencyToWatch = (id: string) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/exchange-rates/settings/currencies-to-watch/" + id,
  });

export const postCustomerToRemind = (data: CreateCustomersToRemind) =>
  queryFetch<CustomerToRemind>({
    method: "POST",
    url: "/finances/settings/customers-to-remind",
    data,
  });

export const deleteCustomerToRemind = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/finances/settings/customers-to-remind/" + id,
  });

export const postHistoricalExchangeRates = (data: Record<string, never>) =>
  queryFetch<void>({
    method: "POST",
    url: "/exchange-rates/get-historical-exchange-rates",
    data,
  });

export const patchTransport = (data: DeepPartial<MiloSettings["transport"]>) =>
  queryFetch<MiloSettings["transport"]>({
    method: "PATCH",
    url: `/milo-settings/transport`,
    data,
  });

export const postConfirmationDateOptions = (data: PostConfirmationDateOptions) =>
  queryFetch<ConfirmationDateOptions>({
    method: "POST",
    url: `/milo-settings/confirmation-deadline-options/`,
    data,
  });

export const removeConfirmationDateOption = (id: string) =>
  queryFetch<void>({
    method: "DELETE",
    url: `/milo-settings/confirmation-deadline-options/` + id,
  });

export const patchSales = (data: DeepPartial<MiloSettings["sales"]>) =>
  queryFetch<MiloSettings["sales"]>({
    method: "PATCH",
    url: `/milo-settings/sales`,
    data,
  });

export const patchWarehouse = (data: DeepPartial<MiloSettings["warehouse"]>) =>
  queryFetch<MiloSettings["warehouse"]>({
    method: "PATCH",
    url: `/milo-settings/warehouse`,
    data,
  });

export const patchTradingDocument = (data: DeepPartial<MiloSettings["tradingDocument"]>) =>
  queryFetch<MiloSettings["tradingDocument"]>({
    method: "PATCH",
    url: `/finances/settings/trading-document/${data.id}`,
    data,
  });

export const patchReminder = (data: DeepPartial<MiloSettings["reminders"]>) =>
  queryFetch<MiloSettings["reminders"]>({
    method: "PATCH",
    url: `/finances/settings/reminder/${data.id}`,
    data,
  });

export const patchProduction = (data: DeepPartial<MiloSettings["production"]>) =>
  queryFetch<MiloSettings["production"]>({
    method: "PATCH",
    url: `/milo-settings/production`,
    data,
  });

export const patchLabels = (data: DeepPartial<MiloSettings["labels"]>) =>
  queryFetch<MiloSettings["labels"]>({
    method: "PATCH",
    url: `/milo-settings/labels`,
    data,
  });

export const patchCommunication = (data: DeepPartial<MiloSettings["communication"]>) =>
  queryFetch<MiloSettings["communication"]>({
    method: "PATCH",
    url: `/milo-settings/communication`,
    data,
  });

export const getAllMiloSettings = () =>
  queryFetch<MiloSettings>({
    method: "GET",
    url: "/milo-settings",
  });

export const getMiloSettingsRevision = () =>
  queryFetch<{ revision: number }>({
    method: "GET",
    url: "/milo-settings/revision",
  });

export const postDeliveryHoursTest = (data: DeliveryHoursTestPayload) =>
  queryFetch<{
    minimumDeliveryHour: string;
    maximumDeliveryHour: string;
  }>({
    method: "POST",
    url: "/milo-settings/test/delivery-hours",
    data,
  });

export const postTestEmailMessage = (data: { email: string }) =>
  queryFetch<"ok">({
    method: "POST",
    url: "/messages/send-test-email-message",
    data,
  });

export const getRemindersSchedule = (
  search: string = "",
): ApiFetcher<Pagination<ReminderSchedule>> => ({
  key: remindersSettingsKeys.reminders.remindersSchedule(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/settings/reminder-schedule" + search,
    }),
});

export const patchReminderSchedule = (
  data: Assign<Partial<ModifyReminderSchedule>, { id: UUID }>,
) =>
  queryFetch<ReminderSchedule>({
    method: "PATCH",
    url: "/finances/settings/reminder-schedule/" + data.id,
    data,
  });

export const deleteReminderSchedule = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/finances/settings/reminder-schedule/" + id,
  });

export const postReminderSchedule = (data: CreateScheduleDTO) =>
  queryFetch<ReminderSchedule>({
    method: "POST",
    url: "/finances/settings/reminder-schedule",
    data,
  });

export const postDefaultReviewer = (
  data: Assign<AddDefaultReviewer, { tradingDocumentSettings: UUID }>,
) =>
  queryFetch<AddDefaultReviewerPayload>({
    method: "POST",
    url: "/finances/settings/reviewers",
    data,
  });

export const deleteDefaultReviewer = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/finances/settings/reviewers/" + id,
  });

import cx from "classnames";
import styles from "./StatusFlow.module.css";
import packingIcon from "assets/images/23.svg";
import transportIcon from "assets/images/24.svg";
import deliveryIcon from "assets/images/25.svg";
import settledIcon from "assets/images/26.svg";
import { Status } from "api/routes/models";

interface Props {
  status: Status;
}

const statusToNumber = (status: Status) => {
  if (status === "NEW") return 0;
  if (status === "ROUTE_READY") return 1;
  if (status === "DEPARTED") return 2;
  if (status === "FINISHED") return 3;
  if (status === "SETTLED") return 4;
  throw new Error("Unknown route status: " + status);
};

const statusesDictionary: { [key: string]: { img: string; name: string } } = {
  0: { img: packingIcon, name: "Nowe" },
  1: { img: transportIcon, name: "Gotowe" },
  2: { img: deliveryIcon, name: "W trasie" },
  3: { img: settledIcon, name: "Zakończono" },
  4: { img: settledIcon, name: "Rozliczono" },
};

function getClassName(status: string, routeStatus: number) {
  const numStatus = Number(status);

  if (numStatus < routeStatus) return styles.statusPassed;
  if (numStatus === routeStatus) return styles.statusInProgress;
  return "";
}

export const RouteStatusFlow = ({ status }: Props) => {
  const routeStatus = statusToNumber(status);

  if (routeStatus === null) {
    return null;
  }
  return (
    <div className={styles.statusesFlow}>
      {Object.keys(statusesDictionary).map(key => (
        <div
          className={cx(styles.status, getClassName(key, routeStatus))}
          key={key}
          title={statusesDictionary[key].name}
        >
          <div className={styles.statusIcon}>
            <img src={statusesDictionary[key].img} alt="" />
          </div>
        </div>
      ))}
    </div>
  );
};

import ReactDOM from "react-dom";
import "react-datepicker/dist/react-datepicker.css";
import "@reach/menu-button/styles.css";
import "@reach/dialog/styles.css";
import "assets/css/bootstrapOverwrites.css";
import "assets/css/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import version from "./VERSION";

// @ts-ignore
window.appVersion = version;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { graphhopper } from "api/graphhopper/graphhopper";
import { Route } from "api/routes/models";
import { StatusHandler, StatusHandlerHelpers, Switch } from "components/utils";
import { useSelector, useToastr } from "hooks";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useRouteViewState } from "../../routeCreatorState";
import { getFullRouteCoords } from "../../utils";

export const GraphhopperPolylineSwitch = ({ route }: { route: Route }) => {
  const toastr = useToastr();
  const actions = useRouteViewState("slave", store => store.actions);
  const isGraphhopperPolylineVisible = useRouteViewState(
    "slave",
    store => store.isGraphhopperPolylineVisible,
  );
  const defaultStartingPoint = useSelector(state =>
    state.partials.startingPoints.find(el => el.isDefault),
  );
  const startingPoint = route?.startingPoint || defaultStartingPoint;

  async function handleTogglePolyline(val: boolean, helpers: StatusHandlerHelpers) {
    if (val) {
      helpers.startFetching();
      assertIsDefined(startingPoint);

      const startingPointLngLat = [startingPoint.point.lng, startingPoint.point.lat] as [
        number,
        number,
      ];

      const ordersPositionsLatLng = [...route.ordersPositions]
        .map(el => el.meta?.point && [el.meta.point.lng, el.meta.point.lat])
        .filter(Boolean) as number[][];

      const points = getFullRouteCoords(route, ordersPositionsLatLng, startingPointLngLat);

      const payload = await graphhopper.route({
        points,
        vehicle: route.vehicleType,
        includeLastPointInOptimization: route.includeLastPointInOptimization,
      });

      helpers.stopFetching();

      if (payload) {
        actions.setIsGraphhopperPolylineVisible(true);
        actions.saveGraphhopperPolyline(payload.polyline);
      } else {
        actions.setIsGraphhopperPolylineVisible(false);
        toastr.open({
          type: "failure",
          title: "Nie udało się pobrać linii trasy",
          text: "",
        });
      }
    } else {
      actions.setIsGraphhopperPolylineVisible(false);
    }
  }

  return (
    <StatusHandler>
      {helpers => (
        <Switch
          onChange={val => handleTogglePolyline(val, helpers)}
          name=""
          overrides={{ label: { className: "fs-12" } }}
          checked={isGraphhopperPolylineVisible}
          disabled={helpers.isFetching}
          label="Pokaż rzeczywisty przebieg trasy"
          color="blue"
        />
      )}
    </StatusHandler>
  );
};

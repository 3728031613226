import { useState, useEffect, useRef, forwardRef, ForwardedRef } from "react";
import * as React from "react";
import { mergeRefs } from "utilities/mergeRefs";

const AutoTextareaInner = (
  props: React.HTMLAttributes<HTMLTextAreaElement> & { autoFocus?: boolean; disabled?: boolean },
  ref: ForwardedRef<HTMLTextAreaElement>,
) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState("");
  const [textAreaHeight, setTextAreaHeight] = useState("auto");
  const [parentHeight, setParentHeight] = useState("auto");

  useEffect(() => {
    setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
    setTextAreaHeight(`${textAreaRef.current!.scrollHeight}px`);
  }, [text]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaHeight("auto");
    setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
    setText(event.target.value);

    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div
      style={{
        minHeight: parentHeight,
      }}
    >
      <textarea
        {...props}
        ref={mergeRefs(textAreaRef, ref)}
        rows={1}
        style={{
          height: textAreaHeight,
          minHeight: 32,
        }}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export const AutoTextarea = forwardRef(AutoTextareaInner);

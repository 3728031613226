import { PreviewCorrectionDocument } from "api/trading-documents/models";
import cx from "classnames";
import { CreateCorrectionValues } from "../CreateCorrectionManuallyModal";
import styles from "../CreateCorrectionManuallyModal.module.css";
import { AmountPerVatRate } from "./AmountPerVatRate";
import { WholeAmountToPay } from "./WholeAmountToPay";
import { SummaryAmount } from "./SummaryAmount";

interface Props {
  preview: PreviewCorrectionDocument;
  values: CreateCorrectionValues;
}

export const SummarySection = ({ preview, values }: Props) => {
  return (
    <div className="pt-4">
      <div className={cx(styles.tableHeader, styles.tableHeaderPositionsSummary)}>
        <div />
        <div className="d-flex align-items-center justify-content-end">kwota</div>
        <div className="d-flex align-items-center justify-content-end" />
        <div>VAT</div>
        <div className="d-flex align-items-center justify-content-end">wartość netto</div>
        <div className="d-flex align-items-center justify-content-end">kwota VAT</div>
        <div className="d-flex align-items-center justify-content-end">Wartość brutto</div>
      </div>
      {preview.amountSummaryPerVatRate.map((summary, index) => (
        <div className={cx(styles.tableRow, styles.tableRowPositionsSummary)} key={index}>
          <div />
          <div className="d-flex align-items-center justify-content-end">
            {index === 0 && (
              <WholeAmountToPay
                currency={preview.currency}
                fieldName="amountWithTax"
                values={values}
              />
            )}
          </div>
          {index === 0 ? (
            <div className="d-flex align-items-center justify-content-end text-grey-35">w tym:</div>
          ) : (
            <div />
          )}
          <div>{summary.vatRate}%</div>
          <AmountPerVatRate
            fieldName="amountWithoutTax"
            values={values}
            vatRate={summary.vatRate}
          />
          <AmountPerVatRate
            fieldName="taxAmountBeforeRounding"
            values={values}
            vatRate={summary.vatRate}
          />
          <AmountPerVatRate fieldName="amountWithTax" values={values} vatRate={summary.vatRate} />
        </div>
      ))}
      <div className={cx(styles.tableRow, styles.tableRowPositionsSummary)}>
        <div />
        <div />
        <div className="d-flex align-items-center justify-content-end text-grey-35">suma:</div>
        <div />
        <div className="d-flex align-items-center justify-content-end" />
        <div className="d-flex align-items-center justify-content-end">
          <strong className="body-14-600 fw-800">
            <SummaryAmount
              currency={preview.currency}
              fieldName="taxAmountBeforeRounding"
              summaries={preview.amountSummaryPerVatRate}
              values={values}
            />
          </strong>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <strong className="body-14-600 fw-800">
            <SummaryAmount
              currency={preview.currency}
              fieldName="amountWithTax"
              summaries={preview.amountSummaryPerVatRate}
              values={values}
            />
          </strong>
        </div>
      </div>
    </div>
  );
};

import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./CreateCourierModal.module.css";
import { Formik } from "formik";
import { cx } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button";
import { CreateCourier } from "api/shipping/models";
import { FormInput, FormSelect } from "components/utils";
import { validationSchema } from "./validationSchema";
import { shippingActions } from "api/shipping/actions";
import { ShippingService, shippingServiceConstants } from "constants/shippingService";

interface Props {
  close: () => void;
}

const initialValues: CreateCourier = {
  name: "",
  provider: ShippingService.AMBRO,
  username: "",
  password: "",
};

const providers: { id: string; name: string }[] = Object.entries(shippingServiceConstants).map(
  ([id, values]) => ({
    id,
    name: values.name,
  }),
);

export const CreateCourierModal = ({ close }: Props) => {
  const handleSubmit = shippingActions.usePostCourier(close);

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj integrację z kurierem
        </Typography>
      }
      width={480}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, values }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className={cx(styles.modalForm, "d-flex flex-column p-3")}>
              <FormSelect
                overwrites={{ wrapper: { className: "w-100" }, button: { className: "w-100" } }}
                label="Serwis"
                name="provider"
                items={providers}
                itemToSelection={item => (item && item.id !== "-" ? item.id : "")}
                selectedItem={values.provider}
              />
              <FormInput name="username" label="Nazwa użytkownika" />
              <FormInput
                name="password"
                type="password"
                label="Hasło"
                autoComplete="new-password"
              />
              <FormInput name="name" label="Nazwa" />
            </div>
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Dodaj{isSubmitting && "..."}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

import { Route } from "api/routes/models";
import pointsImg from "assets/images/302.svg";
import calendarImg from "assets/images/4.svg";
import completeImg from "assets/images/7g.svg";
import cx from "classnames";
import { useRoutePatchMutation } from "hooks/apiPrimitives";
import { PdfPopover } from "pages/routes/shared/pdfPopover/PdfPopover";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./LeftPanel.module.css";
import { PointsList } from "./pointsList";
import { RouteDetails } from "./routeDetails";

export type Tab = "points" | "route";

interface Props {
  route: Route;
}

export const LeftPanel = ({ route }: Props) => {
  const [tab, setTab] = useState<Tab>("points");
  const patchMutation = useRoutePatchMutation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <div className="d-flex align-items-center justify-content-between">
            <h2 className={styles.signature}>{route.signature}</h2>
            <div className="d-flex align-items-center justify-content-center">
              <Link to="/routes/list" className={styles.routesLink}>
                Lista tras
              </Link>

              <div className="mx-3">
                <PdfPopover route={route} />
              </div>
              <button
                type="button"
                className={route.isDraft ? styles.btnDraft : styles.btnReady}
                onClick={() =>
                  patchMutation.mutate({ id: route.id, toUpdate: { isDraft: !route.isDraft } })
                }
                disabled={route.status === "SETTLED" || patchMutation.isLoading}
              >
                <img className="mr-1" alt="" src={completeImg} />
                <div className="fs-16">{route.isDraft ? "Niegotowe" : "Gotowe"}</div>
              </button>
            </div>
          </div>
        </div>
        <div className={styles.tabs}>
          <button
            className={cx(styles.tabBtn, { [styles.tabBtnActive]: tab === "points" })}
            type="button"
            onClick={() => setTab("points")}
          >
            <img className="mr-1" alt="" src={pointsImg} />
            Lista adresów dostawy
          </button>
          <button
            className={cx(styles.tabBtn, { [styles.tabBtnActive]: tab === "route" })}
            type="button"
            onClick={() => setTab("route")}
          >
            <img className="mr-1" alt="" src={calendarImg} />
            Plan trasy
          </button>
        </div>
      </div>
      {tab === "points" && <PointsList route={route} />}
      {tab === "route" && <RouteDetails route={route} />}
    </div>
  );
};

import { FinancesAmountSummaryCurrency } from "api/trading-documents/models";
import { RightPanelTableRow } from "components/utils/drawer";
import { countryCurrenciesFlags } from "CONSTANTS";
import { financesAmountSummaryListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";

interface Props {
  currencyItem: [string, FinancesAmountSummaryCurrency];
}

export const AmountSummaryListItem = ({ currencyItem }: Props) => {
  const [currency, summary] = currencyItem;

  return (
    <RightPanelTableRow grid={financesAmountSummaryListConfig.grid}>
      <div className="fw-700">
        {countryCurrenciesFlags[currency] ? (
          <img alt={currency} src={countryCurrenciesFlags[currency]} />
        ) : (
          <>{currency}</>
        )}
      </div>
      <div className="d-flex align-items-center gap-1">
        <div className="body-14-700">{formatAmount(summary.amountWithoutTaxValue)}</div>
        <div className="text-black-6 body-14-600">{currency}</div>
      </div>
      <div className="d-flex align-items-center gap-1">
        <div className="body-14-700">{formatAmount(summary.amountWithoutTaxPln)}</div>
        <div className="text-black-6 body-14-600">PLN</div>
      </div>
      <div className="d-flex align-items-center gap-1">
        <div className="body-14-700">{formatAmount(summary.taxValue)}</div>
        <div className="text-black-6 body-14-600">{currency}</div>
      </div>
      <div className="d-flex align-items-center gap-1">
        <div className="body-14-700">{formatAmount(summary.taxPln)}</div>
        <div className="text-black-6 body-14-600">PLN</div>
      </div>
    </RightPanelTableRow>
  );
};

const formatAmount = (amount: number): string => {
  return Number(amount.toFixed(2)).toLocaleString("de-DE", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

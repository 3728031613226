import { graphhopper } from "api/graphhopper/graphhopper";
import { OrderCategory } from "api/orders/models";
import { Route, RoutePutMutationSchema } from "api/routes/models";
import { QueryFetchError } from "api/types";
import { ToastSubscription } from "components/common";
import {
  getFullRouteCoords,
  getOrdersPositionsBasedOnGraphhopper,
  getPoints,
} from "pages/routes/creator/utils";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutationResult,
} from "react-query";

export const updateDeliveryMutation = async (
  departureTime: string | null,
  departureDate: string | null,
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<unknown, unknown>>,
  route: Route,
  routePutMutation: UseMutationResult<
    {
      categories: OrderCategory[];
    },
    QueryFetchError,
    {
      data: RoutePutMutationSchema;
      route: number;
    },
    unknown
  >,
  toastr: {
    open: ({ text, title, type }: Omit<ToastSubscription, "id">) => void;
  },
) => {
  const startingPointLngLat = [route.startingPoint.point.lng, route.startingPoint.point.lat];
  const newPoints = getPoints(route.ordersPositions);

  const points = getFullRouteCoords(route, newPoints, startingPointLngLat);

  const payload = await graphhopper.route({
    points,
    vehicle: route.vehicleType,
    includeLastPointInOptimization: route.includeLastPointInOptimization,
  });

  if (payload) {
    const returnToStartingPointDistance = String(payload.points[route.orders.length].distance);
    const returnToStartingPointTime = String(payload.points[route.orders.length].time);

    const ordersPositions = getOrdersPositionsBasedOnGraphhopper(
      route.ordersPositions,
      payload.points,
    );

    const shouldRemoveFirstArrival =
      departureTime !== route.departureTime || departureDate !== route.departureDate ? true : false;

    routePutMutation.mutate(
      {
        data: {
          length: payload.distance,
          operation: null,
          ordersPositions,
          departureDate,
          departureTime,
          firstArrivalTime: shouldRemoveFirstArrival ? null : undefined,
          firstArrivalDate: shouldRemoveFirstArrival ? null : undefined,
          returnToStartingPointDistance,
          returnToStartingPointTime,
          shouldCalculateAverageSpeed: true,
        },
        route: route.id,
      },
      {
        onSettled: () => {
          refetch();
        },
      },
    );
  } else {
    toastr.open({
      type: "warning",
      title: "Wymagane działanie",
      text: "",
    });
  }
};

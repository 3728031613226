import { useRef } from "react";

const SENTINEL = {};

/**
 * How different it is from useRef and when should I use this instead?
 * - argument of useRef is refreshed every render, even though it's returned value doesn't.
 * This function allow us to initialize value once (and ONLY once) like initialize function
 * in useState does. (check react "hooks" docs)
 *
 * https://github.com/facebook/react/issues/14490
 */
export function useRefFn<T>(init: () => T) {
  const ref = useRef<T | typeof SENTINEL>(SENTINEL);
  if (ref.current === SENTINEL) {
    ref.current = init();
  }
  return ref as React.MutableRefObject<T>;
}

import { differenceInDays, format } from "date-fns";

export const calculateDaysDifference = (date: string): number => {
  const today = new Date();
  const targetDate = new Date(date);

  const formattedToday = format(today, "yyyy-MM-dd");
  const formattedTargetDate = format(targetDate, "yyyy-MM-dd");

  return differenceInDays(new Date(formattedTargetDate), new Date(formattedToday));
};

export const info = {
  info12: "#09a9f41f",
  info25: "#E1F5FE",
  info32: "#09a9f452",
  info50: "#B3E5FC",
  info75: "#7FD5FA",
  info100: "#50C2F7",
  info200: "#2BB5F7",
  info300: "#09A9F4",
  info400: "#029BE5",
  info500: "#0088D1",
  info600: "#0078BC",
  info700: "#00579A",
  info800: "#0C355A",
  info900: "#0D1C2B",
};

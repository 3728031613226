export const openEditRecipientNamesModal = (
  e: any,
  setModalPosition: React.Dispatch<React.SetStateAction<{ top: number; left: number }>>,
  toggle: () => void,
) => {
  const target = e.target as HTMLElement;
  const { top, left, height } = target.getBoundingClientRect();
  setModalPosition({ top: top - height - 40, left });
  toggle();
};

import { AdvancedSelect } from "components/common/advancedSelect/AdvancedSelect";
import { ManufacturingStage } from "api/manufacturingNew/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useDefaultEmployeeForm } from "./useDefaultEmployeeForm";
import { useSelector } from "hooks";

interface Props {
  close: () => void;
  manufacturingStage: ManufacturingStage;
}

export const AddEmployeeModal = ({ close, manufacturingStage }: Props) => {
  const employees = useSelector(store => store.partials.employees);

  const { handleSubmit, selectedEmployee, setSelectedEmployee } = useDefaultEmployeeForm(
    close,
    manufacturingStage,
  );

  const employeesToSelect = employees
    .filter(
      employee =>
        !manufacturingStage.settings.employees.some(
          assignedEmployee => assignedEmployee.employeeId === employee.id,
        ),
    )
    .map(employee => {
      return {
        id: employee.id,
        name: `${employee.firstName} ${employee.lastName}`,
        firstName: employee.firstName,
        lastName: employee.lastName,
        filters: {
          name: `${employee.firstName} ${employee.lastName}`,
        },
      };
    });

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj pracownika
        </Typography>
      }
      width={756}
    >
      <div className="p-3 d-flex gap-4 flex-column">
        <AdvancedSelect
          enableClear={false}
          inputPlaceholder="Szukaj pracownika..."
          itemsWidth={400}
          items={employeesToSelect}
          onChange={item => {
            if (item) {
              setSelectedEmployee(item);
            }
          }}
          selectedItem={selectedEmployee?.id ?? null}
          itemToDisplaySelected={item => {
            return (
              <div className="d-flex align-items-center body-14 fw-500">
                {item ? item.name : "Przypisz pracownika"}
              </div>
            );
          }}
          overrides={{
            toggleButton: { style: { minHeight: "32px", border: "1px solid #ccc" } },
          }}
          width={400}
        />
        <div className="d-flex align-items-center gap-3 pt-3 borderTop">
          <Button
            className="mt-2 uppercase fs-12"
            disabled={!selectedEmployee}
            onClick={handleSubmit}
            size="medium"
            variant="deepPurple"
          >
            Dodaj
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import { colorPaletteTokens, ColorPaletteBackground, ColorPaletteTextColor } from "CONSTANTS";

export function getRandomColor() {
  const colors = Object.entries(colorPaletteTokens).map(([backgroundColor, textColor]) => ({
    backgroundColor,
    textColor,
  })) as {
    backgroundColor: ColorPaletteBackground;
    textColor: ColorPaletteTextColor;
  }[];

  return colors[Math.floor(Math.random() * colors.length)];
}

import { useState } from "react";
import v4 from "cuid";
import { Chip } from "../../shared/shared";
import { useQuery } from "hooks";
import { formatDate } from "../../shared/utils/formatDate";

interface Props {
  name: string;
  options: {
    label: string;
    value: string;
  }[];
}

export const Options = ({ name, options }: Props) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const [optionsToPick] = useState(
    options?.map((option, index) => {
      return {
        checked: false,
        key: v4(),
        label: option.label,
        name: name,
        position: index,
        value: option.value,
      };
    }),
  );

  return (
    <div className="mt-3">
      {optionsToPick.map(({ label, name, value, key }) => {
        const checked = query[name] === value;

        return (
          <Chip<string>
            key={key}
            checked={checked}
            handleChange={() => {
              if (value && !checked) {
                updateQuery({
                  [name]: formatDate(value),
                  page: 1,
                });
                return;
              }
              updateQuery({ [name]: "", page: 1 });
            }}
            label={label}
            name={name}
            value={value}
          />
        );
      })}
    </div>
  );
};

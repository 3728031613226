import { useEffect } from "react";
import { ViewStore } from "typeUtilities";
import create, { EqualityChecker, State, StateSelector, UseStore } from "zustand";
import { immerMiddleware } from "./immerMiddleware";

function noopSelector<T>(state: T) {
  return state;
}

/**
 * Creates view state hook. First hook should have 'master' parameter
 * to proper clean the store on unmount.
 * @Example
 * ```javascript
 *  type ViewState = {
      inProgress: boolean;
      actions: {
        fetchData: () => void;
      };
      data: Something;
    };

    const store: ViewStore<ViewState> = (set) => {
      return {
        inProgress: false,
        data: null,
        actions: {
          fetchData: () => {
            set((s) => {
              s.inProgress = true;
              ...
            });
          },
        },
      };
    };

    const useViewState = createViewState(store);
    ...
    const data = useViewState("master", store => store.data)

 * ```
 */
export function createViewState<T extends State>(store: ViewStore<T>) {
  let useStore: UseStore<T> | null = null;
  const useViewState: UseViewState<T> = (
    type: "master" | "slave",
    _selector_todo: StateSelector<T, any> = noopSelector,
    equalityChecker?: EqualityChecker<T>,
  ) => {
    useEffect(() => {
      return () => {
        if (type === "master") {
          useStore!.destroy();
          useStore = null;
        }
      };
    }, [type]);

    if (!useStore) {
      // @ts-ignore
      useStore = create(immerMiddleware(store));
    }
    return useStore!(_selector_todo, equalityChecker);
  };
  return useViewState;
}

export interface UseViewState<T extends State> {
  (type: "master" | "slave"): T;
  <U>(
    type: "master" | "slave",
    selector?: StateSelector<T, U>,
    equalityChecker?: EqualityChecker<T>,
  ): U;
}

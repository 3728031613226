import { CorrespondingDocument as CorrespondingDocumentDTO } from "api/trading-documents/models";
import { StateLabel } from "components/common/stateLabel";
import { RightPanelTableRow } from "components/utils/drawer";
import { getTradingDocumentRedirectUrl } from "pages/tradingDocuments/shared/utils/getTradingDocumentRedirectUrl";
import { correspondingDocumentsSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { Link } from "react-router-dom";
import styles from "../../../../TradingDocumentsLists.module.css";
import localStyles from "../CorrespondingDocumentSection.module.css";

interface Props {
  correspondingDocument: CorrespondingDocumentDTO;
  position: number;
}

export const CorrespondingDocument = ({ correspondingDocument, position }: Props) => {
  return (
    <RightPanelTableRow grid={correspondingDocumentsSectionListConfig.grid}>
      <div>{position}.</div>
      <div className="d-flex align-items-center gap-2">
        <div className={styles.signature}>
          {correspondingDocument.signature ? (
            <Link
              className={localStyles.signatureLink}
              to={getTradingDocumentRedirectUrl(
                correspondingDocument.type,
                correspondingDocument.invoiceType,
                correspondingDocument.id,
              )}
            >
              {correspondingDocument.signature}
            </Link>
          ) : (
            "--"
          )}
        </div>
        {correspondingDocument.isDraft && (
          <StateLabel kind="cyan">oczekuje na zatwierdzenie</StateLabel>
        )}
      </div>
    </RightPanelTableRow>
  );
};

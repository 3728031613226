import * as React from "react";
import { DisabledOpacity } from "components/utils";
import styles from "./CommentForm.module.css";
import cx from "classnames";
import { Order } from "api/orders/models";
import { useToggle, useSelector } from "hooks";
import { UserAvatar } from "components/utils/userAvatar";

export type CommentFormHelpers = {
  clear: () => void;
  setError: (arg: string) => void;
  setFetching: (arg: boolean) => void;
};
interface Props {
  handleSubmit: (value: string, helpers: CommentFormHelpers) => Promise<{ success: boolean }>;
  withFollowers?: boolean;
  showForm?: boolean;
  followers: Order["followers"];
}

export const CommentForm = ({
  handleSubmit,
  followers,
  withFollowers = true,
  showForm = true,
}: Props) => {
  const [fetching, setFetching] = React.useState(false);
  const [error, setError] = React.useState<null | string>(null);
  const [value, setValue] = React.useState("");
  const { isOpen, open, close } = useToggle(false);
  const me = useSelector(state => state.auth.user);

  const clear = () => setValue("");
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    open();
    setValue(e.target.value);
  };

  const submit = async () => {
    if (!value) return;
    const { success } = await handleSubmit(value, { clear, setError, setFetching });
    if (success) {
      close();
    }
  };

  function handleCmdEnter(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      submit();
    }
  }

  return (
    <div>
      {showForm && (
        <div className={cx({ "was-validated": Boolean(error) }, "pb-2")}>
          <DisabledOpacity disabled={fetching}>
            <div className={cx(styles.commentGroup, "d-flex pt-2 pb-2")}>
              <UserAvatar id={me?.id} avatarSrc={me?.avatar} className="my-1" />
              <div className={cx(styles.commentField, "d-flex align-items-center")}>
                <textarea
                  placeholder="Dodaj komentarz lub zadaj pytanie"
                  onKeyDown={handleCmdEnter}
                  onChange={handleChange}
                  onFocus={open}
                  onBlur={() => {
                    if (!value) {
                      close();
                    }
                  }}
                  name="comment"
                  value={value}
                  cols={30}
                  rows={10}
                  style={{ height: isOpen ? 90 : 36 }}
                />
                <button
                  disabled={!value}
                  className="btn-primary btn-rounded btn-font-small btn-color-blue"
                  style={{ display: isOpen ? "inline-flex" : "none" }}
                  onClick={submit}
                >
                  Opublikuj
                </button>
              </div>
            </div>
          </DisabledOpacity>
          <div className="invalid-feedback">{error}</div>
        </div>
      )}
      {withFollowers && (
        <div className={cx(styles.followersGroup, "pb-2")}>
          {followers.map(follower => (
            <div
              key={follower.id}
              className={cx(styles.followerAvatar)}
              title={`${follower.firstName} ${follower.lastName}`}
            >
              <UserAvatar id={follower.id} avatarSrc={follower.avatar} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

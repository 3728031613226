import cx from "classnames";
import { useEffect, createContext, useContext, useRef, useState, useMemo } from "react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { ObjectSchema } from "yup";

const wizardContext = createContext<{
  activePage: number;
  setValidators: any;
  setPageSubmitCallbacks: any;
}>({
  activePage: 0,
  setValidators: () => {},
  setPageSubmitCallbacks: () => {},
});

export type FormWizardHelpers = { next: () => void; previous: () => void };

interface WizardProps<Values> {
  initialValues: Values;
  onSubmit: (
    arg: Values,
    helpers: FormikHelpers<Values>,
    formWizardHelpers: FormWizardHelpers,
  ) => Promise<void> | void;
  children: (
    arg: FormikProps<Values> & {
      page: number;
      isLastPage: boolean;
      next: () => void;
      previous: () => void;
      setPage: (page: number) => void;
    },
  ) => JSX.Element;
  pages: number;
  submitPage?: number;
  enableReinitialize?: boolean;
}

/**
 * FormWizard handles multi-page forms without headache.
 * @example
 * <FormWizard initialValues={initialValues} onSubmit={handleSubmit} pages={2}>
      {({ values, isLastPage, previous, isSubmitting, errors }) => (
        <>
          <FormWizard.Page page={0} validate={validators[0]}>
            <h4 className={cx(styles.header, "mt-5 mb-3")}>Wpisz swój numer telefonu</h4>
            <Field
              id="phone"
              name="phone"
              autoFocus
              placeholder="+01 234567890"
              type="text"
              className={styles.input}
            />
            <ErrorMessage name="phone" />
          </FormWizard.Page>
          ...
          {!isLastPage && (
              <Button>
                <span>Kontynuuj</span>
              </Button>
          )}
          {isLastPage && (
              <Button>
                <span>Zaloguj</span>
              </Button>
          )}
        </>
      )}
    </FormWizard>
 */
export function FormWizard<Values extends { [key: string]: any }>({
  initialValues,
  onSubmit,
  children,
  pages,
  submitPage = pages - 1,
  enableReinitialize = false,
}: WizardProps<Values>) {
  const [page, setPage] = useState(0);
  const [validators, setValidators] = useState<{
    [key: number]: (values: Values) => { [K in keyof Values]: string };
  }>({});
  const [submitCallbacks, setPageSubmitCallbacks] = useState<{
    [key: number]: (
      values: Values,
      formikBag: FormikHelpers<typeof initialValues>,
    ) => Promise<boolean>;
  }>({});
  const isLastPage = page === pages - 1;
  const isSubmitPage = page === submitPage;

  const next = () => setPage((page: number) => Math.min(page + 1, pages - 1));
  const previous = () => setPage((page: number) => Math.max(page - 1, 0));

  const validateType = typeof validators[page] === "function" ? "raw" : "schema";
  const validate = (values: typeof initialValues) => {
    return validators[page](values);
  };
  const handleSubmit = async (
    values: typeof initialValues,
    formikBag: FormikHelpers<typeof initialValues>,
  ) => {
    if (isSubmitPage) {
      await onSubmit(values, formikBag, { next, previous });
    } else {
      if (submitCallbacks[page]) {
        const success = await submitCallbacks[page](values, formikBag);
        if (success) {
          formikBag.setSubmitting(false);
          next();
        }
      } else if (!isLastPage) {
        formikBag.setSubmitting(false);
        next();
      }
    }
  };
  const memoContext = useMemo(() => ({ activePage: page, setValidators, setPageSubmitCallbacks }), [
    page,
  ]);
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={enableReinitialize}
      onSubmit={handleSubmit}
      validate={validateType === "raw" ? validate : undefined}
      validationSchema={validateType === "schema" ? validators[page] : undefined}
    >
      {formikProps => (
        <wizardContext.Provider value={memoContext}>
          <Form className={cx("h-100", { "was-validated": !formikProps.isValid })}>
            {children({ ...formikProps, page, isLastPage, next, previous, setPage })}
          </Form>
        </wizardContext.Provider>
      )}
    </Formik>
  );
}

interface PageProps {
  validate: ObjectSchema | ((values: any) => any);
  children: any;
  page: number;
  /**
   * Be aware that this method is kept in state and is frozen in the state of first render!
   */
  onPageSubmit?: (values: any, formikBag: FormikHelpers<any>) => Promise<boolean>;
}

function Page({ children, page, validate, onPageSubmit }: PageProps) {
  const { activePage, setValidators, setPageSubmitCallbacks } = useContext(wizardContext);
  const initialMount = useRef(true);
  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
      setValidators((v: any) => ({ ...v, [page]: validate }));
      setPageSubmitCallbacks((v: any) => ({ ...v, [page]: onPageSubmit }));
    }
  }, [setValidators, page, validate, setPageSubmitCallbacks, onPageSubmit]);

  if (page !== activePage) return null;
  return children;
}

FormWizard.Page = Page;

import { ManufacturingItemSource, SourcesSummary } from "api/new-production-plans/models";

export const isSourceAlreadyInSourceBar = (
  draggedSource: ManufacturingItemSource,
  sources: SourcesSummary[],
): boolean => {
  return sources.some(
    source => source.id === draggedSource.id && source.signature === draggedSource.signature,
  );
};

import { PropsWithChildren, useState } from "react";
import CopyToClipboardComponent from "react-copy-to-clipboard";
import { cx } from "utilities";
import styles from "./CopyHandler.module.css";
import { CopyButtonProps } from "./types";

export const CopyHandler = (props: PropsWithChildren<CopyButtonProps>) => {
  const [isAnimating, setIsAnimating] = useState(false);

  return (
    <CopyToClipboardComponent text={props.copyText}>
      <div className={styles.icon} onClick={() => setIsAnimating(true)}>
        {props.children}
        <div
          className={cx(styles.checkIcon, {
            [styles.successCheckAnimation]: isAnimating,
          })}
          onAnimationEnd={() => setIsAnimating(false)}
        />
      </div>
    </CopyToClipboardComponent>
  );
};

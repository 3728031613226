export const red = {
  red12: "#C733261F",
  red25: "#FFE4E1",
  red32: "#C7332652",
  red50: "#F6C4BE",
  red75: "#F88F83",
  red100: "#F16A5A",
  red200: "#E15241",
  red300: "#C73326",
  red400: "#B72D25",
  red500: "#A82D25",
  red600: "#6C1B16",
};

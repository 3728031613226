import { useInvoiceAdmissionPatchMutation } from "api/trading-documents/hooks";
import { InvoiceAdmission } from "api/trading-documents/models";
import { Button } from "components/common";
import { AsyncInput, RadioLabels } from "components/utils";
import { useSelector, useToggle } from "hooks";
import { assertIsDefined } from "utilities/assertIsDefined";
import { getMonths } from "../../utils/getMonths";
import styles from "./SearchPanel.module.css";
import arrowRightIcon from "assets/images/arrowForward.svg";
import { useState } from "react";
import { AddWhEntriesModal } from "../addWhEntriesModal/AddWhEntriesModal";
import { AdvancedSelect } from "components/common/advancedSelect/AdvancedSelect";

interface Props {
  assignedSearch: string;
  invoiceAdmission: InvoiceAdmission;
}

export const SearchPanel = ({ assignedSearch, invoiceAdmission }: Props) => {
  const manufacturers = useSelector(store => store.partials.manufacturers);
  const manufacturerMutation = useInvoiceAdmissionPatchMutation(true);
  const pageSizeMutation = useInvoiceAdmissionPatchMutation(true);
  const packageSearchMutation = useInvoiceAdmissionPatchMutation(true);
  const datesMutation = useInvoiceAdmissionPatchMutation();
  const [months, setMonths] = useState<string[]>([]);
  const addWhEntriesModal = useToggle();

  const manufacturersItems = manufacturers.map(manufacturer => {
    return {
      id: manufacturer.id,
      name: manufacturer.name,
      filters: {
        name: manufacturer.name,
      },
    };
  });

  return (
    <div className="d-flex align-items-end flex-wrap gap-2 pb-2">
      <AdvancedSelect
        disabled={manufacturerMutation.isLoading}
        label="Producent"
        onChange={value => {
          if (value) {
            const manufacturer = manufacturers.find(_manufacturer => _manufacturer.id === value.id);

            manufacturerMutation.mutate({
              id: invoiceAdmission.tradingDocument,
              toUpdate: {
                whAggregateSearchManufacturer: manufacturer,
              },
            });
          } else {
            manufacturerMutation.mutate({
              id: invoiceAdmission.tradingDocument,
              toUpdate: {
                whAggregateSearchManufacturer: null,
              },
            });
          }
        }}
        selectedItem={invoiceAdmission.whAggregateSearchManufacturer?.id ?? null}
        inputPlaceholder="Szukaj producentów..."
        itemToDisplaySelected={item => (
          <div className="d-flex align-items-center body-14 fw-500">
            <span className="text-nowrap text-truncate overflow-hidden">
              {item ? item.name : "Przypisz"}
            </span>
          </div>
        )}
        items={manufacturersItems}
        overrides={{
          toggleButton: {
            style: { width: "140px", height: "28px", border: "1px solid rgb(221, 221, 221)" },
          },
          label: { className: styles.manufacturerTextLabel },
        }}
      />

      <div className="position-relative mt-1">
        <AsyncInput
          disabled={pageSizeMutation.isLoading}
          look="common"
          label="L. przyjęć"
          onChange={value =>
            pageSizeMutation.mutate({
              id: invoiceAdmission.tradingDocument,
              toUpdate: { whAggregateSearchPageSize: value },
            })
          }
          overwrites={{
            input: { className: styles.paginationFormSize },
            floatingLabel: { className: styles.inputTextLabel },
          }}
          type="number"
          value={invoiceAdmission.whAggregateSearchPageSize ?? ""}
        />
      </div>

      <div>
        <h6 className={styles.monthsSubtitle}>Data zamknięcia dokumentu</h6>
        <RadioLabels
          allowUncheck={true}
          isNewLayout={true}
          items={getMonths()}
          multiple={true}
          name="whAggregateSearchDates"
          onChange={value => {
            assertIsDefined(value.value);
            if (months.includes(String(value.value))) {
              setMonths(prevMonths => {
                return prevMonths.filter(prevMonth => prevMonth !== value.value);
              });
            } else {
              setMonths(prevMonths => {
                return [...prevMonths, String(value.value)];
              });
            }
          }}
          overrides={{
            wrapper: { className: "mt-0 pt-1" },
            option: { className: styles.labels },
            label: { className: styles.radioMark },
          }}
          values={months}
        />
      </div>

      <div className="position-relative mt-1">
        <AsyncInput
          disabled={packageSearchMutation.isLoading}
          look="common"
          label="Zawiera paczkę:"
          onChange={value =>
            packageSearchMutation.mutate({
              id: invoiceAdmission.tradingDocument,
              toUpdate: {
                whAggregateSearchPackage: value,
              },
            })
          }
          overwrites={{
            input: { className: styles.packageFormSize },
            floatingLabel: { className: styles.inputTextLabel },
          }}
          value={invoiceAdmission.whAggregateSearchPackage}
        />
      </div>

      <Button
        className={styles.searchBtn}
        disabled={
          manufacturerMutation.isLoading ||
          pageSizeMutation.isLoading ||
          packageSearchMutation.isLoading ||
          datesMutation.isLoading
        }
        kind="submit-medium"
        onClick={addWhEntriesModal.open}
      >
        <div className="btnBaseSmall btnBase">
          Szukaj
          <img alt="" src={arrowRightIcon} />
        </div>
      </Button>
      {addWhEntriesModal.isOpen && (
        <AddWhEntriesModal
          assignedSearch={assignedSearch}
          close={addWhEntriesModal.close}
          invoiceAdmission={invoiceAdmission}
          whAggregateSearchDates={months}
        />
      )}
    </div>
  );
};

import { createContext, useContext } from "react";
import PropTypes from "prop-types";
import styles from "./FormRadioLabels.module.css";
import cx from "classnames";

const Context = createContext(false);

/**
 * @callback onChange
 * @param {{name: string, value: any}} val
 */

/**
 * @callback isDisabled
 * @param {any} val
 */

/**
 *
 * @param {Object} props
 * @param {string=} props.label
 * @param {string | number | null=} props.value
 * @param {onChange=} props.onChange
 * @param {array} props.items
 * @param {string=} props.name
 * @param {element=} props.children
 * @param {isDisabled=} props.isDisabled
 * @example
 *
<FormRadioLabels
  label={trans.brand}
  items={state.brands}
  name="brand"
  onChange={val => handle(val)}
  value={Number(query.brand)}
/>
OR
<FormRadioLabels label={trans.category}>
  {state.categories.map(category => (
    <Label
      key={category.id}
      label={category.name}
      name="category"
      value={category.id}
      checked={Number(query.category) === category.id}
      onChange={val => handle(val)}
    />
  ))}
</FormRadioLabels>
 */
const FormRadioLabels = ({
  label,
  value,
  onChange,
  items,
  name,
  children,
  multiple,
  isDisabled,
  overrides = {},
}) => {
  return (
    <div className="fieldset">
      {label && (
        <div className={`${styles.fieldsetLabel} ${overrides?.label?.className || ""}`}>
          {label}
        </div>
      )}
      <div className="d-flex mt-2 mb-3 flex-wrap">
        <Context.Provider value={multiple}>
          {children}
          {items.map(item => (
            <Label
              key={item.id}
              name={name}
              label={item.name}
              value={item.id}
              checked={item.id === value}
              onChange={onChange}
              item={item}
              disabled={isDisabled ? isDisabled(item) : undefined}
            />
          ))}
        </Context.Provider>
      </div>
    </div>
  );
};
FormRadioLabels.defaultProps = {
  items: [],
  multiple: false,
  overrides: {},
};
FormRadioLabels.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  multiple: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  items: PropTypes.array.isRequired,
  name: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  overrides: PropTypes.shape({ label: { classNames: PropTypes.string } }),
  isDisabled: PropTypes.func,
};

export const Label = ({
  label,
  name,
  value,
  checked,
  onChange,
  allowUncheck,
  item,
  disabled,
  type,
  img,
  className,
}) => {
  const multiple = useContext(Context);
  return (
    <label
      className={cx(className, "option mr-2 d-inline-flex p0", styles.label, {
        [styles.disabled]: disabled,
      })}
    >
      <input
        type={multiple ? "checkbox" : "radio"}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onClick={() => {
          if (multiple || disabled) return;
          if (checked && !allowUncheck) return;
          if (checked) {
            onChange({ name, value: null }, item);
          }
        }}
        onChange={() => onChange({ name, value }, item)}
        className={styles.input}
      />
      {type === "image" ? (
        <div className={styles.radiomark}>
          <img src={img} alt="" title={label} />
          <span>{label}</span>
        </div>
      ) : (
        <span className={styles.radiomark}>{label}</span>
      )}
    </label>
  );
};

Label.defaultProps = {
  checked: false,
  allowUncheck: true,
};
Label.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  allowUncheck: PropTypes.bool.isRequired,
  item: PropTypes.shape(),
  disabled: PropTypes.bool,
  img: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(["text", "image"]),
};
FormRadioLabels.Label = Label;
export default FormRadioLabels;

import { PackageInLocation } from "api/wms/layout/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const usePackagesInLocationColumns = () => {
  return useCreateTableColumns<PackageInLocation>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.code, {
        header: "nazwa",
        size: 377,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.text(row => `${row.package.weight} kg`, {
        header: "waga",
        size: 90,
      }),
      // TODO: label btn
      columnHelper.accessor(() => "", {
        id: "packageLabel",
        header: () => (
          <Typography
            className="text-center w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            etykieta
          </Typography>
        ),
        size: 40,
        cell: () => {
          return (
            <div className="d-flex align-items-center justify-content-center ml-1">
              <IconButton icon={<MdiQrCode size="16" />} variant="transparent" />
            </div>
          );
        },
      }),
    ];
  });
};

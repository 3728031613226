import styled from "@emotion/styled";
import { ColorPalette } from "../colorsPalette";

interface SpinnerProps {
  color?: ColorPalette;
  size: number;
}

const StyledSpinner = styled.div<{ color: ColorPalette }>`
  border-radius: 50%;
  background: conic-gradient(#0000 10%, var(${props => `--${props.color}`}));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(70%), #000 0);
  animation: rotate 1s infinite linear;
  @keyframes rotate {
    to {
      transform: rotate(1turn);
    }
  }
`;

export const Spinner = ({ color = "deepPurple500", size }: SpinnerProps) => {
  return (
    <StyledSpinner
      color={color}
      style={{
        height: `${size}px`,
        width: `${size}px`,
        minHeight: `${size}px`,
        minWidth: `${size}px`,
      }}
    />
  );
};

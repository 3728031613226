import { Route } from "api/routes/models";
import dragImg from "assets/images/303.svg";
import commentImg from "assets/images/45.svg";
import pencilImg from "assets/images/1.svg";
import redCrossIcon from "assets/images/11r.svg";
import dollarImg from "assets/images/26.svg"; // TODO: replace with correct dollar icon
import cx from "classnames";
import { useToggle } from "hooks";
import { useRoutePutMutation } from "hooks/apiPrimitives";
import handSetDeliveryIcon from "assets/images/approvalDelegation.svg";
import immer from "immer";
import { useMemo } from "react";
import { dateFns, isValidDate, secondsToTime } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useInfoWindow } from "../../hooks/useInfoWindow";
import { useRouteViewState } from "../../routeCreatorState";
import styles from "./PointsList.module.css";
import { Button } from "components/common";
import { OrderDeliveryUpdateModal } from "./OrderDeliveryUpdateModal";
import { Assign } from "utility-types";
import { Draggable } from "react-beautiful-dnd";
import { OrderPaymentUpdateModal } from "./OrderPaymentUpdateModal";
import { getMarkerIcon } from "utilities/getMarkerIcon";
import { CollectionHours } from "./components/CollectionHours";
import { areConfirmedHoursInCollectionRange } from "./utils/areConfirmedHoursInCollectionRange";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import ReactTooltip from "react-tooltip";

interface Props {
  order: Assign<Route["orders"][number], { order: number | null }>;
  route: Route;
  pointOrderOnList: number;
  isLoading: boolean;
  index: number;
}

export const Point = ({ order, route, pointOrderOnList, isLoading, index }: Props) => {
  const modal = useToggle();
  const paymentModal = useToggle();

  const { toggleInfoWindow, openedInfoWindowData, closeInfoWindow } = useInfoWindow();

  const { hours, minutes } = secondsToTime(order.delivery?.time || 0);

  const departureDate = useMemo(() => {
    if (!route.departureDate || !route.departureTime) {
      return null;
    }
    const date = new Date(route.departureDate + " " + route.departureTime);
    if (isValidDate(date)) {
      return new Date(route.departureDate + " " + route.departureTime);
    } else {
      return null;
    }
  }, [route.departureDate, route.departureTime]);

  return (
    <Draggable draggableId={String(order.id)} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <div className="d-flex">
            <div className={styles.dotContainer}>
              <div className={cx(styles.purpleDot, { invisible: snapshot.isDragging })}></div>
              <div className={cx(styles.greyDot, { invisible: snapshot.isDragging })}></div>
            </div>
            <div
              className={cx(styles.singlePoint, "btn", {
                [styles["selected"]]: openedInfoWindowData?.id === order.id,
                [styles.correctConfirmationRange]:
                  areConfirmedHoursInCollectionRange(
                    order.maximumCollectionHour,
                    order.minimumCollectionHour,
                    order.maximumCollectionHourAtConfirmation,
                    order.minimumCollectionHourAtConfirmation,
                  ) === true,
                [styles.invalidConfirmationRange]:
                  areConfirmedHoursInCollectionRange(
                    order.maximumCollectionHour,
                    order.minimumCollectionHour,
                    order.maximumCollectionHourAtConfirmation,
                    order.minimumCollectionHourAtConfirmation,
                  ) === false,
                [styles.dragging]: snapshot.isDragging,
              })}
              onClick={() => toggleInfoWindow(order.id, order.delivery.point)}
            >
              <div className={`d-flex align-items-start ${styles.orderDetails}`}>
                <div className={styles.singlePointIndexBox}>
                  <div className={styles.singlePointIndex}>{pointOrderOnList}</div>
                </div>
                <div className={styles.singlePointDataWrapper}>
                  {order.warehouseDeliveryDetails &&
                    order.warehouseDeliveryDetails.isInWarehouse === false &&
                    order.warehouseDeliveryDetails.date !== null && (
                      <div className={cx(styles.awaitingLabel, "d-flex align-items-center")}>
                        <img
                          alt="Ikona zamówienia"
                          className="mr-1"
                          style={{ width: "18px", height: "18px" }}
                          src={getMarkerIcon({
                            priority: order.priority,
                            id: order.id,
                            hasUpholstery: order.hasUpholstery,
                            type: order.type,
                            leftDays: order.leftDays,
                            numberOfDaysFromCreatedDate: order.numberOfDaysFromCreatedDate,
                            warehouseDeliveryDetails: order.warehouseDeliveryDetails,
                            hideOnMapTo: order.hideOnMapTo,
                            hideUntilIssueIsSolved: order.hideUntilIssueIsSolved,
                            isHidden: order.isHidden,
                          })}
                        />
                        <div className="fs-10">
                          oczekuje na dostarczenie magazyn{" "}
                          {order.warehouseDeliveryDetails &&
                            order.warehouseDeliveryDetails.daysLeftToDelivery !== null &&
                            order.warehouseDeliveryDetails.daysLeftToDelivery === 0 && (
                              <span>(dzisiaj)</span>
                            )}{" "}
                        </div>
                        {order.warehouseDeliveryDetails &&
                          order.warehouseDeliveryDetails.daysLeftToDelivery !== null && (
                            <>
                              <span>&nbsp;</span>
                              {order.warehouseDeliveryDetails.daysLeftToDelivery > 0 && (
                                <span className="fs-10">
                                  ({order.warehouseDeliveryDetails.daysLeftToDelivery}d)
                                </span>
                              )}
                              {order.warehouseDeliveryDetails.daysLeftToDelivery < 0 && (
                                <span className="fs-10">
                                  ({order.warehouseDeliveryDetails.daysLeftToDelivery}d)
                                </span>
                              )}
                            </>
                          )}
                      </div>
                    )}
                  <div className={styles.address}>
                    <div className={styles["single-point-address-wrapper"]}>
                      {order.warehouseDeliveryDetails &&
                      order.warehouseDeliveryDetails.isInWarehouse === false &&
                      order.warehouseDeliveryDetails.date !== null ? (
                        <span className="fs-12 text-color-grey">
                          {order.delivery.city} {order.delivery.postCode},
                        </span>
                      ) : (
                        <span className="fs-12">
                          {order.delivery?.city} {order.delivery?.postCode},
                        </span>
                      )}
                      <span className="ml-1 mr-2 fs-12 text-color-grey">
                        {order.delivery?.street}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    {order.warehouseDeliveryDetails &&
                    order.warehouseDeliveryDetails.isInWarehouse === false &&
                    order.warehouseDeliveryDetails.date !== null ? (
                      <div className="fs-14 text-color-grey">{order.signature}</div>
                    ) : (
                      <div className="fs-14">{order.signature}</div>
                    )}
                    {Boolean(order.comments) && (
                      <>
                        <span className={styles.separator}></span>
                        <div>
                          <span className="mr-1 fs-12">{order.comments}</span>
                          <span>
                            <img src={commentImg} alt="" className={styles.commentsImg} />
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <Stopover route={route} order={order} isLoading={isLoading} />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className={styles["travel-time"]}>
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <div className={cx(styles.paymentModalBtn, "d-flex align-items-center")}>
                        <Button
                          kind="secondary"
                          size="round-s"
                          onClick={e => {
                            e.stopPropagation();
                            modal.open();

                            closeInfoWindow();
                          }}
                          title="Edytuj dane klienta"
                        >
                          <img src={pencilImg} alt="Edytuj" />
                        </Button>

                        <OrderDeliveryUpdateModal modal={modal} order={order} route={route} />
                        <Button
                          kind="secondary"
                          size="round-s"
                          onClick={e => {
                            e.stopPropagation();
                            paymentModal.open();

                            closeInfoWindow();
                          }}
                          title="Edytuj dane płatności"
                        >
                          <img src={dollarImg} alt="Edytuj" />
                        </Button>

                        <OrderPaymentUpdateModal modal={paymentModal} order={order} route={route} />
                        <div className={cx(styles.rejectSection, "ml-1")}>
                          {order.isDeliveryDateRejected && (
                            <img
                              alt="Odrzucono"
                              src={redCrossIcon}
                              style={{ height: "16px", width: "16px" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      {order.delivery?.distance === 0 || order.delivery?.distance === null ? (
                        <span>...</span>
                      ) : (
                        <span className="text-color-grey fs-10">
                          {(order.delivery?.distance / 1000).toFixed(1)} km
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    {order.delivery?.time === 0 || order.delivery?.time === null ? (
                      <span>...</span>
                    ) : (
                      <span className="text-color-grey fs-10">
                        {hours ? hours + "h" : ""} {minutes}min
                      </span>
                    )}
                  </div>
                  <div>
                    <span className="text-color-grey fs-10 mr-1">
                      {order.delivery?.plannedDeliveryTime &&
                        departureDate &&
                        dateFns.format(
                          new Date(order.delivery?.plannedDeliveryTime),
                          "dd.MM.yyyy,",
                        )}
                    </span>

                    <span className="text-color-grey fs-10">
                      {order.delivery?.plannedDeliveryTime &&
                        dateFns.format(new Date(order.delivery?.plannedDeliveryTime), "HH:mm")}
                    </span>
                  </div>
                  {order.minimumCollectionHour &&
                    order.maximumCollectionHour &&
                    order.minimumCollectionHour.length > 0 &&
                    order.maximumCollectionHour.length > 0 && <CollectionHours order={order} />}
                  <div
                    className="d-flex align-items-center justify-content-end gap-2"
                    data-for={`${String(order.id)}-customDeliveryHour`}
                    data-tip
                  >
                    <div className="d-flex align-items-center">
                      <img
                        alt=""
                        src={handSetDeliveryIcon}
                        style={{ height: 12, width: 12 }}
                        title="Ręcznie ustawiony przedział dostawy"
                      />
                    </div>
                    <Typography color="neutralBlack48" fontSize="10" fontWeight="400">
                      {order.delivery?.minimumCustomDeliveryHour &&
                      Boolean(order.delivery?.minimumCustomDeliveryHour.length)
                        ? order.delivery?.minimumCustomDeliveryHour
                        : "--:--"}{" "}
                      -{" "}
                      {order.delivery?.maximumCustomDeliveryHour &&
                      Boolean(order.delivery?.maximumCustomDeliveryHour.length)
                        ? order.delivery?.maximumCustomDeliveryHour
                        : "--:--"}
                    </Typography>
                  </div>
                  <ReactTooltip
                    className={styles.tooltip}
                    id={`${String(order.id)}-customDeliveryHour`}
                    type="dark"
                    arrowColor="#252728"
                    delayShow={1000}
                    effect="solid"
                    place="top"
                  >
                    <div className={styles.tooltipContent}>ręcznie ustawiony przedział dostawy</div>
                  </ReactTooltip>
                </div>
                <div className="d-flex flex-column position-relative">
                  <span className={styles.dragImg}>
                    <img src={dragImg} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

const Stopover = ({
  order,
  route,
  isLoading,
}: {
  order: Route["orders"][number];
  route: Route;
  isLoading: boolean;
}) => {
  const updateMutation = useRoutePutMutation();
  const actions = useRouteViewState("slave", state => state.actions);

  const handleChangeStopoverTime = (value: number) => {
    actions.openLoader("Trwa zmiana czasu postoju");

    const ordersPositions = immer(route.ordersPositions, draft => {
      const orderToUpdate = draft.find(el => Number(el.id) === order.id);
      assertIsDefined(orderToUpdate);
      orderToUpdate.meta.delivery.stopoverTime = value;
    });

    updateMutation.mutate({
      data: {
        length: route.length,
        operation: null,
        returnToStartingPointDistance: route.returnDistance,
        returnToStartingPointTime: String(route.returnTime || 0),
        ordersPositions,
        shouldCalculateAverageSpeed: false,
      },
      route: route.id,
    });
  };

  return (
    <div className={styles["stopover-point"]}>
      <div className="d-flex align-items-center">
        <label htmlFor="" className={cx(styles.label, "ml-0")}>
          postój:
        </label>
        <select
          className={styles.durationSelect}
          onClick={e => e.stopPropagation()}
          onChange={e => handleChangeStopoverTime(Number(e.target.value) * 60)}
          disabled={updateMutation.isLoading || isLoading}
          value={order.delivery?.stopoverTime / 60 || 0}
        >
          <option value="0">Brak</option>
          <option value="5">5 min</option>
          <option value="10">10 min</option>
          <option value="15">15 min</option>
          <option value="20">20 min</option>
          <option value="25">25 min</option>
          <option value="30">30 min</option>
          <option value="35">35 min</option>
          <option value="40">40 min</option>
          <option value="45">45 min</option>
          <option value="50">50 min</option>
          <option value="55">55 min</option>
          <option value="60">60 min</option>
          <option value="90">1,5 godz.</option>
          <option value="120">2 godz.</option>
          <option value="180">3 godz.</option>
        </select>
      </div>
    </div>
  );
};

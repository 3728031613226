import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { CorrectionInvoicesList } from "pages/tradingDocuments/correctionInvoicesList/CorrectionInvoicesList";
import { CorrectionInvoiceHistory } from "pages/tradingDocuments/correctionInvoicesList/history/CorrectionInvoiceHistory";
import { EditTradingDocument } from "pages/tradingDocuments/editTradingDocument/EditTradingDocument";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const CorrectionInvoices = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={CorrectionInvoiceHistory}
        exact={true}
        path={`${match.path}/:correctionInvoiceId/history`}
        title="Milo - historia korekty"
      />
      <Route
        component={CorrectionInvoicesList}
        exact={true}
        path={`${match.path}/list/:tab`}
        title="Milo - korekty"
      />
      <Route
        component={EditTradingDocument}
        exact={true}
        path={`${match.path}/:tradingDocumentId/edit`}
        title="Milo - edytuj korektę"
      />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};

import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../RemindersSection.module.css";
import { cx } from "utilities";
import { MdiFolderOpen } from "components/miloDesignSystem/atoms/icons/MdiFolderOpen";

export const EmptyRemindersSection = () => {
  return (
    <div className="pb-3">
      <div className={styles.sectionEmpty}>
        <MdiFolderOpen color="neutralBlack48" />
        <Typography className={cx(styles.sectionEmptyTitle, "pt-2")} fontSize="12" fontWeight="700">
          Jeszcze nie wysłano żadnego przypomnienia
        </Typography>
      </div>
    </div>
  );
};

import { Loading, statusDict } from "api/wms/models";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { isUnloadingLate } from "pages/wms/unloadingsList/utils/isUnloadingLate";

interface Props {
  loading: Loading;
}

export const LoadingStatus = ({ loading }: Props) => {
  // TODO: Check if this is correct field?
  if (loading.estimatedTimeOfDeparture && isUnloadingLate(loading.estimatedTimeOfDeparture))
    return <Tag startIcon={MdiPoint} label="Opóźniony" variant="danger" />;
  return (
    <Tag
      startIcon={MdiPoint}
      label={statusDict[loading.status].label}
      variant={statusDict[loading.status].variant}
    />
  );
};

import { addManufacturingWorkingUnit } from "api/new-production-plans/calls";
import { productionPlansKeys } from "api/new-production-plans/keys";
import {
  AddManufacturingWorkingUnit,
  Employee,
  EmployeeWorkingDay,
  ProductionPlan,
} from "api/new-production-plans/models";
import { UUID } from "api/types";
import { useMutation } from "hooks/useMutation";
import { QueryClient } from "react-query";
import { getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Assign } from "utility-types";
import { isCategorySummaryAlreadyAdded } from "../utils/isCategorySummaryAlreadyAdded";
import { isMoreThanOneSummarySlot } from "../utils/isMoreThanOneSummarySlot";

export const useMoveManufacturingWorkingUnitBetweenEmployeesAndDays = () => {
  const handleManufacturingWorkingUnitAssignmentRollback = (
    productionPlanSchedule: ProductionPlan,
    productionPlanId: UUID,
    queryClient: QueryClient,
  ) => {
    queryClient.setQueryData<ProductionPlan>(
      productionPlansKeys.productionPlanSchedule(productionPlanId),
      currentPlan => {
        assertIsDefined(currentPlan);
        return productionPlanSchedule;
      },
    );
  };

  return useMutation(
    ({
      employeeWorkingDay,
      manufacturingWorkingUnit,
      stageManufacturingPlan,
    }: Assign<
      AddManufacturingWorkingUnit,
      {
        sourceId: string | number;
      }
    >) => {
      return addManufacturingWorkingUnit({
        employeeWorkingDay,
        manufacturingWorkingUnit,
        stageManufacturingPlan,
      });
    },
    ({ queryClient, toastr }) => ({
      onMutate: args => {
        const prevProductionPlanSchedule = queryClient.getQueryData<ProductionPlan>(
          productionPlansKeys.productionPlanSchedule(String(args.stageManufacturingPlan)),
        );

        // used to optimistically insert new unit after dropping
        const sourceEmployeeWorkingDay =
          prevProductionPlanSchedule &&
          prevProductionPlanSchedule.employees.reduce(
            (foundEmployeeWorkingDay: EmployeeWorkingDay | null, employee: Employee) => {
              if (foundEmployeeWorkingDay) {
                return foundEmployeeWorkingDay;
              }

              const foundDay = employee.employeeWorkingDays.find(
                employeeWorkingDay => employeeWorkingDay.id === args.sourceId,
              );

              return foundDay !== undefined ? foundDay : null;
            },
            null,
          );

        if (args.stageManufacturingPlan) {
          // Move MWU from one EmployeeWorkingDay to another
          queryClient.setQueryData<ProductionPlan>(
            productionPlansKeys.productionPlanSchedule(String(args.stageManufacturingPlan)),
            currentPlan => {
              assertIsDefined(currentPlan);
              return {
                ...currentPlan,
                employees: currentPlan.employees.map(employee => {
                  const destinationEmployeeDay = employee.employeeWorkingDays.find(
                    employeeWorkingDay => employeeWorkingDay.id === args.employeeWorkingDay,
                  );
                  const sourceEmployeeDay = employee.employeeWorkingDays.find(
                    employeeWorkingDay => employeeWorkingDay.id === args.sourceId,
                  );

                  if (destinationEmployeeDay && sourceEmployeeDay) {
                    return {
                      ...employee,
                      employeeWorkingDays: employee.employeeWorkingDays.map(employeeWorkingDay => {
                        if (
                          destinationEmployeeDay &&
                          employeeWorkingDay.id === args.employeeWorkingDay
                        ) {
                          const alreadyAddedManufacturingWorkingUnit = employeeWorkingDay.manufacturingWorkingUnits.find(
                            manufacturingWorkingUnit =>
                              manufacturingWorkingUnit.id === args.manufacturingWorkingUnit,
                          );

                          if (alreadyAddedManufacturingWorkingUnit) {
                            toastr.open({
                              type: "warning",
                              title: "Wymagane działanie",
                              text: "Pozycja została już dodana",
                            });
                          }

                          if (
                            alreadyAddedManufacturingWorkingUnit === undefined &&
                            sourceEmployeeWorkingDay
                          ) {
                            const draggableItemToAssign = sourceEmployeeWorkingDay.manufacturingWorkingUnits.find(
                              manufacturingWorkingUnit =>
                                manufacturingWorkingUnit.id === args.manufacturingWorkingUnit,
                            );

                            if (draggableItemToAssign) {
                              return {
                                ...employeeWorkingDay,
                                categorySummary: isCategorySummaryAlreadyAdded(
                                  draggableItemToAssign.index.category,
                                  employeeWorkingDay.categorySummary,
                                )
                                  ? employeeWorkingDay.categorySummary.map(categorySummary => {
                                      if (
                                        categorySummary.code ===
                                          draggableItemToAssign.index.category.code &&
                                        categorySummary.color ===
                                          draggableItemToAssign.index.category.color &&
                                        categorySummary.name ===
                                          draggableItemToAssign.index.category.name
                                      ) {
                                        return {
                                          ...categorySummary,
                                          slots: categorySummary.slots + 1,
                                        };
                                      }
                                      return categorySummary;
                                    })
                                  : [
                                      ...employeeWorkingDay.categorySummary,
                                      {
                                        code: draggableItemToAssign.index.category.code,
                                        color: draggableItemToAssign.index.category.color,
                                        name: draggableItemToAssign.index.category.name,
                                        slots: 1,
                                      },
                                    ],
                                manufacturingWorkingUnits: [
                                  ...employeeWorkingDay.manufacturingWorkingUnits,
                                  { ...draggableItemToAssign },
                                ],
                              };
                            }
                          }
                        }
                        if (
                          sourceEmployeeDay &&
                          sourceEmployeeWorkingDay &&
                          employeeWorkingDay.id === sourceEmployeeWorkingDay.id
                        ) {
                          const removableManufacturingWorkingUnit = employeeWorkingDay.manufacturingWorkingUnits.find(
                            manufacturingWorkingUnit =>
                              manufacturingWorkingUnit.id === args.manufacturingWorkingUnit,
                          );

                          return {
                            ...employeeWorkingDay,
                            categorySummary:
                              removableManufacturingWorkingUnit &&
                              isMoreThanOneSummarySlot(
                                employeeWorkingDay.categorySummary,
                                removableManufacturingWorkingUnit.index.category,
                              )
                                ? employeeWorkingDay.categorySummary.map(summary => {
                                    const removableCategory =
                                      removableManufacturingWorkingUnit.index.category;
                                    if (
                                      summary.code === removableCategory.code &&
                                      summary.color === removableCategory.color &&
                                      summary.name === removableCategory.name
                                    ) {
                                      return {
                                        ...summary,
                                        slots: summary.slots - 1,
                                      };
                                    }

                                    return summary;
                                  })
                                : removableManufacturingWorkingUnit &&
                                  !isMoreThanOneSummarySlot(
                                    employeeWorkingDay.categorySummary,
                                    removableManufacturingWorkingUnit.index.category,
                                  )
                                ? employeeWorkingDay.categorySummary.filter(
                                    summary =>
                                      summary.code !==
                                        removableManufacturingWorkingUnit.index.category.code &&
                                      summary.color !==
                                        removableManufacturingWorkingUnit.index.category.color &&
                                      summary.name !==
                                        removableManufacturingWorkingUnit.index.category.name,
                                  )
                                : employeeWorkingDay.categorySummary,
                            manufacturingWorkingUnits: employeeWorkingDay.manufacturingWorkingUnits.filter(
                              manufacturingWorkingUnit =>
                                manufacturingWorkingUnit.id !== args.manufacturingWorkingUnit,
                            ),
                          };
                        }
                        return employeeWorkingDay;
                      }),
                    };
                  } else {
                    if (destinationEmployeeDay) {
                      return {
                        ...employee,
                        employeeWorkingDays: employee.employeeWorkingDays.map(
                          employeeWorkingDay => {
                            if (employeeWorkingDay.id === args.employeeWorkingDay) {
                              const alreadyAddedManufacturingWorkingUnit = employeeWorkingDay.manufacturingWorkingUnits.find(
                                manufacturingWorkingUnit =>
                                  manufacturingWorkingUnit.id === args.manufacturingWorkingUnit,
                              );
                              if (alreadyAddedManufacturingWorkingUnit) {
                                toastr.open({
                                  type: "warning",
                                  title: "Wymagane działanie",
                                  text: "Pozycja została już dodana",
                                });
                              }
                              if (
                                alreadyAddedManufacturingWorkingUnit === undefined &&
                                sourceEmployeeWorkingDay
                              ) {
                                const draggableItemToAssign = sourceEmployeeWorkingDay.manufacturingWorkingUnits.find(
                                  manufacturingWorkingUnit =>
                                    manufacturingWorkingUnit.id === args.manufacturingWorkingUnit,
                                );
                                if (draggableItemToAssign) {
                                  return {
                                    ...employeeWorkingDay,
                                    categorySummary: isCategorySummaryAlreadyAdded(
                                      draggableItemToAssign.index.category,
                                      employeeWorkingDay.categorySummary,
                                    )
                                      ? employeeWorkingDay.categorySummary.map(categorySummary => {
                                          if (
                                            categorySummary.code ===
                                              draggableItemToAssign.index.category.code &&
                                            categorySummary.color ===
                                              draggableItemToAssign.index.category.color &&
                                            categorySummary.name ===
                                              draggableItemToAssign.index.category.name
                                          ) {
                                            return {
                                              ...categorySummary,
                                              slots: categorySummary.slots + 1,
                                            };
                                          }
                                          return categorySummary;
                                        })
                                      : [
                                          ...employeeWorkingDay.categorySummary,
                                          {
                                            code: draggableItemToAssign.index.category.code,
                                            color: draggableItemToAssign.index.category.color,
                                            name: draggableItemToAssign.index.category.name,
                                            slots: 1,
                                          },
                                        ],
                                    manufacturingWorkingUnits: [
                                      ...employeeWorkingDay.manufacturingWorkingUnits,
                                      { ...draggableItemToAssign },
                                    ],
                                  };
                                }
                              }
                            }
                            return employeeWorkingDay;
                          },
                        ),
                      };
                    }
                    if (sourceEmployeeDay) {
                      return {
                        ...employee,
                        employeeWorkingDays: employee.employeeWorkingDays.map(
                          employeeWorkingDay => {
                            if (
                              sourceEmployeeWorkingDay &&
                              employeeWorkingDay.id === sourceEmployeeWorkingDay.id
                            ) {
                              const removableManufacturingWorkingUnit = employeeWorkingDay.manufacturingWorkingUnits.find(
                                manufacturingWorkingUnit =>
                                  manufacturingWorkingUnit.id === args.manufacturingWorkingUnit,
                              );

                              return {
                                ...employeeWorkingDay,
                                categorySummary:
                                  removableManufacturingWorkingUnit &&
                                  isMoreThanOneSummarySlot(
                                    employeeWorkingDay.categorySummary,
                                    removableManufacturingWorkingUnit.index.category,
                                  )
                                    ? employeeWorkingDay.categorySummary.map(summary => {
                                        const removableCategory =
                                          removableManufacturingWorkingUnit.index.category;
                                        if (
                                          summary.code === removableCategory.code &&
                                          summary.color === removableCategory.color &&
                                          summary.name === removableCategory.name
                                        ) {
                                          return {
                                            ...summary,
                                            slots: summary.slots - 1,
                                          };
                                        }

                                        return summary;
                                      })
                                    : removableManufacturingWorkingUnit &&
                                      !isMoreThanOneSummarySlot(
                                        employeeWorkingDay.categorySummary,
                                        removableManufacturingWorkingUnit.index.category,
                                      )
                                    ? employeeWorkingDay.categorySummary.filter(
                                        summary =>
                                          summary.code !==
                                            removableManufacturingWorkingUnit.index.category.code &&
                                          summary.color !==
                                            removableManufacturingWorkingUnit.index.category
                                              .color &&
                                          summary.name !==
                                            removableManufacturingWorkingUnit.index.category.name,
                                      )
                                    : employeeWorkingDay.categorySummary,
                                manufacturingWorkingUnits: employeeWorkingDay.manufacturingWorkingUnits.filter(
                                  manufacturingWorkingUnit =>
                                    manufacturingWorkingUnit.id !== args.manufacturingWorkingUnit,
                                ),
                              };
                            }
                            return employeeWorkingDay;
                          },
                        ),
                      };
                    }
                  }

                  return employee;
                }),
              };
            },
          );
        }

        return { prevProductionPlanSchedule };
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          productionPlansKeys.productionPlanSchedule(String(variables.stageManufacturingPlan)),
        );
      },
      onError: (error, variables, context) => {
        const { prevProductionPlanSchedule } = context as {
          prevProductionPlanSchedule: ProductionPlan;
        };
        handleManufacturingWorkingUnitAssignmentRollback(
          prevProductionPlanSchedule,
          variables.stageManufacturingPlan,
          queryClient,
        );
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

import cx from "classnames";
import { FormInput } from "components/utils";
import { Formik } from "formik";
import { UUID } from "api/types";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { SendNotificationToRecipient } from "api/trading-documents/models";
import { Button } from "components/miloDesignSystem/atoms/button";

interface Props {
  close: () => void;
  tradingDocumentId: UUID;
}

export const SendToSpecificAddressModal = ({ close, tradingDocumentId }: Props) => {
  const sendEmailNotificationMutation = tradingDocumentsActions.useSendEmailNotification();

  const initialValues: SendNotificationToRecipient = {
    recipientEmail: "",
    tradingDocumentId,
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="18" fontWeight="700">
          Wyślij fakturę na wskazany adres
        </Typography>
      }
      width={400}
    >
      <div className="p-3">
        <Formik
          initialValues={initialValues}
          onSubmit={values => {
            sendEmailNotificationMutation.mutate(values, {
              onSuccess: close,
            });
          }}
        >
          {({ handleSubmit, isValid }) => (
            <form onSubmit={handleSubmit} className={cx({ "was-validated": !isValid })}>
              <FormInput className="mt-3" type="email" name="recipientEmail" label="E-mail" />
              <Button
                className="text-uppercase"
                disabled={sendEmailNotificationMutation.isLoading}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Wyślij
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

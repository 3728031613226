import { polishMonthsAbbreviations } from "CONSTANTS";

export const getMonthAbbreviation = (date: string) => {
  const [year, week] = date.split("-");

  const startDate = new Date(Number(year), 0, (parseInt(week) - 1) * 7);
  const startMonth = startDate.getMonth();

  if (startMonth !== new Date(Number(year), 0, parseInt(week) * 7).getMonth()) {
    const endMonth = new Date(Number(year), 0, parseInt(week) * 7).getMonth();
    return `${polishMonthsAbbreviations[startMonth]}. - ${polishMonthsAbbreviations[endMonth]}.`;
  } else {
    return `${polishMonthsAbbreviations[startMonth]}.`;
  }
};

import { RouteEmails } from "api/routes/models";
import awaitingImg from "assets/images/141o.svg";
import completedImg from "assets/images/701.svg";
import letterIcon from "assets/images/866.svg";
import errorIcon from "assets/images/877.svg";
import cx from "classnames";
import { Button } from "components/common/button";
import styles from "../PointsSection.module.css";

export enum Status {
  FAILURE,
  AWAITING,
  CONFIRMED,
  NOT_SENT,
}

interface Props {
  order: RouteEmails["orders"][number];
  status: Status;
  handleConfirm: () => void;
  handleSingleEmailSend: () => void;
}

export const OrderEmailConfirmation = ({ status, handleConfirm, handleSingleEmailSend }: Props) => {
  // TODO: sendEmailMutation (missing backend)

  if (status === Status.FAILURE) {
    return (
      <div className={cx(styles.statusContainer, styles.errorMsg)}>
        <img alt="" src={errorIcon} />
        Nie wysłano wiadomości
        <Button className={styles.errorBtn} onClick={handleConfirm}>
          Potwierdź teraz
        </Button>
      </div>
    );
  }

  if (status === Status.AWAITING) {
    return (
      <div className={cx(styles.statusContainer, styles.awaiting)}>
        <img alt="" src={awaitingImg} />
        Oczekuję na potwierdzenie
        <Button className={styles.awaitingBtn} onClick={handleConfirm}>
          Potwierdź teraz
        </Button>
      </div>
    );
  }

  if (status === Status.CONFIRMED) {
    return (
      <div className={cx(styles.statusContainer, styles.confirmed)}>
        <img alt="" src={completedImg} />
        Potwierdzono termin odbioru
      </div>
    );
  }

  return (
    <div className={cx(styles.statusContainer, styles.notSent)}>
      <img alt="" src={letterIcon} />
      Nie wysłano wiadomości
      <Button
        className={styles.awaitingBtn}
        onClick={() => {
          handleSingleEmailSend();
        }}
      >
        Wyślij teraz
      </Button>
    </div>
  );
};

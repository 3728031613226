import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { HighlightedRow } from "api/other/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { ClickOutsideHandler } from "components/utils";
import { DatePicker } from "components/utils/datePicker";
import { priorityDict } from "pages/externalManufacturerPanel/shared/components/PriorityLabel";
import { useState } from "react";
import { cx, getIsoDateFormat } from "utilities";
import styles from "../../../externalManufacturerPanel/shared/ExternalManufacturerPanel.module.css";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiEvent } from "components/miloDesignSystem/atoms/icons/MdiEvent";
import { MdiMail } from "components/miloDesignSystem/atoms/icons/MdiMail";
import {
  NotificationType,
  PriorityChoice,
  UnsentEmailPreview,
} from "api/external-manufacturing/models";
import { useStateModal } from "hooks";
import { AskForSingleProductionItemsStatusReplyModal } from "pages/externalManufacturerPanel/shared/askForSingleProductionItemsStatusReplyModal/AskForSingleProductionItemsStatusReplyModal";

interface Props {
  highlightedRows: HighlightedRow[];
  setHighlightedRows: React.Dispatch<React.SetStateAction<HighlightedRow[]>>;
}

export const TableToolbarActions = ({ highlightedRows, setHighlightedRows }: Props) => {
  const bulkUpdateMutation = externalManufacturingActions.usePatchBulkUpdateMainLineItems();
  const pickupBySellerPlannedAtBulkUpdateMutation = externalManufacturingActions.usePatchBulkUpdateMainLineItems();
  const askForSingleProductionItemsStatusMutation = externalManufacturingActions.useAskForSingleProductionItemsStatus();
  const [pickupBySellerPlannedAt, setPickupBySellerPlannedAt] = useState("");
  const askForSingleProductionItemsStatusReplyModal = useStateModal<{
    preview: UnsentEmailPreview;
    sentEmails: number;
  }>();

  return (
    <>
      <TableToolbar
        close={() => setHighlightedRows([])}
        numberOfSelectedItems={highlightedRows.length}
      >
        <Popover
          hideOnClickOutside={false}
          content={({ hide }) => (
            <ClickOutsideHandler
              onClickOutside={() => {
                hide();
                setPickupBySellerPlannedAt("");
              }}
              outsideClickIgnoreClass={ignoreClickOutsideClassName}
            >
              <div>
                <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
                  Wskaż nową datę odbioru
                </Typography>
                <div className="d-flex">
                  <div className={cx(styles.datePicker, "position-relative mr-1")}>
                    <DatePicker
                      calendarClassName={ignoreClickOutsideClassName}
                      overwrites={{
                        container: { className: ignoreClickOutsideClassName },
                        popup: {
                          className: cx(ignoreClickOutsideClassName, styles.datePicker),
                        },
                      }}
                      placeholder="Wybierz"
                      className={styles.date}
                      value={pickupBySellerPlannedAt}
                      selectsStart
                      removeDate={false}
                      tabIndex={-1}
                      onChange={date => {
                        if (!date) return;
                        setPickupBySellerPlannedAt(getIsoDateFormat(date));
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-1 justify-content-end mt-3">
                  <Button
                    className={cx("text-uppercase", styles.changeDeliveryDateBtn)}
                    size="small"
                    variant="deepPurple"
                    onClick={() => {
                      pickupBySellerPlannedAtBulkUpdateMutation.mutate(
                        {
                          ids: highlightedRows.map(e => String(e.id)),
                          pickupBySellerPlannedAt,
                        },
                        {
                          onSuccess: hide,
                        },
                      );
                    }}
                    isLoading={pickupBySellerPlannedAtBulkUpdateMutation.isLoading}
                  >
                    Zmień datę odbioru
                  </Button>
                </div>
              </div>
            </ClickOutsideHandler>
          )}
          variant="DARK"
        >
          <div>
            <Tooltip title="Zmień datę odbioru">
              <IconButton icon={MdiEvent} variant="transparent" theme="dark" />
            </Tooltip>
          </div>
        </Popover>
        <Tooltip title="Wyślij email z prośbą o potwierdzenie">
          <IconButton
            isLoading={askForSingleProductionItemsStatusMutation.isLoading}
            onClick={() => {
              askForSingleProductionItemsStatusMutation.mutate(
                {
                  notificationType: NotificationType.ASK_FOR_STATUS,
                  singleProductionItems: highlightedRows.map(e => String(e.id)),
                },
                {
                  onSuccess: (payload, args) => {
                    askForSingleProductionItemsStatusReplyModal.open({
                      preview: payload,
                      sentEmails: args.singleProductionItems.length,
                    });
                  },
                },
              );
            }}
            icon={MdiMail}
            variant="transparent"
            theme="dark"
          />
        </Tooltip>
        <Tooltip title="Ustaw priorytet A">
          <IconButton
            onClick={() => {
              bulkUpdateMutation.mutate({
                ids: highlightedRows.map(e => String(e.id)),
                priority: PriorityChoice.HIGH,
              });
            }}
            icon={
              <Typography fontSize="12" fontWeight="600" color={priorityDict[2].color}>
                {priorityDict[2].label}
              </Typography>
            }
            variant="transparent"
            theme="dark"
          />
        </Tooltip>
        <Tooltip title="Ustaw priorytet B">
          <IconButton
            onClick={() => {
              bulkUpdateMutation.mutate({
                ids: highlightedRows.map(e => String(e.id)),
                priority: PriorityChoice.MEDIUM,
              });
            }}
            icon={
              <Typography fontSize="12" fontWeight="600" color={priorityDict[1].color}>
                {priorityDict[1].label}
              </Typography>
            }
            variant="transparent"
            theme="dark"
          />
        </Tooltip>
        <Tooltip title="Ustaw priorytet C">
          <IconButton
            onClick={() => {
              bulkUpdateMutation.mutate({
                ids: highlightedRows.map(e => String(e.id)),
                priority: PriorityChoice.LOW,
              });
            }}
            icon={
              <Typography fontSize="12" fontWeight="600" color={priorityDict[0].color}>
                {priorityDict[0].label}
              </Typography>
            }
            variant="transparent"
            theme="dark"
          />
        </Tooltip>
        <Tooltip title="Potwierdź datę odbioru">
          <IconButton
            icon={MdiCheck}
            variant="transparent"
            theme="dark"
            onClick={() => {
              bulkUpdateMutation.mutate({
                ids: highlightedRows.map(e => String(e.id)),
                pickedUpBySeller: true,
                pickedUpAt: getIsoDateFormat(new Date()),
              });
            }}
          />
        </Tooltip>
      </TableToolbar>
      {askForSingleProductionItemsStatusReplyModal.isOpen &&
        askForSingleProductionItemsStatusReplyModal.state && (
          <AskForSingleProductionItemsStatusReplyModal
            close={askForSingleProductionItemsStatusReplyModal.close}
            preview={askForSingleProductionItemsStatusReplyModal.state.preview}
            sentEmails={askForSingleProductionItemsStatusReplyModal.state.sentEmails}
          />
        )}
    </>
  );
};

const ignoreClickOutsideClassName = "main-manufacturer-line-items-popover-ignore-class-name";

import { OrderMessagesSchema } from "api/orders/models";

type Message = OrderMessagesSchema["messages"][number];
export const statusDict: Record<Message["status"], string> = {
  AWAITING: "oczekiwanie",
  DELIVERED: "doręczono",
  SENT: "wysłano",
  UNDELIVERED: "nie doręczono",
  UNSENT: "nie wysłano",
};

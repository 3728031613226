import { AutoAssignOrderBy, AutoAssignProcedure } from "api/new-production-plans/enums";
import { ShelfFilters, ShelfItem } from "api/new-production-plans/models";
import { UUID } from "api/types";
import { Button } from "components/common";
import { RadioLabels } from "components/utils";
import { CustomModal } from "components/utils/customModal";
import { useAutoAssignManufacturingWorkingUnits } from "pages/productionPlans/productionPlanDetails/hooks/useAutoAssignManufacturingWorkingUnits";
import { useState } from "react";
import { queryString } from "utilities";
import styles from "./AutoAssignModal.module.css";

interface Props {
  close: () => void;
  filters: ShelfFilters;
  productionPlanId: UUID;
  search: string;
  shelfItem?: ShelfItem;
}

const autoAssignProcedures: Record<AutoAssignProcedure, string> = {
  EVEN: "wypełniaj po równo",
  FILL: "wypełniaj po kolei",
};

const autoAssignOrderBy: Record<AutoAssignOrderBy, string> = {
  priority: "najwyższy priorytet",
  source_departure: "najwcześniejsze daty wyjazdu",
};

const autoAssignProceduresSelect: { id: AutoAssignProcedure; name: string }[] = [
  { id: AutoAssignProcedure.EVEN, name: autoAssignProcedures[AutoAssignProcedure.EVEN] },
  { id: AutoAssignProcedure.FILL, name: autoAssignProcedures[AutoAssignProcedure.FILL] },
];

const autoAssignOrderBySelect: { id: AutoAssignOrderBy; name: string }[] = [
  { id: AutoAssignOrderBy.PRIORITY, name: autoAssignOrderBy[AutoAssignOrderBy.PRIORITY] },
  {
    id: AutoAssignOrderBy.SOURCE_DEPARTURE,
    name: autoAssignOrderBy[AutoAssignOrderBy.SOURCE_DEPARTURE],
  },
];

export const AutoAssignModal = ({ close, filters, productionPlanId, search, shelfItem }: Props) => {
  const [procedure, setProcedure] = useState<AutoAssignProcedure>(AutoAssignProcedure.FILL);
  const [orderBy, setOrderBy] = useState<AutoAssignOrderBy[]>([AutoAssignOrderBy.SOURCE_DEPARTURE]);

  const autoAssignMutation = useAutoAssignManufacturingWorkingUnits();

  const handleSubmit = () => {
    autoAssignMutation.mutate(
      {
        filters,
        group: shelfItem
          ? {
              groupBy: queryString.parse(search).groupBy,
              groupByFilter:
                shelfItem.header !== "Pozostałe"
                  ? shelfItem.origin
                  : queryString.parse(search).groupBy,
              groupByValue: shelfItem.filterValue ? shelfItem.filterValue : "",
            }
          : undefined,
        rules: {
          procedure,
          orderBy,
        },
        id: productionPlanId,
      },
      {
        onSuccess: () => {
          close();
        },
      },
    );
  };

  const changeOrderBy = (value: string | number | null) => {
    if (!value) return;
    if (orderBy.includes(value as AutoAssignOrderBy)) {
      setOrderBy(prevOrderByList => prevOrderByList.filter(orderBy => orderBy !== String(value)));
    } else {
      setOrderBy(prevOrderByList => {
        return [...prevOrderByList, value as AutoAssignOrderBy];
      });
    }
  };

  return (
    <CustomModal
      close={close}
      isOpen
      overrides={{ container: { className: styles.modalContainer } }}
      title="Rozłóż automatycznie"
    >
      <div className="pb-3 pr-3 pl-3 pt-0 d-flex flex-column gap-3">
        <div>
          <div className="text-black-6">Strategia przydziału:</div>
          <RadioLabels
            allowUncheck={false}
            items={autoAssignProceduresSelect}
            name=""
            onChange={({ value }) => {
              if (!value) return;
              setProcedure(value as AutoAssignProcedure);
            }}
            value={procedure}
          />
        </div>
        <div>
          <div className="text-black-6">Przydziel w pierwszej kolejności wg:</div>
          <RadioLabels
            items={autoAssignOrderBySelect}
            multiple={true}
            name=""
            onChange={({ value }) => changeOrderBy(value)}
            values={orderBy}
          />
        </div>
        <div className="d-flex align-items-center justify-content-end gap-3 p-3 borderTop">
          <Button className="uppercase" kind="create-medium-transparent" onClick={close}>
            <div className="btnBaseMedium btnBase">Anuluj</div>
          </Button>
          <Button
            disabled={autoAssignMutation.isLoading}
            kind="submit-medium"
            onClick={handleSubmit}
          >
            <div className="btnBaseMedium btnBase">Rozłóż automatycznie</div>
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

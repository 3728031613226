import { createPaginatedKey } from "api/keys";

export const financesOverviewKeys = {
  soldPackages: {
    list: createPaginatedKey("soldPackages"),
  },
  soldIndexes: {
    list: createPaginatedKey("soldIndexes"),
  },
};

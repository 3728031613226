import { OrderPositionForCreatingInvoice } from "api/trading-documents/models";
import { Assign } from "utility-types";
import { CreateCorrectionPosition, CreateCorrectionValues } from "../CreateCorrectionManuallyModal";

export const getInitialValues = (
  positions: (OrderPositionForCreatingInvoice & { orderSignature: string })[],
): CreateCorrectionValues => {
  // @ts-ignore
  const initialPositions: Assign<
    CreateCorrectionPosition[],
    { isPositionEdited: boolean }
  > = positions.map(position => ({
    ...position,
    isPositionEdited: false,
    initialAmountWithTax: position.amountWithTax,
    initialQuantity: position.quantity,
    initialAmountWihoutTax: position.amountWithoutTax,
    initialTaxAmountBeforeRounding: position.taxAmountBeforeRounding,
  }));

  return {
    positions: initialPositions,
  };
};

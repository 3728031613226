import { Button } from "components/common";
import { useToggle } from "hooks";
import filterIcon from "assets/images/38.svg";
import styles from "./ProductFilterBtn.module.css";
import { ProductFilterModal } from "pages/orders/ordersView/components/productFilterModal/ProductFilterModal";

export const ProductFilterBtn = () => {
  const productFilterModal = useToggle();

  return (
    <div className={styles.container}>
      <Button
        kind="secondary"
        size="round-m"
        title="Filtruj po produktach i indeksach"
        onClick={productFilterModal.open}
      >
        <img src={filterIcon} alt="" />
      </Button>
      {productFilterModal.isOpen && <ProductFilterModal close={productFilterModal.close} />}
    </div>
  );
};

import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import chair from "assets/images/moduleNavigationIcons/chair.svg";
import { Products } from "routes/inventory/Products";

export const products: ModuleLink = {
  url: "products",
  label: "Produkty",
  icon: chair,
  subSection: <></>,
  routing: Products,
};

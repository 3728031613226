import { postUserCenterPoint } from "api/routes/calls";
import { useRedux } from "hooks";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";

export const useCreateUserCenterPoint = () => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  return useMutation(postUserCenterPoint, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      refetchPartials();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Ustawiono centralny punkt",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

import { patchItemSummaryAmounts } from "api/trading-documents/calls";
import { useTradingDocument } from "api/trading-documents/hooks";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { PatchItemSummaryAmountPayload, TradingDocument } from "api/trading-documents/models";
import { UUID } from "api/types";
import { useMutation } from "hooks/useMutation";
import produce from "immer";
import { useState } from "react";
import { assertIsDefined } from "utilities/assertIsDefined";

export const useEditTradingDocumentItemValue = (
  fieldToUpdate: keyof PatchItemSummaryAmountPayload,
  tradingDocumentId: UUID,
) => {
  const [showInput, setShowInput] = useState(false);
  const { handleMutate } = useTradingDocument({ id: tradingDocumentId }, { enabled: false });

  const editItemValueMutation = useMutation(patchItemSummaryAmounts, ({ queryClient, toastr }) => ({
    onMutate: args => {
      return handleMutate(draft => {
        draft.isManagedManually = true;
        draft.items = draft.items.map(item => {
          if (item) {
            item.tradingDocumentItems = item.tradingDocumentItems.map(_tradingDocumentItem => {
              if (_tradingDocumentItem.id === args.id && fieldToUpdate !== "discount") {
                _tradingDocumentItem[fieldToUpdate] = Number(args[fieldToUpdate]);
              } else if (_tradingDocumentItem.id === args.id && fieldToUpdate === "discount") {
                _tradingDocumentItem[fieldToUpdate] = Number(args[fieldToUpdate]);
                _tradingDocumentItem.discountAmount =
                  args.discount !== undefined
                    ? Number(args.discount / 100) * _tradingDocumentItem.amountWithoutTax
                    : 0;
              }
              return _tradingDocumentItem;
            });
          }
          return item;
        });
      });
    },
    onSuccess: payload => {
      queryClient.setQueryData<TradingDocument>(
        tradingDocumentsKeys.tradingDocument.details(tradingDocumentId),
        currentPanel => {
          assertIsDefined(currentPanel);
          return produce(currentPanel, draft => {
            draft.isManagedManually = payload.isManagedManually;
          });
        },
      );
      queryClient.invalidateQueries(
        tradingDocumentsKeys.tradingDocument.details(tradingDocumentId),
      );
      toastr.open({
        type: "success",
        title: "Udało się!",
        text:
          fieldToUpdate === "amountWithTax"
            ? "Zmodyfikowano cenę brutto"
            : fieldToUpdate === "amountWithoutTax"
            ? "Zmodyfikowano cenę netto"
            : "Zmodyfikowano rabat",
      });
    },
    onError: (error, _, rollback) => {
      // @ts-ignore
      rollback(error);
    },
    onSettled: () => {
      setShowInput(false);
    },
  }));

  return {
    editItemValueMutation,
    setShowInput,
    showInput,
  };
};

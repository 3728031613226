import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import cx from "classnames";
import { UserShortType } from "api/types";
import styles from "./AvatarWithTooltip.module.css";

interface Props {
  user: UserShortType;
  className?: string;
}

export const AvatarWithTooltip = ({ user, className }: Props) => {
  if (!user) return null;

  return (
    <div
      className={cx(
        "d-flex align-items-center gap-2 position-relative",
        className,
        styles.hoverable,
      )}
    >
      <AvatarOrInitials
        className="my-0"
        avatarSrc={user.avatar}
        firstName={user.firstName}
        lastName={user.lastName}
      />
      <div className={styles.tooltip}>
        {user.firstName} {user.lastName}
      </div>
    </div>
  );
};

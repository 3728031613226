import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import scene from "assets/images/moduleNavigationIcons/scene.svg";
import { Dashboard } from "routes/inventory/Dashboard";

export const assortment: ModuleLink = {
  url: "assortment",
  label: "Asortymenty",
  icon: scene,
  subSection: <></>,
  routing: Dashboard,
  isAccessible: false,
};

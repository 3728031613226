export function getLabelColor(leftDays: number) {
  if (leftDays > 14) {
    return "blue";
  }
  if (leftDays >= 7) {
    return "blue";
  }
  if (leftDays >= 2) {
    return "green";
  }
  if (leftDays >= 0) {
    return "yellow";
  }
  if (leftDays >= -7) {
    return "orange";
  }
  if (leftDays >= -14) {
    return "pink";
  } else {
    return "dark-red";
  }
}

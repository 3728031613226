import { postCarrier } from "api/logistics/carriers/calls";
import { carriersKeys } from "api/logistics/carriers/keys";
import { CreateCarrier } from "api/logistics/carriers/models";
import { FormikHelpers } from "formik";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";

export const usePostCarrier = (close: () => void) => {
  const createCarrier = useMutation(postCarrier, ({ toastr, queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(carriersKeys.carriers.list());
      close();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Dodano przewoźnika",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

  const handleSubmit = (values: CreateCarrier, actions: FormikHelpers<CreateCarrier>) => {
    createCarrier.mutate(values, {
      onSuccess: () => {
        actions.setSubmitting(false);
      },
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

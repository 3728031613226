import styles from "./CustomerPdfModal.module.css";
import { Modal, RadioGroup } from "components/utils";
import { Button } from "components/common";
import removeIcon from "assets/images/11.svg";
import { Route } from "api/routes/models";
import { useState } from "react";
import cx from "classnames";
import { DOMAIN } from "ENDPOINTS";
import { useSelector } from "hooks";

const formatSignatureForPreview = (signature: string) => signature.replace(/\s|\//g, "-");

interface Props {
  isOpen: boolean;
  close: () => void;
  route: Route;
}

export const CustomerPdfModal = ({ close, isOpen, route }: Props) => {
  const [customer, setCustomer] = useState<number | null>(null);
  const [search, setSearch] = useState("");
  const routeCustomers = route.orders.map(el => el.customer).filter(Boolean);
  const authUser = useSelector(state => state.auth.user!);

  const uniqueCustomers = [];
  const map = new Map();
  for (const item of routeCustomers) {
    if (!item) return null;
    if (!map.has(item.id)) {
      map.set(item.id, true); // set any value to Map
      uniqueCustomers.push({
        id: item.id,
        name: item.name,
      });
    }
  }

  const customers = uniqueCustomers.filter(({ name }) => {
    return name.indexOf(search) > -1;
  });

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      size="auto"
      overrides={{ container: { className: styles.modal } }}
    >
      <div className="d-flex flex-column">
        <h4 className={styles.title}>Pobierz PDF trasy na kontrahenta</h4>
        <div className={styles.inputContainer}>
          <input
            value={search}
            onChange={e => setSearch(e.target.value)}
            className={styles.input}
            placeholder="Szukaj..."
          />
          {search && (
            <img
              className={styles.inputIcon}
              src={removeIcon}
              alt=""
              onClick={() => setSearch("")}
            />
          )}
        </div>
        {customers.length ? (
          <RadioGroup
            className={cx("d-flex flex-column", styles.container)}
            name="customer"
            items={customers.map(el => ({ id: el!.id, name: el!.name }))}
            onChange={val => val && setCustomer(val.id)}
            value={customer}
          />
        ) : (
          "Brak kontrahenta o takiej nazwie"
        )}
        <Button
          type="button"
          kind="primary"
          size="medium"
          className="mt-3 align-self-start"
          as="a"
          disabled={!customer}
          rel="noopener noreferrer"
          href={`${DOMAIN}routes/route-pdf/${route.id}/${formatSignatureForPreview(
            route.signature,
          )}?user=${authUser.id}&customer=${customer}`}
          target="_blank"
        >
          <span>Pobierz PDF</span>
        </Button>
      </div>
    </Modal>
  );
};

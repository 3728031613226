import { flexRender, Cell as CellType } from "@tanstack/react-table";
import { TableProps } from "components/miloDesignSystem/molecules/table/types";

export const Cell = <T,>({
  cell,
  uiSchema,
}: {
  cell: CellType<T, unknown>;
  uiSchema: TableProps<T>["uiSchema"];
}) => {
  const cellWidth = cell.column.getSize();
  return (
    <div
      className="d-flex align-items-center pr-2"
      style={{
        width: cellWidth,
        minWidth: cellWidth,
        maxWidth: cellWidth,
        height: `${uiSchema.cell.height}px`,
        minHeight: `${uiSchema.cell.height}px`,
        maxHeight: `${uiSchema.cell.height}px`,
      }}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </div>
  );
};

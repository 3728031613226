import { InvoiceAdmission } from "api/trading-documents/models";
import { queryString } from "utilities";

export const getSearch = (
  invoiceAdmission: InvoiceAdmission,
  whAggregateSearchDates: string[],
): string => {
  return queryString.stringify({
    dates: whAggregateSearchDates.join(","),
    manufacturer: invoiceAdmission.whAggregateSearchManufacturer
      ? invoiceAdmission.whAggregateSearchManufacturer.id
      : "",
    packages: invoiceAdmission.whAggregateSearchPackage,
    pageSize: invoiceAdmission.whAggregateSearchPageSize
      ? invoiceAdmission.whAggregateSearchPageSize
      : "",
  });
};

import { useState } from "react";
import { CommonError, Modal, Spinner } from "components/utils";
import styles from "./DailyStatistics.module.css";
import cx from "classnames";
import { useDailyStatistics } from "hooks/apiPrimitives";
import { useQuery, useSelector, useStateModal } from "hooks";
import { dateFns, queryString } from "utilities";
import { DatePicker } from "components/utils/datePicker";

type TimePeriod = "today" | "tomorrow" | "week" | "month";

const getTimePeriods: () => {
  name: TimePeriod;
  label: string;
  dateFrom: Date;
  dateTo: Date;
}[] = () => {
  const today = new Date();
  const monday = dateFns.startOfWeek(today, { weekStartsOn: 1 });
  const sunday = dateFns.lastDayOfWeek(today, { weekStartsOn: 1 });
  const saturday = dateFns.subDays(sunday, 1);
  const firstDayOfMonth = dateFns.startOfMonth(today);
  const lastDayOfMonth = dateFns.lastDayOfMonth(today);
  const tomorrow = dateFns.addDays(today, 1);

  return [
    { name: "today", label: "Dzisiaj", dateFrom: today, dateTo: today },
    { name: "tomorrow", label: "Jutro", dateFrom: tomorrow, dateTo: tomorrow },
    { name: "week", label: "Ten tydzień", dateFrom: monday, dateTo: saturday },
    {
      name: "month",
      label: "Najbliższe 30 dni",
      dateFrom: firstDayOfMonth,
      dateTo: lastDayOfMonth,
    },
  ];
};

const Statistics = ({
  item,
}: {
  item: {
    orderGroupCount: number;
    routeCount: number;
    id: number;
    name: string;
    picture: string | null;
  };
}) => {
  const { picture, name, orderGroupCount, routeCount } = item;
  return (
    <div className={styles.tableRow}>
      <div className={styles.tableCell}>
        <div className={styles.name}>
          {picture && <img src={picture} alt="przedmiot" className="mr-2" />}
          <span>{name}</span>
        </div>
      </div>
      <div className={cx(styles.tableCell, "justify-content-end")}>
        <strong>{Number(routeCount)} szt.</strong>
      </div>
      <div className={cx(styles.tableCell, "justify-content-end")}>
        <strong>{Number(orderGroupCount)} szt.</strong>
      </div>
      <div className={cx(styles.tableCell, "justify-content-end")}>
        <strong>{Number(routeCount) + Number(orderGroupCount)} szt.</strong>
      </div>
    </div>
  );
};

interface Props {
  stateModal: ReturnType<typeof useStateModal>;
}

export const DailyStatistics = ({ stateModal }: Props) => {
  const { query } = useQuery();
  const [singleDay, setSingleDay] = useState(dateFns.format(new Date(), "yyyy-MM-dd"));
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState<TimePeriod | "">("today");

  const search = queryString.stringify({
    ...query,
    dateFrom: singleDay ? singleDay : start,
    dateTo: singleDay ? singleDay : end,
  });

  const [statistics, { error, httpStatus, inProgress }] = useDailyStatistics(search, {
    skip: !stateModal.state,
    clearSkip: true,
  });

  const availableCategoriesList = useSelector(
    state => state.partials.configuration.productCategoriesToCount,
  );

  const categoriesListWithCount = availableCategoriesList.map(cat => {
    const categoryCount = statistics?.categories.find(({ id }) => id === cat.id);
    return {
      ...cat,
      orderGroupCount: categoryCount?.orderGroupCount || 0,
      routeCount: categoryCount?.routeCount || 0,
    };
  });

  const timePeriods = getTimePeriods();

  return (
    <Modal isOpen={stateModal.isOpen} close={stateModal.close} size={{ all: { width: "58vw" } }}>
      <div className={styles.container}>
        {error && <CommonError status={httpStatus} />}
        <>
          <h4 className="mb-3 font-weight-bold">Dzienna produkcja</h4>
          <Spinner
            style={{ height: "90%", width: "92%" }}
            color="blue"
            size="big"
            text="trwa wczytywanie"
            position="absolute"
            on={inProgress}
          />
          <div className="d-flex align-items-center justify-content-between my-4">
            <div className="d-flex align-items-center">
              <div className="position-relative mr-4">
                <span className={styles.dateLabel}>Data</span>
                <DatePicker
                  className={styles.date}
                  value={singleDay}
                  onChange={date => {
                    if (!date) {
                      setSingleDay("");
                      setSelectedPeriod("");
                    } else {
                      setSingleDay(dateFns.format(new Date(date), "yyyy-MM-dd"));
                      setStart("");
                      setEnd("");
                      setSelectedPeriod("");
                    }
                  }}
                  overwrites={{ popup: { className: styles.datePickerPopup } }}
                />
              </div>
              <div className="position-relative mr-2">
                <span className={styles.dateLabel}>Zakres od</span>
                <DatePicker
                  className={styles.date}
                  value={start}
                  onChange={date => {
                    if (!date) {
                      setStart("");
                      setSelectedPeriod("");
                    } else {
                      setSingleDay("");
                      setSelectedPeriod("");
                      setStart(dateFns.format(new Date(date), "yyyy-MM-dd"));
                    }
                  }}
                  overwrites={{ popup: { className: styles.datePickerPopup } }}
                />
              </div>
              <div className="position-relative">
                <span className={styles.dateLabel}>Zakres do</span>
                <DatePicker
                  className={styles.date}
                  value={end}
                  onChange={date => {
                    if (!date) {
                      setEnd("");
                      setSelectedPeriod("");
                    } else {
                      setSingleDay("");
                      setSelectedPeriod("");
                      setEnd(dateFns.format(new Date(date), "yyyy-MM-dd"));
                    }
                  }}
                  overwrites={{ popup: { className: styles.datePickerPopup } }}
                />
              </div>
            </div>
            <div>
              {timePeriods.map(period => (
                <label className={styles.accountLabelBox} key={period.label}>
                  <input
                    type="checkbox"
                    checked={selectedPeriod === period.name}
                    onChange={() => {
                      setSelectedPeriod(period.name);
                      const isSingleDate = period.dateFrom === period.dateTo;
                      if (isSingleDate) {
                        setStart("");
                        setEnd("");
                        setSingleDay(dateFns.format(period.dateFrom, "yyyy-MM-dd"));
                      } else {
                        setSingleDay("");
                        setStart(dateFns.format(new Date(period.dateFrom), "yyyy-MM-dd"));
                        setEnd(dateFns.format(new Date(period.dateTo), "yyyy-MM-dd"));
                      }
                    }}
                  />
                  <span className={styles.accountLabel}>
                    <span>{period.label}</span>
                  </span>
                </label>
              ))}
            </div>
          </div>
          {statistics && (
            <div className={cx(styles.table, styles.summaryTable, "border-bottom")}>
              <div className={styles.tableHeader}>
                <div className={cx(styles.tableRow, "border-0")}>
                  <div className={styles.tableCell}>
                    <div className={styles.label}>Kategoria</div>
                  </div>
                  <div className={cx(styles.tableCell, "justify-content-end")}>
                    <div className={styles.label}>Trasy</div>
                  </div>
                  <div className={cx(styles.tableCell, "justify-content-end")}>
                    <div className={styles.label}>Grupy</div>
                  </div>
                  <div className={cx(styles.tableCell, "justify-content-end")}>
                    <div className={styles.label}>Łącznie</div>
                  </div>
                </div>
              </div>
              <div className={styles.tableBody}>
                {categoriesListWithCount.map(item => (
                  <Statistics key={item.id} item={item} />
                ))}
              </div>
            </div>
          )}
        </>
      </div>
    </Modal>
  );
};

import { RadioProps } from "./types";
import styles from "./Radio.module.css";
import { useMemo } from "react";
import cuid from "cuid";
import { Typography } from "../typography";
import { cx } from "utilities";

export const Radio = ({
  caption,
  checked,
  disabled,
  label,
  onClick,
  size = "sm",
  theme = "light",
}: RadioProps) => {
  const id = useMemo(() => cuid(), []);

  return (
    <div id={id} className={styles.radioContainer} onClick={() => onClick(!checked)}>
      <div
        className={cx(styles[`radioBase-${size}`], {
          [styles[`disabled-${theme}`]]: disabled,
          [styles[`radioBase-${theme}-checked`]]: checked && !disabled,
          [styles[`radioBase-${theme}-not-checked`]]: !checked && !disabled,
        })}
      >
        {checked && !disabled && <div className={styles[`checkedRadioInnerDot-${size}`]} />}
        {disabled && (
          <div
            className={cx(
              styles[`disabledInnerRectangle`],
              styles[`disabledInnerRectangle-${size}`],
            )}
          />
        )}
      </div>
      <div>
        {label && (
          <Typography fontSize={size === "sm" ? "12" : "14"} fontWeight="700">
            {label}
          </Typography>
        )}
        {caption && (
          <Typography className="italic" color="neutralBlack48" fontSize="10" fontWeight="600">
            {caption}
          </Typography>
        )}
      </div>
    </div>
  );
};

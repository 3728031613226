import { CURRENCY_TYPE } from "CONSTANTS";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx } from "utilities";

interface Props {
  amount: string;
  currency: CURRENCY_TYPE;
  className?: string;
}

export const AmountDisplay = ({ amount, currency, className }: Props) => {
  const formattedAmount = Number(amount).toLocaleString("de-DE", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const [integerPart, decimalPart] = formattedAmount.split(",");
  return (
    <div className={cx("d-flex align-items-baseline", className)}>
      <Typography fontSize="14" fontWeight="700">
        {integerPart}
      </Typography>
      <Typography fontSize="12" fontWeight="700" color="neutralBlack48">
        ,{decimalPart}&nbsp; {currency}
      </Typography>
    </div>
  );
};

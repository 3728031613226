import { Carrier, CarrierKind, kindDict } from "api/logistics/carriers/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useCarriersListColumns = () =>
  useCreateTableColumns<Carrier>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: "nazwa",
        size: 200,
      }),
      columnHelper.text(row => row.companyName, {
        header: "nazwa firmy",
        size: 200,
      }),
      columnHelper.text(row => row.taxId, {
        header: "NIP",
        size: 90,
      }),
      columnHelper.accessor(row => row.kind, {
        header: "rodzaj",
        size: 80,
        cell: info => {
          if (!info.getValue()) return null;
          const data = kindDict[info.getValue() as CarrierKind];
          return <Tag label={data.label} variant={data.variant} />;
        },
      }),
      columnHelper.text(row => row.phone, {
        header: "telefon",
        size: 100,
      }),
      columnHelper.text(row => row.email, {
        header: "e-mail",
        size: 150,
      }),
      columnHelper.text(row => row.street, {
        header: "adres",
        size: 150,
      }),
      columnHelper.text(row => row.postCode, {
        header: "kod pocztowy",
        size: 100,
      }),
      columnHelper.text(row => row.city, {
        header: "miasto",
        size: 150,
      }),
    ];
  });

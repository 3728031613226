import { storeConnector } from "./storeConnector";

/**
 * Controls "busy" and "idle" status. Sets "idle" when there are no async requests for at least 5 seconds. Propagates status to the redux store.
 */
class StatusController {
  private timeout = 0;
  private status = "busy";
  pendingRequests = 0;
  /**
   * Checks if all methods are invoked properly in scope of StatusController
   */
  private static checkThis(self: StatusController) {
    if (self instanceof StatusController === false) {
      throw new Error("error: function was invoked outside of StatusController scope");
    }
  }

  pageClosePrompt = {
    enable: () => {
      window.onbeforeunload = () => true;
    },
    disable: () => {
      window.onbeforeunload = null;
    },
  };

  increment() {
    StatusController.checkThis(this);
    this.pendingRequests += 1;
    this.pageClosePrompt.enable();

    window.clearTimeout(this.timeout);
    if (this.status === "idle") {
      this.setBusy();
    }
  }

  decrement() {
    StatusController.checkThis(this);
    this.pendingRequests -= 1;
    window.clearTimeout(this.timeout);
    if (this.pendingRequests === 0) {
      this.pageClosePrompt.disable();
      this.timeout = window.setTimeout(() => this.setIdle(), 4000);
    }
  }

  private setIdle() {
    StatusController.checkThis(this);
    this.status = "idle";
    storeConnector.dispatch?.({ type: "ui/setCommunicationStatus", payload: "idle" });
  }

  private setBusy() {
    StatusController.checkThis(this);
    this.status = "busy";
    storeConnector.dispatch?.({ type: "ui/setCommunicationStatus", payload: "busy" });
  }
}

export const CommunicationStatusController = new StatusController();

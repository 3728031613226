/**
 * converts camelCase to camel_case
 * @param {string} name
 */
function decamelize(name: string) {
  return name
    .replace(/\.?([A-Z])/g, function(x, y) {
      return "_" + y.toLowerCase();
    })
    .replace(/^_/, "");
}

export const decamelizeObject = (obj: Record<string, any>) => {
  return Object.keys(obj).reduce((acc: Record<string, any>, key) => {
    acc[decamelize(key)] = (() => {
      if (typeof obj[key] === "object") {
        if (!Array.isArray(obj[key])) {
          return decamelizeObject(obj[key]);
        } else {
          return obj[key].map((el: any) =>
            typeof el === "object" && !Array.isArray(el) ? decamelizeObject(el) : el,
          );
        }
      }
      return obj[key];
    })();
    return acc;
  }, {});
};

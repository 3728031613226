import { CSSProperties } from "react";
import styles from "./UserAvatar.module.css";
import cx from "classnames";

type InitialsAvatarProps =
  | {
      name: string;
      firstName?: undefined;
      lastName?: undefined;
    }
  | {
      name?: undefined;
      firstName: string;
      lastName: string;
    };

export const InitialsAvatar = ({
  name,
  firstName,
  lastName,
  className,
  title,
  style,
}: InitialsAvatarProps & { title?: string; className?: string; style?: CSSProperties }) => {
  const names = name ? name.split(" ").filter(Boolean) : [firstName || "", lastName || ""];
  return (
    <div
      className={cx(styles.avatar, styles.initialsAvatar, className)}
      title={title || ""}
      style={style}
    >
      {names.map((nameStr, index) => (
        <span key={index}>{nameStr.charAt(0).toLocaleUpperCase()}</span>
      ))}
    </div>
  );
};

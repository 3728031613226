import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import packageIcon from "assets/images/package_2.svg";
import { SoldPackagesRouting } from "routes/finances/SoldPackages";
import { FilterList } from "components/common/moduleNavigation/components/filtersSection/filterList/FilterList";
import { FilterType } from "components/common/filters/types";

export const soldPackages: ModuleLink = {
  url: "sold-packages",
  label: "Sprzedane paczki",
  icon: packageIcon,
  subSection: (
    <FilterList
      filters={[
        {
          type: FilterType.Select,
          kind: "label",
          label: "Grupuj po",
          name: "grouping_variable",
          options: [
            { label: "dzień", value: "DAY" },
            { label: "tydzień", value: "WEEK" },
            { label: "miesiąc", value: "MONTH" },
          ],
        },
        {
          type: FilterType.DateRange,
          label: "okres",
          name: ["start_date", "end_date"],
        },
      ]}
    />
  ),
  routing: SoldPackagesRouting,
};

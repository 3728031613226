import { Cell } from "components/utils";
import styles from "./UpholsteryStatuses.module.css";
import { UpholsteryOrder } from "api/orders/models";
import cx from "classnames";
import { useSelector } from "hooks";

interface Props {
  upholstery: UpholsteryOrder;
}

export const UpholsteryStatuses = ({ upholstery }: Props) => {
  const summary = upholstery.productionOrders.reduce(
    (acc, pOrder) => {
      if (pOrder.delegations.isCut) acc.cut += 1;
      if (pOrder.delegations.isSewn) acc.sewn += 1;
      if (pOrder.delegations.isPrepared) acc.prepared += 1;
      return acc;
    },
    { cut: 0, sewn: 0, prepared: 0, sentToManufacturer: 0, receivedFromManufacturer: 0 },
  );

  const itemsLength = upholstery.productionOrders.length;

  const configuration = useSelector(store => store.partials.productionSettings);

  if (upholstery.productionOrders.length <= 10) {
    return (
      <Cell className={styles.upholsteryStatus}>
        <div className="ml-0 ml-xl-2">
          <div className={styles["statuses-flow-box"]}>
            {upholstery.productionOrders.map(upholstery => (
              <div className="single-product mr-1" key={upholstery.id}>
                {configuration.showCut && (
                  <div
                    className={cx("position-relative", {
                      [styles.done]: upholstery.delegations.isCut,
                    })}
                  >
                    <div className={styles["status-dot"]}>K</div>
                  </div>
                )}
                {configuration.showSewn && (
                  <div
                    className={cx("position-relative", {
                      [styles.done]: upholstery.delegations.isSewn,
                    })}
                  >
                    <div className={styles["status-dot"]}>S</div>
                  </div>
                )}
                {configuration.showPrepared && (
                  <div
                    className={cx("position-relative", {
                      [styles.done]: upholstery.delegations.isPrepared,
                    })}
                  >
                    <div className={styles["status-dot"]}>T</div>
                  </div>
                )}
                {configuration.showReceivedFromManufacturer && (
                  <div
                    className={cx("position-relative", {
                      [styles.done]: upholstery.isSendToManufacturer,
                    })}
                  >
                    <div className={styles["status-dot"]}>W</div>
                  </div>
                )}
                {configuration.showReceivedFromManufacturer && (
                  <div
                    className={cx("position-relative", {
                      [styles.done]: upholstery.isReceivedFromManufacturer,
                    })}
                  >
                    <div className={styles["status-dot"]}>G</div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Cell>
    );
  } else {
    return (
      <Cell className={styles.upholsteryStatus}>
        <div className="ml-0 ml-xl-2">
          <div className={styles["statuses-flow-box"]}>
            <div>
              <div className={styles.statusesLabel}>wykonano:</div>
              <div className={styles.done}>
                <StatusSummary value={summary.cut} limit={itemsLength} label="K" />
                <StatusSummary value={summary.sewn} limit={itemsLength} label="S" />
                <StatusSummary value={summary.prepared} limit={itemsLength} label="T" />
              </div>
            </div>
          </div>
        </div>
      </Cell>
    );
  }
};

const StatusSummary = ({
  value,
  limit,
  label,
}: {
  value: number;
  limit: number;
  label: string;
}) => (
  <div className="d-flex">
    <div className={cx(styles["status-dot"], styles["status-dot--total"])}>
      {label} {value}
    </div>{" "}
    <div className={styles.total}>/{limit}</div>
  </div>
);

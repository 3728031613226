import { CountryCode, countryCodes as countryCodeIconDict } from "CONSTANTS";
import styles from "./CountryFlag.module.css";

export const CountryFlag = ({ countryCode }: { countryCode: CountryCode }) => {
  let icon = countryCodeIconDict.find(
    countryCodeObject => countryCodeObject.value.toUpperCase() === countryCode.toUpperCase(),
  )?.icon;

  return (
    <div className="d-flex fw-700">
      <img className={styles.flag} alt="Ikona kraju" src={icon} />
      {countryCode.toUpperCase()}
    </div>
  );
};

import { createPaginatedKey } from "api/keys";

export const orderKeys = {
  itemsWithSingleQuantities: createPaginatedKey("itemsWithSingleQuantities"),
  singleItemQuantities: createPaginatedKey("singleItemQuantities"),
  singleItemQuantitiesDetails: createPaginatedKey("singleItemQuantitiesDetails"),
  singleItemQuantitiesForRoute: createPaginatedKey("singleItemQuantitiesForRoute"),
  orderWarehouseLocations: createPaginatedKey("orderWarehouseLocations"),
  singleItemQuantityWarehouseLocations: createPaginatedKey("singleItemQuantityWarehouseLocations"),
};

import { Picking, statusDict } from "api/wms/models";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { isUnloadingLate } from "pages/wms/unloadingsList/utils/isUnloadingLate";

interface Props {
  picking: Picking;
}

export const PickingStatus = ({ picking }: Props) => {
  if (picking.estimatedTimeOfLoading && isUnloadingLate(picking.estimatedTimeOfLoading))
    return <Tag startIcon={MdiPoint} label="Opóźniony" variant="danger" />;
  return (
    <Tag
      startIcon={MdiPoint}
      label={statusDict[picking.status].label}
      variant={statusDict[picking.status].variant}
    />
  );
};

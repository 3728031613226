import { ProductEntity } from "api/products/models";
import { assertIsDefined } from "utilities/assertIsDefined";
import { AttributesState, Values } from "../ProductForm";
import { getAttributesState } from "./getAttributesState";

export function generateCommonAttributesState(
  productEntity: ProductEntity,
  productElements: Values["productElements"],
): AttributesState {
  const rawCommonAttributesState = productEntity.products
    .map(el => getAttributesState(el.attributes, el.indexes))
    .flat();

  const allProductEntityAttributes = productElements.flatMap(productElement => {
    const possibleValues = (() => {
      const product = productEntity.products.find(el => el.id === productElement.id);
      assertIsDefined(product);
      const indexes = Object.keys(product.indexes || {});

      const values: string[][] = [];
      indexes.forEach(index => {
        const addedIndexes = productElement.attributesState.map(el => el.valueId).filter(Boolean);
        const indexArray = index.split("-");

        if (addedIndexes.every(el => indexArray.includes(String(el)))) {
          values.push(indexArray);
        }
      });

      return [...new Set(values.flat())].map(Number);
    })();

    return possibleValues.length !== 0 ? productElement.attributesState : [];
  });

  const commonAttributesState = rawCommonAttributesState.map(attribute => {
    const attributes = allProductEntityAttributes.filter(
      el => el.attributeId === attribute.attributeId,
    );

    if (!attributes[0]) return attribute;
    const valueId = attributes[0].valueId;
    const areAllAttributesHaveTheSameValueId = attributes.every(el => el.valueId === valueId);
    if (areAllAttributesHaveTheSameValueId) {
      return { ...attribute, valueId };
    }
    return attribute;
  });

  return commonAttributesState;
}

import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import chairIcon from "assets/images/chairAlt.svg";
import { Unloadings } from "routes/wms/Unloadings";

export const indexes: ModuleLink = {
  url: "indexes",
  label: "Indeksy",
  icon: chairIcon,
  subSection: <></>,
  routing: Unloadings,
  isAccessible: false,
};

import { useState, useEffect } from "react";
import { ApiMiddlewareResult } from "apiConnectors/fetchConnector";

interface Props {
  name: string;
  submitFunc: (data: { [x: string]: boolean }) => ApiMiddlewareResult<any>;
  initialValue?: any;
  label?: string;
  children: (arg: {
    fetching: boolean;
    error: JSX.Element;
    submit: (data: any) => void;
    change: (data: any) => void;
    value: any;
    errorText?: string;
  }) => any;
}

/**
 *
 * @example
 * <EditableHandler
      name="minimumDeliveryTime"
      initialValue={1}
      submitFunc={data => patchDelivery(data, result.delivery.id)}
    >
      {({ fetching, error, submit, change, value }) => (...)}
    </EditableHandler>
 */
export function EditableHandler({ name, label = "", submitFunc, initialValue, children }: Props) {
  const [fetching, setFetching] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [lastSavedValue, setLastSavedValue] = useState(initialValue);

  const [error, setError] = useState<null | { [key: string]: any }>(null);

  useEffect(() => {
    if (fetching === true) {
      setError(null);
    }
  }, [fetching]);

  const handleSubmit = async (value: any) => {
    setValue(value);
    setFetching(true);
    const [, error] = await submitFunc(value);
    if (error) {
      setError(error);
      setValue(lastSavedValue);
    } else {
      setLastSavedValue(value);
    }
    setFetching(false);
  };
  const err = (
    <span className="invalid-feedback" style={{ display: error ? "block" : "none" }}>
      {error && (error[name] || "Nie udało się zapisać")}
    </span>
  );
  return children({
    fetching,
    submit: handleSubmit,
    change: setValue,
    value,
    error: err,
    errorText: error && error[name],
  });
}

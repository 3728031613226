import { UserWithInitials } from "api/users/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  collaborators: UserWithInitials[];
}

export const ListAvatar = ({ collaborators }: Props) => {
  if (Boolean(collaborators.length)) {
    return (
      <div className="d-flex align-items-center gap-1">
        <Avatar size="xs" theme="light" user={collaborators[0]} />
        {collaborators.length - 1 > 0 && (
          <Typography color="deepPurple400" fontSize="12" fontWeight="600">
            +{collaborators.length - 1}
          </Typography>
        )}
      </div>
    );
  }
  return <Avatar size="xs" theme="light" user={null} />;
};

import { useLastPickedOrders } from "api/wms/hooks";
import styles from "../../Dashboard.module.css";
import { LastPickedOrder } from "api/wms/models";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useSearch } from "../../shared/useSearch";
import { useLastPickedOrdersColumns } from "./useLastPickedOrdersColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";

export const LastPickedOrdersList = () => {
  const search = useSearch();
  const { data: lastPickedOrders, error, isPreviousData, isLoading } = useLastPickedOrders(search);
  const columns = useLastPickedOrdersColumns();

  return (
    <div className={styles.card}>
      <div>
        <div className="d-flex align-items-center justify-content-between gap-1 px-3 py-3">
          <Typography className={styles.sectionTitle} fontSize="16" fontWeight="700">
            Ostatnio zebrane zamówienia
          </Typography>
        </div>
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<LastPickedOrder>
            rows={lastPickedOrders}
            columns={columns}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={comfortableListUiSchema}
          />
        </div>
      </div>
    </div>
  );
};

import { RouteSynchronize } from "api/routes/models";
import { Link } from "components/miloDesignSystem/atoms/link";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useSynchronizeRouteColumns = () => {
  return useCreateTableColumns<RouteSynchronize>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "sygnatura",
        size: 130,
        cell: info => {
          const order: RouteSynchronize = info.getValue();
          return (
            <Link fontSize="14" fontWeight="700" to={`/orders/list/active/all?panelId=${order.id}`}>
              {order.signature}
            </Link>
          );
        },
      }),
      columnHelper.text(row => row.externalId, {
        header: "nr zewnętrzny",
        size: 150,
      }),
    ];
  });
};

import { LoadingPackageStatus, NormalizedLoadingPackageGroup } from "api/wms/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { loadingPackageStatusDict } from "./LineItemsDetailsModal";
import { dateFns } from "utilities";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { parseLocation } from "utilities/parseLocation";

export const useLineItemsDetailsColumns = () =>
  useCreateTableColumns<NormalizedLoadingPackageGroup>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row.name, {
        header: "nazwa paczki",
        size: 330,
        cell: info => {
          const name = info.getValue();
          const isNested = info.row.depth;
          return (
            <Typography
              color={isNested ? "neutralBlack88" : "neutralBlack48"}
              fontSize="12"
              fontWeight="700"
              noWrap
            >
              {name || "---"}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.location, {
        header: "lokalizacja",
        size: 100,
        cell: info => {
          const isNested = info.row.depth;
          if (!isNested) return null;
          return (
            <Typography fontSize="14" fontWeight="700" noWrap>
              {parseLocation(info.getValue()).name || "---"}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.status, {
        header: "status",
        size: 120,
        cell: info => {
          const status = info.getValue();
          const isNested = info.row.depth;
          if (!isNested) return null;
          if (!status) return <EmptyValue />;
          return (
            <Tag
              label={loadingPackageStatusDict[status].label}
              variant={loadingPackageStatusDict[status].variant}
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "when",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            kiedy?
          </Typography>
        ),
        size: 110,
        cell: info => {
          const status = info.getValue().status;
          const pickedUpAt = info.getValue().pickedUpAt;
          const loadedAt = info.getValue().loadedAt;
          const isNested = info.row.depth;
          if (!isNested) return null;
          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {(status === LoadingPackageStatus.DURING_LOADING ||
                status === LoadingPackageStatus.PICKED_UP) &&
              pickedUpAt
                ? dateFns.formatRelative(new Date(pickedUpAt), "dd.MM.yyyy, H:mm")
                : status === LoadingPackageStatus.LOADED && loadedAt
                ? dateFns.formatRelative(new Date(loadedAt), "dd.MM.yyyy, H:mm")
                : "---"}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.collaborator, {
        header: "kto?",
        size: 26,
        cell: info => {
          const isNested = info.row.depth;
          const collaborator = info.getValue();
          if (!isNested) return null;
          return <Avatar size="sm" theme="light" user={collaborator} />;
        },
      }),
    ];
  });

import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";

export const wmsLayoutKeys = {
  getRouteSearchCollection: (id: number) => ["routeSearchCollection", id],
  getSearchCollections: createPaginatedKey("searchCollections"),
  getPackagesInLocation: createPaginatedKey("packagesInLocation"),
  getLayoutSchema: (id: number) => ["layoutSchema", id],
  getRampStatus: (id: number) => ["rampStatus", id],
  getFillingStatus: (id: number) => ["fillingStatus", id],
  getLayoutField: (hallId: number, nodeId: UUID) => ["layoutField", hallId, nodeId],
  getRouteLayoutCollection: (hallId: number, routeId: number) => [
    "routeCollection",
    hallId,
    routeId,
  ],
};

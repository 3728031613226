import { EmailsSchedule } from "api/external-manufacturing/models";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { EmailScheduleDate } from "./EmailScheduleDate";

export const useEmailsScheduleCardColumns = () => {
  return useCreateTableColumns<EmailsSchedule>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.manufacturer.name, {
        header: "producent",
        size: 120,
        typographyProps: {
          color: "neutralWhite100",
          fontSize: "14",
        },
      }),
      columnHelper.text(row => row.quantity, {
        header: "szt.",
        size: 60,
        typographyProps: {
          color: "neutralWhite100",
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.date, {
        header: "wysyłka e-maila z zapytaniem za:",
        size: 200,
        cell: info => {
          const date: string | null = info.getValue();
          if (!date) return <EmptyValue />;
          return <EmailScheduleDate date={date} />;
        },
      }),
    ];
  });
};

import { pluralize } from "utilities";

interface Props {
  paymentDeadlineDelta: number;
}

export const PaymentDeadlineDeltaLabel = ({ paymentDeadlineDelta }: Props) => {
  if (paymentDeadlineDelta < 0) {
    const positiveDeadlineDelta = Math.abs(paymentDeadlineDelta);

    return (
      <div className="text-red-6">
        zaległe od {positiveDeadlineDelta}{" "}
        {pluralize.pl(positiveDeadlineDelta, {
          singular: "dnia",
          plural: "dni",
          other: "dni",
        })}
      </div>
    );
  }

  if (paymentDeadlineDelta === 0) {
    return <div className="text-red-6">dzisiaj</div>;
  }

  if (paymentDeadlineDelta > 0 && paymentDeadlineDelta <= 2) {
    return (
      <div className="text-red-6">
        zaległe za {paymentDeadlineDelta}{" "}
        {pluralize.pl(paymentDeadlineDelta, {
          singular: "dzień",
          plural: "dni",
          other: "dni",
        })}
      </div>
    );
  }

  if (paymentDeadlineDelta > 2 && paymentDeadlineDelta <= 5) {
    return <div className="text-orange-4">należne za {paymentDeadlineDelta} dni</div>;
  }

  return <div>należne za {paymentDeadlineDelta} dni</div>;
};

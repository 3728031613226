import { UnloadingLineItem, UnloadingLineItemType, UnloadingStatus } from "api/wms/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import styles from "../../RightPanel.module.css";
import { cx, dateFns } from "utilities";
import { ListAvatar } from "pages/wms/shared/components/ListAvatar";
import { useDownloadUnloadingLineItemPdf } from "../../hooks/useDownloadUnloadingLineItemPdf";
import { MdiCancel } from "components/miloDesignSystem/atoms/icons/MdiCancel";
import { MdiRestartAlt } from "components/miloDesignSystem/atoms/icons/MdiRestartAlt";
import { useCancellUnloadingItem } from "api/wms/hooks";
import { MdiChair } from "components/miloDesignSystem/atoms/icons/MdiChair";
import { MdiShoppingCart } from "components/miloDesignSystem/atoms/icons/MdiShoppingCart";
import { MdiPackage2 } from "components/miloDesignSystem/atoms/icons/MdiPackage2";
import { UserWithInitials } from "api/users/models";
import { ISODateTime } from "api/types";

export const useLineItemsColumns = () => {
  const handleDownloadUnloadingLineItemPdf = useDownloadUnloadingLineItemPdf();
  const cancelItemMutation = useCancellUnloadingItem();

  return useCreateTableColumns<UnloadingLineItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "zamów./mebel",
        size: 105,
        cell: info => {
          const lineItem: UnloadingLineItem = info.getValue();
          return (
            <Typography
              className={cx({
                [styles.cancelledSignature]: lineItem.isCancelled,
              })}
              fontSize="12"
              fontWeight="700"
              noWrap
            >
              {lineItem.signature}
            </Typography>
          );
        },
      }),
      columnHelper.text(row => row.sourceExternalId, {
        header: "nr zewn.",
        size: 65,
      }),
      columnHelper.accessor(row => row.type, {
        header: "typ",
        size: 18,
        cell: info => {
          const type: UnloadingLineItemType = info.getValue();
          if (type === UnloadingLineItemType.INDEX) return <MdiChair color="cyan300" size="18" />;
          if (type === UnloadingLineItemType.ORDER)
            return <MdiShoppingCart color="magenta300" size="18" />;
          return <MdiPackage2 color="purple300" size="18" />;
        },
      }),
      columnHelper.accessor(row => row.status, {
        header: "",
        id: "status",
        size: 50,
        cell: info => {
          const status: UnloadingStatus = info.getValue();
          if (status === UnloadingStatus.FINISHED) {
            return <Tag startIcon={MdiCheck} label="OK" variant="success" />;
          }
          if (status === UnloadingStatus.IN_PROGRESS) {
            return <Tag label="trwa" variant="warning" />;
          }
          return null;
        },
      }),
      columnHelper.accessor(row => row.suppliers, {
        header: "kontrahent",
        size: 85,
        cell: info => {
          const suppliers: UnloadingLineItem["suppliers"] = info.getValue();
          if (suppliers && Array.isArray(suppliers) && Boolean(suppliers.length)) {
            return (
              <div className="d-flex align-items-center gap-1">
                <Typography fontSize="12" fontWeight="700" noWrap>
                  {suppliers[0].name}
                </Typography>
                {suppliers.length - 1 > 0 && (
                  <Typography color="deepPurple400" fontSize="12" fontWeight="600">
                    +{suppliers.length - 1}
                  </Typography>
                )}
              </div>
            );
          }
          if (suppliers && !Array.isArray(suppliers))
            return (
              <Typography fontSize="12" fontWeight="700" noWrap>
                {suppliers.name}
              </Typography>
            );
          return <EmptyValue />;
        },
      }),
      columnHelper.accessor(row => row.numberOfPackageGroups, {
        header: "towar",
        size: 40,
        cell: info => {
          const numberOfPackageGroups: UnloadingLineItem["numberOfPackageGroups"] = info.getValue();
          return (
            <div className="d-flex align-items-center">
              <Typography color="success500" fontSize="14" fontWeight="700">
                {numberOfPackageGroups.inStock}
              </Typography>
              <Typography color="neutralBlack48" fontSize="14" fontWeight="700">
                /{numberOfPackageGroups.total}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.inStockAt, {
        header: "rozładowano",
        size: 85,
        cell: info => {
          const date: ISODateTime | null = info.getValue();
          if (!date) return <EmptyValue />;
          return (
            <Typography fontSize="12" fontWeight="600">
              {dateFns.formatRelative(new Date(date))}
            </Typography>
          );
        },
      }),
      columnHelper.text(
        row => {
          if (Boolean(row.locations)) return row.locations.join(", ");
          return null;
        },
        {
          header: "lokalizacja",
          size: 75,
        },
      ),
      columnHelper.accessor(row => row.collaborators, {
        header: "kto?",
        size: 45,
        cell: info => {
          const collaborators: UserWithInitials[] = info.getValue();
          return <ListAvatar collaborators={collaborators} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "",
        id: "labels",
        size: 56,
        cell: info => {
          const unloadingLineItem = info.getValue() as UnloadingLineItem;
          return (
            <div className="d-flex align-items-center gap-1">
              <IconButton
                icon={<MdiQrCode size="16" />}
                onClick={event => {
                  event.stopPropagation();
                  handleDownloadUnloadingLineItemPdf(
                    unloadingLineItem.id,
                    unloadingLineItem.unloading,
                    unloadingLineItem.signature,
                  );
                }}
                theme="dark"
                variant="transparent"
              />
              {unloadingLineItem.isCancelled ? (
                <IconButton
                  icon={<MdiRestartAlt size="16" />}
                  onClick={event => event.stopPropagation()}
                  theme="dark"
                  variant="transparent"
                />
              ) : (
                <IconButton
                  disabled={cancelItemMutation.isLoading}
                  icon={<MdiCancel size="16" />}
                  onClick={event => {
                    event.stopPropagation();
                    cancelItemMutation.mutate({
                      ids: [unloadingLineItem.id],
                      kind: "UNLOADING_LINE_ITEMS",
                      unloadingId: unloadingLineItem.unloading,
                      isCancelled: true,
                      status: UnloadingStatus.CANCELLED,
                    });
                  }}
                  theme="dark"
                  variant="transparent"
                />
              )}
            </div>
          );
        },
      }),
    ];
  });
};

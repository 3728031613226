import { GOOGLE_MAPS_STATIC_KEY } from "CONSTANTS";
import { RouteComponentProps } from "react-router-dom";
import { useDidMount, useSelector, useToastr } from "hooks";
import { postRoute } from "api/routes/calls";
import { dateFns, getAnyErrorKey } from "utilities";
import addDays from "date-fns/addDays";
import { useMutation } from "hooks/useMutation";
import { useQueryClient } from "react-query";
import { routeKeys } from "api/keys";
import { MockupLoader } from "components/utils";

const miniMapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=51.828353,19.362166&zoom=4&size=320x160&key=${GOOGLE_MAPS_STATIC_KEY}`;

/**
 * * This component is responsible only for creating new route and redirecting to creator view.
 */

export const CreateRoute = ({ history }: RouteComponentProps) => {
  const queryClient = useQueryClient();
  const toastr = useToastr();

  const defaultLoadingTime = useSelector(state => state.partials.configuration?.defaultLoadTime);
  const defaultStartingPoint = useSelector(state =>
    state.partials.startingPoints.find(el => el.isDefault),
  );

  const tomorrowDate = dateFns.format(addDays(new Date(), 1), "yyyy-MM-dd");

  const createMutation = useMutation(
    (data: {
      mini_map: string;
      isDraft: true;
      loadingTime: string | null;
      loadingDate: string;
      startingPoint: number;
      averageSpeed: null;
    }) => postRoute(data),
    {
      onSuccess: payload => {
        queryClient.setQueryData(routeKeys.route(String(payload.id)), payload);
        history.replace("/routes/creator/" + payload.id);
      },
      onError: error => {
        history.replace("/routes");
        toastr.open({
          type: "failure",
          title: "Nie udało się utworzyć trasy",
          text: getAnyErrorKey(error),
        });
      },
    },
  );

  useDidMount(() => {
    if (!defaultStartingPoint) {
      history.goBack();
      toastr.open({
        type: "failure",
        title: "Nie udało się utworzyć trasy",
        text: "Brak punktów startowych",
      });
      return;
    }
    createMutation.mutate({
      mini_map: miniMapUrl,
      isDraft: true,
      loadingTime: defaultLoadingTime,
      loadingDate: tomorrowDate,
      startingPoint: defaultStartingPoint?.id,
      averageSpeed: null,
    });
  });

  if (createMutation.isLoading) {
    return <MockupLoader type="map" />;
  }

  return null;
};

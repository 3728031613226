import { Button, PageHeader } from "components/common";
import downloadFileIcon from "assets/images/downloadFile.svg";
import uploadFileIcon from "assets/images/uploadFile.svg";
import visibilityOffIcon from "assets/images/eye_off.svg";
import areaChartIcon from "assets/images/areaChart.svg";
import checkIcon from "assets/images/check_small.svg";
import { getMyImportTabs } from "pages/tradingDocuments/shared/utils/getTabs";
import { myInvoicesToConfirm } from "components/common/moduleNavigation/moduleConfig/finances/routes/myInvoicesToConfirm";
import { useHistory } from "react-router-dom";

export const ListHeader = () => {
  const history = useHistory();

  return (
    <div>
      <PageHeader
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Pobierz plik"
                  src={downloadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Importuj plik"
                  src={uploadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <span className="line-divider" />
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Wyłącz widoczność"
                  src={visibilityOffIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img alt="Wykres" src={areaChartIcon} style={{ height: "16px", width: "16px" }} />
              </div>
            </Button>
          </div>
        }
        bottomBarButtons={
          <Button
            kind="create"
            title="Zatwierdzanie wielu faktur"
            onClick={e => {
              e.stopPropagation();
              history.push(`/finances/import-invoices/check`, {
                fromMyInvoices: `${history.location.pathname}${history.location.search}`,
              });
            }}
          >
            <div className="btnBase btnBaseSmall">
              <img alt="Gotowe" src={checkIcon} style={{ height: "16px", width: "16px" }} />
              Żądanie zatwierdzenia
            </div>
          </Button>
        }
        searchInput={{
          label: "Szukaj wśród swoich faktur do zatwierdzenia",
          tags: [
            {
              name: "issueDateFrom",
              label: "data wystawienia (od)",
              value: "2023-08-01",
            },
            {
              name: "issueDateTo",
              label: "data wystawienia (do)",
              value: "2023-08-04",
            },
          ],
        }}
        tabs={{
          list: getMyImportTabs(),
          routesRoot: `finances/${myInvoicesToConfirm.url}`,
          urlSpan: "list",
        }}
        viewLabel="MY_IMPORT_PURCHASE_INVOICES_LIST"
      />
    </div>
  );
};

import { productionPlansKeys } from "api/new-production-plans/keys";
import { ProductionPlan, ShelfManufacturingItem } from "api/new-production-plans/models";
import { Pagination, UUID } from "api/types";
import { QueryClient } from "react-query";
import { assertIsDefined } from "utilities/assertIsDefined";

export const handleManufacturingWorkingUnitAssignmentRollback = (
  productionPlanSchedule: ProductionPlan,
  sourceShelfGroupList: Pagination<ShelfManufacturingItem>,
  productionPlanId: UUID,
  shelfGroupKeyString: string,
  queryClient: QueryClient,
) => {
  // rollback production plan schedule
  queryClient.setQueryData<ProductionPlan>(
    productionPlansKeys.productionPlanSchedule(productionPlanId),
    currentPlan => {
      assertIsDefined(currentPlan);
      return productionPlanSchedule;
    },
  );

  // rollback source shelf group units
  queryClient.setQueryData<Pagination<ShelfManufacturingItem>>(
    productionPlansKeys.shelfItemDetails(shelfGroupKeyString),
    currentList => {
      assertIsDefined(currentList);
      return {
        ...currentList,
        results: sourceShelfGroupList.results,
      };
    },
  );
};

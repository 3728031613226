import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateFns } from "utilities";

interface Props {
  date: string | null;
}

export const EstimatedTime = ({ date }: Props) => {
  return (
    <Typography fontSize="12" fontWeight="700">
      {date ? dateFns.formatRelative(new Date(date), "dd.MM.yyyy, H:mm") : "---"}
    </Typography>
  );
};

import { queryString } from "utilities";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  ordering: string;
};

export const getSearch = ({ query, ordering }: GetSearchProps): string => {
  return queryString.stringify({
    ...query,
    ordering: ordering,
  });
};

import { createAction } from "@reduxjs/toolkit";
import { ToastSubscription } from "components/common";
import { createReduxReducer } from "utilities";

type ToastrEntity = {
  text: string | React.ReactElement;
  title: string;
  type: ToastSubscription["type"];
  id: string;
  timestamp: string;
};
type State = ToastrEntity[];

const add = createAction<Omit<ToastrEntity, "timestamp">>("toasters/add");

export const initialState: State = [];

export const actions = {
  add,
};

export const reducer = createReduxReducer(initialState, {
  [add.type]: (state, action: ReturnType<typeof add>) => {
    state.unshift({ ...action.payload, timestamp: new Date().toISOString() });
    state.splice(10, state.length - 10);
  },
});

import { Button } from "components/common";
import { HeaderUserDropdown } from "components/common/headerUserDropdown/HeaderUserDropdown";
import styles from "./HelpdeskHeader.module.css";
import plusIcon from "assets/images/20w.svg";
import logoIcon from "assets/images/logo1w.svg";

export const HelpdeskHeader = () => {
  return (
    <div>
      <div className={styles.container}>
        <div className="d-flex justify-content-between align-items-center h-100">
          <div className="d-flex align-items-center">
            <img src={logoIcon} alt="" className="mr-4" />
            <span className="fs-26 text-color-white">Centrum Pomocy</span>
          </div>
          <div className="d-flex align-items-center">
            <Button kind="primary" className="mr-3">
              <img src={plusIcon} alt="" />
              Nowe zgłoszenie
            </Button>
            <HeaderUserDropdown />
          </div>
        </div>
      </div>
    </div>
  );
};

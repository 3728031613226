import styles from "../../Dashboard.module.css";
import { EmptySalesSection } from "./components/EmptySalesSection";

export const SalesSection = () => {
  return (
    <div className={styles.card}>
      <EmptySalesSection />
    </div>
  );
};

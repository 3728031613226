import { patchDeliveryQuery } from "api/deliveries/calls";
import { ordersKeys } from "api/keys";
import { OrderForRoute } from "api/orders/models";
import { routesKeys } from "api/routes/keys";
import { useMutation } from "hooks/useMutation";
import { PartialOf } from "typeUtilities";
import { getAnyErrorKey } from "utilities";
import { parsePatchData } from "utilities/parsePatchData";

export const usePatchDeliveryMutation = () => {
  return useMutation(
    ({
      id,
      toUpdate,
    }: {
      id: string | number;
      toUpdate: PartialOf<OrderForRoute["delivery"]>;
      routeId: number;
    }) => {
      return patchDeliveryQuery(id, parsePatchData(toUpdate));
    },
    ({ queryClient, toastr }) => ({
      onSuccess: (_, args) => {
        queryClient.invalidateQueries(ordersKeys.orderForRoute(String(args.id)));
        queryClient.invalidateQueries(routesKeys.route(String(args.routeId)));
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

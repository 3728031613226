import { RouteEmails } from "api/routes/models";
import { Label } from "components/utils";
import { dateFns } from "utilities";
import styles from "../PointsSection.module.css";

interface Props {
  order: RouteEmails["orders"][number];
}

export const ConfirmedBy = ({ order }: Props) => {
  if (order.deliveryDateConfirmedBy) {
    return (
      <div>
        <div className="d-flex align-items-center mb-1">
          <Label color="primary" className="mr-2">
            Milo
          </Label>
          <div className={styles.date}>
            {order.deliveryDateConfirmTimestamp &&
              dateFns.format(new Date(order.deliveryDateConfirmTimestamp), "dd.MM.yyyy HH:mm")}
          </div>
        </div>
        <div className={styles.name}>
          {order.deliveryDateConfirmedBy.firstName} {order.deliveryDateConfirmedBy.lastName}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex align-items-center">
        <Label color="blue" className="mr-2">
          Klient
        </Label>
        <div className={styles.date}>
          {order.deliveryDateConfirmTimestamp &&
            dateFns.format(new Date(order.deliveryDateConfirmTimestamp), "dd.MM.yyyy HH:mm")}
        </div>
      </div>
    </div>
  );
};

import { BankTransactionDetails } from "api/bank-accounts/models";
import cx from "classnames";
import styles from "./OrdersWithoutDocuments.module.css";

interface Props {
  summaryByMonth: BankTransactionDetails["summaryByMonth"];
}

export const OrdersWithoutDocuments = ({ summaryByMonth }: Props) => {
  const ordersWithoutDocuments = summaryByMonth.filter(el => el.month === null);
  const hasOrders = ordersWithoutDocuments.length > 0;

  const largestColumnSize = (() => {
    const sorted = [...ordersWithoutDocuments].sort((a, b) =>
      a.orders.length > b.orders.length ? -1 : 1,
    );
    return sorted[0]?.orders.length ?? 0;
  })();

  const orderAmount = (amount: number, currency: string) => {
    const [number, decimal] = amount.toFixed(2).split(".");
    return (
      <>
        <span className="text-color-darkgrey">{number}</span>
        <span className="fs-12 text-color-grey">
          .{decimal} {currency}
        </span>
      </>
    );
  };

  return (
    <div className="p-3 mt-3">
      <div>
        <h5>Zamówienia bez dokumentu</h5>
      </div>
      <div
        className={styles.grid}
        style={{
          gridTemplateColumns: `auto repeat(${ordersWithoutDocuments.length}, 1fr)`,
        }}
      >
        <div>
          <div className={cx(styles.cell, styles.bl, styles.br, styles.bb)}>
            Zamówienia
            {hasOrders &&
              largestColumnSize > 0 &&
              Array(largestColumnSize - 1)
                .fill(null)
                .map((_, index) => (
                  <div className={styles.cell} key={index}>
                    &nbsp;
                  </div>
                ))}
          </div>
          <div className={cx(styles.cell, styles.bl, styles.br, styles.bb)}>Razem</div>
        </div>
        {ordersWithoutDocuments.map(summary => {
          return (
            <div key={summary.month}>
              {summary.orders.length > 0 &&
                summary.orders.map((order, index) => (
                  <div className={cx(styles.cell, styles.br, "nowrap")} key={order.signature}>
                    <span>
                      {index + 1}. {order.signature}
                    </span>{" "}
                    <div className={styles.separator} />
                    suma: &nbsp;{orderAmount(order.amount, order.currency)}
                  </div>
                ))}
              {summaryByMonth.filter(el => el.month !== null).length > 0 && !summary.orders.length && (
                <div className={cx(styles.cell, styles.br, "nowrap")}>
                  <h3>Wszystkie zamówienia posiadają dokument</h3>
                </div>
              )}
              {largestColumnSize > 0 &&
                Array(largestColumnSize - summary.orders.length)
                  .fill(null)
                  .map((_, index) => (
                    <div className={cx(styles.cell, styles.br)} key={index}>
                      &nbsp;
                    </div>
                  ))}
              {summary.summary ? (
                <div className={cx(styles.cell, styles.bt, styles.br, styles.bb)}>
                  {summary.summary}zł
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

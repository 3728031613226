import { useCompareListingsQuery } from "api/orders/hooks";
import { CommonError, Modal, Spinner } from "components/utils";
import styles from "./CompareListingsModal.module.css";
import { Attributes } from "components/common/attributes/Attributes";
import { channels } from "CONSTANTS";

interface Props {
  modal: {
    isOpen: boolean;
    close: () => void;
  };
  orderId: number;
}

export const CompareListingsModal = ({ modal: { isOpen, close }, orderId }: Props) => {
  const { data: listings, isLoading: inProgress, error } = useCompareListingsQuery(orderId);
  const noListings =
    listings &&
    !listings.emptyListings.length &&
    !listings.filledListings.length &&
    !listings.itemsWithoutListings.length;
  const hasOnlyItemsWithoutListings =
    !listings?.filledListings.length &&
    !listings?.emptyListings.length &&
    listings?.itemsWithoutListings.length;
  const hasPreviousListAndItemsWithoutListings =
    (listings?.filledListings.length || listings?.emptyListings.length) &&
    listings?.itemsWithoutListings.length;

  return (
    <Modal isOpen={isOpen} close={close} size={{ all: { width: "90vw" } }}>
      <div>
        <h1 className="ml-0">Porównaj listingi</h1>
        <div>
          {error && <CommonError status={error.status} />}
          <Spinner
            on={inProgress}
            color="blue"
            size="big"
            text="trwa wczytywanie"
            className={styles.spinner}
          />
          <div className={`container ${styles.mainContainer}`}>
            <div className="row">
              <div className={`col ${styles.leftList}`}>
                <div className="row mb-1">
                  <h3 className={`mb-0 mr-2 ${styles.spanInfo}`}>Listing</h3>
                  {listings && (
                    <h3
                      style={{
                        color: channels[listings?.salesAccount.channel]?.color ?? "black",
                      }}
                      className="mb-0"
                    >
                      {channels[listings.salesAccount.channel]?.name ? (
                        channels[listings.salesAccount.channel].name
                      ) : (
                        <span className="text-capitalize">{listings.salesAccount.channel}</span>
                      )}
                    </h3>
                  )}
                </div>
                <div className={`row ${styles.titleRow}`}>
                  <div className="col-4 pl-0">
                    <span className={`fs-12 ${styles.label}`}>Nazwa | cechy</span>
                  </div>
                  <div className="col-3">
                    <span className={`fs-12 ${styles.label}`}>Numer</span>
                  </div>
                  <div className="col-2 pl-0">
                    <span className={`fs-12 ${styles.label}`}>EAN</span>
                  </div>
                  <div className="col-1">
                    <span className={`fs-12 ${styles.label}`}>Liczba</span>
                  </div>
                  <div className="col-2">
                    <span className={`fs-12 ${styles.label}`}>Kwota</span>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="row pl-2 mb-1">
                  <h3 className={`mb-0 ${styles.spanInfo}`}>Podpięte produkty</h3>
                </div>
                <div className={`row pl-2 ${styles.titleRow}`}>
                  <div className="col-4 pl-0">
                    <span className={`fs-12 ${styles.label}`}>Nazwa | cechy</span>
                  </div>
                  <div className="col-3">
                    <span className={`fs-12 ${styles.label}`}>Numer</span>
                  </div>
                  <div className="col-2 pl-0">
                    <span className={`fs-12 ${styles.label}`}>EAN</span>
                  </div>
                  <div className="col-1">
                    <span className={`fs-12 ${styles.label}`}>Liczba</span>
                  </div>
                  <div className="col-2">
                    <span className={`fs-12 ${styles.label}`}>Kwota</span>
                  </div>
                </div>
              </div>
            </div>
            {noListings && (
              <div className="row d-flex align-items-center justify-content-center pt-4">
                <span className={styles.spanInfo}>Brak listingów</span>
              </div>
            )}
            {listings?.filledListings.map(filledListing => (
              <div className={`row ${styles.titleRow}`}>
                <div className={`col-6 ${styles.leftList}`}>
                  <div className="row d-flex align-items-center justify-content-center pt-2 pb-2">
                    <div className="col-4">
                      <div className={`row ${styles.productNameFontSize}`}>
                        {filledListing.listing.name}
                      </div>
                      {filledListing.listing.attributes.length ? (
                        <div className="row">
                          <Attributes
                            attributes={formatAttributes(filledListing.listing.attributes)}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className={`col-3 ${styles.adjustedFontSize} ${styles.wrapText}`}>
                      {filledListing.listing.externalId ? (
                        <span className={styles.externalId}>
                          {filledListing.listing.externalId}
                        </span>
                      ) : null}
                    </div>
                    <div className={`col-2 pl-0 ${styles.adjustedFontSize}`}>
                      {filledListing.listing.ean ? (
                        <span className={styles.externalId}>{filledListing.listing.ean}</span>
                      ) : (
                        <span className={`${styles.spanInfo} ${styles.externalId}`}>brak</span>
                      )}
                    </div>
                    <div className={`col-1 ${styles.adjustedFontSize}`}>
                      x{filledListing.listing.quantity}
                    </div>
                    <div className={`col-2 ${styles.adjustedFontSize}`}>
                      <div>
                        {filledListing.listing.amount}
                        <span className={styles.label}> {filledListing.listing.currency}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  {filledListing.items.map(item => (
                    <div className="row d-flex align-items-center justify-content-center pt-2 pb-2 pl-2">
                      <div className="col-4">
                        <div className={`row ${styles.productNameFontSize}`}>{item.name}</div>
                        {item.attributes.length ? (
                          <div className={`row ${styles.wrapAttributes}`}>
                            <Attributes attributes={formatAttributes(item.attributes)} />
                          </div>
                        ) : null}
                      </div>
                      <div className={`col-3 ${styles.adjustedFontSize}`}>
                        <span className={styles.externalId}>
                          {item.externalId ? (
                            <span>{item.externalId}</span>
                          ) : (
                            <span className={styles.spanInfo}> brak</span>
                          )}
                        </span>
                      </div>
                      <div className={`col-2 pl-0 ${styles.adjustedFontSize}`}>
                        <span className={styles.externalId}>
                          {item.ean ? (
                            <span className={styles.externalId}>{item.ean}</span>
                          ) : (
                            <span className={`${styles.spanInfo} ${styles.externalId}`}>brak</span>
                          )}
                        </span>
                      </div>
                      <div className={`col-1 ${styles.adjustedFontSize}`}>x{item.quantity}</div>
                      <div className={`col-2 ${styles.adjustedFontSize}`}>
                        {item.amount}
                        <span className={styles.label}> {item.currency}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {!listings?.filledListings.length && listings?.emptyListings.length ? (
              <div className="row" />
            ) : null}
            {listings?.filledListings.length && listings?.emptyListings.length ? (
              <div className={`row mt-3 ${styles.listBorderTop}`} />
            ) : null}
            {listings?.emptyListings.map(emptyListing => (
              <div className={`row ${styles.titleRow}`}>
                <div className={`col-6 ${styles.leftList}`}>
                  <div className="row d-flex align-items-center justify-content-center pt-2 pb-2">
                    <div className="col-4">
                      <div className={`row ${styles.productNameFontSize}`}>{emptyListing.name}</div>
                      {emptyListing.attributes.length ? (
                        <div className="row">
                          <Attributes attributes={formatAttributes(emptyListing.attributes)} />
                        </div>
                      ) : null}
                    </div>
                    <div className={`col-3 ${styles.adjustedFontSize} ${styles.wrapText}`}>
                      {emptyListing.externalId ? (
                        <span className={styles.externalId}>{emptyListing.externalId}</span>
                      ) : null}
                    </div>
                    <div className={`col-2 pl-0 ${styles.adjustedFontSize}`}>
                      {emptyListing.ean ? (
                        <span className={styles.externalId}>{emptyListing.ean}</span>
                      ) : (
                        <span className={`${styles.spanInfo} ${styles.externalId}`}>brak</span>
                      )}
                    </div>
                    <div className={`col-1 ${styles.adjustedFontSize}`}>
                      x{emptyListing.quantity}
                    </div>
                    <div className={`col-2 ${styles.adjustedFontSize}`}>
                      <div>
                        {emptyListing.amount}
                        <span className={styles.label}> {emptyListing.currency}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 d-flex align-items-center justify-content-center">
                  <span
                    className={`d-flex align-items-center justify-content-center ${styles.spanInfo}`}
                  >
                    Brak dopasowanego produktu
                  </span>
                </div>
              </div>
            ))}
            {hasOnlyItemsWithoutListings ? <div className="row" /> : null}
            {hasPreviousListAndItemsWithoutListings ? (
              <div className={`row mt-3 ${styles.listBorderTop}`} />
            ) : null}
            {listings?.itemsWithoutListings.map(itemWithoutListing => (
              <div key={itemWithoutListing.externalId} className={`row ${styles.titleRow}`}>
                <div
                  className={`col-6 d-flex align-items-center justify-content-center ${styles.leftList}`}
                >
                  <div className="row d-flex align-items-center justify-content-center">
                    <span className={styles.spanInfo}>Brak listingu</span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row d-flex align-items-center justify-content-center pt-2 pb-2 pl-2">
                    <div className="col-4">
                      <div className={`row ${styles.productNameFontSize}`}>
                        {itemWithoutListing.name}
                      </div>
                      {itemWithoutListing.attributes.length ? (
                        <div className="row">
                          <Attributes
                            attributes={formatAttributes(itemWithoutListing.attributes)}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className={`col-3 ${styles.adjustedFontSize}`}>
                      <span className={styles.externalId}>
                        {itemWithoutListing.externalId ? (
                          <span className={styles.externalId}>{itemWithoutListing.externalId}</span>
                        ) : (
                          <span className={`${styles.spanInfo} ${styles.externalId}`}> brak</span>
                        )}
                      </span>
                    </div>
                    <div className={`col-2 pl-0 ${styles.adjustedFontSize}`}>
                      <span className={styles.externalId}>
                        {itemWithoutListing.ean ? (
                          <span>{itemWithoutListing.ean}</span>
                        ) : (
                          <span className={styles.spanInfo}> brak</span>
                        )}
                      </span>
                    </div>
                    <div className={`col-1 ${styles.adjustedFontSize}`}>
                      x{itemWithoutListing.quantity}
                    </div>
                    <div className={`col-2 ${styles.adjustedFontSize}`}>
                      {itemWithoutListing.amount}
                      <span className={styles.label}> {itemWithoutListing.currency}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export function formatAttributes(
  attributes: string,
): { name: string; value: string; id: number | string }[] {
  const stringAttributes = attributes.split(";");
  return stringAttributes.map(el => {
    const [name, value] = el.split(":");
    return { name, value, id: name + value };
  });
}

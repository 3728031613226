import { GoogleMap, InfoWindow } from "@react-google-maps/api";
import { useMemo, useRef, useState } from "react";
import { useRouteViewState } from "../routeCreatorState";
import { PolygonController } from "./DragPolygon";
import { OrderPoint } from "api/orders/models";
import { MapMode } from "../Creator";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Marker as MapMarker } from "@react-google-maps/api";
import { getMarkerIcon } from "utilities/getMarkerIcon";
import { useInfoWindow } from "../hooks/useInfoWindow";
import { EmptyInfoWindowContent } from "./emptyInfoWindowContent";
import { useSelector } from "hooks";

interface Props {
  polygonController: PolygonController;
  mapMode: MapMode;
  routePoints: OrderPoint[];
}
const centerOfPolandCoords = { lat: 52.087669, lng: 19.410481 };

export const EmptyMap = ({ polygonController, mapMode, routePoints }: Props) => {
  const zoom = useRef(7);
  const actions = useRouteViewState("slave", store => store.actions);
  const editingPasspointId = useRouteViewState("slave", store => store.editingPasspointId);
  const [, setSpan] = useState({ south: 0, north: 0, west: 0, east: 0 });
  const spanLoaded = useRef(false);
  const map = useRef<google.maps.Map>();
  const { openedInfoWindowData, toggleInfoWindow, closeInfoWindow } = useInfoWindow();
  const centerPoints = useSelector(store => store.partials.centerPoints);
  const me = useSelector(store => store.auth.user!);
  function handleZoom() {
    if (!map.current) return;
    const zoomLevel = map.current.getZoom();
    zoom.current = zoomLevel;
    const mapSpan = map.current.getBounds();
    if (mapSpan) {
      setSpan(mapSpan.toJSON());
    }
  }

  const centerPoint = useMemo(() => {
    const userCenterPoint = centerPoints.find(point => point.user === me.id);
    if (userCenterPoint) return userCenterPoint.point;
    return centerOfPolandCoords;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onLoad(instance: google.maps.Map<Element>) {
    map.current = instance;
    google.maps.event.addListener(instance, "tilesloaded", () => {
      if (spanLoaded.current === false) {
        const mapSpan = instance.getBounds();
        if (mapSpan) {
          setSpan(mapSpan.toJSON());
        }
        spanLoaded.current = true;
      }
    });
  }

  const handleAddPoint = (e: google.maps.MouseEvent) => {
    assertIsDefined(editingPasspointId);
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        //@ts-ignore
        latLng: e.latLng,
      },
      function(results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            actions.setState({ editingPasspointId: null });
          }
        }
      },
    );
  };

  const onClick = (e: google.maps.MouseEvent) => {
    if (editingPasspointId) {
      return handleAddPoint(e);
    }
    if (mapMode === "polygon") {
      return polygonController.handleAddPoint(e);
    }
  };

  return (
    <GoogleMap
      onLoad={onLoad}
      onZoomChanged={handleZoom}
      mapContainerStyle={{
        height: "100vh",
      }}
      center={centerPoint}
      zoom={7}
      options={{
        disableDefaultUI: true,
      }}
      onClick={onClick}
    >
      {routePoints.map(location => {
        return (
          <MapMarker
            icon={{
              url: getMarkerIcon(location),
              anchor: { x: 9, y: 9 } as google.maps.Point,
            }}
            onClick={() => toggleInfoWindow(Number(location.id), location.point)}
            position={location.point}
            visible={true}
          />
        );
      })}

      {openedInfoWindowData && (
        <InfoWindow
          key={openedInfoWindowData.id}
          position={openedInfoWindowData.point}
          onCloseClick={closeInfoWindow}
        >
          <EmptyInfoWindowContent id={String(openedInfoWindowData.id)} />
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

/**
 * Moves focus caret at the end of text.
 * Use on input or textarea element.
 * @example
 * <input onFocus={moveCaretAtEnd} autoComplete />
 */
export function moveCaretAtEnd(e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) {
  var temp_value = e.target.value;
  e.target.value = "";
  e.target.value = temp_value;
}

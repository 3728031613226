import styles from "./Stepper.module.css";
import cx from "classnames";

export const Stepper = ({ children }: any) => {
  return <div className={styles.tabs}>{children}</div>;
};

type Status = "passed" | "active" | "disabled";
export const Step = ({
  label,
  status,
  onClick,
}: {
  label: string;
  onClick?: (status: Status) => void;
  status: Status;
}) => (
  <div
    onClick={() => {
      if (onClick) {
        onClick(status);
      }
    }}
    className={cx(styles.tab, {
      [styles.active]: status === "active",
      [styles.passed]: status === "passed",
    })}
  >
    {label}
  </div>
);

Stepper.Step = Step;

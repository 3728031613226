import styles from "../MessagesWithCustomer.module.css";
import cx from "classnames";
import { OrderMessagesSchema } from "api/orders/models";
import { dateFns } from "utilities";
import check_small from "assets/images/check_small.svg";
import { statusDict } from "./statusDict";
import errorIcon from "assets/images/877.svg";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";

type Message = OrderMessagesSchema["messages"][number];

interface Props {
  messagesData: Message[];
}

export const MessagesSent = ({ messagesData }: Props) => {
  const { timestamp, createdBy, status, failureReason } = messagesData[0];

  return (
    <>
      <div className="w-100 d-flex justify-content-center pb-3">
        <div className="fs-12 fw-400 text-black-6">
          {createdBy && <span>{statusDict[status]} </span>}
          {timestamp && dateFns.format(new Date(timestamp), "d MMM yyyy HH:mm")}
        </div>
      </div>
      <div className="d-flex flex-column align-items-end pl-5 pr-4 pb-4">
        <div className={styles.errorMsg}>
          <img src={errorIcon} alt="" />
          {failureReason}
        </div>

        <div className={cx(styles.miloClouds, "d-flex flex-column align-items-end")}>
          {messagesData.map(message => (
            <div className={cx(styles.cloud, styles.cloudMilo)}>
              {message.body}

              <div className="d-flex align-items-center justify-content-end w-100">
                {message.status === "SENT" && (
                  <span className={cx(styles.messageDeliveryStatus)}>
                    <img src={check_small} alt="" />
                  </span>
                )}
                {message.status === "DELIVERED" && (
                  <span className={cx(styles.messageDeliveryStatus, "ml-n3")}>
                    <img src={check_small} alt="" />
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex align-items-center gap-2 pt-2">
          <div className="fs-12 text-black-6">
            {createdBy?.firstName} {createdBy?.lastName}
          </div>
          <Avatar user={createdBy} size="sm" />
        </div>
      </div>
    </>
  );
};

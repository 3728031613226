import produce from "immer";
import { useQueryClient } from "react-query";
import { useInfoWindow } from "../creator/hooks/useInfoWindow";
import { Order } from "api/orders/models";
import { Route } from "api/routes/models";
import { RouteOrdersComments } from "api/routes/models";
import { routeKeys } from "api/keys";
import { postComment } from "api/orders/calls";
import { CommentFormHelpers } from "components/common";
import { useRightPanelDisplaySettings } from "hooks";
import { CommentVisibilityForm } from "components/common/commentForm/CommentVisibilityForm";

interface Props {
  orderId: Order["id"];
  routeId: Route["id"];
  showForm?: boolean;
  close: () => void;
}

export const RouteCommentForm = ({ orderId, routeId, close }: Props) => {
  const queryClient = useQueryClient();
  const { closeInfoWindow } = useInfoWindow();
  const settings = useRightPanelDisplaySettings();

  const handleSubmitComment = async (
    value: { body: string; isOnlyVisibleForTeam: boolean },
    { setError, setFetching, clear }: CommentFormHelpers,
  ) => {
    return new Promise<{ success: boolean }>(async resolve => {
      setFetching(true);
      const [comment, error] = await postComment({
        body: value.body,
        order: orderId,
        isOnlyVisibleForTeam: value.isOnlyVisibleForTeam,
      });
      if (error) {
        setError(error.body || "Nie udało się zapisać komentarza");
        setFetching(false);
        return resolve({ success: false });
      } else if (comment) {
        queryClient.setQueryData<RouteOrdersComments | undefined>(
          routeKeys.routeCreatorOrdersComments(String(routeId)),

          currentList => {
            if (!currentList) return;
            return produce(currentList, draft => {
              const orderToUpdate = draft.orders.find(el => el.id === orderId);
              orderToUpdate?.comments.push(comment);
            });
          },
        );

        queryClient.setQueryData<Route | void>(
          routeKeys.route(String(routeId)),

          currentList => {
            if (!currentList) return;
            return produce(currentList, draft => {
              const orderToUpdate = draft.orders.find(el => el.id === orderId);
              if (!orderToUpdate) {
                throw new Error(
                  "Unexpected error - tried to update order that is not on the list.",
                );
              }
              orderToUpdate.comments += 1;
            });
          },
        );

        closeInfoWindow();
        close();

        setFetching(false);
        setError("");
        clear();
        return resolve({ success: true });
      }
    });
  };
  return (
    <CommentVisibilityForm
      handleSubmit={handleSubmitComment}
      followers={[]}
      showForm={settings.comments}
    />
  );
};

import { CategorySummary, IndexCategorySummary } from "api/new-production-plans/models";

export const isMoreThanOneSummarySlot = (
  categorySummary: CategorySummary[],
  checkedSummary: IndexCategorySummary,
): boolean => {
  return categorySummary.some(
    summary =>
      summary.code === checkedSummary.code &&
      summary.name === checkedSummary.name &&
      summary.color === checkedSummary.color &&
      summary.slots - 1 > 0,
  );
};

import { postBankAccount } from "api/bank-accounts/calls";
import { Header } from "components/common";
import { Button } from "components/common/buttonLegacy";
import { CommonError, Spinner } from "components/utils";
import { useQuery } from "hooks";
import { useMutation } from "hooks/useMutation";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { getAnyErrorKey } from "utilities";

export const Create = () => {
  const { query } = useQuery();
  const { ref, error } = query;
  const history = useHistory();

  const postBankAccountMutation = useMutation(postBankAccount, {
    onSuccess: () => {
      history.push("/bank-accounts/list");
    },
  });

  useEffect(() => {
    postBankAccountMutation.mutate({ referenceId: ref });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (postBankAccountMutation.error || error) {
    return (
      <div className="h-100">
        <Header title="Dodaj konto bankowe" />
        <CommonError
          text={
            <div className="d-flex justify-content-center align-items-center flex-column ">
              {error && <div>Wystąpił błąd</div>}
              {postBankAccountMutation.error && (
                <div>{getAnyErrorKey(postBankAccountMutation.error)}</div>
              )}
              <Button
                kind="primary"
                className="mt-2"
                onClick={() => history.replace("/bank-accounts/list")}
              >
                Wróć do listy kont bankowych
              </Button>
            </div>
          }
        />
      </div>
    );
  }

  return (
    <div>
      <Header title="Dodaj konto bankowe" />
      <Spinner color="blue" size="big" text="trwa wczytywanie" position="absolute" />
    </div>
  );
};

import { useSelector } from "hooks";
import { moduleConfig, externalManufacturerModuleConfig } from "../../moduleConfig";
import cx from "classnames";
import { useRedux } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import styles from "./ModuleNavigation.module.css";
import { assertIsDefined } from "utilities/assertIsDefined";
import cuid from "cuid";
import { ExternalManufacturerModule, Module } from "../../moduleTypes/moduleTypes";
import { NavigationHeader } from "./components/NavigationHeader";
import { NavigationBody } from "./components/NavigationBody";
import { FLAVOR } from "CONSTANTS";

interface Props {
  module: string;
}

export const ModuleNavigation = ({ module }: Props) => {
  const isMain = FLAVOR === "main";
  const currentModuleUrl = isMain
    ? Object.entries(moduleConfig).find(config => {
        if (config[0] === module) {
          return config[1].name;
        }
        return undefined;
      })?.[1].url
    : Object.entries(externalManufacturerModuleConfig).find(config => {
        if (config[0] === module) {
          return config[1].name;
        }
        return undefined;
      })?.[1].url;

  const isImportInvoices = useRouteMatch(`${currentModuleUrl}/import-invoices/check`);
  const isDashboard = useRouteMatch(`${currentModuleUrl}/dashboard`);
  const isCallCenterRoute = useRouteMatch<{ routeId: string }>(
    `${currentModuleUrl}/route/:routeId/orders`,
  );
  const isLogisticsRoute = useRouteMatch<{ routeId: string }>(
    `${currentModuleUrl}/route/:routeId/trading-documents`,
  );
  const isLogisticsRouteLineItems = useRouteMatch<{ routeId: string }>(
    `${currentModuleUrl}/route-line-items/:routeId`,
  );
  const isUnloadingsList = useRouteMatch(`${currentModuleUrl}/unloadings/list/all`);
  const isPickingsList = useRouteMatch(`${currentModuleUrl}/pickings/list/all`);
  const isLoadingsList = useRouteMatch(`${currentModuleUrl}/loadings/list/all`);
  const isWarehouseSchema = useRouteMatch(`${currentModuleUrl}/warehouse-schema`);

  const { shouldBeOpen, isOpen, toggleOpen, setShouldBeOpen } = useThresholdToggle();
  const [isSubSectionOpened, setIsSubSectionOpened] = useState(
    shouldBeOpen && !isDashboard && !isWarehouseSchema ? true : false,
  );

  const modules = Object.keys(moduleConfig) as Module[];

  const currentModule = modules.find(key => key === module) as Module | undefined;

  const externalManufacturerModules = Object.keys(
    externalManufacturerModuleConfig,
  ) as ExternalManufacturerModule[];

  const externalManufacturerCurrentModule = externalManufacturerModules.find(
    key => key === module,
  ) as ExternalManufacturerModule | undefined;

  const currentModuleName = Object.entries(
    isMain ? moduleConfig : externalManufacturerModuleConfig,
  ).find(config => {
    if (config[0] === module) {
      return config[1].name;
    }
    return undefined;
  })?.[1].name;

  if (isMain) {
    assertIsDefined(currentModule);
  }

  if (FLAVOR === "externalManufacturing") {
    assertIsDefined(externalManufacturerCurrentModule);
  }

  useEffect(() => {
    if (isWarehouseSchema && isOpen) {
      toggleOpen();
      setIsSubSectionOpened(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={cx(styles.navLeft, {
        [styles.navLeftClosed]: !shouldBeOpen,
        "d-none": isImportInvoices,
      })}
    >
      <div className={styles.navLeftInner}>
        <NavigationHeader
          currentModuleName={currentModuleName}
          isDashboard={isDashboard}
          isOpen={isOpen}
          toggleOpen={toggleOpen}
          shouldBeOpen={shouldBeOpen}
          currentModule={isMain ? currentModule : externalManufacturerCurrentModule}
          module={module}
          setIsSubSectionOpened={setIsSubSectionOpened}
          isWarehouseSchema={isWarehouseSchema}
        />
        <NavigationBody
          currentModule={isMain ? currentModule : externalManufacturerCurrentModule}
          currentModuleUrl={currentModuleUrl}
          isCallCenterRoute={isCallCenterRoute}
          isLogisticsRouteLineItems={isLogisticsRouteLineItems}
          isLogisticsRoute={isLogisticsRoute}
          isDashboard={isDashboard}
          isUnloadingsList={isUnloadingsList}
          isPickingsList={isPickingsList}
          isLoadingsList={isLoadingsList}
          isWarehouseSchema={isWarehouseSchema}
          isSubSectionOpened={isSubSectionOpened}
          setIsSubSectionOpened={setIsSubSectionOpened}
          shouldBeOpen={shouldBeOpen}
          setShouldBeOpen={setShouldBeOpen}
        />
      </div>
    </div>
  );
};

export const useThresholdToggle = () => {
  const isNavbarOpen = useSelector(state => state.ui.isNavbarOpened);
  const [dispatch, { ui }] = useRedux();
  const [shouldBeOpen, setShouldBeOpen] = useState(isNavbarOpen);
  const toggleMenu = useCallback(() => dispatch(ui.toggleNavbar()), [dispatch, ui]);
  const toggleOpen = () => setShouldBeOpen(s => !s);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    toggleMenu();
  }, [shouldBeOpen, toggleMenu]);
  return { isOpen: isNavbarOpen, shouldBeOpen, setShouldBeOpen, toggleOpen };
};

export const clickOutsideShortcutsIgnoreClass = "ignore-outside-shortcuts-click-" + cuid();
export const clickOutsideIgnoreClass = "ignore-outside-click-" + cuid();

import {
  getSalesAccount,
  getSalesAccountsB2b,
  getSalesAccountB2b,
  getSalesAccounts,
} from "api/sales-accounts/calls";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";

export const useSalesAccountB2b = createApiQuery(getSalesAccountB2b);
export const useSalesAccountsB2b = createPaginatedApiQuery(getSalesAccountsB2b);
export const useSalesAccount = createApiQuery(getSalesAccount);
export const useSalesAccounts = createPaginatedApiQuery(getSalesAccounts);

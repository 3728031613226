import { SourceType } from "api/warehouse/models";

const sourceTypeToUrlDict: Record<SourceType, string> = {
  ROUTE: "route",
  BASE_SOURCE: "base-source",
  ORDER_GROUP: "order-group",
  PRODUCTION_ORDER: "production-order",
};

export function formatSourceTypeToUrl(sourceType: SourceType) {
  return sourceTypeToUrlDict[sourceType];
}

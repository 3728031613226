import { Route } from "api/routes/models";
import { CommonError, Modal, Spinner } from "components/utils";
import { useRampLoadingStatus } from "hooks/apiPrimitives";
import cx from "classnames";
import listEmptyIcon from "assets/images/el2.png";
import styles from "./RampLoadingStatusModal.module.css";
import { NoContent } from "components/utils/noContent";
import { IndexItem } from "./IndexItem";

interface Props {
  close: () => void;
  route: Route;
}

export const RampLoadingStatusModal = ({ close, route }: Props) => {
  const { data: rampLoadingStatus, error, isLoading } = useRampLoadingStatus(String(route.id));

  if (error) {
    return (
      <Modal close={close} isOpen>
        <CommonError status={error._httpStatus_} />
      </Modal>
    );
  }

  if (isLoading) {
    return (
      <Modal close={close} isOpen>
        <Spinner color="blue" on={isLoading} size="big" text="trwa wczytywanie indeksów" />
      </Modal>
    );
  }

  return (
    <Modal close={close} isOpen size={{ all: { width: "45vw" } }}>
      <div className="w-100">
        <h1 className="ml-0">{route.ramp!.name}</h1>
        <div className={(cx(styles.container), "container")}>
          <div className="row fs-10 text-color-grey border-bottom">
            <div className="col-1 pr-0" />
            <div className="col-7 pl-0">Indeks</div>
            <div className="col-2 d-flex justify-content-center">Wymagane</div>
            <div className="col-2 d-flex justify-content-center">Obecnie na rampie</div>
          </div>
          {rampLoadingStatus && rampLoadingStatus.length > 0 ? (
            rampLoadingStatus.map(index => <IndexItem index={index} key={index.index.id} />)
          ) : (
            <NoContent
              className="border-top-0"
              header="Lista nie zawiera elementów"
              img={listEmptyIcon}
              variant="B"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

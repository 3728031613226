import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import {
  Employee,
  EmployeeDetails,
  EmployeeDetailsPage,
  Upholstery,
  EmployeeIndex,
  UpholsteryProductionOrder,
} from "./models";
import { Pagination, PaginationWithCounters } from "../types";
import { Assign } from "utility-types";
import { UpholsteryProductionListOrder } from "./models";
import { ApiFetcher } from "hooks/createApiQuery";
import { upholsteriesKeys } from "./keys";
import { queryFetch } from "apiConnectors/queryFetch";

const api = tokenRefreshMiddleware(apiFetch);

export const getUpholsteries = (search: string = "", abortToken?: string) =>
  api<PaginationWithCounters<Upholstery, "numProductionItems">>({
    method: "GET",
    url: "/upholsteries/items" + search,
    abortToken,
  });

export const getUpholstery = (id: string | number, abortToken?: string) =>
  api<Upholstery>({
    method: "GET",
    url: "/upholsteries/items/" + id,
    abortToken,
  });

export const patchUpholstery = (
  data: Partial<
    Assign<Upholstery, { seamstress: number; cutter: number; upholsterer: number | null }>
  >,
  id: string | number,
) =>
  api<any>({
    method: "PATCH",
    url: "/upholsteries/items/" + id,
    data,
  });

export const postComment = (data: any) =>
  api<any>({
    method: "POST",
    url: `/upholsteries/comments`,
    data,
  });

export const getUpholsteriesEmployees = (search: string = "", abortToken?: string) =>
  api<Pagination<Employee>>({
    method: "GET",
    url: "/upholsteries/employees" + search,
    abortToken,
  });

export const getUpholsteriesEmployeesQuery = (
  search: string = "",
): ApiFetcher<Pagination<Employee>> => ({
  key: upholsteriesKeys.upholsteriesEmployees(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/upholsteries/employees" + search,
    }),
});

export const getUpholsteriesEmployeeDetails = (id: string | number, abortToken?: string) =>
  api<EmployeeDetailsPage>({
    method: "GET",
    url: "/upholsteries/employee-last-upholsteries/" + id,
    abortToken,
  });

export const getUpholsteriesEmployeeAllIndexes = (search: string, abortToken?: string) =>
  api<Pagination<EmployeeIndex>>({
    method: "GET",
    url: "/upholsteries/employee-all-upholsteries" + search,
    abortToken,
  });

export const getUpholsteriesEmployee = (id: string | number, abortToken?: string) =>
  api<EmployeeDetails>({
    method: "GET",
    url: "/upholsteries/employees/" + id,
    abortToken,
  });

export const patchUpholsteriesEmployee = (id: number | string, data: Partial<EmployeeDetails>) =>
  api<Partial<EmployeeDetails>>({
    method: "PATCH",
    url: "/upholsteries/employees/" + id,
    data,
  });

export const postUpholsteriesEmployee = (
  data: Assign<Omit<EmployeeDetails, "id" | "dateOfDismissal" | "number">, { avatar: File | null }>,
) =>
  api<EmployeeDetails>({
    method: "POST",
    url: "/upholsteries/employees",
    data,
  });

export const deleteUpholsteriesItem = ({ id }: { id: number }) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/upholsteries/items/" + id,
  });

export const deleteCustomerEmployee = (employeeId: number | string) =>
  api<void>({
    method: "DELETE",
    url: "/upholsteries/employees/" + employeeId,
  });

export const getProductionOrders = (search: string = "", abortToken?: string) =>
  api<Pagination<UpholsteryProductionListOrder>>({
    method: "GET",
    url: "/upholsteries/production-orders" + search,
    abortToken,
  });

export const patchUpholsteries = (data: {
  // list of upholstery Ids
  objects: number[];
  upholstererId?: number;
  plannedProductionDate?: string;
}) =>
  api<{ message: string }>({
    method: "PATCH",
    url: "/upholsteries/items/bulk-update",
    data,
  });

export const getProductionOrder = (id: string | number, abortToken?: string) =>
  api<UpholsteryProductionOrder>({
    method: "GET",
    url: "/upholsteries/production-orders/" + id,
    abortToken,
  });

export const patchProductionOrder = (id: string | number, data: any) =>
  api<UpholsteryProductionOrder>({
    method: "PATCH",
    url: "/upholsteries/production-orders/" + id,
    data,
  });

import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import truckIcon from "assets/images/truck.svg";
import { Loadings } from "routes/wms/Loadings";

export const loadings: ModuleLink = {
  url: "loadings",
  label: "Załadunki",
  icon: truckIcon,
  subSection: <></>,
  routing: Loadings,
};

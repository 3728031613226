/* eslint-disable no-loop-func */
import { ColumnDef, Table } from "@tanstack/react-table";
import { cx } from "utilities";
import styles from "./TableLoader.module.css";
import { TABLE_PADDING_HORIZONTAL } from "../../constants";
import { Loader } from "components/miloDesignSystem/atoms/loader";
import { UiSchema } from "../../uiSchemas";

export const DEFAULT_LOADER_HEIGHT = 12;
export const DEFAULT_ROW_LOADER_WIDTH = 65;
export const DEFAULT_HEADER_LOADER_WIDTH = 30;
export const DEFAULT_CELL_PADDING = 6;

interface Props<T> {
  containerRef: React.RefObject<HTMLDivElement>;
  table: Table<T>;
  uiSchema: UiSchema;
  columns: ColumnDef<T, any>[] | any[];
}

export const TableLoader = <T,>({ containerRef, table, columns, uiSchema }: Props<T>) => {
  const hasRows = table.getRowModel().rows.length > 0;
  const width = table.getTotalSize() + TABLE_PADDING_HORIZONTAL;

  // Render 10 default rows for the first render
  if (!hasRows || containerRef.current?.offsetHeight === undefined) {
    return (
      <div className={styles.disableScroll}>
        {(() => {
          const loaderRows: JSX.Element[] = [];

          for (let i = 0; i < 10; i++) {
            loaderRows.push(
              <div
                className={cx(styles.row, "d-flex align-items-center")}
                key={i}
                style={{ width, maxWidth: width, minWidth: width }}
              >
                {columns.map(cell => (
                  <LoaderCell height={uiSchema.cell.height} width={cell.size} />
                ))}
              </div>,
            );
          }

          return loaderRows;
        })()}
      </div>
    );
  }

  const determineRowNumber = (): number => {
    if (hasRows) return table.getRowModel().rows.length;
    return containerRef.current!.offsetHeight / Number(uiSchema.cell.height);
  };

  return (
    <div className={styles.disableScroll}>
      {(() => {
        const numberOfRows = determineRowNumber();
        const loaderRows: JSX.Element[] = [];
        for (let i = 0; i < numberOfRows; i++) {
          loaderRows.push(
            <div
              className={cx(styles.row, "d-flex align-items-center")}
              style={{ width, maxWidth: width, minWidth: width }}
            >
              {columns.map(cell => {
                if (cell.columns) {
                  const subRows: JSX.Element[] = [];
                  cell.columns.map((subCell: any) => {
                    return subRows.push(
                      <LoaderCell height={uiSchema.cell.height} width={subCell.size} />,
                    );
                  });

                  return subRows;
                }
                return <LoaderCell height={uiSchema.cell.height} width={cell.size} />;
              })}
            </div>,
          );
        }
        return loaderRows;
      })()}
    </div>
  );
};

export const LoaderCell = ({
  height,
  width,
}: {
  height: string | number;
  width: number | undefined;
}) => {
  return (
    <div className="pr-2 d-flex align-items-center" style={{ height: Number(height) }}>
      <Loader
        height={DEFAULT_LOADER_HEIGHT}
        width={(width ?? DEFAULT_ROW_LOADER_WIDTH) - DEFAULT_CELL_PADDING}
      />
    </div>
  );
};

import { useReducer, useEffect, useRef } from "react";
import styles from "./OrderTracking.module.css";
import cx from "classnames";
import statusImg from "assets/images/141.svg";
import locationImg from "assets/images/42.svg";
import timeImg from "assets/images/46.svg";
import contactImg from "assets/images/30.svg";
import kivyLogo from "assets/images/logo2.svg";
import { getOrderStatus } from "utilities/getOrderStatus";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { POLAND_CENTER } from "CONSTANTS";
import { OrderTrackingInfo } from "api/orders/models";
import { getOrderTrackingInfo } from "api/orders/calls";
import { RouteComponentProps } from "react-router";
import destinationImg from "assets/images/mapMarkers/p43.png";
import carImg from "assets/images/mapMarkers/p44.png";
import { useAlertModal } from "hooks/useAlertModal";
import { dateFns } from "utilities";

type State = {
  status: "standby" | "error" | "fetching";
  data: OrderTrackingInfo | null;
};
type Action =
  | {
      type: "REQUEST";
    }
  | {
      type: "SUCCESS";
      payload: OrderTrackingInfo;
    }
  | {
      type: "FAILURE";
    };

const initialState: State = {
  status: "standby",
  data: null,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "REQUEST":
      return { ...state, status: "fetching" };
    case "SUCCESS":
      return { ...state, status: "standby", data: action.payload };
    case "FAILURE":
      return { ...state, status: "error" };
    default:
      return state;
  }
}

export const OrderTracking = ({ match }: RouteComponentProps<{ uid: string; token: string }>) => {
  const [{ data }, dispatch] = useReducer(reducer, initialState);
  const initialMount = useRef(true);
  const alert = useAlertModal();
  useEffect(() => {
    if (initialMount.current) {
      async function fetchData() {
        dispatch({ type: "REQUEST" });
        const [payload] = await getOrderTrackingInfo({
          orderId: match.params.uid,
          trackingToken: match.params.token,
        });
        if (payload) {
          dispatch({ type: "SUCCESS", payload });
        } else {
          dispatch({ type: "FAILURE" });
          alert.open("Nie można wyświetlić danych zamówienia.");
        }
      }
      fetchData();
    }
  });
  useEffect(() => {
    initialMount.current = false;
  }, []);

  const realizationStatus = data ? getOrderStatus(data.order.status) : null;
  return (
    <div className={styles["content-wrapper"]}>
      <div className={styles["map"]}>
        <div className="flex-1 position-relative">
          <GoogleMap
            mapContainerStyle={{
              height: "100vh",
              width: "100%",
            }}
            zoom={6}
            options={{
              mapTypeControl: false,
              streetViewControl: false,
              fullscreenControl: false,
              draggableCursor: "default",
            }}
            center={POLAND_CENTER}
          >
            {data?.car && <Marker position={data.car.coordinates} icon={carImg}></Marker>}
            {data?.order.coordinates && (
              <Marker position={data.order.coordinates} icon={destinationImg}></Marker>
            )}
          </GoogleMap>
        </div>
        {data && realizationStatus && (
          <div
            className={cx(styles["details-panel"], "d-flex justify-content-between flex-column")}
          >
            <div className="px-3 py-4">
              <div className={cx(styles["data-group"], "mb-3 w-100")}>
                <h1>Tu jest Twoje zamówienie</h1>
              </div>
              <div className={cx(styles["data-group"], "mb-3 w-100")}>
                <div className={styles["group-label"]}>
                  <img src={statusImg} alt="" />
                  <span>Status</span>
                </div>
                <div className={styles["data-value"]}>
                  <div className={cx("d-flex", styles["status"])}>
                    <div className="mr-2">
                      <img src={realizationStatus.img} alt="" />
                    </div>
                    <span>{realizationStatus.name}</span>
                  </div>
                </div>
              </div>
              <div className={cx(styles["data-group"], "mb-3 w-100")}>
                <div className={styles["group-label"]}>
                  <img src={locationImg} alt="" />
                  <span>Lokalizacja</span>
                </div>
                <div className={styles["data-value"]}>{data.order.statusText}</div>
              </div>
              <div className={cx(styles["data-group"], "mb-3 w-100")}>
                <div className={styles["group-label"]}>
                  <img src={timeImg} alt="" />
                  <span>Przewidywany czas dostawy</span>
                </div>
                <div className={styles["data-value"]}>
                  {dateFns.format(new Date(data.order.plannedDeliveryTime), "d MMM yyyy, h:mm")}
                </div>
              </div>
              <div className={cx(styles["data-group"], "mb-3 w-100")}>
                <div className={styles["group-label"]}>
                  <img src={contactImg} alt="" />
                  <span>Kontakt</span>
                </div>
                <div className={styles["data-value"]}>
                  <div>
                    {data.contact.firstName} {data.contact.lastName}
                  </div>
                  <div>{data.contact.phone}</div>
                </div>
              </div>
            </div>
            <div className={cx("d-flex justify-content-center py-3", styles["logo"])}>
              <img src={kivyLogo} alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

import { Tab } from "api/trading-documents/models";
import { queryString } from "utilities";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  route: number | string;
  tab: Tab;
};

export const getSearch = ({ tab, route, query }: GetSearchProps): string => {
  const tabsQuery = {
    routeId: route,
    paymentStatus: tab === "paid" ? "PAID" : tab === "not-paid" ? "NOT_PAID" : "",
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

import styles from "./Loader.module.css";
import { cx } from "utilities";

interface Props {
  height: number;
  width: number;
}

export const Loader = ({ height, width }: Props) => {
  return (
    <div
      className={cx(styles.loader, "pt-1 pb-1")}
      style={{
        height,
        width,
      }}
    />
  );
};

import { TradingDocument } from "api/trading-documents/models";
import { Button } from "components/common";
import { RightPanelSection, RightPanelTableRow } from "components/utils/drawer";
import { paymentSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { EmptyPaymentSection } from "./components/EmptyPaymentSection";
import { Payment } from "./components/Payment";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { useToggle } from "hooks";
import { AddPaymentModal } from "./addPaymentModal/AddPaymentModal";

interface Props {
  tradingDocument: TradingDocument;
}

export const PaymentSection = ({ tradingDocument }: Props) => {
  const addPaymentModal = useToggle();

  return (
    <RightPanelSection title="Płatności">
      {!Boolean(tradingDocument.payments.length) ? (
        <EmptyPaymentSection tradingDocument={tradingDocument} />
      ) : (
        <div className="pb-3">
          <RightPanelTableRow grid={paymentSectionListConfig.grid} hover={false}>
            <div>#</div>
            <div>typ</div>
            <div>nr płatności</div>
            <div className="d-flex align-items-center justify-content-end text-align-right">
              kwota | prowizja
            </div>
            <div>sposób</div>
            <div>usługa</div>
            <div>metoda</div>
            <div>nr przelewu</div>
            <div>odp.</div>
            <div>dodano</div>
          </RightPanelTableRow>
          <div>
            {tradingDocument.payments.map((payment, index) => (
              <Payment key={payment.id} payment={payment} position={index + 1} />
            ))}
          </div>
          <div className="py-2">
            <Button kind="create" onClick={addPaymentModal.open}>
              <div className="btnBase btnBaseSmall">
                <img alt="Dodaj" src={darkPlusIcon} />
                Dodaj kolejną płatność
              </div>
            </Button>
          </div>
          {addPaymentModal.isOpen && (
            <AddPaymentModal close={addPaymentModal.close} tradingDocument={tradingDocument} />
          )}
        </div>
      )}
    </RightPanelSection>
  );
};

import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import {
  getCargoUnloads,
  getPackagesReceivedAndReleasedPerDay,
  getReceivedPackagesPerCategory,
  getReleasedPackagesPerCategory,
  getWarehouseWorker,
  getWarehouseWorkers,
  getOverviewReleasedAndReceived,
  getOverviewReceivedByProductCategory,
  getOverviewReleasedForCustomer,
  getOverviewReleasedByProductCategory,
  getOverviewReceivedForCustomer,
  getDailySummaryReceivedAndReleased,
  getPackagesReceivedAndReleasedPerMonth,
  getUnloads,
  getUnloading,
  getUnloadingOrders,
  patchUnloading,
  getPickings,
  getPicking,
  patchPicking,
  getUnloadingLineItemDetails,
  getPickingLineItems,
  getPickingLineItemDetails,
  getLastPickedOrders,
  getLastUnloadedGroups,
  getUnloadingWorkspaceInventory,
  getLoadings,
  getPickingWorkspaceInventory,
  getLastLoadedOrders,
  getLoading,
  patchLoading,
  getLoadingLineItems,
  getLoadingLineItemDetails,
  getLoadingWorkspaceInventory,
  unloadingCancellation,
  addLineItemToUnloading,
} from "./calls";
import { createApiQuery } from "hooks/createApiQuery";
import { useMutation } from "hooks/useMutation";
import { UUID } from "api/types";
import { parsePatchData } from "utilities/parsePatchData";
import { PartialOf } from "typeUtilities";
import {
  AddLineItemToUnloadingPayload,
  LoadingDetails,
  PickingDetails,
  UnloadingDetails,
  UnloadingStatus,
} from "./models";
import { wmsKeys } from "./keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { getAnyErrorKey } from "utilities";
import { FormikHelpers } from "formik";

export const useCargoUnloads = createPaginatedApiQuery(getCargoUnloads);
export const useUnloads = createPaginatedApiQuery(getUnloads);
export const useUnloading = createApiQuery(getUnloading);
export const useUnloadingOrders = createPaginatedApiQuery(getUnloadingOrders);
export const useUnloadingLineItemDetails = createApiQuery(getUnloadingLineItemDetails);
export const useLastUnloadedGroups = createPaginatedApiQuery(getLastUnloadedGroups);
export const useUnloadingWorkspaceInventory = createApiQuery(getUnloadingWorkspaceInventory);

export const usePickings = createPaginatedApiQuery(getPickings);
export const usePicking = createApiQuery(getPicking);
export const usePickingLineItems = createPaginatedApiQuery(getPickingLineItems);
export const usePickingLineItemsDetails = createApiQuery(getPickingLineItemDetails);
export const useLastPickedOrders = createPaginatedApiQuery(getLastPickedOrders);
export const usePickingWorkspaceInventory = createApiQuery(getPickingWorkspaceInventory);

export const useLoadings = createPaginatedApiQuery(getLoadings);
export const useLoading = createApiQuery(getLoading);
export const useLoadingLineItems = createPaginatedApiQuery(getLoadingLineItems);
export const useLoadingLineItemsDetails = createApiQuery(getLoadingLineItemDetails);
export const useLastLoadedOrders = createPaginatedApiQuery(getLastLoadedOrders);
export const useLoadingWorkspaceInventory = createApiQuery(getLoadingWorkspaceInventory);

export const useWarehouseWorkers = createPaginatedApiQuery(getWarehouseWorkers);

export const useOverviewReleasedAndReceived = createApiQuery(getOverviewReleasedAndReceived);
export const useOverviewReceivedByProductCategory = createApiQuery(
  getOverviewReceivedByProductCategory,
);
export const useOverviewReleasedByProductCategory = createApiQuery(
  getOverviewReleasedByProductCategory,
);
export const useOverviewReleasedForCustomer = createApiQuery(getOverviewReleasedForCustomer);
export const useOverviewReceivedForCustomer = createApiQuery(getOverviewReceivedForCustomer);

export const useWarehouseWorker = createApiQuery(getWarehouseWorker);
export const useReceivedAndReleasedPackagesPerDay = createApiQuery(
  getPackagesReceivedAndReleasedPerDay,
);
export const useDailySummaryReceivedAndReleased = createApiQuery(
  getDailySummaryReceivedAndReleased,
);
export const useReceivedAndReleasedPackagesPerMonth = createApiQuery(
  getPackagesReceivedAndReleasedPerMonth,
);
export const useReleasedPerCategory = createApiQuery(getReleasedPackagesPerCategory);
export const useReceivedPerCategory = createApiQuery(getReceivedPackagesPerCategory);

export const useUnloadingPatchMutation = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID | number; toUpdate: PartialOf<UnloadingDetails> }) => {
      return patchUnloading(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(wmsKeys.unloads.details(String(id)), toUpdate);
        const prevList = queryUtils.handlePaginatedListUpdate(wmsKeys.unloads.list(), id, toUpdate);
        return { prevList, prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(wmsKeys.unloads.details(String(id)), onMutateReturn.prevPanel, error);
        queryUtils.rollbackList(wmsKeys.unloads.list(), onMutateReturn.prevList, id);
      },
    }),
  );
};

export const useUnloadingPatchMutationWithoutParsing = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID | number; toUpdate: PartialOf<UnloadingDetails> }) => {
      return patchUnloading(toUpdate, id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(wmsKeys.unloads.details(String(id)), toUpdate);
        const prevList = queryUtils.handlePaginatedListUpdate(wmsKeys.unloads.list(), id, toUpdate);
        return { prevList, prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(wmsKeys.unloads.details(String(id)), onMutateReturn.prevPanel, error);
        queryUtils.rollbackList(wmsKeys.unloads.list(), onMutateReturn.prevList, id);
      },
    }),
  );
};

export const usePickingPatchMutation = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID | number; toUpdate: PartialOf<PickingDetails> }) => {
      return patchPicking(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(wmsKeys.picking.details(String(id)), toUpdate);
        const prevList = queryUtils.handlePaginatedListUpdate(wmsKeys.picking.list(), id, toUpdate);
        return { prevList, prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(wmsKeys.picking.details(String(id)), onMutateReturn.prevPanel, error);
        queryUtils.rollbackList(wmsKeys.picking.list(), onMutateReturn.prevList, id);
      },
    }),
  );
};

export const usePickingPatchMutationWithoutParsing = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID | number; toUpdate: PartialOf<PickingDetails> }) => {
      return patchPicking(toUpdate, id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(wmsKeys.picking.details(String(id)), toUpdate);
        const prevList = queryUtils.handlePaginatedListUpdate(wmsKeys.picking.list(), id, toUpdate);
        return { prevList, prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(wmsKeys.picking.details(String(id)), onMutateReturn.prevPanel, error);
        queryUtils.rollbackList(wmsKeys.picking.list(), onMutateReturn.prevList, id);
      },
    }),
  );
};

export const useLoadingPatchMutation = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID | number; toUpdate: PartialOf<LoadingDetails> }) => {
      return patchLoading(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(wmsKeys.loading.details(String(id)), toUpdate);
        const prevList = queryUtils.handlePaginatedListUpdate(wmsKeys.loading.list(), id, toUpdate);
        return { prevList, prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(wmsKeys.loading.details(String(id)), onMutateReturn.prevPanel, error);
        queryUtils.rollbackList(wmsKeys.loading.list(), onMutateReturn.prevList, id);
      },
    }),
  );
};

export const useLoadingPatchMutationWithoutParsing = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID | number; toUpdate: PartialOf<LoadingDetails> }) => {
      return patchLoading(toUpdate, id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(wmsKeys.loading.details(String(id)), toUpdate);
        const prevList = queryUtils.handlePaginatedListUpdate(wmsKeys.loading.list(), id, toUpdate);
        return { prevList, prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(wmsKeys.loading.details(String(id)), onMutateReturn.prevPanel, error);
        queryUtils.rollbackList(wmsKeys.loading.list(), onMutateReturn.prevList, id);
      },
    }),
  );
};

export const useCancellUnloadingItem = () => {
  return useMutation(
    ({
      unloadingId,
      kind,
      ids,
    }: {
      unloadingId: UUID;
      kind: "UNLOADING_LINE_ITEMS" | "UNLOADING_SINGLE_QUANTITY_ITEMS";
      ids: UUID[];
      isCancelled: boolean;
      status: UnloadingStatus;
    }) => {
      return unloadingCancellation({
        unloadingId,
        kind,
        ids,
      });
    },
    ({ queryUtils }) => ({
      onMutate: ({ ids, isCancelled, status }) => {
        const prevList = queryUtils.handlePaginatedListUpdate(wmsKeys.unloads.lineItems(), ids[0], {
          isCancelled,
          status,
        });
        return { prevList };
      },
      onError: (_, { ids }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollbackList(wmsKeys.unloads.lineItems(), onMutateReturn.prevList, ids[0]);
      },
    }),
  );
};

export const useAddIndexToUnloading = (close: () => void) => {
  const addIndexToUnloadingMutation = useMutation(
    addLineItemToUnloading,
    ({ toastr, queryClient }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries(wmsKeys.unloads.lineItems());
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Dodano produkt",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: AddLineItemToUnloadingPayload,
    actions: FormikHelpers<AddLineItemToUnloadingPayload>,
  ) => {
    if (values.productElements[0].index) {
      addIndexToUnloadingMutation.mutate(
        {
          customerId: values.customerId,
          id: values.id,
          indexId: values.productElements[0].index,
          quantity: values.quantity,
          unloadingId: values.unloadingId,
        } as AddLineItemToUnloadingPayload,
        {
          onSuccess: () => {
            actions.setSubmitting(false);
          },
          onError: error => {
            actions.setSubmitting(false);
            actions.setErrors(error.response?.data);
          },
        },
      );
    }
  };

  return handleSubmit;
};

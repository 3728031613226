import { ClientGenericTable, GenericTable, RowData } from "typeUtilities";
import { dateFns } from "utilities";

export const parseTableRowData = <T extends string>(
  tableData: GenericTable<T> | null,
): ClientGenericTable<T>["rows"] => {
  if (!tableData) return [];

  return tableData.rows.map((row, index) => {
    const rowData = Object.entries(row).reduce((acc, [id, data]) => {
      const value = (data as RowData<T>).value;
      Object.assign(acc, {
        [id]: { id, value },
      });
      return acc;
    }, {});

    return { id: `${index}-${tableData.rowsBy}`, ...rowData };
  }) as ClientGenericTable<T>["rows"];
};

export const formatGenericTableDate = <T extends string>(
  rowsBy: GenericTable<T>["rowsBy"] | null,
  isoDate: string,
) => {
  if (rowsBy === "DAY") {
    return dateFns.format(new Date(isoDate), "dd.MM.yyyy");
  }
  const [, weekOrMonth] = isoDate.split("-");
  if (rowsBy === "WEEK") {
    return `Tydzień ${parseInt(weekOrMonth)}`;
  }
  return dateFns.format(new Date(2000, parseInt(weekOrMonth) - 1, 1), "LLLL");
};

import {
  MutationFunction,
  useMutation as useQueryMutation,
  UseMutationOptions,
  useQueryClient,
} from "react-query";
import { QueryFetchError } from "api/types";
import { useQueryUtils } from "./hooks";
import { useToastr } from "./useToastr";

export const useMutation = <
  TData extends unknown,
  TVariables extends unknown,
  TContext extends unknown
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?:
    | ((args: {
        queryUtils: ReturnType<typeof useQueryUtils>;
        toastr: ReturnType<typeof useToastr>;
        queryClient: ReturnType<typeof useQueryClient>;
      }) => UseMutationOptions<TData, QueryFetchError, TVariables, TContext>)
    | UseMutationOptions<TData, QueryFetchError, TVariables, TContext>,
) => {
  const queryUtils = useQueryUtils();
  const toastr = useToastr();
  const queryClient = useQueryClient();
  const queryOptions =
    typeof options === "function" ? options({ queryUtils, queryClient, toastr }) : options;

  return useQueryMutation<TData, QueryFetchError, TVariables, TContext>({
    mutationFn,
    ...queryOptions,
  });
};

export const useMutationOptions = <
  TData extends unknown,
  TVariables extends unknown,
  TContext extends unknown
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?:
    | ((args: {
        queryUtils: ReturnType<typeof useQueryUtils>;
        toastr: ReturnType<typeof useToastr>;
        queryClient: ReturnType<typeof useQueryClient>;
      }) => UseMutationOptions<TData, QueryFetchError, TVariables, TContext>)
    | UseMutationOptions<TData, QueryFetchError, TVariables, TContext>,
) => {
  const queryUtils = useQueryUtils();
  const toastr = useToastr();
  const queryClient = useQueryClient();
  const queryOptions =
    typeof options === "function" ? options({ queryUtils, queryClient, toastr }) : options;

  return { ...queryOptions, mutationFn };
};

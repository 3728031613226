import { useRoutePatchMutation } from "api/logistics/routes/hooks";
import { Route } from "api/routes/models";
import { AutoTextarea } from "components/common/AutoTextarea";
import { RightPanelSection } from "components/utils/drawer";
import { moveCaretAtEnd } from "utilities/moveCaretAtEnd";
import styles from "../RightPanel.module.css";

interface Props {
  route: Route;
}

export const NoteSection = ({ route }: Props) => {
  const noteMutation = useRoutePatchMutation();

  return (
    <RightPanelSection
      title="Ogólne uwagi do trasy"
      titleClassName={styles.notificationSectionTitle}
    >
      <div className="pb-2">
        <div className="position-relative w-100">
          <AutoTextarea
            className={styles.textarea}
            defaultValue={route.note}
            disabled={noteMutation.isLoading}
            onBlur={value => {
              if (value.target.value !== route.note) {
                noteMutation.mutate({
                  id: route.id,
                  toUpdate: { note: value.target.value },
                });
              }
            }}
            onFocus={moveCaretAtEnd}
            placeholder="Wpisz uwagi"
          />
        </div>
      </div>
    </RightPanelSection>
  );
};

import { Log } from "api/logbook/models";
import { Button } from "components/common";
import { HistoryLogItem } from "components/common/historyLogItem";
import darkPlusIcon from "assets/images/darkPlus.svg";
import styles from "./InvoiceHistory.module.css";

interface Props {
  fetchMore: () => void;
  hasMore: boolean;
  inProgress: boolean;
  logs: Log[];
}

export const InvoiceHistory = ({ fetchMore, hasMore, inProgress, logs }: Props) => {
  return (
    <div className={styles.listWrapper}>
      <div className={styles.listGroup}>
        {logs.map(log => (
          <HistoryLogItem key={log.id} log={log} />
        ))}
      </div>
      <div className="d-flex mt-4 mb-3">
        {!inProgress && hasMore && (
          <Button kind="create" onClick={fetchMore}>
            <div className="btnBase btnBaseSmall">
              <img alt="" src={darkPlusIcon} />
              Wczytaj więcej
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

import { TradingDocumentPaymentStatus } from "api/trading-documents/models";
import { dateExceedsToday } from "./dateExceedsToday";

export const getStatusLabelKind = (
  paymentStatus: TradingDocumentPaymentStatus,
  paymentDeadline: string | null,
) => {
  if (paymentStatus === "PAID") {
    return "green";
  } else {
    if (paymentDeadline && dateExceedsToday(paymentDeadline)) {
      return "red";
    } else {
      return "default";
    }
  }
};

import { Route } from "api/routes/models";
import { Pagination } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { PartialOf } from "typeUtilities";
import { callCenterRoutesKeys } from "./keys";
import {
  CallCenterDashboardDepartedRoute,
  CallCenterDashboardIncompleteRoute,
  CallCenterDashboardLatestSmsMessages,
  CallCenterRouteDetails,
  CallCenterRouteListItem,
  CallCenterRouteOrderListItem,
  RouteTracking,
} from "./models";

// DASHBOARD

export const getDashboardDepartedRoutes = (
  search: string = "",
): ApiFetcher<Pagination<CallCenterDashboardDepartedRoute>> => ({
  key: callCenterRoutesKeys.dashboardDepartedRoute.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/call-center/dashboard/routes-departed" + search,
    }),
});

export const getDashboardIncompleteRoutes = (
  search: string = "",
): ApiFetcher<Pagination<CallCenterDashboardIncompleteRoute>> => ({
  key: callCenterRoutesKeys.dashboardIncompleteRoute.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/call-center/dashboard/routes-incomplete" + search,
    }),
});

export const getDashboardLatestSmsMessages = (
  search: string = "",
): ApiFetcher<Pagination<CallCenterDashboardLatestSmsMessages>> => ({
  key: callCenterRoutesKeys.dashboardLatestSmsMessage.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/call-center/dashboard/latest-sms-messages" + search,
    }),
});

export const getCallCenterRoutes = (
  search: string = "",
): ApiFetcher<Pagination<CallCenterRouteListItem>> => ({
  key: callCenterRoutesKeys.route.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/call-center/routes" + search,
    }),
});

export const getCallCenterRoute = ({ id }: { id: string }): ApiFetcher<CallCenterRouteDetails> => ({
  key: callCenterRoutesKeys.route.details(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/call-center/routes/" + id,
    }),
});

export const getCallCenterRouteOrders = (
  search: string = "",
): ApiFetcher<Pagination<CallCenterRouteOrderListItem>> => ({
  key: callCenterRoutesKeys.routeOrder.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/call-center/orders" + search,
    }),
});

export const patchRouteListItem = (data: PartialOf<CallCenterRouteListItem>, id: string | number) =>
  queryFetch<CallCenterRouteListItem>({
    method: "PATCH",
    url: "/call-center/routes/" + id,
    data,
  });

export const patchSingleRouteOrderListItem = (
  data: PartialOf<CallCenterRouteOrderListItem>,
  id: string | number,
) =>
  queryFetch<CallCenterRouteOrderListItem>({
    method: "PATCH",
    url: "/call-center/orders/" + id,
    data,
  });

export const getCallCenterRouteOrder = (id: number): ApiFetcher<CallCenterRouteOrderListItem> => ({
  key: callCenterRoutesKeys.routeOrder.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/call-center/orders/" + id,
    }),
});

export const getCallCenterRouteTracking = (id: number | string): ApiFetcher<RouteTracking> => ({
  key: callCenterRoutesKeys.routeTracking.details(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/call-center/where-is-driver/" + id,
    }),
});

export const patchRouteTracking = (
  data: PartialOf<Pick<RouteTracking, "departureTime" | "realDepartureTime">>,
  id: string | number,
) =>
  queryFetch<Route>({
    method: "PATCH",
    url: "/routes/items/" + id,
    data,
  });

import { useDashboardIncompleteRoutes } from "api/call-center/routes/hooks";
import localStyles from "../../Dashboard.module.css";
import cx from "classnames";
import { Loader } from "components/utils/loader";
import { CommonError } from "components/utils";
import { InvoicePagination } from "components/common/invoicePagination/InvoicePagination";
import { NoResults } from "components/utils/noResults";
import { IncompleteRoute } from "./components/IncompleteRoute";
import { useFilters } from "hooks/useFilters";
import { queryString } from "utilities";

export type IncompleteRouteFilters = {
  page: string;
  search: string;
};

const initialFilters: IncompleteRouteFilters = {
  page: "1",
  search: "",
};

export const IncompleteRoutesSection = () => {
  const { setFilter, filters } = useFilters<IncompleteRouteFilters>(initialFilters);
  const search = queryString.stringify(filters);

  const {
    data: incompleteRoutes,
    error,
    isFetching,
    isLoading,
    pagination,
  } = useDashboardIncompleteRoutes(search);

  const noResults = !incompleteRoutes.length && !isLoading && !error;

  if (error) {
    return (
      <div className={localStyles.card}>
        <div className="mt-4">
          <CommonError status={error._httpStatus_} />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={localStyles.card}>
        <Loader module="callCenter" />
      </div>
    );
  }

  return (
    <div className={localStyles.card}>
      {isFetching && <Loader module="callCenter" />}
      <div>
        <div className="d-flex align-items-center justify-content-between w-100 gap-1 px-3 py-3">
          <h3 className={localStyles.emptyListTitle}>Trasy niekompletnie potwierdzone</h3>
          <div className="d-flex align-items-center gap-1">
            {incompleteRoutes.length > 0 ? (
              <InvoicePagination
                mode="manual"
                onChange={page => {
                  setFilter("page", String(page));
                }}
                page={Number(filters.page)}
                pagination={pagination}
                position="relative"
              />
            ) : (
              <div style={{ height: "42px" }} />
            )}
          </div>
        </div>
        <div className={cx(localStyles.tableHeader, localStyles.tableHeaderBokIncompleteRoutes)}>
          <div>sygnatura</div>
          <div>start</div>
          <div className="d-flex justify-content-end">niepowierdzone</div>
          <div className="d-flex justify-content-end">potwierdzone</div>
          <div></div>
        </div>
      </div>
      {!noResults &&
        incompleteRoutes.map(incompleteRoute => (
          <IncompleteRoute incompleteRoute={incompleteRoute} key={incompleteRoute.id} />
        ))}
      {noResults && (
        <NoResults
          on={noResults}
          overwrites={{
            background: {
              className: localStyles.noResultsOverwrite,
            },
          }}
          subHeader="trasy"
        />
      )}
    </div>
  );
};

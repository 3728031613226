import { Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { ManufacturingSchemasList } from "pages/manufacturingNew/manufacturingSchemas/ManufacturingSchemasList";

export const ManufacturingSchemas = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list/:tab`}
        component={ManufacturingSchemasList}
        title="Milo - schematy produkcyjne"
      />

      <Route exact={true} path="*" component={NotFound} title="Milo" />
    </Switch>
  );
};

import { yup } from "utilities";

export const validationSchema = yup.object({
  orders: yup
    .string()
    .required("Pole Nr zamówień jest wymagane")
    .matches(
      /^[A-Za-z0-9\s-$&+:;=?@#|'<>.-^*()%!\]"]+(,[ ]*?[A-Za-z0-9\s-$&+:;=?@#|'<>.-^*()%!\]"]+)*[ ]*?$/,
      "Niewłaściwy format",
    ),
});

import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import {
  Courier,
  CourierBooking,
  CreateCourier,
  PostInternalShipmentsPreview,
  Shipment,
  ShipmentListItem,
  ShippingPiece,
} from "./models";
import { dateFns } from "utilities";
import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { shippingKeys } from "./keys";
import { PartialOf } from "typeUtilities";

const api = tokenRefreshMiddleware(apiFetch);

export const postShipmentAndBookCourier = (orderId: string | number) =>
  api<CourierBooking>({
    method: "POST",
    url: "/shipping/create-shipment-and-book-courier",
    data: { order: orderId },
  });

export const shippingFileFactory = (() => {
  function getDate() {
    return dateFns.format(new Date(), "dd-MM-yyyy-HH-mm");
  }
  return {
    shippingLabelPdf: (search: string) => ({
      url: `/shipping/get-label${search}`,
      name: `Zamówienia-${getDate()}`,
    }),
    shippingLabel: (shipmentId: UUID, shipmentServiceId: number) => ({
      url: `/shipping/label/${shipmentServiceId}/${shipmentId}`,
      name: `Etykiety-${getDate()}`,
    }),
    shippingLabels: (shipments: UUID[]) => ({
      url: `/shipping/labels?shipments=${shipments.toString()}`,
      name: `Etykiety-${getDate()}`,
    }),
  };
})();

const getMebelTaxiLabelUrl = (shipmentId: UUID, shipmentServiceId: number) =>
  queryFetch<{ url: string }>({
    method: "GET",
    url: `/shipping/label/${shipmentServiceId}/${shipmentId}`,
  });

const getShippingShipments = (search: string = ""): ApiFetcher<Pagination<ShipmentListItem>> => ({
  key: shippingKeys.shipments.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/shipping/shipments" + search,
    }),
});

const getShippingCouriers = (search: string = ""): ApiFetcher<Pagination<Courier>> => ({
  key: shippingKeys.couriers.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/shipping/items" + search,
    }),
});

const getShippingPieces = (search: string = ""): ApiFetcher<Pagination<ShippingPiece>> => ({
  key: shippingKeys.pieces(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `shipping/pieces${search}`,
    }),
});

const patchShippingPiece = (data: PartialOf<ShippingPiece>, id: UUID) =>
  queryFetch<ShippingPiece>({
    method: "PATCH",
    url: `shipping/pieces/${id}`,
    data,
  });

const deleteShippingPiece = (id: UUID) =>
  queryFetch<ShippingPiece>({
    method: "DELETE",
    url: `shipping/pieces/${id}`,
  });

const postShippingPiece = (data: { shipment: UUID; productId: number; productName: string }) =>
  queryFetch<ShippingPiece>({
    method: "POST",
    url: `shipping/pieces`,
    data,
  });

const patchMultipleShippingPieces = (data: {
  shipment: UUID;
  product: number;
  isIncludedForSend: boolean;
}) =>
  queryFetch<void>({
    method: "PATCH",
    url: "shipping/pieces/bulk-update",
    data,
  });

const getShippingCourier = (id: number): ApiFetcher<Courier> => ({
  key: shippingKeys.couriers.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/shipping/items/${id}`,
    }),
});

const patchShippingCourier = (data: PartialOf<Courier>, id: number) =>
  queryFetch<Courier>({
    method: "PATCH",
    url: `/shipping/items/${id}`,
    data,
  });

const postInternalShipments = (data: { orders: number[] }) =>
  queryFetch<PostInternalShipmentsPreview>({
    method: "POST",
    url: "/shipping/create-internal-shipments",
    data,
  });

const postInternalShipment = (data: { order: number }) =>
  queryFetch<{ message: "ok" }>({
    method: "POST",
    url: "/shipping/create-internal-shipment",
    data,
  });

const postShippingShipment = (data: { shipmentService: number; orders: number[] }) =>
  queryFetch<void>({
    method: "POST",
    url: "/shipping/create-shipments",
    data,
  });

const postShippingCallPickup = (data: { pickupDate: string; shipments: (number | string)[] }) =>
  queryFetch<void>({
    method: "POST",
    url: "/shipping/call-pickup",
    data,
  });

const postShippingCourier = (data: CreateCourier) =>
  queryFetch<Courier>({
    method: "POST",
    url: "/shipping/items",
    data,
  });

const getShippingShipment = (id: UUID): ApiFetcher<Shipment> => ({
  key: shippingKeys.shipments.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/shipping/shipments/" + id,
    }),
});

const deleteShippingShipment = (id: UUID) =>
  queryFetch<Shipment>({
    method: "DELETE",
    url: "/shipping/shipments/" + id,
  });

const patchShippingShipment = (data: PartialOf<Shipment>, id: UUID) =>
  queryFetch<Shipment>({
    method: "PATCH",
    url: "/shipping/shipments/" + id,
    data,
  });

const postExternalShipments = (data: { shipments: { shippingService: number; ids: UUID[] }[] }) =>
  queryFetch({
    method: "POST",
    url: "/shipping/create-external-shipments",
    data,
  });

const patchShippingShipments = (data: {
  shipments: UUID[];
  shippingService?: number | null;
  plannedPickupArrivalDate?: string | null;
  plannedPickupArrivalTime?: string | null;
}) =>
  queryFetch<void>({
    method: "PATCH",
    url: "shipping/shipments/bulk-update",
    data,
  });

const sendShipmentsToExternal = (data: { shipments: UUID[] }) =>
  queryFetch<void>({
    method: "POST",
    url: "shipping/send-shipments-to-external",
    data,
  });

const postShippingAuthorize = (shippingProviderId: number) =>
  queryFetch<void>({
    method: "POST",
    url: `shipping/authorize/${shippingProviderId}`,
  });

const getShippingCheckConnection = (shippingProviderId: number) =>
  queryFetch<void>({
    method: "POST",
    url: `shipping/check-connection/${shippingProviderId}`,
  });

const postCancelShipments = (data: { shipments: UUID[] }) =>
  queryFetch<void>({
    method: "POST",
    url: "shipping/cancel-shipments",
    data,
  });

const getSendShipmentsToExternal = (
  search: string = "",
): ApiFetcher<{
  completed: { id: UUID; signature: string }[];
  missingShippingService: { id: UUID; signature: string }[];
  missingPlannedPickupAt: { id: UUID; signature: string }[];
}> => ({
  key: shippingKeys.sendToExternal(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "shipping/send-shipments-to-external" + search,
    }),
});

export const shippingApi = {
  getShippingShipments,
  postShippingShipment,
  sendShipmentsToExternal,
  postShippingPiece,
  postShippingCallPickup,
  getShippingCouriers,
  postShippingCourier,
  patchShippingShipments,
  getShippingCourier,
  patchShippingCourier,
  postInternalShipments,
  getShippingShipment,
  postExternalShipments,
  patchShippingShipment,
  getMebelTaxiLabelUrl,
  getShippingPieces,
  patchMultipleShippingPieces,
  getSendShipmentsToExternal,
  patchShippingPiece,
  postInternalShipment,
  deleteShippingPiece,
  postCancelShipments,
  postShippingAuthorize,
  getShippingCheckConnection,
  deleteShippingShipment,
};

import { addMonths, format } from "date-fns";
import { pl } from "date-fns/locale";

export const getMonths = (): { id: string; name: string }[] => {
  const currentDate = new Date();
  const previousMonth = addMonths(currentDate, -1);
  const twoMonthsAgo = addMonths(currentDate, -2);

  const formatMonth = (date: Date) => ({
    id: format(date, "yyyy-MM"),
    name: format(date, "LLLL", { locale: pl }),
  });

  return [formatMonth(currentDate), formatMonth(previousMonth), formatMonth(twoMonthsAgo)];
};

import { removeEmployeeFromProductionPlan } from "api/new-production-plans/calls";
import { productionPlansKeys } from "api/new-production-plans/keys";
import { ProductionPlan } from "api/new-production-plans/models";
import { UUID } from "api/types";
import { useQueryUtils } from "hooks";
import { useMutation } from "hooks/useMutation";

export const useRemoveEmployee = () => {
  const { handleMutate, rollback } = useQueryUtils();

  return useMutation(
    ({ id, productionPlanId }: { id: number; productionPlanId: UUID }) =>
      removeEmployeeFromProductionPlan({
        stageManufacturingPlanId: productionPlanId,
        employees: [id],
      }),
    ({ queryClient }) => ({
      onMutate: ({ id, productionPlanId }: { id: number; productionPlanId: UUID }) => {
        const prevState = handleMutate<ProductionPlan>(
          productionPlansKeys.productionPlanSchedule(productionPlanId),
          draft => {
            draft.employees = draft.employees.filter(el => el.id !== id);
          },
        );
        return { prevState };
      },
      onError: (error, _, onMutateReturn) => {
        const { prevState } = onMutateReturn as { prevState: ProductionPlan };
        if (prevState) {
          rollback(productionPlansKeys.productionPlanSchedule(prevState.id), prevState, error);
        }
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries({
          queryKey: productionPlansKeys.shelfItems(variables.productionPlanId),
        });
      },
    }),
  );
};

import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import truckIcon from "assets/images/24.svg";
import { ShippingCouriersRouting } from "routes/logistics/ShippingCouriers";

export const shippingCouriers: ModuleLink = {
  url: "shipping-couriers",
  label: "Kurierzy",
  icon: truckIcon,
  subSection: <></>,
  routing: ShippingCouriersRouting,
};

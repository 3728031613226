import styles from "../CreateInvoicesForMultipleOrdersModal.module.css";
import cx from "classnames";
import { PreviewTradingDocument } from "api/trading-documents/models";

interface Props {
  preview: PreviewTradingDocument;
}

export const CustomerSection = ({ preview }: Props) => {
  return (
    <div className="col-3 pr-4">
      <h4 className={cx(styles.subTitle, "pb-3 textAlignRight")}>Kontrahent</h4>
      <div className="pb-2 textAlignRight">
        <div className="body-18 pb-1">{preview.recipientCompanyName}</div>
        <div className="body-18 pb-1">
          {preview.recipientFirstName} {preview.recipientLastName}
        </div>
        <div className="body-14 pb-1">{preview.recipientStreet}</div>
        <div className="body-14 pb-1">
          {preview.recipientPostCode} {preview.recipientCity}
        </div>
        <div className="body-14 pb-1">{preview.recipientBankAccountNumber}</div>
        {Boolean(preview.recipientTaxId) && (
          <div className="body-14 pb-1">NIP:&nbsp;{preview.recipientTaxId}</div>
        )}
      </div>
    </div>
  );
};

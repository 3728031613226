export const deepOrange = {
  deepOrange12: "#EB61371F",
  deepOrange25: "#F8EAE7",
  deepOrange32: "#EB613752",
  deepOrange50: "#F7CEBF",
  deepOrange75: "#F08F6D",
  deepOrange100: "#E57458",
  deepOrange200: "#E16640",
  deepOrange300: "#D8553A",
  deepOrange400: "#B04123",
  deepOrange500: "#8B331A",
  deepOrange600: "#5E2111",
};

import { useMemo, useState } from "react";
import emptyIcon from "assets/images/el11.png";
import { useToastr } from "hooks";
import styles from "./CreateManufacturerOrProductionOrderModal.module.css";
import { Modal, Checkbox, StatusHandlerHelpers, StatusHandler, Label } from "components/utils";
import { Button } from "components/common";
import { createManufacturerOrder } from "api/production-orders/calls-manufacturer";
import cx from "classnames";
import { dateFns } from "utilities";
import { OrderIndex } from "api/orders/models";
import cuid from "cuid";
import { InferResult } from "typeUtilities";

interface Props {
  modal: {
    isOpen: boolean;
    open: (stateToSet: OrderIndex[]) => void;
    close: () => void;
    state: OrderIndex[] | null;
  };
  resetSelection?: () => void;
  addProductionOrders?: (payload: InferResult<typeof createManufacturerOrder>) => void;
}
type CheckType = "ALL" | "ZP" | "ZD";

export const CreateManufacturerOrProductionOrderModal = ({
  modal,
  resetSelection,
  addProductionOrders,
}: Props) => {
  const [checkedIndexes, setCheckedIndexes] = useState<string[]>([]);
  const initialSelected = {
    ALL: false,
    ZD: false,
    ZP: false,
  };
  const [areAllSelected, setAreAllSelected] = useState<Record<CheckType, boolean>>(initialSelected);
  const { state, close, isOpen } = modal;
  const toastr = useToastr();
  const indexes = useMemo(() => state?.map(index => ({ cuid: cuid(), ...index })), [state]);

  const checkAllIndexes = (type: CheckType) => {
    if (!indexes) return;
    if (areAllSelected[type]) {
      setCheckedIndexes([]);
      setAreAllSelected(initialSelected);
    } else {
      const notSentIndexes = indexes.filter(index => !index.isSent);
      const formattedIndexes = {
        ALL: notSentIndexes,
        ZP: notSentIndexes.filter(el => el.isSelfProduced),
        ZD: notSentIndexes.filter(el => !el.isSelfProduced && el.manufacturer),
      }[type];

      setCheckedIndexes(formattedIndexes.map(({ cuid }) => cuid));
      setAreAllSelected({ ...initialSelected, [type]: true });
    }
  };

  const toggleCheck = (cuid: string) => {
    if (!cuid) return;
    setAreAllSelected(initialSelected);
    const isChecked = checkedIndexes.includes(cuid);
    if (isChecked) {
      const newIndexList = checkedIndexes.filter(checkedIndexCuid => checkedIndexCuid !== cuid);
      setCheckedIndexes(newIndexList);
    } else {
      setCheckedIndexes([...checkedIndexes, cuid]);
    }
  };

  async function handleCreateManufacturerOrder(helpers: StatusHandlerHelpers) {
    if (!checkedIndexes.length) {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie...",
        text: "Nie można utworzyć pustego zlecenia. Wybierz produkt",
      });
      return;
    }
    helpers.startFetching();

    //manufacturer will be always here because we can't add index without manufacturer
    const items = indexes
      ?.map(({ cuid, id, indexId, isSelfProduced, manufacturer, orderId }) =>
        checkedIndexes.includes(cuid)
          ? {
              id,
              indexId,
              isSelfProduced,
              number: 1,
              manufacturer: manufacturer ? manufacturer.id : null,
              order: orderId,
            }
          : undefined,
      )
      .filter(Boolean) as {
      id: number;
      order: number;
      indexId: number;
      number: number;
      manufacturer: number | null;
      isSelfProduced: boolean;
    }[];

    const [payload, error] = await createManufacturerOrder({ items });
    helpers.stopFetching();
    if (payload) {
      if (addProductionOrders) {
        addProductionOrders(payload);
      }
      if (resetSelection) {
        resetSelection();
      }
      close();
      setCheckedIndexes([]);
      setAreAllSelected(initialSelected);
      toastr.open({
        type: "success",
        title: "Zlecenie zostało utworzone",
        text: "Produkty zostały zlecone dostawcy",
      });
    } else if (error) {
      toastr.open({
        type: "failure",
        title: "Oj, coś poszło nie tak",
        text: error.message || "Nie udało się utworzyć zlecenia",
      });
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      close={() => {
        close();
        setCheckedIndexes([]);
        setAreAllSelected(initialSelected);
      }}
      size={{ all: { width: "65vw" } }}
      overrides={{ container: { className: styles.modal } }}
    >
      {indexes?.some(el => !el.isSent) ? (
        <div>
          <h1 className={styles.title}>Utwórz zlecenia</h1>
          <div className="d-flex">
            <div className="d-flex align-items-center mb-3 ml-4">
              <Checkbox
                onChange={() => checkAllIndexes("ALL")}
                checked={areAllSelected["ALL"]}
                name="index"
                label="Zaznacz wszystkie"
              />
            </div>
            <div className="d-flex align-items-center mb-3 ml-4">
              <Checkbox
                onChange={() => checkAllIndexes("ZP")}
                checked={areAllSelected["ZP"]}
                name="index"
                label="Zaznacz ZP"
              />
            </div>
            <div className="d-flex align-items-center mb-3 ml-4">
              <Checkbox
                onChange={() => checkAllIndexes("ZD")}
                checked={areAllSelected["ZD"]}
                name="index"
                label="Zaznacz ZD"
              />
            </div>
            <small className="text-color-grey ml-3">(zaznaczono {checkedIndexes.length})</small>
          </div>

          <div className={cx(styles.indexesContainer, "border-top ")}>
            {indexes &&
              indexes.map(index => (
                <div
                  key={index.cuid}
                  className={cx(
                    { [styles.disabled]: index.isSent },
                    styles.container,
                    "border-bottom",
                  )}
                >
                  <div>
                    {index.isSent || (
                      <Checkbox
                        onChange={() => toggleCheck(index.cuid)}
                        checked={checkedIndexes.includes(index.cuid)}
                        name="index"
                      />
                    )}
                  </div>
                  <div className={styles.index}>
                    {index.name}
                    <div className={styles.attributesContainer}>
                      {index.attributes &&
                        index.attributes.map(({ attribute, value }, index) => (
                          <div className={styles.attribute} key={index}>
                            <span>{attribute}: </span>
                            <strong>{value}</strong>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className={styles.info}>
                    <div className={styles.signature}>{index.orderSignature}</div>
                    {(index.isSelfProduced || index.manufacturer) && <i className="divider"></i>}
                    {index.isSelfProduced ? (
                      <Label color="blueviolet" className="ml-1">
                        ZP
                      </Label>
                    ) : (
                      index.manufacturer && (
                        <Label color="blue" className="ml-1">
                          ZD
                        </Label>
                      )
                    )}
                    <i className="divider"></i>
                    <div className={styles.orderDate}>
                      {dateFns.format(new Date(index.created), "dd.MM.yyyy HH:mm")}
                    </div>
                  </div>
                  {index.manufacturer ? (
                    <div className={styles.manufacturer}>{index.manufacturer.name}</div>
                  ) : index.isSelfProduced ? (
                    <div className={styles.manufacturer}>Produkcja własna</div>
                  ) : (
                    <div className={styles.manufacturer}>Brak producenta</div>
                  )}
                </div>
              ))}
          </div>
          <div className="d-flex justify-content-end">
            <StatusHandler>
              {helpers => (
                <Button
                  kind="primary"
                  size="large"
                  onClick={() => handleCreateManufacturerOrder(helpers)}
                  disabled={helpers.isFetching}
                  className="mt-5 mb-4 mr-4"
                >
                  Utwórz zlecenia
                </Button>
              )}
            </StatusHandler>
          </div>
        </div>
      ) : (
        <div className="py-5">
          <div className="d-flex justify-content-center align-items-center w-100 flex-column mt-5">
            <img src={emptyIcon} alt="" />
            <h4 className="mt-5 mb-3">Wszystkie produkty zostały już zlecone.</h4>
          </div>
        </div>
      )}
    </Modal>
  );
};

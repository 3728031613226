import { useQuery } from "hooks";
import styles from "../RouteOrdersList.module.css";
import cx from "classnames";
import { useState } from "react";

interface FilterTagType {
  name: string;
  iconSrc?: string;
  label: string;
}

export const FilterSquareSingleSelect = ({ iconSrc, label, name }: FilterTagType) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  let initValue = false;
  if (String(query[name]).toLowerCase() === "true") initValue = true;
  const [value, setValue] = useState(initValue);

  return (
    <label className={cx("d-inline-flex", styles.tag, styles.tagFill)}>
      <input
        checked={value}
        onChange={() => {
          if (value === false) updateQuery({ [name]: !value, page: 1 });
        }}
        onClick={() => {
          if (value) {
            updateQuery({ [name]: "", page: 1 });
            setValue(false);
          } else {
            setValue(!value);
          }
        }}
        type="checkbox"
        className={styles.tagInput}
        value="tag"
      />
      <span className={styles.tagSelected}>
        {iconSrc && <img src={iconSrc} alt="" className="imgSX" />}
        {label}
      </span>
    </label>
  );
};

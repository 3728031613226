import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import indexesIcon from "assets/images/moduleNavigationIcons/chair.svg";
import { SoldIndexesRouting } from "routes/finances/SoldIndexes";
import { FilterList } from "components/common/moduleNavigation/components/filtersSection/filterList/FilterList";
import { FilterType } from "components/common/filters/types";

export const soldIndexes: ModuleLink = {
  url: "sold-indexes",
  label: "Sprzedane indeksy",
  icon: indexesIcon,
  subSection: (
    <FilterList
      filters={[
        {
          type: FilterType.Select,
          kind: "label",
          label: "Grupuj po",
          name: "time_grouping_variable",
          options: [
            { label: "dzień", value: "DAY" },
            { label: "tydzień", value: "WEEK" },
            { label: "miesiąc", value: "MONTH" },
          ],
        },
        {
          type: FilterType.DateRange,
          label: "okres",
          name: ["start_date", "end_date"],
        },
        {
          type: FilterType.Search,
          label: "konto sprzedażowe",
          // @ts-ignore
          name: "grouping_variable",
          searchBy: "salesAccounts",
        },
      ]}
    />
  ),
  routing: SoldIndexesRouting,
};

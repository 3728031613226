import { ManufacturingPlan } from "api/manufacturingNew/models";
import { RightPanelSection, RightPanelTableRow } from "components/utils/drawer";
import { ordersListConfig } from "../../utils/panelTablesConfig";
import { StateLabel } from "components/common/stateLabel";

export const Orders = ({ manufacturingPlan }: { manufacturingPlan: ManufacturingPlan }) => {
  return (
    <RightPanelSection title="Zamówienia (bez tras i grup)">
      <RightPanelTableRow hover={false} grid={ordersListConfig.grid}>
        <div>zamówienia</div>
        <div>% skompletowania</div>
      </RightPanelTableRow>

      {manufacturingPlan.collectionsSlotsSummary?.orders.map((order, index) => {
        return (
          <RightPanelTableRow key={order.signature} grid={ordersListConfig.grid}>
            <div className="fs-12 fw-700">{order.signature}</div>
            <div>
              <StateLabel
                className="fs-12 fw-700"
                kind={order.percentage === 100 ? "darkGreen" : "none"}
              >
                {order.percentage}%
              </StateLabel>
            </div>
          </RightPanelTableRow>
        );
      })}
    </RightPanelSection>
  );
};

import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { wmsLayoutKeys } from "./keys";
import { ApiFetcher } from "hooks/createApiQuery";
import {
  AssignField,
  FieldDetails,
  FillingStatus,
  Hall,
  PackageInLocation,
  RampStatus,
  RouteCollection,
  RouteCollectionDetails,
  SearchCollection,
} from "./models";

const getSearchCollections = (search: string = ""): ApiFetcher<Pagination<SearchCollection>> => ({
  key: wmsLayoutKeys.getSearchCollections(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/hall-schemas/search-collections" + search,
    }),
});

const getPackagesInLocation = (search: string = ""): ApiFetcher<Pagination<PackageInLocation>> => ({
  key: wmsLayoutKeys.getPackagesInLocation(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/hall-schemas/packages-in-location" + search,
    }),
});

const getLayoutSchema = (id: number): ApiFetcher<Hall> => ({
  key: wmsLayoutKeys.getLayoutSchema(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-halls/items/${id}`,
    }),
});

const getRouteSearchCollection = (id: number): ApiFetcher<RouteCollectionDetails> => ({
  key: wmsLayoutKeys.getRouteSearchCollection(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/hall-schemas/search-collections/routes/${id}`,
    }),
});

const getRampStatus = (hallId: number): ApiFetcher<RampStatus[]> => ({
  key: wmsLayoutKeys.getRampStatus(hallId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/hall-schemas/items/${hallId}/ramps-status`,
    }),
});

const getFillingStatus = (hallId: number): ApiFetcher<FillingStatus> => ({
  key: wmsLayoutKeys.getFillingStatus(hallId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/hall-schemas/items/${hallId}/filling-status`,
    }),
});

const getRouteLayoutCollection = ({
  hallId,
  routeId,
}: {
  hallId: number;
  routeId: number;
}): ApiFetcher<RouteCollection> => ({
  key: wmsLayoutKeys.getRouteLayoutCollection(hallId, routeId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/hall-schemas/items/${hallId}/collections/route/${routeId}`,
    }),
});

const getLayoutField = ({
  hallId,
  nodeId,
}: {
  hallId: number;
  nodeId: UUID;
}): ApiFetcher<FieldDetails> => ({
  key: wmsLayoutKeys.getLayoutField(hallId, nodeId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/hall-schemas/locations/${nodeId}`,
    }),
});

const patchField = (data: AssignField, id: UUID) =>
  queryFetch<FieldDetails>({
    method: "PATCH",
    url: "/wms/hall-schemas/locations/" + id,
    data,
  });

export const wmsLayoutApi = {
  getRouteSearchCollection,
  getLayoutSchema,
  getSearchCollections,
  getRampStatus,
  getRouteLayoutCollection,
  getFillingStatus,
  getLayoutField,
  getPackagesInLocation,
  patchField,
};

import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";

export const shippingKeys = {
  pieces: createPaginatedKey("shippingPieces"),
  sendToExternal: createPaginatedKey("sendToExternal"),
  couriers: {
    list: createPaginatedKey("shippingCouriers"),
    details: (id: number) => ["shippingCourier", id],
  },
  shipments: {
    list: createPaginatedKey("shippingShipments"),
    details: (id: UUID) => ["shippingShipment", id],
  },
};

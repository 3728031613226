import { Product } from "api/products/models";

function findByArrayOfValues(attribute: Product["attributes"][number], arrayOfIds: number[]) {
  if (attribute.kind !== "FABRIC") {
    return attribute.values.find(val => arrayOfIds.includes(val.id));
  }
  return attribute.categories.reduce(
    (
      acc: {
        id: number;
        picture: string | null;
        isAssignableToIndex: boolean;
        name: string;
      } | null,
      cat,
    ) => {
      const value = cat.values.find(val => arrayOfIds.includes(val.id));
      if (value) {
        acc = value;
      }
      return acc;
    },
    null,
  );
}

function findById(attribute: Product["attributes"][number], id: number) {
  if (attribute.kind !== "FABRIC") {
    return attribute.values.find(val => val.id === id);
  }
  return attribute.categories.reduce(
    (
      acc: {
        id: number;
        picture: string | null;
        isAssignableToIndex: boolean;
        name: string;
      } | null,
      cat,
    ) => {
      const value = cat.values.find(val => val.id === id);
      if (value) {
        acc = value;
      }
      return acc;
    },
    null,
  );
}

export const findSelectedAttributeValue = {
  byArrayOfValues: findByArrayOfValues,
  byId: findById,
};

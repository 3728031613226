import { LoadingDetails, statusDict } from "api/wms/models";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  loading: LoadingDetails;
}

export const TitleSection = ({ loading }: Props) => {
  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <div className="d-flex align-items-center gap-2">
        <Typography fontSize="26" fontWeight="700">
          {loading.signature}
        </Typography>
        <Tag
          startIcon={MdiPoint}
          label={statusDict[loading.status].label}
          variant={statusDict[loading.status].variant}
        />
        {loading.ramp && <Tag label={`Rampa ${loading.ramp?.name}`} variant="warning" />}
      </div>
    </RightPanelSection>
  );
};

import { DeadlineDayOptions } from "api/milo-settings/models";
import { dateFns } from "utilities";

export const isReadyToPost = (
  dayOption: string,
  dayOptions: Record<DeadlineDayOptions, string>,
  time: string,
): boolean => {
  if (time.length > 0 && Object.keys(dayOptions).find(dayOptionKey => dayOptionKey === dayOption)) {
    return false;
  }
  return true;
};

export const dayOptions: Record<DeadlineDayOptions, string> = {
  TODAY: "Dzisiaj",
  TOMORROW: "Jutro",
  AFTER_TOMORROW: "Pojutrze",
  NEAREST_MONDAY: "Następny poniedziałek",
  NEAREST_FRIDAY: "Następny piątek",
};

export const getIndicativeDate = (option: DeadlineDayOptions): string => {
  const today = new Date();
  const day = new Date(today);
  if (option === "TODAY") return dateFns.format(today, "dd-MM-yyyy");
  if (option === "TOMORROW") {
    day.setDate(today.getDate() + 1);
    return dateFns.format(day, "dd-MM-yyyy");
  }
  if (option === "AFTER_TOMORROW") {
    day.setDate(today.getDate() + 2);
    return dateFns.format(day, "dd-MM-yyyy");
  }
  if (option === "NEAREST_MONDAY") {
    const daysUntilMonday = today.getDay() === 1 ? 7 : (8 - today.getDay()) % 7;
    day.setDate(today.getDate() + daysUntilMonday);
    return dateFns.format(day, "dd-MM-yyyy");
  }
  if (option === "NEAREST_FRIDAY") {
    const daysUntilFriday = today.getDay() === 5 ? 7 : (12 - today.getDay()) % 7;
    day.setDate(today.getDate() + daysUntilFriday);
    return dateFns.format(day, "dd-MM-yyyy");
  }
  return "";
};

import { getAnyErrorKey } from "utilities";
import { useToastr } from "./useToastr";

interface ErrorResponse {
  status: string;
  error: Record<string, string>;
  httpStatus: number;
}

export const useErrorToastr = () => {
  const toastr = useToastr();

  const isResponseWarning = (httpStatus: number): boolean => {
    return httpStatus.toString().charAt(0) === "4";
  };

  return (response: ErrorResponse) => {
    return toastr.open({
      type: isResponseWarning(response.httpStatus) ? "warning" : "failure",
      title: isResponseWarning(response.httpStatus) ? "Wymagane działanie" : "Oj, coś nie tak.",
      text: getAnyErrorKey(response.error),
    });
  };
};

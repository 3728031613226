import { CustomModal } from "components/utils/customModal";
import { Formik, FormikHelpers } from "formik";
import styles from "./AddPurchaseInvoicePosition.module.css";
import cx from "classnames";
import { FormInput } from "components/utils";
import { getPurchasePositionInitialValues } from "../utils/getPurchasePositionInitialValues";
import { UUID } from "api/types";
import { Button } from "components/common";
import { validationSchema } from "../utils/validationSchema";
import { useMutation } from "hooks/useMutation";
import { AddPurchaseInvoicePosition as AddPurchaseInvoicePositionDTO } from "api/trading-documents/models";
import { postPurchaseInvoicePosition } from "api/trading-documents/calls";
import { getAnyErrorKey, queryString } from "utilities";
import { useQuery } from "hooks";
import { tradingDocumentsKeys } from "api/trading-documents/keys";

interface Props {
  close: () => void;
  maxDisplayPosition: number;
  tradingDocumentOrder: UUID | undefined;
}

export const AddPurchaseInvoicePosition = ({
  close,
  maxDisplayPosition,
  tradingDocumentOrder,
}: Props) => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const pageSize = { pageSize: 1 };
  const search = queryString.stringify({ ...query, ...pageSize });

  const createPositionMutation = useMutation(
    (formData: AddPurchaseInvoicePositionDTO) => postPurchaseInvoicePosition(formData),
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries(tradingDocumentsKeys.purchaseInvoicesToReview.list(search));
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Dodano pozycję do faktury zakupowej",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: AddPurchaseInvoicePositionDTO,
    actions: FormikHelpers<AddPurchaseInvoicePositionDTO>,
  ) => {
    createPositionMutation.mutate(values, {
      onSuccess: () => {
        actions.setSubmitting(false);
      },
      onError: error => {
        actions.setSubmitting(false);
        // @ts-ignore
        actions.setErrors(error);
      },
    });
  };

  if (tradingDocumentOrder === undefined) return null;

  return (
    <CustomModal
      close={close}
      isOpen
      overrides={{ container: { className: styles.modalContainer } }}
      title="Dodaj pozycję"
    >
      <Formik
        initialValues={getPurchasePositionInitialValues(
          String(tradingDocumentOrder),
          maxDisplayPosition,
        )}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="d-flex flex-column p-3">
              <div className="d-flex align-items-center w-100 gap-2">
                <div className={cx(styles.inputBox, "col-2")}>
                  <FormInput
                    label="Nr"
                    name="displayPosition"
                    overwrites={{ wrapper: { className: styles.input } }}
                    type="number"
                  />
                </div>
                <div className={cx(styles.inputBox, "col-10 pl-0")}>
                  <FormInput
                    autoFocus={true}
                    label="Nazwa"
                    name="name"
                    overwrites={{ wrapper: { className: styles.input } }}
                    type="string"
                  />
                </div>
              </div>
              <div className="d-flex align-items-center w-100">
                <div className={cx(styles.inputBox, "col-3")}>
                  <FormInput
                    label="Liczba"
                    name="quantity"
                    overwrites={{ wrapper: { className: styles.input } }}
                    type="number"
                  />
                </div>
                <div className={cx(styles.inputBox, "col-3")}>
                  <FormInput
                    label="jednostka"
                    name="unit"
                    overwrites={{ wrapper: { className: styles.input } }}
                    type="string"
                  />
                </div>
              </div>
              <div className="d-flex align-items-center w-100">
                <div className={cx(styles.inputBox, "col-4")}>
                  <FormInput
                    label="Cena netto"
                    name="amountWithoutTax"
                    overwrites={{ wrapper: { className: styles.input } }}
                    type="number"
                  />
                </div>
                <div className={cx(styles.inputBox, "col-4 d-flex align-items-center")}>
                  <FormInput
                    label="VAT"
                    name="vatRate"
                    overwrites={{ wrapper: { className: styles.input } }}
                    type="number"
                  />
                  <div className="d-flex align-items-center mb-3 ml-1">%</div>
                </div>
                <div className={cx(styles.inputBox, "col-4 d-flex align-items-center")}>
                  <FormInput
                    label="Rabat"
                    name="discount"
                    overwrites={{ wrapper: { className: styles.input } }}
                    type="number"
                  />
                </div>
              </div>
              <div className="d-flex align-items-center w-100">
                <div className={cx(styles.inputBox, "w-100 col-12")}>
                  <FormInput
                    label="Uwagi"
                    name="note"
                    overwrites={{ wrapper: { className: styles.input } }}
                    type="string"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button className="uppercase" kind="create-medium-transparent" onClick={close}>
                <div className="btnBaseMedium btnBase">Anuluj</div>
              </Button>
              <Button disabled={isSubmitting} kind="submit-medium" type="submit">
                <div className="btnBaseMedium btnBase">Dodaj{isSubmitting && "..."}</div>
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </CustomModal>
  );
};

export const turquoise = {
  turquoise12: "#5EE4F71F",
  turquoise25: "#DBFBFF",
  turquoise32: "#5EE4F752",
  turquoise50: "#B6F6FF",
  turquoise75: "#9EF1FC",
  turquoise100: "#53E3F8",
  turquoise200: "#33D2E9",
  turquoise300: "#26C0D7",
  turquoise400: "#17AEC4",
  turquoise500: "#0D98AC",
  turquoise600: "#007283",
};

import { createReduxReducer } from "utilities";
import { ThunkResult } from "./types";
import { success as partialsSuccess } from "./partials";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { UUID } from "api/types";
import { User as User_ } from "api/users/models";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";

export type ModuleName =
  | "ACCOUNT"
  | "SALES"
  | "MANUFACTURING"
  | "LOGISTICS"
  | "FINANCES"
  | "WMS"
  | "REPORTS"
  | "CRM"
  | "HUMAN_RESOURCES"
  | "INVENTORY"
  | "EXTERNAL_MANUFACTURING"
  | "SUPPORT"
  | "CALL_CENTER"
  | "EXTERNAL_MANUFACTURER_PANEL"
  | "RULES";

export interface UserModule {
  hasAccess: boolean;
  id: UUID;
  name: ModuleName;
  position: number;
  verboseName: string;
}

export interface User extends User_ {
  modules: UserModule[];
  startView: ModuleName;
  initialsBackgroundColor: string;
  initialsTextColor: ColorPalette;
}

export interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
}

export const login = createAction<User>("auth/login");
export const saveUser = createAction<User>("auth/saveUser");
export const resetStore = createAction("RESET_STORE");
export const updateAvatar = createAction<string>("auth/updateAvatar");

export const loginThunk = (data: User): ThunkResult<void> => dispatch => {
  dispatch(login(data));
};

export const resetStoreThunk = (): ThunkResult<void> => dispatch => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  dispatch(resetStore());
};

export const actions = { login: loginThunk, resetStore: resetStoreThunk, updateAvatar, saveUser };

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
};

export const reducer = createReduxReducer(initialState, {
  [login.toString()]: (state, action: PayloadAction<User>) => {
    state.user = action.payload;
    state.isAuthenticated = true;
  },
  [updateAvatar.toString()]: (state, action: PayloadAction<string>) => {
    state.user!.avatar = action.payload;
  },
  [saveUser.toString()]: (state, action: PayloadAction<User>) => {
    state.user = action.payload;
  },
  "partials/success": (state, action: ReturnType<typeof partialsSuccess>) => {
    return { ...state, user: { ...state.user, customer: action.payload.customer } } as AuthState;
  },
  [resetStore.toString()]: () => initialState,
});

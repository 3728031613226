import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { isPast, isYesterday, parseISO } from "date-fns";
import { dateFns } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  estimatedTimeOfSendingEmail: string | null;
  fontSize: TypographyProps["fontSize"];
  fontWeight: TypographyProps["fontWeight"];
}

export const EstimatedTimeOfSendingEmail = ({
  estimatedTimeOfSendingEmail,
  fontSize,
  fontWeight,
}: Props) => {
  if (!estimatedTimeOfSendingEmail) return <EmptyValue />;
  return (
    <Typography
      color={
        isPast(parseISO(estimatedTimeOfSendingEmail)) &&
        isYesterday(new Date(estimatedTimeOfSendingEmail))
          ? "danger400"
          : isPast(parseISO(estimatedTimeOfSendingEmail)) &&
            !isYesterday(new Date(estimatedTimeOfSendingEmail))
          ? "danger500"
          : "neutralBlack100"
      }
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      {estimatedTimeOfSendingEmail
        ? dateFns.formatRelative(new Date(estimatedTimeOfSendingEmail), "dd.MM.yyyy, H:mm")
        : "---"}
    </Typography>
  );
};

import { usePicking } from "api/wms/hooks";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { assertIsDefined } from "utilities/assertIsDefined";
import { PanelHeader } from "./components/PanelHeader";
import { TitleSection } from "./components/TitleSection";
import { GeneralInfoSection } from "./components/GeneralInfoSection";
import { ProgressSection } from "./components/ProgressSection";
import { LineItemsSection } from "./components/LineItemsSection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { close } = useDrawer("wmsPickings");
  const { panelId } = query;
  const { data: picking, error, isLoading } = usePicking(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(picking);

  return (
    <DrawerRightPanel className="position-relative" key={panelId}>
      <PanelHeader close={close} picking={picking} />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody>
          <TitleSection picking={picking} />
          <GeneralInfoSection picking={picking} />
          <ProgressSection picking={picking} />
          <LineItemsSection picking={picking} />
        </RightPanelBody>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};

import { LinkLinePackage } from "api/trading-documents/models";

export const getIsTotalPackagesAmountInvalid = (
  sumAmountWithoutTax: number,
  packages: LinkLinePackage[],
): boolean => {
  return (
    Number(sumAmountWithoutTax) <
    Number(
      packages
        .reduce((sum, _package) => {
          return sum + _package.quantity * _package.amount;
        }, 0)
        .toFixed(2),
    )
  );
};

import { CarrierKind, CreateCarrier, kindDict } from "api/logistics/carriers/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./AddCarrierModal.module.css";
import { Formik } from "formik";
import { cx } from "utilities";
import { FormInput, FormSelect } from "components/utils";
import { Button } from "components/miloDesignSystem/atoms/button";
import { validationSchema } from "./validationSchema";
import { usePostCarrier } from "../hooks/usePostCarrier";

interface Props {
  close: () => void;
}

const initialValues: CreateCarrier = {
  city: "",
  companyName: "",
  email: "",
  kind: CarrierKind.EXTERNAL,
  name: "",
  phone: "",
  postCode: "",
  street: "",
  taxId: "",
};

export const AddCarrierModal = ({ close }: Props) => {
  const handleSubmit = usePostCarrier(close);

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Utwórz przewoźnika
        </Typography>
      }
      width={480}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className={cx(styles.modalForm, "d-flex flex-column p-3")}>
              <FormInput label="Nazwa" name="name" placeholder="Nazwa" />
              <FormInput label="Nazwa firmy" name="companyName" placeholder="Nazwa firmy" />
              <FormSelect
                items={Object.entries(kindDict).map(([kind, { label }]) => ({
                  id: kind,
                  name: label,
                }))}
                itemToSelection={item => (item ? item.id : null)}
                label="Rodzaj"
                name="kind"
                placeholder="Wybierz rodzaj"
                width="full"
              />
              <FormInput label="E-mail" name="email" placeholder="E-mail" />
              <FormInput label="Nr telefonu" name="phone" placeholder="Nr telefonu" />
              <FormInput label="NIP" name="taxId" placeholder="NIP" />
              <FormInput label="Ulica" name="street" placeholder="Ulica" />
              <FormInput label="Kod pocztowy" name="postCode" placeholder="Kod pocztowy" />
              <FormInput label="Miasto" name="city" placeholder="Miasto" />
            </div>
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button isLoading={isSubmitting} size="medium" type="submit" variant="deepPurple">
                Dodaj{isSubmitting && "..."}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import cx from "classnames";
import { manufacturingEmployeesListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { AvatarWithName } from "components/common/avatarWithName";
import { StateLabel } from "components/common/stateLabel";
import { employedPlaceDict, ManufacturingEmployeeListItem } from "api/manufacturingNew/models";
import { employeeKindConfigDict, employmentTypeColorDict } from "CONSTANTS";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateFormatter } from "utilities";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  manufacturingEmployee: ManufacturingEmployeeListItem;
  // selectMutlipleItems: (
  //   event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  //   id: UUID | number,
  //   index: number,
  //   togglePanel: () => void,
  // ) => void;
  togglePanel: (id: UUID) => void;
  pageNumber: number;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  manufacturingEmployee,
  togglePanel,
  pageNumber,
}: Props) => {
  return (
    <>
      <ModuleRow
        className={cx("nowrap", {
          active: isActive(String(manufacturingEmployee.id)),
          highlighted:
            isHighlighted(String(manufacturingEmployee.id)) ||
            highlightedRows.some(row => row.id === manufacturingEmployee.id),
        })}
        gridStyle={manufacturingEmployeesListConfig.grid}
        onMouseDown={() => togglePanel(String(manufacturingEmployee.id))}
      >
        <Typography fontSize="12" fontWeight="700">
          {index + 1 + (pageNumber - 1) * 30}.
        </Typography>
        <div>
          <AvatarWithName user={manufacturingEmployee} />
        </div>
        <div>
          <StateLabel
            kind="default"
            style={{
              color: employeeKindConfigDict[manufacturingEmployee.kind].color,
              backgroundColor: employeeKindConfigDict[manufacturingEmployee.kind].backgroundColor,
            }}
            className="uppercase fw-700 narrowLetters border-0"
          >
            {employeeKindConfigDict[manufacturingEmployee.kind].name}
          </StateLabel>
        </div>

        <div>
          <StateLabel
            kind="default"
            style={{
              color: employmentTypeColorDict[manufacturingEmployee.employedPlace].color,
              backgroundColor:
                employmentTypeColorDict[manufacturingEmployee.employedPlace].backgroundColor,
            }}
            className="fw-700 narrowLetters "
          >
            {employedPlaceDict[manufacturingEmployee.employedPlace]}
          </StateLabel>
        </div>
        <div>
          {manufacturingEmployee.balance ? (
            <Typography fontSize="12" fontWeight="700">
              {manufacturingEmployee.balance} PLN
            </Typography>
          ) : (
            "---"
          )}
        </div>

        <div>
          {manufacturingEmployee.lastWithdrawal ? (
            <Typography fontSize="12" fontWeight="700">
              {manufacturingEmployee.lastWithdrawal.amount} PLN
            </Typography>
          ) : (
            "---"
          )}
        </div>

        <div>
          {manufacturingEmployee.lastWithdrawal ? (
            <Typography fontSize="12" fontWeight="700">
              {dateFormatter(manufacturingEmployee.lastWithdrawal.date)}
            </Typography>
          ) : (
            "---"
          )}
        </div>
      </ModuleRow>
    </>
  );
};

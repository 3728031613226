import styles from "./DisabledCover.module.css";

interface Props {
  active: boolean;
  children?: JSX.Element;
}
export const OpacityCover = ({ children, active = true }: Props) => {
  if (!active) return null;
  return <div className={styles.opacityCover}>{children}</div>;
};

import { LoadingDetails } from "api/wms/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateFns } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  loading: LoadingDetails;
}

export const LoadingDeparture = ({ loading }: Props) => {
  if (!loading.estimatedTimeOfDeparture) return <EmptyValue fontSize="14" fontWeight="500" />;
  return (
    <Typography fontSize="14" fontWeight="500">
      {loading.estimatedTimeOfDeparture
        ? dateFns.formatRelative(new Date(loading.estimatedTimeOfDeparture))
        : "---"}
    </Typography>
  );
};

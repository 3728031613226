import styles from "./LinkToPage.module.css";
import cx from "classnames";
import { Link } from "react-router-dom";
import { noop } from "utilities";

interface Props {
  content: string | React.ReactNode;
  key?: string | number;
  overrides?: {
    contentWrapper?: { className?: string };
    link?: { className?: string };
  };
  stopPropagation?: boolean;
  url: string;
}

export const LinkToPage = ({
  content,
  key,
  overrides = {},
  stopPropagation = false,
  url,
}: Props) => {
  return (
    <Link
      className={cx(styles.link, overrides.link?.className)}
      key={key ? key : url}
      onClick={e => (stopPropagation ? e.stopPropagation() : noop)}
      to={url}
    >
      <div className={cx("d-flex align-items-center", overrides.contentWrapper?.className)}>
        <div className="d-flex align-items-center">{content}</div>
      </div>
    </Link>
  );
};

import { PostInternalShipmentsPreview } from "api/shipping/models";
import { FailedResponse } from "components/miloDesignSystem/organisms/failedResponseModal/FailedResponseModal";
import { pluralize } from "utilities";

export const openShipmentCreationSummary = (
  payload: PostInternalShipmentsPreview,
  open: (stateToSet: FailedResponse) => void,
) => {
  const failedCount =
    payload.signaturesOrdersWithoutCountryCode.length +
    payload.signaturesOrdersWithShipments.length +
    payload.signaturesOrdersWithoutIndexes.length;
  const hasCreatedAnyShipment = Boolean(payload.success.length);
  const reasons = [];

  if (payload.signaturesOrdersWithoutIndexes.length) {
    reasons.push({
      message: `Brak przypisanych indeksów w ${
        payload.signaturesOrdersWithoutIndexes.length
      } ${pluralize.pl(payload.signaturesOrdersWithoutIndexes.length, {
        singular: "zamówieniu",
        plural: "zamówieniach",
        other: "zamówieniach",
      })}`,
      signatures: payload.signaturesOrdersWithoutIndexes,
    });
  }

  if (payload.signaturesOrdersWithoutCountryCode.length) {
    reasons.push({
      message: `Brak wybranego kraju w ${
        payload.signaturesOrdersWithoutCountryCode.length
      } ${pluralize.pl(payload.signaturesOrdersWithoutCountryCode.length, {
        singular: "zamówieniu",
        plural: "zamówieniach",
        other: "zamówieniach",
      })}`,
      signatures: payload.signaturesOrdersWithoutCountryCode,
    });
  }

  if (payload.signaturesOrdersWithShipments.length) {
    reasons.push({
      message: `${payload.signaturesOrdersWithShipments.length} ${pluralize.pl(
        payload.signaturesOrdersWithShipments.length,
        {
          singular: "zamówienie",
          plural: "zamówienia",
          other: "zamówień",
        },
      )} z nadanymi przesyłkami`,
      signatures: payload.signaturesOrdersWithShipments,
    });
  }

  open({
    success: hasCreatedAnyShipment
      ? {
          message: `Udało się utworzyć ${payload.success.length} ${pluralize.pl(
            payload.success.length,
            {
              singular: "przesyłkę",
              plural: "przesyłki",
              other: "przesyłek",
            },
          )}`,
        }
      : null,
    failed: failedCount
      ? {
          message: `Nie udało się utworzyć ${failedCount} ${pluralize.pl(failedCount, {
            singular: "przesyłki",
            plural: "przesyłek",
            other: "przesyłek",
          })}`,
          reasons,
        }
      : null,
  });
  return;
};

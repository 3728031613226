import { TrackingStatus } from "api/call-center/routes/models";
import { isWithinInterval, parse } from "date-fns";

export const compareDeliveryTimeWithPlannedDelivery = (
  calculatedMinDelivery: string,
  calculatedMaxDelivery: string,
  deliveredDate: string,
): TrackingStatus => {
  const maxTime = parse(calculatedMaxDelivery, "H:mm", new Date());
  const realTime = new Date(deliveredDate);

  const intervalStart = new Date(maxTime.getTime() - 15 * 60000);

  const interval = {
    start: intervalStart,
    end: maxTime,
  };

  if (realTime > maxTime) {
    return "OFF_TRACK";
  } else if (isWithinInterval(realTime, interval)) {
    return "AT_RISK";
  } else if (realTime >= parse(calculatedMinDelivery, "H:mm", new Date()) && realTime < maxTime) {
    return "ON_TRACK";
  } else {
    return "ON_TRACK";
  }
};

import { Button } from "components/common";
import styles from "./RightBarBtns.module.css";
import areaImg from "assets/images/208.svg";
import binImg from "assets/images/81g.svg";
import playImg from "assets/images/61.svg";
import { useRouteViewState } from "../../routeCreatorState";
import { MapMode } from "../../Creator";

interface Props {
  mode: MapMode;
  toggleMode: () => void;
  submitPolygon: () => void;
  resetPolygon: () => void;
  submitDisabled: boolean;
}

export const RightBarBtns = ({
  mode,
  toggleMode,
  submitPolygon,
  resetPolygon,
  submitDisabled,
}: Props) => {
  function handleReset() {
    resetPolygon();
    toggleMode();
  }
  const isLoading = useRouteViewState("slave", store => store.isLoading);

  return (
    <div className={styles.container}>
      {mode === "polygon" ? (
        <>
          <Button kind="secondary" size="round-m" onClick={handleReset} title="Usuń zaznaczenie">
            <img src={binImg} alt="" />
          </Button>
          <Button
            kind="secondary"
            size="round-m"
            onClick={submitPolygon}
            disabled={submitDisabled || isLoading}
            title="Dodaj zaznaczone punkty do trasy"
          >
            <img src={playImg} alt="" />
          </Button>
        </>
      ) : (
        <Button
          kind="secondary"
          size="round-m"
          onClick={toggleMode}
          title="Zaznacz obszar, z którego punkty zostaną dodane do trasy"
        >
          <img src={areaImg} alt="" />
        </Button>
      )}
    </div>
  );
};

import { PreviewCorrectionDocument } from "api/trading-documents/models";
import { pluralize } from "utilities";
import { CreateCorrectionValues } from "../CreateCorrectionManuallyModal";
import { WholeAmountToPay, getWholeAmount } from "./WholeAmountToPay";

interface Props {
  expectedPaymentForm: string;
  preview: PreviewCorrectionDocument;
  values: CreateCorrectionValues;
}

export const AdditionalPaymentInfoSection = ({ expectedPaymentForm, preview, values }: Props) => {
  const isRefund = getWholeAmount(values, "amountWithTax") < 0;

  return (
    <div className="d-flex justify-content-between pt-3">
      <div className="pt-1">
        <div>
          <div className="d-flex align-items-center gap-3 pb-2">
            <div>
              <div className="body-12 fw-700 text-black-6">Forma płatności</div>
              <div>{Boolean(expectedPaymentForm) ? expectedPaymentForm : "---"}</div>
            </div>
            <div>
              <div className="body-12 fw-700 text-black-6">Termin płatności</div>
              {preview.basePaymentDeadlineDelta ? (
                <div>
                  {preview.basePaymentDeadlineDelta}{" "}
                  {pluralize.pl(preview.basePaymentDeadlineDelta, {
                    singular: "dzień",
                    plural: "dni",
                    other: "dni",
                  })}
                </div>
              ) : (
                "---"
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex align-items-center justify-content-end gap-3">
          <div className="d-flex align-items-center justify-content-end body-12">
            {isRefund ? "pozostaje do zwrotu:" : "pozostaje do zapłaty:"}
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <strong className="body-14 fw-800">
              <WholeAmountToPay
                currency={preview.currency}
                fieldName="amountWithTax"
                isAbsolute
                values={values}
              />
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

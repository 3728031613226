import { DayType } from "../RouteTrackingModal";
import styles from "../RouteTrackingModal.module.css";
import cx from "classnames";
import { isDeliveredDateInCalculatedDeliveryRange } from "../utils/isDeliveredDateInCalculatedDeliveryRange";
import { StateLabel } from "components/common/stateLabel";

interface Props {
  dayType: DayType;
  deliveredDate: string | null;
  min: string;
  max: string;
}

export const CalculatedDeliveryTimeRange = ({ dayType, deliveredDate, min, max }: Props) => {
  if (
    dayType !== "FUTURE" &&
    deliveredDate &&
    isDeliveredDateInCalculatedDeliveryRange(max, deliveredDate)
  ) {
    return (
      <div className={cx("d-flex align-items-center fw-700", styles.range)}>
        <StateLabel className={styles.realDeliveryLabel} kind="darkGreen">
          {min} - {max}
        </StateLabel>
      </div>
    );
  }

  if (
    dayType !== "FUTURE" &&
    deliveredDate &&
    !isDeliveredDateInCalculatedDeliveryRange(max, deliveredDate)
  ) {
    return (
      <div className={cx("d-flex align-items-center fw-700", styles.range)}>
        <StateLabel className={styles.realDeliveryLabel} kind="bgRed4">
          {min} - {max}
        </StateLabel>
      </div>
    );
  }
  return (
    <div className={cx("d-flex align-items-center fs-12", styles.range)}>
      {min} - {max}
    </div>
  );
};

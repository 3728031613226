import {
  OwnerConfig,
  // OwnerConfigEntity
} from "./types";
import { config as defaultConfig } from "./default";
import { configMerger } from "./utils";
// import { DeepPartial } from "utility-types";
// import { deepMerge } from "utilities/deepMerge";
// import immer from "immer";

export const config: OwnerConfig = {
  main: {
    ...defaultConfig.main,
  },
  manufacturing: {
    ...defaultConfig.manufacturing,
  },
  externalManufacturing: {
    ...defaultConfig.externalManufacturing,
  },
  // @Example1:
  // main: immer(defaultConfig.main, draft => {
  //   draft.orderPanel.deliverySection._show = false;
  // }),
  // @Example2:
  // b2b: configMerger(defaultConfig.b2b, {
  //   orderPanel: { deliverySection: { manualDeliveryPoint: false } },
  // }),
  b2b: configMerger(defaultConfig.b2b, {
    orderPanel: {
      protocolPdf: true,
    },
  }),
  transport: {
    ...defaultConfig.transport,
  },
};

import styles from "./FilterNumber.module.css";
import { useQuery } from "hooks";
import { FilterNumberType } from "../../types";
import { useState } from "react";

export const FilterNumber = <T extends string>({ name, placeholder }: FilterNumberType<T>) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const [value, setValue] = useState(query[name]);

  const handleChange = (value: string) => {
    updateQuery({ [name]: value });
  };

  return (
    <div>
      <input
        className={styles.input}
        value={value}
        type="number"
        onChange={e => setValue(e.target.value)}
        placeholder={placeholder || "Szukaj"}
        onBlur={() => handleChange(value)}
      />
    </div>
  );
};

import { useState, useEffect } from "react";
import { ApiMiddlewareResult } from "apiConnectors/fetchConnector";
import { Select } from "components/utils";
import styles from "./EditableSelect.module.css";
import cx from "classnames";

interface Props<Item> {
  name: string;
  submitFunc: (data: { [x: string]: any }) => ApiMiddlewareResult<any>;
  initialValue?: string | number;
  selectedValue?: string | number;
  items: Item[];
  size?: "regular" | "small";
  itemToDisplay?: (item: Item, selectedItem: Item | null) => any;
  itemToDisplaySelected?: (item: Item | null) => any;
  width?: "auto" | "full";
  onSuccess?: (arg: any) => any;
  disabled?: boolean;
}

export function EditableSelect<Item extends { id?: any; name: string; [key: string]: any }>({
  itemToDisplaySelected,
  itemToDisplay,
  selectedValue,
  initialValue,
  submitFunc,
  onSuccess,
  disabled,
  items,
  width,
  size,
  name,
}: Props<Item>) {
  const [fetching, setFetching] = useState(false);

  const [error, setError] = useState<null | { [key: string]: any }>(null);

  useEffect(() => {
    if (fetching === true) {
      setError(null);
    }
  }, [fetching]);

  const handleSubmit = async (data: any) => {
    setFetching(true);
    const [payload, error] = await submitFunc(data);
    if (error) {
      setError(error);
    } else {
      if (onSuccess) {
        onSuccess(payload);
      }
    }
    setFetching(false);
  };

  return (
    <div className={cx(styles.inputBox, { "was-validated": Boolean(error) })}>
      <Select
        itemToDisplaySelected={itemToDisplaySelected}
        onChange={item => handleSubmit(item)}
        defaultSelected={initialValue}
        selectedItem={selectedValue}
        buttonClassName={disabled ? styles.disabled : styles.button}
        itemToDisplay={itemToDisplay}
        disabled={disabled}
        items={items}
        width={width}
        size={size}
      />
      <span className="invalid-feedback">{error && (error[name] || "Nie udało się zapisać")}</span>
    </div>
  );
}

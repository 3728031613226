import { confirmOrderCollectionDateByMiloUser, postOrderRouteSms_query } from "api/orders/calls";
import { Order } from "api/orders/models";
import { PostDeadline, RouteMessages } from "api/routes/models";
import awaitingImg from "assets/images/141o.svg";
import completeImg from "assets/images/701.svg";
import letterIcon from "assets/images/866.svg";
import errorIcon from "assets/images/877.svg";
import cx from "classnames";
import { Button } from "components/common/button";
import cuid from "cuid";
import { useQuery, useSelector, useSettings, useToggle } from "hooks";
import { useRouteSmsMessages } from "hooks/apiPrimitives";
import { useMutation } from "hooks/useMutation";
import { assertIsDefined } from "utilities/assertIsDefined";
import { SmsConfirmationDateInNotificationsModal } from "../confirmationDateModal/SmsConfirmationDateInNotificationsModal";
import styles from "./ConversationModal.module.css";
import { RejectOrder } from "./RejectOrder";

export const OrderConfirmation = ({ order }: { order: RouteMessages["orders"][number] }) => {
  const confirmMessage = order.messages.confirmationMessage;
  const { query } = useQuery();
  const { panelId } = query;
  const { handleMutate } = useRouteSmsMessages(panelId);
  const { transport } = useSettings();
  const hasDeadlineConfirmationDate = transport.hasDeadlineConfirmationDate;
  const options = transport.options;
  const sendSmsConfirmationDateModal = useToggle();
  const me = useSelector(state => state.auth.user!);

  const sendSmsMutation = useMutation(
    ({ values }: { values: { id: Order["id"]; deadline: PostDeadline | null } }) => {
      return postOrderRouteSms_query({ id: values.id, deadline: values.deadline });
    },
    {
      onMutate: () => {
        const rollback = handleMutate(draft => {
          const orderToUpdate = draft.orders.find(el => el.id === order.id);
          assertIsDefined(orderToUpdate);

          orderToUpdate.isDeliveryDateConfirmed = false;
          orderToUpdate.messages.confirmationMessage = {
            status: "AWAITING",
            body: "",
            failureReason: "",
            id: cuid(),
          };
        });
        return rollback;
      },
      onError: (error, _, rollback) => {
        //@ts-ignore
        rollback(error);
      },
    },
  );

  const confirmMutation = useMutation(confirmOrderCollectionDateByMiloUser, {
    onMutate: () => {
      return handleMutate(draft => {
        const orderToUpdate = draft.orders.find(el => el.id === order.id);
        assertIsDefined(orderToUpdate);
        orderToUpdate.deliveryDateConfirmedBy = me;
        orderToUpdate.isDeliveryDateConfirmed = true;
        orderToUpdate.deliveryDateConfirmation = String(new Date());
      });
    },
    onError: (error, _, rollback) => {
      //@ts-ignore
      rollback(error);
    },
  });

  if (
    confirmMessage?.failureReason &&
    (confirmMessage.status === "UNDELIVERED" || confirmMessage.status === "UNSENT")
  ) {
    return (
      <div className="d-flex align-items-center gap-2">
        <div className={cx(styles.statusContainer, styles.errorMsg)}>
          <img src={errorIcon} alt="" />
          Nie wysłano wiadomości
          <Button className={styles.errorBtn} onClick={() => confirmMutation.mutate(order.id)}>
            Potwierdź teraz
          </Button>
        </div>
        <RejectOrder order={order} />
      </div>
    );
  }

  if (!order.isDeliveryDateConfirmed && order.messages.confirmationMessage) {
    return (
      <div className="d-flex align-items-center gap-2">
        <div className={cx(styles.statusContainer, styles.awaiting)}>
          <img src={awaitingImg} alt="" />
          Oczekuje na potwierdzenie
          <Button className={styles.awaitingBtn} onClick={() => confirmMutation.mutate(order.id)}>
            Potwierdź teraz
          </Button>
        </div>
        <RejectOrder order={order} />
      </div>
    );
  }

  if (order.isDeliveryDateConfirmed) {
    return (
      <div className="d-flex align-items-center gap-2">
        <div className={cx(styles.statusContainer, styles.confirmed)}>
          <img src={completeImg} alt="" />
          Potwierdzono termin odbioru
        </div>
        <RejectOrder order={order} />
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center gap-2">
      <div className={cx(styles.statusContainer, styles.notSent)}>
        <img src={letterIcon} alt="" />
        Nie wysłano wiadomości
        <Button
          className={styles.awaitingBtn}
          onClick={() => {
            if (!hasDeadlineConfirmationDate) {
              sendSmsMutation.mutate({ values: { id: order.id, deadline: null } });
            } else {
              sendSmsConfirmationDateModal.open();
            }
          }}
        >
          Wyślij teraz
        </Button>
        <Button className={styles.awaitingBtn} onClick={() => confirmMutation.mutate(order.id)}>
          <div className="d-flex align-items-center">
            <div>Potwierdź&nbsp;</div>
            <div>teraz</div>
          </div>
        </Button>
        {sendSmsConfirmationDateModal.isOpen && (
          <SmsConfirmationDateInNotificationsModal
            close={sendSmsConfirmationDateModal.close}
            options={options}
            order={order}
            sendSmsMutation={sendSmsMutation}
          />
        )}
      </div>
      <RejectOrder order={order} />
    </div>
  );
};

import * as React from "react";
import Downshift, {
  StateChangeOptions,
  DownshiftState,
  ControllerStateAndHelpers,
} from "downshift";

interface Item {
  name: string;
  id: number | string;
}
interface Props {
  removeMode?: boolean;
  selectedItems?: Item[];
  initialItems?: Item[];
  onSelect?: (arg1: Item[] | null, arg2: ControllerStateAndHelpers<Item>) => void;
  onChange: (arg1: Item[], arg2?: ControllerStateAndHelpers<Item>) => void;
  children: (
    args: ControllerStateAndHelpers<Item> & { selectedItems: Item[]; getRemoveButtonProps: any },
  ) => React.ReactElement;
  itemToString?: (item: Item | null) => string;
  multiple?: boolean;
  onOuterClick: () => void;
}
interface State {
  selectedItems: Item[];
}

class MultiDownshift extends React.Component<Props, State> {
  state = { selectedItems: this.props.selectedItems || this.props.initialItems || [] };
  componentDidUpdate(oldProps: Props) {
    if (oldProps.selectedItems !== this.props.selectedItems) {
      if (this.props.selectedItems) {
        this.setState({ selectedItems: this.props.selectedItems });
      }
    }
  }
  stateReducer = (state: DownshiftState<Item>, changes: StateChangeOptions<Item>) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.changeInput:
        return {
          ...changes,
          isOpen: changes.inputValue && changes.inputValue.length > 0 ? true : false,
        };
      case Downshift.stateChangeTypes.keyDownEnter:
        return {
          ...changes,
          isOpen: false,
          inputValue: "",
        };
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: false,
          inputValue: "",
        };
      default:
        return changes;
    }
  };

  handleSelection = (selectedItem: Item | null, downshift: ControllerStateAndHelpers<Item>) => {
    const { onSelect, onChange, multiple } = this.props;
    const callOnChange = () => {
      const { selectedItems } = this.state;
      const newValue = multiple ? selectedItems : selectedItem ? [selectedItem] : [];
      if (onSelect) {
        onSelect(newValue, this.getStateAndHelpers(downshift));
      }
      if (onChange) {
        onChange(newValue, this.getStateAndHelpers(downshift));
      }
    };
    if (selectedItem) {
      if (this.state.selectedItems.some(el => el.id === selectedItem.id)) {
        this.removeItem(selectedItem);
      } else {
        if (multiple) {
          this.addSelectedItem(selectedItem, callOnChange);
        } else {
          this.replaceSelectedItem(selectedItem, callOnChange);
        }
      }
    }
  };

  removeItem = (item: Item) => {
    const { onChange } = this.props;
    if (this.props.removeMode) {
      this.setState(
        ({ selectedItems }) => {
          return {
            selectedItems: selectedItems.filter(el => el.id !== item.id),
          };
        },
        () => {
          onChange(this.state.selectedItems);
        },
      );
    }
  };

  addSelectedItem(item: Item, cb: () => void) {
    this.setState(
      ({ selectedItems }) => ({
        selectedItems: [...selectedItems, item],
      }),
      cb,
    );
  }
  replaceSelectedItem(item: Item, cb: () => void) {
    this.setState(
      ({ selectedItems }) => ({
        selectedItems: [item],
      }),
      cb,
    );
  }

  getRemoveButtonProps = ({
    onClick,
    item,
    ...props
  }: {
    item: Item;
    onClick: (arg: React.ChangeEvent<HTMLButtonElement>) => void;
  }) => {
    return {
      onClick: (e: React.ChangeEvent<HTMLButtonElement>) => {
        onClick && onClick(e);
        e.stopPropagation();
        this.removeItem(item);
      },
      ...props,
    };
  };

  getStateAndHelpers(downshift: ControllerStateAndHelpers<Item>) {
    const { selectedItems } = this.state;
    const { getRemoveButtonProps, removeItem } = this;
    return {
      getRemoveButtonProps,
      removeItem,
      selectedItems: this.props.selectedItems || selectedItems,
      ...downshift,
    };
  }

  render() {
    const { children, onSelect, ...props } = this.props;
    return (
      <Downshift
        {...props}
        stateReducer={this.stateReducer}
        onChange={this.handleSelection}
        selectedItem={null}
      >
        {downshift => children(this.getStateAndHelpers(downshift))}
      </Downshift>
    );
  }
}

export default MultiDownshift;

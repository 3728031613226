import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";

type ListObject = {
  id: UUID | number;
};

export const handleMultipleRowsHighlight = <T>(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  highlightedRows: HighlightedRow[],
  id: UUID | number,
  index: number,
  setHighlightedRows: React.Dispatch<React.SetStateAction<HighlightedRow[]>>,
  togglePanel: () => void,
  listObjects: T[],
) => {
  const shiftKeyPressed = event.shiftKey;
  const altKeyPressed = event.altKey;
  const listObjectsWithIndexes = ((listObjects as unknown) as ListObject[]).map((object, index) => {
    return {
      id: object.id,
      index: index,
    };
  });
  const firstObjectOfCurrentPage = listObjectsWithIndexes.find(object =>
    highlightedRows.some(row => row.id === object.id),
  );

  if (shiftKeyPressed && highlightedRows.length > 0 && firstObjectOfCurrentPage !== undefined) {
    const start = Math.min(index, firstObjectOfCurrentPage.index);
    const end = Math.max(index, firstObjectOfCurrentPage.index);
    const newHighlightedRows: HighlightedRow[] = [];

    for (let it = start; it <= end; it++) {
      const currentObjectId = ((listObjects[it] as unknown) as ListObject).id;
      const existingRow = highlightedRows.find(row => row.id === currentObjectId);
      if (existingRow) {
        newHighlightedRows.push(existingRow);
      } else {
        const tradingDocumentId = ((listObjects[it] as unknown) as ListObject).id;
        newHighlightedRows.push({ index: it, id: tradingDocumentId });
      }
    }

    // pick all rows selected before
    const currentlyHighlightedRows = highlightedRows.filter(
      row => !newHighlightedRows.includes(row),
    );

    setHighlightedRows([...newHighlightedRows, ...currentlyHighlightedRows]);
  } else if (altKeyPressed) {
    const newHighlightedRows = [...highlightedRows];

    const currentObjectId = ((listObjects[index] as unknown) as ListObject).id;
    const existingRow = newHighlightedRows.find(row => row.id === currentObjectId);

    if (existingRow) {
      setHighlightedRows(newHighlightedRows.filter(row => row.id !== existingRow.id));
    } else {
      newHighlightedRows.push({ index, id });
      setHighlightedRows(newHighlightedRows);
    }
  } else if (firstObjectOfCurrentPage === undefined && shiftKeyPressed) {
    togglePanel();
    setHighlightedRows(prev => [...prev, { index: index, id: id }]);
  } else {
    togglePanel();
    setHighlightedRows([{ index: index, id: id }]);
  }
};

import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { PanelHeader } from "pages/externalManufacturerPanel/shared/rightPanel/PanelHeader";
import { TitleSection } from "pages/externalManufacturerPanel/shared/rightPanel/TitleSection";
import { assertIsDefined } from "utilities/assertIsDefined";
import { GeneralInfoSection } from "./GeneralInfoSection";
import { LineItemsSection } from "./LineItemsSection";
import { NotificationsSection } from "pages/externalManufacturerPanel/shared/rightPanel/notificationsSection/NotificationsSection";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { useRef } from "react";

export const RightPanel = () => {
  const { query } = useQuery();
  const { close } = useDrawer("externalManufacturingOrders");
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const {
    data: manufacturingOrder,
    error,
    isLoading,
  } = externalManufacturingActions.useExternalManufacturingOrder(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(manufacturingOrder);

  return (
    <DrawerRightPanel className="position-relative" key={panelId}>
      <PanelHeader close={close} signature={manufacturingOrder.signature} />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody hasCommentsModule>
          <TitleSection name={manufacturingOrder.signature} />
          <GeneralInfoSection manufacturingOrder={manufacturingOrder} />
          <LineItemsSection id={manufacturingOrder.id} />
          <NotificationsSection manufacturingOrder={manufacturingOrder} />
        </RightPanelBody>
        <CommentsGenericSection
          outsideRef={clearCommentsRef}
          paramName="manufacturingOrder"
          endpointUrl={`/external-manufacturing/orders/items-comments`}
          commentedObject={manufacturingOrder}
        />
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};

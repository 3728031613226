import { createContext, useState, useCallback, useMemo, useContext, memo } from "react";
import * as React from "react";
import { Modal } from "./modal";
import { Button } from "components/common/buttonLegacy";

type ContextType = State & {
  close: () => void;
  open: (text: any, options?: Options | undefined) => void;
};

export const context = createContext<ContextType>({} as ContextType);

export interface Options {
  callback?: (...args: any) => void;
  btnText?: string;
}

type State = {
  isOpen: boolean;
  options: Options | null;
  text: "";
};

export const AlertModalController = memo(({ children }: any) => {
  const [state, setState] = useState<State>({
    isOpen: false,
    options: null,
    text: "",
  });
  const close = useCallback(() => setState(s => ({ ...s, isOpen: false })), []);
  const open = useCallback(
    (text, options?: Options) => setState({ options: options ?? null, text, isOpen: true }),
    [],
  );
  const value = useMemo(() => ({ ...state, close, open }), [state, open, close]);
  return (
    <context.Provider value={value}>
      {children}
      <Modal isOpen={state.isOpen} close={close} size="auto">
        {state.text ? (
          <div>
            {state.text}
            <div className="d-flex pt-2">
              <Button
                kind="primary"
                className="mr-1 ml-auto"
                onClick={() => {
                  state.options?.callback?.();
                  close();
                }}
              >
                {state.options?.btnText || "Zamknij"}
              </Button>
            </div>
          </div>
        ) : (
          <div />
        )}
      </Modal>
    </context.Provider>
  );
});

export const useAlertModal = () => {
  const modal = useContext(context);
  return {
    open: (text: React.ReactNode | ((arg: { close: () => void }) => void), options?: Options) => {
      if (typeof text === "function") {
        modal.open(text(modal), options);
      } else {
        modal.open(text, options);
      }
    },
    close: modal.close,
  };
};

import { Spinner } from "components/utils";
import styles from "./Creator.module.css";
import { useRouteViewState } from "./routeCreatorState";

export const Loader = () => {
  const loader = useRouteViewState("slave", state => state.loader);
  if (!loader) return null;
  return (
    <div className={styles.loader}>
      <Spinner color="blue" size="small" className="mr-3" />
      <div>{loader}</div>
    </div>
  );
};

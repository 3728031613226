import { Component } from "react";
import * as React from "react";
import styles from "./FileInput.module.css";
import xIcon from "assets/images/11.svg";
import fileIcon from "assets/images/280c.svg";
import previewIcon from "assets/images/53.svg";
import cx from "classnames";

type Error = {
  text: string;
  type: "size";
};

interface Props {
  initialValue?: string | File;
  value?: string | File;
  onChange: (arg: File | null) => void;
  onClick?: () => void;
  onError?: (err: Error) => void | Promise<void>;
  fileType: "image" | "any";
  name: string;
  fileSizeLimit?: number;
  overrides?: { image?: { className: string }; uploadBtn?: { className: string } };
  showName?: boolean;
  /**
   * Toggles the X button to clear image
   */
  clearable?: boolean;
}

interface State {
  file?: File | null;
  imagePreviewUrl?: string | null;
}

export class FileInput extends Component<Props, State> {
  inputFileRef = React.createRef<HTMLInputElement>();
  initializeState = () => {
    if (typeof this.props.value === "string") return this.props.value;
    if (typeof this.props.initialValue === "string") return this.props.initialValue;
    return undefined;
  };
  state: State = {
    file: undefined,
    imagePreviewUrl: this.initializeState(),
  };

  componentDidMount() {
    const { initialValue } = this.props;
    if (initialValue instanceof File) {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          file: initialValue,
          imagePreviewUrl: reader.result as string, // as string, because readAsDataURL does its job
        });
      };
      reader.readAsDataURL(initialValue as File);
    }
  }

  componentDidUpdate(oldProps: Props) {
    if (oldProps.value !== this.props.value) {
      this.setState({ imagePreviewUrl: this.props.value as string });
    }
  }

  trans = {
    uploadImage: "Dodaj grafikę",
    uploadFile: "Dodaj plik",
  };

  handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange, onError, fileSizeLimit } = this.props;
    const localFileSizeLimit = fileSizeLimit || 307200; //300kb
    e.preventDefault();
    let reader = new FileReader();

    let file = e.target.files && e.target.files[0];
    if (file) {
      if (file.size > localFileSizeLimit) {
        if (onError) {
          onError({
            text: `Rozmiar pliku nie może być większy niż ${localFileSizeLimit / 1024}kB`,
            type: "size",
          });
        }
        return;
      }
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result as string, // as string, because readAsDataURL does its job
        });
      };
      reader.readAsDataURL(file);
      if (onChange) {
        onChange(file);
      }
    }
  };

  showPreloadImage = () => {
    const { imagePreviewUrl } = this.state;
    let comp = null;
    if (imagePreviewUrl) {
      comp = <img className={styles.avatarThumb} src={imagePreviewUrl} alt="" />;
    }
    return comp;
  };

  checkIsImage = (fileName: State["imagePreviewUrl"]) => {
    if (typeof fileName !== "string") return false;
    const imageTypes = ["jpg", "jpeg", "png", "image"];
    return imageTypes.some(type => fileName.includes(type));
  };

  handleFileType = () => {
    const { file, imagePreviewUrl } = this.state;
    const { fileType } = this.props;
    if (
      fileType === "image" ||
      (file && this.checkIsImage(file.type)) ||
      this.checkIsImage(imagePreviewUrl)
    ) {
      return true;
    }
    return false;
  };

  handleDeleteFile = () => {
    this.setState({ file: null, imagePreviewUrl: null });
    const { onChange } = this.props;
    if (onChange) {
      onChange(null);
    }
    // the only way to clear input file
    if (this.inputFileRef.current) {
      this.inputFileRef.current.type = "";
      this.inputFileRef.current.type = "file";
    }
  };

  render() {
    const {
      name,
      fileType,
      onClick,
      clearable = true,
      showName = true,
      overrides = {},
    } = this.props;
    const { file, imagePreviewUrl } = this.state;
    const isImage = this.handleFileType();
    return (
      <div className={styles.container}>
        {imagePreviewUrl ? (
          <div className={styles.chip}>
            <div className={styles.chipContent}>
              {isImage && (
                <div className={cx(styles.chipImage, overrides?.image?.className)}>
                  {this.showPreloadImage()}
                </div>
              )}
              {showName && (
                <div className={styles.chipName}>
                  <span>{file ? file.name : imagePreviewUrl}</span>
                </div>
              )}
            </div>
            {clearable && (
              <button className={styles.deleteBtn} type="button" onClick={this.handleDeleteFile}>
                <img src={xIcon} alt="" />
              </button>
            )}
          </div>
        ) : (
          <div className={cx(styles.previewBox, overrides?.image?.className)}>
            <img src={previewIcon} alt="" />
          </div>
        )}
        <div className={styles.inputField} onClick={onClick}>
          <input
            name={name}
            type="file"
            onChange={this.handleImageChange}
            ref={this.inputFileRef}
          />
          <img src={isImage ? "uploadImageIcon" : "uploadFileIcon"} alt="" />
          <div className={cx(styles.uploadBtn, overrides?.uploadBtn?.className)}>
            <img src={fileIcon} alt="" />
            <span>{fileType === "image" ? this.trans.uploadImage : this.trans.uploadFile}</span>
          </div>
        </div>
      </div>
    );
  }
}

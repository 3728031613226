import { CallCenterRouteListItem, trackingStatusDict } from "api/call-center/routes/models";
import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import styles from "../../shared/CallCenter.module.css";
import cx from "classnames";
import timelineIcon from "assets/images/timeline.svg";
import unreadMessageIcon from "assets/images/markEmailUnread.svg";
import { callCenterRoutesListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { ColorLabel } from "components/common/colorLabel";
import { StateLabel } from "components/common/stateLabel";
import { PingLabel } from "components/common/pingLabel";
import { convertMinutesToHoursAndMinutes } from "utilities/convertMinutesToHoursAndMinutes";
import { useHistory } from "react-router";
import React from "react";
import { routePriorityDict } from "api/routes/models";
import { ResponsibleForRoute } from "./responsibleForRoute/ResponsibleForRoute";
import { convertDateToPolishMonthAbbreviations } from "utilities/convertDateToPolishMonthAbbreviations";
import { truncateDepartureTime } from "../utils/truncateDepartureTime";
import { Button } from "components/common";
import { useToggle } from "hooks";
import { RouteTrackingModal } from "../routeTracking/RouteTrackingModal";
import { isFromLastXMinutes } from "pages/callCenter/routeOrdersList/utils/isFromLastXMinutes";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  route: CallCenterRouteListItem;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID | number,
    index: number,
    togglePanel: () => void,
  ) => void;
  togglePanel: (id: string) => void;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  route,
  selectMutlipleItems,
  togglePanel,
}: Props) => {
  const history = useHistory();
  const routeTrackingModal = useToggle();

  return (
    <>
      <ModuleRow
        className={cx("user-select-text", {
          active: isActive(String(route.id)),
          highlighted:
            isHighlighted(String(route.id)) || highlightedRows.some(row => row.id === route.id),
          [styles.editedRow]: route.editedAt && isFromLastXMinutes(route.editedAt, 20),
        })}
        gridStyle={callCenterRoutesListConfig.grid}
        onMouseDown={event =>
          selectMutlipleItems(event, route.id, index, () => togglePanel(String(route.id)))
        }
        onClick={e => {
          if (
            (e as React.MouseEvent).button === 0 &&
            !(e as React.MouseEvent).shiftKey &&
            !(e as React.MouseEvent).altKey
          )
            history.push(`/call-center/route/${route.id}/orders`);
        }}
      >
        <div className="d-flex align-items-center gap-1">
          <ColorLabel
            color={
              route.priority === "MEDIUM"
                ? "#ee9e4b"
                : route.priority === "HIGH"
                ? "#e26b77"
                : route.priority === "LOW"
                ? "#e4b700"
                : "#808080"
            }
            height={12}
            width={12}
          />
          <div className="d-flex align-items-center">
            <span>{route.priority ? routePriorityDict[route.priority] : "-"}</span>
            <span>{route.priorityOrder ? route.priorityOrder : "-"}</span>
          </div>
        </div>
        <div className="d-flex align-items-center gap-1">
          <div className={styles.signature}>{route.signature}</div>
          {route.editedAt && isFromLastXMinutes(route.editedAt, 20) && (
            <StateLabel kind="indigo">edytowana</StateLabel>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between fw-700">
          <div className={cx(styles.overflow, "fw-700")}>{route.name ? route.name : "--"}</div>
          <Button
            kind="create-medium-transparent"
            onClick={e => {
              e.stopPropagation();
              routeTrackingModal.open();
            }}
            style={{ height: "26px" }}
          >
            <div className="btnBase btnBaseSmall">
              <img alt="" src={timelineIcon} style={{ height: "16px", width: "16px" }} />
              <div className={styles.routeTrackingBtn}>{String("Podgląd").toUpperCase()}</div>
            </div>
          </Button>
        </div>
        {route.departureDate || route.departureTime ? (
          <div className="fw-700">
            {route.departureTime ? <>{truncateDepartureTime(route.departureTime)}</> : <>--</>},{" "}
            {route.departureDate ? (
              <>{convertDateToPolishMonthAbbreviations(route.departureDate)}</>
            ) : (
              <>--</>
            )}
          </div>
        ) : (
          <div className="fw-700">--</div>
        )}
        {route.status === "DEPARTED" ? (
          <PingLabel kind={route.trackingStatus} label={trackingStatusDict[route.trackingStatus]} />
        ) : (
          <Typography fontSize="12" fontWeight="600">
            --
          </Typography>
        )}
        <div className="fw-400">
          {route.delay === 0 ? <>brak</> : <>{convertMinutesToHoursAndMinutes(route.delay)}</>}
        </div>
        <div className="fw-700">{route.registrationNumber ? route.registrationNumber : "--"}</div>
        <div className="fw-700">
          {route.driver ? (
            <>
              {route.driver.firstName} {route.driver.lastName}
            </>
          ) : (
            "--"
          )}
        </div>
        <div>
          {route.notReadMessages !== 0 && (
            <div className={cx("d-flex align-items-center gap-1")}>
              <img alt="Nieodczytane" className={styles.icon} src={unreadMessageIcon} />
              <span className="fw-700">{route.notReadMessages}</span>
            </div>
          )}
        </div>
        <ResponsibleForRoute route={route} />
      </ModuleRow>
      {routeTrackingModal.isOpen && (
        <RouteTrackingModal close={routeTrackingModal.close} route={route} />
      )}
    </>
  );
};

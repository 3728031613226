import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { ExecutionStatus } from "../../components/ExecutionStatus";
import { OrderLineItemListItem } from "api/external-manufacturing/models";

export const useManufacturerLineItemColumns = () =>
  useCreateTableColumns<OrderLineItemListItem>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.signature, {
          header: "sygnatura",
          size: 110,
        }),
        columnHelper.accessor(row => row, {
          header: " ",
          size: 70,
          cell: info => {
            const lineItem = info.getValue();
            if (lineItem.isNew && !lineItem.isCancelled) return <Tag label="nowe" variant="info" />;
            if (!lineItem.isCancelled) return <Tag label="anulowane" variant="warning" />;
            return null;
          },
        }),
        columnHelper.accessor(row => row.executionStatus, {
          header: "status",
          size: 210,
          cell: info => {
            const status = info.getValue();
            return <ExecutionStatus status={status} />;
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );

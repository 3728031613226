import { OrderProductInstance } from "api/orders/models";
import { isFabric } from "typePredicates";
import { PreselectedValues } from "..";

export function findPreselectedValues(
  product: OrderProductInstance | undefined,
): PreselectedValues {
  if (!product) return [];
  if (!product.product) return [];
  return (
    product.constantValues
      .map(preselectedValue => {
        const selectedAttribute = product.product!.attributes.find(attr => {
          return isFabric(attr)
            ? attr.categories.some(cat => cat.values.some(val => val.id === preselectedValue))
            : attr.values.some(val => val.id === preselectedValue);
        });
        if (!selectedAttribute) return undefined;
        return {
          ...selectedAttribute,
          value: isFabric(selectedAttribute)
            ? selectedAttribute.categories.reduce(
                (
                  acc: null | {
                    picture: string | null;
                    name: string;
                    id: number;
                  },
                  cat,
                ) => {
                  const value = cat.values.find(el => el.id === preselectedValue);
                  if (value) {
                    acc = value;
                  }
                  return acc;
                },
                null,
              )
            : selectedAttribute.values.find(el => el.id === preselectedValue),
        };
      })
      // filter, because we want to omit attributes that were removed from the product
      .filter(el => el && el.value) as PreselectedValues
  );
}

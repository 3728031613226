import { Route } from "components/utils";
import { Overview as WarehouseOverview } from "pages/wms/overview/Overview";
import { NotFound } from "pages/notFound";
import { RouteComponentProps, Switch } from "react-router-dom";

export const Overview = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route component={WarehouseOverview} exact={true} path={`${match.path}`} title="Milo - WMS" />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};

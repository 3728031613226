import { Component } from "react";
import styles from "./ErrorGate.module.css";
import cx from "classnames";
import { Button } from "components/common";
import errorImg from "assets/images/error.svg";
import { withRouter, RouteComponentProps } from "react-router";

class ErrorGateInner extends Component<RouteComponentProps> {
  state = {
    error: false,
  };
  static getDerivedStateFromError() {
    return { error: true };
  }

  render() {
    const { children, history } = this.props;
    if (this.state.error) {
      return (
        <div className={styles.wrapper}>
          <div
            className={cx(
              styles.content,
              "d-flex flex-column align-items-center justify-content-center",
            )}
          >
            <div>
              <img src={errorImg} alt="" />
            </div>
            <h1>Pojawił się błąd w aplikacji</h1>
            <h2>Jeśli problem się powtarza, spróbuj się wylogować i zalogować ponownie.</h2>
            <div className="d-flex mt-3">
              <Button
                type="button"
                kind="primary"
                size="medium"
                className="m-2"
                onClick={() => {
                  this.setState({ error: false });
                  history.push("/");
                }}
              >
                Wróć do Pulpitu
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return children;
  }
}
export const ErrorGate = withRouter(ErrorGateInner);

import { graphhopper } from "api/graphhopper/graphhopper";
import { Route } from "api/routes/models";
import { Button } from "components/common";
import { useToastr } from "hooks";
import { useRoutePutMutation } from "hooks/apiPrimitives";
import { useRouteViewState } from "../../routeCreatorState";
import { getFullRouteCoords, getOrdersPositionsBasedOnGraphhopper, getPoints } from "../../utils";

export const ReverseButton = ({ route }: { route: Route }) => {
  const updateMutation = useRoutePutMutation();
  const toastr = useToastr();
  const actions = useRouteViewState("slave", state => state.actions);
  const isLoading = useRouteViewState("slave", state => state.isLoading);

  const reversePointsOrder = async () => {
    actions.openLoader("Aktualizacja kolejności");
    const newOrdersPositions = [...route.ordersPositions].reverse();
    const startingPointLngLat = [route.startingPoint.point.lng, route.startingPoint.point.lat];

    const newPoints = getPoints(newOrdersPositions);

    const points = getFullRouteCoords(route, newPoints, startingPointLngLat);

    const payload = await graphhopper.route({
      points,
      vehicle: route.vehicleType,
      includeLastPointInOptimization: route.includeLastPointInOptimization,
    });

    if (payload) {
      const returnToStartingPointDistance = String(payload.points[newPoints.length].distance);
      const returnToStartingPointTime = String(payload.points[newPoints.length].time);

      const ordersPositions = getOrdersPositionsBasedOnGraphhopper(
        newOrdersPositions,
        payload.points,
      );

      updateMutation.mutate({
        data: {
          length: payload.distance,
          operation: null,
          ordersPositions,
          returnToStartingPointDistance,
          returnToStartingPointTime,
          shouldCalculateAverageSpeed: true,
        },
        route: route.id,
      });
    } else {
      actions.closeLoader();
      toastr.open({
        type: "failure",
        title: "Nie udało się przypiąć zamówienia",
        text: "",
      });
    }
  };

  if (route.orders.length < 2) return null;

  return (
    <Button
      disabled={updateMutation.isLoading || isLoading}
      kind="secondary-stroke"
      className="fs-10"
      size="small"
      onClick={reversePointsOrder}
    >
      <strong className="text-color-grey">zamień kolejność punktów</strong>
    </Button>
  );
};

import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import apiFetch, { tokenRefreshMiddleware } from "../../apiConnectors/fetchConnector";
import { Pagination } from "../types";
import { messagesKeys } from "./keys";
import { EmailMessage, SmsMessage, SmsMessageDetails } from "./models";

const api = tokenRefreshMiddleware(apiFetch);

export const getSmsMessages = (search: string = "") =>
  api<Pagination<SmsMessage>>({
    method: "GET",
    url: "/messages/sms/items" + search,
  });

export const getSmsMessagesQuery = (search: string = ""): ApiFetcher<Pagination<SmsMessage>> => ({
  fetcher: () =>
    queryFetch<Pagination<SmsMessage>>({
      method: "GET",
      url: "/messages/sms/items" + search,
    }),
  key: messagesKeys.smsMessages(search),
});

export const getSmsMessage = (id: string | number) =>
  api<SmsMessageDetails>({
    method: "GET",
    url: "/messages/sms/items/" + id,
  });

export const postSmsMessage = (data: {
  name: string;
  body: string;
  kind: string;
  salesAccount: number;
  isDefault: boolean;
}) =>
  api<{ message: string }>({
    method: "POST",
    url: "/messages/sms/items",
    data: { ...data, type: "SMS" },
  });

export const patchSmsMessage = (id: number | string, data: Partial<SmsMessageDetails>) =>
  api<Partial<SmsMessageDetails>>({
    method: "PATCH",
    url: "/messages/sms/items/" + id,
    data,
  });

export const getEmailMessagesForOrder = (
  search: string = "",
): ApiFetcher<Pagination<EmailMessage>> => ({
  key: messagesKeys.emailMessages(search),
  fetcher: () =>
    queryFetch<Pagination<EmailMessage>>({
      method: "GET",
      url: "/messages/email-messages/items" + search,
    }),
});

export const patchMessage = ({ id, data }: { id: string; data: { isRead: boolean } }) =>
  queryFetch({
    method: "PATCH",
    url: "/messenger/items/" + id,
    data,
  });

import styles from "../../Dashboard.module.css";
import cx from "classnames";
import { EmptyExpensesSection } from "./components/EmptyExpensesSection";

export const ExpensesSection = () => {
  return (
    <div className={cx(styles.card, "pb-2")}>
      <EmptyExpensesSection />
    </div>
  );
};

import { LoadingDetails, LoadingStatus } from "api/wms/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiPauseCircle } from "components/miloDesignSystem/atoms/icons/MdiPauseCircle";
import { MdiTimer } from "components/miloDesignSystem/atoms/icons/MdiTimer";
import { dateFns } from "utilities";
import { useLoadingPatchMutation } from "api/wms/hooks";
import { isAbleToStartBreak } from "pages/wms/shared/utils/isAbleToStartBreak";

interface Props {
  loading: LoadingDetails;
}

export const ProgressSection = ({ loading }: Props) => {
  const pauseMutation = useLoadingPatchMutation();

  return (
    <RightPanelSection className="mt-3">
      <div className="d-flex align-items-center w-100 gap-2">
        <div className="w-50 borderRight py-3">
          <InfoLabel
            title={
              <Typography fontSize="14" fontWeight="700">
                Postęp
              </Typography>
            }
          >
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="14" fontWeight="700">
                {loading.totalProgress}%
              </Typography>
              {!loading.startedAt && (
                <Typography color="danger600" fontSize="14" fontWeight="600">
                  Załadunek nierozpoczęty
                </Typography>
              )}
            </div>
          </InfoLabel>
          {/* <InfoLabel title="zeskanowano">
            <Typography fontSize="14" fontWeight="600">
              BACKEND
              TODO
              {loading.progress.onTheWay}%
            </Typography>
          </InfoLabel> */}
          {/* TODO: zlokalizowano? */}
        </div>
        <div className="w-50 pl-3 py-2">
          <InfoLabel
            title={
              <Typography fontSize="14" fontWeight="700">
                Pauza
              </Typography>
            }
          >
            {isAbleToStartBreak(loading.pauseStartedAt, loading.pauseEndedAt) ? (
              <Button
                className="text-uppercase"
                disabled={loading.status === LoadingStatus.NOT_STARTED || pauseMutation.isLoading}
                onClick={() =>
                  pauseMutation.mutate({
                    id: loading.id,
                    toUpdate: {
                      pauseStartedAt: dateFns.format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
                      pauseEndedAt: null,
                    },
                  })
                }
                size="small"
                startIcon={MdiPauseCircle}
                variant="gray"
              >
                Rozpocznij
              </Button>
            ) : (
              <Button
                className="text-uppercase"
                disabled={pauseMutation.isLoading}
                onClick={() =>
                  pauseMutation.mutate({
                    id: loading.id,
                    toUpdate: {
                      pauseEndedAt: dateFns.format(new Date(), "yyyy-MM-dd HH:mm:ss.SSSSSS"),
                    },
                  })
                }
                size="small"
                startIcon={MdiTimer}
                variant="gray"
              >
                Wznów pracę
              </Button>
            )}
          </InfoLabel>
          <InfoLabel title="start">
            <Typography fontSize="14" fontWeight="600">
              {loading.pauseStartedAt
                ? dateFns.format(new Date(loading.pauseStartedAt), "H:mm")
                : "---"}
            </Typography>
          </InfoLabel>
          <InfoLabel title="koniec">
            <Typography fontSize="14" fontWeight="600">
              {loading.pauseEndedAt
                ? dateFns.format(new Date(loading.pauseEndedAt), "H:mm")
                : loading.pauseStartedAt
                ? "trwa"
                : "---"}
            </Typography>
          </InfoLabel>
        </div>
      </div>
    </RightPanelSection>
  );
};

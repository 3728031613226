import styles from "./InfoLabel.module.css";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  className?: string;
  title: string | JSX.Element;
  titleStyle?: string;
}

export const InfoLabel = ({ children, className, title, titleStyle }: Props) => {
  return (
    <div className={cx(styles.container, className)}>
      {typeof title === "string" ? (
        <div className={cx(styles.title, titleStyle)}>{title}:</div>
      ) : (
        <div className={styles.title}>{title}</div>
      )}
      {children}
    </div>
  );
};

import styles from "./CustomSwitch.module.css";
import cx from "classnames";

interface Props {
  disabled?: boolean;
  onChange: (status: boolean) => void;
  checked?: boolean;
  label?: string;
  name: string;
  overrides?: {
    container?: { className: string };
    input?: { className: string };
    label?: { className: string };
    row?: { className: string };
  };
  style?: React.CSSProperties;
}

export const CustomSwitch = ({
  checked,
  label = "",
  onChange,
  name,
  disabled = false,
  overrides = {},
  style,
}: Props) => {
  return (
    <div
      className={cx(styles.container, overrides.container?.className, {
        [styles.disabled]: disabled,
      })}
      style={style}
    >
      <div className={cx(styles.row, overrides.row?.className)}>
        <div
          className={cx({
            [styles.checkMark]: true,
            [styles.activeMark]: checked,
            [styles.disabled]: disabled,
          })}
        />
        <input
          name={name}
          onChange={() => onChange(!checked)}
          type="checkbox"
          className={overrides.input?.className}
          disabled={disabled}
          checked={checked}
        />
        {label && (
          <label
            onClick={() => {
              if (!disabled) {
                onChange(!checked);
              }
            }}
            className={cx(styles.label, overrides.label?.className)}
          >
            {label}
          </label>
        )}
      </div>
    </div>
  );
};

import { Employee, ProductionPlan, SelectedSource } from "api/new-production-plans/models";
import localStyles from "./EmployeeRow.module.css";
import { EmployeeHeader } from "./subcomponents/employeeHeader/EmployeeHeader";
import { EmployeeWorkingDay } from "./subcomponents/employeeWorkingDay/EmployeeWorkingDay";

interface Props {
  employee: Employee;
  keyStrings: Record<string, string>;
  productionPlan: ProductionPlan;
  selectedSources: SelectedSource[];
}

export const EmployeeRow = ({ employee, keyStrings, productionPlan, selectedSources }: Props) => {
  return (
    <div className={localStyles.employeeWrapper}>
      <EmployeeHeader employee={employee} productionPlanId={productionPlan.id} />
      <div className={localStyles.dayCardWrapper}>
        {employee.employeeWorkingDays.map(employeeWorkingDay => (
          <EmployeeWorkingDay
            key={employeeWorkingDay.id}
            days={productionPlan.days}
            employeeId={employee.id}
            employeeWorkingDay={employeeWorkingDay}
            keyStrings={keyStrings}
            productionPlanId={productionPlan.id}
            selectedSources={selectedSources}
          />
        ))}
      </div>
    </div>
  );
};

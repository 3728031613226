import { Button } from "components/common";
import { Link, match } from "react-router-dom";
import styles from "../ModuleNavigation.module.css";
import cx from "classnames";
import miloIcon from "assets/images/logo1w.svg";
import pinIcon from "assets/images/pushPin.svg";
import verticalOptionsIcon from "assets/images/moreOptionsVertical.svg";
import {
  externalManufacturerModuleConfig,
  moduleConfig,
} from "components/common/moduleNavigation/moduleConfig";
import React from "react";
import {
  ExternalManufacturerModule,
  Module,
} from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { PopoverMenu } from "components/utils/Popover";
import { useMutation } from "hooks/useMutation";
import { setModuleAsStartView } from "api/profiles/calls";
import { getAnyErrorKey } from "utilities";
import { useSelector } from "hooks";
import { ModuleName } from "ducks/auth";
import { FLAVOR } from "CONSTANTS";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiMenu } from "components/miloDesignSystem/atoms/icons/MdiMenu";

interface Props {
  currentModule: Module | ExternalManufacturerModule | undefined;
  currentModuleName: ModuleName | undefined;
  module: string;
  shouldBeOpen: boolean;
  isOpen: boolean;
  toggleOpen: () => void;
  isDashboard: match<{}> | null;
  isWarehouseSchema: match<{}> | null;
  setIsSubSectionOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NavigationHeader = ({
  currentModule,
  currentModuleName,
  setIsSubSectionOpened,
  isDashboard,
  toggleOpen,
  isOpen,
  shouldBeOpen,
  isWarehouseSchema,
}: Props) => {
  const me = useSelector(store => store.auth.user!);
  const isMain = FLAVOR === "main";
  const isExternalManufacturerPanel = FLAVOR === "externalManufacturing";
  const manufacturer = useSelector(store => store.externalManufacturerPartials.manufacturer);

  const currentModuleId = me.modules.find(module => {
    if (module.name === currentModuleName) {
      return module.id;
    }
    return undefined;
  })?.id;

  const setModuleAsStartViewMutation = useMutation(setModuleAsStartView, ({ toastr }) => ({
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
    onSuccess: () => {
      toastr.open({
        type: "success",
        title: "Udało się!",
        text:
          "Zapisano moduł jako początkowy. Po ponownym zalogowaniu zobaczysz ten moduł jako startowy.",
      });
    },
  }));

  return (
    <div className={styles.navLeftHeader}>
      <div className={styles.logoSection}>
        {isExternalManufacturerPanel ? (
          <Link className={styles.logo} to="/external-manufacturer-panel/manufacturer-line-items">
            <img alt="Milo" src={miloIcon} />
          </Link>
        ) : (
          <Link className={styles.logo} to="/">
            <img alt="Milo" src={miloIcon} />
          </Link>
        )}
        {shouldBeOpen && !isOpen && <div />}
        <div>
          <IconButton
            icon={<MdiMenu color="neutralWhite100" size="16" />}
            onClick={() => {
              toggleOpen();
              if (shouldBeOpen) {
                setIsSubSectionOpened(false);
              } else if (!shouldBeOpen && isDashboard && isWarehouseSchema) {
                setIsSubSectionOpened(false);
              } else {
                setIsSubSectionOpened(true);
              }
            }}
            theme="dark"
            variant="transparent"
          />
        </div>
      </div>
      {currentModule && (
        <div
          className={cx(styles.moduleSection, {
            "d-flex align-items-center justify-content-between": isOpen,
          })}
          style={{
            backgroundColor: isMain
              ? moduleConfig[currentModule as Module].labelBackground
              : externalManufacturerModuleConfig[currentModule as ExternalManufacturerModule]
                  .labelBackground,
          }}
        >
          <div className={styles.moduleIconTitle}>
            {!isExternalManufacturerPanel && (
              <div
                className={styles.moduleIcon}
                style={{
                  backgroundColor: moduleConfig[currentModule as Module].iconBackground,
                }}
              >
                <img alt="Ikona modułu" src={moduleConfig[currentModule as Module].icon!} />
              </div>
            )}
            <div
              className={cx(styles.moduleName, {
                "ml-2": isExternalManufacturerPanel,
              })}
            >
              {isMain
                ? moduleConfig[currentModule as Module].label
                : isExternalManufacturerPanel && Boolean(manufacturer.name.length)
                ? manufacturer.name
                : externalManufacturerModuleConfig[currentModule as ExternalManufacturerModule]
                    .label}
            </div>
          </div>
          {isOpen && isMain && (
            <PopoverMenu
              placement="bottom-end"
              target={btnProps => (
                <div role="button" {...btnProps}>
                  <Button kind="transparent-white" size="square-s">
                    <img alt="Moduł początkowy" src={verticalOptionsIcon} />
                  </Button>
                </div>
              )}
            >
              {({ close }) => (
                <div className={styles.startViewDropdown}>
                  <div className="d-flex align-items-center">
                    <Button
                      className={cx(styles.startViewBtn, "d-flex justify-content-start")}
                      kind="secondary-grey"
                      onClick={() => {
                        if (currentModuleId) {
                          setModuleAsStartViewMutation.mutate({ startViewId: currentModuleId });
                          close();
                        }
                      }}
                      size="small"
                    >
                      <div className="d-flex align-items-center gap-1">
                        <img
                          alt="Pinezka"
                          src={pinIcon}
                          style={{ height: "20px", width: "20px" }}
                        />
                        <div>Ustaw jako początkowy widok</div>
                      </div>
                    </Button>
                  </div>
                </div>
              )}
            </PopoverMenu>
          )}
        </div>
      )}
    </div>
  );
};

import { createPaginatedKey } from "api/keys";
import { SourceType } from "./models";

export const warehouseKeys = {
  warehouse: {
    details: (id: string) => ["warehouse", id],
    list: createPaginatedKey("warehouses"),
  },
  hall: {
    details: (id: string) => ["hall", id],
    list: createPaginatedKey("halls"),
  },
  level: {
    details: (id: string) => ["level", id],
    list: createPaginatedKey("levels"),
  },
  cellsFillingStatus: () => ["cellsFillingStatus"],
  cells: {
    details: (id: string) => ["cell", id],
    list: createPaginatedKey("cells"),
  },
  column: {
    details: (id: string) => ["column", id],
    list: createPaginatedKey("columns"),
  },
  packageDemandsForItem: (id: number) => ["packageDemandsForItem", id],
  packagesFromSource: (id: string, sourceType: SourceType) => [
    "packagesFromSource",
    id,
    sourceType,
  ],
  row: {
    details: (id: string) => ["row", id],
    list: createPaginatedKey("rows"),
  },
  whEntriesOrders: (id: string, sourceType: SourceType) => ["whEntriesOrders", id, sourceType],
  whEntriesOrderPackages: (sourceId: string, orderId: string, sourceType: SourceType) => [
    "routeOrderPackages",
    sourceId,
    orderId,
    sourceType,
  ],
  whEntriesReturnedPackages: (id: string, sourceType: SourceType) => [
    "routeReturnedPackages",
    id,
    sourceType,
  ],
};

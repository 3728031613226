import { Car } from "api/cars/models";
import { Carrier } from "../carriers/models";
import { UserWithInitials } from "api/users/models";
import { UUID } from "api/types";
import { Order } from "api/orders/models";

export interface CarrierOrderRoute {
  cargoVolume: string;
  cargoWeight: string;
  deliveryCost: string;
  id: UUID;
  signature: string;
}

export enum CarrierOrderPaymentMethod {
  CASH = "CASH",
  ONLINE = "ONLINE",
}

export interface CarrierOrder {
  car: Pick<Car, "id" | "registrationNumber">;
  carrier: Pick<Carrier, "id" | "city" | "companyName" | "postCode" | "street">;
  counter: number;
  createdAt: string;
  createdBy: UserWithInitials;
  drivers: Pick<UserWithInitials, "id" | "firstName" | "lastName">[];
  id: UUID;
  orders: Pick<Order, "id" | "signature">[];
  paymentMethod: CarrierOrderPaymentMethod | "";
  route: CarrierOrderRoute;
  signatureUnderDocument: string;
  warehouse: number;
}

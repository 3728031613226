import React from "react";
import {
  useMenuState,
  Menu as BaseMenu,
  MenuItem as BaseMenuItem,
  MenuButton,
  MenuStateReturn,
} from "reakit/Menu";
import { Typography } from "../typography";
import rightArrowIcon from "assets/images/chevronRight.svg";
import styles from "./Menu.module.css";
import cx from "classnames";
import { MenuItem, MenuItemType } from "./types";
import { highlightTextFragment } from "utilities/highlightTextFragment";
import { noop } from "utilities";

interface Props {
  disclosure: JSX.Element;
  menuItems: MenuItem[];
}

export const Menu = React.forwardRef(({ disclosure, menuItems, ...props }: Props, ref) => {
  const menu = useMenuState();

  return (
    <>
      <MenuButton ref={ref} {...menu} {...props} {...disclosure.props}>
        {disclosureProps => React.cloneElement(disclosure, disclosureProps)}
      </MenuButton>
      <MenuList menuItems={menuItems} menuState={menu} />
    </>
  );
});

export const MenuList = ({
  menuItems,
  menuState,
  hideOnClickOutside = true,
  className,
  searchValue,
}: {
  menuItems: MenuItem[];
  menuState: MenuStateReturn;
  hideOnClickOutside?: boolean;
  className?: string;
  searchValue?: string;
}) => {
  return (
    <BaseMenu
      {...menuState}
      hideOnClickOutside={hideOnClickOutside}
      className={cx(styles.dropMenu, className)}
    >
      {menuItems.length ? (
        menuItems.map((menuItem, index) => {
          const itemComponent = getMenuComponent(menuItem, searchValue);
          return (
            <BaseMenuItem {...menuState} {...itemComponent.props} key={index}>
              {itemProps => React.cloneElement(itemComponent, itemProps)}
            </BaseMenuItem>
          );
        })
      ) : (
        <div className={styles.dropDownOption}>
          <Typography fontSize="14" fontWeight="700">
            Brak wyników
          </Typography>
        </div>
      )}
    </BaseMenu>
  );
};

const getMenuComponent = (menuItem: MenuItem, searchValue?: string) => {
  switch (menuItem.type) {
    case MenuItemType.AVATAR:
    case MenuItemType.TEXT:
      const Icon = menuItem.options?.iconStart;
      return (
        <div
          className={cx(styles.dropDownOption, menuItem?.options?.className, {
            [styles.disabled]: menuItem.options && menuItem.options.disabled,
          })}
          onClick={menuItem.options && !menuItem.options.disabled ? menuItem.onClick : noop}
          onKeyDown={event => {
            switch (event.key) {
              case "Enter":
                event.preventDefault();
                menuItem.onClick();
                return;
            }
          }}
        >
          {menuItem.type === MenuItemType.AVATAR ? (
            <img alt="" src={menuItem.avatar} style={{ height: "16px", width: "16px" }} />
          ) : Icon && typeof Icon === "function" ? (
            <Icon color={menuItem?.options?.color} size="16" />
          ) : null}
          <Typography color={menuItem.options?.color} fontSize="14" fontWeight="700">
            {highlightTextFragment(searchValue, menuItem.text, menuItem.text)}
          </Typography>
          {menuItem?.options?.buttonEnd}
        </div>
      );
    case MenuItemType.SECTION:
      return (
        <div className={styles.section}>
          {menuItem.title && (
            <div className={styles.header}>
              <Typography
                className={styles.headerTypography}
                color="neutralBlack48"
                fontSize="12"
                fontWeight="700"
              >
                {menuItem.title}
              </Typography>
            </div>
          )}
          <Menu
            disclosure={
              <div className={cx(styles.dropDownOption, styles.sectionDisclosure)}>
                <Typography fontSize="14" fontWeight="700">
                  {menuItem.text}
                </Typography>
                <img alt="submenu" src={rightArrowIcon} style={{ height: "16px", width: "16px" }} />
              </div>
            }
            menuItems={menuItem.menuItems}
          />
        </div>
      );
    // case MenuItemKind.CHECKBOX:
    //   return <div></div>;
    // case MenuItemKind.RADIO:
    //   return <div></div>;
    // case MenuItemKind.SWITCH:
    //   return <div></div>;
  }
};

import { useWarehouseWorker } from "api/wms/hooks";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useEffect, useState } from "react";
import { assertIsDefined } from "utilities/assertIsDefined";
import { PanelHeader } from "./components/panelHeader/PanelHeader";
import { GeneralInfoSection } from "./components/GeneralInfoSection";
import { ReceivedAndReleasedPackagesPerDay } from "./components/receivedAndReleasedPackagesPerDay/ReceivedAndReleasedPackagesPerDay";
import { ReleasedPerCategory } from "./components/releasedPerCategory/ReleasedPerCategory";
import { ReceivedPerCategory } from "./components/receivedPerCategory/ReceivedPerCategory";
import { ReceivedAndReleasedPackagesPerMonth } from "./components/receivedAndReleasedPackagesPerMonth/ReceivedAndReleasedPackagesPerMonth";

export const RightPanel = () => {
  const { query, setQuery } = useQuery();
  const { panelId } = query;
  const close = () => setQuery({ ...(query ?? {}), panelId: "" });

  const [isScrolledOver, setIsScrolledOver] = useState(false);

  const { data: warehouseWorker, error, isLoading } = useWarehouseWorker(panelId, {
    enabled: Boolean(panelId),
  });

  useEffect(() => {
    setIsScrolledOver(false);
  }, [panelId]);

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(warehouseWorker);

  return (
    <DrawerRightPanel className="position-relative" key={panelId}>
      <PanelHeader
        close={close}
        isScrolledOver={isScrolledOver}
        warehouseWorker={warehouseWorker}
      />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody setIsScrolledOver={setIsScrolledOver}>
          <GeneralInfoSection warehouseWorker={warehouseWorker} />
          <ReceivedAndReleasedPackagesPerDay warehouseWorker={warehouseWorker} />
          <ReceivedPerCategory warehouseWorker={warehouseWorker} />
          <ReleasedPerCategory warehouseWorker={warehouseWorker} />
          <ReceivedAndReleasedPackagesPerMonth warehouseWorker={warehouseWorker} />
        </RightPanelBody>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};

import { Table } from "@tanstack/react-table";
import { useRef } from "react";
import { useIsInitializing } from "./utils";
import { useTableFillWidth } from "./useTableFillWidth";
import { TableProps } from "../../types";

export const useInitializeTable = <T>(table: Table<T>, rows: TableProps<T>["rows"]) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isInitializing, setIsInitializing } = useIsInitializing();

  useTableFillWidth(table, containerRef, setIsInitializing, rows);

  return {
    containerRef,
    isInitializing,
  };
};

import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./AddRampModal.module.css";
import { Formik } from "formik";
import { cx } from "utilities";
import { FormInput } from "components/utils";
import { Button } from "components/miloDesignSystem/atoms/button";
import { validationSchema } from "./validationSchema";
import { wmsRampActions } from "api/wms/ramps/actions";
import { CreateRamp } from "api/wms/ramps/models";

interface Props {
  close: () => void;
}

const initialValues: CreateRamp = {
  code: "",
  hall: "",
  name: "",
};

export const AddRampModal = ({ close }: Props) => {
  const handleSubmit = wmsRampActions.usePostRamp(close);

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Utwórz rampę
        </Typography>
      }
      width={480}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className={cx(styles.modalForm, "d-flex flex-column p-3")}>
              <FormInput label="Nazwa" name="name" placeholder="Nazwa" />
              <FormInput label="Kod rampy" name="code" placeholder="Kod rampy" />
              <FormInput label="Hala" name="hall" placeholder="Hala" />
            </div>
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Dodaj{isSubmitting && "..."}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

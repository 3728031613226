import { queryString } from "utilities";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
};

export const getSearch = ({ query }: GetSearchProps): string => {
  return queryString.stringify({
    ...query,
  });
};

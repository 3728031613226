import { useQuery } from "hooks";
import styles from "./PrintingStatusFilter.module.css";
import cx from "classnames";
import { ColorLabel } from "components/common/colorLabel";

type Option = {
  label: string;
  value: string;
  color: string;
};

export const PrintingStatusFilter = () => {
  const options: Option[] = [
    {
      label: "gotowy do druku",
      value: "NOT_STARTED",
      color: "#6de5b9",
    },
    {
      label: "drukuje się",
      value: "IN_PROGRESS",
      color: "#8c73ff",
    },
    {
      label: "wydrukowano",
      value: "FINISHED",
      color: "#4696ec",
    },
    {
      label: "niepowodzenie",
      value: "FAILED",
      color: "#f08f6d",
    },
  ];
  const name = "printingStatus";
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const value: Option["value"] = query[name];

  const handleChange = (optionValue: Option["value"] | null) => {
    if (optionValue) {
      const values = (() => {
        const appliedFilters = value?.split(",");
        if (appliedFilters?.includes(optionValue)) {
          return appliedFilters?.filter(appliedValue => appliedValue !== optionValue).join(",");
        }

        return [...(appliedFilters || []), optionValue].join(",");
      })();

      updateQuery({ [name]: values, page: 1 });
      return;
    }

    updateQuery({ [name]: "", page: 1 });
  };

  return (
    <div className="d-flex align-items-center gap-2">
      {options.map(option => {
        const checked = value === option.value || value?.includes(option.value);
        return (
          <label className={cx("d-inline-flex", styles.tag, styles.tagFill)} key={option.value}>
            <input
              checked={checked}
              className={styles.tagInput}
              name="printingStatus"
              onChange={() => handleChange(option.value)}
              onClick={() => {
                if (checked) {
                  handleChange(null);
                }
              }}
              type="checkbox"
              value={option.value}
            />
            <span className={styles.tagSelected}>
              <ColorLabel color={option.color} height={12} width={12} />
              {option.label}
            </span>
          </label>
        );
      })}
    </div>
  );
};

import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { PackagesList } from "pages/wms/packagesList/PackagesList";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const Packages = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={PackagesList}
        exact={true}
        path={`${match.path}/list/:tab`}
        title="Milo - WMS - paczki"
      />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};

import { RouteTrackingDay } from "api/call-center/routes/models";
import { RouteTrackingFilters } from "../RouteTrackingModal";

export const getDaysBasedOnFilters = (days: RouteTrackingDay[], filters: RouteTrackingFilters) => {
  if (Object.values(filters).every(filter => !filter)) return days;

  return days.map(day => {
    return {
      ...day,
      positions: day.positions.filter(position => {
        if (!filters.search) return true;

        if (
          `${position.address.city.trim()} ${
            position.address.postCode
          } ${position.address.street.trim()} ${position.phone} ${position.signature}`
            .toLowerCase()
            .includes(filters.search.toLowerCase())
        ) {
          return true;
        }
        return false;
      }),
    };
  });
};

import { ConfirmationDateOptions } from "api/milo-settings/models";
import { Order } from "api/orders/models";
import { PostDeadline, RouteMessages } from "api/routes/models";
import { QueryFetchError } from "api/types";
import { AxiosError } from "axios";
import { Modal } from "components/utils";
import { useState } from "react";
import { UseMutationResult } from "react-query";
import cx from "classnames";
import localStyles from "./ConfirmationDateModal.module.css";
import { AddCustomOption } from "./AddCustomOption";
import { dayOptions, getIndicativeDate } from "./utils";
import { Button } from "components/common";

interface Props {
  close: () => void;
  options: ConfirmationDateOptions[];
  order: RouteMessages["orders"][number];
  sendSmsMutation: UseMutationResult<
    {
      message: string;
    },
    QueryFetchError,
    {
      values: {
        id: Order["id"];
        deadline: PostDeadline | null;
      };
    },
    (error?: AxiosError<any> | undefined) => void
  >;
}

export const SmsConfirmationDateInNotificationsModal = ({
  close,
  options,
  order,
  sendSmsMutation,
}: Props) => {
  const [allOptions, setAllOptions] = useState(options);
  const [dayOption, setDayOption] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [picked, setPicked] = useState<ConfirmationDateOptions | null>(null);
  const [time, setTime] = useState("");

  return (
    <Modal close={close} isOpen={true} overrides={{ container: { className: localStyles.modal } }}>
      <div>
        <div className={cx(localStyles.title, "mt-2 mb-4")}>
          Do kiedy możliwe będzie potwierdzenie zamówienia?
        </div>

        <div className="text-color-grey mb-3">Wybierz jedną z opcji lub dodaj własną:</div>

        <div className={cx(localStyles.optionBox, "mb-4")}>
          {allOptions.length > 0 &&
            allOptions.map(option => {
              return (
                <div
                  className={cx(
                    localStyles.option,
                    "d-flex align-items-center justify-content-center mb-2",
                    {
                      [localStyles.optionActive]: option.id === picked?.id,
                    },
                  )}
                  key={option.id}
                  onClick={() => {
                    if (option.id === picked?.id) {
                      setPicked(null);
                    } else {
                      setPicked(option);
                    }
                  }}
                >
                  <span>
                    {dayOptions[option.deadlineDayOption]}
                    <span className="text-color-grey fs-14">
                      &nbsp;({getIndicativeDate(option.deadlineDayOption)})
                    </span>
                  </span>
                  <i className={localStyles.divider}></i>
                  <span>{option.deadlineTime.slice(0, 5)}</span>
                </div>
              );
            })}
        </div>

        <AddCustomOption
          allOptions={allOptions}
          dayOption={dayOption}
          dayOptions={dayOptions}
          isOpen={isOpen}
          setAllOptions={setAllOptions}
          setDayOption={setDayOption}
          setIsOpen={setIsOpen}
          setPicked={setPicked}
          setTime={setTime}
          time={time}
        />

        <div className={cx(localStyles.buttonBox, "d-flex align-items-center")}>
          <Button
            kind="secondary-grey"
            onClick={() => sendSmsMutation.mutate({ values: { id: order.id, deadline: null } })}
          >
            Pomiń i wyślij SMS
          </Button>
          <Button
            disabled={picked === null}
            kind="primary"
            onClick={() => {
              if (picked) {
                sendSmsMutation.mutate({
                  values: {
                    id: order.id,
                    deadline: {
                      option: picked.deadlineDayOption,
                      time: picked.deadlineTime.slice(0, 5),
                    },
                  },
                });
              }
            }}
          >
            Wyślij SMS z datą potwierdzenia
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export enum HelpdeskIssueModuleEnum {
  ACCOUNTANCY = "ACCOUNTANCY",
  USERS = "PEOPLE",
  PRODUCTION = "PRODUCTION",
  PRODUCTS = "PRODUCTS",
  WAREHOUSE = "WAREHOUSE",
  REPORT = "REPORT",
  TRANSPORT = "TRANSPORT",
  ORDERS = "ORDERS",
  COMPLAINTS = "COMPLAINTS",
}

// Those values on the right side are CHOICE VALUES on backend, not just random strings!
export enum HelpdeskIssueSubjectEnum {
  FEATURE = "I need a new feature",
  HELP = "I need help",
  REMARKS = "I have remarks about application",
  BUG = "Bug in application",
}

export enum HelpdeskAppTypeEnum {
  milo = "milo",
  warehouse = "warehouse",
}

export interface HelpdeskIssuePostPayload {
  subject: string;
  reportedBy: number;
  link: string;
  refersTo: string;
  app: HelpdeskAppTypeEnum;
  description: string;
}

export interface HelpdeskIssueResponse {
  app: HelpdeskAppTypeEnum;
  createdAt: string;
  description: string;
  id: string;
  link: string;
  refersTo: HelpdeskIssueModuleEnum;
  reportedBy: number;
  subject: HelpdeskIssueSubjectEnum;
}

import styles from "../RouteTrackingModal.module.css";
import cx from "classnames";
import { BaseSyntheticEvent } from "react";

interface Props {
  disabled: boolean;
  label?: string;
  onChange: (value: BaseSyntheticEvent) => void;
  placeholder?: string;
  value: string | null;
}

export const TimeFilter = ({ disabled, label = "", onChange, placeholder = "", value }: Props) => {
  return (
    <div className={styles.filterBox}>
      <input
        autoComplete="off"
        className={cx(styles.inputSmall, styles.inputOnLightBg)}
        onChange={onChange}
        placeholder={placeholder.length > 0 ? placeholder : "wybierz godzinę"}
        type="time"
        value={value ? normalizeTime(value) : ""}
      />
      <div className={styles.inputLabel}>{label}</div>
    </div>
  );
};

const normalizeTime = (value: string) => {
  return value.slice(0, 8);
};

import { TagProps } from "components/miloDesignSystem/atoms/tag";

export type CarrierTab = "active" | "deactivated";

export enum CarrierKind {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export interface Carrier {
  city: string;
  companyName: string;
  createdAt: string;
  email: string;
  id: number;
  isActive: boolean;
  kind: CarrierKind;
  name: string;
  phone: string;
  postCode: string;
  street: string;
  taxId: string;
  updatedAt: string;
}

export type CreateCarrier = Omit<Carrier, "createdAt" | "id" | "isActive" | "updatedAt">;

export const kindDict: Record<
  CarrierKind,
  { label: string; variant: TagProps<string>["variant"] }
> = {
  [CarrierKind.INTERNAL]: { label: "własny", variant: "warning" },
  [CarrierKind.EXTERNAL]: { label: "obcy", variant: "info" },
};

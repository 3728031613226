import { RouteListItem } from "api/routes/models";
import { Checkbox } from "components/utils";
import { ListCheckboxesState } from "hooks/useListCheckboxes";
import { SubToolbar as DefaultSubToolbar } from "components/common/subToolbar";
import { Mode } from "./RoutesList";
import { FLAVOR } from "CONSTANTS";
import { Button, useToastr } from "components/common";
import { getAnyErrorKey, pluralize } from "utilities";
import { DOMAIN } from "ENDPOINTS";
import { useMutation } from "hooks/useMutation";
import { postPrintReceiptsForRoutes } from "api/routes/calls";
import map from "assets/images/map_alt.svg";
import { useRouteHook } from "pages/routes/routesList/hooks";
import { Link, useParams } from "react-router-dom";

interface Props {
  checkboxes: ListCheckboxesState<RouteListItem>;
  filterByUser: boolean;
  mode: Mode;
  routes: RouteListItem[];
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
}

export const Subtoolbar = ({ checkboxes, filterByUser, mode, routes, setMode }: Props) => {
  let recentlyOpenedRoute = localStorage.getItem("recentlyOpenedRoute");
  let recentlyChangedRoute = localStorage.getItem("recentlyChangedRoute");
  if (!recentlyOpenedRoute) recentlyOpenedRoute = "";
  if (!recentlyChangedRoute) recentlyChangedRoute = "";
  const recentlyOpenedRouteObject = useRouteHook(recentlyOpenedRoute);
  const recentlyChangedRouteObject = useRouteHook(recentlyChangedRoute);

  const toastr = useToastr();
  const areAllChecked = checkboxes.areAllChecked(routes.map(route => route.id));
  const checkbox = (
    <Checkbox
      checked={areAllChecked}
      className="mr-2"
      label="Zaznacz wszystkie"
      name=""
      onChange={() => {
        areAllChecked ? checkboxes.reset() : checkboxes.addMany(routes);
      }}
      overwrites={{ wrapper: { style: { marginLeft: 15 } } }}
    />
  );
  const { tab } = useParams<{ tab: "active" | "archive" }>();

  const printReceiptsMutation = useMutation(postPrintReceiptsForRoutes, {
    onSuccess: (_, variables) => {
      const receiptsNumber = variables.routes.length;
      setMode("default");
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: `Zlecono drukowanie ${receiptsNumber} ${pluralize.pl(receiptsNumber, {
          singular: "paragonu",
          plural: "paragonów",
          other: "paragonów",
        })}`,
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Oj, coś nie tak...",
        text: getAnyErrorKey(error),
      });
    },
  });

  return (
    <div className="d-flex">
      {mode === "default" && FLAVOR === "main" && (
        <DefaultSubToolbar filterByUser={filterByUser} filter="createdBy" label="Moje trasy" />
      )}
      {mode === "productionPdfPreview" && (
        <small className="d-flex align-items-center text-color-grey border-bottom pb-1 ml-1 my-1">
          {checkbox}
          <Button
            as="a"
            disabled={!Object.keys(checkboxes.values).map(Number).length}
            href={`${DOMAIN}utils/collection-production-pdf/routes-production-pdf?routes=${encodeURIComponent(
              Object.keys(checkboxes.values)
                .map(Number)
                .join(","),
            )}`}
            kind="primary"
            rel="noopener noreferrer"
            size="small"
            target="_blank"
            title="Podgląd PDF-a produkcyjnego dla tras"
          >
            Podgląd PDF-a produkcyjnego
          </Button>
          <div className="ml-4">
            (zaznaczono {checkboxes.valuesArray.length}{" "}
            {pluralize.pl(checkboxes.valuesArray.length, {
              singular: "trasę",
              plural: "trasy",
              other: "tras",
            })}
            )
          </div>
        </small>
      )}
      {mode === "printReceipt" && (
        <small className="d-flex align-items-center text-color-grey border-bottom pb-1 ml-1 my-1">
          {checkbox}
          <Button
            disabled={!Object.keys(checkboxes.values).map(Number).length}
            kind="primary"
            onClick={() => {
              const listOfRoutesIds = Object.keys(checkboxes.values).map(Number);
              printReceiptsMutation.mutate({ routes: listOfRoutesIds });
            }}
            size="small"
            title="Drukuj paragony dla wielu tras"
          >
            Drukuj paragony
          </Button>
          <div className="ml-4">
            (zaznaczono {checkboxes.valuesArray.length}{" "}
            {pluralize.pl(checkboxes.valuesArray.length, {
              singular: "trasę",
              plural: "trasy",
              other: "tras",
            })}
            )
          </div>
        </small>
      )}
      <div className="d-flex align-items-center gap-1 mr-1 textAlignRight">
        <div className="d-flex flex-column align-items-center gap-1 mr-1 textAlignRight">
          <div className="body-10 text-black-6"> Ostatnio otwarta mapa:</div>
          <Link
            to={
              tab === "active"
                ? `/routes/creator/${recentlyOpenedRoute}`
                : `/routes/creator/${recentlyOpenedRoute}/archive`
            }
            className="body-12"
          >
            {recentlyOpenedRouteObject?.data?.signature ? (
              <>{recentlyOpenedRouteObject?.data?.signature}</>
            ) : null}
          </Link>
        </div>

        <div className="d-flex flex-column align-items-center gap-1 mr-1 textAlignRight">
          <div className="body-10 text-black-6"> Ostatnio edytowana:</div>
          <Link
            to={
              tab === "active"
                ? `/routes/creator/${recentlyChangedRoute}`
                : `/routes/creator/${recentlyChangedRoute}/archive`
            }
            className="body-12"
          >
            {recentlyChangedRouteObject?.data?.signature ? (
              <>{recentlyChangedRouteObject?.data?.signature}</>
            ) : null}
          </Link>
        </div>

        <Button className="btn btnSizeSmall" kind="create">
          <Link to="/routes/map" className="btnBase btnBaseSmall uppercase">
            <img alt="Mapa" src={map} style={{ height: "16px", width: "16px" }} />
            Przejdź do mapy
          </Link>
        </Button>
      </div>
    </div>
  );
};

import calculateIcon from "assets/images/calculatePink.svg";
import salesIcon from "assets/images/store.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import favicon from "assets/images/favicons/fv_sales.svg";

export const sales: ModuleNavigation = {
  favicon,
  labelBackground: "#b65eff",
  label: "Handel",
  iconBackground: "#431d87",
  icon: calculateIcon,
  menuIcon: salesIcon,
  name: "SALES",
  url: "/sales",
  navigationSections: [{ navigationList: [] }],
};

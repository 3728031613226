import { useState } from "react";
import { Marker as MapMarker, InfoWindow } from "@react-google-maps/api";
import markerImg from "assets/images/mapMarkers/p44.png";
import { Route } from "api/routes/models";

interface Props {
  startingPoint?: Route["startingPoint"];
}

export const StartingPointMarker = ({ startingPoint }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!startingPoint) {
    return null;
  }
  return (
    <MapMarker
      key={startingPoint.id}
      position={startingPoint.point}
      icon={markerImg}
      onClick={() => setIsOpen(prev => !prev)}
    >
      {isOpen && (
        <InfoWindow position={startingPoint.point} onCloseClick={() => setIsOpen(false)}>
          <div>
            {startingPoint.street},{startingPoint.postCode} {startingPoint.city}
          </div>
        </InfoWindow>
      )}
    </MapMarker>
  );
};

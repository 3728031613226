import { LongHeldPackageTab } from "api/unique-packages/models";
import { queryString } from "utilities";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  tab: LongHeldPackageTab;
};

export const getSearch = ({ query, tab }: GetSearchProps) => {
  const queryParams = queryString.stringify({
    ...query,
  });
  if (tab === LongHeldPackageTab["with-orders"])
    return `with-orders$/orders/long-held-packages$${queryParams}`;
  return `without-orders$/unique-packages/long-held-packages$${queryParams}`;
};

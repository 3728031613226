import { UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";

const postStartUnloading = (data: { unloadingId: UUID }) =>
  queryFetch<void>({
    method: "POST",
    url: `/wms/unloading/web/start-unloading`,
    data,
  });

const postFinishUnloading = (data: { unloadingId: UUID }) =>
  queryFetch<void>({
    method: "POST",
    url: `/wms/unloading/web/finish-unloading`,
    data,
  });

export const wmsUnloadingApi = {
  postStartUnloading,
  postFinishUnloading,
};

import { useFilters } from "hooks/useFilters";
import { dateFns } from "utilities";

export type RowBy = "DAY" | "WEEK" | "MONTH";

export type TableFilters = {
  rowBy: RowBy;
  dateFrom: string;
  dateTo: string;
};

export const useGenericTableFilters = () => {
  const { setFilter, filters } = useFilters<TableFilters>({
    rowBy: "DAY",
    dateTo: dateFns.format(dateFns.addDays(new Date(), 1), "yyyy-MM-dd"),
    dateFrom: dateFns.format(dateFns.subDays(new Date(), 7), "yyyy-MM-dd"),
  });

  const setDateFrom = () => {
    if (filters.rowBy === "DAY" || filters.rowBy === "WEEK") {
      setFilter(
        "dateFrom",
        dateFns.format(dateFns.subDays(new Date(filters.dateFrom), 7), "yyyy-MM-dd"),
      );
    } else {
      setFilter(
        "dateFrom",
        dateFns.format(
          dateFns.startOfMonth(dateFns.subMonths(new Date(filters.dateFrom), 1)),
          "yyyy-MM-dd",
        ),
      );
    }
  };

  const setRowBy = (value: RowBy) => {
    setFilter("rowBy", value);
  };

  return { filters, setDateFrom, setRowBy };
};

import { createContext, useContext, Dispatch, useMemo } from "react";
import * as React from "react";
import { tuplify } from "./utilities";

/**
 * Creates context state provider
 * @example
 * export const [StateProvider, useMainState, useMainDispatch] = createStateProvider<State, Action>();
 */
export function createStateProvider<State, Action>() {
  const stateContext = createContext<[State, Dispatch<Action>]>([] as any);
  const dispatchContext = createContext<Dispatch<Action>>(() => {});

  const StateProvider = ({
    children,
    value: [state, dispatch],
  }: {
    children: React.ReactNode;
    value: [State, Dispatch<Action>];
  }) => {
    const memoizedValue = useMemo(() => [state, dispatch] as [State, Dispatch<Action>], [
      state,
      dispatch,
    ]);
    return (
      <div>
        <stateContext.Provider value={memoizedValue}>
          <dispatchContext.Provider value={dispatch}>{children}</dispatchContext.Provider>
        </stateContext.Provider>
      </div>
    );
  };

  const useMainState = () => {
    return useContext(stateContext);
  };

  const useMainDispatch = () => {
    const dispatch = useContext(dispatchContext);
    return dispatch;
  };

  return tuplify(StateProvider, useMainState, useMainDispatch);
}

/**
 * returns value of <html> lang attribute
 */
export function getLang() {
  return document.documentElement.getAttribute("lang") || "pl";
}
/**
 * removes duplicate slashes
 */
export function removeSlash(url: string) {
  const arr = url.split("?");
  if (arr[0].charAt(0) === "/") {
    arr[0] = arr[0].substring(1);
    const search = arr[1] ? "?" + arr[1] : "";
    return arr[0] + search;
  }
  return url;
}

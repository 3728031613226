import { useQuery } from "hooks";
import { RouteComponentProps } from "react-router";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import {
  DrawerHelpers,
  getTablePropsBasedOnDrawerHelpers,
} from "components/utils/drawerHelpers/DrawerHelpers";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { useExternalManufacturingOrdersColumns } from "./useExternalManfacturingOrdersColumns";
import {
  ExternalManufacturerItemsTab,
  ExternalManufacturingOrder,
} from "api/external-manufacturing/models";
import { getSearch } from "./utils/getSearch";
import { getTabs } from "./utils/getTabs";
import { RightPanel } from "./rightPanel/RightPanel";

export const ExternalManufacturingOrders = ({
  match,
}: RouteComponentProps<{ tab: ExternalManufacturerItemsTab }>) => {
  const { tab } = match.params;
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const {
    data,
    error,
    isPreviousData,
    isLoading,
    pagination,
  } = externalManufacturingActions.useExternalManufacturingOrders(search);

  const columns = useExternalManufacturingOrdersColumns();

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `external-manufacturing/orders`,
          urlSpan: "list",
        }}
        viewLabel="EXTERNAL_MANUFACTURING_ORDERS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <DrawerHelpers name="externalManufacturingOrders">
          {drawerHelpers => (
            <>
              <Table<ExternalManufacturingOrder>
                rows={data}
                columns={columns}
                onPaginationChange={paginationState => {
                  updateQuery({ ...query, page: paginationState.pageIndex });
                }}
                isLoading={isLoading || isPreviousData}
                error={error}
                pagination={pagination}
                uiSchema={mainListUiSchema}
                {...getTablePropsBasedOnDrawerHelpers(drawerHelpers)}
              />

              <RightPanel />
            </>
          )}
        </DrawerHelpers>
      </div>
    </PageWrapper>
  );
};

import { StateLabel } from "components/common/stateLabel";

interface Props {
  isRead: boolean;
}

export const LatestSmsMessageStatus = ({ isRead }: Props) => {
  if (isRead)
    return (
      <div>
        <StateLabel kind="darkGreen">odczytana</StateLabel>
      </div>
    );

  return (
    <div>
      <StateLabel kind="bgGrey10">nieodczytana</StateLabel>
    </div>
  );
};

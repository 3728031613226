import { useState } from "react";
import * as React from "react";
import styles from "./AttributeAccordion.module.css";
import dropdownIcon from "assets/images/27.svg";
import cx from "classnames";

type AttributeAccordionProps = {
  label: React.ReactNode;
  enabled: boolean;
  children: React.ReactElement;
  isSelected: boolean;
  className?: string;
};
export const AttributeAccordion = ({
  label,
  enabled = false,
  children,
  isSelected,
  className,
}: AttributeAccordionProps) => {
  const [isOpen, setOpen] = useState(false);
  if (!enabled) return children;
  return (
    <div className={cx(styles.accordion, className)}>
      <button
        className="d-flex justify-content-between text-uppercase w-100"
        type="button"
        onClick={() => setOpen(s => !s)}
      >
        <span className={cx({ [styles.labelHighlight]: isSelected && !isOpen })}>{label}</span>
        <span>
          <img src={dropdownIcon} alt="" style={{ transform: `rotate(${isOpen ? 90 : 270}deg)` }} />
        </span>
      </button>

      <div style={{ display: isOpen ? "block" : "none" }}>{children}</div>
    </div>
  );
};

import arrowImg from "assets/images/171.svg";
import {
  useWhEntryOrderPackages,
  usePackagesFromSource,
  useWhEntryReturnedPackages,
  useWhEntriesOrders,
} from "api/warehouse/hooks";
import { SourceType, WarehouseDocument, WarehouseDocumentStatus } from "api/warehouse/models";
import completeImg from "assets/images/7b.svg";
import listEmptyIcon from "assets/images/el2.png";
import cx from "classnames";
import { Button } from "components/common";
import { CommonError, Label, Modal, Spinner, StatusHandler } from "components/utils";
import { DrawerSection } from "components/utils/drawer";
import { NoContent } from "components/utils/noContent";
import { StateModalHookState, useConfirmModal, useQuery, useStateModal, useToastr } from "hooks";
import { ReactNode, useState } from "react";
import { dateFns, getAnyErrorKey } from "utilities";
import styles from "./WarehouseDocumentsSection.module.css";
import { Link } from "react-router-dom";
import { confirmEntry } from "api/warehouse/calls";
import { assertIsDefined } from "utilities/assertIsDefined";
import { isAttributeFormatDescription } from "pages/warehouse/utils/isAttributeFormatDescription";
import { Attributes } from "components/common/attributes/Attributes";
import { formatAttributes } from "pages/warehouse/utils/formatAttributes";

type Tabs = "notScannedPackages" | "orders" | "returnedPackages";

interface Props {
  warehouseDocument: WarehouseDocument | null;
  sourceType: SourceType;
  updateDocument: (document: WarehouseDocument) => void;
}

export const WarehouseDocumentsSection = ({
  warehouseDocument,
  sourceType,
  updateDocument,
}: Props) => {
  const [tab, setTab] = useState<Tabs>("notScannedPackages");
  const toastr = useToastr();
  const confirmModal = useConfirmModal();
  const renderDetailsBasedOnTab: Record<Tabs, React.ReactNode> = {
    notScannedPackages: <NotScannedPackages sourceType={sourceType} />,
    orders: <Orders sourceType={sourceType} />,
    returnedPackages: <ReturnedPackages sourceType={sourceType} />,
  };

  const confirm = () => {
    const handleConfirm = async () => {
      assertIsDefined(warehouseDocument);
      const [, error] = await confirmEntry(warehouseDocument.id);
      if (error) {
        toastr.open({
          type: "failure",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      } else {
        updateDocument({ ...warehouseDocument, status: "CLOSED" });
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Dokument został zamknięty.",
        });
      }
    };

    confirmModal.open({
      text: `Czy potwierdzasz zamknięcie dokumentu "${warehouseDocument?.signature}"?`,
      confirmText: "Potwierdź zamknięcie",
      callback: () => handleConfirm(),
    });
  };

  const warehouseStatusToLabelDict: Record<WarehouseDocumentStatus, ReactNode> = {
    CANCELED: <Label color="grey">Anulowany dokument</Label>,
    OPENED: <Label color="blue">Otwarty dokument</Label>,
    CLOSED: <Label color="grey">Zamknięty dokument</Label>,
  };

  return (
    <DrawerSection
      title={
        <div>
          <div className="d-flex align-items-center justify-content-between w-100">
            Dokumenty magazynowe
          </div>
          {warehouseDocument && (
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <Link
                  className={styles.documentSignature}
                  to={`/warehouse/documents?panelId=${warehouseDocument.id}`}
                >
                  {warehouseDocument.signature}
                </Link>
                <div className="d-flex align-items-center">
                  {warehouseStatusToLabelDict[warehouseDocument.status]}
                </div>
              </div>

              <div className="d-flex align-items-center">
                {warehouseDocument.status === "OPENED" && (
                  <StatusHandler>
                    {helpers => (
                      <Button
                        type="button"
                        kind="ready"
                        size="small"
                        width="fit-content"
                        onClick={confirm}
                        disabled={helpers.isFetching}
                      >
                        <img className="mr-1" alt="" src={completeImg} />
                        Zamknij dokument
                      </Button>
                    )}
                  </StatusHandler>
                )}
              </div>
            </div>
          )}
        </div>
      }
    >
      {warehouseDocument ? (
        <>
          <div className="d-flex align-items-center mb-3">
            <div
              className={cx({ [styles.borderActive]: tab === "notScannedPackages" }, styles.tab)}
              onClick={() => setTab("notScannedPackages")}
            >
              Niezeskanowane paczki
            </div>
            <div
              className={cx({ [styles.borderActive]: tab === "orders" }, styles.tab)}
              onClick={() => setTab("orders")}
            >
              Zamówienia
            </div>
            <div
              className={cx({ [styles.borderActive]: tab === "returnedPackages" }, styles.tab)}
              onClick={() => setTab("returnedPackages")}
            >
              Zwrócone paczki
            </div>
          </div>
          <div className={styles.detailsContainer}>{renderDetailsBasedOnTab[tab]}</div>
        </>
      ) : (
        "Brak dokumentu magazynowego"
      )}
    </DrawerSection>
  );
};

const NotScannedPackages = ({ sourceType }: { sourceType: SourceType }) => {
  const { query } = useQuery();
  const { data, isLoading, error } = usePackagesFromSource({ id: query.panelId, sourceType });

  if (error) {
    return <CommonError text={getAnyErrorKey(error)} />;
  }

  if (isLoading) {
    return (
      <div className="d-flex mt-5 flex-1 justify-content-center">
        <Spinner color="blue" size="big" text="trwa wczytywanie" />
      </div>
    );
  }

  if (!data) return null;

  if (data.length) {
    return (
      <div>
        {data.map(notScannedPackage => (
          <div
            className={cx(styles.package, "d-flex align-items-center justify-content-between")}
            key={notScannedPackage.id}
          >
            <div>
              <div>{notScannedPackage.name}</div>
              {isAttributeFormatDescription(notScannedPackage.description) ? (
                <div className="overflow">
                  <Attributes attributes={formatAttributes(notScannedPackage.description)} />
                </div>
              ) : (
                <div className="body-14-500 text-black-6 overflow">
                  {notScannedPackage.description}
                </div>
              )}
              <div className={styles.indexCode}>{notScannedPackage.internalId}</div>
            </div>
            <div className="nowrap fs-14">{notScannedPackage.order?.signature}</div>
          </div>
        ))}
      </div>
    );
  } else {
    return <NoContent header="Brak paczek" img={listEmptyIcon} variant="B" />;
  }
};

const Orders = ({ sourceType }: { sourceType: SourceType }) => {
  const { query } = useQuery();
  const { data, isLoading, error } = useWhEntriesOrders({
    id: query.panelId,
    sourceType: sourceType,
  });

  const modal = useStateModal<{ id: number; signature: string }>();

  if (error) {
    return <CommonError text={getAnyErrorKey(error)} />;
  }

  if (isLoading) {
    return (
      <div className="d-flex mt-5 flex-1 justify-content-center">
        <Spinner color="blue" size="big" text="trwa wczytywanie" />
      </div>
    );
  }

  if (!data) return null;

  if (!data.length) {
    return <NoContent header="Brak zamówień" img={listEmptyIcon} variant="B" />;
  }

  return (
    <div>
      {data.map(el => (
        <div
          key={el.id}
          className={cx(styles.package, "d-flex align-items-center justify-content-between")}
        >
          <div>
            <div className="d-flex align-items-center">
              <div className="mr-2">{el.signature} </div>
              {el.isScanned ? (
                <Label color="blue">Zeskanowano paczki</Label>
              ) : (
                <Label color="grey">Nie zeskanowano paczek</Label>
              )}
            </div>
            <div className="fs-12 text-color-grey">
              {el.customer
                ? el.delivery.companyName
                : `${el.delivery.firstName} ${el.delivery.lastName}`}
            </div>
            <div className="fs-12 text-color-grey">
              {el.delivery.postCode} {el.delivery.city}, {el.delivery.street}
            </div>
          </div>

          <Button
            kind="link"
            className="fs-14"
            onClick={() => {
              modal.open({ id: el.id, signature: el.signature });
            }}
          >
            Pokaż paczki
          </Button>
        </div>
      ))}
      {modal.isOpen && <OrderPackagesModal modal={modal} sourceType={sourceType} />}
    </div>
  );
};

const OrderPackagesModal = ({
  modal,
  sourceType,
}: {
  modal: StateModalHookState<{ id: number; signature: string }>;
  sourceType: SourceType;
}) => {
  const { close, isOpen } = modal;
  const { query } = useQuery();

  const { data, isLoading, error } = useWhEntryOrderPackages(
    { routeId: query.panelId, orderId: modal.state!.id, sourceType: sourceType },
    { enabled: Boolean(modal.state?.id) },
  );

  if (error) {
    return (
      <Modal isOpen={isOpen} close={close} size={{ all: { width: "65vw" } }}>
        <CommonError text={getAnyErrorKey(error)} />
      </Modal>
    );
  }

  if (isLoading) {
    return (
      <Modal isOpen={isOpen} close={close} size={{ all: { width: "65vw" } }}>
        <div className={cx("d-flex mt-5 flex-1 justify-content-center", styles.modalWrapper)}>
          <Spinner color="blue" size="big" text="trwa wczytywanie" />
        </div>
      </Modal>
    );
  }

  if (!data) return null;

  if (!data.length) {
    return (
      <Modal isOpen={isOpen} close={close} size={{ all: { width: "65vw" } }}>
        <NoContent header="Brak paczek" img={listEmptyIcon} variant="B" />
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} close={close} size={{ all: { width: "65vw" } }}>
      {data.length ? (
        <div>
          <h1 className="ml-0">Paczki w zamówieniu {modal.state?.signature}</h1>
          <div className={cx(styles.modalItemsContainer, "pb-0 border-bottom-0")}>
            <div className="fs-14 text-color-grey">nazwa paczki</div>
            <div className="fs-14 text-color-grey">skanowanie</div>
            <div className="fs-14 text-color-grey">kto zeskanował</div>
            <div className="fs-14 text-color-grey">data</div>
            <div></div>
          </div>
          {data &&
            data.map(orderPackage => (
              <div key={orderPackage.id} className={styles.modalItemsContainer}>
                <div>
                  <div>{orderPackage.name}</div>
                  {isAttributeFormatDescription(orderPackage.description) ? (
                    <div className="overflow">
                      <Attributes attributes={formatAttributes(orderPackage.description)} />
                    </div>
                  ) : (
                    <div className="body-14-500 text-black-6 overflow">
                      {orderPackage.description}
                    </div>
                  )}
                  <div className={styles.indexCode}>{orderPackage.internalId}</div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  {orderPackage.isScanned && (
                    <img alt="" src={completeImg} className={styles.completeImg} />
                  )}
                </div>
                <div>
                  {orderPackage.user && (
                    <div>
                      <span className="mr-1">{orderPackage.user.firstName}</span>
                      {orderPackage.user.lastName}
                    </div>
                  )}
                </div>
                <div>
                  {orderPackage.created &&
                    dateFns.format(new Date(orderPackage.created), "dd.MM.yyyy, HH:mm")}
                </div>
                <div className="pl-2 border-left ">
                  <Button
                    kind="link"
                    as={Link}
                    disabled={!orderPackage.uniquePackage}
                    className="nowrap fs-14 "
                    to={`/warehouse/packages/${orderPackage.id}/${orderPackage.uniquePackage?.code}/history`}
                    title="Historia paczki"
                  >
                    <div className="d-flex align-items-center">
                      Pokaż historię paczki
                      <img alt="" src={arrowImg} className="ml-1" />
                    </div>
                  </Button>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <NoContent header="Brak zamówień" img={listEmptyIcon} variant="B" />
      )}
    </Modal>
  );
};

const ReturnedPackages = ({ sourceType }: { sourceType: SourceType }) => {
  const { query } = useQuery();
  const { data, isLoading, error } = useWhEntryReturnedPackages({
    id: query.panelId,
    sourceType,
  });

  if (error) {
    return <CommonError text={getAnyErrorKey(error)} />;
  }

  if (isLoading) {
    return (
      <div className="d-flex mt-5 flex-1 justify-content-center">
        <Spinner color="blue" size="big" text="trwa wczytywanie" />
      </div>
    );
  }

  if (!data) return null;

  if (data.length) {
    return (
      <div>
        {data.map(returnedPackage => (
          <div
            key={returnedPackage.code}
            className={cx(styles.package, "d-flex align-items-center justify-content-between")}
          >
            <div>
              <div>{returnedPackage.name}</div>
              <div className={styles.indexCode}>{returnedPackage.internalId}</div>
            </div>
            <div className="d-flex align-items-center ml-2">
              <div className="nowrap fs-14">{returnedPackage.order.signature}</div>
              <div className="ml-4 pl-2 border-left ">
                <Button
                  kind="link"
                  as={Link}
                  disabled={!returnedPackage.uniquePackage}
                  to={`/warehouse/packages/${returnedPackage.id}/${returnedPackage.uniquePackage?.code}/history`}
                  title="Historia paczki"
                  className="nowrap fs-14 "
                >
                  <div className="d-flex align-items-center">
                    Historia paczki
                    <img alt="" src={arrowImg} className="ml-1" />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return <NoContent header="Brak paczek" img={listEmptyIcon} variant="B" />;
  }
};

import { TagProps } from "components/miloDesignSystem/atoms/tag";
import { InvoiceType, TradingDocumentStatus, TradingDocumentType, ViesStatus } from "./models";

export const invoiceDomainDict: Record<InvoiceType, string> = {
  CORRECTION: "corrections",
  FINAL: "finals",
  ADVANCE: "advances",
  PROFORMA: "proformas",
  PURCHASE: "purchases",
  SALES: "sales",
};

export const invoiceTypeDict: Record<InvoiceType, string> = {
  CORRECTION: "korekta",
  FINAL: "końcowa",
  ADVANCE: "zaliczkowa",
  PROFORMA: "proforma",
  PURCHASE: "zakupowa",
  SALES: "sprzedażowa",
};

export const invoiceStatusDict: Record<TradingDocumentStatus, string> = {
  CONFIRMED: "Zatwierdzona",
  NEW: "Nowa",
};

export const receiptStatusDict: Record<TradingDocumentStatus, string> = {
  CONFIRMED: "Zatwierdzony",
  NEW: "Nowy",
};

export const tradingDocumentStatusVariant: Record<
  TradingDocumentStatus,
  Record<TradingDocumentType, { label: string; variant: TagProps<string>["variant"] }>
> = {
  CONFIRMED: {
    INVOICE: { label: invoiceStatusDict["CONFIRMED"], variant: "success" },
    RECEIPT: { label: receiptStatusDict["CONFIRMED"], variant: "success" },
  },
  NEW: {
    INVOICE: { label: invoiceStatusDict["NEW"], variant: "info" },
    RECEIPT: { label: receiptStatusDict["NEW"], variant: "info" },
  },
};

export const viesStatusDict: Record<ViesStatus, string> = {
  ACTIVE: "Aktywny",
  INACTIVE: "Nieaktywny",
  UNREGISTERED: "Niezarejestrowany",
  NO_DATA: "Brak danych",
  NO_TAX_ID: "Brak NIP-u",
  INVALID: "Nieznany",
};

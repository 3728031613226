import { Route as RouterRoute } from "react-router-dom";
import { RouteEnhancer } from "./RouteEnhancer";
import { Props } from "./types";

export function Route({ title, ...rest }: Props) {
  return (
    <RouterRoute {...rest} component={undefined} render={undefined}>
      {(routeProps: any) => (
        <RouteEnhancer
          {...routeProps}
          match={routeProps.match}
          component={rest.component}
          render={rest.render}
          shouldReRenderTitle={rest.shouldReRenderTitle}
          title={title}
        />
      )}
    </RouterRoute>
  );
}

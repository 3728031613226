import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { useQuery } from "hooks";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useEffect, useRef, useState } from "react";
import { PanelHeader } from "./components/PanelHeader";
import { useManufacturingStage } from "api/manufacturingNew/hooks";
import { Days } from "../components/Days";
import { GeneralInfoSection } from "../components/GeneralInfoSection";
import { Employees } from "../components/Employees";
import { PreviousStagesSection } from "../components/previousStagesSection/PreviousStagesSection";
import { SchemasSection } from "../components/schemasSection/SchemasSection";
import { StageColumnSets } from "../components/StageColumnSets";

interface Props {
  close: () => void;
}

export const RightPanel = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const [isScrolledOver, setIsScrolledOver] = useState(false);
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const { data: manufacturingStage, error, isLoading } = useManufacturingStage(panelId, {
    enabled: Boolean(panelId),
  });

  useEffect(() => {
    setIsScrolledOver(false);
  }, [panelId]);

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!manufacturingStage) return null;

  return (
    <DrawerRightPanel key={manufacturingStage.id}>
      <PanelHeader
        manufacturingStage={manufacturingStage}
        close={close}
        isScrolledOver={isScrolledOver}
      />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody myRef={clearCommentsRef} setIsScrolledOver={setIsScrolledOver}>
          <GeneralInfoSection manufacturingStage={manufacturingStage} />
          <StageColumnSets manufacturingStage={manufacturingStage} />
          <Employees manufacturingStage={manufacturingStage} />
          <Days manufacturingStage={manufacturingStage} />
          <PreviousStagesSection manufacturingStage={manufacturingStage} />
          <SchemasSection manufacturingStage={manufacturingStage} />
        </RightPanelBody>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};

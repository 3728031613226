import { ShipmentListItem, ShipmentStatus as ShipmentStatusChoices } from "api/shipping/models";
import { shipmentStatusToTagDict } from "../../useShippingShipmentsColumns";
import { MdiRepeat } from "components/miloDesignSystem/atoms/icons/MdiRepeat";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Button } from "components/miloDesignSystem/atoms/button";
import { shippingActions } from "api/shipping/actions";

export const ShipmentStatus = ({ shipment }: { shipment: ShipmentListItem }) => {
  const sendShipmentToExternalMutation = shippingActions.useSendShipmentsToExternal();
  const cancelShipment = shippingActions.useCancelShipments();

  if (shipment.status === ShipmentStatusChoices.CREATE_SHIPMENT_FAILED) {
    return (
      <div className="d-flex align-items-center gap-1">
        <Tag {...shipmentStatusToTagDict[shipment.status]} />
        <Button
          className="text-uppercase mr-2"
          disabled={sendShipmentToExternalMutation.isLoading}
          isLoading={sendShipmentToExternalMutation.isLoading}
          onClick={e => {
            e.stopPropagation();
            sendShipmentToExternalMutation.mutate({
              shipments: [shipment.id],
            });
          }}
          size="small"
          startIcon={MdiRepeat}
          variant="gray"
        >
          ponów
        </Button>
      </div>
    );
  }

  if (shipment.status === ShipmentStatusChoices.CANCELED_SHIPMENT_FAILED) {
    return (
      <div className="d-flex align-items-center gap-1">
        <Tag {...shipmentStatusToTagDict[shipment.status]} />
        <Button
          className="text-uppercase mr-2"
          disabled={cancelShipment.isLoading}
          isLoading={cancelShipment.isLoading}
          onClick={e => {
            e.stopPropagation();
            cancelShipment.mutate({
              shipments: [shipment.id],
            });
          }}
          size="small"
          startIcon={MdiRepeat}
          variant="gray"
        >
          ponów
        </Button>
      </div>
    );
  }
  if (shipment.status === ShipmentStatusChoices.CALLED_PICKUP_FAILED) {
    return (
      <div className="d-flex align-items-center gap-1">
        <Tag {...shipmentStatusToTagDict[shipment.status]} />
        <Button
          className="text-uppercase mr-2"
          disabled={sendShipmentToExternalMutation.isLoading}
          isLoading={sendShipmentToExternalMutation.isLoading}
          onClick={e => {
            e.stopPropagation();
            sendShipmentToExternalMutation.mutate({
              shipments: [shipment.id],
            });
          }}
          size="small"
          variant="gray"
        >
          dokończ rejestrację
        </Button>
      </div>
    );
  }
  return <Tag {...shipmentStatusToTagDict[shipment.status]} />;
};

import { usePurchaseInvoicesToReview } from "api/trading-documents/hooks";
import { CommonError, Spinner } from "components/utils";
import { NoContent } from "components/utils/noContent";
import listEmptyIcon from "assets/images/el2.png";
import styles from "./ImportPurchaseInvoices.module.css";
import { queryString } from "utilities";
import { ListHeader } from "./components/ListHeader";
import { PdfSection } from "./components/pdfSection/PdfSection";
import { RightPanel } from "./components/rightPanel/RightPanel";
import { useQuery } from "hooks";
import { useHistory } from "react-router";
import { useState } from "react";

export const ImportPurchaseInvoices = () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const pageSize = { pageSize: 1 };
  const search = queryString.stringify({ ...query, ...pageSize });
  const history = useHistory();
  const [previousListUrl] = useState(() => {
    if (history && history.location && history.location.state) {
      // @ts-ignore
      return history.location.state.fromMyInvoices;
    }
    return "";
  });
  const {
    data: invoicesToReview,
    error,
    isLoading,
    pagination,
    refetch,
  } = usePurchaseInvoicesToReview(search);

  const noResults = !invoicesToReview.length && !isLoading && !error;

  if (error) {
    return (
      <div>
        <ListHeader invoiceToReview={invoicesToReview[0]} refetchList={refetch} />
        <div className="mt-4">
          <CommonError status={error._httpStatus_} />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <ListHeader invoiceToReview={invoicesToReview[0]} refetchList={refetch} />
        <div className="mt-4">
          <Spinner
            color="blue"
            position="absolute"
            size="big"
            text="trwa wczytywanie faktur zakupowych do zatwierdzenia"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="position-relative h-100">
      <ListHeader invoiceToReview={invoicesToReview[0]} refetchList={refetch} />
      {noResults ? (
        <NoContent
          className={styles.narrowLetters}
          on={noResults}
          header="Brak faktur do zatwierdzenia"
          img={listEmptyIcon}
        />
      ) : (
        <div className={styles.mainContent}>
          <div className={styles.mainContentInner}>
            <PdfSection
              search={search}
              invoiceToReview={invoicesToReview[0]}
              pagination={pagination}
              previousListUrl={previousListUrl}
            />
            <RightPanel
              tradingDocumentId={invoicesToReview[0].id}
              invoiceToReview={invoicesToReview[0]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

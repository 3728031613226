import { SearchField } from "components/utils/searchField/SearchField";
import { useQuery } from "hooks";
import { RoutesFilters } from "../../AddRoutesFiltersModal";
import localStyles from "./FiltersBar.module.css";
import cx from "classnames";

interface Props {
  filters: RoutesFilters;
  setFilters: React.Dispatch<React.SetStateAction<RoutesFilters>>;
}

export const FiltersBar = ({ filters, setFilters }: Props) => {
  const { query, setQuery } = useQuery({ exclude: ["panelId"] });

  return (
    <div className={cx(localStyles.filters, "d-flex align-items-center mb-3")}>
      <SearchField
        value={filters.search}
        onUpdate={value => {
          setFilters(filters => {
            return { ...filters, search: value };
          });
          setQuery({ ...query, page: "1" });
        }}
      />
    </div>
  );
};

export const highlightTextFragment = (
  searchValue: string | undefined,
  foundItemText: string,
  key: number | string,
) => {
  const value = searchValue?.toLocaleLowerCase() || "";
  const txt = foundItemText;
  const index = txt.toLocaleLowerCase().indexOf(value);

  if (index >= 0) {
    return [
      txt.substring(0, index),
      <strong key={key} style={{ color: "#765ee3", fontWeight: "inherit" }}>
        {txt.substring(index, index + value.length)}
      </strong>,
      txt.substring(index + value.length),
    ];
  } else {
    return foundItemText;
  }
};

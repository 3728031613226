import produce from "immer";
import { StateCreator } from "zustand";

export function immerMiddleware<T extends Record<string | number | symbol, unknown>>(
  config: StateCreator<T, (fn: ((state: T) => void) | Partial<T>) => void>,
): StateCreator<T> {
  return (set, get, api) =>
    config(
      (fn) => (typeof fn === "function" ? set(produce(fn) as (state: T) => T) : set(fn as any)),
      get,
      api,
    );
}

// type Elo<T> = ((arg:Partial<T>) => void) | ((arg: (arg2: T) => void) => void)

// // IN PROGRESS
// function createStore<T extends Record<string | number | symbol, unknown>>(
//   arg: (set: Elo<T>, get: GetState<T>, api: StoreApi<T>) => T,
//   name: string
// ) {
//   return create<T>(
//     persist(immerMiddleware(arg), { name, getStorage: () => AsyncStorage })
//   );
// }
// const useStore = createStore<{
//   user: any;
//   saveUser: (user: any) => void;
//   saveUser2: (user: any) => void;
// }>(
//   (set, get) => ({
//     user: null,
//     saveUser: (user) =>
//       set((state) => {
//         state.user = user;
//       }),
//     saveUser2: (user) => set({ user: null }),
//   }),
//   "auth"
// );

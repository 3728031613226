import styles from "../SearchWithHistory.module.css";
import cx from "classnames";
import { SearchHistory } from "api/other/models";
import { dateFns } from "utilities";
import filterIcon from "assets/images/imagesmode.svg";
import checkIcon from "assets/images/7g.svg";
import crossIcon from "assets/images/11.svg";
import { filtersConfig } from "../filtersConfig/filtersConfig";
import { useQuery } from "hooks";
import React from "react";
import { InputQuery } from "components/utils/queryProvider";

interface Props {
  active: boolean;
  additionalListParams?: {
    [x: string]: string;
  };
  log: SearchHistory;
  setHovered: React.Dispatch<React.SetStateAction<SearchHistory | undefined>>;
  setShowHistory: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FiltersQueryParams {
  [key: string]: string;
}

export const SearchResultLog = ({
  active,
  additionalListParams,
  log,
  setHovered,
  setShowHistory,
}: Props) => {
  const { setQuery } = useQuery();
  const hasFilters = Object.values(log.conditions).some(filter => {
    if (typeof filter === "string") {
      return filter.length > 0;
    }
    return filter;
  });

  if (additionalListParams) {
    Object.keys(additionalListParams).forEach(key => {
      if (log.conditions.hasOwnProperty(key)) {
        delete log.conditions[key];
      }
    });
  }

  return (
    <div
      className={cx(styles.searchResult, "px-3 py-2", {
        [styles.searchResultActive]: active,
      })}
      onClick={() => {
        setQueryUsingLog(log, setQuery);
        setShowHistory(false);
      }}
      onMouseEnter={() => setHovered(log)}
      onMouseLeave={() => setHovered(undefined)}
    >
      <div className="d-flex align-items-center justify-content-between pb-1">
        <div className={styles.phrase}>{log.phrase}</div>
        <div className={styles.date}>
          {isToday(log.createdAt) ? (
            <>{dateFns.format(new Date(log.createdAt), "HH:mm")}, dzisiaj</>
          ) : (
            <>{dateFns.format(new Date(log.createdAt), "dd.MM.yyyy")}</>
          )}
        </div>
      </div>
      {hasFilters && (
        <div className="d-flex align-items-center gap-1 pb-1 flex-wrap">
          {Object.entries(log.conditions).map(filter => (
            <React.Fragment key={filter[0]}>
              {(((typeof filter[1] === "string" || Array.isArray(filter[1])) &&
                filter[1].length > 0) ||
                typeof filter[1] === "number" ||
                typeof filter[1] === "boolean") && (
                <div
                  className={cx(styles.filterChip, {
                    [filtersConfig[filter[0]]?.className]:
                      filtersConfig[filter[0]] && filtersConfig[filter[0]].className !== undefined,
                  })}
                >
                  <img
                    alt="Ikona filtra"
                    src={filtersConfig[filter[0]] ? filtersConfig[filter[0]].icon! : filterIcon}
                  />
                  {Array.isArray(filter[1]) ? (
                    <>
                      {filter[1].length > 2 ? (
                        <div className="d-flex align-items-center">
                          {filter[1].slice(0, 2).join(",")} &nbsp;+{filter[1].length - 2}
                        </div>
                      ) : (
                        <div>{filter[1].join(",")}</div>
                      )}
                    </>
                  ) : typeof filter[1] === "boolean" ? (
                    <div>
                      {filter[1] === true ? (
                        <img alt="Prawda" src={checkIcon} />
                      ) : (
                        <img alt="Fałsz" src={crossIcon} />
                      )}
                    </div>
                  ) : (
                    <div>{filter[1]}</div>
                  )}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
      <div className="d-flex align-items-center gap-1">
        <div className={styles.labelText}>wówczas:</div>
        <div className={styles.result}>{log.results}</div>
      </div>
    </div>
  );
};

const isToday = (created: string): boolean => {
  const today = new Date().getDate();
  const createdDate = new Date(created).getDate();
  return createdDate === today;
};

const convertFiltersToParams = (
  filters: {
    [x: string]: string;
  }[],
): FiltersQueryParams => {
  const queryParams: FiltersQueryParams = {};
  filters.forEach(item => {
    const key = Object.keys(item)[0];
    const value = item[key];
    queryParams[key] = value;
  });
  return queryParams;
};

export const setQueryUsingLog = (
  log: SearchHistory,
  setQuery: (
    arg: InputQuery,
    stringifyOptions?:
      | {
          allowEmpty?: boolean | undefined;
        }
      | undefined,
  ) => void,
) => {
  const filters = Object.entries(log.conditions).map(filter => {
    if (filter[1] !== null) {
      const value = Array.isArray(filter[1]) ? filter[1].join(",") : String(filter[1]);
      return {
        [filter[0]]: value,
      };
    }
    return {
      [filter[0]]: "",
    };
  });
  const queryParams = convertFiltersToParams(filters);
  queryParams["search"] = log.phrase;

  setQuery(queryParams);
};

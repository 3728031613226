import advanceIcon from "assets/images/cashAdvance.svg";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { FilterList } from "../../../components/filtersSection/filterList/FilterList";
import { countryCodes, currenciesToPick } from "CONSTANTS";
import {
  paymentMethodsToPick,
  paymentProviderToPick,
  taxProcedureFilterOptions,
} from "api/trading-documents/models";
import { FilterType } from "components/common/filters/types";
import { AdvanceInvoices } from "routes/AdvanceInvoices";

export const advances: ModuleLink = {
  url: "advances",
  label: "Faktury Zaliczkowe",
  icon: advanceIcon,
  subSection: (
    <FilterList
      filters={[
        {
          type: FilterType.DateRange,
          label: "data sprzedaży",
          name: ["soldAfter", "soldBefore"],
          showMonths: true,
        },
        {
          type: FilterType.DateRange,
          label: "data wystawienia",
          name: ["fromInvoiceIssueDate", "toInvoiceIssueDate"],
          showMonths: true,
        },
        {
          type: FilterType.Select,
          label: "państwo",
          name: "countryCode",
          multiple: true,
          kind: "label",
          options: countryCodes.map(({ icon, value }) => ({
            label: value,
            value,
            icon,
          })),
        },
        {
          type: FilterType.Search,
          label: "konto sprzedażowe",
          name: "salesAccount",
          multiple: true,
          searchBy: "salesAccounts",
        },
        {
          type: FilterType.Search,
          label: "sprzedawcy",
          name: "sellers",
          multiple: true,
          searchBy: "businessEntities",
          overrides: {
            businessEntitiesKind: "INTERNAL",
          },
        },
        {
          type: FilterType.Select,
          label: "Czy odbiorca faktury ma NIP?",
          name: "hasRecipientTaxId",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          label: "Czy jest sfiskalizowane?",
          name: "isFiscalized",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          label: "Czy potrzebny paragon?",
          name: "canBeFiscalized",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          label: "status faktury",
          name: "status",
          options: [
            { label: "Zatwierdzone", value: "CONFIRMED" },
            { label: "Niezatwierdzone", value: "NEW" },
          ],
        },
        {
          type: FilterType.Select,
          kind: "label",
          label: "typ klienta",
          multiple: true,
          name: "kind",
          options: [
            { label: "detaliczny", value: "RETAIL" },
            { label: "hurtowy", value: "WHOLESALE" },
            { label: "dropshipping", value: "DROPSHIPPING" },
          ],
        },
        {
          type: FilterType.Select,
          kind: "label",
          label: "status drukowania",
          multiple: true,
          name: "correspondingReceiptPrintingStatus",
          options: [
            { label: "niewydrukowane", value: "NOT_STARTED" },
            { label: "w trakcie fiskalizacji", value: "IN_PROGRESS" },
            { label: "wydrukowane", value: "FINISHED" },
            { label: "niepowodzenie", value: "FAILED" },
          ],
        },
        {
          type: FilterType.Select,
          kind: "label",
          label: "procedura podatkowa",
          name: "taxProcedure",
          options: taxProcedureFilterOptions,
        },
        {
          type: FilterType.Select,
          label: "status płatności",
          name: "isPaid",
          options: [
            { label: "Opłacono", value: "true" },
            { label: "Nie opłacono", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          label: "waluta",
          name: "currency",
          kind: "label",
          options: currenciesToPick,
          multiple: true,
        },
        {
          type: FilterType.Text,
          label: "nr zlecenia kurierskiego",
          placeholder: "Szukaj nr zlecenia kurierskiego",
          name: "pickupId",
        },
        {
          type: FilterType.Text,
          label: "nr przesyłki",
          placeholder: "Szukaj nr przesyłki",
          name: "trackingNumber",
        },
        {
          type: FilterType.Select,
          label: "sposób płatności",
          name: "paymentType",
          options: paymentMethodsToPick,
        },
        {
          type: FilterType.Select,
          kind: "label",
          label: "forma płatności",
          multiple: true,
          name: "paymentProvider",
          options: paymentProviderToPick,
        },
        {
          type: FilterType.SelectUser,
          label: "kto się zajmuje?",
          multiple: true,
          name: "isAssigned",
          kind: "user",
          searchBy: "standardUsers",
        },
        {
          type: FilterType.Search,
          label: "kontrahent",
          name: "customers",
          multiple: true,
          searchBy: "customers",
        },
        {
          type: FilterType.Date,
          label: "data przelewu",
          name: "transferDate",
        },
        {
          type: FilterType.Select,
          label: "czy pobrano XML?",
          name: "isXmlOptimaDownloaded",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          label: "zaległe faktury",
          name: "isPaymentOverdue",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Text,
          label: "nr przelewu",
          placeholder: "Szukaj nr przelewu",
          name: "transferNumber",
        },
      ]}
    />
  ),
  routing: AdvanceInvoices,
};

import { RampListItem } from "api/wms/ramps/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  ramp: RampListItem;
}

export const TitleSection = ({ ramp }: Props) => {
  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <div className="d-flex align-items-center gap-2">
        <Typography fontSize="26" fontWeight="700">
          {ramp.name}
        </Typography>
      </div>
    </RightPanelSection>
  );
};

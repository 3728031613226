import { useLoadings } from "api/wms/hooks";
import styles from "../../Dashboard.module.css";
import { Link } from "react-router-dom";
import { Loading } from "api/wms/models";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useSearch } from "../../shared/useSearch";
import { useLoadingsListColumns } from "./useLoadingsListColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { Button } from "components/miloDesignSystem/atoms/button";

export const LoadingsList = () => {
  const search = useSearch();
  const { data: loadings, error, isPreviousData, isLoading } = useLoadings(search);
  const columns = useLoadingsListColumns();

  return (
    <div className={styles.card}>
      <div>
        <div className="d-flex align-items-center justify-content-between gap-1 px-3 py-3">
          <Typography className={styles.sectionTitle} fontSize="16" fontWeight="700">
            Załadunki
          </Typography>
          <Link to={"/wms/loadings/list/all"}>
            <Button
              className="text-uppercase"
              endIcon={MdiArrowForward}
              size="small"
              variant="gray"
            >
              Wszystkie załadunki
            </Button>
          </Link>
        </div>
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<Loading>
            rows={loadings}
            columns={columns}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={comfortableListUiSchema}
          />
        </div>
      </div>
    </div>
  );
};

import { orderKeys } from "api/orders/keys";
import {
  ItemsWithSingleQuantities,
  SingleItemQuantities,
  SingleItemQuantitiesDetails,
} from "api/orders/models";
import { Pagination, UUID } from "api/types";
import { useQueryUtils } from "hooks";
import { dateFns } from "utilities";

export const useModifyLabelDownloadedAt = () => {
  const { handleMutate } = useQueryUtils();

  const modifyLabelDownloadedStatus = (id: UUID, searchParams: string) => {
    handleMutate<Pagination<SingleItemQuantities>>(
      orderKeys.singleItemQuantities(searchParams),
      draft => {
        const toUpdate = draft.results.find(siqp => siqp.id === id);
        if (toUpdate) {
          toUpdate.labelDownloadedAt = dateFns.format(new Date(), "dd.MM.yyyy");
        }
      },
    );
  };

  const singleDetailsDownloadStatus = (id: UUID, searchParams: string) => {
    handleMutate<Pagination<SingleItemQuantitiesDetails>>(
      orderKeys.singleItemQuantitiesDetails(searchParams),
      draft => {
        const toUpdate = draft.results.find(siqp => siqp.id === id);
        if (toUpdate) {
          toUpdate.labelDownloadedAt = dateFns.format(new Date(), "dd.MM.yyyy");
        }
      },
    );
  };

  const modifyItemLabelDownloadStatus = (id: number, searchParams: string) => {
    handleMutate<Pagination<ItemsWithSingleQuantities>>(
      orderKeys.itemsWithSingleQuantities(searchParams),
      draft => {
        const toUpdate = draft.results.find(siqp => siqp.id === id);
        if (toUpdate) {
          toUpdate.customMadeLabelDownloadedAt = dateFns.format(new Date(), "dd.MM.yyyy");
        }
      },
    );
  };

  return {
    modifyItemLabelDownloadStatus,
    modifyLabelDownloadedStatus,
    singleDetailsDownloadStatus,
  };
};

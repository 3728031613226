import { ProductionPlan, SourcesSummary } from "api/new-production-plans/models";

export const isMoreThanOneUnitFromGivenSource = (
  deletedSourceSummary: SourcesSummary,
  productionPlan: ProductionPlan,
): boolean => {
  const numberOfUnitsFromGivenSource = productionPlan.employees.reduce((count, employee) => {
    return (
      count +
      employee.employeeWorkingDays.reduce((employeeWorkingDayCount, employeeWorkingDay) => {
        return (
          employeeWorkingDayCount +
          employeeWorkingDay.manufacturingWorkingUnits.filter(
            manufacturingWorkingUnit =>
              manufacturingWorkingUnit.source.id === deletedSourceSummary.id &&
              manufacturingWorkingUnit.source.signature === deletedSourceSummary.signature,
          ).length
        );
      }, 0)
    );
  }, 0);

  return numberOfUnitsFromGivenSource - 1 > 0;
};

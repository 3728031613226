import { RouteDeliveryPointsLinks } from "api/routes/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import CopyToClipboardComponent from "react-copy-to-clipboard";
import { Button } from "components/miloDesignSystem/atoms/button";
import styles from "./DeliveryPointsLinksModal.module.css";
import { useState } from "react";
import { cx } from "utilities";

export const useDeliveryPointsLinksColumns = () => {
  const [isAnimating, setIsAnimating] = useState(false);

  return useCreateTableColumns<RouteDeliveryPointsLinks>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "punkt startowy - punkt końcowy",
        size: 450,
        cell: info => {
          const pointsDetails = info.getValue();
          return (
            <div
              className={styles.points}
              onClick={event => {
                event.stopPropagation();
                window.open(pointsDetails.link, "_blank");
              }}
            >
              <Typography color="inherit" fontSize="12" fontWeight="700" noWrap>
                {pointsDetails.startingPoint} - {pointsDetails.endingPoint}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.link, {
        header: "skopiuj link do mapy punktów dostawy",
        size: 200,
        cell: info => {
          const link = info.getValue();
          return (
            <div className="w-100 d-flex align-items-center justify-content-center">
              <CopyToClipboardComponent text={link}>
                <div
                  className={cx("ml-1 position-relative", styles.icon)}
                  onClick={() => setIsAnimating(true)}
                >
                  <Button className={styles.copyBtn} size="small" variant="gray">
                    Skopiuj link do mapy
                  </Button>
                  <div
                    className={cx(styles.checkIcon, {
                      [styles.successCheckAnimation]: isAnimating,
                    })}
                    onAnimationEnd={() => setIsAnimating(false)}
                  />
                </div>
              </CopyToClipboardComponent>
            </div>
          );
        },
      }),
    ];
  });
};

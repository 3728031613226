import { shippingActions } from "api/shipping/actions";
import { ShipmentListItem, ShipmentStatus } from "api/shipping/models";
import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiPackageSend } from "components/miloDesignSystem/atoms/icons/MdiPackageSend";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { useQuery, useToggle } from "hooks";
import { cx, queryString } from "utilities";
import { useShippingShipmentsColumns } from "./useShippingShipmentsColumns";
import { SendShipmentToExternalModal } from "./components/sendShipmentToExternalModal/SendShipmentToExternal";
import { RightPanel } from "./rightPanel/RightPanel";
import { AssignCourier } from "./components/assignCourier/AssignCourier";
import { shippingShipments } from "components/common/moduleNavigation/moduleConfig/logistics/routes/shippingShipments";
import { RouteComponentProps } from "react-router";
import { MdiLocalShipping } from "components/miloDesignSystem/atoms/icons/MdiLocalShipping";
import { MdiCalendarClock } from "components/miloDesignSystem/atoms/icons/MdiCalendarClock";
import { AssignPlannedPickupArrivalModal } from "./components/assignPlannedPickupArrivalModal/AssignPlannedPickupArrivalModal";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { MdiCancel } from "components/miloDesignSystem/atoms/icons/MdiCancel";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";

export const ShippingShipments = ({
  match,
}: RouteComponentProps<{ tab: ShippingShipmentsTab }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const assignShipmentModalController = useToggle();
  const assignPlannedPickupArrivalController = useToggle();
  const sendShipmentToExternalController = useToggle();
  const downloadLabels = shippingActions.useDownloadShippingLabels();
  const cancelShipmentsMutation = shippingActions.useCancelShipments();
  const sendShipmentsToExternalMutation = shippingActions.useSendShipmentsToExternal();

  const { data: shipments, error, isLoading, pagination } = shippingActions.useShippingShipments(
    search,
  );

  const tableMultiSelect = useTableMultiSelect({ rows: shipments });

  const tableProps = useTableFeatureConnector({
    rows: shipments,
    withPagination: { pagination },
    withMultiSelect: tableMultiSelect,
    withDrawer: "shippingShipments",
  });

  const columns = useShippingShipmentsColumns();

  return (
    <>
      <PageWrapper>
        <PageHeader
          searchInput={{
            label: "Szukaj...",
            tags: [],
          }}
          bottomBarButtons={
            <Button
              size="small"
              variant="gray"
              onClick={() => {
                sendShipmentToExternalController.open();
              }}
              endIcon={MdiPackageSend}
              className="text-uppercase"
            >
              zamów kuriera na przesyłki
            </Button>
          }
          tabs={{
            list: getTabs(),
            routesRoot: `logistics/${shippingShipments.url}`,
            urlSpan: "list",
          }}
          viewLabel="SHIPPING_SHIPMENTS"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<ShipmentListItem>
            columns={columns}
            isLoading={isLoading}
            error={error}
            uiSchema={mainListUiSchema}
            {...tableProps}
            overrides={() => {
              return {
                ...tableProps?.overrides?.(),
                row: row => ({
                  className: cx(
                    {
                      dashedTableRow:
                        row.status === ShipmentStatus.CANCELED_SHIPMENT_SUCCESS ||
                        row.status === ShipmentStatus.CANCELLATION_IN_PROGRESS,
                    },
                    tableProps?.overrides?.().row?.(row).className,
                  ),
                }),
              };
            }}
          />

          <RightPanel />
          {sendShipmentToExternalController.isOpen && (
            <SendShipmentToExternalModal modal={sendShipmentToExternalController} />
          )}
          {assignShipmentModalController.isOpen && (
            <AssignCourier
              selectedRows={tableMultiSelect.selectedRows}
              modal={assignShipmentModalController}
              resetSelection={tableMultiSelect.clear}
              closePanel={assignShipmentModalController.close}
            />
          )}
          {assignPlannedPickupArrivalController.isOpen && (
            <AssignPlannedPickupArrivalModal
              selectedRows={tableMultiSelect.selectedRows}
              modal={assignPlannedPickupArrivalController}
              resetSelection={tableMultiSelect.clear}
              closePanel={assignPlannedPickupArrivalController.close}
            />
          )}
          <TableToolbar
            close={tableMultiSelect.clear}
            numberOfSelectedItems={tableMultiSelect.selectedRows.length}
          >
            <Tooltip title="Nadaj przesyłki">
              <IconButton
                icon={MdiPackageSend}
                isLoading={sendShipmentsToExternalMutation.isLoading}
                variant="transparent"
                theme="dark"
                onClick={() => {
                  sendShipmentsToExternalMutation.mutate({
                    shipments: tableMultiSelect.selectedRows.map(row => String(row.id)),
                  });
                }}
              />
            </Tooltip>
            <Tooltip title="Przypisz kuriera">
              <IconButton
                icon={MdiLocalShipping}
                variant="transparent"
                theme="dark"
                onClick={assignShipmentModalController.open}
              />
            </Tooltip>
            <Tooltip title="Przypisz datę odbioru przez kuriera">
              <IconButton
                icon={MdiCalendarClock}
                variant="transparent"
                theme="dark"
                onClick={assignPlannedPickupArrivalController.open}
              />
            </Tooltip>
            <Tooltip title="Pobierz etykiety">
              <IconButton
                icon={MdiQrCode}
                variant="transparent"
                theme="dark"
                onClick={() =>
                  downloadLabels(tableMultiSelect.selectedRows.map(row => String(row.id)))
                }
              />
            </Tooltip>
            <Tooltip title="Anuluj przesyłki">
              <IconButton
                icon={MdiCancel}
                variant="transparent"
                theme="dark"
                isLoading={cancelShipmentsMutation.isLoading}
                onClick={() => {
                  cancelShipmentsMutation.mutate({
                    shipments: tableMultiSelect.selectedRows.map(row => String(row.id)),
                  });
                }}
              />
            </Tooltip>
          </TableToolbar>
        </div>
      </PageWrapper>
    </>
  );
};

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  tab: ShippingShipmentsTab;
};

const getSearch = ({ query, tab }: GetSearchProps) => {
  const tabsQuery = {
    status: tab === "called-pickup-success" ? ShipmentStatus.CALLED_PICKUP_SUCCESS : "",
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

type ShippingShipmentsTab = "all" | "called-pickup-success";

const getTabs = (): { label: string; name: ShippingShipmentsTab }[] => {
  return [
    { label: "Wszystkie", name: "all" },
    { label: "Przesłane do kuriera", name: "called-pickup-success" },
  ];
};

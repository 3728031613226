import { combineReducers } from "redux";
import { reducer as auth, actions as authActions } from "./auth";
import { reducer as partials, actions as partialsActions } from "./partials";
import { externalManufacturerReducer } from "./partials";
import { reducer as ui, actions as uiActions } from "./ui";
import { reducer as toasters, actions as toastersActions } from "./toasters";
import { reducer as settings, actions as settingsActions } from "./settings";
import { persistReducer, createMigrate } from "redux-persist";
import localforage from "localforage";
import immer from "immer";

export type Store = ReturnType<typeof rootReducer>;

export const actions = {
  auth: authActions,
  partials: partialsActions,
  externalManufacturerPartials: externalManufacturerReducer,
  ui: uiActions,
  toasters: toastersActions,
  settings: settingsActions,
};

const partialsMigrations: any = {
  0: (state: Store["partials"]) => state,
  1: (state: Store["partials"]) => {
    return immer(state, draft => {
      draft.configuration.productCategoriesToCount = [];
    });
  },
  2: (state: Store["partials"]) => {
    return immer(state, draft => {
      draft.employees.forEach(el => {
        el.kind = "UPHOLSTERER";
      });
    });
  },
};

const uiMigrations: any = {
  0: (state: Store["ui"]) => state,
  1: (state: Store["ui"]) => {
    return immer(state, draft => {
      draft.lastAssignedUsers = [];
    });
  },
};

// "partials" reducer has its own persist config, because "fetching" field should never be persisted -
// without it in some conditions it can totally block updates until logout because "fetching"
// will be always true.
const partialsConfig = {
  key: "partials",
  storage: localforage,
  blacklist: ["fetching"],
  migrate: createMigrate(partialsMigrations, { debug: false }),
  version: 3,
};

const externalManufacturerPartialsConfig = {
  key: "externalManufacturerPartials",
  storage: localforage,
  blacklist: ["fetching"],
  version: 1,
};

const settingsConfig = {
  key: "settings",
  storage: localforage,
  version: 1,
};

const uiConfig = {
  key: "ui",
  storage: localforage,
  blacklist: ["communicationStatus", "isAppLoading"],
  migrate: createMigrate(uiMigrations, { debug: false }),
  version: 1,
};

export const rootReducer = combineReducers({
  auth,
  partials: persistReducer(partialsConfig, partials),
  externalManufacturerPartials: persistReducer(
    externalManufacturerPartialsConfig,
    externalManufacturerReducer,
  ),
  ui: persistReducer(uiConfig, ui),
  toasters,
  settings: persistReducer(settingsConfig, settings),
});

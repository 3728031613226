import { usePickings } from "api/wms/hooks";
import styles from "../../Dashboard.module.css";
import { Link } from "react-router-dom";
import { Picking } from "api/wms/models";
import { detailsListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useSearch } from "../../shared/useSearch";
import { usePickingsColumns } from "./usePickingsColumns";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Typography } from "components/miloDesignSystem/atoms/typography";

export const PickingList = () => {
  const search = useSearch();
  const { data: pickings, error, isPreviousData, isLoading } = usePickings(search);
  const columns = usePickingsColumns();

  return (
    <div className={styles.card}>
      <div>
        <div className="d-flex align-items-center justify-content-between gap-1 px-3 py-3">
          <Typography className={styles.sectionTitle} fontSize="16" fontWeight="700">
            Pickingi
          </Typography>
          <Link to={`/wms/pickings/list/all`}>
            <Button
              className="text-uppercase"
              endIcon={MdiArrowForward}
              size="small"
              variant="gray"
            >
              Wszystkie pickingi
            </Button>
          </Link>
        </div>
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<Picking>
            rows={pickings}
            columns={columns}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={detailsListUiSchema}
          />
        </div>
      </div>
    </div>
  );
};

import xIcon from "assets/images/11.svg";
import moonImg from "assets/images/178w.svg";
import penImg from "assets/images/1g.svg";
import dragImg from "assets/images/303.svg";
import binImg from "assets/images/81g.svg";
import cx from "classnames";
import { Button } from "components/common";
import { AsyncInput } from "components/utils";
import { useToggle } from "hooks";
import { useRoutePutMutation } from "hooks/apiPrimitives";
import { useRouteViewState } from "pages/routes/creator/routeCreatorState";
import { dateFns, secondsToTime } from "utilities";
import styles from "./Stopover.module.css";
import { OrderPositionTypes, Route } from "api/routes/models";
import { Draggable } from "react-beautiful-dnd";

interface Props {
  stopover: OrderPositionTypes["stopover"];
  startTime: string | null;
  route: Route;
  isLoading: boolean;
  index: number;
}

export const Stopover = ({ stopover, isLoading, startTime, route, index }: Props) => {
  const { hours, minutes } = secondsToTime(stopover.meta.delivery.time || 0);
  const updateMutation = useRoutePutMutation();
  const toggle = useToggle();
  const actions = useRouteViewState("slave", state => state.actions);
  const time = (() => {
    if (startTime) {
      const date = new Date(startTime);
      const endTime = dateFns.addMinutes(date, stopover.meta.delivery.time || 0);

      return { start: dateFns.format(date, "H:mm"), end: dateFns.format(endTime, "HH:mm") };
    } else {
      return null;
    }
  })();

  const handleRemoveStopover = () => {
    actions.openLoader("Trwa usuwanie postoju dobowego");
    const newOrdersPositions: Route["ordersPositions"] = route.ordersPositions.filter(
      el => el.id !== stopover.id,
    );

    updateMutation.mutate({
      data: {
        length: route.length,
        operation: null,
        returnToStartingPointDistance: route.returnDistance,
        returnToStartingPointTime: String(route.returnTime || 0),
        ordersPositions: newOrdersPositions,
        shouldCalculateAverageSpeed: false,
      },
      route: route.id,
    });
  };

  const updateMinutes = (seconds: number) => {
    const newOrdersPositions: Route["ordersPositions"] = route.ordersPositions.map(el => {
      if (el.id === stopover.id) {
        const newDuration = (() => {
          const hours = Math.floor((el.meta.delivery.time || 0) / 3600);

          const secondsToAdd = areSecondsGreaterThan59Minutes(seconds)
            ? minutesToSeconds(59)
            : seconds;

          return hoursToSeconds(hours) + secondsToAdd;

          function hoursToSeconds(hours: number) {
            return hours * 60 * 60;
          }

          function minutesToSeconds(minutes: number) {
            return minutes * 60;
          }

          function areSecondsGreaterThan59Minutes(seconds: number) {
            return seconds > 59 * 60;
          }
        })();

        return {
          ...el,
          meta: { ...el.meta, delivery: { ...el.meta.delivery, time: newDuration } },
        };
      }

      return el;
    });

    handleUpdateStopoverTime(newOrdersPositions);
  };

  const updateHours = (seconds: number) => {
    actions.openLoader("Trwa zmiana czasu postoju dobowego");
    const newOrdersPositions: Route["ordersPositions"] = route.ordersPositions.map(el => {
      if (el.id === stopover.id) {
        const newDuration = (() => {
          const minutes = Math.floor(((el.meta.delivery.time || 0) % 3600) / 60);

          return seconds + minutes * 60;
        })();

        return {
          ...el,
          meta: { ...el.meta, delivery: { ...el.meta.delivery, time: newDuration } },
        };
      }

      return el;
    });

    handleUpdateStopoverTime(newOrdersPositions);
  };

  const handleUpdateStopoverTime = (newOrdersPositions: Route["ordersPositions"]) => {
    updateMutation.mutate({
      data: {
        length: route.length,
        operation: null,
        returnToStartingPointDistance: route.returnDistance,
        returnToStartingPointTime: String(route.returnTime || 0),
        ordersPositions: newOrdersPositions,
        shouldCalculateAverageSpeed: false,
      },
      route: route.id,
    });
  };

  return (
    <Draggable draggableId={String(stopover.id)} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <div className="d-flex">
            <div className={styles.dotContainer}>
              <div className={cx(styles.greyDot, { invisible: snapshot.isDragging })}></div>
            </div>
            <div
              className={cx(styles["stopover-point"], styles["day-stopover-point"], {
                [styles.dragging]: snapshot.isDragging,
              })}
            >
              <div className="d-flex w-100">
                <div className="d-flex align-items-center w-100">
                  <img src={moonImg} className="mr-2" alt="" />
                  <label htmlFor="" className={cx("mr-2", styles.label)}>
                    postój dobowy:
                  </label>
                  <div className="mr-1">
                    {hours}h {Boolean(minutes) && <span>{minutes}min</span>}
                  </div>
                  {time ? (
                    <span className={cx("mr-1", styles.label, styles.timeRange)}>
                      ok. {time.start} - {time.end}
                    </span>
                  ) : (
                    <span />
                  )}
                </div>
                <div className={styles.buttons}>
                  <Button
                    kind="secondary"
                    size="round-s"
                    disabled={isLoading}
                    onClick={handleRemoveStopover}
                  >
                    <img src={binImg} alt="" />
                  </Button>
                  <Button
                    kind="secondary"
                    size="round-s"
                    disabled={isLoading}
                    onClick={toggle.open}
                  >
                    <img src={penImg} alt="" />
                  </Button>
                </div>

                <div className={styles.dragImg}>
                  <img src={dragImg} alt="" />
                </div>
              </div>
              {toggle.isOpen && (
                <div className={styles.editForm}>
                  <div className="d-flex align-items-center ">
                    <img src={moonImg} className="mr-2" alt="" />
                    <div className={cx("mr-2", styles.label)}>postój dobowy:</div>
                    <div className="d-flex align-items-center mr-2">
                      <AsyncInput
                        look="common"
                        type="number"
                        disabled={false}
                        onChange={value => updateHours(value * 60 * 60)}
                        value={hours}
                        overwrites={{
                          input: { className: cx(styles.inputBox, "mx-1") },
                        }}
                      />
                      <span className="text-color-lightgrey fs-12">godz.</span>
                    </div>
                    <div className="d-flex align-items-center ">
                      <AsyncInput
                        look="common"
                        type="number"
                        disabled={isLoading}
                        onChange={value => updateMinutes(value * 60)}
                        value={minutes}
                        overwrites={{
                          input: { className: cx(styles.inputBox, "mx-1") },
                        }}
                      />

                      <span className="text-color-lightgrey fs-12">min</span>
                    </div>
                  </div>

                  <div className={cx(styles.buttons, "pr-0")}>
                    <Button kind="secondary" onClick={toggle.close}>
                      <img src={xIcon} alt="Zamknij" />
                    </Button>
                  </div>

                  <div className={styles.dragImg}>
                    <img src={dragImg} alt="" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

import { IndexFulfillment, RequestIndexProduction } from "api/indexes/models";
import cuid from "cuid";
import { Attributes } from "../attributes/Attributes";
import styles from "./IndexFulfillmentModal.module.css";
import cx from "classnames";
import { Button } from "..";
import { useListCheckboxes } from "hooks/useListCheckboxes";
import { Checkbox } from "components/utils";
import { useMutation } from "hooks/useMutation";
import { postAssignProduction, postAssignReservation } from "api/indexes/calls";
import { useQueryUtils, useToastr } from "hooks";
import { getAnyErrorKey } from "utilities";
import { indexesKeys } from "api/indexes/keys";
import { UseMutationResult } from "react-query";
import { QueryFetchError } from "api/types";

interface Props {
  checkboxes: ReturnType<typeof useListCheckboxes>;
  index: IndexFulfillment;
  requestProductionMutation: UseMutationResult<
    IndexFulfillment[],
    QueryFetchError,
    RequestIndexProduction[],
    unknown
  >;
  showCheckbox: boolean;
  source: "route" | "order-group";
  sourceId: number;
}

export const IndexItem = ({
  checkboxes,
  index,
  requestProductionMutation,
  showCheckbox,
  source,
  sourceId,
}: Props) => {
  const toastr = useToastr();
  const { handlePaginatedListUpdate } = useQueryUtils();

  const assignProductionMutation = useMutation(
    () => postAssignProduction(source, sourceId, { indexId: index.id }),
    {
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      },
      onSuccess: payload => {
        handlePaginatedListUpdate(indexesKeys.indexesFulfillment(), payload.id, payload);

        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Przypisano produkcję dla indeksu "${index.name}"`,
        });
      },
    },
  );

  const assignReservationMutation = useMutation(
    () => postAssignReservation(source, sourceId, { indexId: index.id }),
    {
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      },
      onSuccess: payload => {
        handlePaginatedListUpdate(indexesKeys.indexesFulfillment(), payload.id, payload);

        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Przypisano rezerwację dla indeksu "${index.name}"`,
        });
      },
    },
  );

  return (
    <div className="row border-bottom">
      <div className="col-4 pt-1 pb-1 d-flex align-items-center">
        {showCheckbox && (
          <div>
            <Checkbox
              checked={checkboxes.check(index.id)}
              name=""
              onChange={() => checkboxes.toggle(index)}
            />
          </div>
        )}
        <div>
          <div className={styles.indexName}>{index.name}</div>
          {index.isSelfProduced && (
            <span className="fs-12 text-color-grey">(produkcja własna)</span>
          )}
          {index.attributes && (
            <Attributes
              attributes={index.attributes.map(attribute => {
                return {
                  id: cuid(),
                  name: attribute.attribute,
                  value: attribute.value,
                };
              })}
            />
          )}
        </div>
      </div>
      <div className="col-1 d-flex justify-content-center align-items-center">
        {index.counters.requested}
      </div>
      <div
        className={cx(
          styles.colBackground,
          "col-1 d-flex flex-column justify-content-center align-items-center",
        )}
      >
        {index.counters.available}
        <Button kind="secondary-grey" onClick={assignReservationMutation.mutate} size="small">
          Rezerwuj
        </Button>
      </div>
      <div className="col-1 d-flex justify-content-center align-items-center">
        {index.counters.reservedForCollection}
      </div>
      <div
        className={cx(
          styles.colBackground,
          "col-1 d-flex flex-column justify-content-center align-items-center",
        )}
      >
        {index.counters.inProduction}
        <Button kind="secondary-grey" size="small" onClick={assignProductionMutation.mutate}>
          Przypisz
        </Button>
      </div>
      <div className="col-1 d-flex justify-content-center align-items-center">
        {index.counters.inProductionForCollectionActive}
      </div>
      <div
        className={cx(
          styles.colBackground,
          "col-1 d-flex justify-content-center align-items-center",
        )}
      >
        {index.counters.inProductionForCollectionFinished}
      </div>
      <div className="col-1 d-flex justify-content-center align-items-center">
        {index.counters.missing}
      </div>
      <div className="col-1 d-flex justify-content-center align-items-center">
        <Button
          disabled={index.counters.missing <= 0}
          kind="primary"
          size="small"
          onClick={() =>
            requestProductionMutation.mutate([
              {
                indexId: index.id,
                isSelfProduced: index.isSelfProduced,
                manufacturer: index.manufacturer ? index.manufacturer.id : null,
                number: index.counters.missing,
              },
            ])
          }
        >
          Zleć
        </Button>
      </div>
    </div>
  );
};

import { AddedMethodChoices, PackageType, ShippingPiece } from "api/shipping/models";
import {
  ColumnHelperArgs,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { shippingActions } from "api/shipping/actions";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { UUID } from "api/types";
import { memo, useState } from "react";
import { AsyncInput } from "components/utils";
import styles from "../../RightPanel.module.css";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { useQuery } from "hooks";
import { ShippingService } from "constants/shippingService";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Link } from "components/miloDesignSystem/atoms/link";
import { ColumnDef } from "@tanstack/react-table";

export const usePiecesColumns = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: shipment } = shippingActions.useShippingShipment(panelId, {
    enabled: Boolean(panelId),
  });
  const [editingId, setEditingId] = useState("");
  const patchPackage = shippingActions.usePatchShippingPiece();
  return useCreateTableColumns<ShippingPiece>(
    ({ columnHelper }) => {
      const columns = columnFactory(columnHelper, editingId, setEditingId);

      if (shipment?.shippingService?.provider === ShippingService.AMBRO) {
        return [
          columns.package(80),
          columns.weight(),
          columns.length(),
          columns.width(),
          columns.height(),
          columnHelper.accessor(row => row, {
            header: "typ paczki",
            size: 120,
            cell: info => {
              const row = info.getValue();
              return (
                <Select
                  items={Object.values(PackageType).map(option => ({
                    value: option,
                    text: option,
                    type: MenuItemType.TEXT,
                  }))}
                  onChange={ambroPackageType => {
                    assertIsDefined(ambroPackageType);

                    patchPackage.mutate({
                      id: row.id,
                      toUpdate: { ambroPackageType: ambroPackageType as PackageType },
                    });
                  }}
                  selected={row.ambroPackageType}
                />
              );
            },
          }),
          columns.isIncludedForSend(patchPackage),
          columns.deletePiece(),
        ];
      }

      if (shipment?.shippingService?.provider === ShippingService.GLS) {
        return [
          columns.package(300),
          columns.weight(),
          columnHelper.accessor(row => row.trackingNumber, {
            header: "numer śledzenia",
            size: 120,
            cell: info => {
              const trackingNumber = info.getValue();
              if (!trackingNumber) return <EmptyValue fontSize="14" fontWeight="700" />;
              return (
                <Link
                  fontSize="14"
                  fontWeight="700"
                  to={`https://gls-group.com/app/service/open/rest/PL/pl/rstt029?match=${trackingNumber}`}
                >
                  {trackingNumber}
                </Link>
              );
            },
          }),
          columns.isIncludedForSend(patchPackage),
          columns.deletePiece(),
        ];
      }

      if (shipment?.shippingService?.provider === ShippingService.MEBEL_TAXI) {
        return [
          columns.package(498),
          columns.isIncludedForSend(patchPackage),
          columns.deletePiece(),
        ];
      }

      return [
        columns.package(204),
        columns.weight(),
        columns.length(),
        columns.width(),
        columns.height(),
        columns.isIncludedForSend(patchPackage),
        columns.deletePiece(),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};

const columnFactory = (
  columnHelper: ColumnHelperArgs<ShippingPiece>,
  editingId: string,
  setEditingId: React.Dispatch<React.SetStateAction<string>>,
) => {
  return {
    package: (size: number) =>
      columnHelper.accessor(row => row, {
        id: "package",
        header: () => <HeaderCell label="paczka" />,
        size,
        cell: info => {
          const row = info.getValue();
          return (
            <EditableCell editingId={editingId} setEditingId={setEditingId} name="name" row={row} />
          );
        },
      }) as ColumnDef<ShippingPiece>,
    isIncludedForSend: (patchPackage: ReturnType<typeof shippingActions.usePatchShippingPiece>) =>
      columnHelper.accessor(row => row, {
        id: "isIncludedForSend",
        header: () => (
          <Typography fontSize="12" fontWeight="400" color="neutralBlack48" className="text-center">
            w przesyłce
          </Typography>
        ),
        size: 74,
        cell: info => {
          const value = info.getValue();
          return (
            <div className="d-flex align-items-center justify-content-center flex-1">
              <Checkbox
                checked={value.isIncludedForSend}
                onChange={isIncludedForSend => {
                  patchPackage.mutate({
                    id: value.id,
                    toUpdate: { isIncludedForSend },
                  });
                }}
              />
            </div>
          );
        },
      }) as ColumnDef<ShippingPiece>,
    deletePiece: () =>
      columnHelper.accessor(row => row, {
        header: " ",
        size: 26,
        cell: info => {
          const row = info.getValue();
          return <DeletePieceCell row={row} />;
        },
      }) as ColumnDef<ShippingPiece>,
    weight: () =>
      columnHelper.accessor(row => row, {
        id: "weight",
        header: () => <HeaderCell label="waga" />,
        size: 74,
        cell: info => {
          const row = info.getValue();
          return (
            <EditableCell
              editingId={editingId}
              setEditingId={setEditingId}
              name="weight"
              row={row}
            />
          );
        },
      }) as ColumnDef<ShippingPiece>,
    length: () =>
      columnHelper.accessor(row => row, {
        id: "length",
        header: () => <HeaderCell label="długość" />,
        size: 74,
        cell: info => {
          const row = info.getValue();
          return (
            <EditableCell
              editingId={editingId}
              setEditingId={setEditingId}
              name="length"
              row={row}
            />
          );
        },
      }) as ColumnDef<ShippingPiece>,
    width: () =>
      columnHelper.accessor(row => row, {
        id: "width",
        header: () => <HeaderCell label="szerokość" />,
        size: 74,
        cell: info => {
          const row = info.getValue();
          return (
            <EditableCell
              editingId={editingId}
              setEditingId={setEditingId}
              name="width"
              row={row}
            />
          );
        },
      }) as ColumnDef<ShippingPiece>,
    height: () =>
      columnHelper.accessor(row => row, {
        id: "height",
        header: () => <HeaderCell label="wysokość" />,
        size: 74,

        cell: info => {
          const row = info.getValue();
          return (
            <EditableCell
              editingId={editingId}
              setEditingId={setEditingId}
              name="height"
              row={row}
            />
          );
        },
      }) as ColumnDef<ShippingPiece>,
  };
};

const DeletePieceCell = ({ row }: { row: ShippingPiece }) => {
  const deletePieceMutation = shippingActions.useDeleteShippingPiece();
  return (
    <div className="d-flex align-items-center justify-content-center flex-1">
      <IconButton
        icon={MdiDelete}
        disabled={
          row.addedMethod === AddedMethodChoices.AUTOMATICALLY || deletePieceMutation.isLoading
        }
        variant="transparent"
        onClick={() => {
          deletePieceMutation.mutate(row.id);
        }}
      />
    </div>
  );
};
const HeaderCell = ({ label }: { label: string }) => {
  return (
    <Typography fontSize="12" fontWeight="400" color="neutralBlack48" className="ml-2">
      {label}
    </Typography>
  );
};

const EditableCell = ({
  row,
  name,
  editingId,
  setEditingId,
}: {
  name: keyof ShippingPiece;
  row: ShippingPiece;
  editingId: string;
  setEditingId: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const value = String(row[name]);

  const isEditing = getEditingId(row.id, name) === editingId;

  if (!isEditing) {
    const unit = (() => {
      if (name === "weight") return "kg";
      if (name === "name") return "";
      return "cm";
    })();

    return (
      <div onClick={() => setEditingId(getEditingId(row.id, name))} className={styles.editableCell}>
        <Typography
          fontSize={name === "name" ? "14" : "12"}
          fontWeight="600"
          noWrap
          {...getExcludedProps(row)}
        >
          {value} {unit}
        </Typography>
      </div>
    );
  }
  return (
    <EditInput name={name} rowId={row.id} value={value} exitEditingMode={() => setEditingId("")} />
  );
};

const EditInput = memo(
  ({
    name,
    rowId,
    value,
    exitEditingMode,
  }: {
    rowId: UUID;
    name: keyof ShippingPiece;
    value: string;
    exitEditingMode: () => void;
  }) => {
    const patchPiece = shippingActions.usePatchShippingPiece();
    return (
      <AsyncInput
        disabled={patchPiece.isLoading}
        autoFocus
        inProgress={patchPiece.isLoading}
        onChange={async value => {
          await patchPiece.mutateAsync({
            id: rowId,
            toUpdate: { [name]: value },
          });
          exitEditingMode();
        }}
        overwrites={{
          input: { className: styles.input },
        }}
        placeholder={value}
        value={value}
      />
    );
  },
);

const getExcludedProps = (row: ShippingPiece): Pick<TypographyProps, "color" | "className"> => {
  if (!row.isIncludedForSend) {
    return { color: "neutralBlack48", className: "line-through" };
  }
  return {};
};

function getEditingId(rowId: string, name: keyof ShippingPiece) {
  return `${rowId}-${name}`;
}

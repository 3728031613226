import { Autocomplete, AutocompleteAsyncHandler, Label } from "components/utils";
import { getProductsLight } from "api/products/calls";
import defaultImage from "assets/images/53.svg";
import { FLAVOR } from "CONSTANTS";
import { getCustomerOffers } from "api/product-sets/calls";
import { AdaptedOrder } from "./ProductForm";
import { ProductLight } from "api/products/models";
import styles from "./ProductForm.module.css";
import { useSelector } from "hooks";

interface Props {
  order: AdaptedOrder;
  setProductToFetch: any;
}

export const SearchProduct = ({ order, setProductToFetch }: Props) => {
  const canUseAllProductsWithCustomer = useSelector(
    state => state.partials.canUseAllProductsWithCustomer,
  );
  const transformQuery = (query: Record<string, string>) => {
    if (!order.customer || order.customer.canAddNewIndexes) {
      return query;
    }

    return {
      ...query,
      productSetCustomer: String(order.customer.id),
    };
  };

  return (
    <>
      {FLAVOR === "main" && (
        <div className="mb-3">
          <AutocompleteAsyncHandler
            transform={(item: ProductLight) => {
              return {
                ...item,
                name: (
                  <SearchItem
                    item={item}
                    canUseAllProductsWithCustomer={canUseAllProductsWithCustomer}
                  />
                ),
              };
            }}
            fetchFrom={getProductsLight}
            transformQuery={transformQuery}
          >
            <Autocomplete
              placeholder="Szukaj produktu"
              overrides={{ selectedItem: { className: styles.searchItem } }}
              onChange={products => {
                setProductToFetch(products[0] || null);
              }}
              isItemDisabled={(item: any) =>
                Boolean(order.customer) &&
                !Boolean(order.customer?.canAddNewIndexes) &&
                !item.isInProductSet &&
                !canUseAllProductsWithCustomer
              }
              multiple={false}
            />
          </AutocompleteAsyncHandler>
        </div>
      )}

      {FLAVOR === "b2b" && (
        <div className="mb-3">
          <AutocompleteAsyncHandler
            fetchFrom={order.customer!.canAddNewIndexes ? getProductsLight : getCustomerOffers}
            transform={(item: any) => {
              if (order.customer!.canAddNewIndexes) {
                return { ...item, name: <SearchItem item={item} /> };
              }
              return {
                id: item.productId,
                productId: item.productId,
                amount: null,
                name: <SearchItem item={item} />,
              };
            }}
          >
            <Autocomplete
              overrides={{ selectedItem: { className: styles.searchItem } }}
              selectedItems={[]}
              removeMode={false}
              placeholder="Szukaj produktu"
              onChange={(val: any) => {
                setProductToFetch(val[0]);
              }}
              multiple={false}
            />
          </AutocompleteAsyncHandler>
        </div>
      )}
    </>
  );
};

export const SearchItem = ({
  item,
  canUseAllProductsWithCustomer,
}: {
  item: ProductLight;
  canUseAllProductsWithCustomer?: boolean;
}) => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className={styles.picture}>
            <img src={item.picture || defaultImage} alt="" />
          </div>
          <div className="mr-2">{item.name}</div>
        </div>
        {canUseAllProductsWithCustomer && item.isInProductSet && (
          <div className="mr-2">
            <Label color="blue">asortyment</Label>
          </div>
        )}
        {item.kind === "set" && (
          <div>
            <Label color="pink">zestaw</Label>
          </div>
        )}
      </div>
    </div>
  );
};

import { differenceInHours, differenceInMinutes } from "date-fns";

export const isUnloadingLate = (date: string) => {
  const today = new Date();
  const minutesDiff = differenceInMinutes(new Date(date), today);
  const hoursDiff = differenceInHours(new Date(date), today);

  if (hoursDiff < 0 || minutesDiff < 0) return true;
  return false;
};

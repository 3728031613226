import styles from "../PaymentSection.module.css";
import cx from "classnames";
import { TradingDocument } from "api/trading-documents/models";
import { useToggle } from "hooks";
import { AddPaymentModal } from "../addPaymentModal/AddPaymentModal";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { MdiFolderOpen } from "components/miloDesignSystem/atoms/icons/MdiFolderOpen";

interface Props {
  tradingDocument: TradingDocument;
}

export const EmptyPaymentSection = ({ tradingDocument }: Props) => {
  const addPaymentModal = useToggle();

  return (
    <div className="pb-3">
      <div className={styles.sectionEmpty}>
        <MdiFolderOpen color="neutralBlack48" />
        <Typography className={cx(styles.sectionEmptyTitle, "pt-2")} fontSize="12" fontWeight="700">
          Dokument jeszcze nie zawiera żadnych płatności
        </Typography>
        <div className="py-2">
          <Button
            className="text-uppercase"
            onClick={addPaymentModal.open}
            size="small"
            startIcon={MdiAdd}
            variant="gray"
          >
            Dodaj pierwszą płatność
          </Button>
        </div>
      </div>
      {addPaymentModal.isOpen && (
        <AddPaymentModal close={addPaymentModal.close} tradingDocument={tradingDocument} />
      )}
    </div>
  );
};

import { RouteListItem } from "api/routes/models";
import localStyles from "./RoutesList.module.css";
import cx from "classnames";
import { Checkbox } from "components/utils";
import { CheckedElement } from "../../../leftToolbar/LeftToolbar";
import { dateFns } from "utilities";

interface Props {
  checkedRoutes: CheckedElement[];
  setCheckedRoutes: React.Dispatch<React.SetStateAction<CheckedElement[]>>;
  routes: RouteListItem[];
}

export const RoutesList = ({ checkedRoutes, setCheckedRoutes, routes }: Props) => {
  return (
    <div className={localStyles.routesList}>
      {routes.map(route => {
        return (
          <div
            className={cx(
              localStyles.routeRow,
              "d-flex align-items-center justify-content-between p-2 w-100",
            )}
            key={route.id}
          >
            <div className="d-flex align-items-center" style={{ width: "60%" }}>
              <div
                className={localStyles.checkbox}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Checkbox
                  checked={checkedRoutes.some(checkedRoute => {
                    return checkedRoute.id === route.id && checkedRoute.checked === true;
                  })}
                  name=""
                  onChange={() =>
                    setCheckedRoutes(prevCheckedRoutes => {
                      const routeToToggle = prevCheckedRoutes.find(
                        prevCheckedRoute => prevCheckedRoute.id === route.id,
                      );
                      const otherPrevRoutes = prevCheckedRoutes.filter(
                        prevCheckedRoute => prevCheckedRoute.id !== route.id,
                      );

                      return [
                        ...otherPrevRoutes,
                        { checked: !routeToToggle?.checked, id: routeToToggle!.id },
                      ];
                    })
                  }
                />
              </div>
              <div>{route.signature}</div>
            </div>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ width: "40%" }}
            >
              <div className="d-flex justify-content-center" style={{ width: "50%" }}>
                {route.created
                  ? dateFns.format(new Date(route.created.slice(0, 10)), "dd-MM-yyyy")
                  : "- - -"}
              </div>
              <div className="d-flex justify-content-center" style={{ width: "50%" }}>
                {route.departureDate ? route.departureDate : "- - -"}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import cx from "classnames";
import styles from "./AvatarWithName.module.css";
import { UserShortType } from "api/types";

interface Props {
  user: UserShortType;
  className?: string;
  cutOverflowPx?: string;
}

export const AvatarWithName = ({ user, className, cutOverflowPx = "180px" }: Props) => {
  if (!user) return null;
  const fullName = user.firstName + " " + user.lastName;

  return (
    <div className={cx("d-flex align-items-center gap-2", className)}>
      <AvatarOrInitials
        avatarSrc={user.avatar}
        firstName={user.firstName}
        lastName={user.lastName}
        className={cx("avatar avatarSmall m-0", styles.avatarDefaultBackground)}
      />
      <div style={{ width: cutOverflowPx }} className="body14 cut-text">
        {fullName}
      </div>
    </div>
  );
};

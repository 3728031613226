import React from "react";

export const handleRightPanelScroll = (
  setIsScrolledOver: React.Dispatch<React.SetStateAction<boolean>>,
  scrollRef: React.RefObject<HTMLDivElement> | undefined,
) => {
  const scrollPosition = scrollRef?.current?.scrollTop;
  const breakPointDistanceFromTop = 34;
  if (scrollPosition && scrollPosition > breakPointDistanceFromTop) {
    setIsScrolledOver(true);
  } else {
    setIsScrolledOver(false);
  }
};

import { Button, PageHeader } from "components/common";
import downloadFileIcon from "assets/images/downloadFile.svg";
import uploadFileIcon from "assets/images/uploadFile.svg";
import visibilityOffIcon from "assets/images/eye_off.svg";
import areaChartIcon from "assets/images/areaChart.svg";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { manufacturingPlans } from "components/common/moduleNavigation/moduleConfig/manufacturing/routes/manufacturingPlans";
import { getTabs } from "../utils/getTabs";

export const ListHeader = ({ open }: { open: () => void }) => {
  return (
    <div>
      <PageHeader
        tabs={{
          list: getTabs(),
          routesRoot: `manufacturing/${manufacturingPlans.url}`,
          urlSpan: "list",
        }}
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Importuj plik"
                  src={uploadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Pobierz plik"
                  src={downloadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <span className="line-divider lineDividerMargin" />
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Wyłącz widoczność"
                  src={visibilityOffIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img alt="Wykres" src={areaChartIcon} style={{ height: "16px", width: "16px" }} />
              </div>
            </Button>
          </div>
        }
        createButton={{
          alt: "Plus",
          img: darkPlusIcon,
          label: "Utwórz plan produkcyjny",
          onClick: open,
        }}
        searchInput={{
          label: "Szukaj wśród planów produkcyjnych",
          tags: [],
        }}
        viewLabel="MANUFACTURING_PLANS"
      />
    </div>
  );
};

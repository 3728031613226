import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { wmsLayoutApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";
import { useMutation } from "hooks/useMutation";
import { UUID } from "api/types";
import { AssignField } from "./models";
import { assertIsDefined } from "utilities/assertIsDefined";
import { wmsLayoutKeys } from "./keys";

const useRouteLayoutCollection = createApiQuery(wmsLayoutApi.getRouteLayoutCollection);
const useRouteSearchCollection = createApiQuery(wmsLayoutApi.getRouteSearchCollection);
const useRampStatus = createApiQuery(wmsLayoutApi.getRampStatus);
const useFillingStatus = createApiQuery(wmsLayoutApi.getFillingStatus);
const useLayoutSchema = createApiQuery(wmsLayoutApi.getLayoutSchema);
const useLayoutField = createApiQuery(wmsLayoutApi.getLayoutField);
const usePackagesInLocation = createPaginatedApiQuery(wmsLayoutApi.getPackagesInLocation);

const useFieldPatch = (hallId: number) => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID; toUpdate: AssignField }) => {
      return wmsLayoutApi.patchField(toUpdate, id);
    },
    ({ queryUtils, queryClient }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(
          wmsLayoutKeys.getLayoutField(hallId, id),
          toUpdate,
        );
        return { prevPanel };
      },
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(
          wmsLayoutKeys.getLayoutField(hallId, id),
          onMutateReturn.prevPanel,
          error,
        );
      },
    }),
  );
};

export const wmsLayoutActions = {
  useRouteLayoutCollection,
  useRampStatus,
  useFillingStatus,
  useRouteSearchCollection,
  useLayoutSchema,
  useLayoutField,
  usePackagesInLocation,
  useFieldPatch,
};

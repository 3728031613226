import { useMemo, useRef, useEffect } from "react";
import packageIcon from "assets/images/49.svg";
import { Link, useLocation } from "react-router-dom";
import createOrderIcon from "assets/images/31.svg";
import createSmsIcon from "assets/images/174.svg";
import createRouteIcon from "assets/images/32.svg";
import productionPlanIcon from "assets/images/219.svg";
import createUserIcon from "assets/images/30.svg";
import driverIcon from "assets/images/190g.svg";
import createEmployeeIcon from "assets/images/188.svg";
import createClientIcon from "assets/images/34.svg";
import createProductIcon from "assets/images/33.svg";
import createPermissionIcon from "assets/images/223.svg";
import createAttributeIcon from "assets/images/75g.svg";
import createTeamIcon from "assets/images/73g.svg";
import createOfferIcon from "assets/images/79.svg";
import createSalesAccountIcon from "assets/images/86g.svg";
import createManufacturerIcon from "assets/images/140g.svg";
import createCarIcon from "assets/images/24.svg";
import hideDrawerIcon from "assets/images/29.svg";
import styles from "./CreateButtonAside.module.css";
import cx from "classnames";
import { FLAVOR } from "CONSTANTS";
import { useSelector } from "hooks";

interface Props {
  controls: {
    isOpen: boolean;
    toggle: () => void;
    setState: (arg: boolean) => void;
  };
  mainMenuOpened: boolean;
}

export const CreateButtonsAside = ({ controls, mainMenuOpened }: Props) => {
  const { isOpen, toggle, setState } = controls;
  const isWarehouseInUse = useSelector(state => state.partials.configuration.isWarehouseInUse);
  const location = useLocation();
  const initialMount = useRef(true);

  /**
   * Effect closes menu on location change
   */
  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
      return;
    }
    setState(false);
  }, [location, setState]);

  const marginLength = useMemo(() => {
    const minimizedMenuWidth = 70;
    const maximizedMenuWidth = 220;
    const marginMain = isOpen ? maximizedMenuWidth : 0;
    const marginSelf = mainMenuOpened ? 0 : -maximizedMenuWidth + minimizedMenuWidth;
    return marginMain + marginSelf;
  }, [mainMenuOpened, isOpen]);

  return (
    <div>
      <div className={cx(styles.createMenu)} style={{ left: marginLength }}>
        <div className={styles.createMenuInner}>
          <button className={styles.btnCircular} onClick={toggle}>
            <img src={hideDrawerIcon} alt="" />
          </button>
        </div>
        <div className={styles.createMenuLinks} data-testid="createItem">
          <h5>Utwórz...</h5>
          {FLAVOR === "main" && (
            <>
              <Link to="/orders/create">
                <img src={createOrderIcon} alt="" />
                <div>Zamówienie</div>
              </Link>
              <Link to="/routes/creator">
                <img src={createRouteIcon} alt="" />
                <div>Trasę</div>
              </Link>
              <Link to="/customers/create">
                <img src={createClientIcon} alt="" />
                <div>Kontrahenta</div>
              </Link>
              <Link to="/products/create">
                <img src={createProductIcon} alt="" />
                <div>Produkt</div>
              </Link>
              <Link to="/attributes/create">
                <img src={createAttributeIcon} alt="" />
                <div>Atrybut</div>
              </Link>
              <Link to="/users/create">
                <img src={createUserIcon} alt="" />
                <div>Użytkownika</div>
              </Link>
              <Link to="/">
                <img src={createTeamIcon} alt="" />
                <div>Zespół</div>
              </Link>
              <Link to="/users/permission-groups/create">
                <img src={createPermissionIcon} alt="" />
                <div>Grupę uprawnień</div>
              </Link>
              <Link to="/offers/create">
                <img src={createOfferIcon} alt="" />
                <div>Ofertę</div>
              </Link>
              <Link to="/sales-accounts/create">
                <img src={createSalesAccountIcon} alt="" />
                <div>Konto sprzedażowe</div>
              </Link>
              <Link to="/products/categories/create">
                <img src={createProductIcon} alt="" />
                <div>Kategorię produktową</div>
              </Link>
              <Link to="/manufacturers/create">
                <img src={createManufacturerIcon} alt="" />
                <div>Producenta</div>
              </Link>
              <Link to="/cars/create">
                <img src={createCarIcon} alt="" />
                <div>Samochód</div>
              </Link>
              <Link to="/sms-messages/create">
                <img src={createSmsIcon} alt="" />
                <div>SMS</div>
              </Link>
              <Link to="/upholstery/employee/create">
                <img src={createEmployeeIcon} alt="" />
                <div>Pracownika</div>
              </Link>
              <Link to="/drivers/create">
                <img src={driverIcon} alt="" />
                <div>Kierowcę</div>
              </Link>
              <Link to="/production-plans/create">
                <img src={productionPlanIcon} alt="" />
                <div>Plan produkcyjny</div>
              </Link>
              {isWarehouseInUse && (
                <Link to="/warehouse/packages/create">
                  <img src={packageIcon} alt="" />
                  <div>Paczkę</div>
                </Link>
              )}
            </>
          )}
          {FLAVOR === "b2b" && (
            <>
              <Link to="/client/orders/create">
                <img src={createOrderIcon} alt="" />
                <div>Zamówienie</div>
              </Link>
            </>
          )}
          {FLAVOR === "manufacturing" && (
            <>
              <Link to="/manufacturing/routes/creator">
                <img alt="Utwórz trasę" src={createRouteIcon} />
                <div>Trasę</div>
              </Link>
              {/* <Link to="/manufacturing/order-groups/create"></Link> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

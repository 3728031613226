import axios, { AxiosError } from "axios";
import { DOMAIN } from "ENDPOINTS";
import { SearchParams } from "hooks/createPaginatedQuery";
import { flattenErrors } from "utilities";
import { Assign } from "utility-types";
import { CommunicationStatusController } from "./CommunicationStatusController";
import { tokenMiddlewareController } from "./tokenMiddlewareController";
import { tokenMeta, tokenRefresher } from "./tokenRefresher";
import { getLang, removeSlash } from "./utils";

interface Params {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  headers?: { [key: string]: string };
  data?: unknown;
  origin?: string;
  tokenMiddleware?: boolean;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}

export function queryFetch<P>({
  url,
  method = "GET",
  headers = {},
  params = {},
  data,
  origin,
  tokenMiddleware = true,
  onUploadProgress,
}: Assign<Params, { params?: SearchParams }>): Promise<P> {
  if (tokenMiddlewareController.promise) {
    async function getControllerPromise() {
      await tokenMiddlewareController.promise;
    }
    getControllerPromise();
  }
  tokenMiddlewareController.promise = new Promise<void>(resolve => {
    tokenMiddlewareController.resolve = resolve;
  });
  const currentToken = localStorage.getItem("token");
  if (currentToken) {
    const tokenRefresh = async () => {
      const tokenRefreshStatus = await tokenRefresher(currentToken);
      tokenMiddlewareController.resolve?.();
      if (tokenRefreshStatus === "failure") {
        return Promise.resolve({} as P);
      }
    };
    tokenRefresh();
  }
  tokenMiddlewareController.resolve?.();

  const allHeaders: { [key: string]: string } = {
    "Accept-Language": getLang(),
    ...headers,
  };
  const token = localStorage && localStorage.token;
  const endpoint = (origin || DOMAIN) + removeSlash(url);
  if (token && tokenMiddleware) {
    // if user is logged in - add token to request headers
    allHeaders.Authorization = "Bearer " + tokenMeta.accessToken.token;
  }
  if (data instanceof FormData === false) {
    allHeaders["Content-Type"] = "application/json";
  }

  const source = axios.CancelToken.source();

  CommunicationStatusController.increment();

  const promise = axios(endpoint, {
    cancelToken: source.token,
    data: (() => {
      if (!data) return undefined;
      if (data instanceof FormData) return data;
      return JSON.stringify(data);
    })(),
    headers: allHeaders,
    method,
    params,
    onUploadProgress,
  })
    .then(el => el.data)
    .catch((err: AxiosError) => {
      if (err.response?.headers["content-type"]?.includes("application/json")) {
        // eslint-disable-next-line no-throw-literal
        throw { ...flattenErrors({ ...err.response?.data }), response: err.response };
      } else {
        // eslint-disable-next-line no-throw-literal
        throw { message: "Wystąpił błąd", response: err.response };
      }
    })
    .finally(() => {
      CommunicationStatusController.decrement();
    });

  //@ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled by React Query");
  };

  return promise;
}

import { graphhopper } from "api/graphhopper/graphhopper";
import { Route } from "api/routes/models";
import carImg from "assets/images/150.svg";
import carWhiteImg from "assets/images/150w.svg";
import smallTruckImg from "assets/images/151.svg";
import smallTruckWhiteImg from "assets/images/151w.svg";
import largeTruckImg from "assets/images/152.svg";
import largeTruckWhiteImg from "assets/images/152w.svg";
import arrowIcon from "assets/images/28.svg";
import cx from "classnames";
import { ClickOutsideHandler } from "components/utils";
import cuid from "cuid";
import { useToastr, useToggle } from "hooks";
import { useRoutePutMutation } from "hooks/apiPrimitives";
import { useRouteViewState } from "pages/routes/creator/routeCreatorState";
import {
  getFullRouteCoords,
  getOrdersPositionsBasedOnGraphhopper,
  getPoints,
} from "pages/routes/creator/utils";
import styles from "./VehicleType.module.css";

export type VehicleType = {
  id: "car" | "small_truck" | "truck";
  name: string;
  img: string;
  selectedImg: string;
};

const vehicleTypes: VehicleType[] = [
  { id: "car", name: "Samochód osobowy", img: carImg, selectedImg: carWhiteImg },
  {
    id: "small_truck",
    name: "Samochód ciężarowy (mały)",
    img: smallTruckImg,
    selectedImg: smallTruckWhiteImg,
  },
  {
    id: "truck",
    name: "Samochód ciężarowy (duży)",
    img: largeTruckImg,
    selectedImg: largeTruckWhiteImg,
  },
];

interface Props {
  route: Route;
}

const clickOutsideClass = cuid();

export const VehicleTypeSelect = ({ route }: Props) => {
  const toastr = useToastr();
  const { isOpen, close, toggle } = useToggle(false);
  const actions = useRouteViewState("slave", state => state.actions);
  const isLoading = useRouteViewState("slave", state => state.isLoading);

  const updateMutation = useRoutePutMutation();

  const handleUpdateVehicleType = async (vehicleType: Route["vehicleType"]) => {
    const startingPointLngLat = [route.startingPoint.point.lng, route.startingPoint.point.lat];
    actions.openLoader("Trwa zmiana typu samochodu");
    const newPoints = getPoints(route.ordersPositions);

    const points = getFullRouteCoords(route, newPoints, startingPointLngLat);

    const payload = await graphhopper.route({
      points,
      vehicle: vehicleType,
      includeLastPointInOptimization: route.includeLastPointInOptimization,
    });

    if (payload) {
      const returnToStartingPointDistance = String(payload.points[newPoints.length].distance);
      const returnToStartingPointTime = String(payload.points[newPoints.length].time);

      const ordersPositions = getOrdersPositionsBasedOnGraphhopper(
        route.ordersPositions,
        payload.points,
      );

      updateMutation.mutate({
        data: {
          length: payload.distance,
          operation: null,
          vehicleType,
          returnToStartingPointDistance,
          returnToStartingPointTime,
          ordersPositions,
          shouldCalculateAverageSpeed: true,
        },
        route: route.id,
      });
    } else {
      actions.closeLoader();
      toastr.open({
        type: "failure",
        title: "Nie udało się zmienić typu pojazdu",
        text: "",
      });
    }
  };

  return (
    <div>
      <ClickOutsideHandler onClickOutside={close} outsideClickIgnoreClass={clickOutsideClass}>
        <div className={styles.container}>
          <div
            className={cx(
              "d-flex align-items-center px-1",
              styles.openButton,
              {
                [styles.openButtonActive]: isOpen,
              },
              { [styles.disabled]: isLoading },
            )}
            aria-haspopup="menu"
            onClick={() => {
              if (!isLoading) {
                toggle();
              }
            }}
          >
            <div className={cx("mr-1", styles.car)}>
              <img src={vehicleTypes.find(el => el.id === route.vehicleType)!.img} alt="" />
            </div>
            <img src={arrowIcon} alt="" className={styles.arrow} />
          </div>
          <div
            className={styles.optionsList}
            role="menu"
            style={{ display: isOpen ? "block" : "none" }}
          >
            <div className={cx(" text-color-grey", styles.label)}>Typ samochodu</div>
            {vehicleTypes.map(vehicle => (
              <div
                key={vehicle.id}
                className={styles.optionsItem}
                role="menuitem"
                onClick={() => {
                  handleUpdateVehicleType(vehicle.id);
                  close();
                }}
              >
                <div
                  className={cx(
                    styles.imgContainer,
                    "d-flex align-items-center justify-content-center",
                  )}
                >
                  <img src={vehicle.img} alt="" />
                </div>
                <strong>{vehicle.name}</strong>
              </div>
            ))}
          </div>
        </div>
      </ClickOutsideHandler>
    </div>
  );
};

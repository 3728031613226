import { differenceInMinutes } from "date-fns";
import jwtDecode from "jwt-decode";
import fetchConnector from "./fetchConnector";
import { storeConnector } from "./storeConnector";

export const tokenMeta = {
  accessToken: {
    token: "",
    expire: 0,
  },
  refreshToken: {
    token: "",
    expire: 0,
  },
};

/**
 * Function refreshes access token if needed;
 * Function logs out the user if the refresh token has expired;
 * It is important to multiply expiration date by 1000, because there is inconsistency
 * in data returned from backend and browser date API - backend works on seconds,
 * date API works on milliseconds.
 * Be careful not to multiply twice!
 */
export const tokenRefresher = async (accessToken: string) => {
  if (tokenMeta.accessToken.token !== accessToken) {
    const refreshToken = localStorage.getItem("refreshToken") as string;
    const refreshDecoded = jwtDecode<{ exp: number; userId: number }>(refreshToken);
    const accessDecoded = jwtDecode<{ exp: number; userId: number }>(accessToken);
    tokenMeta.accessToken.token = accessToken;
    tokenMeta.accessToken.expire = accessDecoded.exp * 1000;
    tokenMeta.refreshToken.token = refreshToken;
    tokenMeta.refreshToken.expire = refreshDecoded.exp * 1000;
  }
  const now = new Date();

  function lessThan2MinutesToExpire() {
    const accessExpireDate = tokenMeta.accessToken.expire;
    const accessMinutesLeft = differenceInMinutes(new Date(accessExpireDate), now);
    // if less than 2 minutes left for token to expire
    if (accessMinutesLeft <= 1) {
      return true;
    }
    return false;
  }
  /**
   * Function checks if refresh token has expired
   */
  function refreshTokenExpired() {
    const exp = tokenMeta.refreshToken.expire;
    const hasExpired = differenceInMinutes(new Date(exp), now) <= 1;
    return hasExpired;
  }
  /**
   * Function refreshes access token with refresh token and stores it in localStorage
   */
  async function fetchAndStoreToken() {
    return new Promise<"success" | "failure">(async resolve => {
      const refreshToken = tokenMeta.refreshToken.token;
      const [res] = await fetchConnector<{ access: string }>({
        method: "POST",
        url: "/users/token/refresh",
        data: { refresh: refreshToken },
      });
      if (res) {
        const accessDecoded = jwtDecode<{ exp: number }>(res.access);
        localStorage.setItem("token", res.access);
        tokenMeta.accessToken.token = res.access;
        tokenMeta.accessToken.expire = accessDecoded.exp * 1000;
        resolve("success");
      } else {
        resolve("failure");
      }
    });
  }
  return new Promise<"success" | "failure">(async resolve => {
    const tokenNeedsRefresh = lessThan2MinutesToExpire();
    if (tokenNeedsRefresh === false) {
      resolve("success");
    } else {
      const refreshTokenHasExpired = refreshTokenExpired();
      if (refreshTokenHasExpired) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        storeConnector.dispatch?.({ type: "RESET_STORE" });

        resolve("failure");
      } else {
        const status = await fetchAndStoreToken();
        resolve(status);
      }
    }
  });
};

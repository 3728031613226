import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useQuery, useToggle } from "hooks";
import { queryString } from "utilities";
import { useShippingCouriersColumns } from "./useShippingCouriersColumns";
import { shippingActions } from "api/shipping/actions";
import { Courier } from "api/shipping/models";
import { CreateCourierModal } from "./addCourierModal/CreateCourierModal";
import { RightPanel } from "./rightPanel/RightPanel";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";

export const ShippingCouriers = () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify(query);

  const { data: couriers, error, isLoading, pagination } = shippingActions.useShippingCouriers(
    search,
  );
  const tableProps = useTableFeatureConnector({
    rows: couriers,
    withPagination: { pagination },
    withDrawer: "shippingCourier",
  });

  const columns = useShippingCouriersColumns();
  const addCourierModal = useToggle();

  return (
    <>
      <PageWrapper>
        <PageHeader
          searchInput={{
            label: "Szukaj...",
            tags: [],
          }}
          createButton={{
            alt: "utwórz",
            img: darkPlusIcon,
            label: "Dodaj integrację z kurierem",
            onClick: addCourierModal.open,
          }}
          viewLabel="SHIPPING_COURIERS"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between position-relative">
          <Table<Courier>
            columns={columns}
            isLoading={isLoading}
            error={error}
            uiSchema={mainListUiSchema}
            {...tableProps}
          />

          <RightPanel />
        </div>
      </PageWrapper>
      {addCourierModal.isOpen && <CreateCourierModal close={addCourierModal.close} />}
    </>
  );
};

import { removeManufacturingWorkingUnit } from "api/new-production-plans/calls";
import { productionPlansKeys } from "api/new-production-plans/keys";
import {
  AddManufacturingWorkingUnit,
  ManufacturingItemSource,
  ProductionPlan,
  ShelfManufacturingItem,
} from "api/new-production-plans/models";
import { Pagination } from "api/types";
import { useMutation } from "hooks/useMutation";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Assign } from "utility-types";
import { handleManufacturingWorkingUnitAssignmentRollback } from "../utils/handleManufacturingWorkingUnitAssignmentRollback";
import { isMoreThanOneSummarySlot } from "../utils/isMoreThanOneSummarySlot";
import { isMoreThanOneUnitFromGivenSource } from "../utils/isMoreThanOneUnitFromGivenSource";

export const useRemoveManufacturingWorkingUnit = (keyStrings: Record<string, string> | null) => {
  return withDeleteConfirmation(
    useMutation(
      ({
        manufacturingWorkingUnit,
      }: Assign<
        AddManufacturingWorkingUnit,
        { sourceId: string | number; source: ManufacturingItemSource }
      >) => {
        return removeManufacturingWorkingUnit(manufacturingWorkingUnit);
      },
      ({ queryClient, toastr }) => ({
        onMutate: args => {
          const prevProductionPlanSchedule = queryClient.getQueryData<ProductionPlan>(
            productionPlansKeys.productionPlanSchedule(String(args.stageManufacturingPlan)),
          );

          const shelfGroupKeyString =
            keyStrings && keyStrings[args.sourceId]
              ? keyStrings[args.sourceId]
              : keyStrings && !keyStrings[args.sourceId]
              ? keyStrings["null"]
              : "null";

          const prevSourceShelfGroupList = queryClient.getQueryData<
            Pagination<ShelfManufacturingItem>
          >(productionPlansKeys.shelfItemDetails(shelfGroupKeyString));

          let manufacturingWorkingUnitToRemove: ShelfManufacturingItem | null = null;

          if (args.stageManufacturingPlan && prevSourceShelfGroupList) {
            // Remove MWU from EmployeeWorkingDay's list of MWU's
            queryClient.setQueryData<ProductionPlan>(
              productionPlansKeys.productionPlanSchedule(String(args.stageManufacturingPlan)),
              currentPlan => {
                assertIsDefined(currentPlan);
                return {
                  ...currentPlan,
                  employees: currentPlan.employees.map(employee => {
                    const selectedEmployee = employee.employeeWorkingDays.find(
                      employeeWorkingDay => employeeWorkingDay.id === args.employeeWorkingDay,
                    );

                    if (selectedEmployee) {
                      return {
                        ...employee,
                        employeeWorkingDays: employee.employeeWorkingDays.map(
                          employeeWorkingDay => {
                            if (employeeWorkingDay.id === args.employeeWorkingDay) {
                              const removableManufacturingWorkingUnit = employeeWorkingDay.manufacturingWorkingUnits.find(
                                manufacturingWorkingUnit =>
                                  manufacturingWorkingUnit.id === args.manufacturingWorkingUnit,
                              );
                              if (removableManufacturingWorkingUnit) {
                                manufacturingWorkingUnitToRemove = {
                                  id: removableManufacturingWorkingUnit.id,
                                  manufacturingItemIdentity:
                                    removableManufacturingWorkingUnit.manufacturingItemIdentity,
                                  manufacturingItemId:
                                    removableManufacturingWorkingUnit.manufacturingItemId,
                                  orderSignature: removableManufacturingWorkingUnit.orderSignature,
                                  signature: removableManufacturingWorkingUnit.signature,
                                  name: removableManufacturingWorkingUnit.name,
                                  source: removableManufacturingWorkingUnit.source,
                                  index: removableManufacturingWorkingUnit.index,
                                  visiblePreviousStages:
                                    removableManufacturingWorkingUnit.visiblePreviousStages,
                                  status: removableManufacturingWorkingUnit.status,
                                  priority: removableManufacturingWorkingUnit.priority,
                                  priorityOrder: removableManufacturingWorkingUnit.priorityOrder,
                                  isOutdated: removableManufacturingWorkingUnit.isOutdated,
                                };
                              }
                              return {
                                ...employeeWorkingDay,
                                categorySummary:
                                  removableManufacturingWorkingUnit &&
                                  isMoreThanOneSummarySlot(
                                    employeeWorkingDay.categorySummary,
                                    removableManufacturingWorkingUnit.index.category,
                                  )
                                    ? employeeWorkingDay.categorySummary.map(summary => {
                                        const removableCategory =
                                          removableManufacturingWorkingUnit.index.category;
                                        if (
                                          summary.code === removableCategory.code &&
                                          summary.color === removableCategory.color &&
                                          summary.name === removableCategory.name
                                        ) {
                                          return {
                                            ...summary,
                                            slots: summary.slots - 1,
                                          };
                                        }

                                        return summary;
                                      })
                                    : removableManufacturingWorkingUnit &&
                                      !isMoreThanOneSummarySlot(
                                        employeeWorkingDay.categorySummary,
                                        removableManufacturingWorkingUnit.index.category,
                                      )
                                    ? employeeWorkingDay.categorySummary.filter(
                                        summary =>
                                          summary.code !==
                                            removableManufacturingWorkingUnit.index.category.code &&
                                          summary.color !==
                                            removableManufacturingWorkingUnit.index.category
                                              .color &&
                                          summary.name !==
                                            removableManufacturingWorkingUnit.index.category.name,
                                      )
                                    : employeeWorkingDay.categorySummary,
                                manufacturingWorkingUnits: employeeWorkingDay.manufacturingWorkingUnits.filter(
                                  manufacturingWorkingUnit =>
                                    manufacturingWorkingUnit.id !== args.manufacturingWorkingUnit,
                                ),
                              };
                            }
                            return employeeWorkingDay;
                          },
                        ),
                      };
                    }
                    return employee;
                  }),
                  slotsSummary: {
                    total: currentPlan.slotsSummary.total,
                    taken: currentPlan.slotsSummary.taken - 1,
                  },
                  sourcesSummary: isMoreThanOneUnitFromGivenSource(args.source, currentPlan)
                    ? currentPlan.sourcesSummary
                    : currentPlan.sourcesSummary.filter(
                        sourcesSummary =>
                          sourcesSummary.id !== args.source.id &&
                          sourcesSummary.signature !== args.source.signature,
                      ),
                };
              },
            );
          }

          // Restore MWU in shelf source group
          queryClient.setQueryData<Pagination<ShelfManufacturingItem>>(
            productionPlansKeys.shelfItemDetails(shelfGroupKeyString),
            currentList => {
              assertIsDefined(currentList);
              const isManufacturingWorkingUnitInShelfGroup = currentList.results.some(
                manufacturingWorkingUnit =>
                  manufacturingWorkingUnit.id === args.manufacturingWorkingUnit,
              );

              if (!isManufacturingWorkingUnitInShelfGroup && manufacturingWorkingUnitToRemove) {
                return {
                  ...currentList,
                  results: [...currentList.results, manufacturingWorkingUnitToRemove],
                };
              }
              return currentList;
            },
          );

          return { prevProductionPlanSchedule, prevSourceShelfGroupList, shelfGroupKeyString };
        },
        onSuccess: (_, variables, context) => {
          const { shelfGroupKeyString } = context as {
            shelfGroupKeyString: string;
          };
          queryClient.invalidateQueries(
            productionPlansKeys.productionPlanSchedule(String(variables.stageManufacturingPlan)),
          );
          queryClient.invalidateQueries(productionPlansKeys.shelfItemDetails(shelfGroupKeyString));
          queryClient.invalidateQueries(
            productionPlansKeys.shelfItems(String(variables.stageManufacturingPlan)),
          );
        },
        onError: (error, variables, context) => {
          const {
            prevProductionPlanSchedule,
            prevSourceShelfGroupList,
            shelfGroupKeyString,
          } = context as {
            prevProductionPlanSchedule: ProductionPlan;
            prevSourceShelfGroupList: Pagination<ShelfManufacturingItem>;
            shelfGroupKeyString: string;
          };
          handleManufacturingWorkingUnitAssignmentRollback(
            prevProductionPlanSchedule,
            prevSourceShelfGroupList,
            variables.stageManufacturingPlan,
            shelfGroupKeyString,
            queryClient,
          );
          toastr.open({
            type: "warning",
            title: "Wymagane działanie",
            text: getAnyErrorKey(error),
          });
        },
      }),
    ),
  )();
};

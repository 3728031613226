import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { Pagination } from "../types";
import {
  Customer,
  Address,
  CustomerDashboard,
  CustomerEmployee,
  AuthenticatedUserCustomerDetails,
  CustomerLight,
  updateCustomersDTO,
  ViesValidStatus,
} from "./models";
import { PostModel } from "typeUtilities";

const api = tokenRefreshMiddleware(apiFetch);

export const getCustomers = (search: string = "", abortToken?: string) =>
  api<Pagination<Customer>>({
    method: "GET",
    url: "/customers/items" + search,
    abortToken,
  });

export const getCustomer = (id: number | string) =>
  api<Customer>({
    method: "GET",
    url: "/customers/items/" + id,
  });

export const postCustomer = (
  data: Omit<
    Customer,
    | "id"
    | "addresses"
    | "lastOrders"
    | "hasDropShipping"
    | "hasAmountToCollect"
    | "defaultCollectionDateDays"
    | "isVisibleOnLabel"
    | "canAddNewIndexes"
    | "defaultCurrency"
    | "productSet"
    | "url"
    | "integrationToken"
    | "isConnected"
    | "labelTemplate"
    | "logo"
    | "hasPermissionsToCreateProducts"
    | "hasPermissionsToEditWarehouseDeliveryDate"
    | "maximumDeliveryToWarehouseTime"
    | "isRecipient"
    | "priceList"
    | "canSendSms"
    | "countryCode"
    | "viesCheckedAt"
    | "viesValidStatus"
  >,
) =>
  api<Customer>({
    method: "POST",
    url: "/customers/items",
    data,
  });

// TODO: types
export const getCustomerAddresses = (search: string = "") =>
  api<any>({
    method: "GET",
    url: "/customers/delivery-addresses" + search,
  });

export const postCustomerAddress = (data: {
  name: string;
  postCode: string;
  city: string;
  street: string;
  phone: string;
  externalAddressNumber: string;
  point: { lat: number; lng: number };
  countryCode: string;
}) =>
  api<any>({
    method: "POST",
    url: "/customers/delivery-addresses",
    data,
  });

export const patchCustomer = (
  customerId: number | string,
  data: PostModel<Partial<Omit<Customer, "id" | "addresses" | "lastOrders">>>,
  abortToken?: string,
) =>
  api<Customer>({
    method: "PATCH",
    url: "/customers/items/" + customerId,
    data,
    abortToken,
  });

export const postCustomerAvatar = (data: FormData) =>
  api<{ logo: string }>({
    method: "POST",
    url: "/customers/logo",
    data,
  });

export const patchViesInfo = (customerId: number | string) =>
  api<{ viesValidStatus: ViesValidStatus; viesCheckedAt: string }>({
    method: "PATCH",
    url: "/customers/refresh-vies-info/" + customerId,
  });

export const patchCustomerAddress = (
  addressId: number | string,
  data: Partial<Omit<Address, "id">>,
  abortToken?: string,
) =>
  api<Address>({
    method: "PATCH",
    url: "/customers/delivery-addresses/" + addressId,
    data,
    abortToken,
  });

export const deleteCustomer = (customerId: number | string) =>
  api<void>({
    method: "DELETE",
    url: "/customers/items/" + customerId,
  });

export const deleteCustomerAddress = (addressId: number | string) =>
  api<void>({
    method: "DELETE",
    url: "/customers/delivery-addresses/" + addressId,
  });

export const getCustomerDashboard = () =>
  api<CustomerDashboard>({
    method: "GET",
    url: "/customers/dashboard",
  });

export const getCustomerEmployees = (search: string = "", abortToken?: string) =>
  api<Pagination<CustomerEmployee>>({
    method: "GET",
    url: "/customers/employees" + search,
    abortToken,
  });

export const postCustomerEmployee = (data: {
  firstName: string;
  lastName: string;
  phone: string;
  password: string;
  confirmPassword: string;
  email: string;
  customer: number;
}) =>
  api<CustomerEmployee>({
    method: "POST",
    url: "/customers/employees",
    data,
  });

export const deleteCustomerEmployee = (employeeId: number | string) =>
  api<void>({
    method: "DELETE",
    url: "/customers/employees/" + employeeId,
  });

export const getCustomerDetails = () =>
  api<AuthenticatedUserCustomerDetails>({
    method: "GET",
    url: "/utils/partials/customer-details",
  });

export const getCustomersLight = (search: string = "", abortToken?: string) =>
  api<Pagination<CustomerLight>>({
    method: "GET",
    url: "/customers/map" + search,
    abortToken,
  });

export const patchMultipleCustomers = (data: updateCustomersDTO) =>
  api<{ defaultCollectionDateDays: number }>({
    method: "PATCH",
    url: "/customers/items/bulk-update",
    data,
  });

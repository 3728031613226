import * as React from "react";
import { cx } from "utilities";

interface Props {
  status?: number | string;
  show?: boolean;
  text?: React.ReactNode;
  size?: "small" | "big";
}

export const errorStatusToMessageDict: { [key: string]: string } = {
  0: "Brak połączenia z serwerem lub internetem",
  400: "Wystąpił błąd komunikacji z serwerem",
  401: "Brak dostępu - nie rozpoznano użytkownika",
  403: "Brak dostępu",
  404: "Nie znaleziono",
  500: "Wystąpił błąd serwera",
  front: "Wystąpił błąd aplikacji",
};

export const CommonError = ({ status = "", show = true, text, size }: Props) => {
  if (!show) return null;
  const message = text || errorStatusToMessageDict[status] || "Błąd serwera";
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="card mb-1">
        <div className={cx("card-body", { "px-3 py-2": size === "small" })}>{message}</div>
      </div>
    </div>
  );
};

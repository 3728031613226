import { ExternalManufacturerLineItem, ShipmentKind } from "api/external-manufacturing/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { ShippingService, shippingServiceConstants } from "constants/shippingService";
import styles from "../ExternalManufacturerPanel.module.css";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  shipment: ExternalManufacturerLineItem["shippingInfo"];
}

export const PickedUpBy = ({ shipment }: Props) => {
  if (!shipment || !shipment.shippingService) return <EmptyValue />;
  if (shipment.shippingService.kind === ShipmentKind.OWN_TRANSPORT)
    return <Tag label="TRANSPORT WŁASNY" type="filled" variant="deepPurple50" />;
  if (shipment.shippingService.kind === ShipmentKind.SELF_PICKUP)
    return <Tag label="ODBIÓR OSOBISTY" type="filled" variant="warning" />;
  return (
    <div className="d-flex align-items-center gap-1">
      <Tag label="KURIER" type="filled" variant="info" />
      {shipment.shippingService.provider === ShippingService.AMBRO ||
      shipment.shippingService.provider === ShippingService.MEBEL_TAXI ? (
        <div className={styles.courierLogoWrapper}>
          <img
            alt="Kurier"
            className={styles.courierLogo}
            src={shippingServiceConstants[shipment.shippingService.provider].logo}
          />
        </div>
      ) : (
        <Typography fontSize="14" fontWeight="700" noWrap>
          {shipment.shippingService.name ?? "---"}
        </Typography>
      )}
    </div>
  );
};

import { ConfirmResendingModal, dayOptions, SmsModal } from "api/logistics/routes/models";
import { ConfirmationDateOptions } from "api/milo-settings/models";
import { Modal, StatusHandler } from "components/utils";
import { AddCustomOption } from "pages/routes/routesList/rightPanel/pointsSection/confirmationDateModal/AddCustomOption";
import { ConfirmationDateBody } from "./shared/ConfirmationDateBody";
import { useState } from "react";
import styles from "./ConfirmationDateModals.module.css";
import { Button } from "components/common";
import { sendSmsToAll } from "../../utils/sendSmsToAll";
import { ToggleHookState, useToastr } from "hooks";
import { Route } from "api/routes/models";

interface Props {
  confirmResendingModal: ConfirmResendingModal;
  options: ConfirmationDateOptions[];
  route: Route;
  singleSmsConfirmationDateModal: ToggleHookState;
  smsModal: SmsModal;
  smsToAllConfirmationModal: ToggleHookState;
}

export const SmsToAllConfirmation = ({
  confirmResendingModal,
  options,
  route,
  singleSmsConfirmationDateModal,
  smsModal,
  smsToAllConfirmationModal,
}: Props) => {
  const [allOptions, setAllOptions] = useState(options);
  const [dayOption, setDayOption] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [picked, setPicked] = useState<ConfirmationDateOptions | null>(null);
  const [time, setTime] = useState("");
  const toastr = useToastr();

  return (
    <Modal
      close={smsToAllConfirmationModal.close}
      isOpen
      overrides={{ container: { className: styles.modal } }}
    >
      <div>
        <ConfirmationDateBody allOptions={allOptions} picked={picked} setPicked={setPicked} />
        <AddCustomOption
          allOptions={allOptions}
          dayOption={dayOption}
          dayOptions={dayOptions}
          isOpen={isOpen}
          setAllOptions={setAllOptions}
          setDayOption={setDayOption}
          setIsOpen={setIsOpen}
          setPicked={setPicked}
          setTime={setTime}
          time={time}
        />

        <div className="d-flex align-items-center gap-2">
          <StatusHandler>
            {helpers => (
              <Button
                kind="secondary-grey"
                onClick={() => {
                  sendSmsToAll(
                    smsToAllConfirmationModal,
                    null,
                    confirmResendingModal,
                    {
                      resendSms: false,
                      sendSms: false,
                      resendUnconfirmed: false,
                    },
                    helpers,
                    null,
                    route,
                    singleSmsConfirmationDateModal,
                    smsModal,
                    toastr,
                  );
                }}
              >
                Pomiń i wyślij SMS
              </Button>
            )}
          </StatusHandler>
          <StatusHandler>
            {helpers => (
              <Button
                disabled={helpers.isFetching || picked === null}
                kind="primary"
                onClick={() => {
                  if (picked) {
                    sendSmsToAll(
                      smsToAllConfirmationModal,
                      { option: picked.deadlineDayOption, time: picked.deadlineTime.slice(0, 5) },
                      confirmResendingModal,
                      {
                        resendSms: false,
                        sendSms: false,
                        resendUnconfirmed: false,
                      },
                      helpers,
                      null,
                      route,
                      singleSmsConfirmationDateModal,
                      smsModal,
                      toastr,
                    );
                  }
                }}
              >
                Wyślij SMS z datą potwierdzenia
              </Button>
            )}
          </StatusHandler>
        </div>
      </div>
    </Modal>
  );
};

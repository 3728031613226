import { CreateWarehouseman, WarehouseWorkerOccupation } from "api/wms/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Formik } from "formik";
import { useSubmitWarehouseWorker } from "../hooks/useSubmitWarehouseWorker";
import styles from "./AddWarehousemanModal.module.css";
import cx from "classnames";
import { FormInput, FormSelect } from "components/utils";
import { Button } from "components/miloDesignSystem/atoms/button";
import { validationSchema } from "./validationSchema";

interface Props {
  close: () => void;
}

const occupationsToSelect: { id: WarehouseWorkerOccupation; name: string }[] = [
  { id: WarehouseWorkerOccupation.WAREHOUSEMAN, name: "Magazynier" },
  { id: WarehouseWorkerOccupation.PACKER, name: "Pakujący" },
  { id: WarehouseWorkerOccupation.PICKER, name: "Picker" },
];

const initialValues: CreateWarehouseman = {
  email: "",
  firstName: "",
  lastName: "",
  occupation: "",
  phone: "",
};

export const AddWarehousemanModal = ({ close }: Props) => {
  const handleSubmit = useSubmitWarehouseWorker(close);

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Utwórz magazyniera
        </Typography>
      }
      width={480}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className={cx(styles.modalForm, "d-flex flex-column p-3")}>
              <FormSelect
                items={occupationsToSelect}
                itemToSelection={item => (item ? item.id : null)}
                label="Stanowisko"
                name="occupation"
                placeholder="Wybierz stanowisko"
                width="full"
              />
              <FormInput label="Imię" name="firstName" placeholder="Imię" />
              <FormInput label="Nazwisko" name="lastName" placeholder="Nazwisko" />
              <FormInput label="E-mail" name="email" placeholder="E-mail" />
              <FormInput label="Nr telefonu" name="phone" placeholder="Nr telefonu" />
            </div>
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button isLoading={isSubmitting} size="medium" type="submit" variant="deepPurple">
                Dodaj{isSubmitting && "..."}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

import { AxiosError } from "axios";
import { useCallback, useMemo, useRef } from "react";
import { produce } from "immer";
import {
  QueryKey,
  QueryObserverOptions,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { Assign } from "utility-types";
import { useToastr } from "./useToastr";
import { getAnyErrorKey } from "utilities";

export type ErrorType = { [key: string]: any; _httpStatus_: number };
export type SearchParams = { [key: string]: any };

export interface ApiFetcher<TRes extends unknown> {
  fetcher: () => Promise<TRes>;
  key: Exclude<QueryKey, string>;
  globalOptions?: QueryObserverOptions<TRes, AxiosError>;
}

export const createApiQuery = <TRes extends unknown, T>(
  fetcherFn: (args: T) => ApiFetcher<TRes>,
) => (
  args: T,
  options?: QueryObserverOptions<TRes, AxiosError>,
): Assign<
  UseQueryResult,
  {
    data: TRes | null;
    error: ErrorType | null;
    key: Exclude<QueryKey, string>;
    handleMutate: (
      toUpdate: Partial<TRes> | ((draft: TRes) => void),
    ) => (error?: AxiosError<any> | undefined) => void;
  }
> => {
  const toastr = useToastr();
  const error = useRef<ErrorType | null>(null);
  const { key, fetcher, globalOptions } = useMemo(() => fetcherFn(args), [args]);
  const res = useQuery<any, any>(key, fetcher, {
    onError: err => {
      if (err.response.headers["content-type"] === "application/json") {
        error.current = err.response.data;
      } else {
        error.current = {} as any;
      }

      Object.defineProperty(error.current, "_httpStatus_", {
        value: err.response.status,
        enumerable: false,
      });
    },
    ...globalOptions,
    ...options,
  });
  const queryClient = useQueryClient();

  const handleMutate = useCallback(
    (toUpdate: Partial<TRes> | ((draft: TRes) => void)) => {
      // Snapshot the previous value
      const prev = queryClient.getQueryData<TRes>(key);

      // Optimistically update to the new value
      if (prev) {
        const value = typeof toUpdate === "function" ? produce(prev, toUpdate) : toUpdate;
        //@ts-ignore
        queryClient.setQueryData<TRes>(key, { ...prev, ...value });
      } else {
        throw new Error("Unexpected behavior: there is no query to mutate.");
      }

      const rollback = (error?: AxiosError) => {
        queryClient.setQueryData<any>(key, prev);

        if (error) {
          if (error.response?.status === 500) {
            toastr.open({
              type: "failure",
              title: "Oj, coś nie tak...",
              text: getAnyErrorKey(error),
            });
          } else {
            toastr.open({
              type: "warning",
              title: "Wymagane działanie",
              text: getAnyErrorKey(error),
            });
          }
        }
      };
      return rollback;
    },
    [queryClient, key, toastr],
  );

  if (res.isError) {
    return {
      ...res,
      error: error.current,
      data: null,
      key,
      handleMutate,
    };
  } else {
    return {
      ...res,
      error: null,
      data: res.data,
      key,
      handleMutate,
    };
  }
};

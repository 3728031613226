import { useReceivedAndReleasedPackagesPerDay } from "api/wms/hooks";
import { WarehouseWorkerDetails } from "api/wms/models";
import { queryString } from "utilities";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useReceivedAndReleasedPackagesPerDay as useReceivedAndReleasedPackagesPerDayColumns } from "./useReceivedAndReleasedPackagesPerDay";
import { RightPanelSection } from "components/utils/drawer";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../../RightPanel.module.css";
import { useWarehouseWorkerFilters } from "../../hooks/useWarehouseWorkerFilters";
import { GenericTable } from "components/common/genericTable/GenericTable";
import { useGenericTableFilters } from "components/common/genericTable/useGenericTableFilters";
import { parseTableRowData } from "components/common/genericTable/utils";
import { detailsListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";

interface Props {
  warehouseWorker: WarehouseWorkerDetails;
}

export const ReceivedAndReleasedPackagesPerDay = ({ warehouseWorker }: Props) => {
  const warehouseWorkerFilters = useWarehouseWorkerFilters(warehouseWorker);
  const { setDateFrom, filters } = useGenericTableFilters();
  const search = queryString.stringify({ ...filters, ...warehouseWorkerFilters.filters });
  const {
    data: packagesPerDay,
    error,
    isLoading,
    isFetching,
  } = useReceivedAndReleasedPackagesPerDay(search, { keepPreviousData: true });

  const columns = useReceivedAndReleasedPackagesPerDayColumns(packagesPerDay);
  const normalizedRows = parseTableRowData(packagesPerDay);

  return (
    <RightPanelSection padding="pl-0 pr-0">
      <div className={styles.sectionTitle}>
        <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
          Zestawienie przyjęć i wydań (dzień)
        </Typography>
      </div>
      <div className="pt-1">
        <Table
          rows={normalizedRows}
          // @ts-ignore
          columns={columns}
          error={error}
          isLoading={isLoading || isFetching}
          uiSchema={detailsListUiSchema}
        />
      </div>
      <GenericTable.Footer rowBy={filters.rowBy} setDateFrom={setDateFrom} />
    </RightPanelSection>
  );
};

import { DropResult } from "react-beautiful-dnd";

export const validateManufacturingWorkingUnitOnPlanDrop = (result: DropResult) => {
  const destinationArray = result.destination?.droppableId.split("$");
  const sourceArray = result.source.droppableId.split("$");

  if (
    destinationArray &&
    destinationArray[0] === "plan" &&
    sourceArray[0] === "plan" &&
    result.destination?.droppableId !== result.draggableId
  )
    return true;
  return false;
};

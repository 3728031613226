import { carrierActions } from "api/logistics/carriers/actions";
import { Carrier } from "api/logistics/carriers/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader } from "components/utils/drawer";
import { cx } from "utilities";

interface Props {
  carrier: Carrier;
  close: () => void;
  isScrolledOver: boolean;
}

export const PanelHeader = ({ carrier, close, isScrolledOver }: Props) => {
  const deleteMutation = carrierActions.useDeleteCarrier(carrier, close);

  return (
    <>
      <RightPanelHeader
        className={cx({
          slidingPanelHeaderShadow: isScrolledOver,
        })}
      >
        {isScrolledOver && (
          <div className="slidingPanelHeader">
            <div className="slidingPanelHeaderContent nowrap">{carrier.name}</div>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <Tooltip title="Usuń przewoźnika">
            <IconButton
              icon={MdiDelete}
              onClick={() => deleteMutation.mutate(carrier.id)}
              variant="transparent"
            />
          </Tooltip>
          <span className="divider line-divider" />
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </RightPanelHeader>
    </>
  );
};

import {
  CreateTradingDocumentPayment,
  TradingDocument,
  tradingDocumentPaymentKindToPick,
  tradingDocumentPaymentProviderToPick,
  tradingDocumentPaymentTypeToPick,
} from "api/trading-documents/models";
import styles from "./AddPaymentModal.module.css";
import cx from "classnames";
import { Formik } from "formik";
import { getIsoDateFormat, getStandardDateFormat } from "utilities";
import { useSelector } from "hooks";
import { FormInput, FormSelect } from "components/utils";
import { DatePicker } from "components/utils/datePicker";
import { Button } from "components/common";
import { currenciesToPick } from "CONSTANTS";
import { validationSchema } from "./validationSchema";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { tradingDocumentsActions } from "api/trading-documents/actions";

interface Props {
  close: () => void;
  tradingDocument: TradingDocument;
}

export const AddPaymentModal = ({ close, tradingDocument }: Props) => {
  const me = useSelector(store => store.auth.user!);
  const handleSubmit = tradingDocumentsActions.useSubmitPayment(close, tradingDocument.id);

  const initialValues: CreateTradingDocumentPayment = {
    amount: "",
    bankTransactionDate: null,
    bankTransactionNumber: "",
    commission: null,
    createdAt: getIsoDateFormat(new Date()),
    createdBy: me,
    currency: "PLN",
    kind: "ADVANCE",
    paymentDate: null,
    paymentNumber: "",
    provider: "",
    tradingDocument: tradingDocument.id,
    type: "CASH",
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj płatność
        </Typography>
      }
      width={480}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className={cx(styles.modalForm, "d-flex flex-column p-3")}>
              <div className={cx(styles.select, "w-100")}>
                <FormSelect
                  items={tradingDocumentPaymentKindToPick}
                  itemToSelection={item => (item ? item.id : null)}
                  label="Typ płatności"
                  name="kind"
                  placeholder="Wybierz typ płatności"
                  selectedItem={values.kind}
                  width="full"
                />
              </div>
              <div className="d-flex align-items-center gap-3">
                <div className={cx(styles.inputBox, "w-100")}>
                  <FormInput label="Nr płatności" name="paymentNumber" />
                </div>
                <div className={cx(styles.inputBox, styles.datePick)}>
                  <DatePicker
                    className={styles.datePickContainer}
                    overwrites={{
                      popup: { className: styles.datePickPopUp },
                    }}
                    portalId={undefined}
                    label="Data płatności"
                    look="common"
                    onChange={date => {
                      if (!date) {
                        setFieldValue("paymentDate", null);
                      } else {
                        setFieldValue("paymentDate", getIsoDateFormat(date));
                      }
                    }}
                    placeholder="dd/mm/yy"
                    value={values.paymentDate || ""}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center w-100 gap-3">
                <div className="d-flex align-items-center w-100">
                  <div className={cx(styles.inputBox, "w-100")}>
                    <FormInput autoFocus label="Kwota" name="amount" type="number" />
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 fw-400">
                  <div className={cx(styles.inputBox, "w-100")}>
                    <FormInput label="Prowizja" name="commission" type="number" />
                  </div>
                </div>
              </div>
              <div className={cx(styles.select, "w-100")}>
                <FormSelect
                  items={currenciesToPick.map(currency => {
                    return { id: currency.value, name: currency.label };
                  })}
                  itemToSelection={item => (item ? item.id : null)}
                  label="Waluta"
                  name="currency"
                  placeholder="Wybierz walutę"
                  selectedItem={values.currency}
                  width="full"
                />
              </div>
              <div className={cx(styles.select, "w-100")}>
                <FormSelect
                  items={tradingDocumentPaymentTypeToPick}
                  itemToSelection={item => (item ? item.id : null)}
                  label="Sposób"
                  name="type"
                  placeholder="Wybierz sposób płatności"
                  selectedItem={values.type}
                  width="full"
                />
              </div>
              <div className={cx(styles.select, "w-100")}>
                <FormSelect
                  items={[{ id: "", name: "nie dotyczy" }, ...tradingDocumentPaymentProviderToPick]}
                  itemToSelection={item => (item ? item.id : null)}
                  label="Usługa"
                  name="provider"
                  placeholder="Wybierz usługę"
                  selectedItem={values.provider}
                  width="full"
                />
              </div>
              <div className="d-flex align-items-center gap-3">
                <div className={cx(styles.inputBox, "w-100")}>
                  <FormInput label="Nr przelewu" name="bankTransactionNumber" />
                </div>
                <div className={cx(styles.inputBox, styles.datePick)}>
                  <DatePicker
                    className={styles.datePickContainer}
                    overwrites={{
                      popup: { className: styles.datePickPopUp },
                    }}
                    portalId={undefined}
                    label="Data przelewu"
                    look="common"
                    onChange={date => {
                      if (!date) {
                        setFieldValue("bankTransactionDate", null);
                      } else {
                        setFieldValue("bankTransactionDate", getIsoDateFormat(date));
                      }
                    }}
                    placeholder="dd/mm/yy"
                    value={values.bankTransactionDate || ""}
                  />
                </div>
              </div>
              <>
                <div className="fs-10 text-color-grey">Odpowiedzialny(/a)</div>
                <div className="d-flex align-items-center gap-2 pl-1 pb-1">
                  <Avatar size="sm" user={values.createdBy} />
                  <div>
                    {values.createdBy.firstName} {values.createdBy.lastName}
                  </div>
                </div>
              </>
              <>
                <div className="fs-10 text-color-grey">Dodano(/a)</div>
                <div>{getStandardDateFormat(values.createdAt)}</div>
              </>
            </div>
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button className="uppercase" kind="create-medium-transparent" onClick={close}>
                <div className="btnBaseMedium btnBase">Anuluj</div>
              </Button>
              <Button disabled={isSubmitting} kind="submit-medium" type="submit">
                <div className="btnBaseMedium btnBase">Dodaj{isSubmitting && "..."}</div>
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

import cx from "classnames";
import styles from "./DisabledOpacity.module.css";

interface Props {
  disabled: boolean;
  children: any;
  className?: string;
  bgColor?: string;
  transparency?: string;
  title?: string;
  kind?: "clear" | "stripes" | "cover";
}
export const DisabledOpacity = ({
  disabled,
  children,
  className,
  bgColor = "ffffff",
  transparency = "50",
  title,
  kind = "clear",
}: Props) => {
  if (disabled) {
    const kindStyles = {
      clear: "",
      stripes: styles.stripes,
      cover: styles.cover,
    }[kind];

    return (
      <div
        className={`${styles.wrapper} ${styles.disabled} ${kindStyles} ${className} position-relative`}
        title={title}
      >
        {children}
        <div style={{ background: `#${bgColor}${transparency}` }} />
      </div>
    );
  } else {
    return <div className={cx(styles.wrapper, className)}>{children}</div>;
  }
};

import { ConfirmResendingModal, SmsModal, Toastr } from "api/logistics/routes/models";
import { postRouteEmail } from "api/routes/calls";
import { PostDeadline, Route } from "api/routes/models";
import { StatusHandlerHelpers } from "components/utils";
import { ToggleHookState } from "hooks";
import { getAnyErrorKey } from "utilities";

export const sendEmailToAll = async (
  confirmationDeadline: PostDeadline | null = null,
  confirmResendingModal: ConfirmResendingModal,
  emailForAllConfirmationDateModal: ToggleHookState,
  helpers: StatusHandlerHelpers,
  route: Route,
  smsModal: SmsModal,
  toastr: Toastr,
) => {
  if (!route) return;
  helpers.startFetching();
  const [, error, { rawError }] = await postRouteEmail(route.id, {
    force: false,
    deadline: confirmationDeadline,
  });
  helpers.stopFetching();
  if (!error) {
    toastr.open({
      type: "success",
      title: "Udało się!",
      text: "Wiadomość e-mail została wysłana",
    });
    smsModal.close();
    emailForAllConfirmationDateModal.close();
  } else {
    if (error.message === "noEmail") {
      confirmResendingModal.open({
        callback: () => postRouteEmail(route.id, { force: true, deadline: confirmationDeadline }),
        confirmText: "Wyślij email",
        text: (
          <div>
            <div>Poniższe zamówienia nie mają adresu email:</div>
            <span>{rawError.orders.map((orderSignature: string) => orderSignature)}</span>
            <div>czy pomimo tego chcesz wysłać wiadomość email do pozostałych zamówień?</div>
          </div>
        ),
      });
    } else {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    }
  }
};

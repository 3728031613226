import { Button, PageHeader } from "components/common";
import downloadFileIcon from "assets/images/downloadFile.svg";
import uploadFileIcon from "assets/images/uploadFile.svg";
import visibilityOffIcon from "assets/images/eye_off.svg";
import areaChartIcon from "assets/images/areaChart.svg";
import { getTabs } from "../utils/getTabs";
import { routes } from "components/common/moduleNavigation/moduleConfig/logistics/routes/routes";
import { Link, useParams } from "react-router-dom";
import { useRouteHook } from "pages/routes/routesList/hooks";
import map from "assets/images/map_alt.svg";
import redo from "assets/images/redo.svg";
import styles from "../RoutesList.module.css";
import cx from "classnames";

export const ListHeader = () => {
  let recentlyOpenedRoute = localStorage.getItem("recentlyOpenedRoute");
  let recentlyChangedRoute = localStorage.getItem("recentlyChangedRoute");
  if (!recentlyOpenedRoute) recentlyOpenedRoute = "";
  if (!recentlyChangedRoute) recentlyChangedRoute = "";
  const recentlyOpenedRouteObject = useRouteHook(recentlyOpenedRoute);
  const recentlyChangedRouteObject = useRouteHook(recentlyChangedRoute);
  const { tab } = useParams<{ tab: "active" | "archive" }>();

  return (
    <div>
      <PageHeader
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Importuj plik"
                  src={uploadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Pobierz plik"
                  src={downloadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <span className="line-divider lineDividerMargin" />
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Wyłącz widoczność"
                  src={visibilityOffIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img alt="Wykres" src={areaChartIcon} style={{ height: "16px", width: "16px" }} />
              </div>
            </Button>
          </div>
        }
        createButton={{ label: "Utwórz trasę", onClick: () => {} }}
        searchInput={{
          label: "Szukaj wśród zamówień, klientów i tras",
          tags: [],
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `logistics/${routes.url}`,
          urlSpan: "list",
        }}
        viewLabel="LOGISTICS_ROUTES"
        bottomBarButtons={
          <div className="d-flex justify-content-center align-items-center gap-2">
            <div className="d-flex flex-column align-items-end mr-1 textAlignRight">
              <div className="d-flex align-items-center justify-content-end gap-1 mr-1 textAlignRight">
                <div className="body-10 text-black-6"> Ostatnio otwarta mapa:</div>
                <Link
                  to={
                    tab === "active"
                      ? `/routes/creator/${recentlyOpenedRoute}`
                      : `/routes/creator/${recentlyOpenedRoute}/archive`
                  }
                  className="body-12"
                >
                  {recentlyOpenedRouteObject?.data?.signature ? (
                    <div className={cx("d-flex align-items-center", styles.goToMapBtn)}>
                      <span>{recentlyOpenedRouteObject?.data?.signature}</span>
                      <Button kind="transparent-black" size="small">
                        <img alt="Idź do" src={redo} />
                      </Button>
                    </div>
                  ) : null}
                </Link>
              </div>

              <div className="d-flex align-items-center justify-content-end gap-1 mr-1 textAlignRight">
                <div className="body-10 text-black-6"> Ostatnio edytowana:</div>
                <Link
                  to={
                    tab === "active"
                      ? `/routes/creator/${recentlyChangedRoute}`
                      : `/routes/creator/${recentlyChangedRoute}/archive`
                  }
                  className="body-10"
                >
                  {recentlyChangedRouteObject?.data?.signature ? (
                    <div className={cx("d-flex align-items-center", styles.goToMapBtn)}>
                      <span>{recentlyChangedRouteObject?.data?.signature}</span>
                      <Button kind="transparent-black" size="small">
                        <img alt="Idź do" src={redo} />
                      </Button>
                    </div>
                  ) : null}
                </Link>
              </div>
            </div>
            <Button className={cx("btn btnSizeSmall", styles.goToMap)} kind="create">
              <Link to="/routes/map" className="btnBase btnBaseSmall uppercase">
                <img alt="Mapa" src={map} style={{ height: "16px", width: "16px" }} />
                Przejdź do mapy
              </Link>
            </Button>
          </div>
        }
      />
    </div>
  );
};

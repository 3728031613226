import { createPaginatedKey } from "api/keys";

export const productionPlansKeys = {
  productionPlans: createPaginatedKey("productionPlans"),
  productionPlan: (id: string) => ["productionPlan", id],
  productionPlanSchedule: (id: string) => ["productionPlanSchedule", id],
  manufacturingSchemaStages: createPaginatedKey("manufacturingSchemaStages"),
  schemaStages: createPaginatedKey("schemaStages"),

  shelfItems: (...params:[id: string, search?: string]) => ["shelfItems", ...params],
  shelfItemDetails: createPaginatedKey("shelfItemDetails"),
};

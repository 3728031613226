import styles from "../CommentsGenericSection.module.css";
import cx from "classnames";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { useEffect, useState } from "react";
import { CommentType } from "api/comments/models";
import { User } from "api/users/models";
import { useMutation } from "hooks/useMutation";
import { deleteComment, patchComment } from "api/comments/calls";
import { useQueryUtils } from "hooks";
import { useQueryClient } from "react-query";
import { Field, Formik } from "formik";
import { commentsKeys } from "api/comments/keys";
import { Pagination } from "api/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { dateFns } from "utilities";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiVisibilityOff } from "components/miloDesignSystem/atoms/icons/MdiVisibilityOff";
import { MdiVisibility } from "components/miloDesignSystem/atoms/icons/MdiVisibility";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { MdiEdit } from "components/miloDesignSystem/atoms/icons/MdiEdit";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";

interface Props extends CommentType {
  userLoggedIn: User;
  pageSize: number;
  getUrlWithParams: string;
  isLoading: boolean;
  endpointUrl: string;
}

export const Comment = ({
  id,
  body,
  user,
  created,
  createdAt,
  isOnlyVisibleForTeam,
  userLoggedIn,
  isLoading = false,
  getUrlWithParams,
  endpointUrl,
}: Props) => {
  const { rollbackList, handlePaginatedListUpdate } = useQueryUtils();
  const [isCommentEdited, setIsCommentEdited] = useState(false);
  const listCommentKey = commentsKeys.comments.list();
  const listCommentKeyQueried = commentsKeys.comments.list(getUrlWithParams);
  const queryClient = useQueryClient();
  let [date, time] = ["---", "---"];
  useEffect(() => {
    setIsCommentEdited(false);
  }, [id]);

  const [initialValues, setInitialValues] = useState({ body: body });
  const patchMutation = useMutation(patchComment, {
    onMutate: toUpdate => {
      const prevList = handlePaginatedListUpdate(listCommentKey, toUpdate.data.id, toUpdate);
      return { prevList };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(listCommentKey);
    },
    onError: (error, toUpdate, context) => {
      //@ts-ignore
      rollbackList(listCommentKey, context.prevList, toUpdate.id);
    },
  });

  const editMutation = useMutation(patchComment, {
    onMutate: toUpdate => {
      const prevList = handlePaginatedListUpdate(listCommentKey, toUpdate.data.id, toUpdate.data);
      return { prevList };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(listCommentKey);
    },
    onError: (error, toUpdate, context) => {
      //@ts-ignore
      rollbackList(listCommentKey, context.prevList, toUpdate.id);
    },
    onSettled: () => {
      setIsCommentEdited(false);
    },
  });

  const deleteMutation = useMutation(deleteComment, {
    onMutate: data => {
      const prevList = queryClient.getQueryData(listCommentKeyQueried);
      queryClient.setQueryData<Pagination<CommentType>>(listCommentKeyQueried, currentList => {
        assertIsDefined(currentList);
        const filteredResults = currentList.results.filter(el => el.id !== data.id);
        return { ...currentList, results: filteredResults };
      });
      return prevList;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(listCommentKey);
    },
    onError: (error, toUpdate, context) => {
      //@ts-ignore
      rollbackList(listCommentKey, context.prevList, toUpdate.id);
    },
  });
  const handleSubmit = (values: Pick<CommentType, "body">) => {
    editMutation.mutate({
      data: { id: String(id), ...values },
      endpointUrl: endpointUrl,
    });
  };

  useEffect(() => {
    if (body) {
      setInitialValues({ body: body });
    }
  }, [body, id]);

  const dateParser = (date: string) => dateFns.format(new Date(date), "dd LLL yyyy");
  const timeParser = (date: string) => dateFns.format(new Date(date), "HH:mm");
  let dateToParse = "";

  if (created) {
    dateToParse = created;
  } else if (createdAt) {
    dateToParse = createdAt;
  }

  [date, time] = [dateParser(dateToParse), timeParser(dateToParse)];

  return (
    <div className="justify-content-between gap-2 px-3 pt-2 d-flex">
      <div className="w-100">
        <div className={cx(styles.body14, styles.font400, "pt-1")}>
          <span className={!isCommentEdited ? "d-block" : "d-none"}>{body}</span>
          <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit}>
            {({ handleSubmit }) => (
              <form className={!isCommentEdited ? "d-none" : "d-flex"} onSubmit={handleSubmit}>
                <Field as="input" name="body" autoComplete="off" />
                <button
                  disabled={isLoading}
                  type="submit"
                  className={cx(
                    styles.btnBase,
                    styles.btnBaseSmall,
                    styles.btnBgDeepPurple,
                    "mx-2 rounded-sm",
                  )}
                >
                  Edit
                </button>
              </form>
            )}
          </Formik>
        </div>

        <div className="d-flex align-items-center gap-2 pt-1">
          <AvatarOrInitials
            avatarSrc={user.avatar}
            firstName={user.firstName}
            lastName={user.lastName}
            className={cx(styles.avatar, styles.avatarSmall, "m-0 ")}
          />

          <div className={styles.body12}>
            {user.firstName} {user.lastName}
          </div>
          <div className={styles.body10}>
            {date}, <span className={styles.textgreyAB}>{time}</span>
          </div>
        </div>
      </div>

      {userLoggedIn.id === user.id && (
        <>
          <Tooltip
            title={isOnlyVisibleForTeam ? "Widoczny dla zespołu" : "Widoczny dla wszystkich"}
          >
            <IconButton
              disabled={patchMutation.isLoading}
              icon={
                isOnlyVisibleForTeam ? (
                  <MdiVisibilityOff color="red100" size="20" />
                ) : (
                  <MdiVisibility color="green100" size="20" />
                )
              }
              onClick={() =>
                patchMutation.mutate({
                  data: {
                    id: String(id),
                    isOnlyVisibleForTeam: !isOnlyVisibleForTeam,
                  },
                  endpointUrl,
                })
              }
              variant="transparent"
            />
          </Tooltip>

          <div className="position-relative">
            <Menu
              disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
              menuItems={[
                {
                  type: MenuItemType.TEXT,
                  options: {
                    iconStart: MdiEdit,
                  },
                  onClick: () => setIsCommentEdited(!isCommentEdited),
                  text: "Edytuj",
                },
                {
                  type: MenuItemType.TEXT,
                  options: {
                    color: "red100",
                    iconStart: MdiDelete,
                  },
                  onClick: () =>
                    deleteMutation.mutate({
                      id: String(id),
                      endpointUrl,
                    }),
                  text: "Usuń",
                },
              ]}
            />
          </div>
        </>
      )}
    </div>
  );
};

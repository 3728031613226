import styles from "./MultipleCheckedItemsToolbar.module.css";
import cx from "classnames";
import { useState } from "react";
import { useEffect } from "react";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";

interface Props {
  actionButtons?: React.ReactNode;
  className?: string;
  close: () => void;
  quantity: number;
  style?: React.CSSProperties;
}

export const MultipleCheckedItemsToolbar = ({
  actionButtons,
  className,
  close,
  quantity,
  style,
}: Props) => {
  const [wasMoreThanOneUnit, setWasMoreThanOneUnit] = useState(false);
  const [isSlidingOut, setIsSlidingOut] = useState(false);

  useEffect(() => {
    if (quantity > 1) {
      setWasMoreThanOneUnit(true);
    }
    return () => {};
  }, [quantity]);

  return (
    <div
      className={cx(styles.changeMany, className, {
        "d-none": wasMoreThanOneUnit !== true,
        [styles.slideInUp]: quantity > 1,
        [styles.slideOutUp]: quantity <= 1 || isSlidingOut,
      })}
      style={style}
    >
      <div className="body-14-600 text-color-white mr-2">Zaznaczono {quantity}</div>
      {actionButtons}
      <Tooltip title="Więcej opcji">
        <IconButton icon={MdiMoreHoriz} theme="dark" variant="transparent" />
      </Tooltip>
      <IconButton
        icon={MdiClose}
        onClick={() => {
          setIsSlidingOut(true);
          setTimeout(() => {
            close();
            setIsSlidingOut(false);
          }, 200);
        }}
        theme="dark"
        variant="transparent"
      />
    </div>
  );
};

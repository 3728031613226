import { CreateCorrectionPosition, CreateCorrectionValues } from "../CreateCorrectionManuallyModal";
import { FieldName } from "../components/WholeAmountToPay";
import { getSinglePositionAmountForSummary } from "./getSinglePositionAmountForSummary";

export const useAmountPerVatRate = () => {
  const getFilteredPositions = (
    fieldName: FieldName,
    values: CreateCorrectionValues,
    vatRate: number,
  ) => {
    return values.positions.filter(position => {
      return (
        !position.alreadyInvoiced &&
        position.vatRate === vatRate &&
        position[fieldName] !== null &&
        position[fieldName] !== undefined
      );
    });
  };

  const getAmountPerVatRate = (position: CreateCorrectionPosition, fieldName: FieldName) => {
    const calculatedAmountWithTax = getSinglePositionAmountForSummary("amountWithTax", position);
    if (fieldName === "amountWithTax") return calculatedAmountWithTax;
    const calculatedTax = calculatedAmountWithTax * (position.vatRate / 100);
    if (fieldName === "taxAmountBeforeRounding") return calculatedTax;
    return calculatedAmountWithTax - calculatedTax;
  };

  const getAmount = (
    fieldName: FieldName,
    values: CreateCorrectionValues,
    vatRate: number,
  ): number => {
    return getFilteredPositions(fieldName, values, vatRate).reduce((acc, position) => {
      return acc + getAmountPerVatRate(position, fieldName);
    }, 0);
  };

  const getCurrencyConvertion = (
    fieldName: FieldName,
    values: CreateCorrectionValues,
    vatRate: number,
    conversionRate: number,
  ): number => {
    return getFilteredPositions(fieldName, values, vatRate).reduce((acc, position) => {
      return acc + getAmountPerVatRate(position, fieldName) * conversionRate!;
    }, 0);
  };

  return { getAmount, getCurrencyConvertion };
};

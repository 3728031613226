import { updateViesStatus, updateWhiteListStatus } from "api/trading-documents/calls";
import { useTradingDocument } from "api/trading-documents/hooks";
import { UUID } from "api/types";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";

export const useViesSectionActions = (tradingDocumentId: UUID) => {
  const { handleMutate, refetch } = useTradingDocument(
    { id: tradingDocumentId },
    { enabled: false },
  );

  const viesStatusMutation = useMutation(updateViesStatus, ({ toastr }) => ({
    onSuccess: payload => {
      handleMutate(draft => {
        Object.assign(draft, {
          ...draft,
          viesCheckedAt: payload.viesCheckedAt,
          viesValidStatus: payload.viesValidStatus,
        });
      });
      refetch();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

  const whiteListStatusMutation = useMutation(updateWhiteListStatus, ({ toastr }) => ({
    onSuccess: payload => {
      handleMutate(draft => {
        Object.assign(draft, {
          ...draft,
          whiteListCheckedAt: payload.whiteListCheckedAt,
          whiteListValidStatus: payload.whiteListStatus,
        });
      });
      refetch();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

  return { viesStatusMutation, whiteListStatusMutation };
};

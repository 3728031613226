import { createReduxReducer } from "utilities";
import { MiloSettings } from "api/milo-settings/models";
import { ThunkResult } from "./types";
import { getAllMiloSettings, getMiloSettingsRevision } from "api/milo-settings/calls";
import { deepMerge } from "utilities/deepMerge";
import { DeepPartial } from "utility-types";
import { createAction } from "@reduxjs/toolkit";

export interface State {
  settings: MiloSettings | null;
  isFetching: boolean;
  revision: number;
}

export const saveSettings = createAction<State>("settings/saveSettings");
export const updateSettings = createAction<DeepPartial<MiloSettings>>("settings/updateSettings");
export const setFetching = createAction<boolean>("settings/setFetching");

/**
 * THUNKS
 */
const fetchSettingsThunk = (): ThunkResult<void> => async (dispatch, getState) => {
  const state = getState();

  try {
    const revisionRes = await getMiloSettingsRevision();
    if (state.settings?.revision !== revisionRes?.revision) {
      dispatch(setFetching(true));
      const settingsRes = await getAllMiloSettings();
      if (settingsRes) {
        dispatch(
          saveSettings({
            revision: revisionRes.revision,
            settings: settingsRes,
            isFetching: false,
          }),
        );
        return;
      }
    }
  } catch (e) {}
};

export const actions = { saveSettings, fetchSettings: fetchSettingsThunk, updateSettings };

const initialState: State = {
  isFetching: false,
  revision: 0,
  settings: null,
};

export const reducer = createReduxReducer(initialState, {
  [saveSettings.type]: (state, action: { payload: State }) => {
    return { ...action.payload };
  },
  [setFetching.type]: (state, action: { payload: boolean }) => {
    return { ...state, isFetching: action.payload };
  },
  [updateSettings.type]: (state, action) => {
    return Object.assign(state, deepMerge(state, { settings: action.payload }));
  },
});

import { useCallback, useState } from "react";
import { queryString } from "utilities";

export const useFilters = <F extends {}>(initialFilters: F) => {
  const [filters, setFiltersLocal] = useState<F>(initialFilters);

  const setFilter = useCallback(<T extends keyof F, U extends F[T]>(name: T, value: U) => {
    setFiltersLocal(prev => ({ ...prev, [name]: value }));
  }, []);

  const setFilters = useCallback((newFilters: Partial<F>) => {
    setFiltersLocal(prev => ({ ...prev, ...newFilters }));
  }, []);

  return { filters, setFilter, setFilters, searchParams: queryString.stringify(filters) };
};

import { ViewStore } from "typeUtilities";
import { createViewState } from "utilities/createViewState";

type InitialState = {
  showOnlyRoutePoints: boolean;
  loader: string;
  graphhopperPolyline: { lat: number; lng: number }[];
  isOrderCommentsDrawerOpen: boolean;
  editingPasspointId: string | null;
  isLoading: boolean;
  isGraphhopperPolylineVisible: boolean;
  isFiltersDrawerOpen: boolean;
  isSettingCenterPoint: boolean;
  isPointPolylineVisible: boolean;
};

type ViewState = InitialState & {
  actions: {
    clear: () => void;
    toggle: (
      key: keyof Omit<
        InitialState,
        "graphhopperPolyline" | "infoWindowPoint" | "loader" | "editingPasspointId"
      >,
    ) => void;
    saveGraphhopperPolyline: (polyline: InitialState["graphhopperPolyline"]) => void;
    setState: (state: Partial<InitialState>) => void;
    setIsGraphhopperPolylineVisible: (value: boolean) => void;
    setIsSettingCenterPoint: () => void;
    openLoader: (value: string) => void;
    closeLoader: () => void;
  };
};

const initialState: InitialState = {
  showOnlyRoutePoints: false,
  graphhopperPolyline: [],
  editingPasspointId: null,
  isGraphhopperPolylineVisible: false,
  isPointPolylineVisible: true,
  isOrderCommentsDrawerOpen: false,
  isFiltersDrawerOpen: false,
  isLoading: false,
  isSettingCenterPoint: false,
  loader: "",
};

const viewState: ViewStore<ViewState> = (set, get) => {
  return {
    ...initialState,
    actions: {
      clear: () => {
        set(initialState);
      },
      toggle: key => {
        set(draft => {
          draft[key] = !draft[key];
        });
      },
      setState: state => {
        set(draft => {
          Object.assign(draft, state);
        });
      },
      openLoader: val => {
        set(draft => {
          draft.loader = val;
          draft.isLoading = true;
        });
      },
      closeLoader: () => {
        set(draft => {
          draft.loader = "";
          draft.isLoading = false;
        });
      },
      setIsGraphhopperPolylineVisible: val => {
        set(draft => {
          draft.isGraphhopperPolylineVisible = val;
        });
      },
      setIsSettingCenterPoint: () => {
        set(draft => {
          draft.isSettingCenterPoint = !draft.isSettingCenterPoint;
        });
      },
      saveGraphhopperPolyline: polyline => {
        set(draft => {
          draft.graphhopperPolyline = polyline;
        });
      },
    },
  };
};

export const useRouteViewState = createViewState(viewState);

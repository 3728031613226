import { PropsWithChildren } from "react";
import { BaseIcon } from "./types";
import { colorPalette } from "../colorsPalette";

export const SvgWrapper = ({
  size = "24",
  className,
  color,
  children,
  customColor,
}: PropsWithChildren<BaseIcon>) => (
  <div className="d-flex align-items-center" style={{ height: `${size}px`, width: `${size}px` }}>
    <svg
      className={className}
      width={size}
      height={size}
      fill={customColor ? customColor : colorPalette[color || "neutralBlack100"]}
      viewBox="0 0 24 24"
    >
      {children}
    </svg>
  </div>
);

import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./Field.module.css";
import { FieldKind, LayoutSchema, RampActivity } from "api/wms/layout/models";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { wmsLayoutActions } from "api/wms/layout/actions";

export const Field = ({
  field,
  area,
  viewScale,
  color,
  selectedHall,
}: {
  field: LayoutSchema["areas"][number]["fields"][number];
  area: LayoutSchema["areas"][number];
  viewScale: number;
  color: string;
  selectedHall: number | null;
}) => {
  if (field.kind === FieldKind.RAMP)
    return (
      <Ramp
        color={color}
        area={area}
        field={field}
        selectedHall={selectedHall}
        viewScale={viewScale}
      />
    );

  return <BaseField color={color} area={area} field={field} viewScale={viewScale} />;
};

const Ramp = ({
  field,
  area,
  viewScale,
  color,
  selectedHall,
}: {
  field: LayoutSchema["areas"][number]["fields"][number];
  area: LayoutSchema["areas"][number];
  viewScale: number;
  color: string;
  selectedHall: number | null;
}) => {
  // @ts-ignore
  const { data: rampStatuses } = wmsLayoutActions.useRampStatus(selectedHall, {
    enabled: Boolean(selectedHall),
  });
  const ramp = rampStatuses?.find(rampStatus => rampStatus.id === field.wmsId);

  const isRampActive = ramp?.activity === RampActivity.ACTIVE;
  return (
    <Popover
      variant="DARK"
      content={props => (
        <div className="p-4">
          <Typography fontSize="100" fontWeight="700">
            {field.verboseName}
          </Typography>
          <Typography fontSize="100" fontWeight="700" color="success500">
            Trwa załadunek
          </Typography>
          <div className="d-flex align-items-center gap-1">
            <Typography fontSize="100" fontWeight="700">
              trasa:
            </Typography>
            <Typography fontSize="100" fontWeight="500">
              {ramp?.unloading?.signature}
            </Typography>
          </div>
          <div className="d-flex align-items-center gap-1">
            <Typography fontSize="100" fontWeight="700">
              samochód:
            </Typography>
            <Typography fontSize="100" fontWeight="500">
              {ramp?.loading?.car?.registrationNumber}
            </Typography>
          </div>
        </div>
      )}
    >
      <div
        className={styles.field}
        style={{
          top: field.coords.y - area.coords.y + viewScale,
          left: field.coords.x - area.coords.x + viewScale,
          width: field.width + viewScale,
          height: field.height + viewScale,
          backgroundColor: color,
        }}
      >
        {isRampActive && (
          <div className={styles.wavesTB}>
            <div className={styles.wave__waveletBT}></div>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-center flex-1 h-100">
          <Typography fontSize="64" fontWeight="400" color="neutralWhite100">
            {field.verboseName}
          </Typography>
        </div>
      </div>
    </Popover>
  );
};

const BaseField = ({
  field,
  area,
  viewScale,
  color,
}: {
  field: LayoutSchema["areas"][number]["fields"][number];
  area: LayoutSchema["areas"][number];
  viewScale: number;
  color: string;
}) => {
  return (
    <div
      key={`${field.kind}-${field.id}`}
      className={styles.field}
      style={{
        top: field.coords.y - area.coords.y + viewScale,
        left: field.coords.x - area.coords.x + viewScale,
        width: field.width + viewScale,
        height: field.height + viewScale,
        backgroundColor: color,
      }}
    >
      <div className="d-flex align-items-center justify-content-center flex-1 h-100">
        <Typography fontSize="64" fontWeight="400" color="neutralWhite100">
          {field.verboseName}
        </Typography>
      </div>
    </div>
  );
};

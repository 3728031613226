import { OrderPositionTypes, Route } from "api/routes/models";

export const pickOrdersIdsFromOrdersPositions = (ordersPositions: Route["ordersPositions"]) => {
  function isOrderPredicate(
    item: Route["ordersPositions"][0],
  ): item is OrderPositionTypes["order"] {
    return (item as OrderPositionTypes["order"]).type === "order";
  }
  return ordersPositions.filter(isOrderPredicate).map(order => Number(order.id));
};

import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { Pagination } from "../types";
import { Log } from "./models";
import { ApiFetcher } from "hooks/createApiQuery";
import { logbookKeys } from "./keys";
import { queryFetch } from "apiConnectors/queryFetch";

const api = tokenRefreshMiddleware(apiFetch);

export const getLogs = (search: string) =>
  api<Pagination<Log>>({
    method: "GET",
    url: "/logbook/items" + search,
  });

const getLogsQuery = (search: string = ""): ApiFetcher<Pagination<Log>> => ({
  key: logbookKeys.logbookItems(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/logbook/items" + search,
    }),
});

export const logbookApi = {
  getLogsQuery,
};

import { WhEntryAggregate } from "api/trading-documents/models";
import styles from "../AddWhEntriesModal.module.css";
import cx from "classnames";
import React from "react";
import { UUID } from "api/types";
import { dateFns, getAnyErrorKey } from "utilities";
import { useState } from "react";
import downIcon from "assets/images/keyboardArrowDown.svg";
import upIcon from "assets/images/keyboardArrowUp.svg";
import { Button } from "components/common";
import { RightPanelTableRow } from "components/utils/drawer";
import { whEntrySectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { StateLabel } from "components/common/stateLabel";
import { WhEntryPackage } from "./WhEntryPackage";
import { SelectedWhEntry } from "../AddWhEntriesModal";
import { useMutation } from "hooks/useMutation";
import { deleteWhEntryAggregate, postWhEntryAggregate } from "api/trading-documents/calls";
import { MoreDataCounter } from "components/common/moreDataCounter";

interface Props {
  invoiceAdmissionLink: UUID;
  pickedWhEntries: SelectedWhEntry[];
  setPickedWhEntries: React.Dispatch<React.SetStateAction<SelectedWhEntry[]>>;
  whAggregateSearchPackage: string;
  whEntry: WhEntryAggregate;
}

export const WhEntry = ({
  invoiceAdmissionLink,
  pickedWhEntries,
  setPickedWhEntries,
  whAggregateSearchPackage,
  whEntry,
}: Props) => {
  const [showPackages, setShowPackages] = useState(true);

  const unassignWhEntryMutation = useMutation(
    (data: { whEntryId: UUID; relationId: UUID }) => {
      return deleteWhEntryAggregate(data.relationId);
    },
    ({ queryClient, toastr }) => ({
      onMutate: variables => {
        setPickedWhEntries(prevPickedWhEntries =>
          prevPickedWhEntries.map(_prevPicked => {
            if (_prevPicked.id === variables.whEntryId) {
              return {
                ..._prevPicked,
                isSelected: false,
                whEntryInvoiceAdmissionId: null,
              };
            }
            return _prevPicked;
          }),
        );
      },
      onSuccess: (_, variables) => {
        setPickedWhEntries(prevPickedWhEntries =>
          prevPickedWhEntries.filter(prevPicked => prevPicked.id !== variables.whEntryId),
        );
      },
      onError: (error, variables) => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
        setPickedWhEntries(prevPickedWhEntries =>
          prevPickedWhEntries.map(prevPicked => {
            if (prevPicked.id === variables.whEntryId) {
              return {
                ...prevPicked,
                isSelected: true,
                whEntryInvoiceAdmissionId: variables.relationId,
              };
            }
            return prevPicked;
          }),
        );
      },
    }),
  );

  const assignWhEntryMutation = useMutation(postWhEntryAggregate, ({ queryClient, toastr }) => ({
    onMutate: variables => {
      setPickedWhEntries(prevPicked => {
        return [
          ...prevPicked,
          { id: variables.whEntryAggregate, isSelected: true, whEntryInvoiceAdmissionId: null },
        ];
      });
    },
    onSuccess: (payload, variables) => {
      setPickedWhEntries(prevPickedWhEntries =>
        prevPickedWhEntries.map(prevPicked => {
          if (prevPicked.id === variables.whEntryAggregate) {
            return {
              ...prevPicked,
              whEntryInvoiceAdmissionId: payload.id,
            };
          }
          return prevPicked;
        }),
      );
    },
    onError: (error, variables) => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
      setPickedWhEntries(prevPicked =>
        prevPicked.filter(_whEntry => _whEntry.id !== variables.whEntryAggregate),
      );
    },
  }));

  const isWhEntryAlreadyAssigned = (): boolean => {
    const foundInvoiceAdmission = whEntry.invoiceAdmissionsLinks.find(
      _invoiceAdmission => _invoiceAdmission.id === invoiceAdmissionLink,
    );

    return foundInvoiceAdmission ? true : false;
  };

  const isWhEntryInPickedWhEntries = (): boolean => {
    const foundWhEntry = pickedWhEntries.find(pickedWhEntry => pickedWhEntry.id === whEntry.id);

    return foundWhEntry ? true : false;
  };

  const isWhEntrySelected = (): boolean => {
    const foundWhEntry = pickedWhEntries.find(pickedWhEntry => pickedWhEntry.id === whEntry.id);

    if (foundWhEntry) {
      return foundWhEntry.isSelected;
    }
    return false;
  };

  const hasPickedWhEntryRelationId = (): boolean => {
    const foundWhEntry = pickedWhEntries.find(pickedWhEntry => pickedWhEntry.id === whEntry.id);

    if (foundWhEntry && foundWhEntry.whEntryInvoiceAdmissionId) return true;
    return false;
  };

  return (
    <div
      className={cx(styles.reception, "p-2", {
        [styles.receptionSelected]: isWhEntrySelected(),
        [styles.stripesBgOrange]: whEntry.status === "EMPTY",
        [styles.inaccessible]: whEntry.status === "EMPTY" || isWhEntryAlreadyAssigned(),
      })}
      onClick={() => {
        if (whEntry.status !== "EMPTY" && isWhEntryAlreadyAssigned() === false) {
          if (isWhEntryInPickedWhEntries()) {
            if (hasPickedWhEntryRelationId()) {
              const foundWhEntry = pickedWhEntries.find(
                pickedWhEntry => pickedWhEntry.id === whEntry.id,
              );

              if (foundWhEntry && foundWhEntry.whEntryInvoiceAdmissionId) {
                unassignWhEntryMutation.mutate({
                  whEntryId: whEntry.id,
                  relationId: foundWhEntry.whEntryInvoiceAdmissionId,
                });
              }
            }
          } else {
            assignWhEntryMutation.mutate({
              whEntryAggregate: whEntry.id,
              invoiceAdmissionLink: invoiceAdmissionLink,
            });
          }
        }
      }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <div className="body-16">{whEntry.signature}</div>
          <span className="separator" />
          <div className="fs-12">
            {whEntry.whEntryClosedAt
              ? dateFns.format(new Date(whEntry.whEntryClosedAt), "dd.MM.yyyy")
              : "---"}
          </div>
          {whEntry.manufacturers.length > 0 && (
            <>
              <span className="separator" />
              <div className="position-relative">
                <div
                  className={cx("overflow body-14-600", {
                    "pr-4": whEntry.manufacturers.length - 2 > 0,
                  })}
                >
                  {whEntry.manufacturers.slice(0, 2).map((manufacturer, index) => {
                    if (index === 0 && whEntry.manufacturers.length > 1) {
                      return manufacturer ? `${manufacturer.manufacturer.name}, ` : "";
                    }
                    return manufacturer ? manufacturer.manufacturer.name : "";
                  })}{" "}
                  &nbsp;
                  {whEntry.manufacturers.length - 2 > 0 && (
                    <MoreDataCounter
                      counter={whEntry.manufacturers.length - 2}
                      style={{ width: "max-content" }}
                    />
                  )}
                </div>
              </div>
            </>
          )}
          {isWhEntryAlreadyAssigned() && <StateLabel kind="deepPurple">Już na liście</StateLabel>}
        </div>
        <div className="d-flex align-items-center gap-2">
          <Button
            kind="transparent-black"
            onClick={e => {
              e.stopPropagation();
              setShowPackages(show => !show);
            }}
            size="square-s"
          >
            <div className="btnBase btnBaseSmall btnBase16">
              {showPackages ? <img alt="Zwiń" src={upIcon} /> : <img alt="Pokaż" src={downIcon} />}
            </div>
          </Button>
        </div>
      </div>
      {showPackages && (
        <>
          <RightPanelTableRow
            className={styles.tableHeaderPZ}
            grid={whEntrySectionListConfig.grid}
            hover={false}
          >
            <div>#</div>
            <div>paczka</div>
            <div>numer paczki</div>
            <div className="d-flex align-items-center justify-content-end">wykorzystane</div>
            <div className="d-flex align-items-center justify-content-end">pozostało</div>
            <div className="d-flex align-items-center justify-content-end">w sumie</div>
          </RightPanelTableRow>
          {whEntry.items.map((_package, index) => (
            <WhEntryPackage
              index={index}
              key={_package.id}
              whAggregateSearchPackage={whAggregateSearchPackage}
              whEntryPackage={_package}
            />
          ))}
        </>
      )}
    </div>
  );
};

import * as React from "react";
import pencilImg from "assets/images/1.svg";
import styles from "./EditableHoverEffect.module.css";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
}

export const EditableHoverEffect = ({ children, disabled = false }: Props) => {
  return (
    <div className={cx(styles.inputBox, "d-flex align-items-center", { disabled })}>
      {children}
      <div className={styles.editIcon}>
        <img src={pencilImg} alt="Edytuj" />
      </div>
    </div>
  );
};

export const pink = {
  pink12: "#D639641F",
  pink25: "#F8E5EC",
  pink32: "#D6396452",
  pink50: "#EFBDCF",
  pink75: "#E694B0",
  pink100: "#EB5281",
  pink200: "#D63964",
  pink300: "#B52C62",
  pink400: "#7C1E4E",
  pink500: "#64183F",
  pink600: "#440E2A",
};

import { ExternalManufacturerLineItemsTab } from "api/external-manufacturing/models";

export const getTabs = (): { label: string; name: ExternalManufacturerLineItemsTab }[] => {
  return [
    { label: "Do zrobienia", name: ExternalManufacturerLineItemsTab["to-do"] },
    { label: "W produkcji", name: ExternalManufacturerLineItemsTab["in-production"] },
    { label: "Gotowe do odbioru", name: ExternalManufacturerLineItemsTab["ready-to-deliver"] },
    { label: "Odebrane", name: ExternalManufacturerLineItemsTab["picked-up"] },
    { label: "Anulowane", name: ExternalManufacturerLineItemsTab["cancelled"] },
    { label: "Wszystkie", name: ExternalManufacturerLineItemsTab["all"] },
  ];
};

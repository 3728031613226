import { invoicePaymentStatusDict, TradingDocument } from "api/trading-documents/models";
import { StateLabel } from "components/common/stateLabel";
import { RightPanelSection } from "components/utils/drawer";
import { PaymentDeadlineDeltaLabel } from "pages/tradingDocuments/shared/components/PaymentDeadlineDeltaLabel";
import { getStatusLabelKind } from "pages/tradingDocuments/shared/utils/getStatusLabelKind";
import emergency from "assets/images/emergency_home.svg";

interface Props {
  tradingDocument: TradingDocument;
}

export const TitleSection = ({ tradingDocument }: Props) => {
  return (
    <RightPanelSection>
      <div className="d-flex align-items-center gap-2">
        <div className="fs-24">{getTradingDocumentSignaturePrefix(tradingDocument)}</div>
        {tradingDocument.invoiceType === "SALES" ? (
          tradingDocument.hasCorrectionRequired ? (
            <StateLabel className="gap-1" kind="deepPurple">
              <img src={emergency} alt="Uwaga" />
              wymaga zmiany
            </StateLabel>
          ) : null
        ) : null}
        <StateLabel
          kind={getStatusLabelKind(tradingDocument.paymentStatus, tradingDocument.paymentDeadline)}
        >
          {tradingDocument.paymentStatus === "PAID" ? (
            <>{invoicePaymentStatusDict[tradingDocument.paymentStatus]}</>
          ) : (
            "nieopłacona"
          )}
        </StateLabel>
        {tradingDocument.paymentStatus !== "PAID" && (
          <div className="body-10">
            {tradingDocument.paymentDeadlineDelta !== null && (
              <PaymentDeadlineDeltaLabel
                paymentDeadlineDelta={tradingDocument.paymentDeadlineDelta}
              />
            )}
          </div>
        )}
      </div>
    </RightPanelSection>
  );
};

const getTradingDocumentSignaturePrefix = (tradingDocument: TradingDocument): string => {
  if (tradingDocument.invoiceType === "PROFORMA") return `Proforma ${tradingDocument.signature}`;
  if (tradingDocument.type === "INVOICE") return `Faktura VAT ${tradingDocument.signature}`;
  return `Paragon ${tradingDocument.signature}`;
};

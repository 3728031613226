import { orderApi, orderFileFactory } from "api/orders/calls";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { Order } from "./models";
import { StatusHandlerHelpers } from "components/utils";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useErrorToastr } from "hooks/useErrorToastr";
import { fileDownloader } from "fileDownloader";

const useSingleItemQuantityWarehouseLocations = createPaginatedApiQuery(
  orderApi.getSingleItemQuantityWarehouseLocations,
);

const useDownloadOrderSpecificationPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (order: Order, helpers: StatusHandlerHelpers) => {
    helpers.startFetching();
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = orderFileFactory.orderSpecificationPdf(order);
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    helpers.stopFetching();
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

export const orderActions = {
  useSingleItemQuantityWarehouseLocations,
  useDownloadOrderSpecificationPdf,
};

import styles from "./NoContent.module.css";
import cx from "classnames";

interface Props {
  status?: number | string;
  on?: boolean;
  img: string;
  header: string;
  variant?: "A" | "B";
  className?: string;
}

export const NoContent = ({ on = true, header, img, variant = "A", className }: Props) => {
  if (on) {
    if (variant === "B") {
      return (
        <div className={cx(styles.emptyListVariantB, "border-top", className)}>
          <img src={img} alt="" />
          <h4>{header}</h4>
        </div>
      );
    }
    return (
      <div>
        <div className={cx(styles.emptyList, className)}>
          <div className={styles.emptyListContent}>
            <div className={styles.emptyListHeader}>{header}</div>
            <div className={styles.emptyListIcon}>
              <img src={img} alt="" />
            </div>
            <div className="d-flex justify-content-center pb-4 mt-3"></div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { CreateCorrectionValues } from "../CreateCorrectionManuallyModal";
import { FieldName } from "./WholeAmountToPay";
import { useAmountPerVatRate } from "../utils/useAmount";

interface Props {
  conversionRate?: number;
  fieldName: FieldName;
  values: CreateCorrectionValues;
  vatRate: number;
}

export const AmountPerVatRate = ({ conversionRate, fieldName, values, vatRate }: Props) => {
  const { getAmount, getCurrencyConvertion } = useAmountPerVatRate();

  if (
    values.positions
      .filter(position => position.vatRate === vatRate && !position.alreadyInvoiced)
      .some(
        position =>
          position[fieldName] === undefined ||
          position[fieldName] === null ||
          position.quantity < 0 ||
          position[fieldName] < 0,
      )
  ) {
    return (
      <div className="d-flex align-items-center justify-content-end text-color-coral">
        Błąd przeliczania
      </div>
    );
  }

  if (conversionRate) {
    return (
      <>
        <strong className="body-14 fw-800">
          <FinanceAmountFormat
            value={getCurrencyConvertion(fieldName, values, vatRate, conversionRate)}
          />
        </strong>
        <div className="fw-700">PLN</div>
      </>
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-end">
      <FinanceAmountFormat value={getAmount(fieldName, values, vatRate)} />
    </div>
  );
};

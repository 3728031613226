import { yup } from "utilities";

const requiredMsg = (field: string): string => {
  return `Pole ${field} jest wymagane`;
};

const minLengthMsg = (field: string): string => {
  return `Pole ${field} nie może być ujemne`;
};

export const validationSchema = yup.object().shape({
  displayPosition: yup
    .number()
    .required(requiredMsg("Nr pozycji"))
    .min(0, minLengthMsg("Nr pozycji")),
  quantity: yup
    .number()
    .required(requiredMsg("Liczba"))
    .min(0, minLengthMsg("Liczba")),
  amountWithoutTax: yup
    .number()
    .required(requiredMsg("Cena netto"))
    .min(0, minLengthMsg("Cena netto")),
  vatRate: yup
    .number()
    .required(requiredMsg("VAT"))
    .min(0, minLengthMsg("VAT")),
  discount: yup
    .number()
    .required(requiredMsg("Rabat"))
    .min(0, minLengthMsg("Rabat")),
});

import { useIndexesFulfillment } from "api/indexes/hooks";
import { Checkbox, CommonError, Modal, Pagination, Spinner } from "components/utils";
import { getAnyErrorKey, pluralize } from "utilities";
import listEmptyIcon from "assets/images/el2.png";
import { IndexItem } from "./IndexItem";
import styles from "./IndexFulfillmentModal.module.css";
import cx from "classnames";
import { useState } from "react";
import { Button } from "..";
import { useListCheckboxes } from "hooks/useListCheckboxes";
import { useMutation } from "hooks/useMutation";
import { postRequestProduction } from "api/indexes/calls";
import { useQueryUtils, useToastr } from "hooks";
import { RequestIndexProduction } from "api/indexes/models";
import { indexesKeys } from "api/indexes/keys";
import { NoContent } from "components/utils/noContent";
import { useFilters } from "hooks/useFilters";

interface Props {
  close: () => void;
  id: number;
  source: "route" | "order-group";
}

type Filters = {
  page: string;
  search: string;
};

export const IndexFulfillmentModal = ({ close, id, source }: Props) => {
  const { setFilter, searchParams } = useFilters<Filters>({
    page: "1",
    search: "",
  });
  const hackSearch = `${source}$${id}$${searchParams}`;
  const { data: indexes, error, isLoading, pagination } = useIndexesFulfillment(hackSearch);
  const checkboxes = useListCheckboxes("default");
  const areAllIndexesChecked = checkboxes.areAllChecked(indexes.map(index => index.id));
  const [showCheckbox, setShowCheckbox] = useState(false);
  const { handlePaginatedListUpdate } = useQueryUtils();
  const toastr = useToastr();

  const requestProductionMutation = useMutation(
    (indexesToProduce: RequestIndexProduction[]) =>
      postRequestProduction(source, id, indexesToProduce),
    {
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      },
      onSuccess: payload => {
        payload.forEach(index => {
          return handlePaginatedListUpdate(indexesKeys.indexesFulfillment(), index.id, index);
        });
        setShowCheckbox(false);

        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Zlecono produkcję dla wybranych indeksów",
        });
      },
    },
  );

  const handleRequestIndexProduction = (): void => {
    const values = Object.values(checkboxes.values).map(index => {
      return {
        indexId: index.id,
        isSelfProduced: index.isSelfProduced,
        manufacturer: index.manufacturer ? index.manufacturer.id : null,
        number: index.counters.missing,
      };
    });
    requestProductionMutation.mutate(values);
  };

  if (error) {
    return (
      <Modal close={close} isOpen>
        <CommonError status={error._httpStatus_} />
      </Modal>
    );
  }

  if (isLoading) {
    return (
      <Modal close={close} isOpen>
        <Spinner color="blue" on={isLoading} size="big" text="trwa wczytywanie indeksów" />
      </Modal>
    );
  }

  return (
    <Modal close={close} isOpen size={{ all: { width: "80vw" } }}>
      <div className="w-100">
        <h1 className="ml-0">Zapotrzebowanie indeksów</h1>
        <div className={cx(styles.container, "container")}>
          <div className="row fs-10 text-color-grey border-bottom">
            <div className="col-4 d-flex align-items-center">Indeks</div>
            <div className="col-1 d-flex align-items-center justify-content-center">Potrzebne</div>
            <div className="col-1 d-flex align-items-center justify-content-center">Dostępne</div>
            <div className="col-1 d-flex align-items-center justify-content-center">
              Zarezerwowane
            </div>
            <div className="col-1 d-flex align-items-center justify-content-center">
              W produkcji
            </div>
            <div className="col-1 d-flex align-items-center justify-content-center">
              Zlecona produkcja (aktywna)
            </div>
            <div className="col-1 d-flex align-items-center justify-content-center">
              Zlecona produkcja (zakończona)
            </div>
            <div className="col-1 d-flex align-items-center justify-content-center">Brakuje</div>
            <div className="col-1 d-flex align-items-center justify-content-center pb-1">
              <Button kind="primary" size="small" onClick={() => setShowCheckbox(prev => !prev)}>
                Zleć wszystkie
              </Button>
            </div>
          </div>
          {showCheckbox && (
            <div className="row">
              <div className="col-12 p-2 pt-3 pb-3 d-flex align-items-center">
                <div className="d-flex align-items-center mr-2 fs-12 text-color-grey">
                  <Checkbox
                    checked={areAllIndexesChecked && checkboxes.valuesArray.length > 0}
                    className="mr-2"
                    label={
                      areAllIndexesChecked && checkboxes.valuesArray.length > 0
                        ? "Odznacz wszystkie"
                        : "Zaznacz wszystkie"
                    }
                    name=""
                    onChange={() => {
                      areAllIndexesChecked
                        ? checkboxes.reset()
                        : checkboxes.addMany(
                            indexes.map(index => {
                              return {
                                attributes: index.attributes,
                                counters: index.counters,
                                id: index.id,
                                isSelfProduced: index.isSelfProduced,
                                manufacturer: index.manufacturer?.id,
                                name: index.name,
                              };
                            }),
                          );
                    }}
                    overwrites={{ wrapper: { style: { marginLeft: 8 } } }}
                  />
                  (zaznaczono {checkboxes.valuesArray.length}{" "}
                  {pluralize.pl(checkboxes.valuesArray.length, {
                    singular: "indeks",
                    plural: "indeksy",
                    other: "indeksów",
                  })}
                  )
                </div>
                <Button
                  disabled={checkboxes.valuesArray.length === 0}
                  kind="primary"
                  size="small"
                  onClick={handleRequestIndexProduction}
                >
                  Zleć
                </Button>
              </div>
            </div>
          )}
          {indexes.length > 0 ? (
            indexes.map(index => (
              <IndexItem
                checkboxes={checkboxes}
                index={index}
                key={index.id}
                requestProductionMutation={requestProductionMutation}
                showCheckbox={showCheckbox}
                source={source}
                sourceId={id}
              />
            ))
          ) : (
            <NoContent
              header="Lista nie zawiera elementów"
              img={listEmptyIcon}
              variant="B"
              className="border-top-0"
            />
          )}
          <Pagination
            mode="manual"
            onChange={page => {
              setFilter("page", String(page));
            }}
            pagination={pagination}
          />
        </div>
      </div>
    </Modal>
  );
};

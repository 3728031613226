import { useToggle } from "hooks";
import { Button } from "components/common";
import styles from "./MapOverlay.module.css";

export const MapOverlay = () => {
  const { isOpen, close } = useToggle(true);

  if (!isOpen) {
    return null;
  }
  return (
    <div className={styles.overlay}>
      <div className={styles.content}>
        <h5 className="mb-3">
          Po uzupełnieniu formularza zlokalizuj lub zaznacz na mapie lokalizację punktu dostawy.
          Upewnij się, że adres się zgadza.
        </h5>
        <Button kind="primary" onClick={close} className="mt-2">
          Rozumiem
        </Button>
      </div>
    </div>
  );
};

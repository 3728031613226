import { countryFullNames, PurchaseInvoiceToReview } from "api/trading-documents/models";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  invoice: PurchaseInvoiceToReview;
}

export const CustomerInfoSection = ({ invoice }: Props) => {
  return (
    <RightPanelSection title="Dane kontrahenta">
      <div className="pb-3">
        {invoice.sellerCompanyName && (
          <div className="body-18 pb-1">{invoice.sellerCompanyName}</div>
        )}
        {invoice.sellerStreet && <div className="body-14-500 pb-1">{invoice.sellerStreet}</div>}
        {(invoice.sellerPostCode || invoice.sellerCity) && (
          <div className="body-14-500 pb-1">
            {invoice.sellerPostCode}&nbsp;{invoice.sellerCity}
          </div>
        )}
        {invoice.countryCode && (
          <div className="body-14-500 pb-1">{countryFullNames[invoice.countryCode]}</div>
        )}
        <div className="body-14-500 pb-1">
          Bank:&nbsp;{invoice.sellerBankName ? invoice.sellerBankName : "---"}
        </div>
        <div className="body-14-500 pb-1">
          Konto nr:&nbsp;
          {invoice.sellerBankAccountNumber ? invoice.sellerBankAccountNumber : "---"}
        </div>
        {invoice.sellerTaxId && (
          <div className="body-14-500 pb-1">NIP:&nbsp;{invoice.sellerTaxId}</div>
        )}
      </div>
    </RightPanelSection>
  );
};

import { useToggle } from "./hooks";
import { tuplify } from "utilities";
import { useMemo, useCallback } from "react";

function trimArray<A extends any[]>(arr: A, limit: number) {
  const a = [...arr];
  a.splice(0, arr.length - limit);
  return a as A;
}

function sortDateDesc<T extends { id: number | string }>(arr: T[]) {
  return [...arr].sort((a, b) => (a.id > b.id ? 1 : -1));
}

/**
 *
 * @param arr
 * @param trim
 * @example
 * const [trimmedComments, { toggle, areTrimmed }] = useTrimmedArray(comments, trim);
 *
 */
export function useTrimmedArray<T extends { id: number | string }>(arr: T[], trim: number) {
  const { isOpen, toggle, open } = useToggle(true);
  const trimmedArr = useMemo(
    () => (isOpen ? sortDateDesc(trimArray(arr, trim)) : sortDateDesc(arr)),
    [arr, isOpen, trim],
  );
  const resetTrim = useCallback(() => open(), [open]);
  const isTrimmable = arr.length > trim;
  return tuplify(trimmedArr, { areTrimmed: isOpen, toggle, reset: resetTrim, isTrimmable });
}

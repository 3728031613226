import {
  EmployeeWorkingDay as EmployeeWorkingDayModel,
  SelectedSource,
  WorkingDay,
} from "api/new-production-plans/models";
import { UUID } from "api/types";
import cx from "classnames";
import { Droppable } from "react-beautiful-dnd";
import localStyles from "./EmployeeWorkingDay.module.css";
import { EmptyManufacturingItem } from "./subcomponents/manufacturingItem/EmptyManufacturingItem";
import { ManufacturingItem } from "./subcomponents/manufacturingItem/ManufacturingItem";
import { Slots } from "./subcomponents/slots/Slots";

interface Props {
  days: WorkingDay[];
  employeeId: string | number;
  employeeWorkingDay: EmployeeWorkingDayModel;
  keyStrings: Record<string, string>;
  productionPlanId: UUID;
  selectedSources: SelectedSource[];
}

export const EmployeeWorkingDay = ({
  days,
  employeeId,
  employeeWorkingDay,
  keyStrings,
  productionPlanId,
  selectedSources,
}: Props) => {
  return (
    <div
      className={cx(localStyles.dayCard, {
        [localStyles.dayCardOff]: !employeeWorkingDay.isAvailable,
        [localStyles.dayCardHoliday]: days.find(day => day.date === employeeWorkingDay.date)!
          .isHoliday,
        [localStyles.dayCardSaturday]:
          days.find(day => day.date === employeeWorkingDay.date)!.dayOfTheWeek === "SATURDAY",
        [localStyles.dayCardSunday]:
          days.find(day => day.date === employeeWorkingDay.date)!.dayOfTheWeek === "SUNDAY",
      })}
    >
      <div className={localStyles.dayCardInner}>
        <Slots
          employeeId={employeeId}
          employeeWorkingDay={employeeWorkingDay}
          productionPlanId={productionPlanId}
        />
        <div className={localStyles.manufacturingItems}>
          <Droppable droppableId={`plan$${employeeWorkingDay.id}`}>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {employeeWorkingDay.manufacturingWorkingUnits.length > 0 ? (
                  <div className={localStyles.manufacturingItems}>
                    {employeeWorkingDay.manufacturingWorkingUnits.map(
                      (manufacturingItem, index) => (
                        <ManufacturingItem
                          employeeWorkingDayId={employeeWorkingDay.id}
                          index={index}
                          key={manufacturingItem.id}
                          keyStrings={keyStrings}
                          manufacturingItem={manufacturingItem}
                          manufacturingWorkingUnitEmployeeWorkingDayId={
                            manufacturingItem.manufacturingWorkingUnitEmployeeWorkingDayId
                          }
                          productionPlanId={productionPlanId}
                          selectedSources={selectedSources}
                          source="EMPLOYEE_WORKING_DAY"
                        />
                      ),
                    )}
                  </div>
                ) : (
                  <EmptyManufacturingItem snapshot={snapshot} />
                )}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </div>
  );
};

import { useMutation, useMutationOptions } from "hooks/useMutation";
import { InfoLabel } from "components/common/infoLabel";
import { TradingDocument } from "api/trading-documents/models";
import { useSelector } from "hooks";
import { RightPanelSection } from "components/utils/drawer";
import { Select } from "components/utils";
import { patchTradingDocument } from "api/trading-documents/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";

interface Props {
  tradingDocument: TradingDocument;
}

export const AssignAccountancyAccount = ({ tradingDocument }: Props) => {
  const updateTradingDocumentOptions = useMutationOptions(
    patchTradingDocument,
    ({ queryUtils }) => ({
      onMutate: toUpdate => {
        const prevDetails = queryUtils.handleMutate(
          tradingDocumentsKeys.tradingDocument.details(String(toUpdate.id)),
          toUpdate,
        );

        const prevList = queryUtils.handlePaginatedListUpdate(
          tradingDocumentsKeys.tradingDocument.list(),
          toUpdate.id,
          toUpdate,
        );

        return { prevDetails, prevList };
      },
      onError: (error, toUpdate, context) => {
        queryUtils.rollback(
          tradingDocumentsKeys.tradingDocument.details(String(toUpdate.id)),
          // @ts-ignore
          context.prevDetails,
          error,
        );
        queryUtils.rollbackList(
          tradingDocumentsKeys.tradingDocument.list(),
          // @ts-ignore
          context.prevList,
          toUpdate.id,
        );
      },
    }),
  );

  const changeAccountancyAccount = useMutation(
    updateTradingDocumentOptions.mutationFn,
    updateTradingDocumentOptions,
  );

  const ledgerAccounts = useSelector(store => store.partials.ledgerAccounts);

  return (
    <RightPanelSection title="">
      <div className="pb-3 w-100">
        <InfoLabel title="konto księgowe">
          <Select
            width="full"
            size="small"
            items={ledgerAccounts}
            onChange={value => {
              if (value) {
                changeAccountancyAccount.mutate({
                  id: tradingDocument.id,
                  ledgerAccount: value,
                });
              }
            }}
            selectedItem={
              tradingDocument.ledgerAccount ? tradingDocument.ledgerAccount.name : "wtf"
            }
            itemToDisplaySelected={() => (
              <div className="d-flex align-items-center">
                <span className="fs-16">
                  {tradingDocument.ledgerAccount
                    ? tradingDocument.ledgerAccount.name
                    : "Wybierz konto"}
                </span>
              </div>
            )}
          />
        </InfoLabel>
      </div>
    </RightPanelSection>
  );
};

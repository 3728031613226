import { capitalizeFirstLetter } from "./utilities";

export type DateOptions = { label: string; value: string }[];

export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date
    .getDate()
    .toString()
    .padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const createSingleDateOptions = (): DateOptions => {
  const currentDate = new Date();
  const newDates: DateOptions = [];

  for (let i = 0; i <= 5; i++) {
    const date = new Date();
    date.setDate(currentDate.getDate() - i);

    const dayOptions: any = {
      weekday: "long",
    };

    let dayLabel = date.toLocaleDateString("pl-PL", dayOptions);

    if (i === 0) {
      dayLabel = "Dzisiaj";
    } else if (i === 1) {
      dayLabel = "Wczoraj";
    }

    const dateKey = formatDate(date);

    newDates.push({
      label: capitalizeFirstLetter(dayLabel),
      value: dateKey,
    });
  }

  return newDates;
};

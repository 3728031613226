import { DatePicker } from "components/utils/datePicker";
import { useQuery } from "hooks";
import { FilterDateRangeWithOptionsType } from "../../types";
import { formatDate } from "../shared/utils/formatDate";
import { Options } from "./components/Options";
import styles from "./FilterDateRangeWithOptions.module.css";

export const FilterDateRangeWithOptions = ({ name, options }: FilterDateRangeWithOptionsType) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const [startDateName, endDateName] = name;
  const queryStartDate = query[startDateName];
  const queryEndDate = query[endDateName];

  const handleDateChange = (date: Date | null, name: string) => {
    updateQuery({
      [name]: date ? formatDate(date) : "",
      page: 1,
    });
  };

  return (
    <div>
      <div className="d-flex">
        <div className="position-relative mr-1">
          <span className={styles.label}>Od:</span>
          <DatePicker
            overwrites={{ container: { className: styles.container } }}
            placeholder="Wybierz"
            className={styles.date}
            value={queryStartDate}
            selectsStart
            startDate={queryStartDate ? new Date(queryStartDate) : null}
            endDate={queryStartDate ? new Date(queryStartDate) : null}
            maxDate={queryEndDate ? new Date(queryEndDate) : null}
            onChange={date => handleDateChange(date, startDateName)}
          />
        </div>
        <div className="position-relative">
          <span className={styles.label}>Do:</span>
          <DatePicker
            overwrites={{ container: { className: styles.container } }}
            placeholder="Wybierz"
            className={styles.date}
            value={queryEndDate}
            selectsEnd
            startDate={queryStartDate ? new Date(queryStartDate) : null}
            endDate={queryEndDate ? new Date(queryEndDate) : null}
            minDate={queryStartDate ? new Date(queryStartDate) : null}
            onChange={date => handleDateChange(date, endDateName)}
          />
        </div>
      </div>
      <Options name={name} options={options} />
    </div>
  );
};

import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import cx from "classnames";
import styles from "../ProductsList.module.css";
import { manufacturingSchemasListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { ProductLight } from "api/products/models";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  product: ProductLight;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID | number,
    index: number,
    togglePanel: () => void,
  ) => void;
  togglePanel: (id: string) => void;
  search: string;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  product,
  selectMutlipleItems,
  togglePanel,
  search,
}: Props) => {
  return (
    <>
      <ModuleRow
        className={cx("nowrap", styles.fixedSingleRouteRow, {
          active: isActive(String(product.id)),
          highlighted:
            isHighlighted(String(product.id)) || highlightedRows.some(row => row.id === product.id),
        })}
        gridStyle={manufacturingSchemasListConfig.grid}
        onMouseDown={event =>
          selectMutlipleItems(event, product.id, index, () => togglePanel(String(product.id)))
        }
      >
        <div>{product.name.length > 0 ? product.name : "--"}</div>
      </ModuleRow>
    </>
  );
};

import { Route } from "components/utils";
import { HelpdeskHeader } from "pages/helpdesk/components/HelpdeskHeader/HelpdeskHeader";
import { HelpdeskNewIssue } from "pages/helpdesk/newIssue/NewIssue";
import { NotFound } from "pages/notFound";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

const Helpdesk = ({ match }: RouteComponentProps) => {
  return (
    <>
      <HelpdeskHeader />
      <Switch>
        <Route
          exact={true}
          path={`${match.path}`}
          render={() => <Redirect to={`${match.path}/list`} />}
        />
        <Route
          exact={true}
          path={`${match.path}/new-issue/:sourcePath`}
          component={HelpdeskNewIssue}
          title="Milo - helpdesk"
        />
        <Route exact={true} path="*" component={NotFound} title="Milo" />
      </Switch>
    </>
  );
};

export default Helpdesk;

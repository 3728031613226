import styles from "./ColorLabel.module.css";
import cx from "classnames";

interface Props {
  className?: string;
  color?: string;
  height?: number;
  width?: number;
}

export const ColorLabel = ({ className, color, height, width }: Props) => {
  return (
    <div
      className={cx(styles.colorLabel, className, {
        [styles.defaultSize]: height === undefined && width === undefined,
        [styles.defaultColor]: color === undefined,
      })}
      style={{
        background: color ? color : "",
        height: height ? `${height}px` : "",
        width: width ? `${width}px` : "",
      }}
    />
  );
};

import linksIcon from "assets/images/19w.svg";
import createIcon from "assets/images/20w.svg";
import kivyIcon from "assets/images/K.svg";
import cx from "classnames";
import { FLAVOR } from "CONSTANTS";
import { clickOutsideIgnoreClass, clickOutsideShortcutsIgnoreClass } from "../Navbar";
import styles from "../Navbar.module.css";

export const Footer = ({
  createNavbarControlsToggle,
  shortcutsToggle,
}: {
  createNavbarControlsToggle: () => void;
  shortcutsToggle: () => void;
}) => {
  return (
    <div
      className={cx(
        "d-flex flex-column justify-content-end align-items-center pt-3 pb-3",
        styles.navLeftFooter,
      )}
    >
      {FLAVOR === "main" && (
        <button
          className={cx(
            "d-flex align-items-center justify-content-center mb-2",
            styles.bgPurple,
            styles.btnCircularMd,
            clickOutsideShortcutsIgnoreClass,
          )}
          type="button"
          onClick={shortcutsToggle}
        >
          <img src={linksIcon} alt="Lista linków" />
        </button>
      )}

      <button
        className={cx(
          "d-flex align-items-center justify-content-center mb-2",
          styles.bgBlue,
          styles.btnCircularMd,
          clickOutsideIgnoreClass,
        )}
        onClick={createNavbarControlsToggle}
      >
        <img src={createIcon} alt="Utwórz..." />
      </button>

      <div className={cx("pt-3", styles.linkToKivy)}>
        <a href="https://kivy.pl" target="_blank" rel="noopener noreferrer" title="Kivy Software">
          <strong>crafted by</strong>
          <img src={kivyIcon} alt="Logo Kivy Software" />
        </a>
      </div>
    </div>
  );
};

import { useProductionPlanSchedule } from "api/new-production-plans/hooks";
import { CommonError, MockupLoader } from "components/utils";
import { RouteComponentProps } from "react-router-dom";
import { Header } from "./subcomponents/header/Header";
import { LeftToolbar } from "./subcomponents/leftToolbar/LeftToolbar";
import styles from "./ProductionPlanDetails.module.css";
import { DateBar } from "./subcomponents/dateBar/DateBar";
import { EmployeeRow } from "./subcomponents/employeeRow/EmployeeRow";
import { Shelf } from "./shelf/Shelf";
import { useEffect, useState } from "react";
import { SelectedSource, ShelfFilters } from "api/new-production-plans/models";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { validateManufacturingWorkingUnitDrop } from "./utils/validateManufacturingWorkingUnitDrop";
import { useAddManufacturingWorkingUnit } from "./hooks/useAddManufacturingWorkingUnit";
import { useSelector } from "hooks";
import cx from "classnames";
import { validateManufacturingWorkingUnitOnPlanDrop } from "./utils/validateManufacturingWorkingUnitOnPlanDrop";
import { useMoveManufacturingWorkingUnitBetweenEmployeesAndDays } from "./hooks/useMoveManufacturingWorkingUnitBetweenEmployeesAndDays";

interface Props {
  routeComponentsProps: RouteComponentProps<{ id: string }>;
}

const PAGE_HEADER_HEIGHT = 100;

export const ProductionPlanDetails = ({ routeComponentsProps: { match } }: Props) => {
  const { data: productionPlan, error, isLoading, refetch } = useProductionPlanSchedule(
    match.params.id,
  );
  const [keyStrings, setKeyStrings] = useState<Record<string, string>>({});
  const [selectedSources, setSelectedSources] = useState<SelectedSource[]>([]);

  const addManufacturingWorkingUnitMutation = useAddManufacturingWorkingUnit(keyStrings);
  const moveManufacturingWorkingUnitMutation = useMoveManufacturingWorkingUnitBetweenEmployeesAndDays();

  const storedHeight = useSelector(store => store.ui.shelfHeight);

  // state for shelf filters manipulation in modals
  const [shelfFilters, setShelfFilters] = useState<ShelfFilters>({
    orders: {},
    orderGroups: {},
    products: {},
    routes: {},
  });

  useEffect(() => {
    if (productionPlan) {
      const updatedShelfFilters = {
        orders: productionPlan.shelfFilters.orders,
        orderGroups: productionPlan.shelfFilters.orderGroups,
        products: productionPlan.shelfFilters.products,
        routes: productionPlan.shelfFilters.routes,
      };
      setShelfFilters(updatedShelfFilters);
    }
  }, [productionPlan]);

  if (error) {
    <CommonError status={error._httpStatus_} />;
  }

  if (isLoading) {
    return <MockupLoader type="smallList" />;
  }

  if (!productionPlan) return null;

  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) return;

    // Check if destination and source exist
    // Check if we drag from SHELF to PLAN (any other combination is wrong at this point)
    if (result.destination && result.source && validateManufacturingWorkingUnitDrop(result)) {
      const destinationArray = result.destination?.droppableId.split("$");
      const sourceArray = result.source.droppableId.split("$");

      addManufacturingWorkingUnitMutation.mutate({
        employeeWorkingDay: destinationArray[1],
        manufacturingWorkingUnit: result.draggableId,
        stageManufacturingPlan: match.params.id,
        sourceId: sourceArray[1],
      });
    }

    // Check if we drag between employees and employee working days (PLAN to PLAN)
    if (result.destination && result.source && validateManufacturingWorkingUnitOnPlanDrop(result)) {
      const destinationArray = result.destination?.droppableId.split("$");
      const sourceArray = result.source.droppableId.split("$");

      // Unable to drag and drop inside one employeeWorkingDay (for now)
      if (destinationArray[1] !== sourceArray[1]) {
        moveManufacturingWorkingUnitMutation.mutate({
          employeeWorkingDay: destinationArray[1],
          manufacturingWorkingUnit: result.draggableId,
          stageManufacturingPlan: match.params.id,
          sourceId: sourceArray[1],
        });
      }
    }
  };

  return (
    <div className="position-relative">
      <DragDropContext onDragEnd={onDragEnd}>
        <Header productionPlan={productionPlan} />
        <div className={styles.contentWrapper}>
          <LeftToolbar
            productionPlan={productionPlan}
            refetch={refetch}
            shelfFilters={shelfFilters}
            setShelfFilters={setShelfFilters}
          />
          <div className={styles.scrollXWrapper}>
            <div className={styles.contentGroup}>
              <div className={cx(styles.cols)}>
                <DateBar days={productionPlan.days} productionPlanId={productionPlan.id} />
                <div
                  className={styles.scrollYWrapper}
                  style={{
                    height: `calc(100vh - ${storedHeight}px - ${PAGE_HEADER_HEIGHT}px)`,
                  }}
                >
                  {productionPlan.employees.map(employee => (
                    <EmployeeRow
                      key={employee.id}
                      employee={employee}
                      keyStrings={keyStrings}
                      productionPlan={productionPlan}
                      selectedSources={selectedSources}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Shelf
          setKeyStrings={setKeyStrings}
          productionPlan={productionPlan}
          selectedSources={selectedSources}
          setSelectedSources={setSelectedSources}
          shelfFilters={shelfFilters}
        />
      </DragDropContext>
    </div>
  );
};

import styles from "../../EditTradingDocument.module.css";
import cx from "classnames";
import { TradingDocument } from "api/trading-documents/models";
import { useState } from "react";
import { ClientEdit } from "./ClientEdit";
import { ClientPreview } from "./ClientPreview";
import { SellerChange } from "./SellerChange";
import { SellerPreview } from "./SellerPreview";

interface Props {
  tradingDocument: TradingDocument;
  updateOptions: any;
}

export const ClientAndSellerSection = ({ tradingDocument, updateOptions }: Props) => {
  const [isClientEditing, setIsClientEditing] = useState(false);
  const [isSellerChanging, setIsSellerChanging] = useState(false);

  return (
    <div className={cx(styles.clientAndSellerSection, "container m-0 pl-0 pr-0 pb-0 w-100")}>
      <div className="row">
        <div className="col-4">
          <div className={styles.clientAndSellerTitle}>Klient (Nabywca)</div>
          {isClientEditing ? (
            <ClientEdit
              setIsClientEditing={setIsClientEditing}
              tradingDocument={tradingDocument}
              updateOptions={updateOptions}
            />
          ) : (
            <ClientPreview
              setIsClientEditing={setIsClientEditing}
              tradingDocument={tradingDocument}
            />
          )}
        </div>
        <div className={cx(styles.emptyColumn, "col-4")} />
        <div className="col-4 pr-0">
          <div className={styles.clientAndSellerTitle}>Sprzedawca</div>
          {isSellerChanging ? (
            <SellerChange
              setIsSellerChanging={setIsSellerChanging}
              tradingDocument={tradingDocument}
              updateOptions={updateOptions}
            />
          ) : (
            <SellerPreview
              setIsSellerChanging={setIsSellerChanging}
              tradingDocument={tradingDocument}
            />
          )}
        </div>
      </div>
    </div>
  );
};

import { ErrorMessage } from "formik";
import { useField } from "formik";
import cx from "classnames";

interface Props {
  className?: string;
  name: string;
}

export const FormikErrorMessage = ({ className = "", name }: Props) => {
  const [, meta] = useField({ name });
  const error = meta.error;
  // condition is required to display some complex validation cases
  // (when there can be both error for eg. "values" and "values.element.name")
  if (typeof error === "string") {
    return (
      <div className={cx("invalid-feedback", className)}>
        <ErrorMessage name={name} />
      </div>
    );
  }
  return null;
};

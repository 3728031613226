import backupIcon from "assets/images/backup.svg";
import localStyles from "../DocumentsTraySection.module.css";
import styles from "../../../Dashboard.module.css";
import cx from "classnames";
import { Button } from "components/common";

export const UploadBox = () => {
  return (
    <div className={cx(localStyles.uploadBox, "p-3")}>
      <div>
        <img alt="Wyślij" src={backupIcon} />
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center gap-2">
        <div className={cx(styles.body14, "body-14-600")}>
          Przeciągnij i upuść plik aby go dodać lub
        </div>
        <Button className={localStyles.uploadBtn} kind="create">
          <div className="btnBase btnBaseSmall">Wybierz ze swojego urządzenia</div>
        </Button>
      </div>
    </div>
  );
};

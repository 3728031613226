import { routeActions } from "api/routes/actions";
import { Route, RouteSynchronize } from "api/routes/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useSynchronizeRouteColumns } from "pages/routes/routesList/rightPanel/synchronizeRoute/useSynchronizeRouteColumns";
import { noop } from "utilities";

interface Props {
  close: () => void;
  route: Route;
}

export const SynchronizeRouteModal = ({ close, route }: Props) => {
  const { data: orders, error, isLoading } = routeActions.useRouteSynchronizeDetails(route.id);
  const synchronizeRouteMutation = routeActions.useRouteSynchronize(close);
  const columns = useSynchronizeRouteColumns();

  return (
    <Modal close={noop} isOpen removeHeader width={700}>
      <div className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <Typography color="danger600" fontSize="18" fontWeight="700" noWrap>
            Niespójność między zamówieniami i punktami trasy
          </Typography>
          {(isLoading || synchronizeRouteMutation.isLoading) && <Spinner size={16} />}
        </div>
        {orders && (
          <div className="py-2 mb-2">
            <Table<RouteSynchronize>
              rows={orders}
              columns={columns}
              isLoading={isLoading}
              error={error}
              uiSchema={comfortableListUiSchema}
            />
          </div>
        )}
        <div className="d-flex align-items-center gap-2 pt-3 pb-1 border-top">
          {isLoading ? (
            <Spinner size={16} />
          ) : (
            <Button
              className="text-uppercase"
              onClick={() => synchronizeRouteMutation.mutate(route.id)}
              size="small"
              variant="deepPurple"
            >
              Synchronizuj
            </Button>
          )}
          {orders && (
            <Typography color="neutralBlack48" fontSize="14" fontWeight="400" noWrap>
              Kliknij, by przywrócić {orders.length > 1 ? "zamówienia" : "zamówienie"} do puli
            </Typography>
          )}
        </div>
      </div>
    </Modal>
  );
};

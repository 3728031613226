import { useState, useEffect } from "react";
import { ApiMiddlewareResult } from "apiConnectors/fetchConnector";
import { Switch } from "components/utils";
// import styles from "./EditableSwitch.module.css";
import cx from "classnames";

interface Props {
  name: string;
  submitFunc: (data: { [x: string]: boolean }) => ApiMiddlewareResult<any>;
  initialValue: boolean;
  label?: string;
}

export function EditableSwitch({ name, label = "", submitFunc, initialValue }: Props) {
  const [fetching, setFetching] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [lastSavedValue, setLastSavedValue] = useState(initialValue);

  const [error, setError] = useState<null | { [key: string]: any }>(null);

  useEffect(() => {
    if (fetching === true) {
      setError(null);
    }
  }, [fetching]);

  const handleChange = async (value: boolean) => {
    setValue(value);
    setFetching(true);
    const [, error] = await submitFunc({ [name]: value });
    if (error) {
      setError(error);
      setValue(lastSavedValue);
    } else {
      setLastSavedValue(value);
    }
    setFetching(false);
  };

  return (
    <div className={cx({ "was-validated": Boolean(error) })}>
      <Switch
        onChange={handleChange}
        name={name}
        checked={value}
        label={label}
        disabled={fetching}
      />
      <span className="invalid-feedback">{error && (error[name] || "Nie udało się zapisać")}</span>
    </div>
  );
}

import envelopeIcon from "assets/images/276.svg";
import cx from "classnames";
import { Button } from "components/common/buttonLegacy";
import styles from "../RightPanel.module.css";
import { routeActions } from "api/routes/actions";
import { useQuery } from "hooks";

export const SendEmailButton = ({ orderId }: { orderId: number }) => {
  const { query } = useQuery();
  const { panelId } = query;
  const sendEmailMutation = routeActions.useEmailSendMutation(panelId);
  return (
    <Button
      kind="secondary-stroke"
      size="btnSizeSmall"
      disabled={sendEmailMutation.isLoading}
      onClick={() => {
        sendEmailMutation.mutate({
          orderId: orderId,
          resend: false,
          deadline: null,
        });
      }}
    >
      <div className="d-flex align-items-center">
        <img alt="" src={envelopeIcon} className={cx(styles.sendButtonIcon, "mr-2")} />
        <span className={cx(styles.smsBtn, "fs-14")}>Wyślij Email</span>
      </div>
    </Button>
  );
};

import { Loading } from "api/wms/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateFns, pluralize } from "utilities";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { LoadingFinish } from "../components/LoadingFinish";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { LoadingStart } from "../components/LoadingStart";
import { parse } from "date-fns";
import { formatHours } from "pages/wms/shared/utils/formatHours";
import { formatMinutes } from "pages/wms/shared/utils/formatMinutes";
import { useDownloadLoadingPdf } from "./useDownloadLoadingPdf";

export const useLoadingsListColumns = () => {
  const handleDownloadLoadingPdf = useDownloadLoadingPdf();

  return useCreateTableColumns<Loading>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "sygnatura",
        size: 200,
        cell: info => {
          const loading = info.getValue();
          return (
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="14" fontWeight="700">
                {loading.signature}
              </Typography>
              {loading.pauseStartedAt && !loading.pauseEndedAt && (
                <Tag label="Trwa pauza" variant="warning" />
              )}
            </div>
          );
        },
      }),
      columnHelper.text(
        row => (row.sellers ? row.sellers.map(seller => seller.name).join(", ") : null),
        {
          header: "producenci",
          size: 250,
        },
      ),
      columnHelper.accessor(row => row.ramp, {
        header: "rampa",
        size: 40,
        cell: info => {
          const ramp = info.getValue();
          if (!ramp)
            return <EmptyValue className="d-flex justify-content-center w-100" fontWeight="700" />;
          return (
            <div className="d-flex justify-content-center w-100">
              <Tag label={ramp.name} variant="warning" />
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.estimatedTimeOfDeparture, {
        id: "estimatedTimeOfDeparture",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            wyjazd
          </Typography>
        ),
        size: 90,
        cell: info => {
          const date = info.getValue();
          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {!date ? "---" : `${dateFns.format(new Date(date), "H:mm")}`}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "start",
        size: 120,
        cell: info => {
          return <LoadingStart fontSize="12" fontWeight="700" loading={info.getValue()} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "zakończenie",
        size: 90,
        cell: info => {
          return <LoadingFinish fontSize="12" fontWeight="700" loading={info.getValue()} />;
        },
      }),
      columnHelper.text(
        row => {
          if (!row.duration || !Boolean(row.duration.length)) return null;

          const matchDays = row.duration.match(/^\d+\s/);

          const parsedTime = parse(
            matchDays ? row.duration.slice(matchDays[0].length) : row.duration,
            "HH:mm:ss.SSSSSS",
            new Date(),
          );

          if (!isNaN(parsedTime.getTime())) {
            if (matchDays) {
              const days = parseInt(matchDays[0], 10);
              return `${days} ${pluralize.pl(days, {
                singular: "dzień",
                plural: "dni",
                other: "dni",
              })}, ${formatHours(parsedTime)} ${formatMinutes(parsedTime)}`;
            }

            return `${formatHours(parsedTime)} ${formatMinutes(parsedTime)}`;
          } else {
            return null;
          }
        },
        {
          header: "czas trwania",
          size: 90,
        },
      ),
      columnHelper.accessor(row => row.loadingGroupsCounters, {
        header: "skompletowane zam.",
        size: 120,
        cell: info => {
          const loadingGroupsCounters = info.getValue();
          return (
            <div className="d-flex align-items-center">
              <Typography color="success500" fontSize="14" fontWeight="700">
                {Number(loadingGroupsCounters.completed)}
              </Typography>
              <Typography fontSize="14" fontWeight="700">
                /{Number(loadingGroupsCounters.total)}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "etykiety",
        size: 43,
        cell: info => {
          const loading = info.getValue();
          return (
            <div className="d-flex justify-content-center w-100">
              <IconButton
                icon={<MdiQrCode size="16" />}
                onClick={event => {
                  event.stopPropagation();
                  handleDownloadLoadingPdf(loading);
                }}
                variant="gray"
              />
            </div>
          );
        },
      }),
    ];
  });
};

import { Picking } from "api/wms/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { format, isToday, isYesterday } from "date-fns";
import { dateFns } from "utilities";

interface Props {
  fontSize: TypographyProps["fontSize"];
  fontWeight: TypographyProps["fontWeight"];
  picking: Picking;
}

export const PickingFinishDate = ({ fontSize, fontWeight, picking }: Props) => {
  if (!picking.finishedAt)
    return (
      <Typography fontSize={fontSize} fontWeight={fontWeight}>
        ---
      </Typography>
    );

  if (picking.finishedAt) {
    if (isToday(new Date(picking.finishedAt))) {
      return (
        <Typography fontSize={fontSize} fontWeight={fontWeight}>
          {format(new Date(picking.finishedAt), "H:mm")}
        </Typography>
      );
    }
    if (isYesterday(new Date(picking.finishedAt))) {
      return (
        <Typography fontSize={fontSize} fontWeight={fontWeight}>
          wczoraj, {dateFns.format(new Date(picking.finishedAt), "H:mm")}
        </Typography>
      );
    }
    return (
      <Typography fontSize={fontSize} fontWeight={fontWeight}>
        {dateFns.format(new Date(picking.finishedAt), "d.MM.yyyy")}
      </Typography>
    );
  }

  return null;
};

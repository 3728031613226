import { MdiFolderOpen } from "components/miloDesignSystem/atoms/icons/MdiFolderOpen";
import styles from "./EmptySection.module.css";
import { BaseIcon } from "components/miloDesignSystem/atoms/icons/types";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx } from "utilities";

interface Props {
  className?: string;
  icon?: React.FunctionComponent<BaseIcon> | JSX.Element;
  label: string;
}

export const EmptySection = ({ className, icon: Icon, label }: Props) => {
  return (
    <div className={cx("pl-3 pr-3", className)}>
      <div className={styles.sectionEmpty}>
        {Icon && typeof Icon === "function" ? (
          <Icon color="neutralBlack48" />
        ) : Icon ? (
          Icon
        ) : (
          <MdiFolderOpen color="neutralBlack48" />
        )}
        <Typography className={cx(styles.sectionEmptyTitle, "pt-2")} fontSize="12" fontWeight="700">
          {label}
        </Typography>
      </div>
    </div>
  );
};

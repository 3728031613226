import { Link as LinkWrapper } from "react-router-dom";
import styles from "./Link.module.css";
import { LinkProps } from "./types";
import { Typography } from "../typography";
import { noop } from "utilities";
import { PropsWithChildren } from "react";

export const Link = (props: PropsWithChildren<LinkProps>) => {
  return (
    <LinkWrapper
      className={styles.link}
      key={props.to}
      onClick={event => {
        event.stopPropagation();
        props.onClick ? props.onClick() : noop();
      }}
      to={props.to}
    >
      <Typography color="inherit" fontSize={props.fontSize} fontWeight={props.fontWeight} noWrap>
        {props.children}
      </Typography>
    </LinkWrapper>
  );
};

Link.Dark = (props: PropsWithChildren<LinkProps>) => {
  return (
    <LinkWrapper
      className={styles.linkDark}
      key={props.to}
      onClick={event => {
        event.stopPropagation();
        props.onClick ? props.onClick() : noop();
      }}
      to={props.to}
    >
      <Typography color="inherit" fontSize={props.fontSize} fontWeight={props.fontWeight} noWrap>
        {props.children}
      </Typography>
    </LinkWrapper>
  );
};

import { FilterDateRangeWithOptionsType } from "components/common/filters/types";
import { useQuery } from "hooks";
import v4 from "cuid";
import { Chip } from "../../shared/shared";
import { formatDate } from "../../shared/utils/formatDate";

export const Options = ({
  name,
  options,
}: Pick<FilterDateRangeWithOptionsType, "name" | "options">) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const [startDateName, endDateName] = name;

  const optionsToDisplay = options.map((option, index) => {
    return {
      checked: false,
      key: v4(),
      label: option.label,
      name: option.label,
      position: index,
      value: [option.value[0], option.value[1]],
    };
  });

  const handleChange = (checked: boolean, queryStartDate: string, queryEndDate: string) => {
    if (queryStartDate && queryEndDate && !checked) {
      updateQuery({
        [startDateName]: formatDate(queryStartDate),
        [endDateName]: formatDate(queryEndDate),
        page: 1,
      });
      return;
    }
    updateQuery({
      [startDateName]: "",
      [endDateName]: "",
      page: 1,
    });
  };

  return (
    <div className="mt-3">
      {optionsToDisplay.map(({ label, name, value: [queryStartDate, queryEndDate], key }) => {
        const checked =
          query[startDateName] === queryStartDate && query[endDateName] === queryEndDate;

        return (
          <Chip<string[]>
            key={key}
            checked={checked}
            handleChange={() => handleChange(checked, queryStartDate, queryEndDate)}
            label={label}
            name={name}
            value={[queryStartDate, queryEndDate]}
          />
        );
      })}
    </div>
  );
};

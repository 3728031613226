import { useRef, useState } from "react";
import * as React from "react";
import {
  Label,
  Modal,
  Select,
  Spinner,
  StatusHandler,
  StatusHandlerHelpers,
  Switch,
} from "components/utils";
import { Button } from "components/common";
import styles from "./CustomSMSModal.module.css";
import { smsMessageRouteTags } from "CONSTANTS";
import { queryString } from "utilities";
import { useSmsMessages } from "api/messages/hooks";
import { ConfirmationDateOptions, DeadlineDayOptions } from "api/milo-settings/models";
import cx from "classnames";
import { PostDeadline } from "api/routes/models";
import { AddCustomOption } from "pages/routes/routesList/rightPanel/pointsSection/confirmationDateModal/AddCustomOption";
import { getIndicativeDate } from "pages/routes/routesList/rightPanel/pointsSection/confirmationDateModal/utils";

interface Props {
  isOpen: boolean;
  close: () => void;
  kind: string | null;
  onSubmit: (
    message: string | null,
    helpers: StatusHandlerHelpers,
    force?: { resendSms: boolean; sendSms: boolean; resendUnconfirmed: boolean },
    confirmationDeadline?: PostDeadline | null,
  ) => void;
  options: ConfirmationDateOptions[];
}

const dayOptions: Record<DeadlineDayOptions, string> = {
  TODAY: "Dzisiaj",
  TOMORROW: "Jutro",
  AFTER_TOMORROW: "Pojutrze",
  NEAREST_MONDAY: "Następny poniedziałek",
  NEAREST_FRIDAY: "Następny piątek",
};

export const CustomSMSModal = ({ isOpen, close, kind, onSubmit, options }: Props) => {
  const [value, setValue] = useState("");
  const [resendUnconfirmed, setResendUnconfirmed] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const search = queryString.stringify({
    kind: kind || "",
  });

  const [allOptions, setAllOptions] = useState(options);
  const [dayOption, setDayOption] = useState("");
  const [isCustomOptionCreatorOpen, setIsCustomOptionCreatorOpen] = useState(false);
  const [picked, setPicked] = useState<ConfirmationDateOptions | null>(null);
  const [time, setTime] = useState("");

  const [smsMessages, { inProgress }] = useSmsMessages(search, {
    skip: !kind,
    clearSkip: true,
  });

  const spinnerStyle: React.CSSProperties = { position: "absolute", left: -19, top: -15 };

  return (
    <Modal
      isOpen={isOpen}
      close={() => {
        close();
        setValue("");
      }}
    >
      <div>
        <div>
          <h5 className="pb-3">Utwórz wiadomość sms</h5>
        </div>
        <div className="position-relative inline-flex">
          <Spinner on={inProgress} color="blue" style={spinnerStyle} />
          <Select
            size="small"
            onChange={item => {
              setValue(item?.body || "");
              textAreaRef.current?.focus();
            }}
            selectedItem={null}
            itemToDisplaySelected={() => <span className="text-color-grey">Wybierz wiadomość</span>}
            width="auto"
            items={smsMessages}
          />
        </div>
        <div className={styles.textareaWrapper}>
          <label className="w-100">
            <textarea
              className={styles.textarea}
              onChange={e => setValue(e.target.value)}
              value={value}
              placeholder="Wpisz treść SMS-a"
              ref={textAreaRef}
            />
            <span className={styles.label}>Wiadomość</span>
          </label>
        </div>
        {kind === "ROUTE" && (
          <>
            <div className="text-color-grey mb-2"> W treści możesz użyć następujących tagów:</div>
            <div className={styles.tagsContainer}>
              {smsMessageRouteTags.map(({ name, value }, index) => (
                <div
                  className={styles.tag}
                  key={index}
                  onClick={() => setValue(prev => prev + ` ${name}`)}
                >
                  <Label color="custom" className={styles.tagLabel}>
                    {name}
                  </Label>
                  - <span className={styles.tagValue}>{value}</span>
                </div>
              ))}
            </div>
            <div className="mt-3">
              <Switch
                onChange={val => setResendUnconfirmed(val)}
                name=""
                overrides={{ label: { className: "fs-12" } }}
                checked={resendUnconfirmed}
                label="Wyślij tylko do niepotwierdzonych zamówień"
                color="blue"
              />
            </div>
            <div className="mb-2 mt-3">Do kiedy możliwe będzie potwierdzenie zamówienia?</div>

            <div className="text-color-grey mb-3">Wybierz jedną z opcji lub dodaj własną:</div>

            <div className={cx(styles.optionBox, "mb-4")}>
              {allOptions &&
                allOptions.length > 0 &&
                allOptions?.map(option => {
                  return (
                    <div
                      className={cx(
                        styles.option,
                        "d-flex align-items-center justify-content-center mb-2",
                        {
                          [styles.optionActive]: option.id === picked?.id,
                        },
                      )}
                      key={option.id}
                      onClick={() => {
                        if (option.id === picked?.id) {
                          setPicked(null);
                        } else {
                          setPicked(option);
                        }
                      }}
                    >
                      <span>
                        {dayOptions[option.deadlineDayOption]}
                        <span className="text-color-grey fs-14">
                          &nbsp;({getIndicativeDate(option.deadlineDayOption)})
                        </span>
                      </span>
                      <i className={styles.divider}></i>
                      <span>{option.deadlineTime.slice(0, 5)}</span>
                    </div>
                  );
                })}
            </div>

            <AddCustomOption
              allOptions={allOptions}
              dayOption={dayOption}
              dayOptions={dayOptions}
              isOpen={isCustomOptionCreatorOpen}
              setAllOptions={setAllOptions}
              setDayOption={setDayOption}
              setIsOpen={setIsCustomOptionCreatorOpen}
              setPicked={setPicked}
              setTime={setTime}
              time={time}
            />
          </>
        )}
        <div className="d-flex align-items-center justify-content-between pt-4">
          <StatusHandler>
            {helpers => (
              <Button
                kind="secondary-grey"
                disabled={helpers.isFetching}
                onClick={() => {
                  onSubmit(value, helpers, { sendSms: false, resendSms: false, resendUnconfirmed });
                }}
              >
                Pomiń datę potwierdzenia i wyślij SMS
              </Button>
            )}
          </StatusHandler>
          <StatusHandler>
            {helpers => (
              <Button
                kind="primary"
                disabled={helpers.isFetching || picked === null}
                onClick={() => {
                  if (picked) {
                    onSubmit(
                      value,
                      helpers,
                      { sendSms: false, resendSms: false, resendUnconfirmed },
                      { option: picked.deadlineDayOption, time: picked.deadlineTime.slice(0, 5) },
                    );
                  }
                }}
              >
                Wyślij SMS z datą potwierdzenia
              </Button>
            )}
          </StatusHandler>
        </div>
      </div>
    </Modal>
  );
};

import { shippingActions } from "api/shipping/actions";
import { Shipment } from "api/shipping/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../RightPanel.module.css";
import { AsyncInput } from "components/utils";
import { Select } from "components/miloDesignSystem/molecules/select";
import { CountryCode, countryCodes } from "CONSTANTS";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";

interface Props {
  shipment: Shipment;
}

export const PickupSection = ({ shipment }: Props) => {
  const companyNameMutation = shippingActions.usePatchShipping();
  const firstNameMutation = shippingActions.usePatchShipping();
  const lastNameMutation = shippingActions.usePatchShipping();
  const streetMutation = shippingActions.usePatchShipping();
  const postCodeMutation = shippingActions.usePatchShipping();
  const cityMutation = shippingActions.usePatchShipping();
  const countryCodeMutation = shippingActions.usePatchShipping();
  const phoneMutation = shippingActions.usePatchShipping();
  const emailMutation = shippingActions.usePatchShipping();

  return (
    <div className="px-3  pb-2 flex-1">
      <Typography fontSize="14" fontWeight="600" color="neutralBlack48" className="mb-2">
        Adres odbioru przesyłki przez kuriera
      </Typography>
      <AsyncInput
        disabled={companyNameMutation.isLoading}
        inProgress={companyNameMutation.isLoading}
        onChange={value =>
          companyNameMutation.mutate({
            id: shipment.id,
            toUpdate: { pickupCompanyName: value },
          })
        }
        overwrites={asyncInputOverrides}
        placeholder="Nazwa firmy"
        label="Nazwa firmy"
        value={shipment.pickupCompanyName}
      />

      <AsyncInput
        disabled={firstNameMutation.isLoading}
        inProgress={firstNameMutation.isLoading}
        onChange={value =>
          firstNameMutation.mutate({
            id: shipment.id,
            toUpdate: { pickupFirstName: value },
          })
        }
        overwrites={asyncInputOverrides}
        placeholder="Imię"
        label="Imię"
        value={shipment.pickupFirstName}
      />
      <AsyncInput
        disabled={lastNameMutation.isLoading}
        inProgress={lastNameMutation.isLoading}
        onChange={value =>
          lastNameMutation.mutate({
            id: shipment.id,
            toUpdate: { pickupLastName: value },
          })
        }
        overwrites={asyncInputOverrides}
        placeholder="Nazwisko"
        label="Nazwisko"
        value={shipment.pickupLastName}
      />
      <AsyncInput
        disabled={streetMutation.isLoading}
        inProgress={streetMutation.isLoading}
        onChange={value =>
          streetMutation.mutate({
            id: shipment.id,
            toUpdate: { pickupStreet: value },
          })
        }
        overwrites={asyncInputOverrides}
        placeholder="Adres (ulica)"
        label="Adres"
        value={shipment.pickupStreet}
      />
      <div className="d-flex ">
        <AsyncInput
          disabled={postCodeMutation.isLoading}
          inProgress={postCodeMutation.isLoading}
          onChange={value =>
            postCodeMutation.mutate({
              id: shipment.id,
              toUpdate: { pickupPostCode: value },
            })
          }
          overwrites={{
            container: { className: styles.postCodeContainer },
            input: { className: styles.input },
            floatingLabel: { className: styles.floatingLabel },
          }}
          placeholder="Kod pocztowy"
          label="Kod pocztowy"
          value={shipment.pickupPostCode}
        />
        <AsyncInput
          disabled={cityMutation.isLoading}
          inProgress={cityMutation.isLoading}
          onChange={value =>
            cityMutation.mutate({
              id: shipment.id,
              toUpdate: { pickupCity: value },
            })
          }
          overwrites={asyncInputOverrides}
          placeholder="Miasto"
          label="Miasto"
          value={shipment.pickupCity}
        />
      </div>
      <Select
        className="mb-3"
        label="Kod kraju"
        items={countryCodes.map(countryCode => ({
          text: countryCode.value,
          value: countryCode.value,
          type: MenuItemType.TEXT,
        }))}
        onChange={countryCode => {
          countryCodeMutation.mutate({
            id: shipment.id,
            toUpdate: { pickupCountryCode: countryCode as CountryCode },
          });
        }}
        selected={shipment.pickupCountryCode}
      />
      <AsyncInput
        disabled={phoneMutation.isLoading}
        inProgress={phoneMutation.isLoading}
        onChange={value =>
          phoneMutation.mutate({
            id: shipment.id,
            toUpdate: { pickupPhone: value },
          })
        }
        overwrites={asyncInputOverrides}
        placeholder="Telefon"
        label="Telefon"
        value={shipment.pickupPhone}
      />
      <AsyncInput
        disabled={emailMutation.isLoading}
        inProgress={emailMutation.isLoading}
        onChange={value =>
          emailMutation.mutate({
            id: shipment.id,
            toUpdate: { pickupEmail: value },
          })
        }
        overwrites={asyncInputOverrides}
        placeholder="Email"
        label="Email"
        value={shipment.pickupEmail}
      />
    </div>
  );
};

const asyncInputOverrides = {
  container: { className: styles.inputContainer },
  input: { className: styles.input },
  floatingLabel: { className: styles.floatingLabel },
};

import React from "react";
import styles from "./Popover.module.css";
import cx from "classnames";
import {
  usePopoverState,
  Popover as BasePopover,
  PopoverDisclosure,
  PopoverStateReturn,
} from "reakit/Popover";

type Variant = "LIGHT" | "DARK";

/**
 * Component for displaying tooltip to the user
 *
 * @example
 *  <Tooltip title="Więcej informacji">
 *    <IconButton icon="moreInfo"/> (any component)
 *  </Tooltip>
 *
 * @example
 *  <Tooltip title="Sygnatura zamówienia">
 *     <Typography>{order.signature}</Typography>
 *  </Tooltip>
 */
export const Popover = ({
  content,
  children,
  variant = "LIGHT",
  hideOnClickOutside = true,
}: {
  content: (args: PopoverStateReturn) => React.ReactNode;
  children: JSX.Element;
  variant?: Variant;
  hideOnClickOutside?: boolean;
}) => {
  const popover = usePopoverState();

  return (
    <>
      <PopoverDisclosure {...popover} {...children.props}>
        {disclosureProps => {
          return React.cloneElement(children, disclosureProps);
        }}
      </PopoverDisclosure>
      <BasePopover
        hideOnClickOutside={hideOnClickOutside}
        {...popover}
        className={cx(
          styles.popover,
          {
            [styles.light]: variant === "LIGHT",
            [styles.dark]: variant === "DARK",
          },
          "root-portal",
        )}
      >
        {content(popover)}
      </BasePopover>
    </>
  );
};

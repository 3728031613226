import { postPrintReceiptsForRoutes } from "api/routes/calls";
import { useToastr } from "hooks";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey, pluralize } from "utilities";

export const usePrintReceipts = () => {
  const toastr = useToastr();

  const printReceiptsMutation = useMutation(postPrintReceiptsForRoutes, {
    onSuccess: (_, variables) => {
      const receiptsNumber = variables.routes.length;
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: `Zlecono drukowanie ${receiptsNumber} ${pluralize.pl(receiptsNumber, {
          singular: "paragonu",
          plural: "paragonów",
          other: "paragonów",
        })}`,
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  });

  return printReceiptsMutation;
};

import { pluralize, yup } from "utilities";

const requiredMsg = (field: string): string => {
  return `Pole ${field} jest wymagane`;
};

const maxLengthMsg = (field: string, chars: number): string => {
  return `Pole ${field} powinno zawierać maksymalnie ${chars} ${pluralize.pl(chars, {
    singular: "znak",
    plural: "znaki",
    other: "znaków",
  })}`;
};

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required(requiredMsg("E-mail"))
    .email("Niepoprawny adres e-mail"),
  name: yup
    .string()
    .required(requiredMsg("Nazwa"))
    .max(100, maxLengthMsg("Nazwa", 100)),
  companyName: yup
    .string()
    .required(requiredMsg("Nazwa firmy"))
    .max(350, maxLengthMsg("Nazwa firmy", 350)),
  city: yup
    .string()
    .required(requiredMsg("Miasto"))
    .max(50, maxLengthMsg("Miasto", 50)),
  street: yup
    .string()
    .required(requiredMsg("Ulica"))
    .max(150, maxLengthMsg("Ulica", 150)),
  postCode: yup
    .string()
    .required(requiredMsg("Kod pocztowy"))
    .max(10, maxLengthMsg("Kod pocztowy", 10)),
  taxId: yup
    .string()
    .required(requiredMsg("NIP"))
    .max(20, maxLengthMsg("NIP", 20)),
  phone: yup
    .string()
    .min(9, "Wprowadź poprawny numer telefonu")
    .matches(/^\+?[0-9]+$/, "Numer telefonu powinien zawierać jedynie cyfry")
    .required("Numer telefonu jest wymagany"),
});

import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { RightPanelHeader } from "components/utils/drawer";
import { cx, dateFns, getAnyErrorKey } from "utilities";
import { WarehouseWorkerDetails } from "api/wms/models";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { MdiExpandView } from "components/miloDesignSystem/atoms/icons/MdiExpandView";
import { useToastr, useToggle } from "hooks";
import { DailySummaryReceivedAndReleased } from "../dailySummaryReceivedAndReleased/DailySummaryReceivedAndReleased";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import styles from "./PanelHeader.module.css";
import { DatePicker } from "components/utils/datePicker";
import { useState } from "react";
import { ClickOutsideHandler, StatusHandler, StatusHandlerHelpers } from "components/utils";
import { MdiDownloadCsv } from "components/miloDesignSystem/atoms/icons/MdiDownloadCsv";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";
import { wmsFileFactory } from "api/wms/calls";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
  warehouseWorker: WarehouseWorkerDetails;
}

const initialDateFrom = dateFns.format(dateFns.subMonths(new Date(), 1), "yyyy-MM-dd");
const initialDateTo = dateFns.format(new Date(), "yyyy-MM-dd");

export const PanelHeader = ({ close, isScrolledOver, warehouseWorker }: Props) => {
  const modalController = useToggle();
  const [dateFrom, setDateFrom] = useState(initialDateFrom);
  const [dateTo, setDateTo] = useState(initialDateTo);

  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const toastr = useToastr();

  async function handleDownloadCsv(helpers: StatusHandlerHelpers) {
    helpers.startFetching();
    const tstr = downloadFeedbackToastr.open({ type: "csv", calculateProgress: false });
    const { url, name } = wmsFileFactory.releasedAndReceivedCsv(warehouseWorker, dateFrom, dateTo);
    const response = await fileDownloader({
      url,
      name,
      type: "csv",
    });

    if (response.status === "success") {
      tstr.lazyClose();
    } else if (response.error) {
      tstr.lazyClose();
      toastr.open({
        type: response.httpStatus === 400 ? "warning" : "failure",
        title: response.httpStatus === 400 ? "Wymagane działanie" : "Oj, coś nie tak...",
        text: getAnyErrorKey(response.error),
      });
    }
    helpers.stopFetching();
  }

  return (
    <>
      <RightPanelHeader
        className={cx({
          slidingPanelHeaderShadow: isScrolledOver,
        })}
      >
        {isScrolledOver && (
          <div className="slidingPanelHeader">
            <div className="slidingPanelHeaderContent nowrap">
              {warehouseWorker.firstName} {warehouseWorker.lastName}
            </div>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <Button size="medium" variant="gray" onClick={modalController.open}>
            <div className="d-flex align-items-center gap-2">
              <Typography className="text-uppercase" fontSize="10" fontWeight="700">
                Zestawienie dzienne
              </Typography>{" "}
              <MdiExpandView size="16" />
            </div>
          </Button>
          <Popover
            hideOnClickOutside={false}
            variant="DARK"
            content={({ hide }) => (
              <ClickOutsideHandler
                onClickOutside={() => {
                  hide();
                  setDateFrom(initialDateFrom);
                  setDateTo(initialDateTo);
                }}
                outsideClickIgnoreClass={ignoreClickOutsideClassName}
              >
                <div>
                  <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
                    Wskaż zakres dat:
                  </Typography>
                  <div className="d-flex">
                    <div className="position-relative mr-1">
                      <span className={styles.label}>Od:</span>
                      <DatePicker
                        calendarClassName={ignoreClickOutsideClassName}
                        overwrites={{
                          container: { className: ignoreClickOutsideClassName },
                          popup: { className: ignoreClickOutsideClassName },
                        }}
                        placeholder="Wybierz"
                        className={styles.date}
                        value={dateFrom}
                        selectsStart
                        removeDate={false}
                        tabIndex={-1}
                        startDate={dateFrom ? new Date(dateFrom) : null}
                        endDate={dateFrom ? new Date(dateFrom) : null}
                        maxDate={dateTo ? new Date(dateTo) : null}
                        onChange={date => {
                          if (!date) return;
                          setDateFrom(dateFns.format(date, "yyyy-MM-dd"));
                        }}
                      />
                    </div>
                    <div className="position-relative">
                      <span className={styles.label}>Do:</span>
                      <DatePicker
                        placeholder="Wybierz"
                        overwrites={{
                          container: { className: ignoreClickOutsideClassName },
                          popup: { className: ignoreClickOutsideClassName },
                        }}
                        calendarClassName={ignoreClickOutsideClassName}
                        tabIndex={-1}
                        className={styles.date}
                        removeDate={false}
                        value={dateTo}
                        selectsEnd
                        startDate={dateFrom ? new Date(dateFrom) : null}
                        endDate={dateTo ? new Date(dateTo) : null}
                        minDate={dateFrom ? new Date(dateFrom) : null}
                        onChange={date => {
                          if (!date) return;
                          setDateTo(dateFns.format(date, "yyyy-MM-dd"));
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-1 justify-content-end mt-3">
                    <StatusHandler>
                      {helpers => (
                        <Button
                          tabIndex={2}
                          size="small"
                          variant="deepPurple"
                          onClick={() => handleDownloadCsv(helpers)}
                          isLoading={helpers.isFetching}
                        >
                          Pobierz CSV
                        </Button>
                      )}
                    </StatusHandler>
                  </div>
                </div>
              </ClickOutsideHandler>
            )}
          >
            <IconButton icon={MdiDownloadCsv} variant="transparent" />
          </Popover>
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      {modalController.isOpen && (
        <DailySummaryReceivedAndReleased
          close={modalController.close}
          warehouseWorker={warehouseWorker}
        />
      )}
    </>
  );
};

const ignoreClickOutsideClassName = "warehouseman-popover-ignore-class-name";

import { Button, Header as CommonHeader } from "components/common";
import binIcon from "assets/images/81.svg";
import { memo, useCallback, useRef } from "react";
import { AutocompleteAsyncHandler, Autocomplete, CommonError } from "components/utils";
import { getOrdersBankTransactions } from "api/orders/calls";
import { OrderBankTransactionsSearch } from "api/orders/models";
import { Spinner } from "components/utils";
import { useBankAccountTransaction } from "api/bank-accounts/hooks";
import styles from "./BankTransactionDetails.module.css";
import cx from "classnames";
import { useToastr } from "hooks";
import { dateFns, getAnyErrorKey } from "utilities";
import { useMutation } from "hooks/useMutation";
import { deleteBankTransactionOrder, postBankTransactionOrder } from "api/bank-accounts/calls";
import { useHistory, useParams } from "react-router";
import { addedBySourceToLabelDict, balanceToLabelDict, kindToLabelDict } from "../shared";
import { UserAvatar } from "components/utils/userAvatar";
import { BankTransactionDetails as BankTransactionDetailsSchema } from "api/bank-accounts/models";
import { assertIsDefined } from "utilities/assertIsDefined";
import { highlightTextFragment } from "utilities/highlightTextFragment";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import { MonthlySummary } from "./monthlySummary/MonthlySummary";
import { OrdersWithoutDocuments } from "./ordersWithoutDocuments/OrdersWithoutDocuments";

interface Params {
  id: string;
}

const useBankAccountTransactionHook = () => {
  const { id } = useParams<Params>();
  const hookData = useBankAccountTransaction(id);

  const renderAmount = useCallback(
    (amount: number, numberClassName?: string, decimalClassName?: string) => {
      const [number, decimal] = amount.toFixed(2).split(".");
      return (
        <div>
          <span className={cx("text-color-darkgrey", numberClassName)}>{number}</span>
          <span className={cx("fs-12 text-color-grey", decimalClassName)}>
            .{decimal} {hookData.data?.currency}
          </span>
        </div>
      );
    },
    [hookData.data?.currency],
  );

  return { ...hookData, renderAmount };
};

export const BankTransactionDetails = () => {
  const {
    data: transaction,
    isLoading,
    error,
    renderAmount,
    isFetching,
    isFetchedAfterMount,
  } = useBankAccountTransactionHook();

  if (error) {
    return (
      <div className="h-100">
        <Header />
        <CommonError status={error._httpStatus_} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="h-100">
        <Header />
        <div className="d-flex align-items-center justify-content-center flex-1 h-100">
          <Spinner color="blue" size="big" text="trwa wczytywanie" position="absolute" />
        </div>
      </div>
    );
  }

  if (!transaction) {
    return null;
  }

  return (
    <div className="position-relative d-flex flex-column h-100 overflow-auto">
      <Header />
      <div className="p-3 d-flex flex-column flex-1">
        <div className="mb-3">
          <div className="d-flex align-items-center mb-2">
            <div className="fs-18 text-color-black">{transaction.name || "Brak tytułu"}</div>
            <i className="divider mx-2"></i>
            <span>
              <span className="fs-12 text-color-grey italic mr-1">data przelewu:</span>
              {transaction.bookingDate &&
                dateFns.format(new Date(transaction.bookingDate), "dd.MM.yyyy")}
            </span>

            <i className="divider mx-2"></i>
            {kindToLabelDict()[transaction.kind]}
            {balanceToLabelDict()[transaction.summary.balance]}
          </div>
          <span className={cx(styles.bankAccount, "d-flex align-items-center mb-1")}>
            <img className="mr-2" src={transaction.bankAccount.logo} alt="logo" />
            <span className="fs-15 text-color-black">{transaction.bankAccount.name}</span>
            <i className="divider"></i>
            <span className="fs-15 text-color-black">{transaction.bankAccount.iban}</span>
          </span>
          <span className="fs-15 text-color-black">{transaction.name}</span>
        </div>
        <div className={styles.section}>
          <div className={cx(styles.row, styles.header)}>
            <div>l.p.</div>
            <div>zamówienia</div>
            <div className="d-flex justify-content-end">produkty</div>
            <div className="d-flex justify-content-end">transport</div>
            <div className="d-flex justify-content-end">suma</div>
            <div></div>
          </div>
          <div className={styles.container}>
            {isFetching && isFetchedAfterMount && (
              <div className="d-flex justify-content-center flex-1 my-4">
                <Spinner color="blue" size="big" />
              </div>
            )}

            {transaction.orders.map((order, index) => (
              <Order index={index} key={order.id} order={order} />
            ))}
          </div>
          <SearchBar />
          <div className={styles.summary}>
            <div>
              <div className={styles.summaryPrice}>
                <span>Kwota przelewu</span>
                <span> {renderAmount(Number(transaction.amount), "fs-20", "fs-14")}</span>
              </div>
              <div
                className={cx(styles.summaryAmount, {
                  [styles.balancedSummary]: Number(transaction.summary.difference) === 0,
                  [styles.balancedSummaryOver]: Number(transaction.summary.difference) > 0,
                })}
              >
                <span className="fs-18 text-color-white">Bilans</span>
                <span className="d-flex align-items-center">
                  <span className={cx("fs-34", styles.totalAmountNumber)}>
                    {Number(transaction.summary.difference) > 0 && "+"}
                    {Number(transaction.summary.difference) === 0 && "="}
                  </span>
                  <span>
                    {renderAmount(
                      Number(transaction.summary.difference),
                      cx("fs-34", styles.totalAmountNumber),
                      cx("fs-14", styles.totalAmountDecimal),
                    )}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <MonthlySummary summaryByMonth={transaction.summaryByMonth} />
          <OrdersWithoutDocuments summaryByMonth={transaction.summaryByMonth} />
        </div>
      </div>
    </div>
  );
};

const Order = ({
  order,
  index,
}: {
  order: BankTransactionDetailsSchema["orders"][number];
  index: number;
}) => {
  const { renderAmount, refetch } = useBankAccountTransactionHook();
  const history = useHistory();
  const toastr = useToastr();

  const deleteOrder = useMutation(deleteBankTransactionOrder, {
    onSuccess: () => refetch(),
    onError: error => {
      toastr.open({
        type: "failure",
        title: "Oj, coś nie tak...",
        text: getAnyErrorKey(error),
      });
    },
  });

  return (
    <div className={cx(styles.row, { [styles.stripes]: order.isReturn })}>
      <div>{index + 1}</div>
      <div>
        <div>
          <div className="d-flex align-items-center mb-1">
            <div
              className="fs-16 orderRedirectSignature mr-2"
              onClick={() => history.push(getOrderRedirectUrl(order))}
            >
              {order.signature}
            </div>
            {addedBySourceToLabelDict()[order.addedBySource]}
            {order.paymentDate && (
              <>
                <i className="divider mx-2"></i>
                <span>
                  <span className="fs-12 text-color-grey italic mr-1">data płatności:</span>
                  {dateFns.format(new Date(order.paymentDate), "dd.MM.yyyy")}
                </span>
              </>
            )}
          </div>
          <span className={cx(styles.bankAccountDetails, "d-flex align-items-center mb-1")}>
            <span className="fs-15 text-color-black">
              {order.client.firstName} {order.client.lastName}
            </span>
            <i className="divider mx-2"></i>
            <span className={cx("fs-15", styles.address)}>
              {order.client.address.street} {order.client.address.city}{" "}
              {order.client.address.postCode}
            </span>

            {order.client.phone && (
              <>
                <i className="divider mx-2"></i>
                <span className="fs-15 text-color-grey">tel. </span>
                <span className="fs-15 text-color-black ml-1">{order.client.phone}</span>
              </>
            )}
          </span>
          <div className="d-flex align-items-center">
            <span className="fs-15 text-color-grey mr-1">dodano: </span>
            <span className="fs-15 text-color-grey">
              {dateFns.format(new Date(order.created), "dd.MM.yyyy")}
            </span>
            {order.createdBy && (
              <>
                <i className="divider mx-2"></i>
                <div className="fs-15 text-color-grey d-flex align-items-center">
                  <span className="mr-2">
                    <UserAvatar
                      id={order.createdBy.id}
                      avatarSrc={order.createdBy.avatar}
                      className="m-0"
                    />
                  </span>
                  <span className="mr-1">{order.createdBy.firstName}</span>
                  {order.createdBy.lastName}
                </div>
              </>
            )}
          </div>
          <div className="d-flex align-items-center fs-14 text-color-darkgrey">
            {order.receipt.externalId.length > 0 && (
              <div>
                <span>dokument handlowy: &nbsp;{order.receipt.externalId}</span>
                <i className={styles.separator}></i>
              </div>
            )}
            {order.receipt.issued && (
              <div>
                <span>
                  data wystawienia: &nbsp;
                  {dateFns.format(new Date(order.receipt.issued), "dd.MM.yyyy")}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end">{renderAmount(order.amounts.items)}</div>
      <div className="d-flex justify-content-end">{renderAmount(order.amounts.delivery)}</div>
      <div className="d-flex justify-content-end">
        {renderAmount(order.amounts.total, "fs-20", "fs-14")}
      </div>
      <div>
        <Button
          kind="secondary"
          size="round-s"
          title="Usuń zamówienie"
          onClick={() => deleteOrder.mutate(order.relationId)}
        >
          <img alt="" src={binIcon} />
        </Button>
      </div>
    </div>
  );
};

const Header = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useBankAccountTransaction(id);
  return (
    <div className={styles.headerWrapper}>
      <CommonHeader
        title={({ styles: s }) => (
          <div className="">
            <h1 className={s.title}>Szczegóły transakcji</h1>
            <div className="text-color-purple">{data?.externalId}</div>
          </div>
        )}
        routeRoot="bank-accounts"
      />
    </div>
  );
};

export const SearchBar = memo(() => {
  const { data: transaction, refetch } = useBankAccountTransactionHook();

  assertIsDefined(transaction);
  const inputRef = useRef<HTMLInputElement | undefined>();

  const toastr = useToastr();

  const addOrderMutation = useMutation(postBankTransactionOrder, {
    onSuccess: () => refetch(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Oj, coś nie tak...",
        text: getAnyErrorKey(error),
      });
    },
  });

  const isOrderAdded = (transactionsExternalIds: string[]) => {
    return transactionsExternalIds.some(el => el === transaction.externalId);
  };

  return (
    <div className={styles.searchBarContainer}>
      <AutocompleteAsyncHandler
        debounce={600}
        fetchFrom={getOrdersBankTransactions}
        transform={(order: OrderBankTransactionsSearch) => {
          const highlightedSignature = highlightTextFragment(
            inputRef.current?.value,
            order.signature,
            order.id,
          );

          const highlightedFullName = highlightTextFragment(
            inputRef.current?.value,
            `${order.client.firstName} ${order.client.lastName}`,
            order.id,
          );

          const highlightedAddress = highlightTextFragment(
            inputRef.current?.value,
            `${order.client.street} ${order.client.city} ${order.client.postCode}`,
            order.id,
          );

          const highlightedPhone = highlightTextFragment(
            inputRef.current?.value,
            order.client.phone,
            order.id,
          );

          const isAdded = isOrderAdded(order.transactionsExternalIds);

          return {
            name: (
              <div
                className={cx("d-flex align-items-center justify-content-between py-2 px-3", {
                  [styles.isOrderAdded]: isAdded,
                })}
              >
                <div>
                  <div>{highlightedSignature}</div>
                  <div>
                    <span className="text-color-black fs-12 mr-1">{highlightedFullName}</span>
                    <span className="text-color-grey fs-12 mr-1">{highlightedAddress}</span>
                  </div>
                  <div>
                    <span className="text-color-grey fs-12 mr-1">tel.</span>
                    <span className="fs-12 text-color-black">{highlightedPhone}</span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  {order.totalAmount} {order.currency}
                </div>
              </div>
            ),
            ...order,
          };
        }}
      >
        <Autocomplete
          orientation={transaction.orders.length > 2 ? "top" : "bottom"}
          selectedItems={[]}
          placeholder="Szukaj..."
          inputRef={ref => (inputRef.current = ref)}
          overrides={{
            input: { className: styles.searchInput },
            list: { className: styles.searchBarList },
            item: { className: styles.item },
          }}
          onChange={(val: any) => {
            if (val[0] && !isOrderAdded(val[0].transactionsExternalIds)) {
              const order = val[0] as OrderBankTransactionsSearch;
              addOrderMutation.mutate({
                bankTransaction: Number(transaction!.id),
                order: order.id,
                addedBySource: "MANUALLY",
                amount: order.totalAmount,
                currency: order.currency,
              });
            }
          }}
          multiple={false}
        />
      </AutocompleteAsyncHandler>
    </div>
  );
});

import { useState, useMemo } from "react";
import cx from "classnames";
import styles from "./FiltersDrawer.module.css";
import { DrawerSection } from "components/utils/drawer/Drawer";
import { RadioGroup } from "components/utils";
import { ToolbarSearchFilter } from "../toolbar/types";

export const SearchFilter = ({
  filter,
  query,
  onRadioChange,
}: {
  filter: ToolbarSearchFilter;
  query: Record<string, string>;
  onRadioChange: (item: { id: number | string; name: string } | null, name: string) => void;
}) => {
  const [inputValue, setInputValue] = useState("");
  const itemId = query[filter.name];
  const filteredItems = useMemo(
    () =>
      filter.options.filter(element => {
        return element.value === Number(itemId)
          ? element
          : element.label.toLowerCase().includes(inputValue.toLowerCase());
      }),
    [itemId, inputValue, filter],
  );
  return (
    <DrawerSection title={filter.label}>
      <input
        type="search"
        value={inputValue}
        className={cx(styles.searchInput, "mb-1")}
        onChange={e => setInputValue(e.target.value)}
        placeholder="Szukaj"
      />
      <div className={styles.customSection}>
        {filteredItems.length ? (
          <RadioGroup
            className="d-flex flex-column mb-3"
            name={filter.name}
            items={filteredItems.map(el => ({ id: String(el.value), name: el.label }))}
            onChange={onRadioChange}
            value={query[filter.name] ?? ""}
          />
        ) : (
          "Brak wyszukań"
        )}
      </div>
    </DrawerSection>
  );
};

import { postManufacturingStageEmployee } from "api/manufacturingNew/calls";
import { useManufacturingStage } from "api/manufacturingNew/hooks";
import { ManufacturingStage } from "api/manufacturingNew/models";
import { useSelector } from "hooks";
import { useMutation } from "hooks/useMutation";
import { useState } from "react";
import { getAnyErrorKey } from "utilities";

type EmployeeValue = {
  firstName: string;
  lastName: string;
  name: string;
  id: number;
  filters: { name: string };
};

export const useDefaultEmployeeForm = (
  close: () => void,
  manufacturingStage: ManufacturingStage,
) => {
  const employees = useSelector(store => store.partials.employees);
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeValue | null>(null);
  const { handleMutate } = useManufacturingStage(manufacturingStage.id, {
    enabled: false,
  });

  const addDefaultEmployeeMutation = useMutation(postManufacturingStageEmployee, ({ toastr }) => ({
    onSuccess: (payload, variables) => {
      const correspondingEmployee = employees.find(employee => employee.id === variables.employee);
      handleMutate(draft => {
        draft.settings.employees = [
          ...draft.settings.employees,
          {
            avatar: correspondingEmployee!.avatar,
            employeeId: payload.employee,
            firstName: correspondingEmployee!.firstName,
            id: payload.id,
            isDeleted: correspondingEmployee!.isDeleted,
            kind: correspondingEmployee!.kind,
            lastName: correspondingEmployee!.lastName,
            number: correspondingEmployee!.number,
          },
        ];
      });
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Dodano pracownika",
      });
      close();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

  const handleSubmit = () => {
    const position = manufacturingStage.settings.employees.length + 1;

    if (selectedEmployee) {
      return addDefaultEmployeeMutation.mutate({
        employee: selectedEmployee.id,
        setting: manufacturingStage.settings.id,
        position: position,
      });
    }
  };

  return { handleSubmit, selectedEmployee, setSelectedEmployee };
};

import { parseISO, set } from "date-fns";

export const combineDateFormat = (date: string | null, time: string | null): Date | null => {
  if (!date || !time) return null;
  return set(parseISO(date), {
    hours: parseInt(time.split(":")[0], 10),
    minutes: parseInt(time.split(":")[1], 10),
    seconds: parseInt(time.split(":")[2] ?? 0, 10),
  });
};

import { format, addDays, addHours, addWeeks, startOfDay } from "date-fns";

const getDateWithTime = (days: number, hours: number): Date => {
  const currentDate = new Date();
  const futureDate = addDays(currentDate, days);
  return addHours(futureDate, hours);
};

const getDateWithTimeFromDayStart = (days: number, hours: number) => {
  const currentDate = new Date();
  const futureDate = addDays(startOfDay(currentDate), days);
  return addHours(futureDate, hours);
};

export const getHidePointDates = (): { label: string; value: string }[] => {
  return [
    { label: "do jutra, 7:00", value: getDateWithTimeFromDayStart(1, 7) },
    { label: "na 2 dni, do 7:00", value: getDateWithTimeFromDayStart(2, 7) },
    { label: "na 3 dni, do 7:00", value: getDateWithTimeFromDayStart(3, 7) },
    { label: "na 24h", value: getDateWithTime(1, 0) },
    { label: "na 48h", value: getDateWithTime(2, 0) },
    { label: "na 72h", value: getDateWithTime(3, 0) },
    { label: "na tydzień", value: addWeeks(new Date(), 1) },
  ].map(({ label, value }) => ({
    label,
    value: format(value, "yyyy-MM-dd'T'HH:mm"),
  }));
};

import { RightPanelSection } from "components/utils/drawer";
import styles from "./OrderedProducts.module.css";
import cx from "classnames";
import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";

import { AttributesList } from "../attributesList/AttributesList";

export const OrderedProducts = ({ routeOrder }: { routeOrder: CallCenterRouteOrderListItem }) => {
  return (
    <RightPanelSection padding="" title="Zamówione produkty">
      <div className="pb-3">
        <div className={cx(styles.tableHeader, styles.tableHeaderBokOrder)}>
          <div>#</div>
          <div>nazwa towaru</div>
          <div>cechy</div>
          <div className="d-flex align-items-center justify-content-end">liczba</div>
          <div>unit</div>
          <div className="d-flex align-items-center justify-content-end">cena / szt.</div>
        </div>

        <div className={styles.tableBody}>
          {routeOrder.items.map((item, index) => {
            return (
              <div className={cx(styles.tableRow, styles.tableRowBokOrder)}>
                <div>{index + 1}.</div>
                <div>
                  <div className={styles.productName}>{item.product.name}</div>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <AttributesList
                    maxCharactersNumForAllItems={40}
                    attributes={item.attributes}
                    id={item.id}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-end">{item.quantity}</div>
                <div>szt.</div>
                <div className="d-flex align-items-center justify-content-end">{item.amount}</div>
              </div>
            );
          })}
        </div>
      </div>
    </RightPanelSection>
  );
};

import { User } from "api/users/models";
import { SuggestionSelect } from "components/common/suggestionSelect/SuggestionSelect";
import { UserAvatar } from "components/utils/userAvatar";
import { useSelector } from "hooks";
import React, { useMemo } from "react";
import styles from "./AssignUser.module.css";
import cx from "classnames";
import { UserPartial } from "api/types";

interface Props {
  disabled?: boolean;
  enableClear?: boolean;
  fixedWidth?: number;
  items?: {
    id: number;
    avatar: string;
    name: string;
    firstName: string;
    lastName: string;
  }[];
  onChange?: (user: UserPartial) => void;
  overrideList?: { className?: string };
  overrideWrapper?: { className?: string };
  overrideToggleButton?: { style?: React.CSSProperties };
  type?: "driver" | "user";
  value: UserPartial;
}

function getUserName(
  user: { name: string; id: number },
  myself: Pick<User, "firstName" | "lastName" | "id">,
) {
  const selectedUserIsMyself = user.id === myself.id;
  if (selectedUserIsMyself) {
    return `${myself.firstName} ${myself.lastName} (Ty)`;
  }
  return user.name;
}

export const AssignedUser = ({
  value,
  onChange,
  disabled = false,
  enableClear = false,
  fixedWidth = 300,
  items = [],
  overrideList = { className: styles.list },
  overrideWrapper = { className: styles.wrapper },
  overrideToggleButton = { style: { height: 30, width: "200px" } },
  type = "user",
}: Props) => {
  const { users, me, lastAssignedUsers } = useSelector(state => {
    const me = state.auth.user!;
    const lastAssignedUsers = state.ui.lastAssignedUsers;
    return {
      users: state.partials.standardUsers.filter(el => el.id !== me.id && el.isDeleted === false),
      me,
      lastAssignedUsers,
    };
  });
  const drivers = useSelector(store => store.partials.drivers);

  const mappedDrivers = drivers.map(driver => ({
    id: driver.id,
    avatar: driver.avatar || "",
    name: `${driver.firstName} ${driver.lastName}`,
    firstName: driver.firstName,
    lastName: driver.lastName,
  }));

  const mappedUsers = useMemo(() => {
    const mappedMe = [
      {
        name: "Przypisz do mnie",
        id: me.id,
        avatar: me.avatar,
        firstName: me.firstName,
        lastName: me.lastName,
      },
    ];
    const mappedUsers = mappedMe.concat(
      users.map(user => ({
        id: user.id,
        avatar: user.avatar || "",
        name: `${user.firstName} ${user.lastName}`,
        firstName: user.firstName,
        lastName: user.lastName,
      })),
    );
    return mappedUsers;
  }, [users, me]);

  const getItems = () => {
    if (items && items.length > 0) {
      return items;
    } else {
      if (type === "user") {
        return mappedUsers;
      } else {
        return mappedDrivers;
      }
    }
  };

  return (
    <>
      <SuggestionSelect
        disabled={disabled}
        enableClear={enableClear}
        items={getItems()}
        onChange={user => {
          onChange?.(user);
        }}
        defaultSuggestedItems={
          type === "user"
            ? mappedUsers.filter(el => lastAssignedUsers.includes(el.id))
            : // eslint-disable-next-line array-callback-return
              mappedDrivers.filter(mappedDriver => {
                if (
                  lastAssignedUsers.includes(mappedDriver.id) &&
                  drivers.find(driver => driver.id === mappedDriver.id)
                ) {
                  return mappedDriver;
                }
              })
        }
        selectedItem={value?.id ?? null}
        width={fixedWidth}
        itemToDisplay={(item, selected) => (
          <div
            className="d-flex align-items-center"
            style={selected ? { color: "#ccc" } : undefined}
          >
            <UserAvatar
              className={cx(styles.avatar, "mr-1")}
              avatarSrc={item?.avatar}
              id={item.id}
              type={type}
            />
            <div>{item.name}</div>
          </div>
        )}
        itemToDisplaySelected={item => (
          <div className="d-flex align-items-center">
            <UserAvatar
              className={cx(styles.avatar, "mr-1")}
              avatarSrc={value?.avatar}
              id={item?.id}
              type={type}
            />
            <span className={styles.userName}>{item ? getUserName(item, me) : "Przypisz"}</span>
          </div>
        )}
        overrides={{
          wrapper: overrideWrapper,
          list: overrideList,
          toggleButton: overrideToggleButton,
        }}
      />
    </>
  );
};

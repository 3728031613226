import { TradingDocument } from "api/trading-documents/models";
import styles from "../../EditTradingDocument.module.css";
import cx from "classnames";
import { AsyncInput } from "components/utils";
import { noop, replaceDotsWithCommas } from "utilities";

interface Props {
  tradingDocument: TradingDocument;
}

export const PaymentSummarySection = ({ tradingDocument }: Props) => {
  return (
    <div className={cx(styles.paymentSummarySection, "container m-0 pb-0 w-100 border-top")}>
      <div className="row">
        <div className={cx(styles.leftSummary, "col-5")}>
          <div
            className={cx(styles.paymentSummaryHeader, "row text-color-grey fs-12 border-bottom")}
          >
            <div className="col-4 pl-0">Forma płatności</div>
            <div className="col-4">Termin płatności</div>
            <div className="col-4">Kwota do zapłaty</div>
          </div>
          <div className={cx(styles.paymentSummaryContent, "row")}>
            <div className="col-4 d-flex align-items-center pl-0">---</div>
            <div className="col-4 d-flex align-items-center">---</div>
            <div className="col-4 d-flex align-items-center">---</div>
          </div>
        </div>
        <div className="col-1" />
        <div className="col-6">
          <div
            className={cx(styles.paymentSummaryHeader, "row text-color-grey fs-12 border-bottom")}
          >
            <div className="col-2"></div>
            <div className="col-1 d-flex justify-content-center">VAT</div>
            <div className="col-3">Cena netto</div>
            <div className="col-3">Wartość VAT</div>
            <div className="col-3">Cena brutto</div>
          </div>
          {tradingDocument.amountSummaryPerVatRate
            .filter(
              vatRate =>
                vatRate.vatRate !== null &&
                vatRate.totalWithTax !== null &&
                vatRate.totalWithoutTax !== null &&
                vatRate.totalTax !== null,
            )
            .map((vatRate, index) => (
              <div className={cx(styles.paymentSummaryContent, "row")} key={index}>
                <div className="col-2 d-flex align-items-center fs-12 text-color-grey">
                  {index === 0 && <>W tym</>}
                </div>
                <div className="col-1 d-flex align-items-center justify-content-center">
                  {vatRate.vatRate}%
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div>{replaceDotsWithCommas(vatRate.totalWithoutTax)}</div>
                  <div className={cx(styles.paymentSectionCurrency, "fs-10 text-color-grey")}>
                    {tradingDocument.currency}
                  </div>
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div>{replaceDotsWithCommas(vatRate.totalTax)}</div>
                  <div className={cx(styles.paymentSectionCurrency, "fs-10 text-color-grey")}>
                    {tradingDocument.currency}
                  </div>
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div>{replaceDotsWithCommas(vatRate.totalWithTax)}</div>
                  <div className={cx(styles.paymentSectionCurrency, "fs-10 text-color-grey")}>
                    {tradingDocument.currency}
                  </div>
                </div>
              </div>
            ))}
          <div className={cx(styles.paymentSummarySum, "row")}>
            <div className="col-2 d-flex align-items-center fs-12 text-color-grey">SUMA:</div>
            <div className="col-1 d-flex align-items-center justify-content-center" />
            <div className="col-3 d-flex align-items-center">
              <div>{replaceDotsWithCommas(tradingDocument.amountSummary.totalWithoutTax)}</div>
              <div className={cx(styles.paymentSectionCurrency, "fs-10 text-color-grey")}>
                {tradingDocument.currency}
              </div>
            </div>
            <div className="col-3 d-flex align-items-center">
              <div>{replaceDotsWithCommas(tradingDocument.amountSummary.totalTax)}</div>
              <div className={cx(styles.paymentSectionCurrency, "fs-10 text-color-grey")}>
                {tradingDocument.currency}
              </div>
            </div>
            <div className="col-3 d-flex align-items-center">
              <div>{replaceDotsWithCommas(tradingDocument.amountSummary.totalWithTax)}</div>
              <div className={cx(styles.paymentSectionCurrency, "fs-10 text-color-grey")}>
                {tradingDocument.currency}
              </div>
            </div>
          </div>
          <div className={cx(styles.paymentSum, "row")}>
            <div className={cx(styles.paymentSumContent, "col-12 border-top")}>
              <div className={cx(styles.paymentSumByDigits, "row")}>
                <div className="col-12 d-flex align-items-center justify-content-end">
                  Razem do zapłaty:&nbsp;
                  {replaceDotsWithCommas(tradingDocument.amountSummary.totalWithTax)}&nbsp;
                  {tradingDocument.currency}
                </div>
              </div>
              <div>
                <AsyncInput
                  disabled={true}
                  label="Słownie"
                  onChange={noop}
                  value={tradingDocument.amountSummary.totalWithTaxInWords}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

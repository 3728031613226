import { colorPalette } from "../../colorsPalette";
import { SvgWrapper } from "../SvgWrapper";
import { BaseIcon } from "../types";
import styles from "./MdiPoint.module.css";

export const MdiPoint = (props: BaseIcon) => (
  <div className="position-relative">
    <div
      className={styles.pulse}
      style={{
        backgroundColor: props.customColor
          ? props.customColor
          : colorPalette[props.color || "neutralBlack100"],
        width: `${props.size}px`,
        height: `${props.size}px`,
      }}
    />
    <SvgWrapper {...props}>
      <path d="M12 17C13.3833 17 14.5625 16.5125 15.5375 15.5375C16.5125 14.5625 17 13.3833 17 12C17 10.6167 16.5125 9.4375 15.5375 8.4625C14.5625 7.4875 13.3833 7 12 7C10.6167 7 9.4375 7.4875 8.4625 8.4625C7.4875 9.4375 7 10.6167 7 12C7 13.3833 7.4875 14.5625 8.4625 15.5375C9.4375 16.5125 10.6167 17 12 17Z" />
    </SvgWrapper>
  </div>
);

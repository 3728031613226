import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import styles from "./RightPanel.module.css";
import { wmsLayoutActions } from "api/wms/layout/actions";
import { assertIsDefined } from "utilities/assertIsDefined";
import { PanelHeader } from "./components/PanelHeader";
import { PackagesInLocationSection } from "./components/packagesInLocationSection/PackagesInLocationSection";

interface Props {
  close: () => void;
  selectedHall: number;
}

export const RightPanel = ({ close, selectedHall }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: field, error, isLoading } = wmsLayoutActions.useLayoutField({
    hallId: selectedHall,
    nodeId: panelId,
  });

  if (error) {
    return (
      <DrawerRightPanel className={styles.rightPanel}>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className={styles.rightPanel}>
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(field);

  return (
    <DrawerRightPanel className={styles.rightPanel} key={panelId}>
      <PanelHeader close={close} field={field} />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody>
          <PackagesInLocationSection selectedHall={selectedHall} field={field} />
        </RightPanelBody>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};

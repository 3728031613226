import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import shoppingCart from "assets/images/moduleNavigationIcons/shopping_cart.svg";
import { Dashboard } from "routes/reports/Dashboard";

export const sales: ModuleLink = {
  url: "sales",
  label: "Sprzedaż",
  icon: shoppingCart,
  subSection: <></>,
  routing: Dashboard,
  isAccessible: false,
};

import { Button } from "components/common";
import { SendNotificationPopUp } from "./SendNotificationPopUp";
import smsIcon from "assets/images/sms.svg";
import shoppingCartIcon from "assets/images/shoppingCartCheckout.svg";
import threeDotsIcon from "assets/images/more_horiz.svg";
import ReactTooltip from "react-tooltip";
import { Route } from "api/routes/models";
import styles from "../../../RightPanel.module.css";
import cx from "classnames";
import { useToggle } from "hooks";
import { EmailNotificationsModal } from "pages/routes/routesList/rightPanel/pointsSection/emailNotificationsModal/EmailNotificationsModal";
import { ConversationModal } from "pages/routes/routesList/rightPanel/pointsSection/conversationModal/ConversationModal";
import { Link } from "react-router-dom";
import { Button as ButtonPlaceholder } from "components/miloDesignSystem/atoms/button";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiMail } from "components/miloDesignSystem/atoms/icons/MdiMail";
import { MdiTimeline } from "components/miloDesignSystem/atoms/icons/MdiTimeline";

interface Props {
  route: Route;
}

export const NotificationsPanel = ({ route }: Props) => {
  const emailNotificationsModal = useToggle();
  const smsNotificationsModal = useToggle();
  const routeTrackingModal = useToggle();

  return (
    <>
      <div
        className={cx(
          "d-flex align-items-center justify-content-between pb-2",
          styles.notificationPanelContainer,
        )}
      >
        <SendNotificationPopUp route={route} />
        <div className="d-flex align-items-center justify-content-end gap-1">
          <ButtonPlaceholder
            onClick={emailNotificationsModal.open}
            size="small"
            startIcon={MdiMail}
            variant="gray"
          >
            Powiadomienia email
          </ButtonPlaceholder>
          <Button kind="create" onClick={smsNotificationsModal.open} style={{ height: "26px" }}>
            <div className="btnBase btnBase16 btnBaseSmall">
              <img alt="wiadomości sms" src={smsIcon} />
              Powiadomienia SMS
            </div>
          </Button>
          <span className="divider line-divider" />
          <Tooltip title="Śledź trasę">
            <IconButton
              icon={MdiTimeline}
              onClick={routeTrackingModal.open}
              variant="transparent"
            />
          </Tooltip>
          <Button
            as={Link}
            kind="transparent-black"
            size="square-s"
            to={`/orders/source/list/active/all/route/${route.id}?page=1`}
          >
            <div
              className="btnBase btnBase16 btnBaseSmall"
              data-tip
              data-for={`route-orders-${route.id}`}
            >
              <img alt="Zamówienia" src={shoppingCartIcon} />
            </div>
          </Button>
          <ReactTooltip
            className={styles.customTooltip}
            id={`route-orders-${route.id}`}
            place="top"
            effect="solid"
            arrowColor="transparent"
            offset={{ top: -10 }}
          >
            Lista zamówień trasy
          </ReactTooltip>
          <Button kind="transparent-black" size="square-s">
            <div
              className="btnBase btnBase16 btnBaseSmall"
              data-tip
              data-for={`route-more-${route.id}`}
            >
              <img alt="Więcej" src={threeDotsIcon} />
            </div>
          </Button>
          <ReactTooltip
            className={styles.customTooltip}
            id={`route-more-${route.id}`}
            place="top"
            effect="solid"
            arrowColor="transparent"
            offset={{ top: -10 }}
          >
            Więcej...
          </ReactTooltip>
        </div>
      </div>
      <EmailNotificationsModal modal={emailNotificationsModal} />
      <ConversationModal modal={smsNotificationsModal} />
      {/* {routeTrackingModal.isOpen && (
        <RouteTrackingModal close={routeTrackingModal.close} route={route} />
      )} */}
    </>
  );
};

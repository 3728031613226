import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import forkliftIcon from "assets/images/forklift.svg";
import { Unloadings } from "routes/wms/Unloadings";

export const unloadings: ModuleLink = {
  url: "unloadings",
  label: "Rozładunki",
  icon: forkliftIcon,
  subSection: <></>,
  routing: Unloadings,
};

import trayIcon from "assets/images/tray.svg";
import styles from "../../../Dashboard.module.css";
import cx from "classnames";

export const EmptyDocumentsTraySection = () => {
  return (
    <div className={cx(styles.emptyList, "px-5")}>
      <div>
        <img alt="Taca" className={cx(styles.icon, "pb-3")} src={trayIcon} />
      </div>
      <h3 className={styles.emptyListTitle}>Zarządzaj dokumentami</h3>
      <div className={cx(styles.body14, "text-grey-35 pb-3 body-14-600")}>
        Przechowuj swoje dokumenty transakcyjne w jednym miejscu
      </div>
    </div>
  );
};

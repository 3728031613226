import { orderFileFactory } from "api/orders/calls";
import { RouteOrder } from "api/routes/models";
import { StatusHandlerHelpers } from "components/utils";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";

export const useOrderPdfDownload = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();

  async function handleDownloadOrderPdf(order: RouteOrder, helpers: StatusHandlerHelpers) {
    const fileToastr = downloadFeedbackToastr.open({ type: "pdf" });
    helpers.startFetching();
    const { url, name } = orderFileFactory.orderPdf(order);
    const response = await fileDownloader({
      onProgress: fileToastr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    helpers.stopFetching();
    if (response.status === "success") {
      fileToastr.lazyClose();
    }
  }

  return handleDownloadOrderPdf;
};

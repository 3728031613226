import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { Dashboard } from "pages/tradingDocuments/dashboard/Dashboard";
import { RouteComponentProps, Switch } from "react-router-dom";

export const InvoiceDashboard = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        component={Dashboard}
        exact={true}
        path={`${match.path}`}
        title="Milo - pulpit faktur"
      />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};

import { dayOptions } from "api/logistics/routes/models";
import { getIndicativeDate } from "pages/routes/routesList/rightPanel/pointsSection/confirmationDateModal/utils";
import { ConfirmationDateOptions } from "api/milo-settings/models";
import styles from "../ConfirmationDateModals.module.css";
import cx from "classnames";

interface Props {
  allOptions: ConfirmationDateOptions[];
  picked: ConfirmationDateOptions | null;
  setPicked: React.Dispatch<React.SetStateAction<ConfirmationDateOptions | null>>;
}

export const ConfirmationDateBody = ({ allOptions, picked, setPicked }: Props) => {
  return (
    <>
      <div className={cx(styles.title, "mt-2 mb-4")}>
        Do kiedy możliwe będzie potwierdzenie zamówienia?
      </div>

      <div className="text-color-grey mb-3">Wybierz jedną z opcji lub dodaj własną:</div>

      <div className="mb-4 gap-2">
        {allOptions.length > 0 &&
          allOptions.map(option => {
            return (
              <div
                className={cx(
                  styles.option,
                  "d-flex align-items-center justify-content-center mb-2",
                  {
                    [styles.optionActive]: option.id === picked?.id,
                  },
                )}
                key={option.id}
                onClick={() => {
                  if (option.id === picked?.id) {
                    setPicked(null);
                  } else {
                    setPicked(option);
                  }
                }}
              >
                <span>
                  {dayOptions[option.deadlineDayOption]}
                  <span className="text-color-grey fs-14">
                    &nbsp;({getIndicativeDate(option.deadlineDayOption)})
                  </span>
                </span>
                <span className="divider" />
                <span>{option.deadlineTime.slice(0, 5)}</span>
              </div>
            );
          })}
      </div>
    </>
  );
};

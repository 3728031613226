import * as React from "react";
import { dateFns } from "utilities";
import styles from "./Comment.module.css";
import cx from "classnames";
import { UserAvatar } from "components/utils/userAvatar";
import { PopoverMenu } from "components/utils/Popover";
import threeDotsImg from "assets/images/41.svg";
import visibilityOffIcon from "assets/images/eye_off.svg";
import visibilityOnIcon from "assets/images/eye_on.svg";
import editIcon from "assets/images/1.svg";
import binIcon from "assets/images/81.svg";
import { Button } from "../buttonLegacy";
import { AutoTextarea } from "../AutoTextarea";
import { useSelector, useToggle } from "hooks";
import { moveCaretAtEnd } from "utilities/moveCaretAtEnd";
import { StatusHandler, StatusHandlerHelpers } from "components/utils";
import { FLAVOR } from "CONSTANTS";

export interface CommentType {
  body: string;
  id: number;
  created: string;
  isOnlyVisibleForTeam?: boolean;
  user: { id: number; avatar: string | null; lastName: string; firstName: string };
}

interface Props {
  comment: CommentType;
  onDelete?: (comment: CommentType) => void;
  onEdit?: (comment: CommentType, helpers: StatusHandlerHelpers) => Promise<void>;
}

export const Comment = ({ comment, onDelete, onEdit }: Props) => {
  const { isOpen: editMode, open: enterEditMode, close: exitEditMode } = useToggle();
  const me = useSelector(state => state.auth.user!);
  const iAmTheAuthor = me.id === comment.user.id;
  const deletePrivileges = iAmTheAuthor || me.isSuperuser;
  const displayMenu =
    (FLAVOR === "main" && (onEdit || onDelete)) ||
    (iAmTheAuthor && onEdit) ||
    (deletePrivileges && onDelete);

  async function handleEdit(
    e: React.FocusEvent<HTMLTextAreaElement>,
    helpers: StatusHandlerHelpers,
  ) {
    if (!onEdit) {
      throw new Error("Invoked comment edit function without edit callback!");
    }
    const newValue = e.target.value;
    if (newValue === comment.body) {
      exitEditMode();
    } else {
      await onEdit(
        {
          ...comment,
          body: newValue,
          // updated:  TODO
        },
        helpers,
      );
      exitEditMode();
    }
  }

  async function handleCommentVisibility(isVisible: boolean, helpers: StatusHandlerHelpers) {
    if (!onEdit) {
      throw new Error("Invoked comment edit function without edit callback!");
    }
    await onEdit(
      {
        ...comment,
        isOnlyVisibleForTeam: isVisible,
      },
      helpers,
    );
  }

  return (
    <div className={cx("d-flex align-items-center mt-2", styles.comment)}>
      <UserAvatar id={comment.user.id} avatarSrc={comment.user.avatar} />
      <div className="ml-2 flex-1">
        <div className="d-flex">
          <div className={styles.commentBy}>
            {comment.user.firstName} {comment.user.lastName}
          </div>
          <div className={styles.commentDate}>
            <i className="mr-2 ml-2" />{" "}
            <strong className="mr-2">
              {comment.created && dateFns.format(new Date(comment.created), "d MMM yyyy")}
            </strong>{" "}
            <span>{comment.created && dateFns.format(new Date(comment.created), "H:mm")}</span>
          </div>
        </div>

        {editMode ? (
          <StatusHandler>
            {helpers => (
              <AutoTextarea
                disabled={helpers.isFetching}
                onBlur={e => handleEdit(e, helpers)}
                className={styles.textarea}
                defaultValue={comment.body}
                onFocus={moveCaretAtEnd}
                autoFocus
              />
            )}
          </StatusHandler>
        ) : (
          <div className="d-flex">
            <div className={styles.body}>{comment.body}</div>
          </div>
        )}
      </div>
      {FLAVOR === "main" && (
        <StatusHandler>
          {helpers => (
            <Button
              disabled={helpers.isFetching}
              kind="secondary"
              size="round-m"
              onClick={() => {
                handleCommentVisibility(!comment.isOnlyVisibleForTeam, helpers);
              }}
            >
              {comment.isOnlyVisibleForTeam ? (
                <img src={visibilityOnIcon} alt="" />
              ) : (
                <img src={visibilityOffIcon} alt="" />
              )}
            </Button>
          )}
        </StatusHandler>
      )}
      {displayMenu && (
        <PopoverMenu
          target={btnProps => (
            <Button kind="secondary" size="round-m" {...btnProps}>
              <img src={threeDotsImg} alt="" />
            </Button>
          )}
        >
          {({ close }) => (
            <div className={styles.popoverMenu}>
              {onEdit && FLAVOR === "main" && comment.isOnlyVisibleForTeam && (
                <StatusHandler>
                  {helpers => (
                    <Button
                      disabled={helpers.isFetching}
                      kind="secondary"
                      size="small"
                      width="full"
                      className={styles.popoverBtn}
                      onClick={() => {
                        handleCommentVisibility(!comment.isOnlyVisibleForTeam, helpers);
                        close();
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img src={visibilityOnIcon} alt="" />
                        <span className="pl-1">Widoczny dla wszystkich</span>
                      </div>
                    </Button>
                  )}
                </StatusHandler>
              )}
              {onEdit && FLAVOR === "main" && !comment.isOnlyVisibleForTeam && (
                <StatusHandler>
                  {helpers => (
                    <Button
                      disabled={helpers.isFetching}
                      kind="secondary"
                      size="small"
                      width="full"
                      className={styles.popoverBtn}
                      onClick={() => {
                        handleCommentVisibility(!comment.isOnlyVisibleForTeam, helpers);
                        close();
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img src={visibilityOffIcon} alt="" />
                        <span className="pl-1">Widoczny tylko dla zespołu</span>
                      </div>
                    </Button>
                  )}
                </StatusHandler>
              )}
              {onEdit && (FLAVOR === "main" || iAmTheAuthor) && (
                <Button
                  kind="secondary"
                  size="small"
                  width="full"
                  className={styles.popoverBtn}
                  onClick={() => {
                    enterEditMode();
                    close();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <img src={editIcon} alt="" />
                    <span className="pl-1">Edytuj</span>
                  </div>
                </Button>
              )}
              {onDelete && (FLAVOR === "main" || deletePrivileges) && (
                <Button
                  kind="secondary"
                  size="small"
                  width="full"
                  className={styles.popoverBtn}
                  onClick={() => onDelete(comment)}
                >
                  <div className="d-flex align-items-center">
                    <img src={binIcon} alt="" />
                    <span className="pl-1" style={{ color: "#E87076" }}>
                      Usuń
                    </span>
                  </div>
                </Button>
              )}
            </div>
          )}
        </PopoverMenu>
      )}
    </div>
  );
};

import { Redirect, Switch } from "react-router-dom";
import { Route, AuthRoute, CommonError } from "components/utils";
import { Button } from "components/common";
import { Login } from "pages/login";
import loadable from "@loadable/component";
import { OrderTracking } from "pages/orderTracking/OrderTracking";
import { NotFound } from "pages/notFound";
import { ManufacturerOrderDetails } from "pages/manufacturerOrder/manufacturerOrderDetails";
import { useRedux, useSelector, useTrigger } from "hooks";
import { ConfirmCollectionDate } from "pages/orders/confirmCollectionDate";
import { ResetPassword } from "pages/resetPassword";
import { OrderStatus } from "pages/orderStatus/OrderStatus";
import { getAnyErrorKey } from "utilities";
import { Creator } from "pages/routes/creator/Creator";
import { CreatorEmpty } from "pages/routes/creator/CreatorEmpty";
import { CreateRoute } from "pages/routes/creator/CreateRoute";
import { AppLoader } from "components/common/appLoader/AppLoader";
import { FLAVOR } from "CONSTANTS";
import Helpdesk from "./Helpdesk";
import { ProductionPlanDetails } from "pages/productionPlans/productionPlanDetails";
import {
  externalManufacturerModuleConfig,
  moduleConfig,
} from "components/common/moduleNavigation/moduleConfig";
import { WarehouseSchema } from "pages/warehouseSchema/productionPlanDetails/WarehouseSchema";

const MainRoutes = loadable(() => import("./MainRoutes"), {
  fallback: <div />,
});

const ClientRoutes = loadable(() => import("./ClientRoutes"), {
  fallback: <div />,
});

const ManufacturingRoutes = loadable(() => import("./ManufacturingRoutes"), {
  fallback: <div />,
});

const ExternalManufacturingRoutes = loadable(
  () => import("./externalManufacturing/ExternalManufacturingRoutes"),
  {
    fallback: <div />,
  },
);

/**
 * On settings refetch we want to mount all the UI again to be sure that form
 * validators match the settings.
 */
function useB2bRemountTrigger() {
  const settings = useSelector(store => store.settings.settings);
  const b2bSettingsChangeTrigger = useTrigger(settings, (a, b) => {
    return FLAVOR === "b2b" && a !== b;
  });

  return b2bSettingsChangeTrigger;
}

export const RoutesRoot = () => {
  const [dispatch, { auth }] = useRedux();
  const partialsError = useSelector(store => store.partials.error);
  const b2bSettingsChangeTrigger = useB2bRemountTrigger();
  const isLocked = useSelector(store => store.ui.versionLock);

  return (
    <Switch key={b2bSettingsChangeTrigger}>
      <Route exact={true} path="/login" component={Login} title="Milo - login" />
      <Route
        exact={true}
        path="/reset-password"
        component={ResetPassword}
        title="Milo - odzyskaj hasło"
      />
      <Route
        exact={true}
        path="/order-tracking/:uid/:token"
        component={OrderTracking}
        title="Milo - śledzenie zamówienia"
      />
      <Route
        exact={true}
        path="/order-status/:signature"
        component={OrderStatus}
        title="Milo - status zamówienia"
      />
      {/* Support for old addresses, delete after 01.2023 */}
      <Route
        exact={true}
        path="/orders/confirm-sms/:orderId/:token"
        component={ConfirmCollectionDate}
        title="Milo - potwierdzenie daty odbioru"
      />
      {/* /s/ is shortened version of the sms link.
      This URL address is used in SMS messages - do not change it without consulting the backend first. */}
      <Route
        exact={true}
        path="/s/:orderId"
        component={ConfirmCollectionDate}
        title="Milo - potwierdzenie daty odbioru"
      />
      <Route
        exact={true}
        path="/manufacturers/order/:orderId/:token"
        component={ManufacturerOrderDetails}
        title="Milo - zlecenie dla dostawcy"
      />
      <AuthRoute
        path="/"
        render={() => {
          if (partialsError)
            return (
              <CommonError
                text={
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <div>{getAnyErrorKey(partialsError)}</div>
                    <div className="mb-2 mt-1">
                      Sprawdź czy zalogowałeś się pod właściwym adresem.
                    </div>
                    <Button
                      kind="primary"
                      className="mt-2"
                      onClick={() => dispatch(auth.resetStore())}
                    >
                      Wróć do logowania
                    </Button>
                  </div>
                }
              />
            );

          if (isLocked)
            return (
              <div className="d-flex align-items-center justify-content-center h-100 mt-5">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center">
                      <div className="mb-2"> Nowa wersja aplikacji</div>
                      <Button kind="primary" onClick={() => window.location.reload()}>
                        Pobierz nową wersję aplikacji
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            );

          return (
            <>
              <AppLoader />
              <Switch>
                <Route
                  path={`/routes/creator`}
                  exact
                  component={CreateRoute}
                  title="Milo - kreator trasy"
                />
                <Route
                  path={`/routes/creator/:routeId/archive`}
                  title="Milo - szczegóły archiwalnej trasy"
                  render={props => <Creator archive routeComponentsProps={props} />}
                />

                {FLAVOR === "externalManufacturing" &&
                  Object.entries(externalManufacturerModuleConfig).map(([module, data]) => {
                    return data.navigationSections.map(section => {
                      return (
                        <Route
                          key={module}
                          exact
                          path={`${data.url}`}
                          render={() => {
                            // @ts-ignore
                            if (section.navigationList.length > 0) {
                              // @ts-ignore
                              return (
                                <Redirect to={`${data.url}/${section.navigationList[0].url}`} />
                              );
                            } else {
                              return <Redirect to={`${data.url}`} />;
                            }
                          }}
                        />
                      );
                    });
                  })}

                {Object.entries(moduleConfig).map(([module, data]) => {
                  return data.navigationSections.map(section => {
                    return (
                      <Route
                        key={module}
                        exact
                        path={`${data.url}`}
                        render={() => {
                          // @ts-ignore
                          if (section.navigationList.length > 0) {
                            // @ts-ignore
                            return <Redirect to={`${data.url}/${section.navigationList[0].url}`} />;
                          } else {
                            return <Redirect to={`${data.url}`} />;
                          }
                        }}
                      />
                    );
                  });
                })}

                <Route
                  path={`/production-plans/details/:id`}
                  title="Milo - szczegóły planu produkcyjnego"
                  render={props => <ProductionPlanDetails routeComponentsProps={props} />}
                />
                <Route
                  path={`/warehouse-schema`}
                  title="Milo - schemat magazynu"
                  render={props => <WarehouseSchema />}
                />
                <Route
                  path={`/wms/warehouse-schema`}
                  title="Milo - schemat magazynu"
                  render={props => <WarehouseSchema />}
                />
                <Route
                  path={`/routes/map/`}
                  title="Milo - kreator trasy"
                  render={() => <CreatorEmpty />}
                />
                <Route
                  path={`/routes/creator/:routeId`}
                  title="Milo - kreator trasy"
                  render={props => <Creator routeComponentsProps={props} />}
                />
                <Route exact={false} path="/helpdesk" component={Helpdesk} />

                {process.env.REACT_APP_FLAVOR === "main" && <MainRoutes />}
                {process.env.REACT_APP_FLAVOR === "b2b" && <ClientRoutes />}
                {process.env.REACT_APP_FLAVOR === "manufacturing" && <ManufacturingRoutes />}
                {process.env.REACT_APP_FLAVOR === "externalManufacturing" && (
                  <ExternalManufacturingRoutes />
                )}
                <Route exact={true} path="*" component={NotFound} title="Milo" />
              </Switch>
            </>
          );
        }}
      />
      <Route exact={true} path="*" component={NotFound} title="Milo" />
    </Switch>
  );
};

import { Assign } from "utility-types";
import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { Order } from "../orders/models";

type Payment = Order["payment"];

const api = tokenRefreshMiddleware(apiFetch);

export const patchPayment = (
  id: number | string,
  data: Partial<Assign<Payment, { source: "ON_DELIVERY" | "ONLINE" }>>,
) =>
  api<Payment>({
    method: "PATCH",
    url: `/payments/items/${id}`,
    data,
  });

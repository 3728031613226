import { AvatarProps } from "./types";
import styles from "./Avatar.module.css";
import cx from "classnames";
import darkCloseIcon from "assets/images/close.svg";
import lightCloseIcon from "assets/images/closeWhite.svg";
import { Typography } from "../typography";
import { MdiFace } from "../icons/MdiFace";
import { TypographyProps } from "../typography/types";

export const Avatar = ({
  onClick,
  onDelete,
  size,
  theme = "light",
  user,
  className,
}: AvatarProps) => {
  if (!user) return <AvatarPlaceholder onClick={onClick} size={size} theme={theme} />;

  return (
    <div
      className={cx(
        styles.avatar,
        styles[size],
        {
          [styles[`${theme}WithOnDelete`]]: onDelete !== undefined,
          [styles[theme]]: onDelete === undefined,
        },
        className,
      )}
      style={{ backgroundColor: !user.avatar ? user.initialsBackgroundColor : "" }}
    >
      {user.avatar && (
        <img
          className={cx(styles.image, "w-100 h-100", {
            [styles[`${theme}WithOnDelete`]]: onDelete !== undefined,
            [styles[theme]]: onDelete === undefined,
          })}
          alt="avatar"
          src={user.avatar}
        />
      )}
      {!user.avatar && (
        <Typography
          className={cx(styles.initials, styles[`${size}LineHeight`])}
          color={user.initialsTextColor}
          fontSize={initialsFontSize[size]}
          fontWeight="700"
        >
          {getInitials(user.firstName, user.lastName)}
        </Typography>
      )}
      {onDelete && (
        <div
          className={cx(styles.onDelete, styles[`${size}OnDeletePosition`], {
            [styles.lightOnDelete]: theme === "light",
            [styles.darkOnDelete]: theme === "dark",
          })}
          onClick={onDelete}
        >
          <div className={styles.onDeleteInner}>
            {theme === "light" && <img alt="usuń" src={lightCloseIcon} />}
            {theme === "dark" && <img alt="usuń" src={darkCloseIcon} />}
          </div>
        </div>
      )}
    </div>
  );
};

const AvatarPlaceholder = ({
  onClick,
  size,
  theme,
}: Pick<AvatarProps, "onClick" | "size" | "theme">) => {
  return (
    <div
      className={cx(styles.avatar, styles[size], styles[`${theme}Placeholder`])}
      onClick={onClick}
    >
      <MdiFace
        color={theme === "light" ? "neutralBlack20" : "neutralWhite64"}
        size={placeholderSize[size]}
      />
    </div>
  );
};

const initialsFontSize: Record<AvatarProps["size"], TypographyProps["fontSize"]> = {
  xs: "10",
  sm: "12",
  md: "14",
  lg: "16",
  xl: "26",
};

const placeholderSize: Record<AvatarProps["size"], string> = {
  xs: "18",
  sm: "24",
  md: "30",
  lg: "34",
  xl: "56",
};

const getInitials = (firstName: string, lastName: string): string => {
  const fullName = [firstName, lastName];
  return fullName.map(name => name.charAt(0).toLocaleUpperCase()).join("");
};

import { useSelector } from "hooks";
import { cx } from "utilities";
import styles from "./FulfillmentEntityDetails.module.css";
import { useCellFillingStatus } from "api/warehouse/hooks";
import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { getFilledLocationPercentage } from "pages/wms/shared/components/WarehouseFillingStatus";

interface Props {
  showFill: boolean;
}

export const FulfillmentEntityDetails = ({ showFill }: Props) => {
  const isNavbarOpen = useSelector(store => store.ui.isNavbarOpened);
  const { data, isLoading, error } = useCellFillingStatus(
    {},
    {
      enabled: Boolean(showFill),
    },
  );

  if (error) {
    return (
      <div
        className={cx("p-3", styles.wrapper, {
          [styles.wrapperForOpenedNavbar]: isNavbarOpen,
          [styles.wrapperForClosedNavbar]: !isNavbarOpen,
        })}
      >
        <CommonError status={error._httpStatus_} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        className={cx("p-3 d-flex align-items-center justify-content-center", styles.wrapper, {
          [styles.wrapperForOpenedNavbar]: isNavbarOpen,
          [styles.wrapperForClosedNavbar]: !isNavbarOpen,
        })}
      >
        <Spinner size={30} />
      </div>
    );
  }

  if (!data) return null;

  return (
    <div
      className={cx("p-3", styles.wrapper, {
        [styles.wrapperForOpenedNavbar]: isNavbarOpen,
        [styles.wrapperForClosedNavbar]: !isNavbarOpen,
      })}
    >
      <div className="d-flex align-items-center gap-3">
        <Typography color="neutralWhite100" fontSize="18" fontWeight="700">
          Magazyn główny
        </Typography>
        <div className="d-flex align-items-center gap-1">
          <Typography color="success400" fontSize="18" fontWeight="700">
            Zapełnienie: {getFilledLocationPercentage(data.filledLocationsPercentage)}
          </Typography>
        </div>
      </div>
      <div className="d-flex align-items-center gap-2 pt-2">
        <Typography color="grey500" fontSize="12" fontWeight="700">
          liczba paczek ogółem:
        </Typography>
        <Typography color="neutralWhite100" fontSize="12" fontWeight="700">
          {data.totalLocations} szt.
        </Typography>
      </div>
    </div>
  );
};

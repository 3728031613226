import { createColumnHelper } from "@tanstack/react-table";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { OverviewReceivedByProductCategory } from "api/wms/models";
import { useMemo } from "react";
import { GenericTable } from "typeUtilities";
import { TableFilters } from "components/common/genericTable/useGenericTableFilters";
import { formatGenericTableDate } from "components/common/genericTable/utils";
import { DatepointerDetails } from "pages/wms/shared/components/DatepointerDetails";
import { getDatepointerColor } from "pages/wms/shared/utils/getDatepointerColor";

const columnHelper = createColumnHelper<
  GenericTable<OverviewReceivedByProductCategory>["rows"][number]
>();

const dynamicColumnHelper = createColumnHelper<GenericTable<string>["rows"][number]>();

export const useOverviewReceivedByProductCategoryListColumns = (
  tableData: GenericTable<OverviewReceivedByProductCategory> | null,
  filters: TableFilters,
) => {
  return useMemo(() => {
    if (!tableData) return [];

    const dynamicColumns = tableData.columns.filter(column => !isNaN(Number(column.identifier)));

    return [
      columnHelper.accessor(row => row.DATEPOINTER.value, {
        header:
          tableData.columns.find(
            column => column.identifier === OverviewReceivedByProductCategory.DATEPOINTER,
          )?.name || "",
        size: 60,
        cell: info => (
          <Typography
            color={getDatepointerColor(info.getValue(), filters.rowBy)}
            fontSize="12"
            fontWeight="600"
          >
            {formatGenericTableDate(tableData.rowsBy, info.getValue())}
          </Typography>
        ),
      }),
      columnHelper.accessor(row => row.DATEPOINTER.value, {
        id: "DATEPOINTER_DAYNAME_RELEASED",
        header: "",
        size: 40,
        cell: info => <DatepointerDetails date={info.getValue()} rowBy={filters.rowBy} />,
      }),
      ...dynamicColumns.map(dynamicColumn => {
        return dynamicColumnHelper.accessor(row => row[dynamicColumn.identifier].value, {
          id: dynamicColumn.identifier,
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              {tableData.columns.find(column => column.identifier === dynamicColumn.identifier)
                ?.name || ""}
            </Typography>
          ),
          size: 65,
          cell: info => (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {info.getValue() || "---"}
            </Typography>
          ),
        });
      }),

      columnHelper.accessor(row => row.OTHERS.value, {
        id: "OTHER",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            {tableData.columns.find(
              column => column.identifier === OverviewReceivedByProductCategory.OTHERS,
            )?.name || ""}
          </Typography>
        ),
        size: 65,
        cell: info => (
          <Typography className="text-right w-100" fontSize="12" fontWeight="700">
            {info.getValue() || "---"}
          </Typography>
        ),
      }),
    ];
  }, [filters.rowBy, tableData]);
};

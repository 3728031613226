import { SelectedSource } from "api/new-production-plans/models";

export const toggleSelectedSources = (
  id: string | number,
  setSelectedSources: React.Dispatch<React.SetStateAction<SelectedSource[]>>,
  signature: string,
): void => {
  setSelectedSources(prevSelectedSources => {
    const isSourcePreviouslySelected = prevSelectedSources.some(
      prevSelectedSource =>
        prevSelectedSource.id === id && prevSelectedSource.signature === signature,
    );

    if (isSourcePreviouslySelected) {
      return prevSelectedSources.filter(
        prevSelectedSource =>
          prevSelectedSource.id !== id && prevSelectedSource.signature !== signature,
      );
    }
    return [
      ...prevSelectedSources,
      {
        id,
        signature,
      },
    ];
  });
};

import styles from "./WayfairPdf.module.css";
import viewPdfIcon from "assets/images/161.svg";
import { Modal, RadioGroup, StatusHandler } from "components/utils";
import { Button } from "components/common";
import removeIcon from "assets/images/11.svg";
import { useState } from "react";
import cx from "classnames";
import { useToggle } from "hooks";
import { wayFairFileFactory } from "api/wayfair/calls";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";
import { useToastr } from "hooks";
import { getAnyErrorKey } from "utilities";
import { WayfairPdfLang } from "api/wayfair/models";

interface Props {
  id: number;
  type: "group" | "route";
}

const languages: { id: WayfairPdfLang; name: string }[] = [
  { id: "EN", name: "Angielski" },
  { id: "DE", name: "Niemiecki" },
];

export const WayfairPdf = ({ id, type }: Props) => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const toastr = useToastr();
  const customerPdfModal = useToggle();
  const [lang, setLang] = useState<WayfairPdfLang | null>(null);
  const [search, setSearch] = useState("");
  const { close, isOpen, open } = useToggle();

  const languagesToDisplay = languages.filter(({ name }) => {
    return name.toLowerCase().includes(search.toLowerCase());
  });

  const handleWayfairPdfDownload = async () => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = wayFairFileFactory.wayFairPDF(type, id, lang!);
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
      customerPdfModal.close();
    } else {
      tstr.close();
      toastr.open({
        type: response.httpStatus === 400 ? "warning" : "failure",
        title: "Oj, coś nie tak.",
        text: getAnyErrorKey(response.error),
      });
    }
  };

  return (
    <>
      <div className={styles.pdf} onClick={open}>
        <img alt="" src={viewPdfIcon} />
        <span>Pobierz PDF: lista zamówień wayfair</span>
      </div>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          close={close}
          size="auto"
          overrides={{ container: { className: styles.modal } }}
        >
          <div className="d-flex flex-column">
            <h4 className={styles.title}>Wybierz język</h4>
            <div className={styles.inputContainer}>
              <input
                value={search}
                onChange={e => setSearch(e.target.value)}
                className={styles.input}
                placeholder="Szukaj..."
              />
              {search && (
                <img
                  className={styles.inputIcon}
                  src={removeIcon}
                  alt=""
                  onClick={() => setSearch("")}
                />
              )}
            </div>
            {languagesToDisplay.length ? (
              <RadioGroup
                className={cx("d-flex flex-column", styles.container)}
                name="lang"
                items={languagesToDisplay}
                onChange={val => val && setLang(val.id)}
                value={lang}
              />
            ) : (
              "Brak języka o takiej nazwie"
            )}
            <StatusHandler>
              {helpers => (
                <Button
                  type="button"
                  kind="primary"
                  size="medium"
                  className="mt-3 align-self-start"
                  disabled={!lang || helpers.isFetching}
                  onClick={() => helpers.handle(handleWayfairPdfDownload())}
                >
                  <span>Pobierz PDF</span>
                </Button>
              )}
            </StatusHandler>
          </div>
        </Modal>
      )}
    </>
  );
};

import { postExtraSlot } from "api/new-production-plans/calls";
import { ExtraSlotMethod } from "api/new-production-plans/enums";
import { productionPlansKeys } from "api/new-production-plans/keys";
import { AddExtraSlotPayload, ProductionPlan } from "api/new-production-plans/models";
import { UUID } from "api/types";
import { useMutation } from "hooks/useMutation";
import produce from "immer";
import { QueryClient } from "react-query";
import { getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Assign } from "utility-types";

export const useToggleExtraSlot = () => {
  const handleRollback = (prevPlan: ProductionPlan, queryClient: QueryClient) => {
    queryClient.setQueryData<ProductionPlan>(
      productionPlansKeys.productionPlanSchedule(prevPlan.id),
      currentPlan => {
        assertIsDefined(currentPlan);
        return prevPlan;
      },
    );
  };

  return useMutation(
    ({
      employee,
      method,
      workingDay,
    }: Assign<AddExtraSlotPayload, { employeeWorkingDayId: UUID; productionPlanId: UUID }>) => {
      return postExtraSlot({ employee, method, workingDay });
    },
    ({ queryClient, toastr }) => ({
      onMutate: args => {
        const prevPlan = queryClient.getQueryData<ProductionPlan>(
          productionPlansKeys.productionPlanSchedule(args.productionPlanId),
        );

        queryClient.setQueryData<ProductionPlan>(
          productionPlansKeys.productionPlanSchedule(args.productionPlanId),
          currentPlan => {
            assertIsDefined(currentPlan);
            return produce(currentPlan, draft => {
              const employeeToUpdate = draft.employees.find(
                employee => String(employee.id) === args.employee,
              );

              if (!employeeToUpdate) return;

              const workingDay = employeeToUpdate.employeeWorkingDays.find(
                employeeWorkingDay => employeeWorkingDay.id === args.employeeWorkingDayId,
              );

              if (!workingDay) return;

              if (args.method === ExtraSlotMethod.ADD) {
                workingDay.slots.extra += 1;
                return;
              }

              workingDay.slots.extra -= 1;
            });
          },
        );

        return { prevPlan };
      },
      onError: (error, _, context) => {
        const { prevPlan } = context as { prevPlan: ProductionPlan };
        handleRollback(prevPlan, queryClient);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

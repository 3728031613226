import { Modal } from "components/utils";
import { ToggleHookState } from "hooks";
import styles from "../PointsSection.module.css";
import { ModalContent } from "./ModalContent";

interface Props {
  modal: ToggleHookState;
}

export const EmailNotificationsModal = ({ modal: { close, isOpen } }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      close={close}
      size="auto"
      overrides={{ container: { className: styles.modal } }}
    >
      <ModalContent />
    </Modal>
  );
};

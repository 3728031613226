export const getTabs = (): { label: string; name: string }[] => {
  return [
    { label: "Nowe", name: "new" },
    { label: "Gotowe", name: "route-ready" },
    { label: "W drodze", name: "departed" },
    { label: "Zakończone", name: "finished" },
    { label: "Rozliczone", name: "settled" },
    { label: "Wszystkie", name: "all" },
  ];
};

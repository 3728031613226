import {
  InvoiceAdmission,
  InvoiceReviewer,
  PurchaseInvoiceToReview,
} from "api/trading-documents/models";
import cx from "classnames";
import { InitialsAvatar } from "components/utils/userAvatar/InitialsAvatar";
import closeIcon from "assets/images/close.svg";
import styles from "./ReviewerLabel.module.css";
import { Button } from "components/common";
import { ReviewerLabelIcon } from "./components/ReviewerLabelIcon";
import { useQuery, useSelector } from "hooks";
import { getAnyErrorKey, queryString } from "utilities";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { Pagination, UUID } from "api/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { QueryClient } from "react-query";
import { useMutation } from "hooks/useMutation";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { deleteReviewer } from "api/trading-documents/calls";

interface Props {
  isInvoiceAdmission?: boolean;
  reviewer: InvoiceReviewer;
  tradingDocument?: UUID;
}

export const ReviewerLabel = ({ isInvoiceAdmission, reviewer, tradingDocument }: Props) => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const pageSize = { pageSize: 1 };
  const search = queryString.stringify({ ...query, ...pageSize });
  const me = useSelector(store => store.auth.user!);

  const handleRollback = (context: unknown, queryClient: QueryClient) => {
    queryClient.setQueryData<Pagination<PurchaseInvoiceToReview>>(
      tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
      currentList => {
        assertIsDefined(currentList);
        return {
          ...currentList,
          // @ts-ignore
          results: context.results,
        };
      },
    );
  };

  const handleAdmissionRollback = (context: unknown, queryClient: QueryClient) => {
    if (tradingDocument) {
      queryClient.setQueryData<InvoiceAdmission>(
        tradingDocumentsKeys.invoiceAdmission.details(tradingDocument),
        currentAdmission => {
          assertIsDefined(currentAdmission);
          return {
            ...currentAdmission,
            // @ts-ignore
            reviewers: context.reviewers,
          };
        },
      );
    }
  };

  const deleteMutation = withDeleteConfirmation(
    useMutation(deleteReviewer, ({ queryClient, toastr }) => ({
      onMutate: id => {
        if (isInvoiceAdmission && tradingDocument) {
          const prevAdmission = queryClient.getQueryData(
            tradingDocumentsKeys.invoiceAdmission.details(tradingDocument),
          );

          queryClient.setQueryData<InvoiceAdmission>(
            tradingDocumentsKeys.invoiceAdmission.details(tradingDocument),
            currentAdmission => {
              assertIsDefined(currentAdmission);
              return {
                ...currentAdmission,
                reviewers: currentAdmission.reviewers.filter(reviewer => reviewer.id !== id),
              };
            },
          );

          return prevAdmission;
        } else {
          const prevList = queryClient.getQueryData(
            tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
          );

          queryClient.setQueryData<Pagination<PurchaseInvoiceToReview>>(
            tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
            currentList => {
              assertIsDefined(currentList);
              return {
                ...currentList,
                results: currentList.results.map((result, index) => {
                  if (index === 0) {
                    return {
                      ...result,
                      reviewers: result.reviewers.filter(reviewer => reviewer.id !== id),
                    };
                  }
                  return result;
                }),
              };
            },
          );

          return prevList;
        }
      },
      onSuccess: () => {
        if (isInvoiceAdmission && tradingDocument) {
          queryClient.invalidateQueries(
            tradingDocumentsKeys.invoiceAdmission.details(tradingDocument),
          );
        } else {
          queryClient.invalidateQueries(tradingDocumentsKeys.purchaseInvoicesToReview.list(search));
        }
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Pomyślnie usunięto recenzenta",
        });
      },
      onError: (error, _, context) => {
        if (isInvoiceAdmission && tradingDocument) {
          handleAdmissionRollback(context, queryClient);
        } else {
          handleRollback(context, queryClient);
        }
        toastr.open({
          type: "warning",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      },
    })),
  )();

  return (
    <div className={cx(styles.label, "justify-content-between")}>
      <div className="d-flex align-items-center gap-2">
        {reviewer.user.avatar && reviewer.user.avatar.length > 0 ? (
          <div className={cx(styles.avatar, styles.avatarSmall)}>
            <img alt="avatar recenzenta" src={reviewer.user.avatar} />
          </div>
        ) : (
          <InitialsAvatar
            className={cx(styles.avatar, styles.avatarSmall)}
            firstName={reviewer.user.firstName}
            lastName={reviewer.user.lastName}
            style={{ margin: 0, background: "rgb(0, 207, 145)" }}
          />
        )}
        <div className={cx(styles.name, "body-14-500")}>
          {reviewer.user.firstName}&nbsp;{reviewer.user.lastName}
        </div>
      </div>
      <ReviewerLabelIcon status={reviewer.status} />
      <Button
        disabled={reviewer.user.id === me.id}
        kind="create-transparent"
        onClick={() => deleteMutation.mutate(reviewer.id)}
        style={{ height: "26px", width: "26px" }}
      >
        <div className="btnBase btnBaseSmall">
          <img alt="Usuń" src={closeIcon} style={{ height: "16px", width: "16px" }} />
        </div>
      </Button>
    </div>
  );
};

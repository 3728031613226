import { PinToRouteAction } from "api/routes/enums";
import { queryString } from "utilities";

export const getPinToRouteSearch = (orderId: string, isPinned: boolean) => {
  return queryString.stringify({
    order: orderId,
    action: isPinned ? PinToRouteAction.PINNED : PinToRouteAction.UNPINNED,
    pageSize: isPinned ? "1" : "3",
  });
};

import styles from "./LocationFiltersBar.module.css";

interface Props {
  isChecked: boolean;
  updateCheckbox: (val: boolean) => void;
  img: string;
  name: string;
  title: string;
}

export const PriorityBtn = ({ isChecked, updateCheckbox, img, name, title }: Props) => {
  return (
    <label className={styles["version-box-option"]} title={title}>
      <input
        type="checkbox"
        name={name}
        checked={isChecked}
        onChange={e => updateCheckbox(e.target.checked)}
      />
      <img src={img} alt="" />
    </label>
  );
};

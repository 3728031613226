import carWhiteImg from "assets/images/24w.svg";
import carImg from "assets/images/24.svg";
// import flagWhiteImg from "assets/images/25w.svg";
// import flagImg from "assets/images/25.svg";
import billWhiteImg from "assets/images/26w.svg";
import billImg from "assets/images/26.svg";
import styles from "./OrderGroupStatus.module.css";
import cx from "classnames";

interface Props {
  status: "NEW" | "GROUP_READY" | "SETTLED";
}

export const OrderGroupStatus = ({ status }: Props) => {
  function statusToNumber() {
    switch (status) {
      case "NEW":
        return 1;
      case "GROUP_READY":
        return 2;
      case "SETTLED":
        return 3;
      default:
        throw new Error("Unexpected order group status: " + status);
    }
  }
  const statusNum = statusToNumber();
  return (
    <div className="d-flex">
      <div className={cx(styles.statusIcon, { [styles.active]: statusNum > 1 })}>
        <img className={cx(styles.img)} src={statusNum > 1 ? carWhiteImg : carImg} alt="" />
      </div>
      {/* <img src={flagImg} alt="" /> */}
      <div className={cx(styles.statusIcon, { [styles.active]: statusNum === 3 })}>
        <img className={styles.img} src={statusNum === 3 ? billWhiteImg : billImg} alt="" />
      </div>
    </div>
  );
};

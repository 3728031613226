import { RouteOrder } from "api/routes/models";
import { showCustomerLabel, showSalesAccountLabel } from "components/common";
import { StateLabel } from "components/common/stateLabel";
import { RightPanelTableRow } from "components/utils/drawer";
import { LinkToPage } from "components/utils/linkToPage";
import { useSelector } from "hooks";
import { routePointsSectionListConfig } from "pages/logistics/shared/utils/panelTablesConfig";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import localStyles from "./RoutePoints.module.css";
import cx from "classnames";
import { RoutePointDocuments } from "./components/RoutePointDocuments";

interface Props {
  index: number;
  order: RouteOrder;
}

export const RoutePoint = ({ index, order }: Props) => {
  const configuration = useSelector(store => store.partials.configuration);

  return (
    <RightPanelTableRow
      className={localStyles.tableRowRoutePoint}
      grid={routePointsSectionListConfig.grid}
    >
      <div className="fw-700">{index + 1}.</div>
      <div>
        <div className="body-14 fw-700 overflow">
          {order.customer
            ? order.client.companyName
            : `${order.client.firstName} ${order.client.lastName}`}
        </div>
        <div className="fw-700 overflow">
          {order.delivery.city}, {order.delivery.street} {order.delivery.postCode}
        </div>
      </div>
      <div>
        <LinkToPage
          content={<div className="fw-700 overflow">{order.signature}</div>}
          url={getOrderRedirectUrl(order)}
        />
        <div className="body-10">{order.name}</div>
      </div>
      <div className="d-flex flex-wrap gap-1">
        {order.salesAccount &&
          showSalesAccountLabel(
            configuration.customerSalesAccountLabelsInOrderList,
            order.customer,
            order.salesAccount,
          ) && (
            <StateLabel kind="default" style={{ backgroundColor: order.salesAccount.color }}>
              <div className="fw-700" style={{ color: order.salesAccount.textColor }}>
                {order.salesAccount.name}
              </div>
            </StateLabel>
          )}
        {order.salesAccount &&
          order.customer &&
          showCustomerLabel(
            configuration.customerSalesAccountLabelsInOrderList,
            order.customer,
            order.salesAccount,
          ) && (
            <StateLabel
              className={cx({
                "ml-2": showSalesAccountLabel(
                  configuration.customerSalesAccountLabelsInOrderList,
                  order.customer,
                  order.salesAccount,
                ),
              })}
              kind="default"
              style={{
                backgroundColor: order.customer.color,
              }}
            >
              <div className="fw-700" style={{ color: order.customer.textColor }}>
                {order.customer.name.toLowerCase()}
              </div>
            </StateLabel>
          )}
      </div>
      <div>BACKEND</div>
      <div>BACKEND</div>
      <div>BACK</div>
      <RoutePointDocuments order={order} />
    </RightPanelTableRow>
  );
};

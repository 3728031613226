import { Comment } from "components/common";
import commentIcon from "assets/images/6.svg";
import { useTrimmedArray } from "hooks";
import { CommentType } from "../comment";
import { StatusHandlerHelpers } from "components/utils";
import { FLAVOR } from "CONSTANTS";
import { Fragment } from "react";

interface Props {
  comments: CommentType[];
  onDelete?: (comment: CommentType) => void;
  onEdit?: (comment: CommentType, helpers: StatusHandlerHelpers) => Promise<void>;
}

const trim = 2;

function sortDateDesc(arr: CommentType[]) {
  return [...arr].sort((a, b) => (a.id > b.id ? 1 : -1));
}

export const Comments = ({ comments, onDelete, onEdit }: Props) => {
  const [trimmedComments, { toggle, areTrimmed }] = useTrimmedArray(sortDateDesc(comments), trim);
  return (
    <div>
      {comments.length > trim && areTrimmed && (
        <button className="btn-appear-link blue-link" type="button" onClick={toggle}>
          <img src={commentIcon} alt="" />
          <span className="ml-1">Wcześniejsze komentarze: +{comments.length - trim}</span>
        </button>
      )}
      {trimmedComments.map(comment => (
        <Fragment key={comment.id}>
          {FLAVOR === "b2b" && !comment.isOnlyVisibleForTeam ? (
            <Comment key={comment.id} comment={comment} onDelete={onDelete} onEdit={onEdit} />
          ) : null}
          {FLAVOR === "main" && (
            <Comment key={comment.id} comment={comment} onDelete={onDelete} onEdit={onEdit} />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export const yellow = {
  yellow12: "#F6C6021F",
  yellow25: "#FBF4D6",
  yellow32: "#F6C60252",
  yellow50: "#F6E8AD",
  yellow75: "#F4D96A",
  yellow100: "#F4CB24",
  yellow200: "#E8BF19",
  yellow300: "#CFA600",
  yellow400: "#BB8C15",
  yellow500: "#9A7109",
  yellow600: "#715200",
};

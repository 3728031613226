export function parseLocation(
  location: string | null,
): { prefix: string; name: string; code: string } {
  if (!location) return { prefix: "", name: "---", code: "" };
  if (!location.includes(".") && !location.includes(":"))
    return { prefix: "", name: location, code: "" };

  if (location.includes(":")) {
    const [prefix, restOfLocation] = location.split(":");
    if (restOfLocation.includes(".")) {
      const [name, code] = restOfLocation.split(".");
      return { prefix, name, code };
    }

    return { prefix, name: restOfLocation, code: "" };
  }

  if (location.includes(".")) {
    const [name, code] = location.split(".");
    return { prefix: "", name, code };
  }

  return { prefix: "", name: location, code: "" };
}

import { PurchaseInvoiceToReview } from "api/trading-documents/models";
import { RightPanelSection } from "components/utils/drawer";
import cx from "classnames";
import styles from "../../RightPanel.module.css";

interface Props {
  invoice: PurchaseInvoiceToReview;
}

export const TitleSection = ({ invoice }: Props) => {
  return (
    <RightPanelSection>
      <div className="d-flex flex-column justify-content-center align-items-start ">
        <div className={cx(styles.FVsignature, "fs-24")}> Faktura VAT {invoice.signature}</div>
        <div className="body-12 fw-700 text-color-grey">
          {invoice.description ? (
            invoice.description
          ) : (
            <div className={cx(styles.description, "font-italic")}>Brak opisu</div>
          )}
        </div>
      </div>
    </RightPanelSection>
  );
};

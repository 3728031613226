import { useTradingDocument } from "api/trading-documents/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { UUID } from "api/types";
import { PageHeader } from "components/common";
import { Loader } from "components/utils/loader";
import { useInfiniteLogs } from "hooks/useInfinityLogs";
import { InvoiceHistory } from "pages/tradingDocuments/shared/components/invoiceHistory/InvoiceHistory";
import { useLocation, useParams } from "react-router";

type LocationState = { salesInvoice: Pick<TradingDocument, "signature"> } | undefined;

export const SalesInvoiceHistory = () => {
  const params = useParams<{ salesInvoiceId: UUID }>();
  const location = useLocation<LocationState>();

  const locationSalesInvoice = location.state?.salesInvoice;
  const { data: fetchedInvoice } = useTradingDocument(
    { id: params.salesInvoiceId },
    { enabled: !Boolean(locationSalesInvoice) },
  );
  const salesInvoice = locationSalesInvoice ?? fetchedInvoice;

  const { logs, fetchMore, inProgress, hasMore } = useInfiniteLogs(
    params.salesInvoiceId,
    "tradingDocument",
  );

  const subtitle = salesInvoice?.signature ?? "";

  return (
    <div className="position-relative">
      {inProgress && <Loader module="finances" />}
      <PageHeader
        searchBar={false}
        subtitle={subtitle}
        title="Historia faktury sprzedażowej"
        viewLabel="SALES_INVOICE_HISTORY"
      />
      <InvoiceHistory fetchMore={fetchMore} hasMore={hasMore} inProgress={inProgress} logs={logs} />
    </div>
  );
};

export function getLabelColor(leftDays: number, type: "STANDARD" | "COMPLAINT") {
  const days = type === "STANDARD" ? leftDays : (leftDays - 14) * -1;

  if (days > 14) {
    return "blue";
  }
  if (days >= 7) {
    return "blue";
  }
  if (days >= 2) {
    return "green";
  }
  if (days >= 0) {
    return "yellow";
  }
  if (days >= -7) {
    return "orange";
  }
  if (days >= -14) {
    return "pink";
  } else {
    return "dark-red";
  }
}

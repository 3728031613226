import { TradingDocument } from "api/trading-documents/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";

import { RightPanelSection } from "components/utils/drawer";

interface Props {
  tradingDocument: TradingDocument;
}

export const SellerInfoSection = ({ tradingDocument }: Props) => {
  return (
    <RightPanelSection title="Sprzedawca">
      <div className="pb-3">
        {tradingDocument.sellerCompanyName && (
          <Typography className="pb-1" fontSize="18" fontWeight="700">
            {tradingDocument.sellerCompanyName}
          </Typography>
        )}
        {tradingDocument.sellerStreet && (
          <Typography className="pb-1" fontSize="14" fontWeight="500">
            {tradingDocument.sellerStreet}
          </Typography>
        )}
        {(tradingDocument.sellerPostCode || tradingDocument.sellerCity) && (
          <Typography className="pb-1" fontSize="14" fontWeight="500">
            {tradingDocument.sellerPostCode}&nbsp;{tradingDocument.sellerCity}
          </Typography>
        )}
        {tradingDocument.sellerBankAccountNumber && (
          <Typography className="pb-1" fontSize="14" fontWeight="500">
            {tradingDocument.sellerBankAccountNumber}
          </Typography>
        )}
        {tradingDocument.sellerTaxId && (
          <Typography className="pb-1" fontSize="14" fontWeight="500">
            NIP:&nbsp;{tradingDocument.sellerTaxId}
          </Typography>
        )}
      </div>
    </RightPanelSection>
  );
};

import { AxiosError } from "axios";
import { useQueryUtils, useToastr } from "hooks";
import {
  QueryClient,
  useMutation as useNativeMutation,
  UseMutationOptions,
  useQueryClient,
} from "react-query";
import { InferArgsType, PromiseThen } from "typeUtilities";
import { Assign } from "utility-types";
import { getAnyErrorKey } from "./utilities";

type QueryFetchError = Record<string, string> & Pick<AxiosError, "response">;

type GenericErrorToastr = (error: QueryFetchError) => void;

type Utils = Assign<
  ReturnType<typeof useQueryUtils>,
  { toastr: ReturnType<typeof useToastr>; genericErrorToastr: GenericErrorToastr }
>;

export function createMutationHook<T extends (...args: any[]) => Promise<any>, U extends unknown>(
  mutationFn: T,
  options: (
    qc: QueryClient,
    utils: Utils,
  ) => UseMutationOptions<
    PromiseThen<ReturnType<T>>,
    QueryFetchError,
    InferArgsType<T>,
    U
  > = () => ({}),
) {
  return (
    optionsOverrides?: UseMutationOptions<
      PromiseThen<ReturnType<T>>,
      QueryFetchError,
      InferArgsType<T>,
      U
    >,
  ) => {
    const queryClient = useQueryClient();
    const utils = useQueryUtils();
    const toastr = useToastr();

    const genericErrorToastr: GenericErrorToastr = error => {
      if (error.response?.status === 500) {
        toastr.open({
          type: "failure",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      } else {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      }
    };

    const mutation = useNativeMutation<
      PromiseThen<ReturnType<T>>,
      QueryFetchError,
      InferArgsType<T>,
      U
    >(args => mutationFn(...args), {
      ...options(queryClient, { ...utils, toastr, genericErrorToastr }),
      ...optionsOverrides,
    });

    return mutation;
  };
}

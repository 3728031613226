import { Checkbox, Select } from "components/utils";
import { useSelector, useQuery } from "hooks";

interface Props {
  filterByUser: boolean;
  filter: string;
  label: string;
  showNotAssignedOption?: boolean;
}

export const SubToolbar = ({
  filterByUser,
  filter,
  label,
  showNotAssignedOption = false,
}: Props) => {
  const currentFilterValue = useQuery().query[filter];
  const { updateQuery } = useQuery();

  const allUsers = useSelector(store => store.partials.users);
  const users = allUsers.filter(user => !user.hasCustomer);
  const me = useSelector(store => store.auth.user!);
  const selectedUser = currentFilterValue ? Number(currentFilterValue) : me.id;

  const usersList = (() => {
    const usersMap = users
      .filter(el => el.id !== me.id && el.isDeleted === false)
      .map(el => Object.assign({ name: `${el.firstName} ${el.lastName}` }, el));

    if (showNotAssignedOption) {
      return [{ id: me.id, name: label }, { id: 0, name: "Nie przypisane" }, ...usersMap];
    } else {
      return [{ id: me.id, name: label }, ...usersMap];
    }
  })();

  return (
    <div className="d-flex align-items-center border-bottom py-1 px-3">
      <Checkbox
        name=""
        checked={filterByUser}
        onChange={checked =>
          updateQuery({ filterByUser: checked, [filter]: selectedUser, page: 1 })
        }
      />
      <div className="mr-2">Pokaż tylko</div>
      <Select
        onChange={user => {
          if (user) {
            updateQuery({ filterByUser: true, [filter]: user.id, page: 1 });
          }
        }}
        selectedItem={selectedUser}
        items={usersList}
        size="small"
      />
    </div>
  );
};

import { Header as CommonHeader, Toolbar } from "components/common";
import listEmptyIcon from "assets/images/el2.png";
import { Cell, CommonError, Label, List, Pagination, Select, Spinner } from "components/utils";
import arrowIcon from "assets/images/526w.svg";
import { useBankAccountTransactions } from "api/bank-accounts/hooks";
import { NoContent } from "components/utils/noContent";
import styles from "./BankAccountTransactions.module.css";
import cx from "classnames";
import { CURRENCY } from "CONSTANTS";
import { useQuery, useQueryUtils, useSelector } from "hooks";
import { createDateRanges, dateFns, queryString } from "utilities";
import { useState } from "react";
import { useMutation } from "hooks/useMutation";
import { patchBankTransaction } from "api/bank-accounts/calls";
import { RouteComponentProps, useHistory } from "react-router";
import {
  balanceToLabelDict,
  balanceFilterDict,
  transferTypeFilterDict,
  kindFilterDict,
} from "../shared";

type Tab = "in" | "out";

type GetSearchProps = {
  tab: Tab;
  query: {
    [x: string]: string;
  };
};

const getSearch = ({ query, tab }: GetSearchProps): string => {
  const tabsQuery = {
    kind: tab === "in" ? "IN" : "OUT",
  };
  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

export const BankAccountTransactions = ({ match }: RouteComponentProps<{ tab: Tab }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const { data, pagination, isLoading, error, key } = useBankAccountTransactions(search);
  const history = useHistory();
  const { handlePaginatedListUpdate, rollbackList } = useQueryUtils();

  const patchMutation = useMutation(patchBankTransaction, {
    onMutate: ({ id, ...args }) => {
      return handlePaginatedListUpdate(key, id, { ...args });
    },
    onError: (error, { id }, prevList) => {
      rollbackList(key, prevList as any, id, error);
    },
  });

  if (error) {
    return (
      <div className="h-100">
        <Header />
        <CommonError status={error._httpStatus_} />
      </div>
    );
  }

  if (!data || isLoading) {
    return (
      <div className="h-100">
        <Header />
        <div className="d-flex align-items-center justify-content-center flex-1 h-100">
          <Spinner color="blue" size="big" text="trwa wczytywanie" position="absolute" />
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div>
        <Header />
        <NoContent header="Lista nie zawiera elementów" img={listEmptyIcon} />;
      </div>
    );
  }

  const isPatchMutationLoading = (id: number) => {
    return patchMutation.isLoading && (patchMutation.context as { id: number })?.id === id;
  };

  return (
    <div className="position-relative">
      <Header />

      <List variant="compact">
        {data.map(transaction => {
          return (
            <div
              key={transaction.id}
              className={cx(styles.transaction, {
                "bg-white": !transaction.isSeen,
              })}
              onClick={() => {
                if (transaction.isSeen) {
                  history.push(`/bank-accounts/transactions/details/${transaction.id}`);
                  return;
                }
                patchMutation.mutate(
                  { id: transaction.id, isSeen: true },
                  {
                    onSuccess: () => {
                      history.push(`/bank-accounts/transactions/details/${transaction.id}`);
                    },
                  },
                );
              }}
            >
              <Spinner
                on={isPatchMutationLoading(transaction.id)}
                style={{ height: "90%", width: "92%" }}
                color="blue"
                size="small"
                position="absolute"
              />
              <Cell className={cx(styles.nameCell, { [styles.seen]: transaction.isSeen })}>
                <div className="d-flex align-items-center mb-1">
                  <div className="fs-18 text-color-black mr-2">{transaction.externalId}</div>
                  <Label color={transaction.kind === "OUT" ? "yellow" : "blue"}>
                    <img
                      src={arrowIcon}
                      alt="logo"
                      className={cx("py-1 d-flex align-items-center justify-content-center", {
                        [styles.rotateArrow]: transaction.kind === "OUT",
                      })}
                    />
                  </Label>
                  <i className="divider ml-2 mr-1"></i>
                  <span className="fs-12 text-color-grey italic">
                    {transaction.bookingDate &&
                      dateFns.format(new Date(transaction.bookingDate), "dd.MM.yyyy")}
                  </span>
                </div>
                <span className={cx(styles.bankAccount, "d-flex align-items-center mb-1")}>
                  <img className="mr-2" src={transaction.bankAccount.logo} alt="logo" />
                  <span className="fs-15 text-color-black">{transaction.bankAccount.name}</span>
                  <i className="divider"></i>
                  <span className="fs-15 text-color-black">{transaction.bankAccount.iban}</span>
                </span>
                <span className="fs-15 text-color-black">{transaction.name}</span>
              </Cell>

              <Cell className="justify-content-start">
                {transaction.orders.slice(0, 3).map(signature => (
                  <div key={signature} className="fs-12 text-color-black mr-2">
                    {signature}
                  </div>
                ))}
                {transaction.orders.length > 3 && (
                  <Label color="light-grey" className={styles.moreLabel}>
                    <span className="text-color-purple fs-12">
                      +{transaction.orders.length - 3}
                    </span>
                  </Label>
                )}
              </Cell>
              <Cell>
                <div onClick={e => e.stopPropagation()}>
                  <Select
                    buttonClassName={styles.selectBtn}
                    onChange={value => {
                      patchMutation.mutate({
                        id: transaction.id,
                        transferType: value?.id,
                      });
                    }}
                    selectedItem={transaction.transferType}
                    items={Object.values(transferTypeFilterDict).map(({ label, value }) => ({
                      id: value,
                      name: label,
                    }))}
                    size="small"
                    placeholder="Wybierz..."
                  />
                </div>
              </Cell>
              <Cell className="justify-content-end">
                <div>
                  <span className="fs-18 text-color-black mr-1">{transaction.amount}</span>
                  <span className="fs-12 text-color-grey">{transaction.currency}</span>
                </div>
                <div className={styles.status}>
                  {balanceToLabelDict()[transaction.summary.balance]}
                </div>
              </Cell>
            </div>
          );
        })}
        <Pagination pagination={pagination} />
      </List>
    </div>
  );
};

const Header = () => {
  const banks = useSelector(state => state.partials.banks);

  const [dateRanges] = useState(() => createDateRanges());
  const currencyList = CURRENCY.map(({ id, name }) => ({ label: name, value: id }));
  const bankList = banks.map(({ id, name }) => ({ label: name, value: String(id) }));

  return (
    <div className={styles.headerWrapper}>
      <CommonHeader
        tabs={[
          { label: "Przychodzące", name: "in" },
          { label: "Wychodzące", name: "out" },
        ]}
        title={({ styles: s }) => (
          <div className="d-flex align-items-center">
            <h1 className={s.title}>Lista transakcji</h1>
          </div>
        )}
        routeRoot="bank-accounts/transactions"
      />
      <Toolbar
        searchInput
        filters={[
          {
            name: "ordering",
            label: "sortuj",
            type: "string",
            options: [
              { label: "Wszystkie", value: "" },
              { label: "Od najstarszych", value: "id" },
              { label: "Od najnowszych", value: "-id" },
              { label: "Data utworzenia od najstarszych", value: "bookingDate" },
              { label: "Data utworzenia od najnowszych", value: "-bookingDate" },
              { label: "Data wpływu od najstarszych", value: "valueDate" },
              { label: "Data wpływu od najnowszych", value: "-valueDate" },
            ],
            default: "-id",
          },
          {
            name: "bank",
            label: "bank",
            type: "string",
            options: [{ label: "Wszystkie", value: "" }, ...bankList],
            default: "",
          },
          {
            name: "currency",
            label: "waluta",
            type: "string",
            keepTextFormatting: true,
            options: [{ label: "Wszystkie", value: "" }, ...currencyList],
            default: "",
          },
          {
            name: "balance",
            label: "status",
            type: "string",
            options: [{ label: "Wszystkie", value: "" }, ...Object.values(balanceFilterDict)],
            default: "",
          },
          {
            name: "kind",
            label: "kierunek przelewu",
            type: "string",
            options: [{ label: "Wszystkie", value: "" }, ...Object.values(kindFilterDict)],
            default: "",
          },
          {
            name: "transferType",
            label: "typ",
            type: "string",
            options: [{ label: "Wszystkie", value: "" }, ...Object.values(transferTypeFilterDict)],
            default: "",
          },
          {
            name: "createdDate",
            range: ["minBookingDate", "maxBookingDate"],
            label: "data",
            type: "range",
            options: [
              { label: "Wszystkie", value: ["", ""] },
              { label: "Ten tydzień", value: dateRanges.thisWeek },
              { label: "Ten miesiąc", value: dateRanges.thisMonth },
              { label: "Ten rok", value: dateRanges.thisYear },
            ],
            default: ["", ""],
          },
        ]}
      />
    </div>
  );
};

import { Status } from "api/orders/models";
import completingIcon from "assets/images/21.svg";
import pinIcon from "assets/images/22.svg";
import packingIcon from "assets/images/23.svg";
import transportIcon from "assets/images/24.svg";
import deliveryIcon from "assets/images/25.svg";
import settledIcon from "assets/images/26.svg";

const statusesDictionary: { [key: string]: { img: string; name: string } } = {
  0: { img: completingIcon, name: "Nowe" },
  1: { img: pinIcon, name: "Przypięte do trasy" },
  2: { img: packingIcon, name: "Gotowe" },
  3: { img: transportIcon, name: "W trasie" },
  4: { img: deliveryIcon, name: "Dostarczono" },
  5: { img: settledIcon, name: "Rozliczono" },
  6: { img: "", name: "Anulowano" },
};

export const getOrderStatus = (status: Status) => {
  if (status === "RECEIVED") return statusesDictionary[0];
  if (status === "PROCESSING") return statusesDictionary[1];
  if (status === "ROUTE_READY") return statusesDictionary[2];
  if (status === "ON_ROUTE") return statusesDictionary[3];
  if (status === "DELIVERED") return statusesDictionary[4];
  if (status === "SETTLED") return statusesDictionary[5];
  if (status === "CANCELED") return statusesDictionary[6];
  throw new Error("Unknown order status: " + status);
};

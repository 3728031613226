import { WarehouseWorkerDetails } from "api/wms/models";
import { useWarehouseWorkerFilters } from "../../hooks/useWarehouseWorkerFilters";
import { useGenericTableFilters } from "components/common/genericTable/useGenericTableFilters";
import { dateFns, queryString } from "utilities";
import { useDailySummaryReceivedAndReleased } from "api/wms/hooks";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./DailySummaryReceivedAndReleased.module.css";
import cx from "classnames";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useDailySummaryReceivedAndReleasedColumns } from "./useDailySummaryReceivedAndReleasedColumns";
import { parseTableRowData } from "components/common/genericTable/utils";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";

interface Props {
  close: () => void;
  warehouseWorker: WarehouseWorkerDetails;
}

export const DailySummaryReceivedAndReleased = ({ close, warehouseWorker }: Props) => {
  const warehouseWorkerFilters = useWarehouseWorkerFilters(warehouseWorker);
  const { filters } = useGenericTableFilters();
  const search = queryString.stringify({ ...filters, ...warehouseWorkerFilters.filters });
  const {
    data: dailySummary,
    error,
    isLoading,
    isFetching,
  } = useDailySummaryReceivedAndReleased(search, { keepPreviousData: true });

  const columns = useDailySummaryReceivedAndReleasedColumns(dailySummary, filters);
  const normalizedRows = parseTableRowData(dailySummary);

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Zestawienie dzienne: {dateFns.format(new Date(filters.dateFrom), "dd.MM.yyyy")}{" "}
          {filters.dateFrom !== filters.dateTo && (
            <>- {dateFns.format(new Date(filters.dateTo), "dd.MM.yyyy")}</>
          )}
        </Typography>
      }
      width={750}
    >
      <div
        className={cx(
          styles.tableWrapper,
          "d-flex flex-column flex-1 overflow-hidden justify-content-between",
        )}
      >
        <Table
          rows={normalizedRows}
          // @ts-ignore
          columns={columns}
          error={error}
          isLoading={isLoading || isFetching}
          uiSchema={comfortableListUiSchema}
        />
      </div>
    </Modal>
  );
};

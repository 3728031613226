import { ManufacturingSchema, StageSchemaAssignment } from "api/manufacturingNew/models";
import { RightPanelSection, RightPanelTableRow } from "components/utils/drawer";
import { stagesListConfig } from "../utils/panelTablesConfig";
import { Button } from "components/common";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { QueryClient } from "react-query";
import { Stage } from "./Stage";
import { useConfirmModal, useQueryUtils } from "hooks";
import { useMutation } from "hooks/useMutation";
import {
  deleteManufacturingStageSchemaAssignment,
  patchManufacturingStageSchemaAssignment,
} from "api/manufacturingNew/calls";
import { ToggleHookState } from "hooks";
import produce from "immer";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";

interface Props {
  manufacturingSchema: ManufacturingSchema;
  assignStagesToSchemaModal: ToggleHookState;
  queryClient: QueryClient;
  panelKey: string[];
}
export const Stages = ({
  manufacturingSchema,
  assignStagesToSchemaModal,
  queryClient,
  panelKey,
}: Props) => {
  const confirmModal = useConfirmModal();
  const { handleMutate, handlePaginatedListUpdate, rollback, rollbackList } = useQueryUtils();

  const patchMutation = useMutation(patchManufacturingStageSchemaAssignment, {
    onMutate: toUpdate => {
      const prevPanel = handleMutate(panelKey, { stages: toUpdate.newOrdersPositions });
      const prevList = handlePaginatedListUpdate(
        manufacturingNewKeys.manufacturingSchemas(),
        manufacturingSchema.id,
        { stages: toUpdate.newOrdersPositions },
      );
      return { prevList, prevPanel };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(panelKey);
    },
    onError: (error, toUpdate, context) => {
      const { prevList, prevPanel } = context as {
        prevList: {
          id: string | number;
        } | null;
        prevPanel: {
          stages: StageSchemaAssignment[];
        };
      };
      if (context) {
        rollback(panelKey, prevPanel, error);
        rollbackList(manufacturingNewKeys.manufacturingSchemas(), prevList, toUpdate.id);
      }
    },
  });

  const deleteMutation = useMutation(deleteManufacturingStageSchemaAssignment, {
    onSuccess: () => {
      queryClient.invalidateQueries(manufacturingNewKeys.manufacturingSchemas());
      queryClient.invalidateQueries(panelKey);
    },
  });

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newOrdersPositions = produce(manufacturingSchema.stages, draft => {
      const [removed] = draft.splice(result.source.index, 1);
      draft.splice(result.destination!.index, 0, removed);
    });

    if (result.source && result.destination.droppableId !== result.draggableId) {
      const schema = queryClient.getQueryData<ManufacturingSchema>(panelKey);
      const draggedSchema = schema?.stages.find(stage => String(stage.id) === result?.draggableId);

      if (draggedSchema) {
        patchMutation.mutate({
          id: draggedSchema.id,
          newOrdersPositions: newOrdersPositions,
          data: {
            position: result.destination.index,
          },
        });
      }
    }
  };

  return (
    <RightPanelSection title="Etapy">
      <div className="pb-3">
        <RightPanelTableRow hover={false} grid={stagesListConfig.grid}>
          <div>#</div>
          <div>etap</div>
          <div></div>
          <div className="d-flex justify-content-center">kolor</div>
          <div>wykonanie</div>
          <div>kod</div>
          <div>poprzedni etap</div>
          <div>dodano</div>
          <div>przez</div>
        </RightPanelTableRow>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="stages">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {manufacturingSchema.stages.map((stage, index) => (
                  <Draggable key={stage.id} draggableId={String(stage.id)} index={index}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Stage
                          confirmModal={confirmModal}
                          index={index}
                          stage={stage}
                          deleteMutation={deleteMutation}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Button size="btnSizeMedium" onClick={assignStagesToSchemaModal.open} kind="create">
        <div className="btnBase btnBaseSmall">
          <img alt="Dodaj" src={darkPlusIcon} />
          Dodaj etap do schematu
        </div>
      </Button>
    </RightPanelSection>
  );
};

import { OrderPositionForCreatingInvoice } from "api/trading-documents/models";
import { Assign } from "utility-types";
import cx from "classnames";
import styles from "../CreateCorrectionManuallyModal.module.css";

interface Props {
  position: Assign<OrderPositionForCreatingInvoice, { isPositionEdited: boolean }> | any;
}

export const IsEditedLabel = ({ position }: Props) => {
  if (position.isPositionEdited) {
    return <div className={cx(styles.helperText, "text-black-6")}>(edytowano)</div>;
  }

  return null;
};

import { Header } from "./components/Header";
import styles from "./Dashboard.module.css";
import cx from "classnames";

export const Dashboard = () => {
  return (
    <div className="position-relative">
      <Header />
      <div className={styles.mainContent}>
        <div className={cx(styles.mainContentInner, "flex-wrap")}>
          <div className={cx(styles.scrollableContent, "d-flex flex-wrap w-100 pt-3")}></div>
        </div>
      </div>
    </div>
  );
};

import { parse } from "date-fns";

export const isDeliveredDateInCalculatedDeliveryRange = (
  max: string,
  deliveredDate: string,
): boolean => {
  const maxTime = parse(max, "H:mm", new Date());
  const deliveredTime = new Date(deliveredDate);

  return deliveredTime < maxTime;
};

import * as React from "react";
import styles from "./List.module.css";
import cx from "classnames";

// INTERFACES //

interface ListProps {
  className?: string;
  children: React.ReactNode;
  variant?: "compact";
  style?: React.CSSProperties;
  overwrites?: { tableBody?: { style?: React.CSSProperties } };
}
interface BasicProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
  onClick?: (event: React.SyntheticEvent) => void;
}

// COMPONENTS //

export const ListWrapper = ({ children, className = "", ...rest }: BasicProps) => {
  return (
    <div className={cx(className, styles.listWrapper)} {...rest}>
      {children}
    </div>
  );
};

export const List = ({
  variant,
  children,
  className = "",
  style = {},
  overwrites = {},
}: ListProps) => {
  const extraClassNames = {
    [styles.compactView]: variant === "compact",
  };

  return (
    <div className={cx(className, styles.list)} style={style}>
      <div
        className={cx(styles.tableBody, extraClassNames)}
        style={overwrites.tableBody?.style ?? undefined}
      >
        {children}
      </div>
    </div>
  );
};

export const Row = ({ children, className = "", ...rest }: BasicProps) => {
  return (
    <div className={cx(className, styles.tableRow)} {...rest}>
      {children}
    </div>
  );
};

export const Cell = ({ children, className = "", onClick }: BasicProps) => {
  return (
    <div className={cx(className, styles.tableCell)} onClick={onClick}>
      {children}
    </div>
  );
};

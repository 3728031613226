import { Route } from "components/utils";
import { RouteOrdersList } from "pages/callCenter/routeOrdersList/RouteOrdersList";
import { NotFound } from "pages/notFound";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const CallCenterRoute = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={RouteOrdersList}
        exact={true}
        path={`${match.path}/:routeId/orders`}
        shouldReRenderTitle={false}
        title="Milo - BOK - zamówienia trasy"
      />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};

import { LinkLinePackage } from "api/trading-documents/models";

export const getMissingAmountForPackages = (
  sumAmountWithoutTax: number,
  packages: LinkLinePackage[],
): number => {
  return (
    Number(sumAmountWithoutTax) -
    packages.reduce((sum, _package) => {
      return sum + _package.quantity * _package.amount;
    }, 0)
  );
};

import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx, dateFns } from "utilities";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "../RightPanel.module.css";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Address, NumberOfPickedUpPackages, PickingLineItem, PickingStatus } from "api/wms/models";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { ListAvatar } from "pages/wms/shared/components/ListAvatar";
import { useDownloadPickingLineItemPdf } from "./useDownloadPickingLineItemPdf";
import { UserWithInitials } from "api/users/models";
import { countryToFlagDict } from "constants/countriesFlags";
import { ISODateTime } from "api/types";

export const useLineItemsColumns = () => {
  const handleDownloadPickingLineItemPdf = useDownloadPickingLineItemPdf();

  return useCreateTableColumns<PickingLineItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "sygnatura",
        size: 115,
        cell: info => {
          const lineItem: PickingLineItem = info.getValue();
          return (
            <Typography
              className={cx({
                [styles.cancelledSignature]: lineItem.isCancelled,
              })}
              fontSize="12"
              fontWeight="700"
              noWrap
            >
              {Boolean(lineItem.signature.length) ? `${lineItem.signature}` : "---"}
            </Typography>
          );
        },
      }),
      columnHelper.text(row => row.sourceExternalId, {
        header: "nr zewnętrzny",
        size: 80,
      }),
      columnHelper.accessor(row => row.status, {
        header: "",
        id: "status",
        size: 50,
        cell: info => {
          const status: PickingStatus = info.getValue();
          if (status === PickingStatus.FINISHED) {
            return <Tag startIcon={MdiCheck} label="OK" variant="success" />;
          }
          if (status === PickingStatus.IN_PROGRESS) {
            return <Tag label="trwa" variant="warning" />;
          }
          return null;
        },
      }),
      columnHelper.text(
        row =>
          row.buyer.firstName || row.buyer.lastName
            ? `${row.buyer.firstName} ${row.buyer.lastName}`
            : null,
        {
          header: "klient",
          size: 105,
        },
      ),
      columnHelper.accessor(row => row.numberOfPickedUp, {
        header: "towar",
        size: 40,
        cell: info => {
          const numberOfPackageGroups: NumberOfPickedUpPackages = info.getValue();
          return (
            <div className="d-flex align-items-center">
              <Typography color="success500" fontSize="14" fontWeight="700">
                {numberOfPackageGroups.onRamp}
              </Typography>
              <Typography color="neutralBlack48" fontSize="14" fontWeight="700">
                /{numberOfPackageGroups.total}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.address, {
        header: "kraj wysyłki",
        size: 62,
        cell: info => {
          const address: Address = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1">
              {address.countryCode && (
                <img alt="kraj" src={countryToFlagDict[address.countryCode]} />
              )}
              <Typography fontSize="10" fontWeight="700">
                {address.countryCode ?? "---"}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.onRampAt, {
        id: "inStockAt",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            odłożono
          </Typography>
        ),
        size: 90,
        cell: info => {
          const date: ISODateTime | null = info.getValue();
          if (!date) return <EmptyValue className="text-right w-100" />;
          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {dateFns.formatRelative(new Date(date), "dd.MM.yyyy, H:mm")}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.collaborators, {
        header: "kto?",
        size: 45,
        cell: info => {
          const collaborators: UserWithInitials[] = info.getValue();
          return <ListAvatar collaborators={collaborators} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "",
        id: "labels",
        size: 70,
        cell: info => {
          const lineItem: PickingLineItem = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1">
              <IconButton
                icon={<MdiQrCode size="16" />}
                onClick={event => {
                  event.stopPropagation();
                  handleDownloadPickingLineItemPdf(
                    lineItem.id,
                    lineItem.picking,
                    lineItem.signature,
                  );
                }}
                theme="dark"
                variant="transparent"
              />
              {lineItem.isCancelled ? (
                <Button
                  onClick={event => event.stopPropagation()}
                  size="small"
                  variant="transparent"
                >
                  Przywróć
                </Button>
              ) : (
                <Button
                  onClick={event => event.stopPropagation()}
                  size="small"
                  variant="transparent"
                >
                  Anuluj
                </Button>
              )}
            </div>
          );
        },
      }),
    ];
  });
};

import styles from "../ModuleList.module.css";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  className?: string;
  gridStyle?: string;
  onClick?: (event: React.SyntheticEvent) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const ModuleRow = ({
  children,
  className = "",
  gridStyle = "",
  onClick,
  onMouseDown,
}: Props) => {
  return (
    <div
      className={cx(className, styles.tableRow)}
      onClick={onClick}
      onMouseDown={onMouseDown}
      style={{ gridTemplateColumns: gridStyle }}
    >
      {children}
    </div>
  );
};

import { Table } from "components/miloDesignSystem/molecules/table";
import { usePiecesColumns } from "./usePiecesColumns";
import { Shipment, ShippingPiece } from "api/shipping/models";
import { shippingActions } from "api/shipping/actions";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Switch } from "components/miloDesignSystem/atoms/switch";
import styles from "../../RightPanel.module.css";
import { cx } from "utilities";
import { ErrorType } from "hooks/createApiQuery";
import { CommonError } from "components/utils";
import { memo, useMemo } from "react";
import { EmptySection } from "components/common/emptySection/EmptySection";
import { useFilters } from "hooks/useFilters";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { ShippingService } from "constants/shippingService";
import { MdiPackage2 } from "components/miloDesignSystem/atoms/icons/MdiPackage2";

interface Props {
  shipment: Shipment;
}

interface Package {
  id: number;
  name: string;
  packages: ShippingPiece[];
}

export const PiecesSection = ({ shipment }: Props) => {
  const { searchParams } = useFilters({ shipment: shipment.id });
  const { data: rows, isLoading, error } = shippingActions.useShippingPieces(searchParams);

  const patchShippingPiece = shippingActions.usePatchMultipleShippingPieces();

  const noResults = !rows.length && !isLoading && !error;
  const groupedRowsByProduct = useMemo(() => {
    return rows.reduce<Record<number, Package>>((acc, row) => {
      if (!acc[row.productId]) {
        Object.assign(acc, {
          [row.productId]: { id: row.productId, name: row.productName, packages: [row] },
        });
        return acc;
      }

      acc[row.productId].packages.push(row);

      return acc;
    }, {});
  }, [rows]);

  if (noResults) {
    return (
      <div className="mb-2">
        <EmptySection label="Brak paczek w przesyłce" />
      </div>
    );
  }

  if (error)
    return (
      <div className="mt-4">
        <CommonError status={error._httpStatus_} />
      </div>
    );

  return (
    <div className="px-3 pt-1 pb-3 flex-1">
      <div className="d-flex align-items-center justify-content-between">
        <Typography fontSize="14" fontWeight="600" color="neutralBlack48" className="mb-2">
          W przesyłce
        </Typography>
        {shipment.shippingService?.provider === ShippingService.GLS &&
          Boolean(rows.some(row => row.trackingNumber)) && (
            <div>
              <Button
                className="text-uppercase"
                onClick={() => {
                  window.open(
                    `https://gls-group.com/app/service/open/rest/PL/pl/rstt029?match=${rows
                      .map(row => row.trackingNumber)
                      .toString()}`,
                    "_blank",
                  );
                }}
                size="small"
                startIcon={MdiPackage2}
                variant="gray"
              >
                Śledź przesyłkę
              </Button>
            </div>
          )}
      </div>

      {Object.values(groupedRowsByProduct)?.map(product => (
        <div key={product.id}>
          <div
            className={cx(
              "d-flex align-items-center justify-content-between mb-1",
              styles.piecesSectionMargin,
            )}
          >
            <Typography
              fontSize="14"
              fontWeight="700"
              color={
                product.packages.every(({ isIncludedForSend }) => !isIncludedForSend)
                  ? "neutralBlack48"
                  : "neutralBlack100"
              }
              className={cx({
                "line-through": product.packages.every(
                  ({ isIncludedForSend }) => !isIncludedForSend,
                ),
              })}
            >
              {product.name}
            </Typography>
            <Switch
              checked={product.packages.every(({ isIncludedForSend }) => !isIncludedForSend)}
              onChange={value => {
                patchShippingPiece.mutate({
                  isIncludedForSend: !value,
                  product: product.id,
                  shipment: shipment.id,
                });
              }}
              label="Usuń z przesyłki"
            />
          </div>
          <ShippingPieceTable
            shippingPieces={product.packages}
            error={error}
            isLoading={isLoading}
          />
          <PostShippingPieceButton shipment={shipment} product={product} />
        </div>
      ))}
    </div>
  );
};

const ShippingPieceTable = memo(
  ({
    error,
    isLoading,
    shippingPieces,
  }: {
    isLoading: boolean;
    error: ErrorType | null;
    shippingPieces: ShippingPiece[];
  }) => {
    const columns = usePiecesColumns();
    return (
      <Table<ShippingPiece>
        overrides={() => ({
          tableInnerWrapper: { className: "overflow-visible" },
          table: { className: "overflow-visible" },
        })}
        rows={shippingPieces}
        columns={columns}
        isLoading={isLoading}
        error={error}
        uiSchema={{
          header: {
            backgroundColor: "neutralWhite100",
          },
          cell: {
            height: "38",
          },
        }}
      />
    );
  },
);

const PostShippingPieceButton = ({
  product,
  shipment,
}: {
  shipment: Shipment;
  product: Package;
}) => {
  const postShippingPiece = shippingActions.usePostShippingPiece();
  return (
    <Button
      size="small"
      variant="gray"
      isLoading={postShippingPiece.isLoading}
      disabled={postShippingPiece.isLoading}
      startIcon={MdiAdd}
      className="text-uppercase mt-1"
      onClick={() => {
        postShippingPiece.mutate({
          shipment: shipment.id,
          productId: product.id,
          productName: product.name,
        });
      }}
    >
      Dodaj paczkę
    </Button>
  );
};

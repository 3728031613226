import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import homeIcon from "assets/images/homeLight.svg";
import { CallCenterDashboard } from "routes/CallCenterDashboard";

export const dashboard: ModuleLink = {
  url: "dashboard",
  label: "Pulpit",
  icon: homeIcon,
  subSection: <></>,
  routing: CallCenterDashboard,
};

import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import { logisticsRoutesListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import cx from "classnames";
import styles from "../../shared/Logistics.module.css";
import { ColorLabel } from "components/common/colorLabel";
import { RouteListItem, routePriorityDict, trackingStatusDict } from "api/routes/models";
import { StateLabel } from "components/common/stateLabel";
import { RouteStatus } from "./RouteStatus";
import { RouteDates } from "./RouteDates";
import { PingLabel } from "components/common/pingLabel";
import arrowDownIcon from "assets/images/keyboardArrowDown.svg";
import { PopoverMenu } from "components/utils/Popover";
import { RoutePriority } from "./RoutePriority";
import { MarkRouteReadyCheckbox } from "../rightPanel/components/markRouteReady/MarkRouteReadyCheckbox";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  route: RouteListItem;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID | number,
    index: number,
    togglePanel: () => void,
  ) => void;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
  togglePanel: (id: string) => void;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  route,
  selectMutlipleItems,
  setShowLoader,
  togglePanel,
}: Props) => {
  return (
    <ModuleRow
      className={cx(styles.fixedRouteRow, {
        active: isActive(String(route.id)),
        highlighted:
          isHighlighted(String(route.id)) || highlightedRows.some(row => row.id === route.id),
        [styles.editedRow]: route.isRecentlyEdited,
      })}
      gridStyle={logisticsRoutesListConfig.grid}
      onMouseDown={event =>
        selectMutlipleItems(event, route.id, index, () => togglePanel(String(route.id)))
      }
    >
      <div>
        <MarkRouteReadyCheckbox route={route} setShowLoader={setShowLoader} />
      </div>
      <ColorLabel color={route.color} />
      <PopoverMenu
        placement="bottom-start"
        target={btnProps => (
          <div
            className={cx(
              styles.priority,
              styles.prioritySmall,
              "d-flex align-items-center justify-content-between gap-2 ml-n1",
            )}
            onMouseDown={e => e.stopPropagation()}
            {...btnProps}
            role="button"
          >
            <div className="d-flex align-items-center gap-2 pl-1">
              <ColorLabel
                color={
                  route.priority === "MEDIUM"
                    ? "#ee9e4b"
                    : route.priority === "HIGH"
                    ? "#e26b77"
                    : route.priority === "LOW"
                    ? "#e4b700"
                    : "#808080"
                }
                height={12}
                width={12}
              />
              <div className="d-flex align-items-center">
                <span>{route.priority ? routePriorityDict[route.priority] : "-"}</span>
                <span>{route.priorityOrder ? route.priorityOrder : "-"}</span>
              </div>
            </div>
            <div className="d-flex justify-content-end pl-2">
              <img alt="Wybierz" src={arrowDownIcon} />
            </div>
          </div>
        )}
      >
        {({ close }) => <RoutePriority route={route} />}
      </PopoverMenu>
      <div className="d-flex align-items-center">
        <div className={cx(styles.signature, styles.overflow, "gap-1")}>
          {route.signature ? route.signature : "--"}
        </div>
        <div className="d-flex align-items-center gap-1">
          {route.isDraft && <StateLabel kind="deepPurple">szkic</StateLabel>}
          {route.isRecentlyEdited && <StateLabel kind="indigo">edytowana</StateLabel>}
        </div>
      </div>
      <div className="fw-700 overflow">{route.name ? route.name : "--"}</div>
      <div className="fw-800">{route.car ? route.car.registrationNumber : "--"}</div>
      <div className="fw-700 overflow">
        {route.driver ? (
          <>
            {route.driver.firstName} {route.driver.lastName}
          </>
        ) : (
          "--"
        )}
      </div>
      <RouteStatus status={route.status} />
      <RouteDates date={route.loadingDate} time={route.loadingTime} />
      <RouteDates date={route.departureDate} time={route.departureTime} />
      <PingLabel kind={route.trackingStatus} label={trackingStatusDict[route.trackingStatus]} />
    </ModuleRow>
  );
};

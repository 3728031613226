import { useOverviewReceivedByProductCategory } from "api/wms/hooks";
import { Table } from "components/miloDesignSystem/molecules/table";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../Overview.module.css";
import { useOverviewReceivedByProductCategoryListColumns } from "../utils/useOverviewReceivedByProductCategoryListColumns";
import { useGenericTableFilters } from "components/common/genericTable/useGenericTableFilters";
import { queryString } from "utilities";
import { GenericTable } from "components/common/genericTable/GenericTable";
import { detailsListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { parseTableRowData } from "components/common/genericTable/utils";

export const OverviewReceivedByProductCategory = () => {
  const { setDateFrom, setRowBy, filters } = useGenericTableFilters();
  const search = queryString.stringify(filters);
  const {
    data: itemsReceived,
    error,
    isLoading,
    isFetching,
  } = useOverviewReceivedByProductCategory(search, {
    keepPreviousData: true,
  });

  const columns = useOverviewReceivedByProductCategoryListColumns(itemsReceived, filters);
  const normalizedRows = parseTableRowData(itemsReceived);

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <Typography fontSize="16" fontWeight="600">
          Przyjęcia - Kategorie mebli
        </Typography>
        <GenericTable.RowBySelect rowBy={filters.rowBy} setRowBy={setRowBy} />
      </div>
      <div className={styles.tableWrapper}>
        <Table
          rows={normalizedRows}
          // @ts-ignore
          columns={columns}
          error={error}
          isLoading={isLoading || isFetching}
          uiSchema={detailsListUiSchema}
        />
      </div>
      <GenericTable.Footer rowBy={filters.rowBy} setDateFrom={setDateFrom} />
    </div>
  );
};

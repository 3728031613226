import { Button, PageHeader } from "components/common";
import downloadFileIcon from "assets/images/downloadFile.svg";
import uploadFileIcon from "assets/images/uploadFile.svg";
import visibilityOffIcon from "assets/images/eye_off.svg";
import areaChartIcon from "assets/images/areaChart.svg";
import { InvoiceAdmission } from "api/trading-documents/models";
import { ReviewerLabel } from "pages/tradingDocuments/importPurchaseInvoices/components/reviewers/ReviewerLabel";

interface Props {
  invoiceAdmission: InvoiceAdmission;
}

export const ListHeader = ({ invoiceAdmission }: Props) => {
  return (
    <div>
      <PageHeader
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Pobierz plik"
                  src={downloadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Importuj plik"
                  src={uploadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <span className="line-divider" />
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Wyłącz widoczność"
                  src={visibilityOffIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img alt="Wykres" src={areaChartIcon} style={{ height: "16px", width: "16px" }} />
              </div>
            </Button>
          </div>
        }
        bottomBarButtons={
          invoiceAdmission && invoiceAdmission.reviewers.length > 0 ? (
            <div className="d-flex align-items-center gap-1 justify-content-end">
              {invoiceAdmission.reviewers.map(reviewer => (
                <ReviewerLabel
                  isInvoiceAdmission={true}
                  key={reviewer.id}
                  reviewer={reviewer}
                  tradingDocument={invoiceAdmission.tradingDocument}
                />
              ))}
            </div>
          ) : null
        }
        searchBar={false}
        title="Połącz fakturę z PZ"
        viewLabel="CONNECT_INVOICE"
      />
    </div>
  );
};

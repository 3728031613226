import { InvoiceAdmission } from "api/trading-documents/models";
import { AsyncInput, RadioLabels, Select } from "components/utils";
import { useSelector } from "hooks";
import { noop } from "utilities";
import { getMonths } from "../../../utils/getMonths";
import styles from "../../searchPanel/SearchPanel.module.css";
import cx from "classnames";

interface Props {
  invoiceAdmission: InvoiceAdmission;
  whAggregateSearchDates: string[];
}

export const FiltersDisplay = ({ invoiceAdmission, whAggregateSearchDates }: Props) => {
  const manufacturers = useSelector(store => store.partials.manufacturers);

  return (
    <div className={cx("d-flex align-items-end gap-2 px-3 pb-2", styles.modalFilterSection)}>
      <Select
        disabled={true}
        label="Producent"
        onChange={noop}
        selectedItem={invoiceAdmission.whAggregateSearchManufacturer?.id ?? null}
        itemToDisplaySelected={item => (
          <div className="d-flex align-items-center body-14 fw-500">
            <span className="text-nowrap text-truncate overflow-hidden">
              {item ? item.name : "Przypisz"}
            </span>
          </div>
        )}
        items={manufacturers}
        overwrites={{
          button: { className: styles.modalManufacturerSelect },
          label: { className: styles.modalInputTextLabel },
        }}
      />

      <div className="position-relative mt-1">
        <AsyncInput
          disabled={true}
          look="common"
          label="Liczba przyjęć"
          onChange={noop}
          overwrites={{
            input: { className: styles.modalPaginationFormSize },
            floatingLabel: { className: styles.inputTextLabel },
          }}
          type="number"
          value={invoiceAdmission.whAggregateSearchPageSize ?? ""}
        />
      </div>

      <div>
        <h6 className={styles.monthsSubtitle}>Data zamknięcia dokumentu</h6>
        <RadioLabels
          allowUncheck={false}
          isNewLayout={true}
          items={getMonths()}
          multiple={true}
          name="whAggregateSearchDates"
          onChange={noop}
          overrides={{
            wrapper: { className: "mt-0 pt-1" },
            option: { className: styles.modalLabels },
            label: { className: styles.radioMark },
          }}
          values={whAggregateSearchDates}
        />
      </div>

      <div className="position-relative mt-1">
        <AsyncInput
          disabled={true}
          look="common"
          label="Zawiera paczkę:"
          onChange={noop}
          overwrites={{
            input: { className: styles.modalPackageFormSize },
            floatingLabel: { className: styles.inputTextLabel },
          }}
          value={invoiceAdmission.whAggregateSearchPackage}
        />
      </div>
    </div>
  );
};

import { useState, useCallback, useMemo } from "react";
import { tuplify } from "utilities";

export function useDict<ContentType extends string | boolean | number | []>(initialValue = {}) {
  const [dict, setDict] = useState<Record<string, ContentType>>(initialValue);
  const setValue = useCallback((id: number | string, val: ContentType) => {
    if (typeof val === "boolean") {
      setDict(s => ({ ...s, [id]: val ?? true }));
    } else if (typeof val === "string") {
      setDict(s => ({ ...s, [id]: val ?? "" }));
    } else if (typeof val === "number") {
      setDict(s => ({ ...s, [id]: val ?? 0 }));
    } else if (val instanceof Array) {
      setDict(s => ({ ...s, [id]: val ?? [] }));
    } else {
      setDict(s => ({ ...s, [id]: val }));
    }
  }, []);

  const clear = useCallback(() => setDict({}), []);
  const methods = useMemo(() => ({ clear }), [clear]);
  return tuplify(dict, setValue, methods);
}

// type SetStateAction<S> = (prevState: S) => S;

// export function useDict<ContentType>() {
//   const [dict, setDict] = useState<{ [key: string]: ContentType }>({});
//   const setValue = useCallback(
//     (...args: [number, ContentType] | [SetStateAction<{ [key: string]: ContentType }>]) => {
//       const [arg1, val] = args;
//       if (typeof arg1 === "function") {
//         setDict(arg1);
//       } else {
//         const id = arg1;
//         if (typeof val === "boolean") {
//           setDict(s => ({ ...s, [id]: val ?? true }));
//         } else if (typeof val === "string") {
//           setDict(s => ({ ...s, [id]: val ?? "" }));
//         } else if (typeof val === "number") {
//           setDict(s => ({ ...s, [id]: val ?? 0 }));
//         } else if (val instanceof Array) {
//           setDict(s => ({ ...s, [id]: val ?? [] }));
//         } else {
//           setDict(s => ({ ...s, [id]: val ?? {} }));
//         }
//       }
//     },
//     [],
//   );
//   return tuplify(dict, setValue);
// }

import { BankTransaction, BankTransactionDetails } from "api/bank-accounts/models";
import arrowIcon from "assets/images/526w.svg";
import { Label } from "components/utils";
import { ReactNode } from "react";

export const transferTypeFilterDict: Record<
  BankTransaction["transferType"],
  { label: string; value: BankTransaction["transferType"] }
> = {
  ADVANCE: { label: "zaliczka", value: "ADVANCE" },
  COLLECTIVE: { label: "zbiorczy", value: "COLLECTIVE" },
  RETURN: { label: "zwrot", value: "RETURN" },
  SINGLE: { label: "pojedynczy", value: "SINGLE" },
};

export const balanceFilterDict: Record<
  BankTransaction["summary"]["balance"],
  { label: string; value: BankTransaction["summary"]["balance"] }
> = {
  BALANCED: { label: "Bilans zgodny", value: "BALANCED" },
  SURPLUS: { label: "Nadwyżka", value: "SURPLUS" },
  UNDERPAID: { label: "Niedopłata", value: "UNDERPAID" },
};

export const balanceToLabelDict = (): Record<BankTransaction["summary"]["balance"], ReactNode> => ({
  BALANCED: (
    <Label color="blue" className="px-1">
      {balanceFilterDict.BALANCED.label}
    </Label>
  ),
  SURPLUS: (
    <Label color="yellow-orange" className="px-1">
      {balanceFilterDict.SURPLUS.label}
    </Label>
  ),
  UNDERPAID: (
    <Label color="red" className="px-1">
      {balanceFilterDict.UNDERPAID.label}
    </Label>
  ),
});

export const addedBySourceToLabelDict = (): Record<
  BankTransactionDetails["orders"][number]["addedBySource"],
  ReactNode
> => ({
  FETCHED_FROM_API: (
    <Label color="blue" className="px-1">
      Milo
    </Label>
  ),
  IMPORTED: (
    <Label color="red-orange" className="px-1">
      import
    </Label>
  ),
  MANUALLY: (
    <Label color="yellow" className="px-1">
      użytkownik
    </Label>
  ),
});

export const kindFilterDict: Record<
  BankTransaction["kind"],
  { label: string; value: BankTransaction["kind"] }
> = {
  IN: { label: "Przychodzący", value: "IN" },
  OUT: { label: "Wychodzący", value: "OUT" },
};

export const kindToLabelDict = (): Record<BankTransaction["kind"], ReactNode> => ({
  IN: (
    <Label color="blue" className="mr-2">
      <img
        src={arrowIcon}
        alt="logo"
        className=" pr-1 d-flex align-items-center justify-content-center"
      />
      {kindFilterDict.IN.label}
    </Label>
  ),
  OUT: (
    <Label color="yellow" className="mr-2">
      {kindFilterDict.OUT.label}
      <img
        src={arrowIcon}
        alt="logo"
        className="pr-1 d-flex align-items-center justify-content-center rotate180"
      />
    </Label>
  ),
});

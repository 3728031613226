import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useEffect, useRef, useState } from "react";
import { useQuery, useToggle } from "hooks";
import { PanelHeader } from "./components/PanelHeader";
import { useManufacturingSchema } from "api/manufacturingNew/hooks";
import { GeneralInfoSection } from "./components/GeneralInfoSection";
import { Stages } from "./components/Stages";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { useQueryClient } from "react-query";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";
import { AssignStagesToSchemaModal } from "./components/AssignStagesToSchemaModal";
import { assertIsDefined } from "utilities/assertIsDefined";

export const RightPanel = () => {
  const { query, setQuery } = useQuery();
  const { panelId } = query;
  const close = () => setQuery({ ...(query ?? {}), panelId: "" });

  const [isScrolledOver, setIsScrolledOver] = useState(false);
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const key = manufacturingNewKeys.manufacturingSchema(panelId);
  const assignStagesToSchemaModal = useToggle();

  const { data: manufacturingSchema, error, isLoading } = useManufacturingSchema(panelId, {
    enabled: Boolean(panelId),
  });

  useEffect(() => {
    setIsScrolledOver(false);
  }, [panelId]);

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;
  assertIsDefined(manufacturingSchema);
  return (
    <DrawerRightPanel key={manufacturingSchema.id} className="position-static ">
      <PanelHeader
        manufacturingSchema={manufacturingSchema}
        panelId={panelId}
        close={close}
        isScrolledOver={isScrolledOver}
      />

      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody
          myRef={clearCommentsRef}
          setIsScrolledOver={setIsScrolledOver}
          hasCommentsModule
        >
          <GeneralInfoSection manufacturingSchema={manufacturingSchema} panelKey={key} />
          <Stages
            queryClient={queryClient}
            assignStagesToSchemaModal={assignStagesToSchemaModal}
            manufacturingSchema={manufacturingSchema}
            panelKey={key}
          />
        </RightPanelBody>
        <CommentsGenericSection
          outsideRef={clearCommentsRef}
          commentedObject={{ ...manufacturingSchema, id: String(manufacturingSchema.id) }}
          paramName="manufacturingSchema"
          endpointUrl="/manufacturing/schemas/comments"
        />
      </DisabledOpacity>

      {assignStagesToSchemaModal.isOpen && (
        <AssignStagesToSchemaModal
          close={assignStagesToSchemaModal.close}
          isOpen={assignStagesToSchemaModal.isOpen}
          manufacturingSchema={manufacturingSchema}
        />
      )}
    </DrawerRightPanel>
  );
};

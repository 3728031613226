export const lightGreen = {
  lightGreen12: "#97C05C1F",
  lightGreen25: "#F2F8EA",
  lightGreen32: "#97C05C52",
  lightGreen50: "#DFEBCB",
  lightGreen75: "#CADFAB",
  lightGreen100: "#A3D37C",
  lightGreen200: "#94C74C",
  lightGreen300: "#85B73E",
  lightGreen400: "#709D30",
  lightGreen500: "#5E8A20",
  lightGreen600: "#3A5B0D",
};

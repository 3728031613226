import { LastUnloadedGroup } from "api/wms/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { LinkToPage } from "components/utils/linkToPage";
import { ListAvatar } from "pages/wms/shared/components/ListAvatar";
import { dateFns } from "utilities";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useLastUnloadedOrdersColumns = () =>
  useCreateTableColumns<LastUnloadedGroup>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size: 100,
        typographyProps: {
          color: "blue300",
        },
      }),
      columnHelper.text(
        row =>
          row.suppliers && Boolean(row.suppliers.length)
            ? row.suppliers.map(supplier => supplier.name).join(", ")
            : null,
        {
          header: "producent",
          size: 110,
        },
      ),
      columnHelper.text(row => (Boolean(row.locations.length) ? row.locations.join(", ") : null), {
        header: "lokalizacja",
        size: 60,
        typographyProps: {
          color: "success500",
        },
      }),
      columnHelper.accessor(row => row.inStockAt, {
        id: "inStockAt",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            przyjęto
          </Typography>
        ),
        size: 100,
        cell: info => {
          const date = info.getValue();
          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {date ? dateFns.formatRelative(new Date(date), "dd.MM.yyyy, H:mm") : "---"}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.collaborators, {
        header: "kto?",
        size: 45,
        cell: info => {
          const collaborators = info.getValue();
          return <ListAvatar collaborators={collaborators} />;
        },
      }),
      columnHelper.accessor(row => row.unloading, {
        header: "rozładunek",
        size: 100,
        cell: info => {
          const unloading = info.getValue();
          return (
            <LinkToPage
              content={unloading.signature}
              url={`/wms/unloadings/list/all?panelId=${unloading.id}`}
            />
          );
        },
      }),
    ];
  });

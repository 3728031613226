import { TradingDocument } from "api/trading-documents/models";
import { RightPanelSection, RightPanelTableRow } from "components/utils/drawer";
import { correctionSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { Correction } from "./components/Correction";

interface Props {
  tradingDocument: TradingDocument;
}

export const CorrectionSection = ({ tradingDocument }: Props) => {
  return (
    <RightPanelSection title="Korekty">
      <div className="pb-3">
        <RightPanelTableRow grid={correctionSectionListConfig.grid} hover={false}>
          <div>#</div>
          <div>nr korekty</div>
          <div>przyczyna</div>
          <div>data dostawy</div>
          <div>termin</div>
          <div>linki</div>
        </RightPanelTableRow>
        <div>
          {tradingDocument.corrections.map((correction, index) => (
            <Correction correction={correction} key={correction.id} position={index + 1} />
          ))}
        </div>
      </div>
    </RightPanelSection>
  );
};

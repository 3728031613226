import * as React from "react";
import styles from "./CheckboxGreen.module.css";
import cuid from "cuid";
import cx from "classnames";
import checkIcon from "assets/images/checkSmall.svg";

interface Props {
  className?: string;
  checked: boolean;
  onChange: (status: boolean) => void;
  disabled?: boolean;
  name: string;
  overwrites?: {
    label?: { className?: string };
    wrapper?: { className?: string; style?: React.CSSProperties };
  };
}

/**
 * @deprecated
 */
export const CheckboxGreen = ({
  className = "",
  checked,
  onChange,
  disabled,
  name,
  overwrites = {},
}: Props &
  Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "onChange"
  >) => {
  const id = React.useMemo(() => cuid(), []);
  return (
    <div
      className={cx(styles.container, className, overwrites.wrapper?.className ?? "", {
        [styles.disabled]: disabled,
      })}
      style={overwrites.wrapper?.style}
      onClick={() => {
        onChange(!checked);
      }}
    >
      <div
        className={cx(
          "d-flex justify-content-center align-items-center",
          overwrites.label?.className ?? "",
          {
            checkMark: true,
            [styles.checkMark]: true,
            [styles.checkMarkActive]: checked,
          },
        )}
      >
        <div className="btnBase btnBaseSmall">
          <input
            id={id}
            name={name}
            type="checkbox"
            className={styles.input}
            checked={checked}
            disabled={disabled}
            readOnly
          />
          <img alt="Zaznacz" src={checkIcon} />
        </div>
      </div>
    </div>
  );
};

import { useDashboardDepartedRoutes } from "api/call-center/routes/hooks";
import { CommonError } from "components/utils";
import { Loader } from "components/utils/loader";
import { useQuery } from "hooks";
import localStyles from "../../Dashboard.module.css";
import cx from "classnames";
import { getSearch } from "../../utils/getSearch";
import { InvoicePagination } from "components/common/invoicePagination/InvoicePagination";
import { DepartedRoute } from "./components/DepartedRoute";
import { FilterSquareMultiSelect } from "pages/callCenter/routeOrdersList/components/FilterSquareMultiSelect";
import { trackingStatusOnDashboardToPick } from "api/call-center/routes/models";
import { NoResults } from "components/utils/noResults";
import { SearchField } from "components/utils/searchField/SearchField";
import { useFilters } from "hooks/useFilters";

type DepartedRouteFilters = {
  search: string;
};

const initialFilters: DepartedRouteFilters = {
  search: "",
};

export const DepartedRoutesSection = () => {
  const { query } = useQuery({ exclude: ["panelId", "isRead"] });
  const { setFilter, filters } = useFilters<DepartedRouteFilters>(initialFilters);
  const search = getSearch({ query, filters });
  const {
    data: departedRoutes,
    error,
    isFetching,
    isLoading,
    pagination,
  } = useDashboardDepartedRoutes(search);

  const noResults = !departedRoutes.length && !isLoading && !error;

  if (error) {
    return (
      <div className={localStyles.card}>
        <div className="mt-4">
          <CommonError status={error._httpStatus_} />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={localStyles.card}>
        <Loader module="callCenter" />
      </div>
    );
  }

  return (
    <div className={localStyles.card}>
      {isFetching && <Loader module="callCenter" />}
      <div>
        <div className="d-flex align-items-center justify-content-between w-100 gap-1 px-3 py-3">
          <h3 className={localStyles.emptyListTitle}>Trasy w drodze</h3>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100 gap-1 px-3">
          <SearchField
            isNewLayout
            debounce={500}
            value={filters.search || ""}
            onUpdate={value => {
              setFilter("search", value);
            }}
            overrides={{
              input: { className: localStyles.input },
              wrapper: { className: localStyles.wrapper },
            }}
          />
          <div className="d-flex align-items-center gap-1">
            <FilterSquareMultiSelect
              name="trackingStatus"
              options={trackingStatusOnDashboardToPick}
              multiple={false}
              overwrites={{ label: { className: localStyles.filterLabelHeight } }}
            />
            {departedRoutes.length > 0 && <div className="line-divider ml-1 mr-1" />}
            {departedRoutes.length > 0 ? (
              <InvoicePagination pagination={pagination} position="relative" />
            ) : (
              <div style={{ height: "42px" }} />
            )}
          </div>
        </div>
        <div className={cx(localStyles.tableHeader, localStyles.tableHeaderBokDepartedRoutes)}>
          <div>sygnatura</div>
          <div>nazwa trasy</div>
          <div>kierowca</div>
          <div>nr rejestracyjny</div>
          <div>status</div>
          <div className="d-flex justify-content-end">opóźnienie</div>
          <div></div>
        </div>
        {!noResults &&
          departedRoutes.map(departedRoute => (
            <DepartedRoute departedRoute={departedRoute} key={departedRoute.id} />
          ))}
        {noResults && (
          <NoResults
            on={noResults}
            overwrites={{
              background: {
                className: localStyles.noResultsOverwrite,
              },
            }}
            subHeader="trasy"
          />
        )}
      </div>
    </div>
  );
};

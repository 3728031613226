import { Route, RouteListItem } from "api/routes/models";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { PartialOf } from "typeUtilities";
import { logisticsRoutesKeys } from "./keys";
import { RouteSimplified } from "./models";

export const getLogisticsRouteSimplified = ({
  id,
}: {
  id: string;
}): ApiFetcher<RouteSimplified> => ({
  key: logisticsRoutesKeys.route.simplified(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/simplified-items/" + id,
    }),
});

export const patchRoute = (data: PartialOf<RouteListItem>, id: string | number) =>
  queryFetch<Route>({
    method: "PATCH",
    url: "/routes/items/" + id,
    data,
  });

export const deleteRoute = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/routes/items/" + id,
  });

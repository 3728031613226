import { TableFilters } from "components/common/genericTable/useGenericTableFilters";
import { useWarehouseWorkerFilters } from "../../hooks/useWarehouseWorkerFilters";
import { dateFns, queryString } from "utilities";
import { WarehouseWorkerDetails } from "api/wms/models";
import { useReceivedAndReleasedPackagesPerMonth } from "api/wms/hooks";
import { Table } from "components/miloDesignSystem/molecules/table";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../../RightPanel.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useReceivedAndReleasedPackagesPerMonth as useReceivedAndReleasedPackagesPerMonthColumns } from "./useReceivedAndReleasedPackagesPerMonth";
import { parseTableRowData } from "components/common/genericTable/utils";
import { detailsListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useFilters } from "hooks/useFilters";

interface Props {
  warehouseWorker: WarehouseWorkerDetails;
}

export const ReceivedAndReleasedPackagesPerMonth = ({ warehouseWorker }: Props) => {
  const warehouseWorkerFilters = useWarehouseWorkerFilters(warehouseWorker);
  const { filters } = useFilters<TableFilters>({
    rowBy: "DAY",
    dateTo: dateFns.format(dateFns.addDays(new Date(), 1), "yyyy-MM-dd"),
    dateFrom: dateFns.format(dateFns.startOfMonth(dateFns.subMonths(new Date(), 7)), "yyyy-MM-dd"),
  });
  const search = queryString.stringify({ ...filters, ...warehouseWorkerFilters.filters });

  const {
    data: packagesPerMonth,
    error,
    isLoading,
    isFetching,
  } = useReceivedAndReleasedPackagesPerMonth(search, { keepPreviousData: true });

  const columns = useReceivedAndReleasedPackagesPerMonthColumns(packagesPerMonth);
  const normalizedRows = parseTableRowData(packagesPerMonth);

  return (
    <RightPanelSection padding="pl-0 pr-0 pb-2 pt-2 mb-5">
      <div className={styles.sectionTitle}>
        <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
          Zestawienie przyjęć i wydań (miesiąc)
        </Typography>
      </div>
      <Table<{ id: string | number }>
        // @ts-ignore
        rows={normalizedRows}
        // @ts-ignore
        columns={columns}
        error={error}
        isLoading={isLoading || isFetching}
        uiSchema={detailsListUiSchema}
      />
    </RightPanelSection>
  );
};

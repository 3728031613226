import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { WorkingDay } from "api/new-production-plans/models";
import { UUID } from "api/types";
import { useRemoveWorkingDay } from "../hooks/useRemoveWorkingDay";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";

interface Props {
  day: WorkingDay;
  productionPlanId: UUID;
}

export const RemoveWorkingDayButton = ({ day, productionPlanId }: Props) => {
  const handleWorkingDayRemoval = useRemoveWorkingDay(day, productionPlanId);

  return (
    <Tooltip title="Usuń dzień">
      <IconButton icon={MdiClose} onClick={handleWorkingDayRemoval} variant="transparent" />
    </Tooltip>
  );
};

import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import autoStories from "assets/images/moduleNavigationIcons/auto_stories.svg";
import { Dashboard } from "routes/inventory/Dashboard";

export const miloOffers: ModuleLink = {
  url: "milo-offers",
  label: "Oferty Milo",
  icon: autoStories,
  subSection: <></>,
  routing: Dashboard,
  isAccessible: false,
};

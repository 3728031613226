import localStyles from "./EmptyShelfContent.module.css";
import addProductIcon from "assets/images/addProduct.svg";
import { Button } from "components/common";

export const EmptyShelfContent = () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column w-100 p-5">
      <div className={localStyles.addProductIcon}>
        <img alt="" src={addProductIcon} />
      </div>
      <h5>Wybierz produkt</h5>
      <span style={{ color: "#808080" }}>Dodaj meble na podstawie tras, grup lub pojedynczo</span>
      <Button
        kind="primary"
        className="mt-3"
        onClick={() => {
          // open modal
          // TODO: Modal with adding products
        }}
      >
        <span>Dodaj produkty</span>
      </Button>
    </div>
  );
};

import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import packageIcon from "assets/images/packageAlt.svg";
import { Packages } from "routes/wms/Packages";

export const packages: ModuleLink = {
  url: "packages",
  label: "Paczki",
  icon: packageIcon,
  subSection: <></>,
  routing: Packages,
  isAccessible: false,
};

import cx from "classnames";
import styles from "./OrderStatusFlow.module.css";
import completingIcon from "assets/images/21.svg";
import pinIcon from "assets/images/22.svg";
import packingIcon from "assets/images/23.svg";
import transportIcon from "assets/images/24.svg";
import deliveryIcon from "assets/images/25.svg";
import settledIcon from "assets/images/26.svg";
import { Status } from "api/orders/models";
import { getOrderStatus } from "utilities/getOrderStatus";

interface Props {
  isSeen: boolean;
  status: Status;
}

const statusToNumber = (status: Status) => {
  if (status === "RECEIVED") return 0;
  if (status === "PROCESSING") return 1;
  if (status === "ROUTE_READY") return 2;
  if (status === "ON_ROUTE") return 3;
  if (status === "DELIVERED") return 4;
  if (status === "SETTLED") return 5;
  if (status === "CANCELED") return null;
  throw new Error("Unknown order status: " + status);
};

const statusesDictionary: { [key: string]: { img: string; name: string } } = {
  0: { img: completingIcon, name: "Nowe" },
  1: { img: pinIcon, name: "Przypięte do trasy" },
  2: { img: packingIcon, name: "Gotowe" },
  3: { img: transportIcon, name: "W trasie" },
  4: { img: deliveryIcon, name: "Dostarczono" },
  5: { img: settledIcon, name: "Rozliczono" },
};

function getClassName(status: string, orderStatus: number, isSeen: boolean) {
  const numStatus = Number(status);
  if (isSeen === false && orderStatus === 0) {
    return "";
  }
  if (numStatus < orderStatus) return styles.statusPassed;
  if (numStatus === orderStatus) return styles.statusInProgress;
  return "";
}

export const OrderStatusFlow = ({ status, isSeen }: Props) => {
  const orderStatus = statusToNumber(status);

  if (orderStatus === null) {
    return null;
  }

  return (
    <div className={styles.statusesFlow}>
      {Object.keys(statusesDictionary).map(key => (
        <div
          className={cx(styles.status, getClassName(key, orderStatus, isSeen))}
          key={key}
          title={statusesDictionary[key].name}
        >
          <div className={styles.statusIcon}>
            <img src={statusesDictionary[key].img} alt="" />
          </div>
        </div>
      ))}
    </div>
  );
};

export const OrderStatus = ({ status }: { status: Status }) => {
  const orderStatus = getOrderStatus(status);
  if (!orderStatus) return <span>??</span>;
  return (
    <div className={styles.statusesFlow} title={orderStatus.name}>
      <div className={cx(styles.status)}>
        <div>
          <img src={orderStatus.img} alt="" />
        </div>
      </div>
    </div>
  );
};

import {
  DeliveryStatus,
  ManufacturingStatus,
  Order,
  WarehouseReceivedStatus,
  WarehouseReleasedStatus,
} from "api/orders/models";
import { useSingleItemQuantitiesDetails } from "hooks/apiPrimitives";
import { Table } from "components/miloDesignSystem/molecules/table";
import {
  NormalizedSingleItemQuantities,
  useSingleItemQuantitiesDetailsColumns,
} from "./useSingleItemQuantitiesDetailsColumns";
import { useFilters } from "hooks/useFilters";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./singleItemQuantitiesDetails.module.css";
import { useMemo } from "react";
import { capitalizeFirstLetter, cx } from "utilities";
import { SearchField } from "components/utils/searchField/SearchField";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { Select } from "components/utils";

interface Props {
  order: Order;
  close: () => void;
}

export const SingleItemQuantitiesDetails = ({ order, close }: Props) => {
  const { searchParams, setFilter, filters } = useFilters({
    page: 1,
    order: order.id,
    search: "",
    manufacturingStatus: "",
    warehouseReceivedStatus: "",
    warehouseReleasedStatus: "",
    deliveryStatus: "",
  });
  const { data, isLoading, isFetching, error, pagination } = useSingleItemQuantitiesDetails(
    searchParams,
  );
  const columns = useSingleItemQuantitiesDetailsColumns(searchParams);

  const normalizedData: NormalizedSingleItemQuantities[] = useMemo(() => {
    if (!data) return [];
    return data.map(row => {
      const subRows: NormalizedSingleItemQuantities[] = (row.packages || []).map(packageRow => {
        return {
          attributes: null,
          description: packageRow.package.description,
          id: packageRow.id,
          internalId: packageRow.package.internalId,
          locations: packageRow.location ? [packageRow.location] : [],
          name: packageRow.package.name,
          statuses: packageRow.statuses,
          uniquePackage: packageRow.code,
        } as NormalizedSingleItemQuantities;
      });
      return {
        attributes: row.index.attributes.map(attribute => {
          return {
            name: attribute.attribute,
            id: attribute.id,
            value: attribute.value,
          };
        }),
        description: null,
        id: row.id,
        internalId: null,
        uniquePackage: null,
        labelDownloadedAt: row.labelDownloadedAt,
        locations: row.locations,
        subRows,
        name: row.index.product.name,
        statuses: row.statuses,
      } as NormalizedSingleItemQuantities;
    });
  }, [data]);

  const productionStatusList = useMemo(
    () =>
      [{ name: "Wszystkie", id: "" }].concat(
        Object.entries(productionStatusToLabelDict).map(([id, name]) => ({
          name: capitalizeFirstLetter(name),
          id,
        })),
      ),
    [],
  );

  const warehouseReceivedStatusList = useMemo(
    () =>
      [{ name: "Wszystkie", id: "" }].concat(
        Object.entries(warehouseReceivedStatusToLabelDict).map(([id, name]) => ({
          name: capitalizeFirstLetter(name),
          id,
        })),
      ),
    [],
  );

  const warehouseReleasedStatusList = useMemo(
    () =>
      [{ name: "Wszystkie", id: "" }].concat(
        Object.entries(warehouseReleasedStatusToLabelDict).map(([id, name]) => ({
          name: capitalizeFirstLetter(name),
          id,
        })),
      ),
    [],
  );

  const deliveryStatusList = useMemo(
    () =>
      [{ name: "Wszystkie", id: "" }].concat(
        Object.entries(deliveryStatusToLabelDict).map(([id, name]) => ({
          name: capitalizeFirstLetter(name),
          id,
        })),
      ),
    [],
  );
  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Status realizacji zamówienia
        </Typography>
      }
      width={1325}
    >
      <div
        className={cx(
          styles.tableWrapper,
          "d-flex flex-column flex-1 overflow-hidden justify-content-between",
        )}
      >
        <div className={styles.filterWrapper}>
          <SearchField
            isNewLayout
            debounce={500}
            value={filters.search || ""}
            onUpdate={value => {
              setFilter("search", value);
              setFilter("page", 1);
            }}
            overrides={{
              input: { className: styles.input },
              wrapper: { className: styles.wrapper },
            }}
          />

          <Select
            items={productionStatusList}
            label="Status produkcji"
            onChange={value => {
              if (value) {
                setFilter("manufacturingStatus", String(value.id));
                setFilter("page", 1);
              }
            }}
            overwrites={{
              button: { className: cx("fs-14 fw-700", styles.select) },
              label: { className: "pt-0 pb-0" },
              dropdownList: { className: styles.dropdownList },
            }}
            selectedItem={filters.manufacturingStatus}
            itemToDisplay={(item, selected) => (
              <Typography
                fontSize="14"
                fontWeight="600"
                color={selected?.id === item.id ? "neutralBlack32" : "neutralBlack88"}
              >
                {item.name}
              </Typography>
            )}
            size="small"
          />
          <Select
            items={warehouseReceivedStatusList}
            label="Stan przyjęcia"
            onChange={value => {
              if (value) {
                setFilter("warehouseReceivedStatus", String(value.id));
                setFilter("page", 1);
              }
            }}
            overwrites={{
              button: { className: cx("fs-14 fw-700", styles.select) },
              label: { className: "pt-0 pb-0" },
              dropdownList: { className: styles.dropdownList },
            }}
            itemToDisplay={(item, selected) => (
              <Typography
                fontSize="14"
                fontWeight="600"
                color={selected?.id === item.id ? "neutralBlack32" : "neutralBlack88"}
              >
                {item.name}
              </Typography>
            )}
            selectedItem={filters.warehouseReceivedStatus}
            size="small"
          />

          <Select
            items={warehouseReleasedStatusList}
            label="Stan wydania"
            onChange={value => {
              if (value) {
                setFilter("warehouseReleasedStatus", String(value.id));
                setFilter("page", 1);
              }
            }}
            overwrites={{
              button: { className: cx("fs-14 fw-700", styles.select) },
              label: { className: "pt-0 pb-0" },
              dropdownList: { className: styles.dropdownList },
            }}
            itemToDisplay={(item, selected) => (
              <Typography
                fontSize="14"
                fontWeight="600"
                color={selected?.id === item.id ? "neutralBlack32" : "neutralBlack88"}
              >
                {item.name}
              </Typography>
            )}
            selectedItem={filters.warehouseReleasedStatus}
            size="small"
          />

          <Select
            items={deliveryStatusList}
            label="Status dostawy"
            onChange={value => {
              if (value) {
                setFilter("deliveryStatus", String(value.id));
                setFilter("page", 1);
              }
            }}
            overwrites={{
              button: { className: cx("fs-14 fw-700", styles.select) },
              label: { className: "pt-0 pb-0" },
            }}
            itemToDisplay={(item, selected) => (
              <Typography
                fontSize="14"
                fontWeight="600"
                color={selected?.id === item.id ? "neutralBlack32" : "neutralBlack88"}
              >
                {item.name}
              </Typography>
            )}
            selectedItem={filters.deliveryStatus}
            size="small"
          />
        </div>
        <Table<NormalizedSingleItemQuantities>
          showIndexColumn
          rows={normalizedData}
          columns={columns}
          onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
          pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
          isLoading={isLoading || isFetching}
          error={error}
          uiSchema={mainListUiSchema}
        />
      </div>
    </Modal>
  );
};

export const productionStatusToLabelDict: Record<ManufacturingStatus, string> = {
  AWAITING: "zlecone do produkcji",
  FINISHED: "wyprodukowano",
  IN_PROGRESS: "w produkcji",
  NOT_SCHEDULED: "nie rozpoczęto",
};

export const warehouseReceivedStatusToLabelDict: Record<WarehouseReceivedStatus, string> = {
  NOT_RECEIVED: "nie przyjęto",
  RECEIVED: "przyjęto",
};

export const warehouseReleasedStatusToLabelDict: Record<WarehouseReleasedStatus, string> = {
  NOT_RELEASED: "nie wydano",
  RELEASED: "wydano",
};

export const deliveryStatusToLabelDict: Record<DeliveryStatus, string> = {
  DELIVERED: "wydano klientowi",
  NOT_DELIVERED: "nie doręczono",
  ON_ROUTE: "nie wydano",
};

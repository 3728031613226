import { PreviewCorrectionDocument } from "api/trading-documents/models";
import { useAmountPerVatRate } from "../utils/useAmount";
import { FieldName } from "./WholeAmountToPay";
import { CreateCorrectionValues } from "../CreateCorrectionManuallyModal";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { cx } from "utilities";

interface Props {
  conversionRate?: number;
  currency: string;
  fieldName: FieldName;
  isMainSummary?: boolean;
  summaries: PreviewCorrectionDocument["amountSummaryPerVatRate"];
  values: CreateCorrectionValues;
}

export const SummaryAmount = ({
  conversionRate,
  currency,
  fieldName,
  isMainSummary,
  summaries,
  values,
}: Props) => {
  const { getAmount, getCurrencyConvertion } = useAmountPerVatRate();

  if (conversionRate) {
    const summariesCurrencyConversion = summaries.reduce((acc, summary) => {
      return acc + getCurrencyConvertion(fieldName, values, summary.vatRate, conversionRate);
    }, 0);

    return (
      <>
        <strong
          className={cx({
            "body-14 fw-800": isMainSummary === undefined,
            "body-18-800": isMainSummary,
          })}
        >
          <FinanceAmountFormat value={summariesCurrencyConversion} />
        </strong>
        <div className="fw-700">PLN</div>
      </>
    );
  }

  const summariesAmount = summaries.reduce((acc, summary) => {
    return acc + getAmount(fieldName, values, summary.vatRate);
  }, 0);

  return (
    <div className="d-flex align-items-center">
      <FinanceAmountFormat value={summariesAmount} />
      <div className="fs-12 text-color-grey">{currency}</div>
    </div>
  );
};

import { Route as RoutePath } from "components/utils";
import { RouteInvoicesList } from "pages/logistics/routeInvoicesList/RouteInvoicesList";
import { NotFound } from "pages/notFound";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const Route = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <RoutePath
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <RoutePath
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <RoutePath
        component={RouteInvoicesList}
        exact={true}
        path={`${match.path}/:routeId/trading-documents/:tab`}
        title="Milo - logistyka - faktury trasy"
      />
      <RoutePath component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};

import { ProductAttribute, ProductAttributeFabric } from "api/products/models";

export function isFabric(attr: ProductAttribute): attr is ProductAttributeFabric {
  return (attr as ProductAttributeFabric).categories !== undefined;
}

export function isTruthy<T extends any, U extends Exclude<T, null | undefined | 0>>(
  val: T,
): val is U {
  return val !== null && val !== undefined && val !== 0;
}

export function isObject(candidate: unknown): candidate is Record<string, any> {
  return typeof candidate === "object" && candidate !== null;
}

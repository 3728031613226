import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { uniquePackagesApi } from "./calls";
import { useMutation } from "hooks/useMutation";
import { parsePatchData } from "utilities/parsePatchData";
import { PartialOf } from "typeUtilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { createApiQuery } from "hooks/createApiQuery";
import { Fee } from "./models";
import { uniquePackagesKeys } from "./keys";

const useFees = createApiQuery(uniquePackagesApi.getFees);
const useLongHeldPackages = createPaginatedApiQuery(uniquePackagesApi.getLongHeldPackages);

const usePatchFee = () => {
  return useMutation(
    ({ id, toUpdate }: { id: number; toUpdate: PartialOf<Fee> }) => {
      return uniquePackagesApi.patchFee(parsePatchData(toUpdate), id);
    },
    ({ queryUtils, queryClient }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prev = queryUtils.handleMutate(uniquePackagesKeys.fee(id), toUpdate);
        return { prev };
      },
      onSuccess: ({ id }) => queryClient.invalidateQueries(uniquePackagesKeys.fee(id)),
      onError: (_, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(uniquePackagesKeys.fee(id), onMutateReturn.prev);
      },
    }),
  );
};

export const uniquePackagesAction = {
  useFees,
  useLongHeldPackages,
  usePatchFee,
};

import { AddPurchaseInvoicePosition } from "api/trading-documents/models";
import { UUID } from "api/types";
import { AsyncInput } from "components/utils";
import React, { useState } from "react";
import styles from "../RightPanel.module.css";
import { useEditPurchaseInvoicePosition } from "./hooks/useEditPurchaseInvoicePosition";

interface Props {
  inputPlaceholder: string;
  inputType: "number" | "string";
  itemId: string | number;
  setIsDisplayPositionEdited?: React.Dispatch<React.SetStateAction<boolean>>;
  target: React.ReactNode;
  targetKey: keyof AddPurchaseInvoicePosition;
  tradingDocumentId: UUID;
  tradingDocumentItemId: UUID;
  value: number | string;
}

export const EditPurchaseInvoicePosition = ({
  inputPlaceholder,
  inputType,
  itemId,
  setIsDisplayPositionEdited,
  target,
  targetKey,
  tradingDocumentId,
  tradingDocumentItemId,
  value,
}: Props) => {
  const [showInput, setShowInput] = useState(false);

  const patchPositionMutation = useEditPurchaseInvoicePosition(
    itemId,
    tradingDocumentId,
    tradingDocumentItemId,
  );

  return (
    <div className="fw-700">
      {!showInput ? (
        <div
          className={styles.editableItem}
          onClick={e => {
            e.stopPropagation();
            setShowInput(true);
            if (inputPlaceholder === "#" && setIsDisplayPositionEdited) {
              setIsDisplayPositionEdited(true);
            }
          }}
        >
          {target}
        </div>
      ) : (
        <div className="d-flex align-items-center gap-1">
          <AsyncInput
            disabled={patchPositionMutation.isLoading}
            inProgress={patchPositionMutation.isLoading}
            onChange={value => {
              patchPositionMutation.mutate(
                {
                  id: tradingDocumentItemId,
                  [targetKey]: value,
                },
                {
                  onSettled: () => {
                    setShowInput(false);
                  },
                },
              );
            }}
            overwrites={{
              container: {
                className: styles.editPurchaseInvoicePositionInput,
              },
              input: {
                className: styles.editPurchaseInvoicePositionInput,
              },
            }}
            placeholder={inputPlaceholder}
            type={inputType}
            value={value}
          />
        </div>
      )}
    </div>
  );
};

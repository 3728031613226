import { useQuery, useRedux, useSelector } from "hooks";
import { AvailableViewKeys } from "ducks/ui";
import { omit } from "utilities";

interface Props {
  overrittenPanelKey?: string;
  children: (args: {
    togglePanel: (id: number | string, additionalId?: number | string) => void;
    active: string;
    isActive: (id: number | string) => boolean;
    isHighlighted: (id: number | string) => boolean;
    close: () => void;
  }) => JSX.Element;
  /**
   * name is used to store last clicked element in redux store
   */
  name: AvailableViewKeys;
}

export const RightPanelHandler = ({ children, name, overrittenPanelKey }: Props) => {
  const [dispatch, { ui }] = useRedux();
  const highlighted = useSelector(store => store.ui.highlights[name]);
  const panelKey = overrittenPanelKey ?? "panelId";
  const { highlight } = ui;
  const { query, setQuery } = useQuery();
  const searchParamValue = query[panelKey];
  const isActive = (id: number | string) => String(id) === String(searchParamValue);
  const isHighlighted = (id: number | string) => String(id) === String(highlighted);

  const currentQuery = query ?? {};

  const togglePanel = (id: number | string) => {
    const updatedQuery = (() => {
      const omittedQuery = omit(currentQuery, panelKey);
      if (String(id) === searchParamValue) {
        return { ...omittedQuery, [panelKey]: "" };
      }
      dispatch(highlight({ name, id: Number(id) }));
      return { ...omittedQuery, [panelKey]: String(id) };
    })();

    setQuery(updatedQuery);
  };

  const close = () => setQuery({ ...(query ?? {}), [panelKey]: "" });
  return children({ togglePanel, active: searchParamValue, isActive, isHighlighted, close });
};

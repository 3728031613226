import { RouteOrder } from "api/routes/models";
import { Button } from "components/common";
import downloadPdfIcon from "assets/images/download_pdf.svg";
import qrCodeIcon from "assets/images/qrCode.svg";
import { useOrderPdfDownload } from "../../../hooks/useOrderPdfDownload";
import ReactTooltip from "react-tooltip";
import styles from "pages/logistics/routesList/rightPanel/RightPanel.module.css";
import { StatusHandler } from "components/utils";
import { DOMAIN } from "ENDPOINTS";
import { formatSignatureForPreview } from "../../../utils/formatSignatureForPreview";

interface Props {
  order: RouteOrder;
}

export const RoutePointDocuments = ({ order }: Props) => {
  const downloadOrderPdf = useOrderPdfDownload();

  return (
    <div className="d-flex align-items-center gap-1">
      <StatusHandler>
        {helpers => (
          <Button
            disabled={helpers.isFetching}
            kind="transparent-black"
            onClick={() => {
              downloadOrderPdf(order, helpers);
            }}
            size="square-s"
          >
            <div
              className="btnBase btnBaseSmall"
              data-tip
              data-for={`order-pdf-download-${order.id}`}
            >
              <img alt="PDF" src={downloadPdfIcon} />
            </div>
          </Button>
        )}
      </StatusHandler>
      <ReactTooltip
        className={styles.customTooltip}
        id={`order-pdf-download-${order.id}`}
        place="top"
        effect="solid"
        arrowColor="transparent"
        offset={{ top: -15 }}
      >
        Pobierz PDF zamówienia
      </ReactTooltip>
      <Button
        as="a"
        href={`${DOMAIN}utils/labels/order/${order.id}/${formatSignatureForPreview(
          order.signature,
        )}`}
        kind="transparent-black"
        rel="noopener noreferrer"
        size="square-s"
        target="_blank"
      >
        <div
          className="btnBase btnBase16 btnBaseSmall"
          data-tip
          data-for={`order-label-preview-${order.id}`}
        >
          <img alt="Etykieta" src={qrCodeIcon} />
        </div>
      </Button>
      <ReactTooltip
        className={styles.customTooltip}
        id={`order-label-preview-${order.id}`}
        place="top"
        effect="solid"
        arrowColor="transparent"
        offset={{ top: -10, right: -10 }}
      >
        <div className="d-flex flex-nowrap">Podgląd etykiety</div>
      </ReactTooltip>
    </div>
  );
};

import { Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { BankAccountsList } from "pages/bankAccounts/bankAccountList";
import { Create, RedirectLink } from "pages/bankAccounts/create";
import { BankAccountTransactions } from "pages/bankAccounts/bankAccountTransactions";
import { BankTransactionDetails } from "pages/bankAccounts/bankTransactionDetails";

export const BankAccounts = ({ match }: RouteComponentProps) => {
  return (
    <>
      <Switch>
        <Route
          exact={true}
          path={`${match.path}`}
          render={() => <Redirect to={`${match.path}/list`} />}
        />
        <Route
          exact={true}
          path={`${match.path}/list`}
          component={BankAccountsList}
          title="Milo - konta bankowe"
        />
        <Route
          exact={true}
          path={`${match.path}/transactions/details/:id`}
          component={BankTransactionDetails}
          title="Milo - szczegóły transakcji"
        />
        <Route
          exact={true}
          path={`${match.path}/transactions/list/:tab`}
          component={BankAccountTransactions}
          title="Milo - lista transakcji"
        />
        <Route
          exact={true}
          path={`${match.path}/create/step-three`}
          component={Create}
          title="Milo - dodaj konto bankowe"
        />
        <Route
          exact={true}
          path={`${match.path}/create`}
          component={RedirectLink}
          title="Milo - wybierz konto bankowe"
        />
        <Route exact={true} path="*" component={NotFound} title="Milo" />
      </Switch>
    </>
  );
};

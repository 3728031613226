import { wmsLayoutActions } from "api/wms/layout/actions";
import { wmsLayoutConstants } from "api/wms/layout/constants";
import { FieldDetails, FieldKind } from "api/wms/layout/models";
import { Search } from "components/miloDesignSystem/molecules/search";
import { Select } from "components/miloDesignSystem/molecules/select";
import { RightPanelSection } from "components/utils/drawer";
import styles from "./AssignRealFieldSection.module.css";
import { useState } from "react";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { getCells, getRows } from "api/warehouse/calls";
import { wmsRampApi } from "api/wms/ramps/api";
import { PackagesFilters } from "../packagesInLocationSection/PackagesInLocationSection";

interface Props {
  field: FieldDetails;
  selectedHall: number;
  setFilter: <T extends keyof PackagesFilters, U extends PackagesFilters[T]>(
    name: T,
    value: U,
  ) => void;
}

export const AssignRealFieldSection = ({ field, selectedHall, setFilter }: Props) => {
  const [selectedFieldKind, setSelectedFieldKind] = useState<FieldKind>(field.wmsKind);
  const assignFieldMutation = wmsLayoutActions.useFieldPatch(selectedHall);

  const setLevelFilter = (id: number | string) => {
    if (field.wmsKind === FieldKind.FIELD) {
      setFilter("field", String(id));
    }
    if (field.wmsKind === FieldKind.RACK) {
      setFilter("row", String(id));
    }
    if (field.wmsKind === FieldKind.RAMP) {
      setFilter("ramp", String(id));
    }
  };

  return (
    <RightPanelSection title="Przypisanie rzeczywistego miejsca w magazynie">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex align-items-center">
          <Select
            items={wmsLayoutConstants.fieldKindOptions}
            onChange={field => {
              if (field) {
                setSelectedFieldKind(field as FieldKind);
              }
            }}
            selected={selectedFieldKind || null}
          />
        </div>
        <div className="d-flex align-items-center gap-3">
          {selectedFieldKind === FieldKind.FIELD && (
            <Search
              isNullable
              textFieldProps={{ containerClassName: styles.input }}
              fetcherFn={getCells}
              externalSelectedItem={
                field.wmsId
                  ? {
                      value: String(field.wmsId),
                      text: field.name,
                    }
                  : null
              }
              onChange={searchedField => {
                if (searchedField) {
                  assignFieldMutation.mutate({
                    id: field.id,
                    toUpdate: {
                      wmsId: searchedField.id,
                      wmsKind: selectedFieldKind,
                      name: searchedField.name,
                    },
                  });
                  setLevelFilter(searchedField.id);
                } else {
                  assignFieldMutation.mutate({
                    id: field.id,
                    toUpdate: {
                      wmsId: null,
                      wmsKind: selectedFieldKind,
                    },
                  });
                }
              }}
            />
          )}
          {selectedFieldKind === FieldKind.RACK && (
            <Search
              isNullable
              textFieldProps={{ containerClassName: styles.input }}
              fetcherFn={getRows}
              externalSelectedItem={
                field.wmsId
                  ? {
                      value: String(field.wmsId),
                      text: field.name,
                    }
                  : null
              }
              onChange={searchedField => {
                if (searchedField) {
                  assignFieldMutation.mutate({
                    id: field.id,
                    toUpdate: {
                      wmsId: searchedField.id,
                      wmsKind: selectedFieldKind,
                      name: searchedField.name,
                    },
                  });
                  setLevelFilter(searchedField.id);
                } else {
                  assignFieldMutation.mutate({
                    id: field.id,
                    toUpdate: {
                      wmsId: null,
                      wmsKind: selectedFieldKind,
                    },
                  });
                }
              }}
            />
          )}
          {selectedFieldKind === FieldKind.RAMP && (
            <Search
              isNullable
              textFieldProps={{ containerClassName: styles.input }}
              fetcherFn={wmsRampApi.getRamps}
              externalSelectedItem={
                field.wmsId
                  ? {
                      value: String(field.wmsId),
                      text: field.name,
                    }
                  : null
              }
              onChange={searchedField => {
                if (searchedField) {
                  assignFieldMutation.mutate({
                    id: field.id,
                    toUpdate: {
                      wmsId: searchedField.id,
                      wmsKind: selectedFieldKind,
                      name: searchedField.name,
                    },
                  });
                  setLevelFilter(searchedField.id);
                } else {
                  assignFieldMutation.mutate({
                    id: field.id,
                    toUpdate: {
                      wmsId: null,
                      wmsKind: selectedFieldKind,
                    },
                  });
                }
              }}
            />
          )}
          {assignFieldMutation.isLoading && <Spinner size={20} />}
        </div>
      </div>
    </RightPanelSection>
  );
};

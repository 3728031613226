import { ClickOutsideHandler } from "components/utils";
import { useToggle } from "hooks";
import nextIcon from "assets/images/28.svg";
import styles from "./Dropdown.module.css";
import cx from "classnames";

const clickOutsideIgnoreClass = "ignore-outside-click";
type OptionWithId = {
  id: number;
  label: string;
  onClick: (id: number) => void;
};

type Option = {
  id?: number;
  label: string;
  onClick: () => void;
};

interface Props {
  label: React.ReactNode;
  options?: OptionWithId[] | Option[];
  icon?: string;
  overrides?: { button?: { className: string }; optionList?: { className: string } };
  position?: "bottom" | "top";
  children?: (args: { close: () => void }) => JSX.Element;
}

const assertIsOptionWithId = (attr: OptionWithId | Option): attr is OptionWithId =>
  Boolean((attr as OptionWithId).id);

export const Dropdown: React.FC<Props> = ({
  options,
  label,
  icon,
  overrides = {},
  position = "bottom",
  children,
}) => {
  const { isOpen, close, toggle } = useToggle(false);

  return (
    <ClickOutsideHandler onClickOutside={close} outsideClickIgnoreClass={clickOutsideIgnoreClass}>
      <div className={styles.item}>
        <button
          type="button"
          className={cx("d-flex align-items-center", styles.btn, overrides.button?.className)}
          aria-haspopup="menu"
          onClick={e => {
            e.stopPropagation();
            toggle();
          }}
        >
          {icon && <img src={icon} alt="" className="mr-2" />}
          <strong className={styles.label}>
            {label}
            <img src={nextIcon} alt="" className={cx(styles.arrowIcon, { "ml-1": label })} />
          </strong>
        </button>
        <div
          className={cx(styles.optionsList, overrides.optionList?.className, {
            [styles.top]: position === "top",
          })}
          role="menu"
          style={{ display: isOpen ? "block" : "none" }}
        >
          {options
            ? options.map(option => (
                <div
                  key={option.label}
                  className={styles.optionsItem}
                  role="menuitem"
                  onClick={() => {
                    close();
                    if (assertIsOptionWithId(option)) {
                      option.onClick(option.id);
                    } else {
                      option.onClick();
                    }
                  }}
                >
                  <strong>{option.label}</strong>
                </div>
              ))
            : children?.({ close })}
        </div>
      </div>
    </ClickOutsideHandler>
  );
};

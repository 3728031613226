import { MdiFolderOpen } from "components/miloDesignSystem/atoms/icons/MdiFolderOpen";
import styles from "../NotificationsSection.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiSend } from "components/miloDesignSystem/atoms/icons/MdiSend";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { UUID } from "api/types";

interface Props {
  manufacturingOrder: UUID;
  open: () => void;
}

export const EmptyNotificationSection = ({ manufacturingOrder, open }: Props) => {
  const sendEmailNotification = externalManufacturingActions.useSendEmailNotification();

  return (
    <div className="pb-3">
      <div className={styles.sectionEmpty}>
        <MdiFolderOpen color="neutralBlack48" />
        <Typography className={cx(styles.sectionEmptyTitle, "pt-2")} fontSize="12" fontWeight="700">
          Jeszcze nie wysłano żadnego maila
        </Typography>
        <div className="py-2 d-flex align-items-center gap-2">
          <Button
            className="text-uppercase"
            isLoading={sendEmailNotification.isLoading}
            onClick={() =>
              sendEmailNotification.mutate({
                orderId: manufacturingOrder,
              })
            }
            size="small"
            startIcon={MdiSend}
            variant="gray"
          >
            Wyślij teraz
          </Button>
          <Button
            className="text-uppercase"
            onClick={open}
            size="small"
            startIcon={MdiSend}
            variant="gray"
          >
            Wskaż adres i wyślij
          </Button>
        </div>
      </div>
    </div>
  );
};

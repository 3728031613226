import { useEffect, useMemo, useState } from "react";
import * as React from "react";
import TimeFieldComponent from "react-simple-timefield";
import { Spinner } from "../spinner";
import closeIcon from "assets/images/11.svg";
import clockIcon from "assets/images/46.svg";
import styles from "./timePicker.module.css";
import cuid from "cuid";
import cx from "classnames";
import { Form } from "components/common/form/Form";

interface TimePickerProps {
  disabled?: boolean;
  errors?: string;
  inProgress?: boolean;
  label?: string | undefined;
  look?: "common" | "editable";
  min?: string; // "06:00"
  max?: string;
  onBlur: (value: string) => void;
  overwrites?: {
    container?: { className: string };
    input?: { className: string };
    label?: { className?: string };
  };
  value: string | null;
}

interface CustomInputProps {
  setInputValue: (value: string) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  initialValue?: string | null;
}
const spinnerStyle: React.CSSProperties = { position: "absolute", right: -18, top: -17 };

const CustomInput = ({
  onBlur,
  label,
  look = "editable",
  overwrites = {},
  setInputValue,
  disabled,
  errors,
  inProgress,
  max,
  min = "00:00",
  initialValue,
  value,
  ...rest
}: CustomInputProps &
  Pick<
    TimePickerProps,
    | "errors"
    | "disabled"
    | "inProgress"
    | "max"
    | "min"
    | "overwrites"
    | "onBlur"
    | "label"
    | "look"
  >) => {
  if (value === undefined) throw new Error("Type");
  const [customError, setCustomError] = useState("");
  const customId = useMemo(cuid, []);

  const handleBlur = () => {
    if (value === initialValue) {
      return;
    }
    if (value.includes("-")) {
      setInputValue("");
      setCustomError("nieprawidłowy format godziny");
    } else if (value < min || (max !== undefined && value > max)) {
      setCustomError(
        value <= min ? `Podaj godzinę większą od ${min}` : `Podaj godzinę mniejszą od ${max}`,
      );
      setInputValue("");
    } else {
      setCustomError("");
      onBlur(value);
    }
  };

  return (
    <>
      <div>
        {label ? (
          <Form.SectionLabel
            className={cx(
              "mb-2",
              look === "common" ? styles.commonLabel : "",
              overwrites.label?.className,
            )}
          >
            {label}
          </Form.SectionLabel>
        ) : null}
        <div className={cx(styles.container, overwrites.container?.className || "")}>
          <input
            id={customId}
            type="text"
            disabled={disabled}
            onBlur={handleBlur}
            className={cx(overwrites.input?.className || "", {
              [styles.timePicker]: look === "editable",
              [styles.commonTimePicker]: look === "common",
            })}
            value={value}
            {...rest}
          />

          {value === "--:--" ? (
            <label htmlFor={customId}>
              <img src={clockIcon} alt="wybierz godzinę" className={styles.clockIcon} />
            </label>
          ) : (
            <img
              src={closeIcon}
              alt="wyczyść"
              className={styles.closeIcon}
              onClick={() => {
                setInputValue("");
                onBlur("");
              }}
            />
          )}

          <Spinner
            on={inProgress}
            color={{ dark: "blue", light: "white" }["dark"] as "blue" | "white"}
            style={spinnerStyle}
          />
        </div>
        {errors || customError ? (
          <div className="invalid-feedback" style={{ display: "block" }}>
            {customError} <br />
            {errors}
          </div>
        ) : null}
      </div>
    </>
  );
};
export const TimePicker = ({
  disabled = false,
  errors,
  inProgress = false,
  label,
  look = "editable",
  min,
  max,
  onBlur,
  overwrites = {},
  value,
}: TimePickerProps) => {
  const [inputValue, setInputValue] = useState("");
  const formattedInitialValue = value?.length === 8 ? value.slice(0, -3) : value ? value : "--:--";

  useEffect(() => {
    setInputValue(value ? value : "");
  }, [value]);

  return (
    <TimeFieldComponent
      value={inputValue ? inputValue : "--:--"}
      onChange={e => setInputValue(e.target.value)}
      input={
        <CustomInput
          initialValue={formattedInitialValue}
          overwrites={overwrites}
          label={label}
          look={look}
          min={min}
          max={max}
          onBlur={onBlur}
          disabled={disabled}
          setInputValue={(value: string) => setInputValue(value)}
          errors={errors}
          inProgress={inProgress}
        />
      }
    />
  );
};

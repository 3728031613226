import { Table } from "components/miloDesignSystem/molecules/table";
import { useNotificationsColumns } from "../rightPanel/notificationsSection/useNotificationsColumns";
import { Notification } from "api/external-manufacturing/models";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";

interface Props {
  notifications: Notification[];
}

export const NotificationSectionTable = ({ notifications }: Props) => {
  const columns = useNotificationsColumns();

  return (
    <Table<Notification>
      rows={notifications}
      columns={columns}
      isLoading={false}
      error={null}
      uiSchema={comfortableListUiSchema}
    />
  );
};

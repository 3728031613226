import { queryString } from "utilities";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  filters: {
    [x: string]: string;
  };
};

export const getSearch = ({ query, filters }: GetSearchProps): string => {
  return queryString.stringify({
    ...query,
    ...filters,
    pageSize: 15,
  });
};

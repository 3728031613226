import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { CreateCorrectionValues } from "../CreateCorrectionManuallyModal";
import cx from "classnames";
import { getSinglePositionAmountForSummary } from "../utils/getSinglePositionAmountForSummary";

export type FieldName = "amountWithoutTax" | "amountWithTax" | "taxAmountBeforeRounding";

export type InitialFieldName = "AmountWithoutTax" | "AmountWithTax" | "TaxAmountBeforeRounding";

interface Props {
  convertionRate?: number;
  currency: string;
  fieldName: FieldName;
  isAbsolute?: boolean;
  isMainSummary?: boolean;
  values: CreateCorrectionValues;
}

export const WholeAmountToPay = ({
  convertionRate,
  currency,
  fieldName,
  isAbsolute = false,
  isMainSummary,
  values,
}: Props) => {
  if (
    values.positions
      .filter(position => !position.alreadyInvoiced)
      .some(
        position =>
          position[fieldName] === undefined ||
          position[fieldName] === null ||
          position.quantity < 0 ||
          position.quantity > position.initialQuantity ||
          position[fieldName] < 0,
      )
  ) {
    return (
      <div className="d-flex align-items-center justify-content-end text-color-coral">
        Błąd przeliczania
      </div>
    );
  }

  if (convertionRate) {
    return (
      <>
        <strong
          className={cx({
            "body-14 fw-800": isMainSummary === undefined,
            "body-18-800": isMainSummary,
          })}
        >
          <FinanceAmountFormat
            value={
              isAbsolute
                ? Math.abs(getCurrencyConvertion(values, fieldName, convertionRate))
                : getCurrencyConvertion(values, fieldName, convertionRate)
            }
          />
        </strong>
        <div className="fw-700">PLN</div>
      </>
    );
  }

  return (
    <div className="d-flex align-items-center">
      <FinanceAmountFormat
        value={
          isAbsolute
            ? Math.abs(getWholeAmount(values, fieldName))
            : getWholeAmount(values, fieldName)
        }
      />
      <div className="fs-12 text-color-grey">{currency}</div>
    </div>
  );
};

export const getWholeAmount = (values: Props["values"], fieldName: Props["fieldName"]) => {
  return values.positions
    .filter(
      position =>
        position[fieldName] !== null &&
        position[fieldName] !== undefined &&
        !position.alreadyInvoiced,
    )
    .reduce((acc, position) => acc + getSinglePositionAmountForSummary(fieldName, position), 0);
};

export const getCurrencyConvertion = (
  values: Props["values"],
  fieldName: Props["fieldName"],
  convertionRate: Props["convertionRate"],
) => {
  return values.positions
    .filter(
      position =>
        position[fieldName] !== null &&
        position[fieldName] !== undefined &&
        !position.alreadyInvoiced,
    )
    .reduce(
      (acc, position) =>
        acc + getSinglePositionAmountForSummary(fieldName, position) * convertionRate!,
      0,
    );
};

import styles from "./Attributes.module.css";
import { cx } from "utilities";
import { AttributesProps } from "./types";
import { Attribute } from "components/miloDesignSystem/atoms/attribute";

export const Attributes = ({ attributes, noWrap }: AttributesProps) => {
  return (
    <div
      className={cx(styles.attributes, {
        [styles.noWrap]: noWrap,
      })}
    >
      {attributes.map((attribute, index) => (
        <Attribute
          endIcon={attribute.endIcon}
          id={attribute.id}
          key={index}
          label={attribute.label}
          value={attribute.value}
        />
      ))}
    </div>
  );
};

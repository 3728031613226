import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { WarehouseFinancialState as WarehouseFinancialStateView } from "pages/tradingDocuments/warehouseFinancialState/WarehouseFinancialState";
import { RouteComponentProps, Switch } from "react-router-dom";

export const WarehouseFinancialState = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        component={WarehouseFinancialStateView}
        exact={true}
        path={`${match.path}`}
        title="Milo - stan finansowy magazynu"
      />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};

export const success = {
  success12: "#4ddfaa1f",
  success25: "#F1FFFA",
  success32: "#4DDFAA52",
  success50: "#E2FFF4",
  success75: "#C6FFEA",
  success100: "#A6F6D8",
  success200: "#6DE5B9",
  success300: "#4DDFAA",
  success400: "#28CE92",
  success500: "#0BAF73",
  success600: "#0AA06A",
  success700: "#0C8056",
  success800: "#104C36",
  success900: "#0D1F18",
};

import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import { wmsLoadingApi } from "./api";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { deleteLoading } from "../calls";
import { LoadingDetails } from "../models";
import { wmsKeys } from "../keys";

const usePostStartLoading = () => {
  return useMutation(wmsLoadingApi.postStartLoading, ({ toastr, queryClient }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePostFinishLoading = () => {
  return useMutation(wmsLoadingApi.postFinishLoading, ({ toastr, queryClient }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useRemoveLoading = (close: () => void, loading: LoadingDetails) => {
  return withDeleteConfirmation(
    useMutation(deleteLoading, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries(wmsKeys.loading.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto załadunek "${loading.signature}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć ten załadunek?",
  )();
};

export const wmsLoadingActions = { usePostStartLoading, usePostFinishLoading, useRemoveLoading };

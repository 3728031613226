import { TradingDocument } from "api/trading-documents/models";
import editIcon from "assets/images/edit.svg";
import styles from "../../EditTradingDocument.module.css";
import cx from "classnames";
import { useState } from "react";
import { Button } from "components/common";

interface Props {
  setIsClientEditing: React.Dispatch<React.SetStateAction<boolean>>;
  tradingDocument: TradingDocument;
}

export const ClientPreview = ({ setIsClientEditing, tradingDocument }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="d-flex align-items-start w-100 row">
      {isExpanded ? (
        <div className={cx(styles.clientBody, "col-8 pr-0 pt-1")}>
          <div className="d-flex align-items-center mb-2">
            <div>{tradingDocument.recipientCompanyName}&nbsp;</div>
          </div>
          <div className="d-flex align-items-center mb-2">
            {tradingDocument.recipientFirstName}&nbsp;{tradingDocument.recipientLastName}
          </div>
          <div className="mb-2">{tradingDocument.recipientStreet}</div>
          <div className="d-flex align-items-center mb-2">
            <div>{tradingDocument.recipientPostCode}&nbsp;</div>
            <div>{tradingDocument.recipientCity}</div>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div>NIP:&nbsp;</div>
            <div>{tradingDocument.recipientTaxId}</div>
          </div>
          <div className="mb-2">{tradingDocument.recipientEmail}</div>
          <div>{tradingDocument.recipientPhone}</div>
        </div>
      ) : (
        <div className={cx(styles.clientBody, styles.clientBodyCollapse, "col-8 pr-0 pt-1")}>
          {Object.values(possibleRecipientFields(tradingDocument)).find(value => value !== "")}...
        </div>
      )}
      <div className="col-2 pl-0 d-flex align-items-center justify-content-center">
        <Button
          kind="secondary"
          size="small"
          className={cx(styles.expandBtn, "ml-1")}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "Zwiń" : "Pokaż więcej"}
        </Button>
      </div>
      <div className="col-2 d-flex justify-content-center pr-0">
        <Button
          className={styles.editDataBtn}
          kind="secondary-grey"
          onClick={() => setIsClientEditing(prev => !prev)}
          size="small"
        >
          <div className={cx(styles.editDataBtnContent, "d-flex align-items-center")}>
            <img alt="Edytuj" src={editIcon} style={{ height: "16px", width: "16px" }} />
            <span>Zmień dane</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

const possibleRecipientFields = (tradingDocument: TradingDocument) => {
  return {
    recipientCompanyName: tradingDocument.recipientCompanyName,
    recipientFirstName: tradingDocument.recipientFirstName,
    recipientLastName: tradingDocument.recipientLastName,
    recipientStreet: tradingDocument.recipientStreet,
    recipientPostCode: tradingDocument.recipientPostCode,
    recipientCity: tradingDocument.recipientCity,
    recipientTaxId: tradingDocument.recipientTaxId,
    recipientEmail: tradingDocument.recipientEmail,
    recipientPhone: tradingDocument.recipientPhone,
  };
};

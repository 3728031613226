import * as React from "react";
import { Subtract } from "utility-types";

interface OwnProps {
  resetComponent: () => void;
}
/**
 * HOC pins "key" to the component and pass a function to increment the key.
 * It is used to hard reset the component.
 */
export function withResetComponent<P extends OwnProps>(Component: React.ComponentType<P>) {
  return (props: Subtract<P, OwnProps>) => {
    const [key, setKey] = React.useState(0);
    const reset = React.useCallback(() => setKey(oldKey => oldKey + 1), [setKey]);

    return (
      <div>
        <Component {...(props as P)} resetComponent={reset} key={key} />
      </div>
    );
  };
}

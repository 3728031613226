import styles from "../ModuleList.module.css";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const ModuleListBody = ({ children, className, style }: Props) => {
  return (
    <div className={cx(className, styles.moduleListBody)} style={style}>
      {children}
    </div>
  );
};

import { Header } from "./components/Header";
import localStyles from "./Dashboard.module.css";
import cx from "classnames";
import { DepartedRoutesSection } from "./components/departedRoutesSection/DepartedRoutesSection";
import { IncompleteRoutesSection } from "./components/incompleteRoutesSection/IncompleteRoutesSection";
import { LatestSmsMessagesSection } from "./components/latestSmsMessagesSection/LatestSmsMessagesSection";

export const Dashboard = () => {
  return (
    <div className="position-relative">
      <Header />
      <div className={localStyles.mainContent}>
        <div className={cx(localStyles.mainContentInner, "flex-wrap")}>
          <div className={cx(localStyles.scrollableContent, "d-flex flex-wrap w-100 pt-3")}>
            <div className="col-12 col-xl-6 pr-3 pr-xl-0">
              <DepartedRoutesSection />
              <IncompleteRoutesSection />
            </div>
            <div className="col-12 col-xl-6 pr-xl-3">
              <LatestSmsMessagesSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

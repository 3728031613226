import { useOrderMessages } from "hooks/apiPrimitives";
import { UnreadSmsModal } from "./UnreadSmsModal";
import styles from "./SharedStyles.module.css";
import { CommonError, Modal, Spinner } from "components/utils";

interface Props {
  close: () => void;
  search: string;
  orderId: number;
}

export const UnreadSmsModalWrapper = ({ close, search, orderId }: Props) => {
  const { data, error, isLoading } = useOrderMessages(String(orderId));

  if (isLoading) {
    return (
      <Modal
        overrides={{ container: { className: styles.modalContainer } }}
        isOpen
        close={close}
        showCloseBtn={false}
      >
        <Spinner color="blue" on={isLoading} size="big" text="trwa wczytywanie wiadomości" />
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal
        overrides={{ container: { className: styles.modalContainer } }}
        isOpen
        close={close}
        showCloseBtn={false}
      >
        <CommonError status={error._httpStatus_} />
      </Modal>
    );
  }

  if (!data) return null;

  return (
    <UnreadSmsModal search={search} orderMessagesSchema={data} orderId={orderId} close={close} />
  );
};

export const assignedUsersListConfig = {
  grid: "20px 190px 80px 80px 80px 80px 60px",
};

export const daysListConfig = {
  grid: "20px 1fr 80px 80px 1fr",
};

export const transportListConfig = {
  grid: "110px 1fr",
};

export const ordersListConfig = {
  grid: "110px 1fr",
};

import styles from "../../Dashboard.module.css";
import { EmptyDocumentsTraySection } from "./components/EmptyDocumentsTraySection";
import { UploadBox } from "./components/UploadBox";

export const DocumentsTraySection = () => {
  return (
    <div className={styles.card}>
      <EmptyDocumentsTraySection />
      <div className="d-flex align-items-center justify-content-between gap-1 px-3 py-3">
        <UploadBox />
      </div>
    </div>
  );
};

import styles from "../../ManufacturingStagesList.module.css";
import cx from "classnames";
import { Select } from "components/utils";
import { Button } from "components/common";
import { useSelector } from "hooks";
import { useState } from "react";
import { UUID } from "api/types";
import { useAddPreviousStage } from "../../hooks/useAddPreviousStage";
import { VisiblePreviousStage } from "api/manufacturingNew/models";

interface Props {
  panelId: UUID;
  position: number;
  setIsAdding: React.Dispatch<React.SetStateAction<boolean>>;
  setting: UUID;
  visiblePreviousStages: VisiblePreviousStage[];
}

export const AddPreviousStage = ({
  panelId,
  position,
  setIsAdding,
  setting,
  visiblePreviousStages,
}: Props) => {
  const stages = useSelector(store => store.partials.stages);
  const [stage, setStage] = useState<{ id: UUID; name: string } | null>(null);
  const addStageMutation = useAddPreviousStage();

  return (
    <div className={cx(styles.miniFormBox, "px-3 py-4")}>
      <div style={{ maxWidth: "fit-content" }}>
        <Select
          disabled={addStageMutation.isLoading}
          items={stages
            .filter(
              stage =>
                stages.some(_stage => _stage.id === stage.id) &&
                !visiblePreviousStages.some(previousStage => previousStage.stage.id === stage.id),
            )
            .map(stage => ({ id: stage.id, name: stage.name }))}
          label="Wybierz etap"
          onChange={item => {
            if (item) setStage(item);
          }}
          placeholder="Wybierz etap"
        />
      </div>
      <div className="d-flex align-items-center gap-2 pt-3">
        <Button kind="create" onClick={() => setIsAdding(false)}>
          <div className="btnBase btnBaseSmall">Anuluj</div>
        </Button>
        <Button
          disabled={!stage || addStageMutation.isLoading}
          kind="submit"
          onClick={() => {
            if (stage) {
              addStageMutation.mutate(
                {
                  position,
                  setting,
                  stage: stage.id,
                  manufacturingStageId: panelId,
                  stageName: stage.name,
                },
                {
                  onSuccess: () => setIsAdding(false),
                },
              );
            }
          }}
        >
          <div className="btnBase btnBaseSmall">Dodaj etap</div>
        </Button>
      </div>
    </div>
  );
};

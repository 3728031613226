import { useQuery } from "hooks";
import { RouteComponentProps } from "react-router";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import {
  DrawerHelpers,
  getTablePropsBasedOnDrawerHelpers,
} from "components/utils/drawerHelpers/DrawerHelpers";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useOrderLineItemColumns } from "./useOrderLineItemsColumns";
import styles from "../../externalManufacturerPanel/shared/ExternalManufacturerPanel.module.css";
import { cx } from "utilities";
import { RightPanel } from "./rightPanel/RightPanel";
import {
  ExternalManufacturerLineItemsTab,
  OrderLineItemListItem,
} from "api/external-manufacturing/models";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { getTabs } from "pages/externalManufacturerPanel/manufacturerLineItemsList/utils/getTabs";
import { getSearch } from "pages/externalManufacturerPanel/manufacturerLineItemsList/utils/getSearch";
import { handleMultipleRowsHighlight } from "utilities/handleMultipleRowsHighlight";
import { useState } from "react";
import { HighlightedRow } from "api/other/models";
import { TableToolbarActions } from "./components/TableToolbarActions";

export const OrderLineItems = ({
  match,
}: RouteComponentProps<{ tab: ExternalManufacturerLineItemsTab }>) => {
  const { tab } = match.params;
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const {
    data: manufacturerLineItems,
    error,
    isPreviousData,
    isLoading,
    pagination,
  } = externalManufacturingActions.useOrderLineItems(search);
  const columns = useOrderLineItemColumns();
  const [highlightedRows, setHighlightedRows] = useState<HighlightedRow[]>([]);

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `external-manufacturing/manufacturer-line-items`,
          urlSpan: "list",
        }}
        viewLabel="EXTERNAL_MANUFACTURER_LINE_ITEMS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between position-relative">
        <DrawerHelpers name="externalManufacturerItems">
          {drawerHelpers => (
            <>
              <Table<OrderLineItemListItem>
                rows={manufacturerLineItems}
                columns={columns}
                onPaginationChange={paginationState => {
                  updateQuery({ ...query, page: paginationState.pageIndex });
                }}
                isLoading={isLoading || isPreviousData}
                error={error}
                pagination={pagination}
                uiSchema={mainListUiSchema}
                onRowClick={(rowId, e) => {
                  const index = manufacturerLineItems.findIndex(e => e.id === rowId);
                  handleMultipleRowsHighlight(
                    e,
                    highlightedRows,
                    rowId,
                    index,
                    setHighlightedRows,
                    () => drawerHelpers.togglePanel(rowId),
                    manufacturerLineItems,
                  );
                }}
                overrides={() => {
                  return {
                    row: row => {
                      const className = getTablePropsBasedOnDrawerHelpers(drawerHelpers).overrides;

                      if (highlightedRows.find(r => r.id === row.id)) {
                        return {
                          className: styles.selected,
                        };
                      }
                      if (row.isCancelled && className) {
                        return {
                          className: cx(styles.cancelledRow, className().row?.(row).className),
                        };
                      }
                      if (className)
                        return {
                          className: className().row?.(row).className,
                        };
                      return getTablePropsBasedOnDrawerHelpers(drawerHelpers)?.overrides!().row?.(
                        row,
                      )!;
                    },
                  };
                }}
              />

              <RightPanel />
            </>
          )}
        </DrawerHelpers>
        <TableToolbarActions
          highlightedRows={highlightedRows}
          setHighlightedRows={setHighlightedRows}
        />
      </div>
    </PageWrapper>
  );
};

import { ManufacturingPlan } from "api/manufacturingNew/models";
import { RightPanelSection, RightPanelTableRow } from "components/utils/drawer";
import { assignedUsersListConfig } from "../../utils/panelTablesConfig";
import { AvatarWithName } from "components/common/avatarWithName";
import { Button } from "components/common";
import downloadPdfIcon from "assets/images/downloadPdfNew.svg";
import qrCode from "assets/images/qrCode.svg";

export const AssignedUsers = ({ manufacturingPlan }: { manufacturingPlan: ManufacturingPlan }) => {
  return (
    <RightPanelSection title="Osoby przypisane">
      <div>
        <RightPanelTableRow
          className="tableHeaderGreyBg"
          hover={false}
          grid={assignedUsersListConfig.grid}
        >
          <div>#</div>
          <div>osoba</div>
          <div>miejsce</div>
          <div>zajęte</div>
          <div>% zajęte</div>
          <div>wolne</div>
          <div>dokumenty</div>
        </RightPanelTableRow>
        {manufacturingPlan.employees.map((employee, index) => {
          return (
            <RightPanelTableRow
              key={employee.firstName + index}
              grid={assignedUsersListConfig.grid}
            >
              <div className="fs-12 fw-700">{index + 1}.</div>
              <div>
                <AvatarWithName className="fs-12 fw-700" user={employee} />
              </div>
              <div className="fs-12 fw-700">{employee.slots.total}</div>
              <div className="fs-12 fw-700">{employee.slots.taken}</div>
              <div className="fs-12 fw-700">{employee.slots.percentage}%</div>
              <div className="fs-12 fw-700">{employee.slots.total && employee.slots.taken}</div>

              <div className="d-flex align-items-center gap-1">
                <Button
                  kind="transparent-black"
                  size="square-s"
                  rel="noopener noreferrer"
                  href="/"
                  target="_blank"
                  as="a"
                  title="Pobierz PDF"
                >
                  <img alt="Pobierz PDF" src={downloadPdfIcon} />
                </Button>
                <Button
                  kind="transparent-black"
                  size="square-s"
                  rel="noopener noreferrer"
                  href="/"
                  target="_blank"
                  as="a"
                  title="Zobacz kod QRgit"
                >
                  <div className="btnBase btnBase16 btnBaseSmall">
                    <img alt="Kod QR" src={qrCode} />
                  </div>
                </Button>
              </div>
            </RightPanelTableRow>
          );
        })}
      </div>
    </RightPanelSection>
  );
};

import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { dateFns } from "utilities";

export const getDateColor = (
  date: string,
  type: "DATE" | "DETAILS" = "DATE",
): TypographyProps["color"] => {
  const formattedDate = new Date(date);
  if (dateFns.isToday(formattedDate) || dateFns.isSunday(formattedDate)) return "purple500";
  if (dateFns.isSaturday(formattedDate)) return "orange400";
  if (type === "DETAILS") return "neutralBlack48";
  return "neutralBlack100";
};

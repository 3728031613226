export const teal = {
  teal12: "#4194881F",
  teal25: "#E3F1F1",
  teal32: "#41948852",
  teal50: "#BBDEDB",
  teal75: "#91C9C4",
  teal100: "#61B9B1",
  teal200: "#4AA39B",
  teal300: "#219C91",
  teal400: "#16867C",
  teal500: "#11756C",
  teal600: "#075049",
};

import { queryString } from "utilities";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  routeId: string;
};

export const getSearch = ({ query, routeId }: GetSearchProps): string => {
  return queryString.stringify({
    ...query,
    route: routeId,
  });
};

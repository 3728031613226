import { useTradingDocument } from "api/trading-documents/hooks";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { AmountSection } from "pages/tradingDocuments/shared/components/rightPanel/amountSection/AmountSection";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { CorrectionSection } from "pages/tradingDocuments/shared/components/rightPanel/correctionSection/CorrectionSection";
import { CorrespondingDocumentSection } from "pages/tradingDocuments/shared/components/rightPanel/correspondingDocumentsSection/CorrespondingDocumentSection";
import { GeneralInfoSection } from "pages/tradingDocuments/shared/components/rightPanel/generalInfoSection/GeneralInfoSection";
import { ItemsSummarySection } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/ItemsSummarySection";
import { NotificationSection } from "pages/tradingDocuments/shared/components/rightPanel/notificationSection/NotificationSection";
import { PaymentSection } from "pages/tradingDocuments/shared/components/rightPanel/paymentSection/PaymentSection";
import { SellerInfoSection } from "pages/tradingDocuments/shared/components/rightPanel/sellerInfoSection/SellerInfoSection";
import { TitleSection } from "pages/tradingDocuments/shared/components/rightPanel/titleSection/TitleSection";
import { PanelHeader } from "./components/PanelHeader";
import { useRef } from "react";

interface Props {
  close: () => void;
}

export const RightPanel = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);

  const { data: purchaseInvoice, error, isLoading } = useTradingDocument(
    { id: panelId },
    { enabled: Boolean(panelId) },
  );

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!purchaseInvoice) return null;

  return (
    <DrawerRightPanel key={purchaseInvoice.id}>
      <PanelHeader close={close} />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody myRef={clearCommentsRef} hasCommentsModule>
          <TitleSection tradingDocument={purchaseInvoice} />
          <GeneralInfoSection tradingDocument={purchaseInvoice} />
          <AmountSection tradingDocument={purchaseInvoice} />
          {purchaseInvoice.items.length > 0 && (
            <ItemsSummarySection tradingDocument={purchaseInvoice} />
          )}
          <PaymentSection tradingDocument={purchaseInvoice} />
          <NotificationSection tradingDocument={purchaseInvoice} />
          {purchaseInvoice.corrections.length > 0 && (
            <CorrectionSection tradingDocument={purchaseInvoice} />
          )}
          {purchaseInvoice.correspondingDocuments.length > 0 && (
            <CorrespondingDocumentSection tradingDocument={purchaseInvoice} />
          )}
          <SellerInfoSection tradingDocument={purchaseInvoice} />
        </RightPanelBody>
        <CommentsGenericSection
          outsideRef={clearCommentsRef}
          paramName="tradingDocument"
          endpointUrl="/finances/comments/items"
          commentedObject={purchaseInvoice}
        />
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};

import { Button, Header } from "components/common";
import { RouteComponentProps, useHistory } from "react-router";
import styles from "./EditTradingDocument.module.css";
import cx from "classnames";
import { UUID } from "api/types";
import { useTradingDocument } from "api/trading-documents/hooks";
import { CommonError, Spinner } from "components/utils";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { patchTradingDocument } from "api/trading-documents/calls";
import { useMutationOptions } from "hooks/useMutation";
import { GeneralInfoSection } from "./subcomponents/GeneralInfoSection";
import { ClientAndSellerSection } from "./subcomponents/clientAndSellerSection/ClientAndSellerSection";
import { OrdersSection } from "./subcomponents/ordersSection/OrdersSection";
import { PaymentSummarySection } from "./subcomponents/paymentSummarySection/PaymentSummarySection";

interface UrlParams {
  tradingDocumentId: UUID;
}

export const EditTradingDocument = (props: RouteComponentProps<UrlParams>) => {
  const { match } = props;
  const { tradingDocumentId } = match.params;
  const { data: tradingDocument, error, isLoading } = useTradingDocument(
    { id: tradingDocumentId },
    { enabled: Boolean(tradingDocumentId) },
  );
  const history = useHistory();

  const updateOptions = useMutationOptions(patchTradingDocument, ({ queryUtils }) => ({
    onMutate: toUpdate => {
      const prevDetails = queryUtils.handleMutate(
        tradingDocumentsKeys.tradingDocument.details(String(toUpdate.id)),
        toUpdate,
      );

      const prevList = queryUtils.handlePaginatedListUpdate(
        tradingDocumentsKeys.tradingDocument.list(),
        toUpdate.id,
        toUpdate,
      );

      return { prevDetails, prevList };
    },
    onError: (error, toUpdate, context) => {
      queryUtils.rollback(
        tradingDocumentsKeys.tradingDocument.details(String(toUpdate.id)),
        // @ts-ignore
        context.prevDetails,
        error,
      );
      queryUtils.rollbackList(
        tradingDocumentsKeys.tradingDocument.list(),
        // @ts-ignore
        context.prevList,
        toUpdate.id,
      );
    },
  }));

  if (error) {
    return <CommonError status={error._httpStatus_} />;
  }

  if (isLoading) {
    return (
      <Spinner
        color="blue"
        on={isLoading}
        position="absolute"
        size="big"
        text="trwa wczytywanie dokumentu handlowego"
      />
    );
  }

  if (!tradingDocument) return null;

  return (
    <div>
      <Header title="Edytuj dokument handlowy" />
      <div className={cx(styles.formWrapper, "d-flex")}>
        <div className={cx(styles.formInner, "flex-grow-1")}>
          <div className={styles.sections}>
            <div className={styles.section}>
              <GeneralInfoSection tradingDocument={tradingDocument} updateOptions={updateOptions} />
              <ClientAndSellerSection
                tradingDocument={tradingDocument}
                updateOptions={updateOptions}
              />
              {tradingDocument.items.length > 0 && (
                <OrdersSection tradingDocument={tradingDocument} />
              )}
              <PaymentSummarySection tradingDocument={tradingDocument} />
              <div className={cx(styles.summaryBtns, "d-flex align-items-center")}>
                <Button
                  className={styles.summaryBtn}
                  kind="secondary-grey"
                  onClick={() => history.push("/trading-documents/list")}
                  size="medium"
                >
                  Anuluj
                </Button>
                <Button
                  className={styles.summaryBtn}
                  kind="primary"
                  onClick={() => history.push("/trading-documents/list")}
                  size="medium"
                >
                  Zapisz
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import styles from "../../Dashboard.module.css";
import cx from "classnames";
import { Button } from "components/common";
import plusIcon from "assets/images/darkPlus.svg";

interface Props {
  btnLabel?: string;
  img: string;
  subtitle?: string;
  title: string;
}

export const EmptySection = ({ btnLabel = "Zrób coś", img, subtitle, title }: Props) => {
  return (
    <div className={cx(styles.emptyList, "px-5")}>
      <div>
        <img alt="Sprzedaż" className={cx(styles.icon, "pb-3")} src={img} />
      </div>
      <h3 className={styles.emptyListTitle}>{title}</h3>
      {subtitle && (
        <div className={cx(styles.body14, "text-grey-35 pb-3 body-14-600")}>{subtitle}</div>
      )}
      <Button
        className={cx(styles.actionBtn, {
          "mt-3": subtitle === undefined,
        })}
        kind="create"
      >
        <div className="btnBase btnBaseSmall">
          <img alt="Akcja" src={plusIcon} />
          {btnLabel}
        </div>
      </Button>
    </div>
  );
};

import { useRef } from "react";

// export function useGeocoder() {
//   // @ts-ignore
//   const geocoder = useRef(new google.maps.Geocoder());
//   return geocoder.current;
// }

function initializeGeocoder(ref: React.MutableRefObject<any>) {
  if (ref.current) return ref.current;
  // @ts-ignore
  return new google.maps.Geocoder();
}
export function useGeocoder() {
  const geocoder = useRef(null);
  geocoder.current = initializeGeocoder(geocoder);
  return geocoder.current as any;
}

import { memo, useMemo } from "react";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { SliderRail, Handle, Track } from "./components";
import styles from "./RangeInput.module.css";

const sliderStyle = {
  position: "relative",
  width: "100%",
};

export const RangeInput = memo(
  ({ values, title, step, onChange, mode, domain, unit, type }) => {
    const [leftValue, rightValue] = values;

    const memoizedValues = useMemo(
      () => [values[0], values[1] > domain[1] ? domain[1] : values[1]],
      [values, domain],
    );
    return (
      <div>
        <div className={styles.container}>
          {type === "form" ? (
            <div className={styles.label}>{title}</div>
          ) : (
            <div className={styles.fieldset_label}>{title}</div>
          )}
          <div className={styles.slider}>
            <Slider
              mode={mode}
              step={step}
              domain={domain}
              rootStyle={sliderStyle}
              onChange={onChange}
              values={memoizedValues}
            >
              {type === "form" && (
                <div>
                  <input type="number" value={values[0]} />
                  <input type="number" value={values[1]} />
                </div>
              )}
              <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
              <Handles>
                {({ handles, getHandleProps }) => (
                  <div className="slider-handles">
                    {handles.map(handle => (
                      <Handle
                        key={handle.id}
                        handle={handle}
                        domain={domain}
                        getHandleProps={getHandleProps}
                      />
                    ))}
                  </div>
                )}
              </Handles>
              <Tracks left={false} right={false}>
                {({ tracks, getTrackProps }) => (
                  <div className="slider-tracks">
                    {tracks.map(({ id, source, target }) => (
                      <Track
                        key={id}
                        source={source}
                        target={target}
                        getTrackProps={getTrackProps}
                      />
                    ))}
                  </div>
                )}
              </Tracks>
            </Slider>
          </div>
        </div>
        <div className={styles.labelValues}>
          <span className={styles.fromValue}>{`${leftValue}${unit}`}</span>
          <span className={styles.toValue}>
            {rightValue > domain[1] ? ">" : null}
            {rightValue === Infinity ? domain[1] : rightValue}
            {unit}
          </span>
        </div>
      </div>
    );
  },
);

RangeInput.defaultProps = {
  title: "",
  unit: "",
  step: 1,
  mode: 2,
  ticksValues: null,
};

export default RangeInput;

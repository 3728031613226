import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  routeOrder: CallCenterRouteOrderListItem;
}

export const OrderSignature = ({ routeOrder }: Props) => {
  return (
    <div className="d-flex align-items-center mb-2 gap-2">
      <div className="fs-24">{routeOrder.signature}</div>
      {routeOrder.externalId.length > 0 && (
        <Typography color="neutralBlack48" fontSize="20" fontWeight="700">
          ({routeOrder.externalId})
        </Typography>
      )}
    </div>
  );
};

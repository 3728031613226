import bankIcon from "assets/images/accountBalance.svg";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { BankAccounts } from "routes/BankAccounts";

export const bankAccounts: ModuleLink = {
  url: "bank-accounts",
  label: "Konta bankowe",
  icon: bankIcon,
  subSection: (
    <div className="text-color-white pt-5 d-flex justify-content-center">
      Filtry dla kont bankowych
    </div>
  ),
  routing: BankAccounts,
};

import { createColumnHelper } from "@tanstack/react-table";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useMemo } from "react";
import {
  DeliveryStatus,
  SingleItemQuantitiesDetails,
  WarehouseReceivedStatus,
  WarehouseReleasedStatus,
} from "api/orders/models";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { UUID } from "api/types";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { useModifyLabelDownloadedAt } from "../hooks/useModifyLabelDownloadedAt";
import { DOMAIN } from "ENDPOINTS";
import { MdiHistory } from "components/miloDesignSystem/atoms/icons/MdiHistory";
import { useNavigate } from "hooks/useNavigate";
import { Button } from "components/miloDesignSystem/atoms/button";
import {
  deliveryStatusToLabelDict,
  productionStatusToLabelDict,
} from "./SingleItemQuantitiesDetails";
import styles from "./singleItemQuantitiesDetails.module.css";
import { cx, parseDescriptionToAttributes } from "utilities";
import { MoreDataCounter } from "components/common/moreDataCounter";
import { isAttributeFormatDescription } from "pages/warehouse/utils/isAttributeFormatDescription";
import { formatAttributes } from "pages/orders/shared/compareListingsModal/CompareListingsModal";
import { useTrimmedAttributes } from "hooks/useTrimmedAttributes";

const NUMBER_OF_VISIBLE_ATTRIBUTES = 2;

export interface NormalizedSingleItemQuantities {
  attributes:
    | {
        id: string | number;
        name: string;
        value: string;
      }[]
    | null;
  description: string | null;
  id: UUID;
  statuses: SingleItemQuantitiesDetails["statuses"];
  name: string;
  uniquePackage: string | null;
  internalId: string | null;
  labelDownloadedAt: string | null;
  subRows?: NormalizedSingleItemQuantities[];
  locations: SingleItemQuantitiesDetails["locations"];
}
const columnHelper = createColumnHelper<NormalizedSingleItemQuantities>();

export const useSingleItemQuantitiesDetailsColumns = (searchParams: string) => {
  const { singleDetailsDownloadStatus } = useModifyLabelDownloadedAt();
  const navigate = useNavigate();

  return useMemo(() => {
    return [
      columnHelper.accessor(row => row.name, {
        header: "produkt",
        size: 220,
        cell: info => {
          const value = info.getValue();

          const isNested = info.row.depth;
          if (isNested) {
            return (
              <Typography fontSize="12" fontWeight="700" noWrap>
                {value}
              </Typography>
            );
          }
          return (
            <Typography fontSize="14" fontWeight="700" noWrap>
              {value}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "wariant",
        size: 320,
        cell: info => {
          const value = info.getValue();

          const isNested = info.row.depth;

          if (isNested) {
            if (value.description && isAttributeFormatDescription(value.description)) {
              const attributes = formatAttributes(parseDescriptionToAttributes(value.description));

              return <Attributes attributes={attributes} />;
            }
            return (
              <div className="overflow">
                <Typography fontSize="12" fontWeight="500">
                  {value.description || "---"}
                </Typography>
              </div>
            );
          }
          return <Attributes attributes={value.attributes} />;
        },
      }),
      columnHelper.accessor(row => row.statuses.manufacturing, {
        header: "status produkcji",
        size: 120,
        cell: info => {
          const value = info.getValue();

          return (
            <Typography fontSize="12" fontWeight="700">
              {productionStatusToLabelDict[value.value]}
            </Typography>
          );
        },
      }),

      columnHelper.accessor(row => row.statuses.warehouseReceived, {
        header: "przyjęcie",
        size: 72,
        cell: info => {
          if (info.getValue()) {
            const value = info.getValue().value;
            const statusDict: Record<WarehouseReceivedStatus, string> = {
              NOT_RECEIVED: "nie przyjęto",
              RECEIVED: "przyjęto",
            };
            return (
              <Typography
                fontSize="12"
                fontWeight="700"
                noWrap
                color={value === WarehouseReceivedStatus.RECEIVED ? "success500" : "danger600"}
              >
                {statusDict[value]}
              </Typography>
            );
          }
          return (
            <Typography fontSize="12" fontWeight="700">
              ---
            </Typography>
          );
        },
      }),

      columnHelper.accessor(row => row.statuses.warehouseReleased, {
        header: "wydanie",
        size: 70,
        cell: info => {
          if (info.getValue()) {
            const value = info.getValue().value;
            const statusDict: Record<WarehouseReleasedStatus, string> = {
              NOT_RELEASED: "nie wydano",
              RELEASED: "wydano",
            };
            return (
              <Typography
                fontSize="12"
                fontWeight="700"
                noWrap
                color={value === WarehouseReleasedStatus.RELEASED ? "success500" : "danger600"}
              >
                {statusDict[value]}
              </Typography>
            );
          }
          return (
            <Typography fontSize="12" fontWeight="700">
              ---
            </Typography>
          );
        },
      }),

      columnHelper.accessor(row => row.locations, {
        header: "lokalizacje",
        size: 100,
        cell: info => {
          const locations = info.getValue();
          const isNested = info.row.depth;

          if (isNested) {
            const locationsString = locations
              ?.map(
                location =>
                  `${location.name} - ${location.row}/${location.column}/${location.level}`,
              )
              .join(", ");

            return (
              <Typography fontSize="12" fontWeight="400" noWrap>
                {locationsString}
              </Typography>
            );
          }
          return null;
        },
      }),
      columnHelper.accessor(row => row.statuses.warehouseReceived, {
        header: "gotowe",
        size: 55,
        cell: info => {
          const status = info.getValue();
          const isReadyToDelivery = status.value === WarehouseReceivedStatus.RECEIVED;

          if (isReadyToDelivery) {
            return <Tag label="OK" startIcon={MdiCheck} variant="success" />;
          }

          return <Tag label="NIE" variant="outline" />;
        },
      }),
      columnHelper.accessor(row => row.statuses.delivery.value, {
        header: "status dostawy",
        size: 95,
        cell: info => {
          const value = info.getValue();

          if (value === DeliveryStatus.NOT_DELIVERED)
            return (
              <Typography fontSize="12" fontWeight="700" color="danger600">
                {deliveryStatusToLabelDict[value]}
              </Typography>
            );

          return (
            <Typography fontSize="12" fontWeight="700">
              {deliveryStatusToLabelDict[value]}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "LABEL_PREVIEW",
        header: "",
        size: 30,
        cell: info => {
          const value = info.getValue();
          const isNested = info.row.depth;

          if (isNested) {
            return (
              <IconButton
                disabled={!value.id || !value.uniquePackage}
                icon={<MdiHistory color="neutralBlack88" size="16" />}
                onClick={() => {
                  navigate(
                    `/warehouse/packages/${value.internalId}/${value.uniquePackage}/history`,
                  );
                }}
                variant="gray"
              />
            );
          }

          return (
            <IconButton
              icon={
                <MdiDownloadPdf
                  color={value.labelDownloadedAt ? "success400" : "neutralBlack88"}
                  size="22"
                />
              }
              onClick={() => {
                window.open(`${DOMAIN}labels/siqp-preview?siqs=${value.id}`, "_blank");
                singleDetailsDownloadStatus(value.id, searchParams);
              }}
              variant="gray"
            />
          );
        },
      }),
      columnHelper.accessor(row => row.internalId, {
        header: "nr paczki",
        size: 70,
        cell: info => {
          const value = info.getValue() || "";

          return (
            <Typography fontSize="12" fontWeight="400" noWrap>
              {value}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.uniquePackage, {
        header: "unikalny nr paczki",
        size: 100,
        cell: info => {
          const value = info.getValue() || "";
          const year = value.slice(0, 2);
          const month = value.slice(2, 4);
          const day = value.slice(4, 6);
          const week = value.slice(6, 8);
          const hours = value.slice(8, 10);
          const mins = value.slice(10, 12);
          const code = value.slice(12);

          const hiddenValue = value ? `*********${value?.slice(-6)}` : "";
          return (
            <Popover
              content={props => (
                <div>
                  <Typography fontSize="20" fontWeight="600" color="neutralWhite100" noWrap>
                    20{year}.{month}.{day}.{week}:{hours}:{mins}-{code}
                  </Typography>
                  <div className="d-flex mt-2 flex-1 justify-content-end">
                    <Button size="small" variant="gray" theme="dark" onClick={props.hide}>
                      zamknij
                    </Button>
                  </div>
                </div>
              )}
            >
              <div className="overflow-hidden w-100 cursor-pointer">
                <Typography fontSize="12" fontWeight="400">
                  {hiddenValue}
                </Typography>
              </div>
            </Popover>
          );
        },
      }),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const Attributes = ({
  attributes,
}: {
  attributes: NormalizedSingleItemQuantities["attributes"];
}) => {
  const { getVisibleAttributes, getTrimmedAttributes } = useTrimmedAttributes(
    NUMBER_OF_VISIBLE_ATTRIBUTES,
  );

  return (
    <div className={styles.attributesContainer}>
      {getVisibleAttributes(attributes!).map((attr, index) => (
        <div className={cx(styles.attribute, "")} key={`${attr}${index}`}>
          <Typography fontSize="10" fontWeight="400">
            {attr.name}:{" "}
          </Typography>
          <Typography fontSize="10" fontWeight="700">
            {attr.value}
          </Typography>
        </div>
      ))}
      {Boolean(getTrimmedAttributes(attributes!).length) && (
        <div className={styles.attributesContainer}>
          <MoreDataCounter
            className={styles.moreDataCounter}
            counter={attributes!.length - 2}
            style={{ width: "max-content" }}
          />
        </div>
      )}
    </div>
  );
};

import { SoldPackage } from "api/trading-documents/overview/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useQuery } from "hooks";
import { dateFns, getStandardDateFormat } from "utilities";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Assign } from "utility-types";

export const useSoldPackagesColumns = () => {
  const { query } = useQuery();

  return useCreateTableColumns<Assign<SoldPackage, { id: string }>>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.packageName, {
        header: "nazwa paczki",
        size: 250,
      }),
      columnHelper.text(row => row.packageCode, {
        header: "kod paczki",
        size: 130,
      }),
      columnHelper.accessor(row => row, {
        header: "data sprzedaży",
        size: 130,
        cell: info => {
          const soldDate = info.getValue().soldDate;
          const year = info.getValue().item_Order_Created_Year;
          if (!soldDate) return <EmptyValue />;
          return (
            <div className="d-flex align-items-center gap-1">
              <Typography fontSize="12" fontWeight="600">
                {query["grouping_variable"] === "DAY"
                  ? getStandardDateFormat(soldDate)
                  : query["grouping_variable"] === "WEEK"
                  ? `Tydzień ${parseInt(soldDate)},`
                  : `${dateFns.format(new Date(2000, parseInt(soldDate) - 1, 1), "LLLL")}`}
              </Typography>
              {query["grouping_variable"] !== "DAY" && (
                <Typography fontSize="12" fontWeight="600">
                  {year}
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.numberOfSold, {
        id: "numberOfSold",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            sprzedanych sztuk
          </Typography>
        ),
        size: 150,
        cell: info => {
          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {info.getValue()}
            </Typography>
          );
        },
      }),
    ];
  });
};

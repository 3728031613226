import { postWarehouseman } from "api/wms/calls";
import { wmsKeys } from "api/wms/keys";
import { CreateWarehouseman } from "api/wms/models";
import { FormikHelpers } from "formik";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";

export const useSubmitWarehouseWorker = (close: () => void) => {
  const createWarehouseWorker = useMutation(postWarehouseman, ({ toastr, queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(wmsKeys.warehouseWorkers.list());
      close();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Dodano magazyniera",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

  const handleSubmit = (values: CreateWarehouseman, actions: FormikHelpers<CreateWarehouseman>) => {
    createWarehouseWorker.mutate(values, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

import { Shipment } from "api/shipping/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import { shippingActions } from "api/shipping/actions";
import { ShippingService } from "constants/shippingService";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { CollapsibleSection } from "pages/logistics/shared/CollapsibleSection";

interface Props {
  shipment: Shipment;
}

export const CustomShippingServiceOptionsSection = (props: Props) => {
  if (!props.shipment.shippingService) {
    return null;
  }
  switch (props.shipment.shippingService.provider) {
    case ShippingService.AMBRO: {
      return <Ambro {...props} />;
    }
    case ShippingService.DPD: {
      return null;
    }
    case ShippingService.GLS: {
      return <GLS {...props} />;
    }
    case ShippingService.MEBEL_TAXI: {
      return null;
    }
    case ShippingService.SPT: {
      return null;
    }
    case ShippingService.ZADBANO: {
      return null;
    }

    default:
      const exhaustiveCheck: never = props.shipment.shippingService?.provider;
      throw new Error(`Unhandled courier case: ${exhaustiveCheck}`);
  }
};

const GLS = ({ shipment }: Props) => {
  const glsDefaultAdditionalServicesMutation = shippingActions.usePatchShipping();

  return (
    <RightPanelSection padding="px-3 pt-0 pb-0">
      <InfoLabel title="usługi dodatkowe" className="align-items-start mt-1">
        <CollapsibleSection
          options={shipment.shippingService!.glsDefaultAdditionalServicesOptions}
          selectedOptions={shipment.glsAdditionalServices}
        >
          {options => (
            <div className="d-flex flex-column">
              {options.map(option => (
                <div key={option.id}>
                  <Checkbox
                    label={option.name}
                    checked={Boolean(
                      shipment.glsAdditionalServices.find(service => service === option.id),
                    )}
                    onChange={status => {
                      const glsAdditionalServices = status
                        ? [...shipment.glsAdditionalServices, option.id]
                        : shipment.glsAdditionalServices.filter(
                            addedOption => addedOption !== option.id,
                          );

                      glsDefaultAdditionalServicesMutation.mutate({
                        id: shipment.id,
                        toUpdate: {
                          glsAdditionalServices,
                        },
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </CollapsibleSection>
      </InfoLabel>
    </RightPanelSection>
  );
};

const Ambro = ({ shipment }: Props) => {
  const ambroServiceMutation = shippingActions.usePatchShipping();
  const ambroDefaultAdditionalServicesMutation = shippingActions.usePatchShipping();
  return (
    <RightPanelSection padding="px-3 pt-0 pb-0">
      <InfoLabel title="domyślny typ usługi">
        <Select
          items={shipment.shippingService!.ambroServicesOptions.map(option => ({
            value: option.id,
            text: option.name,
            type: MenuItemType.TEXT,
          }))}
          onChange={id => {
            const ambroDefaultService = shipment.shippingService!.ambroServicesOptions.find(
              service => service.id === id,
            );
            assertIsDefined(ambroDefaultService);
            ambroServiceMutation.mutate({
              id: shipment.id,
              toUpdate: { ambroService: ambroDefaultService.id },
            });
          }}
          selected={shipment.ambroService}
        />
      </InfoLabel>
      <InfoLabel title="usługi dodatkowe" className="align-items-start mt-1">
        <CollapsibleSection
          options={shipment.shippingService!.ambroAdditionalServicesOptions}
          selectedOptions={shipment.ambroAdditionalServices}
        >
          {options => (
            <div className="d-flex flex-column">
              {options.map(option => (
                <div key={option.id}>
                  <Checkbox
                    label={option.name}
                    checked={Boolean(
                      shipment.ambroAdditionalServices.find(service => service === option.id),
                    )}
                    onChange={status => {
                      const ambroAdditionalServices = status
                        ? [...shipment.ambroAdditionalServices, option.id]
                        : shipment.ambroAdditionalServices.filter(
                            addedOption => addedOption !== option.id,
                          );

                      ambroDefaultAdditionalServicesMutation.mutate({
                        id: shipment.id,
                        toUpdate: {
                          ambroAdditionalServices,
                        },
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </CollapsibleSection>
      </InfoLabel>
    </RightPanelSection>
  );
};

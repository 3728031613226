import * as React from "react";
import { FormikErrorMessage } from "./FormikErrorMessage";
import { safePick } from "utilities";
import cx from "classnames";

type ComponentType = "plain" | "form" | "text";
type Props<Type> = Type extends "form"
  ? FormTypeProps<Type>
  : Type extends "text"
  ? TextTypeProps<Type>
  : PlainTypeProps<Type>;

interface FormTypeProps<Type> {
  className?: string;
  name: string;
  type?: Type;
  error?: undefined;
  text?: undefined;
}

interface PlainTypeProps<Type> {
  className?: string;
  name: string;
  type?: Type;
  error?: { [key: string]: any } | null;
  text?: undefined;
}

interface TextTypeProps<Type> {
  className?: string;
  name?: undefined;
  type?: Type;
  error?: { [key: string]: any } | null;
  text: React.ReactNode;
}

/**
 * Component for displaying red error messages.
 * Default behavior is using it inside Formik's context.
 * If you want to use it outside a form, pass type="plain".
 */
export function ErrorMessage<Type extends ComponentType = "form">({
  className = "",
  name = "",
  type = "form",
  error = null,
  text,
  ...props
}: React.HTMLAttributes<HTMLElement> & Props<Type>) {
  if (type === "form") {
    return <FormikErrorMessage className={className} name={name} />;
  }
  if (type === "plain") {
    return (
      <div {...props} className={cx(className, "invalid-feedback")}>
        {safePick(error, name)}
      </div>
    );
  }
  if (type === "text") {
    return (
      <div
        {...props}
        className={cx(className, "invalid-feedback")}
        style={{ display: "block", ...props.style }}
      >
        {text}
      </div>
    );
  }
  throw new Error(
    `An unexpected error occurred. ErrorMessage component has no type!
    It should be one of [form, plain]`,
  );
}

import { getLogs, logbookApi } from "api/logbook/calls";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createPrimitivePaginatedHook } from "hooks/createPrimitiveHook";

export const useLogs = createPrimitivePaginatedHook(getLogs);
export const useLogsQuery = createPaginatedApiQuery(logbookApi.getLogsQuery);

export const logbookActions = {
  useLogsQuery,
};

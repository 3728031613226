import { ManufacturingPlan } from "api/manufacturingNew/models";
import { RightPanelSection, RightPanelTableRow } from "components/utils/drawer";
import { transportListConfig } from "../../utils/panelTablesConfig";
import { StateLabel } from "components/common/stateLabel";

export const Transport = ({ manufacturingPlan }: { manufacturingPlan: ManufacturingPlan }) => {
  return (
    <RightPanelSection title="Transport">
      <RightPanelTableRow
        className="tableHeaderGreyBg"
        hover={false}
        grid={transportListConfig.grid}
      >
        <div>trasa/grupa</div>
        <div>% skompletowania</div>
      </RightPanelTableRow>

      {manufacturingPlan.collectionsSlotsSummary?.collections.map(collection => {
        return (
          <RightPanelTableRow key={collection.signature} grid={transportListConfig.grid}>
            <div className="fs-12 fw-700">{collection.signature}</div>
            <div>
              <StateLabel
                className="fs-12 fw-700"
                kind={Number(collection.percentage) === 100 ? "darkGreen" : "none"}
              >
                {collection.percentage}%
              </StateLabel>
            </div>
          </RightPanelTableRow>
        );
      })}
    </RightPanelSection>
  );
};

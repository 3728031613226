import { LoadingsTab, UnloadingStatus, UnloadingTab } from "api/wms/models";
import { queryString } from "utilities";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  tab: LoadingsTab;
};

const statusDict: Record<LoadingsTab, UnloadingStatus> = {
  [UnloadingTab["not-started"]]: UnloadingStatus.NOT_STARTED,
  [UnloadingTab["in-progress"]]: UnloadingStatus.IN_PROGRESS,
  [UnloadingTab["finished"]]: UnloadingStatus.FINISHED,
  [UnloadingTab["all"]]: "" as UnloadingStatus,
};

export const getSearch = ({ query, tab }: GetSearchProps) => {
  const tabsQuery = {
    status: statusDict[tab],
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

import cx from "classnames";
import { useState } from "react";
import routeMarkerImg from "assets/images/mapMarkers/p29.png";
import styles from "./CustomerAddressModal.module.css";
import { Button } from "components/common";
import { ErrorMessage, FormInput, Modal } from "components/utils";
import { Formik, FormikHelpers } from "formik";
import { POLAND_CENTER } from "CONSTANTS";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { MapOverlay } from "./mapOverlay";
import { NonNullableObject } from "typeUtilities";
import { useToggle, useGeocoder, GoogleGeocodeStatus, useToastr, GoogleGeocodeResult } from "hooks";
import { yup } from "utilities";
import { postCustomerAddress } from "api/customers/calls";
import { Address } from "api/customers/models";

export const validationSchema = yup.object().shape({
  postCode: yup.string().required("To pole jest wymagane"),
  city: yup.string().required("To pole jest wymagane"),
  street: yup.string().required("To pole jest wymagane"),
  point: yup
    .object()
    .shape({
      lat: yup.number(),
      lng: yup.number(),
    })
    .nullable()
    .required("Punkt dostawy musi mieć lokalizację. Zaznacz go na mapie."),
});

interface Values {
  name: string;
  postCode: string;
  city: string;
  street: string;
  phone: string;
  externalAddressNumber: string;
  point: null | { lat: number; lng: number };
  countryCode: string;
}

const initialValues: Values = {
  name: "",
  postCode: "",
  city: "",
  street: "",
  externalAddressNumber: "",
  phone: "",
  point: null,
  countryCode: "",
};

interface Props {
  formType?: "post";
  modal: ReturnType<typeof useToggle>;
  customerId: number | null;
  onSubmit: (arg: Address) => void;
}

export const CustomerAddressModal = ({ modal, formType = "post", customerId, onSubmit }: Props) => {
  const [isLocalizing, setLocalizing] = useState(false);
  const toastr = useToastr();
  const geocoder = useGeocoder();

  const handleSubmit = async (values: Values, actions: FormikHelpers<Values>) => {
    if (values.point === null) {
      throw new Error(
        'Something bad happened: the form validation has passed the required "point" field',
      );
    }
    const payloadData = {
      ...values,
      customer: customerId,
    };
    const submit = {
      post: (data: Values) => postCustomerAddress(data as NonNullableObject<Values>),
    }[formType];

    const [payload, error] = await submit(payloadData);
    if (error) {
      if (error.message || error.detail) {
        alert(error.message || error.detail);
      }
      actions.setErrors(error);
    } else {
      onSubmit(payload);
      modal.close();
    }
  };
  const geocodeAddress = (
    address: string,
    setFieldValue: (field: string, value: any) => void,
    setLocalizing: (status: boolean) => void,
  ) => {
    setLocalizing(true);

    geocoder.geocode({ address }, (payload: GoogleGeocodeResult[], status: GoogleGeocodeStatus) => {
      setLocalizing(false);
      if (status === "OK") {
        const location = payload?.[0]?.geometry.location;
        const countryCode = (payload?.[0]?.address_components || [])
          .filter(addressComponent => addressComponent.types.includes("country"))
          .map(addressComponent => addressComponent.short_name)[0];
        const point = { lat: location.lat(), lng: location.lng() };
        setFieldValue("point", point);
        setFieldValue("countryCode", countryCode);
      } else {
        toastr.open({
          type: "warning",
          title: "Niepoprawna lokalizacja",
          text: "Nie możemy znaleźć podanego adresu.",
        });
      }
    });
  };

  return (
    <Modal isOpen={modal.isOpen} close={modal.close} size={{ lg: { width: "80vw" } }}>
      <div className={cx(styles.form, "pr-3 pl-3")}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, values, setFieldValue, isValid, isSubmitting, errors }) => (
            <form
              onSubmit={handleSubmit}
              className={cx({ "was-validated": !isValid }, styles.sections)}
            >
              <div className="d-flex mt-2">
                <div className={cx(styles.section, "mr-4 flex-1")}>
                  <FormInput name="name" label="Nazwa" />
                  <FormInput name="street" label="Ulica" />
                  <div className="d-flex">
                    <FormInput name="postCode" label="Kod pocztowy" className="flex-1 mr-2" />
                    <FormInput name="city" label="Miejscowość" className="flex-2" />
                  </div>
                  <FormInput name="phone" label="Telefon" className="flex-2" />
                  <FormInput name="externalAddressNumber" label="Kod magazynu" className="flex-2" />
                  <ErrorMessage name="isDefault" />
                  <ErrorMessage name="point" />
                  <div className="d-flex align-items-center justify-content-end">
                    <Button kind="primary" type="submit" disabled={isSubmitting} className="mb-3">
                      Zapisz{isSubmitting && "..."}
                    </Button>
                  </div>
                </div>
                <div className="flex-1 position-relative">
                  <GoogleMap
                    mapContainerStyle={{
                      height: "50vh",
                      width: "100%",
                    }}
                    onClick={(mapEvent: any) => {
                      setFieldValue("point", {
                        lat: mapEvent.latLng.lat(),
                        lng: mapEvent.latLng.lng(),
                      });
                    }}
                    zoom={6}
                    options={{
                      mapTypeControl: false,
                      streetViewControl: false,
                      fullscreenControl: false,
                      draggableCursor: "default",
                    }}
                    center={POLAND_CENTER}
                  >
                    {values.point && (
                      <Marker
                        position={values.point}
                        onClick={() => setFieldValue("point", null)}
                        icon={routeMarkerImg}
                      ></Marker>
                    )}
                  </GoogleMap>
                  <Button
                    disabled={!values.street || !values.postCode || !values.city || isLocalizing}
                    className={styles.localizeBtn}
                    kind="primary"
                    onClick={() =>
                      geocodeAddress(
                        `${values.street} ${values.postCode} ${values.city}`,
                        setFieldValue,
                        setLocalizing,
                      )
                    }
                  >
                    Lokalizuj adres
                  </Button>
                  <MapOverlay />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

import { callCenterRoutesKeys } from "api/call-center/routes/keys";
import { CallCenterDashboardLatestSmsMessages } from "api/call-center/routes/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useEffect } from "react";
import localStyles from "pages/callCenter/shared/messagesWithCustomer/MessagesWithCustomer.module.css";
import { cx, getAnyErrorKey } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useMutation } from "hooks/useMutation";
import { patchMessage } from "api/messages/calls";
import { useQueryClient } from "react-query";
import { Pagination } from "api/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useToastr } from "hooks";

interface Props {
  close: () => void;
  latestSmsMessage: CallCenterDashboardLatestSmsMessages;
  search: string;
}

export const UnreadMessageModal = ({ close, latestSmsMessage, search }: Props) => {
  const queryClient = useQueryClient();
  const toastr = useToastr();
  const patchMessageMutation = useMutation(patchMessage);

  useEffect(() => {
    if (latestSmsMessage.isRead) return;

    const unreadMessageHandler = setTimeout(() => {
      patchMessageMutation.mutate(
        { id: String(latestSmsMessage.id), data: { isRead: true } },
        {
          onSuccess: (_, args) => {
            queryClient.setQueryData<Pagination<CallCenterDashboardLatestSmsMessages>>(
              callCenterRoutesKeys.dashboardLatestSmsMessage.list(search),
              currentList => {
                assertIsDefined(currentList);
                return {
                  ...currentList,
                  results: currentList.results.map(message => {
                    if (message.id === Number(args.id)) {
                      return {
                        ...message,
                        isRead: true,
                      };
                    }
                    return message;
                  }),
                };
              },
            );
          },
          onError: error => {
            toastr.open({
              type: "warning",
              title: "Wymagane działanie",
              text: getAnyErrorKey(error),
            });
          },
        },
      );
    }, 5000);

    return () => {
      clearTimeout(unreadMessageHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="600">
          {latestSmsMessage.isRead ? "Odczytana wiadomość" : "Nieodczytana wiadomość"}
        </Typography>
      }
      width={480}
    >
      <>
        <div className="px-3 py-2">
          <div className="d-flex align-items-center gap-2">
            <Typography fontSize="14" fontWeight="700">
              {latestSmsMessage.order.firstName} {latestSmsMessage.order.lastName}
            </Typography>
            <Typography color="neutralBlack48" fontSize="14" fontWeight="400">
              ({latestSmsMessage.route.signature})
            </Typography>
          </div>
          <div className="px-3 py-2">
            <div className={cx(localStyles.cloud, localStyles.cloudClient)}>
              {latestSmsMessage.body}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between p-3 borderTop">
          <Button size="medium" variant="gray" onClick={close}>
            ZAMKNIJ
          </Button>
        </div>
      </>
    </Modal>
  );
};

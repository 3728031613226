import { ApiMiddlewareResult } from "apiConnectors/fetchConnector";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { getAnyErrorKey, tuplify } from "./utilities";
import { usePrevious } from "hooks";

interface Options<Payload, Res, K extends keyof Payload> {
  updateCallback?(payload: Res, value: Payload[K], namespace: K, resetKeyHasChanged: boolean): void;
  namespaceCallbacks?: { [K in keyof Payload]: (arg: Res, arg2: Payload[K]) => void };
  abortToken?: string;
}

interface NCOptions<UpdatedPart> {
  /**
   * Proxy is called just before submit, it allows you to modify
   * the payload. It is mostly used in cases, when local state is full
   * object, but API takes only id on PATCH
   */
  proxy?(arg: UpdatedPart): any;
  validate?: (value: UpdatedPart) => string;
}

export type AsyncUpdateConnectorFieldState = {
  setError: (err: string) => void;
  setFocused: () => void;
  setBusy: () => void;
  clearFocused: () => void;
  error: string;
  isFetching: boolean;
  name: string | number | symbol;
  isBusy: boolean;
};

const initialState = {
  error: "",
  isFetching: false,
  isFocused: false,
  isBusy: false,
};

/**
 *
 * @example
 *   export const useUpdateCustomer = createAsyncUpdateConnector(patchCustomer);
 *   ...
 *   const useConnector = useUpdateCustomer(panelId, {
 *     updateCallback: (_payload, toUpdate) => dispatch({ type: "UPDATE", payload: toUpdate }),
 *     namespaceCallbacks: {
 *       fullName: (payload, sentValue) => dispatch({ type: "UPDATE_FULL_NAME", payload }),
 *     },
 *   });
 *
 *   const [nameConnector, nameState] = useConnector("name");
 *   const [fullNameConnector, fullNameState] = useConnector("fullName");
 *   ...
 *   <ProxyInput
 *     label="Pełna nazwa"
 *     value={customer.fullName}
 *     fieldState={fullNameState}
 *     onChange={fullNameConnector}
 *     mode="editable"
 *   />
 */
export function createAsyncUpdateConnector<Res, Payload>(
  func: (arg1: number | string, arg2: Payload, abortToken?: string) => ApiMiddlewareResult<Res>,
) {
  function useAsyncUpdate<K extends keyof Payload>(
    instanceId: number | string,
    options: Options<Payload, Res, K>,
  ) {
    const resetKeyHolder = useRef(instanceId);
    useEffect(() => {
      resetKeyHolder.current = instanceId;
    }, [instanceId]);

    function useNamespacedConnector<FieldType extends Payload[K]>(
      namespace: K,
      ncOptions: NCOptions<FieldType> = {},
    ) {
      const prevInstanceId = usePrevious(instanceId);
      const initialMount = useRef(true);
      const isFocused = useRef(false);
      const submitCallback = useRef<(() => void) | null>(null);
      const [state, setState] = useState<{
        error: string;
        isFetching: boolean;
        isBusy: boolean;
      }>(initialState);
      const submitProxy = ncOptions.proxy;
      useEffect(() => {
        if (initialMount.current) {
          initialMount.current = false;
          return;
        }
        if (prevInstanceId !== instanceId) {
          setState(initialState);
        }
      }, [prevInstanceId]);

      async function namespacedConnector(value: FieldType) {
        const validationError = ncOptions.validate?.(value) ?? "";
        if (validationError) {
          setState(s => ({ ...s, error: validationError, isBusy: true }));
          return;
        }
        setState(s => ({ ...s, isFetching: true, isBusy: true }));
        const resetKey = resetKeyHolder.current;
        const [payload, error, { isCanceled }] = await func(
          instanceId,
          submitProxy ? submitProxy(value) : { [namespace]: value },
          options.abortToken,
        );

        const submitCallbackHandler = () => {
          const resetKeyHasChanged = resetKeyHolder.current !== resetKey;
          if (payload) {
            setState(s => ({ ...s, isFetching: false, error: "" }));
            if (options.namespaceCallbacks && options.namespaceCallbacks[namespace]) {
              options.namespaceCallbacks[namespace](payload, value);
            } else {
              options.updateCallback?.(payload, value, namespace, resetKeyHasChanged);
            }
          } else if (error && !isCanceled) {
            setState(s => ({
              ...s,
              isFetching: false,
              error: getAnyErrorKey(error, namespace as string),
            }));
          }
        };
        if (isFocused.current && payload) {
          setState(s => ({ ...s, isFetching: false, error: "" }));
          submitCallback.current = submitCallbackHandler;
        } else {
          submitCallback.current = null;
          submitCallbackHandler();
        }
      }
      const setError = useCallback(
        (err: string) =>
          setState(p => {
            if (err === p.error) return p;
            return { ...p, error: err };
          }),
        [],
      );
      const setBusy = useCallback(
        () =>
          setState(p => {
            if (p.isBusy) return p;
            return { ...p, isBusy: true };
          }),
        [],
      );
      const setFocused = useCallback(() => {
        isFocused.current = true;
      }, []);
      const clearFocused = useCallback(() => {
        isFocused.current = false;
        setState(p => ({ ...p, isBusy: false }));
        if (submitCallback.current) {
          submitCallback.current();
          submitCallback.current = null;
        }
      }, []);
      const statuses: AsyncUpdateConnectorFieldState = useMemo(
        () => ({
          ...state,
          isBusy: state.isBusy || state.isFetching,
          setError,
          setBusy,
          setFocused,
          clearFocused,
          name: namespace,
        }),
        [state, setError, setFocused, clearFocused, namespace, setBusy],
      );
      return tuplify(namespacedConnector, statuses);
    }
    return useNamespacedConnector;
  }
  return useAsyncUpdate;
}

import { RouteMessages } from "api/routes/models";
import magnifierIcon from "assets/images/g18603.svg";
import cx from "classnames";
import { FilterDropdownManual } from "components/common/toolbar/filterDropdown/FilterDropdown";
import { CommonError, Modal, Spinner } from "components/utils";
import { ToggleHookState, useQuery, useSettings } from "hooks";
import { useRouteSmsMessages } from "hooks/apiPrimitives";
import { useFilters } from "hooks/useFilters";
import { useState } from "react";
import { useHistory } from "react-router";
import { dateFns } from "utilities";
import eventAvailableIcon from "assets/images/eventAvailable.svg";
import eventBusyIcon from "assets/images/eventBusy.svg";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import { highlightTextFragment } from "utilities/highlightTextFragment";
import { ConfirmedBy } from "./ConfirmedBy";
import styles from "./ConversationModal.module.css";
import { OrderConfirmation } from "./OrderConfirmation";
import { OrderConversation } from "./OrderConversation";
import { RightPanel } from "./RightPanel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const ConversationModal = ({ modal: { close, isOpen } }: { modal: ToggleHookState }) => {
  return (
    <Modal
      isOpen={isOpen}
      close={close}
      size="auto"
      overrides={{ container: { className: styles.modal } }}
    >
      <Content />
    </Modal>
  );
};

interface Filters {
  status: string;
  confirmedBy: string;
  search: string;
}

const initialFilters: Filters = { confirmedBy: "", status: "", search: "" };

const Content = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data, error, isLoading } = useRouteSmsMessages(panelId);
  const { filters, setFilter } = useFilters<Filters>(initialFilters);
  const [order, setOrder] = useState<number | null>(null);
  const history = useHistory();
  const { transport } = useSettings();
  const hasDeadlineConfirmationDate = transport.hasDeadlineConfirmationDate;

  if (error) {
    return <CommonError status={error._httpStatus_} />;
  }

  if (!data || isLoading) {
    return (
      <div className="h-100 d-flex flex-column">
        <Spinner color="blue" size="big" text="trwa wczytywanie" position="absolute" />
      </div>
    );
  }

  const orders = getOrdersBasedOnFilters(data.orders, filters);

  return (
    <div className="h-100 d-flex flex-column">
      <div className={styles.title}>Powiadomienia na trasie</div>
      <div className={styles.filtersContainer}>
        <input
          className={styles.input}
          value={filters.search}
          onChange={e => setFilter("search", e.target.value)}
          placeholder="Szukaj"
        />
        <div className="d-flex">
          <FilterDropdownManual
            value={filters.status}
            label="status"
            onClick={value => {
              setFilter("status", value);
            }}
            options={[
              { label: "Wszystkie", value: "" },
              { label: "Oczekujące", value: "false" },
              { label: "Potwierdzone", value: "true" },
            ]}
          />
          <FilterDropdownManual
            value={filters.confirmedBy}
            onClick={value => {
              setFilter("confirmedBy", value);
            }}
            label="potwierdzono przez"
            options={[
              { label: "Wszystkie", value: "" },
              { label: "Klient", value: "client" },
              { label: "Użytkownik Milo", value: "milo" },
            ]}
          />
        </div>
      </div>
      <div className="position-relative flex-1">
        <div className={cx(styles.row, styles.header, "flex-grow-1")}>
          <div>lp.</div>
          <div>zamówienie</div>
          <div>konto sprzedażowe</div>
          <div>konwersacja z klientem</div>
          <div>potwierdzenie</div>
          <div>kto i kiedy potwierdził</div>
        </div>

        <div className={styles.ordersContainer}>
          {orders.length ? (
            orders.map((order, index) => {
              const highlightedSignature = highlightTextFragment(
                filters.search,
                order.signature,
                order.id,
              );

              const highlightedFullName = highlightTextFragment(
                filters.search,
                `${order.client.firstName} ${order.client.lastName}`,
                order.id,
              );

              const highlightedPhone = highlightTextFragment(
                filters.search,
                order.client.phone,
                order.id,
              );

              const highlightedName = highlightTextFragment(filters.search, order.name, order.id);
              return (
                <div className={styles.row} key={order.id}>
                  <div className="d-flex align-items-center fs-14">{index + 1}.</div>
                  <div className="d-flex flex-column">
                    <div
                      className={cx(styles.signature, "orderRedirectSignature")}
                      onClick={() => history.push(getOrderRedirectUrl(order))}
                    >
                      {highlightedSignature}
                    </div>
                    <div className={styles.greyText}>{highlightedName}</div>
                    <div className={styles.greyText}>{highlightedFullName}</div>
                    <div className={styles.tel}>
                      <span className={styles.greyText}>tel.</span> {highlightedPhone}
                    </div>
                    {order.minimumCollectionHourAtConfirmation.length > 0 &&
                      order.maximumCollectionHourAtConfirmation.length > 0 && (
                        <div className="d-flex align-items-center fs-12">
                          {order.minimumCollectionHourAtConfirmation} -{" "}
                          {order.maximumCollectionHourAtConfirmation}
                        </div>
                      )}
                  </div>
                  {Boolean(order.salesAccount) ? (
                    <div className="d-flex flex-column justify-content-center">
                      <div className="d-flex align-items-center gap-2">
                        <Typography
                          className={styles.salesAccountLabel}
                          color="neutralBlack48"
                          fontSize="10"
                          fontWeight="500"
                        >
                          typ:{" "}
                        </Typography>
                        <Typography
                          className={styles.salesAccountLabel}
                          color="neutralBlack100"
                          fontSize="12"
                          fontWeight="600"
                        >
                          {order.salesAccount.channel}
                        </Typography>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <Typography
                          className={styles.salesAccountLabel}
                          color="neutralBlack48"
                          fontSize="10"
                          fontWeight="500"
                        >
                          nazwa:{" "}
                        </Typography>
                        <Typography
                          className={styles.salesAccountLabel}
                          color="neutralBlack100"
                          fontSize="12"
                          fontWeight="600"
                        >
                          {order.salesAccount.name}
                        </Typography>
                      </div>
                      <div></div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <EmptyValue />
                    </div>
                  )}
                  <div className="d-flex align-items-center mr-3">
                    <OrderConversation
                      order={order}
                      openRightPanel={id => {
                        setOrder(id);
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <OrderConfirmation order={order} />
                  </div>
                  <div>
                    {hasDeadlineConfirmationDate && order.deliveryConfirmationDeadline && (
                      <div className="d-flex align-items-center fs-10 text-color-grey mb-1">
                        <span>potwierdzić do: &nbsp;</span>
                        <span className="text-color-black">
                          {dateFns.format(
                            new Date(order.deliveryConfirmationDeadline),
                            "dd.MM.yyyy HH:mm",
                          )}
                        </span>
                      </div>
                    )}
                    {order.isDeliveryDateConfirmed && <ConfirmedBy order={order} />}
                    {hasDeadlineConfirmationDate &&
                      order.deliveryConfirmationDeadline &&
                      order.deliveryDateConfirmation &&
                      new Date(String(order.deliveryConfirmationDeadline)) <
                        new Date(String(order.deliveryDateConfirmation)) && (
                        <div className="d-flex align-items-center mt-1">
                          <img alt="Termin przekroczony" className="mr-1" src={eventBusyIcon} />
                          <span className={styles.deadlineLabel}>po terminie</span>
                        </div>
                      )}
                    {hasDeadlineConfirmationDate &&
                      order.deliveryConfirmationDeadline &&
                      order.deliveryDateConfirmation &&
                      new Date(String(order.deliveryConfirmationDeadline)) >
                        new Date(String(order.deliveryDateConfirmation)) && (
                        <div className="d-flex align-items-center mt-1">
                          <img
                            alt="Termin przekroczony"
                            className="mr-1"
                            src={eventAvailableIcon}
                          />
                          <span className={styles.inTimeLabel}>w terminie</span>
                        </div>
                      )}
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className={cx(
                "d-flex align-items-center h-100 flex-column justify-content-center w-100",
                styles.noContent,
              )}
            >
              <img src={magnifierIcon} alt="" />
              <div>Niczego nie znaleziono</div>
              <span>Zmień kryteria i spróbuj wyszukać ponownie</span>
            </div>
          )}
        </div>

        {order && <RightPanel close={() => setOrder(null)} order={order} />}
      </div>
    </div>
  );
};

const getOrdersBasedOnFilters = (orders: RouteMessages["orders"], filters: Filters) => {
  if (Object.values(filters).every(el => !el)) return orders;

  return orders
    .filter(order => {
      if (!filters.search) return true;

      if (
        `${order.signature} ${order.client.firstName.trim()} ${order.client.lastName.trim()} ${
          order.client.phone
        } ${order.name}`
          .toLowerCase()
          .includes(filters.search.toLowerCase())
      ) {
        return true;
      }
      return false;
    })
    .filter(order => {
      if (!filters.status) return true;

      if (filters.status === String(order.isDeliveryDateConfirmed)) {
        return true;
      }
      return false;
    })
    .filter(order => {
      if (!filters.confirmedBy) return true;
      if (
        filters.confirmedBy === "milo" &&
        order.isDeliveryDateConfirmed &&
        order.deliveryDateConfirmedBy
      ) {
        return true;
      }
      if (
        filters.confirmedBy === "client" &&
        order.isDeliveryDateConfirmed &&
        !order.deliveryDateConfirmedBy
      ) {
        return true;
      }
      return false;
    });
};

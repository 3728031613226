import { convertSecondsToHoursMinutesAndSeconds } from "utilities/convertSecondsToHoursAndMinutes";
import styles from "../RouteTrackingModal.module.css";

interface Props {
  departureDelay: number;
}

export const DepartureDelay = ({ departureDelay }: Props) => {
  return (
    <div>
      <span className={styles.labelText}>Początkowe opóźnienie:</span>
      {departureDelay <= 0 ? (
        <strong className="body-14-600 text-black-6"> brak</strong>
      ) : (
        <strong className="body-14 fw-800">
          {" "}
          {convertSecondsToHoursMinutesAndSeconds(departureDelay, false)}
        </strong>
      )}
    </div>
  );
};

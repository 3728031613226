import { useQuery } from "hooks";
import v4 from "cuid";
import { Chip } from "../shared/shared";
import { useState } from "react";
import { useEffect } from "react";
import { formatMonth } from "./utils/formatMonth";
import { handleNotCheckedMonth } from "./utils/handleNotCheckedMonth";
import { handleCheckedMonth } from "./utils/handleCheckedMonth";

export interface Props {
  name: [string, string];
  additionalKeys: [string, string] | undefined;
  months: [Date, Date, Date];
}

export const MonthPicker = ({ additionalKeys, name, months }: Props) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });

  const [startDateName, endDateName] = name;

  const queryStartDate = query[startDateName];
  const queryEndDate = query[endDateName];

  const [monthsToDisplay, setMonthsToDisplay] = useState(
    months.map((month, index) => {
      const monthDate = formatMonth(month);
      return {
        checked: false,
        key: v4(),
        label: monthDate.displayValue,
        name: monthDate.displayValue,
        position: index,
        value: [monthDate.firstDay, monthDate.lastDay],
      };
    }),
  );

  useEffect(() => {
    if (queryStartDate === undefined && queryEndDate === undefined) {
      setMonthsToDisplay(months => {
        return months.map(month => {
          return {
            ...month,
            checked: false,
          };
        });
      });
    }
  }, [queryStartDate, queryEndDate]);

  const handleChange = (dateRanges: string[] | string | null, name: string, checked?: boolean) => {
    if (!checked) {
      handleNotCheckedMonth(
        dateRanges,
        startDateName,
        endDateName,
        name,
        queryStartDate,
        queryEndDate,
        setMonthsToDisplay,
        updateQuery,
        additionalKeys,
      );
    } else {
      handleCheckedMonth(
        dateRanges,
        startDateName,
        endDateName,
        name,
        queryStartDate,
        queryEndDate,
        monthsToDisplay,
        setMonthsToDisplay,
        updateQuery,
        additionalKeys,
      );
    }
  };

  return (
    <div className="mt-3">
      {monthsToDisplay.map(({ label, name, value: [startDate, endDate], key, checked }) => {
        return (
          <Chip<string[]>
            key={key}
            checked={checked}
            handleChange={handleChange}
            label={label}
            name={name}
            value={[startDate, endDate]}
          />
        );
      })}
    </div>
  );
};

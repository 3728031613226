import { FLAVOR } from "CONSTANTS";
import { Route } from "components/utils";
import { ManufacturerLineItemsList as ManufacturerLineItemsListFlavor } from "pages/externalManufacturerPanel/manufacturerLineItemsList/ManufacturerLineItemsList";
import { OrderLineItems } from "pages/externalManufacturing/orderLineItems/OrderLineItems";
import { NotFound } from "pages/notFound";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const ManufacturerLineItems = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/to-do`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/to-do`} />}
      />
      <Route
        component={
          FLAVOR === "externalManufacturing" ? ManufacturerLineItemsListFlavor : OrderLineItems
        }
        exact={true}
        path={`${match.path}/list/:tab`}
        title="Milo - pojedyncze zlecenia produktowe"
      />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};

import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";

export const externalManufacturingKeys = {
  main: {
    manufacturingOrders: {
      list: createPaginatedKey("mainExternalManufacturingOrders"),
      details: (id: UUID) => ["mainExternalManufacturingOrder", id],
    },
    manufacturingLineItems: {
      list: createPaginatedKey("mainExternalManufacturingLineItems"),
    },
    orderLineItems: {
      list: createPaginatedKey("orderLineItems"),
      details: (id: UUID) => ["orderLineItem", id],
    },
    routeLineItems: {
      list: createPaginatedKey("routeLineItems"),
    },
    emailsSchedule: {
      list: (search: string) => ["emailsSchedule", search],
    },
  },
  externalManufacturer: {
    manufacturingOrders: {
      list: createPaginatedKey("externalManufacturerItems"),
      details: (id: UUID) => ["externalManufacturerItem", id],
    },
    manufacturingLineItems: {
      list: createPaginatedKey("externalManufacturerLineItems"),
      details: (id: UUID) => ["externalManufacturerLineItem", id],
    },
  },
};

import { PickingDetails } from "api/wms/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateFns } from "utilities";

interface Props {
  picking: PickingDetails;
}

export const PickingDeparture = ({ picking }: Props) => {
  if (!picking.estimatedTimeOfDeparture)
    return (
      <Typography fontSize="14" fontWeight="500">
        ---
      </Typography>
    );
  return (
    <Typography fontSize="14" fontWeight="500">
      {picking.estimatedTimeOfDeparture
        ? dateFns.formatRelative(new Date(picking.estimatedTimeOfDeparture))
        : "---"}
    </Typography>
  );
};

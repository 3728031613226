import immer from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";

export interface ListCheckboxesState<T extends { id: number }> {
  values: Record<number, T>;
  valuesArray: T[];
  toggle: (order: T) => void;
  check: (id: number) => boolean;
  reset: () => void;
  areAllChecked: (ids: number[]) => boolean;
  addMany: (orders: T[]) => void;
}

export function useListCheckboxes<T extends { id: number } & Record<string, any>>(mode: any) {
  const [values, setValues] = useState<Record<number, T>>({});

  const toggle = useCallback(
    (order: T) => {
      if (values[order.id]) {
        setValues(s => {
          const newState = { ...s };
          delete newState[order.id];
          return newState;
        });
      } else {
        setValues(s => ({ ...s, [order.id]: order }));
      }
    },
    [values],
  );

  useEffect(() => {
    setValues([]);
  }, [mode]);

  const valuesArray = useMemo(
    () =>
      Object.entries(values)
        .map(([, val]) => val)
        .filter(Boolean),
    [values],
  );

  const check = useCallback((id: number) => Boolean(values[id]), [values]);
  const reset = useCallback(() => setValues({}), []);
  const areAllChecked = useCallback((ids: number[]) => ids.every(id => values[id]), [values]);

  const addMany = useCallback(
    (orders: T[]) => {
      const valuesToAdd = orders.reduce((acc: Record<number, T>, order) => {
        acc[order.id] = order;
        return acc;
      }, {});
      setValues({ ...values, ...valuesToAdd });
    },
    [values],
  );

  const removeMany = useCallback((orders: number[]) => {
    setValues(
      immer(draft => {
        orders.forEach(orderId => {
          delete draft[orderId];
        });
      }),
    );
  }, []);

  return useMemo(
    () => ({ values, valuesArray, toggle, check, reset, addMany, areAllChecked, removeMany }),
    [check, toggle, values, valuesArray, reset, addMany, areAllChecked, removeMany],
  );
}

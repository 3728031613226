import billIcon from "assets/images/bill.svg";
import { EmptySection } from "../../shared/EmptySection";

export const EmptyPurchaseSection = () => {
  // add invoice

  return (
    <EmptySection
      btnLabel="Utwórz fakturę"
      img={billIcon}
      title="Fakturowanie, dzięki któremu szybko załatwisz formalności"
    />
  );
};

import calculateIcon from "assets/images/calculatePink.svg";
import customersIcon from "assets/images/customers.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import favicon from "assets/images/favicons/fv_customers.svg";

export const crm: ModuleNavigation = {
  favicon,
  labelBackground: "#b65eff",
  label: "Kontrahenci",
  iconBackground: "#431d87",
  icon: calculateIcon,
  menuIcon: customersIcon,
  name: "CRM",
  url: "/crm",
  navigationSections: [{ navigationList: [] }],
};

import { patchTradingDocumentSummary } from "api/trading-documents/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { UUID } from "api/types";
import { useQueryUtils } from "hooks";
import { useMutation } from "hooks/useMutation";
import { useState } from "react";

export const useEditTradingDocumentTotalValue = (tradingDocumentId: UUID) => {
  const [showInput, setShowInput] = useState(false);
  const { handlePaginatedListUpdate, rollbackList } = useQueryUtils();

  const editItemTotalValueMutation = useMutation(
    patchTradingDocumentSummary,
    ({ queryClient, toastr }) => ({
      onMutate: toUpdate => {
        const prevList = handlePaginatedListUpdate(
          tradingDocumentsKeys.tradingDocument.list(),
          toUpdate.id,
          toUpdate,
        );
        return { prevList };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(tradingDocumentId),
        );
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Zmodyfikowano kwotę całkowitą",
        });
      },
      onError: (error, { id }, context) => {
        rollbackList(tradingDocumentsKeys.tradingDocument.list(), context?.prevList, id);
      },
      onSettled: () => {
        setShowInput(false);
      },
    }),
  );

  return {
    editItemTotalValueMutation,
    setShowInput,
    showInput,
  };
};

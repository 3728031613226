import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AsyncInput } from "components/utils";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../RightPanel.module.css";
import QRCode from "qrcode.react";
import { wmsRampActions } from "api/wms/ramps/actions";
import { RampListItem } from "api/wms/ramps/models";

interface Props {
  ramp: RampListItem;
}

export const GeneralInfoSection = ({ ramp }: Props) => {
  const nameMutation = wmsRampActions.usePatchRamp();
  const codeMutation = wmsRampActions.usePatchRamp();
  const hallMutation = wmsRampActions.usePatchRamp();

  return (
    <RightPanelSection padding="px-3 pt-0 pb-2">
      <div className="d-flex align-items-start justify-content-between gap-2">
        <div>
          <InfoLabel title="nazwa">
            <AsyncInput
              disabled={nameMutation.isLoading}
              inProgress={nameMutation.isLoading}
              onChange={value =>
                nameMutation.mutate({
                  id: ramp.id,
                  toUpdate: {
                    name: value,
                  },
                })
              }
              overwrites={{ container: { className: styles.input } }}
              placeholder="nazwa"
              value={ramp.name}
            />
          </InfoLabel>

          <InfoLabel title="kod rampy">
            <AsyncInput
              disabled={codeMutation.isLoading}
              inProgress={codeMutation.isLoading}
              onChange={value =>
                codeMutation.mutate({
                  id: ramp.id,
                  toUpdate: {
                    code: value,
                  },
                })
              }
              overwrites={{ container: { className: styles.input } }}
              placeholder="kod rampy"
              value={ramp.code}
            />
          </InfoLabel>

          <InfoLabel title="hala">
            <AsyncInput
              disabled={hallMutation.isLoading}
              inProgress={hallMutation.isLoading}
              onChange={value =>
                hallMutation.mutate({
                  id: ramp.id,
                  toUpdate: {
                    hall: value,
                  },
                })
              }
              overwrites={{ container: { className: styles.input } }}
              placeholder="hala"
              value={ramp.hall}
            />
          </InfoLabel>
        </div>
        <div className="d-flex flex-column gap-1">
          <div>
            <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
              QR kod rampy
            </Typography>
          </div>
          <QRCode value={ramp.qrCode} />
        </div>
      </div>
    </RightPanelSection>
  );
};

import { useState, useCallback, useEffect } from "react";
import { usePrevious } from "hooks/hooks";

interface Attrs {
  isOpen: boolean;
  setOpen: (arg: boolean) => void;
  open: () => void;
  close: () => void;
  toggle: () => void;
}
interface Props {
  initialValue?: boolean;
  children: (args: Attrs) => React.ReactElement;
  onOpen?: () => void;
}
export const Toggle = ({ initialValue = false, children, onOpen }: Props) => {
  const [isOpen, setOpen] = useState(initialValue);
  const prevIsOpen = usePrevious(isOpen);
  const close = useCallback(() => setOpen(false), []);
  const open = useCallback(() => setOpen(true), []);
  const toggle = useCallback(() => setOpen(!isOpen), [isOpen]);

  useEffect(() => {
    if (isOpen && !prevIsOpen && onOpen) {
      onOpen();
    }
  }, [isOpen, onOpen, prevIsOpen]);

  return children({ isOpen, setOpen, open, close, toggle });
};

import { TradingDocument } from "api/trading-documents/models";
import { Button } from "components/common";
import { useMutation } from "hooks/useMutation";
import taskDoneIcon from "assets/images/taskDone.svg";
import sellerIcon from "assets/images/businessCenterDark.svg";
import styles from "../../EditTradingDocument.module.css";
import cx from "classnames";
import { useSelector } from "hooks";
import { AdvancedSelect } from "components/common/advancedSelect/AdvancedSelect";
import { Icon } from "components/utils";

interface Props {
  setIsSellerChanging: React.Dispatch<React.SetStateAction<boolean>>;
  tradingDocument: TradingDocument;
  updateOptions: any;
}

export const SellerChange = ({ setIsSellerChanging, tradingDocument, updateOptions }: Props) => {
  const sellers = useSelector(store => store.partials.sellers);
  const sellerMutation = useMutation(updateOptions.mutationFn, updateOptions);

  return (
    <div>
      <div className="pr-3">
        <AdvancedSelect
          enableClear={false}
          inputPlaceholder="Szukaj sprzedawcy..."
          items={sellers.map(seller => {
            return {
              id: seller.id,
              name: seller.companyName,
              filters: {
                companyName: seller.companyName,
              },
            };
          })}
          itemsWidth={380}
          itemToDisplaySelected={item => (
            <div className="d-flex align-items-center">
              <Icon img={sellerIcon} type="round" className={cx("mr-2", styles.sellerImg)} />
              <span className="text-nowrap text-truncate overflow-hidden">
                {item ? item.name : "Wybierz sprzedawcę"}
              </span>
            </div>
          )}
          onChange={value => {
            value &&
              sellerMutation.mutate({
                seller: sellers.find(seller => seller.id === value.id),
                id: tradingDocument.id,
              });
            value === null &&
              sellerMutation.mutate({
                seller: null,
                id: tradingDocument.id,
              });
          }}
          selectedItem={tradingDocument.seller?.id ?? null}
        />
      </div>
      <div className="mt-3 d-flex justify-content-end pr-3">
        <Button
          className={styles.editDataBtn}
          kind="secondary-grey"
          onClick={() => setIsSellerChanging(prev => !prev)}
          size="small"
        >
          <div className={cx(styles.editDataBtnContent, "d-flex align-items-center")}>
            <img alt="Edytuj" src={taskDoneIcon} style={{ height: "16px", width: "16px" }} />
            <span>Zakończ edycję</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

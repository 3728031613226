import { removeWorkingDayFromProductionPlan } from "api/new-production-plans/calls";
import { useProductionPlanSchedule } from "api/new-production-plans/hooks";
import { productionPlansKeys } from "api/new-production-plans/keys";
import { WorkingDay } from "api/new-production-plans/models";
import { UUID } from "api/types";
import { polishDaysNamesDict } from "CONSTANTS";
import { useConfirmModal } from "hooks";
import { useMutation } from "hooks/useMutation";
import { dateFns } from "utilities";

export const useRemoveWorkingDay = (day: WorkingDay, productionPlanId: UUID) => {
  const confirmationModal = useConfirmModal();
  const { handleMutate, refetch } = useProductionPlanSchedule(productionPlanId, { enabled: false });

  const removeWorkingDayMutation = useMutation(
    removeWorkingDayFromProductionPlan,
    ({ queryClient }) => ({
      onMutate: args => {
        return handleMutate(draft => {
          draft.days = draft.days.filter(day => day.date !== args.workingDays[0]);
          draft.employees = draft.employees.map(employee => {
            const updatedEmployeeWorkingDays = employee.employeeWorkingDays.filter(
              employeeWorkingDay => employeeWorkingDay.date !== args.workingDays[0],
            );

            return { ...employee, employeeWorkingDays: updatedEmployeeWorkingDays };
          });
        });
      },
      onSuccess: () => {
        refetch();
        queryClient.invalidateQueries(productionPlansKeys.shelfItems(productionPlanId));
      },
      onError: (error, _, rollback) => {
        // @ts-ignore
        rollback(error);
      },
    }),
  );

  const handleWorkingDayRemoval = () => {
    confirmationModal.open({
      text: `Czy na pewno chcesz usunąć z planu dzień "${
        polishDaysNamesDict[day.dayOfTheWeek]
      }, ${dateFns.format(new Date(day.date), "dd/MM/yyyy")}"?`,
      confirmText: "Usuń",
      callback: () =>
        removeWorkingDayMutation.mutate({
          workingDays: [day.date],
          stageManufacturingPlanId: productionPlanId,
        }),
    });
  };

  return handleWorkingDayRemoval;
};

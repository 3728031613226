import { PinToRoute } from "api/routes/models";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { dateFns } from "utilities";
import styles from "../InfoWindowContent.module.css";

interface Props {
  unpinnedLog: PinToRoute;
}

export const UnpinnedLog = ({ unpinnedLog }: Props) => {
  return (
    <div className="d-flex align-items-center fs-12 pt-1 pb-1 border-bottom w-100">
      <div className="col-4 fw-700 pl-1">{unpinnedLog.route.signature}</div>
      <div className="col-4 text-black-6 pl-1">
        {dateFns.format(new Date(unpinnedLog.actionAt), "dd.MM.yyyy, HH:mm")}
      </div>
      <div className="col-4 d-flex pl-0">
        <div className="d-flex align-items-center gap-2">
          <AvatarOrInitials
            avatarSrc={unpinnedLog.createdBy.avatar}
            firstName={unpinnedLog.createdBy.firstName}
            lastName={unpinnedLog.createdBy.lastName}
            className={styles.avatar}
          />
          <div className="overflow">
            {unpinnedLog.createdBy.firstName} {unpinnedLog.createdBy.lastName}
          </div>
        </div>
      </div>
    </div>
  );
};

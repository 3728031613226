import { useOverviewReceivedForCustomer } from "api/wms/hooks";
import { Table } from "components/miloDesignSystem/molecules/table";
import styles from "../Overview.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useOverviewReceivedForCustomerListColumns } from "../utils/useOverviewReceivedForCustomerListColumns";
import { queryString } from "utilities";
import { useGenericTableFilters } from "components/common/genericTable/useGenericTableFilters";
import { useFilters } from "hooks/useFilters";
import { useSelector } from "hooks";
import { GenericTable } from "components/common/genericTable/GenericTable";
import { detailsListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { parseTableRowData } from "components/common/genericTable/utils";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";

export const OverviewReceivedForCustomer = () => {
  const { filters: customFilters, setFilter } = useFilters({ customerId: "" });
  const customers = useSelector(state => state.partials.customers);

  const { setDateFrom, setRowBy, filters } = useGenericTableFilters();
  const search = queryString.stringify({ ...filters, ...customFilters });
  const {
    data: itemsReceived,
    isLoading,
    error,
    isFetching,
  } = useOverviewReceivedForCustomer(search, { keepPreviousData: true });
  const columns = useOverviewReceivedForCustomerListColumns(itemsReceived, filters);
  const normalizedRows = parseTableRowData(itemsReceived);

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <Typography fontSize="16" fontWeight="600">
          Przyjęcia - Kontrahenci
        </Typography>
        <div className="d-flex align-items-center gap-2">
          <Select
            className={styles.select}
            label="Kontrahent"
            items={customers.map(customer => ({
              text: customer.name,
              value: customer.id,
              type: MenuItemType.TEXT,
            }))}
            onChange={id => {
              if (id) setFilter("customerId", String(id));
            }}
            selected={customFilters.customerId}
          />
          <GenericTable.RowBySelect rowBy={filters.rowBy} setRowBy={setRowBy} />
        </div>
      </div>

      <div className={styles.tableWrapper}>
        <Table
          rows={normalizedRows}
          // @ts-ignore
          columns={columns}
          error={error}
          isLoading={isLoading || isFetching}
          uiSchema={detailsListUiSchema}
        />
      </div>
      <GenericTable.Footer rowBy={filters.rowBy} setDateFrom={setDateFrom} />
    </div>
  );
};

import { UUID } from "api/types";
import { wmsFileFactory } from "api/wms/calls";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";

export const useDownloadLoadingLineItemPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();

  async function handleDownloadLoadingLineItemPdf(
    lineItemId: UUID,
    loading: UUID,
    signature: string,
  ) {
    if (!lineItemId || !loading) throw new Error("An error occurred");
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = wmsFileFactory.loadingLineItemPdf({ loading, signature, id: lineItemId });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    }
  }

  return handleDownloadLoadingLineItemPdf;
};

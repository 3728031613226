import { useOverviewReleasedForCustomer } from "api/wms/hooks";
import { useOverviewReleasedForCustomerListColumns } from "./../utils/useOverviewReleasedForCustomerListColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import styles from "../Overview.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { GenericTable } from "components/common/genericTable/GenericTable";
import { queryString } from "utilities";
import { useGenericTableFilters } from "components/common/genericTable/useGenericTableFilters";
import { useFilters } from "hooks/useFilters";
import { useSelector } from "hooks";
import { useMemo } from "react";
import { detailsListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { parseTableRowData } from "components/common/genericTable/utils";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";

export const OverviewReleasedForCustomer = () => {
  const { filters: customFilters, setFilter } = useFilters({ customerId: "" });
  const { setDateFrom, setRowBy, filters } = useGenericTableFilters();
  const customers = useSelector(state => state.partials.customers);
  const search = queryString.stringify({ ...filters, ...customFilters });
  const {
    data: itemsReleased,
    isLoading,
    error,
    isFetching,
  } = useOverviewReleasedForCustomer(search, { keepPreviousData: true });
  const columns = useOverviewReleasedForCustomerListColumns(itemsReleased, filters);
  const normalizedRows = parseTableRowData(itemsReleased);

  const customersList = useMemo(
    () =>
      [{ name: "Wszyscy", id: "" }].concat(
        customers.map(customer => ({
          name: customer.name,
          id: String(customer.id),
        })),
      ),
    [customers],
  );

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <Typography fontSize="16" fontWeight="600">
          Wydania - Kontrahenci
        </Typography>
        <div className="d-flex align-items-center gap-2">
          <Select
            className={styles.dropdownList}
            items={customersList.map(customer => ({
              text: customer.name,
              value: customer.id,
              type: MenuItemType.TEXT,
            }))}
            label="Kontrahent"
            onChange={id => {
              if (id) setFilter("customerId", String(id));
            }}
            selected={customFilters.customerId}
          />
          <GenericTable.RowBySelect size="small" rowBy={filters.rowBy} setRowBy={setRowBy} />
        </div>
      </div>

      <div className={styles.tableWrapper}>
        <Table
          rows={normalizedRows}
          //@ts-ignore
          columns={columns}
          error={error}
          isLoading={isLoading || isFetching}
          uiSchema={detailsListUiSchema}
        />
      </div>
      <GenericTable.Footer rowBy={filters.rowBy} setDateFrom={setDateFrom} />
    </div>
  );
};

import { createColumnHelper } from "@tanstack/react-table";
import { PackagesReceivedAndReleasedPerDayIdentifiers } from "api/wms/models";
import { useMemo } from "react";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { GenericTable } from "typeUtilities";
import { getDateColor } from "pages/wms/overview/utils/getDateColor";
import { formatGenericTableDate } from "components/common/genericTable/utils";
import { DatepointerDetails } from "pages/wms/shared/components/DatepointerDetails";

const columnHelper = createColumnHelper<
  GenericTable<PackagesReceivedAndReleasedPerDayIdentifiers>["rows"][number]
>();

export const useReceivedAndReleasedPackagesPerDay = (
  tableData: GenericTable<PackagesReceivedAndReleasedPerDayIdentifiers> | null,
) => {
  return useMemo(() => {
    if (tableData) {
      return [
        columnHelper.accessor(row => row.DATEPOINTER.value, {
          header:
            tableData.columns.find(
              column =>
                column.identifier === PackagesReceivedAndReleasedPerDayIdentifiers.DATEPOINTER,
            )?.name || "",
          size: 60,
          cell: info => (
            <Typography color={getDateColor(info.getValue())} fontSize="12" fontWeight="600">
              {formatGenericTableDate(tableData.rowsBy, info.getValue())}
            </Typography>
          ),
        }),
        columnHelper.accessor(row => row.DATEPOINTER.value, {
          id: "DATEPOINTER_DAYNAME",
          header: "",
          size: 40,
          cell: info => <DatepointerDetails date={info.getValue()} rowBy="DAY" />,
        }),
        columnHelper.group({
          id: "Received",
          header: () => (
            <Typography fontSize="14" fontWeight="700">
              Przyjęcia
            </Typography>
          ),
          columns: [
            columnHelper.accessor(row => row.RECEIVED_INDEXES.value, {
              id: "RECEIVED_INDEXES",
              header: () => (
                <Typography
                  className="text-right w-100"
                  color="neutralBlack48"
                  fontSize="12"
                  fontWeight="400"
                >
                  {tableData.columns.find(
                    column =>
                      column.identifier ===
                      PackagesReceivedAndReleasedPerDayIdentifiers.RECEIVED_INDEXES,
                  )?.name || ""}
                </Typography>
              ),
              size: 65,
              cell: info => (
                <Typography className="text-right w-100" fontSize="12" fontWeight="600">
                  {info.getValue() || "---"}
                </Typography>
              ),
            }),
            columnHelper.accessor(row => row.RECEIVED_PACKAGES.value, {
              id: "RECEIVED_PACKAGES",
              header: () => (
                <Typography
                  className="text-right w-100"
                  color="neutralBlack48"
                  fontSize="12"
                  fontWeight="400"
                >
                  {tableData.columns.find(
                    column =>
                      column.identifier ===
                      PackagesReceivedAndReleasedPerDayIdentifiers.RECEIVED_PACKAGES,
                  )?.name || ""}
                </Typography>
              ),
              size: 65,
              cell: info => (
                <Typography className="text-right w-100" fontSize="12" fontWeight="600">
                  {info.getValue() || "---"}
                </Typography>
              ),
            }),
          ],
        }),
        columnHelper.group({
          id: "Released",
          header: () => (
            <Typography fontSize="14" fontWeight="700">
              Wydania
            </Typography>
          ),
          columns: [
            columnHelper.accessor(row => row.RELEASED_INDEXES.value, {
              id: "RELEASED_INDEXES",
              header: () => (
                <Typography
                  className="text-right w-100"
                  color="neutralBlack48"
                  fontSize="12"
                  fontWeight="400"
                >
                  {tableData.columns.find(
                    column =>
                      column.identifier ===
                      PackagesReceivedAndReleasedPerDayIdentifiers.RELEASED_INDEXES,
                  )?.name || ""}
                </Typography>
              ),
              size: 65,
              cell: info => (
                <Typography className="text-right w-100" fontSize="12" fontWeight="600">
                  {info.getValue() || "---"}
                </Typography>
              ),
            }),
            columnHelper.accessor(row => row.RELEASED_PACKAGES.value, {
              id: "RELEASED_PACKAGES",
              header: () => (
                <Typography
                  className="text-right w-100"
                  color="neutralBlack48"
                  fontSize="12"
                  fontWeight="400"
                >
                  {tableData.columns.find(
                    column =>
                      column.identifier ===
                      PackagesReceivedAndReleasedPerDayIdentifiers.RELEASED_PACKAGES,
                  )?.name || ""}
                </Typography>
              ),
              size: 65,
              cell: info => (
                <Typography className="text-right w-100" fontSize="12" fontWeight="600">
                  {info.getValue() || "---"}
                </Typography>
              ),
            }),
          ],
        }),
      ];
    }
    return [];
  }, [tableData]);
};

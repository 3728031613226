import { RightPanelHeader } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useRoute } from "hooks/apiPrimitives";
import cx from "classnames";
import { Button } from "components/common";
import historyIcon from "assets/images/history.svg";
import styles from "../RightPanel.module.css";
import { MarkRouteReadyBtn } from "./markRouteReady/MarkRouteReadyBtn";
import { MarkRouteReadyCheckbox } from "./markRouteReady/MarkRouteReadyCheckbox";
import { MoreOptionsPopUp } from "./moreOptionsPopUp/MoreOptionsPopUp";
import { Link } from "react-router-dom";
import { Button as ButtonPlaceholder } from "components/miloDesignSystem/atoms/button";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadFile } from "components/miloDesignSystem/atoms/icons/MdiDownloadFile";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiMapAlt } from "components/miloDesignSystem/atoms/icons/MdiMapAlt";
import { MdiRequestQuote } from "components/miloDesignSystem/atoms/icons/MdiRequestQuote";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PanelHeader = ({ close, isScrolledOver, setShowLoader }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: route } = useRoute(panelId);

  if (!route) return null;

  return (
    <RightPanelHeader
      className={cx(styles.panelHeaderSizing, {
        slidingPanelHeaderShadow: isScrolledOver,
        panelHeaderDuringSlide: isScrolledOver,
      })}
    >
      {isScrolledOver ? (
        <div className="slidingPanelHeader">
          <div className="slidingPanelHeaderContent d-flex align-items-center gap-2">
            <MarkRouteReadyCheckbox route={route} setShowLoader={setShowLoader} />
            <div>{route.signature}</div>
          </div>
        </div>
      ) : (
        <MarkRouteReadyBtn route={route} setShowLoader={setShowLoader} />
      )}
      <div className="d-flex align-items-center justify-content-end gap-1 position-relative">
        <ButtonPlaceholder size="small" variant="gray">
          Zatwierdź faktury
        </ButtonPlaceholder>
        <Tooltip title="Podgląd PDF faktur">
          <IconButton icon={MdiRequestQuote} variant="transparent" />
        </Tooltip>
        <Tooltip title="Dokumenty do pobrania">
          <IconButton icon={MdiDownloadFile} variant="transparent" />
        </Tooltip>
        <Tooltip title="Historia trasy">
          <Button
            as={Link}
            kind="transparent-black"
            size="square-s"
            to={{
              pathname: `/logistics/routes/${panelId}/history`,
              state: { route },
            }}
          >
            <div className="btnBase btnBase16 btnBaseSmall">
              <img alt="Historia" src={historyIcon} />
            </div>
          </Button>
        </Tooltip>
        <span className="divider line-divider" />
        <Tooltip title="Edytuj trasę">
          <IconButton icon={MdiMapAlt} variant="transparent" />
        </Tooltip>
        <MoreOptionsPopUp route={route} />
        <span className="divider line-divider" />
        <Tooltip title="Zamknij">
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </Tooltip>
      </div>
    </RightPanelHeader>
  );
};

import styles from "./FilterDropdown.module.css";
import cx from "classnames";
import { useQuery, useToggle } from "hooks";
import { ClickOutsideHandler } from "components/utils";
import { clickOutsideIgnoreClass } from "../constants";
import { SingleToolbarDateAsyncModal } from "components/common/ToolbarDateAsyncModal/SingleToolbarDateAsyncModal";
import { dateFns } from "utilities";
import { useState } from "react";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";

const modalId = Symbol("modal");

export const FilterDropdownSearchSelect = ({
  options,
  placeholder,
  name,
  label,
  defaultValue,
}: {
  label: string;
  name: string;
  options: {
    value: string;
    label: string;
    avatarProps?: {
      avatar: string | null;
      firstName: string;
      lastName: string;
    };
  }[];
  placeholder?: string;
  defaultValue: string;
}) => {
  const [optionsToSelect, setOptionsToSelect] = useState(options);
  const [inputValue, setInputValue] = useState("");
  const { isOpen, close, toggle } = useToggle(false);
  const { query, updateQuery } = useQuery();
  const selected = options.find(option => option.value === query[name]);
  const defaultOption = options.find(option => option.value === defaultValue);

  const displayLabel = (() => {
    return selected?.label || formatLabel(query[name]) || defaultOption?.label;
  })();

  return (
    <ClickOutsideHandler
      onClickOutside={() => {
        close();
        setOptionsToSelect(options);
        setInputValue("");
      }}
      outsideClickIgnoreClass={clickOutsideIgnoreClass}
    >
      <div className={styles.filter}>
        <button className="d-flex align-items-center px-1" aria-haspopup="menu" onClick={toggle}>
          <strong className={cx("mr-1", styles.filterLabel)}>{label}:</strong>
          <strong className={styles.selected}>{displayLabel}</strong>
        </button>
        <div
          className={styles.optionsList}
          role="menu"
          style={{ display: isOpen ? "block" : "none" }}
        >
          <div className="border-bottom p-2 d-flex align-items-center justify-content-center">
            <input
              className={styles.search}
              onChange={event => {
                setOptionsToSelect(() => {
                  return options.filter(option => {
                    const words = option.label.split(" ");
                    return words.some(word =>
                      word.toLowerCase().startsWith(event.target.value!.toLowerCase()),
                    );
                  });
                });
                setInputValue(event.target.value);
              }}
              placeholder={placeholder ?? "Szukaj"}
              value={inputValue}
            />
          </div>
          {optionsToSelect.map(option => (
            <div
              key={`${option.label} ${option.value}`}
              className={cx(styles.optionsItem, "d-flex align-items-center gap-2")}
              role="menuitem"
              onClick={() => {
                updateQuery({ [name]: option.value, page: 1 });
                setOptionsToSelect(options);
                setInputValue("");
                close();
              }}
            >
              {option.avatarProps && (
                <AvatarOrInitials
                  avatarSrc={option.avatarProps.avatar}
                  className="m-0"
                  firstName={option.avatarProps.firstName}
                  lastName={option.avatarProps.lastName}
                />
              )}
              <Typography fontSize="12" fontWeight="700">
                {option.label}
              </Typography>
            </div>
          ))}
        </div>
        <SingleToolbarDateAsyncModal id={modalId} />
      </div>
    </ClickOutsideHandler>
  );
};

function formatLabel(date: string) {
  if (!date) return "";
  try {
    return dateFns.format(new Date(date), "dd.MM.yyyy");
  } catch (err) {
    return "???";
  }
}

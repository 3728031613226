import { CallCenterDashboardLatestSmsMessages } from "api/call-center/routes/models";
import styles from "pages/callCenter/shared/CallCenter.module.css";
import localStyles from "../../../Dashboard.module.css";
import cx from "classnames";
import { LatestSmsMessageStatus } from "./LatestSmsMessageStatus";
import { LinkToPage } from "components/utils/linkToPage";
import { useToggle } from "hooks";
import { UnreadMessageModal } from "./UnreadMessageModal";

interface Props {
  latestSmsMessage: CallCenterDashboardLatestSmsMessages;
  search: string;
}

export const LatestSmsMessage = ({ latestSmsMessage, search }: Props) => {
  const unreadMessageModal = useToggle();

  return (
    <>
      <div
        className={cx(
          localStyles.tableRow,
          localStyles.tableRowBokLatestSmsMessages,
          "cursor-pointer",
        )}
        onClick={() => unreadMessageModal.open()}
      >
        {latestSmsMessage.order ? (
          <div className={styles.overflow}>
            {latestSmsMessage.order.firstName} {latestSmsMessage.order.lastName}
          </div>
        ) : (
          <div className={styles.overflow}>---</div>
        )}
        <div
          className={cx(
            latestSmsMessage.body.length > 17 && styles.overflowingMessage,
            "position-relative",
          )}
        >
          <div className={styles.tooltip}>{latestSmsMessage.body}</div>
          <div className={cx(styles.overflow, "fs-14 fw-700")}>{latestSmsMessage.body}</div>
        </div>

        <LatestSmsMessageStatus isRead={latestSmsMessage.isRead} />
        <div className={styles.overflow}>
          {latestSmsMessage.route ? (
            <div className={styles.overflow}>
              <LinkToPage
                content={latestSmsMessage.route.signature}
                url={`/call-center/route/${latestSmsMessage.route.id}/orders`}
              />
            </div>
          ) : (
            "---"
          )}
        </div>
        <div className="d-flex justify-content-end">
          {latestSmsMessage.order.routePosition ? latestSmsMessage.order.routePosition : "Odpięto"}
        </div>
      </div>
      {unreadMessageModal.isOpen && (
        <UnreadMessageModal
          close={unreadMessageModal.close}
          latestSmsMessage={latestSmsMessage}
          search={search}
        />
      )}
    </>
  );
};

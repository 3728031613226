import { useLogisticsRouteSimplified } from "api/logistics/routes/hooks";
import { routesKeys } from "api/routes/keys";
import { Route } from "api/routes/models";
import { useQueryClient } from "react-query";
import { match } from "react-router-dom";

export const useLogisticsRouteDetails = (isLogisticsRoute: match<{ routeId: string }> | null) => {
  const queryClient = useQueryClient();
  const logisticsRouteId = isLogisticsRoute?.params.routeId;

  const logisticsRouteData = logisticsRouteId
    ? queryClient.getQueryData<Route>(routesKeys.route(String(logisticsRouteId)))
    : null;

  const {
    data: fetchedLogisticsRoute,
    isLoading: logisticsRouteIsLoading,
  } = useLogisticsRouteSimplified(
    { id: logisticsRouteId ? logisticsRouteId : "" },
    {
      enabled:
        Boolean(logisticsRouteId) &&
        (logisticsRouteData === undefined || logisticsRouteData === null),
    },
  );

  const logisticsRoute = logisticsRouteData ?? fetchedLogisticsRoute;

  return {
    logisticsRoute,
    logisticsRouteIsLoading,
    logisticsRouteId,
  };
};

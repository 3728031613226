import styles from "../RouteOrdersList.module.css";
import cx from "classnames";
import { useQuery } from "hooks";

interface FilterRoundMultiSelectType {
  name: string;
  options: { value: string; icon: string }[];
  kind?: "checkbox";
  multiple?: boolean;
}
type Option = FilterRoundMultiSelectType["options"][number];

export const FilterRoundMultiSelect = ({
  name,
  options,
  kind = "checkbox",
  multiple,
}: FilterRoundMultiSelectType) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const value: Option["value"] = query[name];

  const handleChange = (optionValue: Option["value"] | null) => {
    if (optionValue && multiple) {
      const values = (() => {
        const appliedFilters = value?.split(",");
        if (appliedFilters?.includes(optionValue)) {
          return appliedFilters?.filter(appliedValue => appliedValue !== optionValue).join(",");
        }

        return [...(appliedFilters || []), optionValue].join(",");
      })();

      updateQuery({ [name]: values, page: 1 });
      return;
    }

    if (optionValue) {
      updateQuery({ [name]: optionValue, page: 1 });
      return;
    }

    updateQuery({ [name]: "", page: 1 });
  };

  return (
    <div className="d-flex  gap-1">
      {options.map(option => {
        const checked = value === option.value || value?.includes(option.value);
        return (
          <label
            key={option.value}
            className={cx("d-inline-flex", styles.tag, styles.tagFill, styles.tagRound)}
          >
            <input
              checked={checked}
              onClick={() => {
                if (checked) {
                  handleChange(null);
                }
              }}
              onChange={() => handleChange(option.value)}
              type="checkbox"
              className={styles.tagInput}
            />
            <span className={styles.tagSelected}>
              <img src={option.icon} alt="" className="imgSX" />
            </span>
          </label>
        );
      })}
    </div>
  );
};

import { useWhEntryAggregates } from "api/trading-documents/hooks";
import { InvoiceAdmission } from "api/trading-documents/models";
import { CustomModal } from "components/utils/customModal";
import { useState } from "react";
import styles from "./AddWhEntriesModal.module.css";
import { FiltersDisplay } from "./components/FiltersDisplay";
import { getSearch } from "./utils/getSearch";
import cx from "classnames";
import { WhEntry } from "./components/WhEntry";
import { Button } from "components/common";
import { CommonError } from "components/utils";
import { getAnyErrorKey } from "utilities";
import { Loader } from "components/utils/loader";
import { UUID } from "api/types";
import { useQueryClient } from "react-query";
import { tradingDocumentsKeys } from "api/trading-documents/keys";

interface Props {
  assignedSearch: string;
  close: () => void;
  invoiceAdmission: InvoiceAdmission;
  whAggregateSearchDates: string[];
}

export interface SelectedWhEntry {
  id: UUID;
  isSelected: boolean;
  whEntryInvoiceAdmissionId: UUID | null;
}

export const AddWhEntriesModal = ({
  assignedSearch,
  close,
  invoiceAdmission,
  whAggregateSearchDates,
}: Props) => {
  const [pickedWhEntries, setPickedWhEntries] = useState<SelectedWhEntry[]>([]);
  const search = getSearch(invoiceAdmission, whAggregateSearchDates);
  const { data: whEntries, error, isLoading } = useWhEntryAggregates(search);
  const queryClient = useQueryClient();

  if (isLoading) {
    return (
      <div>
        <Loader module="finances" />
      </div>
    );
  }

  if (error) {
    return (
      <CustomModal
        close={close}
        isOpen
        overrides={{ container: { className: styles.modalContainer } }}
        title=""
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </CustomModal>
    );
  }

  return (
    <CustomModal
      close={() => {
        close();
        queryClient.invalidateQueries(
          tradingDocumentsKeys.invoiceAdmission.assignedWhEntries(assignedSearch),
        );
      }}
      isOpen
      overrides={{ container: { className: styles.modalContainer }, title: { className: "mb-0" } }}
      title="Ostatnie przyjęcia"
    >
      <>
        <FiltersDisplay
          invoiceAdmission={invoiceAdmission}
          whAggregateSearchDates={whAggregateSearchDates}
        />
        <div className={cx("d-flex flex-column gap-2 px-3", styles.scrollableList)}>
          {whEntries.map(whEntry => (
            <WhEntry
              invoiceAdmissionLink={invoiceAdmission.id}
              key={whEntry.id}
              pickedWhEntries={pickedWhEntries}
              setPickedWhEntries={setPickedWhEntries}
              whAggregateSearchPackage={invoiceAdmission.whAggregateSearchPackage}
              whEntry={whEntry}
            />
          ))}
          <div className="py-2" />
        </div>
        <div className="d-flex align-items-center gap-3 p-3 borderTop">
          <Button
            disabled={false}
            kind="submit-medium"
            onClick={() => {
              close();
              queryClient.invalidateQueries(
                tradingDocumentsKeys.invoiceAdmission.assignedWhEntries(assignedSearch),
              );
            }}
          >
            <div className="btnBaseMedium btnBase">Zastosuj</div>
          </Button>
        </div>
      </>
    </CustomModal>
  );
};

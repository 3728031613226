import { ErrorMessage, Modal } from "components/utils";
import cuid from "cuid";
import { ToggleHookState } from "hooks";
import { useState } from "react";
import { useRef } from "react";
import fileImg from "assets/images/72b.svg";
import uploadIcon from "assets/images/878.svg";
import styles from "./UploadFiles.module.css";
import cx from "classnames";
import { Button } from "../buttonLegacy";
import produce from "immer";
import { getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";

interface ProgressInfo {
  id: string;
  percentage: number;
  fileName: string;
  errorMessage: string;
  successMessage: string;
}

type OnUploadProgressFunction = (progressEvent: ProgressEvent) => void;

interface Props {
  modal: ToggleHookState;
  fetcher: (
    formData: FormData,
    onUploadProgress: OnUploadProgressFunction,
    search?: string,
  ) => Promise<{ message: string }>;
  onSuccess?: (payload: { message: string }) => void;
  title?: string;
  fileSizeLimit?: number;
  accepts?: string[];
  queryParams?: string;
}

export const UploadFilesModal = ({
  modal,
  fetcher,
  title,
  fileSizeLimit = 2097152, //2MB
  accepts = [""],
  onSuccess,
  queryParams,
}: Props) => {
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [progressInfos, setProgressInfos] = useState<ProgressInfo[]>([]);

  const uploadService: (
    file: File,
    onUploadProgress: OnUploadProgressFunction,
    search: string,
  ) => Promise<{ message: string }> = (file, onUploadProgress, search) => {
    let formData = new FormData();
    formData.append("file", file);
    return fetcher(formData, onUploadProgress, search);
  };

  const upload = (file: File) => {
    const progresInfoFile: ProgressInfo = {
      id: cuid(),
      percentage: 0,
      fileName: file.name,
      errorMessage: "",
      successMessage: "",
    };

    setProgressInfos(prev => [...prev, progresInfoFile]);

    uploadService(
      file,
      (event: ProgressEvent) => {
        setProgressInfos(prev =>
          produce(prev, draft => {
            const toUpdate = draft.find(el => el.id === progresInfoFile.id);
            assertIsDefined(toUpdate);
            toUpdate.percentage = Math.round((100 * event.loaded) / event.total);
          }),
        );
      },
      queryParams ?? "",
    )
      .then(payload => {
        onSuccess?.(payload);
        setProgressInfos(prev =>
          produce(prev, draft => {
            const toUpdate = draft.find(el => el.id === progresInfoFile.id);
            assertIsDefined(toUpdate);
            toUpdate.successMessage = payload.message;
          }),
        );
      })
      .catch(error => {
        setProgressInfos(prev =>
          produce(prev, draft => {
            const toUpdate = draft.find(el => el.id === progresInfoFile.id);
            assertIsDefined(toUpdate);
            toUpdate.errorMessage = getAnyErrorKey(error);
          }),
        );
      });
  };

  const uploadFiles = (files: File[]) => {
    files.forEach(file => {
      if (file.size > fileSizeLimit) {
        const progresInfoFile: ProgressInfo = {
          id: cuid(),
          percentage: 0,
          fileName: file.name,
          errorMessage: `Rozmiar pliku nie może być większy niż ${fileSizeLimit / 1024}kB`,
          successMessage: "",
        };

        setProgressInfos(prev => [...prev, progresInfoFile]);

        return;
      }

      upload(file);
    });
  };

  const onDrop: React.DragEventHandler<HTMLInputElement> = (event: any) => {
    event.preventDefault();
    const filesAdded = event.dataTransfer ? event.dataTransfer.files : event.target.files;

    uploadFiles([...filesAdded]);
  };

  const onDragOver: React.DragEventHandler<HTMLInputElement> = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  function openFileChooser() {
    if (!inputRef.current) return;
    inputRef.current.value = "";
    inputRef.current.dispatchEvent(new MouseEvent("click"));
  }

  const inputAttributes = {
    type: "file",
    multiple: true,
    accept: accepts ? accepts.join() : "",
    name: cuid(),
    style: { display: "none" },
    onChange: (event: any) => uploadFiles([...event.target.files]),
  };

  return (
    <Modal
      overrides={{ container: { className: styles.modal } }}
      isOpen={modal.isOpen}
      close={modal.close}
    >
      <div>
        <h5 className="mb-3">{title}</h5>
        <div>
          <div
            ref={dropZoneRef}
            className={styles.dropFileArea}
            onDragOver={onDragOver}
            onDrop={onDrop}
          >
            <input {...inputAttributes} ref={inputRef} />
            <div className="align-items-center d-flex flex-column">
              <img src={uploadIcon} alt="prześlij" />
              <div className=" mt-5">Przeciągnij i upuść plik tutaj</div>
              <div className="fs-14 text-color-grey my-3">albo</div>
              <Button kind="secondary-stroke" size="small" onClick={openFileChooser}>
                Wybierz plik ze swojego urządzenia
              </Button>
            </div>
          </div>
          <div className={styles.filesWrapper}>
            {progressInfos.map(({ errorMessage, fileName, percentage, id, successMessage }) => (
              <div className={cx("mb-2", styles.file)} key={id}>
                <div className="mb-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className={cx("mr-2", styles.fileIcon)}>
                        <img src={fileImg} alt="folder" />
                      </div>
                      <div className={styles.name}>{fileName}</div>
                    </div>
                    <div className="fs-14 text-color-grey ml-2">
                      {errorMessage ? 0 : percentage}%
                    </div>
                  </div>
                  {!errorMessage && (
                    <div className={styles.progress}>
                      <div
                        className={styles.progressBar}
                        role="progressbar"
                        aria-valuenow={percentage}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: percentage + "%" }}
                      ></div>
                    </div>
                  )}
                </div>
                <ErrorMessage type="text" text={errorMessage} className="mb-1" />
                <ErrorMessage type="text" text={successMessage} className="mb-1 text-color-green" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

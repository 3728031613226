import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { useUpdateTradingDocumentOptionsForPurchaseImport } from "api/trading-documents/hooks";
import styles from "../../RightPanel.module.css";
import {
  ExpectedPaymentForm,
  TradingDocumentStatus,
  expectedPaymentFormDict,
} from "api/trading-documents/models";
import { UUID } from "api/types";
import { cx } from "utilities";
import { AsyncInput } from "components/utils";

interface Props {
  expectedPaymentForm: ExpectedPaymentForm;
  id: UUID;
  status: TradingDocumentStatus;
}

export const PurchaseInvoiceImportPaymentForm = ({ expectedPaymentForm, id, status }: Props) => {
  const paymentFormMutation = useUpdateTradingDocumentOptionsForPurchaseImport();

  if (status === "CONFIRMED") {
    if (!Boolean(expectedPaymentForm))
      return <EmptyValue className="italic" fontSize="14" fontWeight="500" />;
    return (
      <span
        className={cx("body-14-500", {
          [styles.fontStyle]: expectedPaymentForm !== "CASH" && expectedPaymentForm !== "ONLINE",
        })}
      >
        {expectedPaymentForm === "CASH" || expectedPaymentForm === "ONLINE"
          ? expectedPaymentFormDict[expectedPaymentForm]
          : expectedPaymentForm}
      </span>
    );
  }

  return (
    <AsyncInput
      disabled={paymentFormMutation.isLoading}
      look="common"
      onChange={value =>
        paymentFormMutation.mutate({
          id,
          expectedPaymentForm: value,
        })
      }
      overwrites={{
        input: {
          className: cx(styles.formHeight, {
            [styles.fontStyle]: expectedPaymentForm !== "CASH" && expectedPaymentForm !== "ONLINE",
          }),
        },
        floatingLabel: { className: styles.inputTextLabel },
      }}
      value={
        expectedPaymentForm === "CASH" || expectedPaymentForm === "ONLINE"
          ? expectedPaymentFormDict[expectedPaymentForm]
          : expectedPaymentForm
      }
    />
  );
};

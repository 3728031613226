import { Delivery } from "api/orders/models";
import { OrderEmails } from "components/common/orderEmails/OrderEmails";
import { ClickOutsideHandler } from "components/utils";
import styles from "../PointsSection.module.css";

interface Props {
  client: Pick<Delivery, "firstName" | "lastName" | "email">;
  close: () => void;
  orderId: string;
  signature: string;
}

export const clickOutsideIgnoreClass = "click-outside-ignore-class-right-panel";

export const RightPanel = ({ client, close, orderId, signature }: Props) => {
  return (
    <ClickOutsideHandler onClickOutside={close} outsideClickIgnoreClass={clickOutsideIgnoreClass}>
      <div className={styles.rightPanel}>
        <OrderEmails client={client} orderId={orderId} signature={signature} />
      </div>
    </ClickOutsideHandler>
  );
};

import { UUID } from "api/types";
import { wmsFileFactory } from "api/wms/calls";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";

export const useDownloadPickingLineItemPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();

  async function handleDownloadPickingLineItemPdf(
    lineItemId: UUID,
    picking: UUID,
    signature: string,
  ) {
    if (!lineItemId || !picking) throw new Error("An error occurred");
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = wmsFileFactory.pickingLineItemPdf({ picking, signature, id: lineItemId });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    }
  }

  return handleDownloadPickingLineItemPdf;
};

import { Product } from "api/products/models";
import { isFabric } from "typePredicates";

export const isMoreThanOneAttributeValue = (product: Product, attributeId: number): boolean => {
  const attributeToCheck = product.attributes.find(attr => attr.id === attributeId);
  if (!attributeToCheck) return false;

  if (isFabric(attributeToCheck)) {
    const assignableValues = attributeToCheck.categories.map(cat =>
      cat.values.filter(value => value.isAssignableToIndex === true),
    );
    return assignableValues.length > 1;
  }

  const assignableValues = attributeToCheck.values.filter(
    value => value.isAssignableToIndex === true,
  );

  return assignableValues.length > 1;
};

import { useUnloads } from "api/wms/hooks";
import styles from "../../Dashboard.module.css";
import { Unloading } from "api/wms/models";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useUnloadingsColumns } from "./useUnloadingsColumns";
import { useSearch } from "../../shared/useSearch";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { Link } from "react-router-dom";
import { Typography } from "components/miloDesignSystem/atoms/typography";

export const CargoUnloadsSection = () => {
  const search = useSearch();
  const { data: unloadings, error, isPreviousData, isLoading } = useUnloads(search);
  const columns = useUnloadingsColumns();

  return (
    <div className={styles.card}>
      <div>
        <div className="d-flex align-items-center justify-content-between gap-1 px-3 py-3">
          <Typography className={styles.sectionTitle} fontSize="16" fontWeight="700">
            Rozładunki
          </Typography>
          <Link to={`/wms/unloadings/list/all`}>
            <Button
              className="text-uppercase"
              endIcon={MdiArrowForward}
              size="small"
              variant="gray"
            >
              Wszystkie rozładunki
            </Button>
          </Link>
        </div>
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<Unloading>
            rows={unloadings}
            columns={columns}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={comfortableListUiSchema}
          />
        </div>
      </div>
    </div>
  );
};

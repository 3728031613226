import styles from "./FinanceAmountFormat.module.css";
import cx from "classnames";

interface Props {
  decimalClassName?: string;
  integerClassName?: string;
  value: number | string;
}

export const FinanceAmountFormat = ({ decimalClassName, integerClassName, value }: Props) => {
  const formattedAmount = Number(value).toLocaleString("de-DE", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const [integerPart, decimalPart] = formattedAmount.split(",");

  return (
    <div>
      <span className={cx(styles.integerPart, integerClassName)}>{integerPart}</span>
      <span className={cx(styles.decimalPart, decimalClassName)}>,{decimalPart}&nbsp;</span>
    </div>
  );
};

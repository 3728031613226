import styles from "../../Dashboard.module.css";
import cx from "classnames";
import { EmptyIncomeSection } from "./components/EmptyIncomeSection";

export const IncomeSection = () => {
  return (
    <div className={cx(styles.card, "pb-2")}>
      <EmptyIncomeSection />
    </div>
  );
};

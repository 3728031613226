export const orange = {
  orange12: "#EC91351F",
  orange25: "#FDF3E2",
  orange32: "#EC913552",
  orange50: "#FEE0B1",
  orange75: "#F7CD8B",
  orange100: "#EE9E4B",
  orange200: "#EC8B28",
  orange300: "#ED752F",
  orange400: "#BF4E1F",
  orange500: "#8E401F",
  orange600: "#582713",
};

import { buildBankAccountLink } from "api/bank-accounts/calls";
import { useBanks } from "api/bank-accounts/hooks";
import { Bank } from "api/bank-accounts/models";
import { Header } from "components/common";
import { Button } from "components/common/buttonLegacy";
import { SearchInput } from "components/common/searchInput";
import { useMutation } from "hooks/useMutation";
import { useState } from "react";
import styles from "./Create.module.css";
import cx from "classnames";
import { CommonError, Spinner } from "components/utils";

export const RedirectLink = () => {
  const { data, error, isLoading } = useBanks("");
  const [selectedBank, setSelectedBank] = useState<null | Bank>(null);
  const [search, setSearch] = useState("");

  const addBankMutation = useMutation(buildBankAccountLink, {
    onSuccess: payload => {
      window.location.href = payload.redirectTo;
    },
  });

  if (error) {
    return <CommonError status={error._httpStatus_} />;
  }

  if (!data || isLoading) {
    return (
      <div className="h-100 d-flex flex-column">
        <Header title="Dodaj konto bankowe" />
        <div
          className={cx(styles.section, "h-100 d-flex align-items-center justify-content-center")}
        >
          <Spinner color="blue" size="big" text="trwa wczytywanie" />
        </div>
      </div>
    );
  }

  const filteredBanks = data.filter(el => el.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <div className="h-100 d-flex flex-column">
      <Header title="Dodaj konto bankowe" />
      <div className={styles.section}>
        <SearchInput className="w-50" value={search} onChange={value => setSearch(value)} />
        <div className={styles.container}>
          {filteredBanks.map(bank => (
            <div className={styles.bank} onClick={() => setSelectedBank(bank)} key={bank.id}>
              <div
                className={cx(styles.radio, {
                  [styles["radio-selected"]]: selectedBank?.id === bank.id,
                })}
              ></div>
              <img src={bank.logo} className={styles.logo} alt={`${bank.name} logo`} />
              {bank.name}
            </div>
          ))}
        </div>
        <Button
          kind="primary"
          disabled={addBankMutation.isLoading || !selectedBank}
          onClick={() => {
            if (!selectedBank) return;
            const { id, name, logo } = selectedBank;
            addBankMutation.mutate({
              id,
              name,
              logo,
              redirectUri: `${window.location.origin}/bank-accounts/create/step-three`,
            });
          }}
        >
          Utwórz konto
        </Button>
      </div>
    </div>
  );
};

import { graphhopper } from "api/graphhopper/graphhopper";
import { Assign } from "utility-types";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useState, useEffect, useMemo } from "react";
import { OrderPositionTypes, Route, RouteOrder } from "api/routes/models";
import moonBlackImg from "assets/images/311.svg";
import pointImg from "assets/images/312.svg";
import markerImg from "assets/images/42.svg";
import cx from "classnames";
import { Button } from "components/common";
import { Select, Switch } from "components/utils";
import cuid from "cuid";
import { useSelector, useToastr } from "hooks";
import { useRoutePutMutation } from "hooks/apiPrimitives";
import { dateFns, secondsToTime } from "utilities";
import { useRouteViewState } from "../../routeCreatorState";
import { GraphhopperPolylineSwitch } from "./GraphhopperPolylineSwitch";
import { OptimizeRouteBtn } from "./OptimizeRouteBtn";
import { Point } from "./Point";
import styles from "./PointsList.module.css";
import { ReverseButton } from "./ReverseButton";
import { RouteSpeed } from "./routeSpeed/RouteSpeed";
import { SearchBar } from "./SearchBar";
import { Stopover } from "./stopover/Stopover";
import { VehicleTypeSelect } from "./vehicleType/VehicleType";
import { Passpoint } from "./passpoint/Passpoint";
import { getFullRouteCoords, getOrdersPositionsBasedOnGraphhopper, getPoints } from "../../utils";
import produce from "immer";

type OrderAndStopoverList =
  | Assign<Route["orders"][number], { order: number | null }>
  | Assign<OrderPositionTypes["stopover"], { order: number | null }>
  | Assign<OrderPositionTypes["passpoint"], { order: number | null }>;

export const PointsList = ({ route }: { route: Route }) => {
  const actions = useRouteViewState("slave", store => store.actions);
  const isLoading = useRouteViewState("slave", store => store.isLoading);
  const showOnlyRoutePoints = useRouteViewState("slave", store => store.showOnlyRoutePoints);
  const isPointPolylineVisible = useRouteViewState("slave", store => store.isPointPolylineVisible);
  const toastr = useToastr();
  const putMutation = useRoutePutMutation();

  const orderDict: Record<string, RouteOrder> = useMemo(
    () =>
      route.orders.reduce((acc, el) => {
        Object.assign(acc, { [el.id]: el });
        return acc;
      }, {}),
    [route.orders],
  );

  const orderAndStopoverList: OrderAndStopoverList[] = useMemo(() => {
    let currOrderNum = 0;
    return route.ordersPositions.map(item => {
      if (item.type === "order") {
        currOrderNum = currOrderNum + 1;
        return { ...orderDict[item.id]!, order: currOrderNum };
      }
      return { ...item, order: null };
    });
  }, [route.ordersPositions, orderDict]);

  const [localOrderAndStopoverList, setLocalOrderAndStopoverList] = useState(orderAndStopoverList);

  useEffect(() => {
    setLocalOrderAndStopoverList(orderAndStopoverList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  const onDragEnd = async (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setLocalOrderAndStopoverList(prev =>
      produce(prev, draft => {
        const [removed] = draft.splice(result.source.index, 1);
        draft.splice(result.destination!.index, 0, removed);
      }),
    );

    const startingPointLngLat = [route.startingPoint.point.lng, route.startingPoint.point.lat];
    actions.openLoader("Trwa zmiana kolejności");
    const newOrdersPositions = produce(route.ordersPositions, draft => {
      const [removed] = draft.splice(result.source.index, 1);
      draft.splice(result.destination!.index, 0, removed);
    });

    const newPoints = getPoints(newOrdersPositions);

    const points = getFullRouteCoords(route, newPoints, startingPointLngLat);

    const payload = await graphhopper.route({
      points,
      vehicle: route.vehicleType,
      includeLastPointInOptimization: route.includeLastPointInOptimization,
    });

    if (payload) {
      const returnToStartingPointDistance = String(payload.points[newPoints.length].distance);
      const returnToStartingPointTime = String(payload.points[newPoints.length].time);

      const ordersPositions = getOrdersPositionsBasedOnGraphhopper(
        newOrdersPositions,
        payload.points,
      );

      putMutation.mutate({
        data: {
          length: payload.distance,
          operation: null,
          returnToStartingPointDistance,
          returnToStartingPointTime,
          shouldCalculateAverageSpeed: true,
          ordersPositions,
        },
        route: route.id,
      });
    } else {
      actions.closeLoader();
      toastr.open({
        type: "failure",
        title: "Nie udało się zmienić typu pojazdu",
        text: "",
      });
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className={styles.pointList}>
        <div className="d-flex">
          <div className="d-flex align-items-center w-100">
            <VehicleTypeSelect route={route} />
            <RouteSpeed route={route} />
          </div>
          <div>
            <Switch
              onChange={() => actions.toggle("showOnlyRoutePoints")}
              name=""
              checked={showOnlyRoutePoints}
              label="Pokaż tylko przypięte do trasy"
              color="blue"
              overrides={{ label: { className: "fs-12" } }}
            />
            <GraphhopperPolylineSwitch route={route} />
            <Switch
              onChange={() => actions.toggle("isPointPolylineVisible")}
              name=""
              checked={isPointPolylineVisible}
              label="Pokaż linię trasy"
              color="blue"
              overrides={{ label: { className: "fs-12" } }}
            />
          </div>
        </div>
        <div className={styles.group}>
          <StartingPoint route={route} isLoading={isLoading} />
          <div
            className={cx(
              "d-flex align-items-center justify-content-between",
              styles.buttonWrapper,
            )}
          >
            <ReverseButton route={route} />
            <OptimizeRouteBtn route={route} />
          </div>
          <div className={styles.routePoints}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {localOrderAndStopoverList.map((item, index) => {
                      if (item.type !== "stopover" && item.type !== "passpoint") {
                        return (
                          <Point
                            index={index}
                            key={item.id}
                            order={item}
                            route={route}
                            isLoading={isLoading}
                            pointOrderOnList={item.order!}
                          />
                        );
                      } else if (item.type === "stopover") {
                        return (
                          <Stopover
                            stopover={item}
                            key={item.id}
                            isLoading={isLoading}
                            route={route}
                            startTime={""}
                            index={index}
                          />
                        );
                      }
                      return (
                        <Passpoint
                          key={item.id}
                          passpoint={item}
                          isLoading={isLoading}
                          route={route}
                          index={index}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className={styles.endingPointWrapper}>
              <div className={styles.dotContainer}>
                <div className={styles.purpleDot}></div>
              </div>
              <div className={styles.endingPoint}>
                <span
                  className={cx({
                    "line-through":
                      (!route.returnTime && route.ordersPositions.length >= 1) ||
                      !route.includeLastPointInOptimization,
                  })}
                >
                  {route.startingPoint.street} {route.startingPoint.city}
                </span>
                <div className={styles["travel-time"]}>
                  <div>
                    {Number(route.returnDistance) > 0 ? (
                      <span className={styles.distance}>
                        {(Number(route.returnDistance) / 1000).toFixed(1)}km
                      </span>
                    ) : (
                      <span>...</span>
                    )}
                  </div>{" "}
                  <div>
                    {route.returnTime > 0 ? (
                      <span>
                        {(() => {
                          const { hours, minutes } = secondsToTime(route.returnTime);
                          return (
                            <span>
                              {hours ? hours + "h" : ""} {minutes}min
                            </span>
                          );
                        })()}
                      </span>
                    ) : (
                      <span>...</span>
                    )}
                  </div>
                  <div>
                    <span className="text-color-grey fs-10 mr-1">
                      {route.returnDate &&
                        route.includeLastPointInOptimization &&
                        route.returnTime &&
                        dateFns.format(new Date(route.returnDate), "dd.MM.yyyy,")}
                    </span>

                    <span className="text-color-grey fs-10">
                      {route.returnDate &&
                        route.includeLastPointInOptimization &&
                        route.returnTime &&
                        dateFns.format(new Date(route.returnDate), "HH:mm")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <AddStopoverButton route={route} isLoading={isLoading} />
          <AddPoint route={route} isLoading={isLoading} />
        </div>
      </div>

      <SearchBar />
    </div>
  );
};

function AddPoint({ route, isLoading }: { route: Route; isLoading: boolean }) {
  const updateMutation = useRoutePutMutation();
  const actions = useRouteViewState("slave", store => store.actions);

  const onClick = () => {
    actions.openLoader("Trwa dodawanie punktu przelotowego");
    const id = cuid();
    const newOrdersPositions: Route["ordersPositions"] = [
      ...route.ordersPositions,
      {
        id,
        type: "passpoint",
        meta: {
          point: null,
          delivery: {
            distance: 0,
            time: 0,
            stopoverTime: 0,
            plannedDeliveryTime: null,
            address: null,
          },
        },
      },
    ];
    actions.setState({ editingPasspointId: id });

    updateMutation.mutate({
      data: {
        length: route.length,
        operation: null,
        returnToStartingPointDistance: route.returnDistance,
        returnToStartingPointTime: String(route.returnTime || 0),
        ordersPositions: newOrdersPositions,
        shouldCalculateAverageSpeed: false,
      },
      route: route.id,
    });
  };

  return (
    <Button
      kind="primary"
      type="button"
      disabled={isLoading}
      onClick={onClick}
      size="medium"
      className={styles.addBtn}
    >
      <img src={pointImg} alt="" /> Dodaj punkt przelotowy
    </Button>
  );
}

function AddStopoverButton({ route, isLoading }: { route: Route; isLoading: boolean }) {
  const updateMutation = useRoutePutMutation();
  const actions = useRouteViewState("slave", store => store.actions);

  const handleAddStopover = () => {
    actions.openLoader("Trwa dodawanie postoju dobowego");
    const newOrdersPositions: Route["ordersPositions"] = [
      ...route.ordersPositions,
      {
        id: cuid(),
        type: "stopover",
        meta: {
          point: null,
          delivery: {
            distance: 0,
            time: 0,
            stopoverTime: 0,
            plannedDeliveryTime: null,
            address: null,
          },
        },
      },
    ];

    updateMutation.mutate({
      data: {
        length: route.length,
        operation: null,
        returnToStartingPointDistance: route.returnDistance,
        returnToStartingPointTime: String(route.returnTime || 0),
        ordersPositions: newOrdersPositions,
        shouldCalculateAverageSpeed: false,
      },
      route: route.id,
    });
  };

  return (
    <Button
      kind="secondary-stroke"
      type="button"
      disabled={isLoading}
      onClick={handleAddStopover}
      size="medium"
      className={styles.addBtn}
    >
      <img src={moonBlackImg} alt="" /> Dodaj postój dobowy
    </Button>
  );
}

function StartingPoint({ route, isLoading }: { route: Route; isLoading: boolean }) {
  const startingPoints = useSelector(state => state.partials.startingPoints);
  const startingPoint = route.startingPoint;
  const updateMutation = useRoutePutMutation();
  const toastr = useToastr();
  const actions = useRouteViewState("slave", store => store.actions);

  const handleUpdate = async (newStartingPoint: Route["startingPoint"]) => {
    actions.openLoader("Trwa zamiana punktu startowego");
    const startingPointLngLat = [newStartingPoint.point.lng, newStartingPoint.point.lat];
    const newPoints = getPoints(route.ordersPositions);

    const points = getFullRouteCoords(route, newPoints, startingPointLngLat);

    const payload = await graphhopper.route({
      points,
      vehicle: route.vehicleType,
      includeLastPointInOptimization: route.includeLastPointInOptimization,
    });

    if (payload) {
      const returnToStartingPointDistance = String(payload.points[newPoints.length].distance);
      const returnToStartingPointTime = String(payload.points[newPoints.length].time);

      const ordersPositions = getOrdersPositionsBasedOnGraphhopper(
        route.ordersPositions,
        payload.points,
      );

      updateMutation.mutate({
        data: {
          length: payload.distance,
          operation: null,
          returnToStartingPointDistance,
          returnToStartingPointTime,
          startingPoint: newStartingPoint.id,
          ordersPositions,
          shouldCalculateAverageSpeed: true,
        },
        route: route.id,
      });
    } else {
      actions.closeLoader();
      toastr.open({
        type: "failure",
        title: "Nie udało się zmienić punktu startowego",
        text: "",
      });
    }
  };

  return (
    <>
      <div className={styles.groupLabel}>
        <img src={markerImg} alt="" />
        <span className="fs-10">adres początku trasy</span>
      </div>
      <div className={styles.startingPointWrapper}>
        <div className={styles.dotContainer}>
          <div className={styles.purpleDot}></div>
        </div>

        <Select
          mode="editable"
          onChange={startingPoint => {
            if (startingPoint) {
              handleUpdate(startingPoint);
            }
          }}
          disabled={updateMutation.isLoading || isLoading}
          size="small"
          overwrites={{
            button: { className: "flex-1" },
            wrapper: { className: styles.startingPointContainer },
          }}
          defaultSelected={startingPoint.id}
          items={startingPoints.map(point => ({
            ...point,
            name: `${point.street}, ${point.city}`,
          }))}
        />
      </div>
    </>
  );
}

import { Button } from "components/common";
import binIcon from "assets/images/81.svg";
import { UUID } from "api/types";
import { useRemovePurchaseInvoicePosition } from "./hooks/useRemovePurchaseInvoicePosition";

interface Props {
  itemId: string | number;
  tradingDocumentId: UUID;
  tradingDocumentItemId: UUID;
  tradingDocumentItemName: string;
}

export const DeletePurchaseInvoicePosition = ({
  itemId,
  tradingDocumentId,
  tradingDocumentItemId,
  tradingDocumentItemName,
}: Props) => {
  const deletePositionMutation = useRemovePurchaseInvoicePosition(
    itemId,
    tradingDocumentId,
    tradingDocumentItemId,
    tradingDocumentItemName,
  );

  return (
    <Button
      disabled={deletePositionMutation.isLoading}
      kind="transparent-black"
      onClick={e => {
        e.stopPropagation();
        deletePositionMutation.mutate(tradingDocumentItemId);
      }}
      size="square-s"
      style={{ height: "17px", minHeight: "17px" }}
      title="Usuń pozycję"
    >
      <div className="btnBase btnBaseSmall">
        <img alt="Usuń" src={binIcon} style={{ height: "12px", width: "12px" }} />
      </div>
    </Button>
  );
};

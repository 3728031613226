import { useEmailMessagesForOrder } from "api/messages/hooks";
import { Delivery } from "api/orders/models";
import listEmptyIcon from "assets/images/56g.svg";
import cx from "classnames";
import { CommonError, Spinner } from "components/utils";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { queryString } from "utilities";
import { EmailMessage } from "./EmailMessage";
import styles from "./OrderEmails.module.css";

interface Props {
  client: Pick<Delivery, "firstName" | "lastName" | "email">;
  orderId: string;
  signature: string;
}

export const OrderEmails = ({ client, orderId, signature }: Props) => {
  const search = queryString.stringify({
    pageSize: "999",
    order: orderId,
  });
  const { data, isLoading, error } = useEmailMessagesForOrder(search);

  if (error) {
    return <CommonError status={error._httpStatus_} />;
  }

  if (!data || isLoading) {
    return (
      <div className={styles.container}>
        <Spinner color="blue" size="big" text="trwa wczytywanie" position="absolute" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={cx(styles.titleWrapper, "d-flex align-items-center justify-content-between")}>
        <div className={styles.title}>Wysłane powiadomienia</div>
      </div>

      <div className={styles.header}>
        <div className="d-flex align-items-center">
          <AvatarOrInitials
            avatarSrc={null}
            firstName={client.firstName}
            lastName={client.lastName}
            className={styles.avatar}
          />
          <div className="pt-2">
            <div className={styles.name}>{`${client.firstName} ${client.lastName}`}</div>
            <div className={cx(styles.date, styles.sentDate)}>{client.email}</div>
          </div>
        </div>
        <div className={styles.signature}>{signature}</div>
      </div>

      <div className={styles.emailList}>
        {data.length === 0 ? (
          <div className={styles.noContent}>
            <img alt="" src={listEmptyIcon} className={styles.inputIcon} />
            <div>Nie wysłano jeszcze powiadomienia Email</div>
          </div>
        ) : (
          data.map(message => (
            <EmailMessage
              key={message.id}
              data={message}
              firstName={client.firstName}
              lastName={client.lastName}
            />
          ))
        )}
      </div>
    </div>
  );
};

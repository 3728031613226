import { useCurrentlyPrintedReceipts } from "api/trading-documents/hooks";
import { Loader } from "components/utils/loader";
import { queryString } from "utilities";
import { PrintingStatusModal } from "../shared/PrintingStatusModal";

interface Props {
  close: () => void;
}

export const CurrentlyPrintedReceiptsModal = ({ close }: Props) => {
  const search = queryString.stringify({
    pageSize: 9999,
  });
  const { data: currentlyPrintedReceipts, error, isLoading } = useCurrentlyPrintedReceipts(search);

  if (error) {
    return <PrintingStatusModal close={close} error={error} title="Teraz w druku" />;
  }

  if (isLoading) {
    return (
      <div>
        <Loader module="finances" />
      </div>
    );
  }

  return (
    <PrintingStatusModal close={close} receipts={currentlyPrintedReceipts} title="Teraz w druku" />
  );
};

import { useMemo, useContext } from "react";
import { queryContext } from "./QueryProvider";
import { getQueryParam, queryString } from "utilities";

interface QueryOptions {
  exclude?: string[];
}

export const useQuery = (options: QueryOptions = {}) => {
  const context = useContext(queryContext);

  const contextMemo = useMemo(() => {
    return { ...context, query: { ...context.query }, getParam: getQueryParam };
  }, [context]);

  let search = "";
  if (options.exclude) {
    options.exclude.forEach((key: string) => {
      delete contextMemo.query[key];
    });
    search = queryString.stringify(contextMemo.query);
    contextMemo.search = search;
  } else {
    search = context.search;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => contextMemo, [search]);
};

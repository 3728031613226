import { isAfter, isBefore, isSameMinute, parse } from "date-fns";

export const areConfirmedHoursInCollectionRange = (
  maximumCollectionHour: string | undefined | null,
  minimumCollectionHour: string | undefined | null,
  maximumConfirmedCollectionHour: string | undefined | null,
  minimumConfirmedCollectionHour: string | undefined | null,
): boolean | null => {
  if (
    maximumCollectionHour === undefined ||
    !maximumCollectionHour ||
    maximumCollectionHour?.length === 0 ||
    minimumCollectionHour === undefined ||
    !minimumCollectionHour ||
    minimumCollectionHour?.length === 0 ||
    maximumConfirmedCollectionHour === undefined ||
    !maximumConfirmedCollectionHour ||
    maximumConfirmedCollectionHour?.length === 0 ||
    minimumConfirmedCollectionHour === undefined ||
    !minimumConfirmedCollectionHour ||
    minimumConfirmedCollectionHour?.length === 0
  )
    return null;

  const minCollectionTime = parse(minimumCollectionHour, "HH:mm", new Date());
  const maxCollectionTime = parse(maximumCollectionHour, "HH:mm", new Date());
  const minConfirmedTime = parse(minimumConfirmedCollectionHour, "HH:mm", new Date());
  const maxConfirmedTime = parse(maximumConfirmedCollectionHour, "HH:mm", new Date());

  const isConfirmedWithinRange =
    isSameMinute(minConfirmedTime, minCollectionTime) ||
    (isAfter(minConfirmedTime, minCollectionTime) && isBefore(maxConfirmedTime, maxCollectionTime));

  return isConfirmedWithinRange;
};

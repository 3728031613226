import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader } from "components/utils/drawer";
import { useQuery } from "hooks";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import cx from "classnames";
import { MoreInfoButton } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/MoreInfoButton";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Link } from "react-router-dom";
import { MdiArrowOutward } from "components/miloDesignSystem/atoms/icons/MdiArrowOutward";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadXml } from "components/miloDesignSystem/atoms/icons/MdiDownloadXml";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { tradingDocumentsActions } from "api/trading-documents/actions";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
}

export const PanelHeader = ({ close, isScrolledOver }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: purchaseInvoice } = useTradingDocument({ id: panelId });
  const handleDownloadOrderXml = tradingDocumentsActions.useDownloadOrderXml();

  if (!purchaseInvoice) return null;

  return (
    <RightPanelHeader
      className={cx({
        slidingPanelHeaderShadow: isScrolledOver,
        panelHeaderDuringSlide: isScrolledOver,
      })}
    >
      {isScrolledOver ? (
        <div className="slidingPanelHeader">
          <div className="slidingPanelHeaderContent d-flex align-items-center gap-2">
            <ConfirmCheckbox tradingDocument={purchaseInvoice} />
            <div>Faktura VAT&nbsp;{purchaseInvoice.signature}</div>
          </div>
        </div>
      ) : (
        <ConfirmTradingDocument tradingDocument={purchaseInvoice} />
      )}
      <div className="d-flex align-items-center position-relative justify-content-end gap-1">
        <Link to={`/finances/import-invoices/check?id=${purchaseInvoice.id}`}>
          <Button className="text-uppercase" endIcon={MdiArrowOutward} size="small" variant="gray">
            Zweryfikuj fakturę
          </Button>
        </Link>
        <IconButton
          icon={<MdiDownloadXml size="18" />}
          onClick={() => handleDownloadOrderXml(purchaseInvoice)}
          title="Pobierz XML z danymi faktury zakupowej"
          variant="transparent"
        />
        <IconButton
          icon={<MdiDownloadPdf size="18" />}
          onClick={() => window.open(`${purchaseInvoice.tradingDocumentPdf?.pdf}`)}
          title="Podgląd PDF z danymi faktury zakupowej"
          variant="transparent"
        />
        <MoreInfoButton close={close} invoice={purchaseInvoice} panelId={panelId} />
        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};

import { Tab } from "api/trading-documents/models";
import { queryString } from "utilities";

type GetSearchProps = {
  tab: Tab;
  type: string;
  invoiceType?: string;
  query: {
    [x: string]: string;
  };
};

export const getSearch = ({ tab, type, invoiceType, query }: GetSearchProps): string => {
  const tabsQuery = {
    type: type,
    invoiceType: (invoiceType ?? "").toUpperCase(),
    paymentStatus: tab === "paid" ? "PAID" : tab === "not-paid" ? "NOT_PAID" : "",
  };
  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

export const isDateOlderThan24Hours = (givenDate: string): boolean => {
  const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
  const currentTime = new Date().getTime();
  const givenTime = new Date(givenDate).getTime();

  return currentTime - givenTime > twentyFourHoursInMilliseconds;
};

export const isDateOlderThan48Hours = (givenDate: string): boolean => {
  const fourtyEightHoursInMilliseconds = 48 * 60 * 60 * 1000;
  const currentTime = new Date().getTime();
  const givenTime = new Date(givenDate).getTime();

  return currentTime - givenTime > fourtyEightHoursInMilliseconds;
};

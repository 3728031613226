import { TableFilters } from "components/common/genericTable/useGenericTableFilters";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { getDateColor } from "pages/wms/overview/utils/getDateColor";
import { getMonthColor, getWeekColor } from "utilities";

export const getDatepointerColor = (
  date: string,
  rowBy: TableFilters["rowBy"],
): TypographyProps["color"] => {
  if (rowBy === "DAY") return getDateColor(date);
  if (rowBy === "WEEK") return getWeekColor(date);
  if (rowBy === "MONTH") return getMonthColor(date);
  return "neutralBlack100";
};

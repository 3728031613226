import * as React from "react";
import styles from "./IconLabel.module.css";
import cx from "classnames";

interface Props {
  img: string;
  title: string;
  value: string;
  manyValues?: boolean;
  theme?: "light" | "dark";
  size?: "1/2" | "1/3" | "1/1";
  children?: React.ReactNode;
}

export const IconLabel = ({
  img,
  title,
  value,
  theme = "dark",
  size = "1/2",
  children,
  manyValues = false,
}: Props) => {
  return (
    <div className={cx({ [styles.size12]: size === "1/2", [styles.size13]: size === "1/3" })}>
      <div className={styles.groupLabel}>
        <img src={img} alt="" />
        <span className={cx({ [styles.titleLight]: theme === "light" })}>{title}</span>
      </div>
      <div className={cx("d-flex align-items-center mb-2", styles.groupData)}>
        <div className={cx({ [styles.valueLight]: theme === "light" })}>
          {!manyValues && (
            <>
              {value}
              {children}
            </>
          )}
          {manyValues && (
            <>
              {value.split(";").map((value, index) => (
                <div key={index}>{value}</div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

import { StageSchemaAssignment } from "api/manufacturingNew/models";
import styles from "../RightPanel.module.css";
import { Button } from "components/common";
import binIcon from "assets/images/deleteNew.svg";
import { UseMutationResult } from "react-query";
import { QueryFetchError } from "api/types";
import { RightPanelTableRow } from "components/utils/drawer";
import { dateFormatter } from "utilities";
import { StateLabel } from "components/common/stateLabel";
import { stagesListConfig } from "../utils/panelTablesConfig";
import { ConfirmModal } from "components/common/confirmModal/ConfirmModalController";
import { AvatarWithTooltip } from "components/common/avatarWithTooltip/AvatarWithTooltip";

interface Props {
  stage: StageSchemaAssignment;
  index: number;
  confirmModal: ConfirmModal;
  deleteMutation: UseMutationResult<unknown, QueryFetchError, string, unknown>;
}

export const Stage = ({ stage, index, confirmModal, deleteMutation }: Props) => {
  return (
    <RightPanelTableRow className={styles.tableRowPPStages} grid={stagesListConfig.grid}>
      <div className="fw-700">{index + 1}.</div>
      <div>{stage.name}</div>
      <div>
        <div className="btnBase btnBase16 btnBaseSmall">
          <Button
            kind="secondary"
            size="square-s"
            title="Usuń etap"
            onClick={() => {
              if (stage.stageId) {
                confirmModal.open({
                  text: `Czy na pewno chcesz usunąć etap "${stage.name}"?`,
                  confirmText: "Usuń",
                  callback: () => deleteMutation.mutate(stage.id),
                });
              }
            }}
          >
            <img src={binIcon} alt="Usuń" />
          </Button>
        </div>
      </div>
      <div className="d-flex align-items-center justfiy-content-center h-100">
        <div
          className={styles.colorLabel}
          style={{
            backgroundColor: stage.backgroundColor,
          }}
        ></div>
      </div>
      <div>
        {stage.implementedBy === "CONTRACTOR" ? (
          <StateLabel kind="limeBlack"> kontrahent</StateLabel>
        ) : stage.implementedBy === "IN_HOUSE" ? (
          <StateLabel kind="deepPurple">na miejscu</StateLabel>
        ) : (
          "---"
        )}
      </div>
      <div>{stage.code}</div>
      <div>{stage.parent ? stage.parent : "---"}</div>

      <div>{stage.createdAt ? dateFormatter(stage.createdAt) : "---"}</div>

      <div>
        {stage.addedBy ? (
          <AvatarWithTooltip className={styles.showTooltip} user={stage.addedBy} />
        ) : (
          "---"
        )}
      </div>
    </RightPanelTableRow>
  );
};

export const warning = {
  warning12: "#FDBA0F1F",
  warning25: "#FFFAED",
  warning32: "#FDBA0F52",
  warning50: "#FFF5DB",
  warning75: "#FFECBA",
  warning100: "#FFDD86",
  warning200: "#FFCB45",
  warning300: "#FDBA0F",
  warning400: "#F2AA09",
  warning500: "#E09400",
  warning600: "#D07D00",
  warning700: "#B55E0F",
  warning800: "#5C3111",
  warning900: "#27170B",
};

import { patchInvoiceAdmissionStatus } from "api/trading-documents/calls";
import { InvoiceAdmission, InvoiceAdmissionStatus } from "api/trading-documents/models";
import { Button } from "components/common";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import checkIcon from "assets/images/check_small.svg";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import cx from "classnames";
import styles from "./ConfirmReception.module.css";
import { QueryClient } from "react-query";

interface Props {
  invoiceAdmission: InvoiceAdmission;
}

export const ConfirmReception = ({ invoiceAdmission }: Props) => {
  const handleRollback = (prevPanel: InvoiceAdmission, queryClient: QueryClient) => {
    queryClient.setQueryData<InvoiceAdmission>(
      tradingDocumentsKeys.invoiceAdmission.details(invoiceAdmission.tradingDocument),
      currentInvoiceAdmission => {
        assertIsDefined(currentInvoiceAdmission);
        return {
          ...currentInvoiceAdmission,
          status: prevPanel.status,
        };
      },
    );
  };

  const updateStatusOptions = useMutationOptions(
    () => {
      const data = { invoiceAdmissionLink: invoiceAdmission.id };
      return patchInvoiceAdmissionStatus(data);
    },
    ({ queryClient, toastr }) => ({
      onMutate: args => {
        const { status } = args as { status: InvoiceAdmissionStatus };

        const prevPanel = queryClient.getQueryData<InvoiceAdmission>(
          tradingDocumentsKeys.invoiceAdmission.details(String(invoiceAdmission.tradingDocument)),
        );

        queryClient.setQueryData<InvoiceAdmission>(
          tradingDocumentsKeys.invoiceAdmission.details(String(invoiceAdmission.tradingDocument)),
          currentInvoiceAdmission => {
            assertIsDefined(currentInvoiceAdmission);
            return {
              ...currentInvoiceAdmission,
              status: status,
            };
          },
        );

        return prevPanel;
      },
      onSuccess: (payload, _, context) => {
        const { status } = context as { status: InvoiceAdmissionStatus };
        queryClient.invalidateQueries(
          tradingDocumentsKeys.invoiceAdmission.details(invoiceAdmission.tradingDocument),
        );
        if (status === "CONFIRMED") {
          toastr.open({
            type: "success",
            title: "Udało się!",
            text: "Anulowano zatwierdzenie przyjęcia",
          });
        } else {
          toastr.open({
            type: "success",
            title: "Udało się!",
            text: "Zatwierdzono przyjęcie",
          });
        }
      },
      onError: (error, _, context) => {
        const prevPanel = context as InvoiceAdmission;
        handleRollback(prevPanel, queryClient);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const updateStatusMutation = useMutation(updateStatusOptions.mutationFn, updateStatusOptions);

  return (
    <div>
      <Button
        kind="confirm"
        onClick={() => {
          if (invoiceAdmission.status === "NEW") {
            updateStatusMutation.mutate({ status: "CONFIRMED" });
          } else {
            updateStatusMutation.mutate({ status: "NEW" });
          }
        }}
        size="small"
      >
        <div className="btnBase btnBaseSmall">
          <img alt="Gotowe" src={checkIcon} style={{ height: "16px", width: "16px" }} />

          <div className={cx(styles.btnLabel, "fs-10")}>
            {invoiceAdmission.status === "NEW" ? "Zatwierdź" : "Anuluj zatwierdzenie"}
          </div>
        </div>
      </Button>
    </div>
  );
};

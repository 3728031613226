import { useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import styles from "../Navbar.module.css";

export const RoutesMenu = () => {
  const match = useRouteMatch<{ tab: string }>("/routes/list/:tab");
  const tab = match?.params.tab || "active";
  return (
    <div className={styles.navLeftLink}>
      <NavLink className="pl-3 pr-3" to={`/routes/list/${tab}`} activeClassName={styles.active}>
        <strong>Trasy</strong>
      </NavLink>
    </div>
  );
};

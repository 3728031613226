import styles from "./RadioOptions.module.css";
import cx from "classnames";
import { useState } from "react";
import { Button } from "..";

export interface RadioOption {
  name: string | number;
  label: string | number;
  value: string | number | boolean | null;
}

interface Props {
  btnDisabled?: boolean;
  btnLabel?: string;
  close: () => void;
  options: RadioOption[];
  onSubmit: (value: string | number | boolean | null) => void;
}

export const RadioOptions = ({ btnDisabled, btnLabel, close, options, onSubmit }: Props) => {
  const [pickedValue, setPickedValue] = useState<string | number | boolean | null>(
    options[0].value,
  );

  const isChecked = (value: string | number | boolean | null): boolean => {
    return value === pickedValue;
  };

  return (
    <div className={styles.optionList}>
      <div>
        {options.map((option, index) => (
          <div className={cx(styles.optionRow, "px-3 py-2")} key={index}>
            <label className={styles.option}>
              <input
                type="radio"
                name="approve"
                checked={isChecked(option.value)}
                onChange={() => setPickedValue(option.value)}
              />
              <span className={styles.radiomark} />
              {option.name}
            </label>
            <div className="body-10 text-black-6 fw-500">{option.label}</div>
          </div>
        ))}
      </div>
      <div className="borderTop p-3">
        <Button
          disabled={btnDisabled}
          kind="submit"
          onClick={() => {
            onSubmit(pickedValue);
            close();
          }}
        >
          <div className="btnBase btnBaseSmall">{btnLabel ? btnLabel : "Zastosuj"}</div>
        </Button>
      </div>
    </div>
  );
};

import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import { wmsUnloadingApi } from "./api";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { deleteUnloading } from "../calls";
import { wmsKeys } from "../keys";
import { UnloadingDetails } from "../models";

const usePostStartUnloading = () => {
  return useMutation(wmsUnloadingApi.postStartUnloading, ({ toastr, queryClient }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePostFinishUnloading = () => {
  return useMutation(wmsUnloadingApi.postFinishUnloading, ({ toastr, queryClient }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useRemoveUnloading = (close: () => void, unloading: UnloadingDetails) => {
  return withDeleteConfirmation(
    useMutation(deleteUnloading, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries(wmsKeys.unloads.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto rozładunek "${unloading.signature}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć ten rozładunek?",
  )();
};

export const wmsUnloadingActions = {
  usePostFinishUnloading,
  usePostStartUnloading,
  useRemoveUnloading,
};

import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { InitialsAvatar } from "components/utils/userAvatar/InitialsAvatar";
import styles from "./CallCenter.module.css";
import { cx } from "utilities";

interface Props {
  driver: CallCenterRouteOrderListItem["driver"];
}

export const OrderDriverDetails = ({ driver }: Props) => {
  if (!driver)
    return (
      <Typography fontSize="14" fontWeight="600">
        ---
      </Typography>
    );

  return (
    <div className="d-flex align-items-center">
      {driver.avatar ? (
        <div className={cx(styles.listAvatar, styles.avatarSmall)}>
          <img alt="avatar" src={driver.avatar} />
        </div>
      ) : (
        <InitialsAvatar
          className={cx(styles.listAvatar, styles.avatarMedium)}
          firstName={driver.firstName}
          lastName={driver.lastName}
          style={{ margin: 0, background: "#00cf91" }}
        />
      )}
      <Typography className="ml-1" fontSize="14" fontWeight="700" noWrap>
        {driver.firstName} {driver.lastName}
      </Typography>
      <Typography className="ml-1" fontSize="12" fontWeight="600">
        ({driver.phone})
      </Typography>
    </div>
  );
};

import { RightPanelTableRow } from "components/utils/drawer";
import styles from "../AddWhEntriesModal.module.css";
import cx from "classnames";
import { WhEntryPackage as WhEntryPackageI } from "api/trading-documents/models";
import { whEntrySectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";

interface Props {
  index: number;
  whAggregateSearchPackage: string;
  whEntryPackage: WhEntryPackageI;
}

export const WhEntryPackage = ({ index, whAggregateSearchPackage, whEntryPackage }: Props) => {
  const isPackageSearchResultInWhEntryPackageInternalId = (): boolean => {
    if (whAggregateSearchPackage.length > 0) {
      return whEntryPackage.internalId.includes(whAggregateSearchPackage);
    }
    return false;
  };

  return (
    <RightPanelTableRow
      className={cx(styles.tableRowPZ, {
        [styles.stripesBgOrange]: whEntryPackage.usedQuantity === whEntryPackage.quantity,
        [styles.inaccessible]: whEntryPackage.usedQuantity === whEntryPackage.quantity,
        [styles.rowHighlight]: isPackageSearchResultInWhEntryPackageInternalId(),
      })}
      grid={whEntrySectionListConfig.grid}
    >
      <div>{index + 1}.</div>
      <div className="overflow">{whEntryPackage.name}</div>
      <div className="overflow">{whEntryPackage.internalId}</div>
      <div className="d-flex align-items-center justify-content-end">
        {whEntryPackage.usedQuantity}
      </div>
      <div className="d-flex align-items-center justify-content-end">
        {whEntryPackage.leftQuantity}
      </div>
      <div className="d-flex align-items-center justify-content-end">
        {whEntryPackage.quantity}&nbsp;szt.
      </div>
    </RightPanelTableRow>
  );
};

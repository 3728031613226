import { ExternalManufacturerModule, Module, ModuleNavigation } from "../moduleTypes/moduleTypes";
import { finances } from "./finances";
import { account } from "./account";
import { crm } from "./crm";
import { humanResources } from "./humanResources";
import { inventory } from "./inventory";
import { logistics } from "./logistics";
import { externalManufacturingConfig } from "./externalManufacturing";
import { manufacturing } from "./manufacturing";
import { reports } from "./reports";
import { rules } from "./rules";
import { sales } from "./sales";
import { support } from "./support";
import { wms } from "./wms";
import { callCenter } from "./callCenter";
import { externalManufacturerPanel } from "./externalManufacturerPanel";

export const moduleConfig: Record<Module, ModuleNavigation> = {
  account,
  crm,
  finances,
  humanResources,
  inventory,
  externalManufacturing: externalManufacturingConfig,
  logistics,
  manufacturing,
  reports,
  rules,
  sales,
  support,
  callCenter,
  wms,
};

export const externalManufacturerModuleConfig: Record<
  ExternalManufacturerModule,
  ModuleNavigation
> = {
  externalManufacturerPanel,
};

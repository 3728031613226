import styles from "../../EditTradingDocument.module.css";
import cx from "classnames";
import { TradingDocument } from "api/trading-documents/models";
import { Attributes } from "components/common/attributes/Attributes";
import { formatAttributes } from "pages/orders/shared/compareListingsModal/CompareListingsModal";

interface Props {
  counter: number;
  item: TradingDocument["items"][number]["tradingDocumentItems"][number];
}

export const TradingDocumentItem = ({ counter, item }: Props) => {
  return (
    <div className={cx(styles.tradingDocumentItem, "row w-100")}>
      <div className="col-1 d-flex align-items-center text-color-grey fs-12">{counter + 1}.</div>
      <div className="col-4 d-flex pl-0">
        <div className="row">
          <div className="col-12 fs-14">{item.name}</div>
          {item.verboseAttributesValues.length > 0 && (
            <div className="col-12 fs-10">
              <Attributes attributes={formatAttributes(item.verboseAttributesValues)} />
            </div>
          )}
        </div>
      </div>
      <div className="col-2 d-flex align-items-center pl-3">
        <div
          className={cx(styles.externalNumber, "row", {
            "text-color-grey": !item.ean,
          })}
        >
          {item.ean ? item.ean : "brak"}
        </div>
      </div>
      <div className="col-1 d-flex justify-content-center align-items-center">{item.quantity}</div>
      <div className="col-2 d-flex align-items-center">
        <div>{String(item.amountWithoutTax).replace(/\./g, ",")}</div>
        <div className={cx(styles.itemAmount, "fs-10 text-color-grey")}>{item.currency}</div>
      </div>
      <div className="col-1 d-flex align-items-center">{item.vatRate}%</div>
      <div className="col-1 d-flex align-items-center">
        <div>{String(item.amountWithTax).replace(/\./g, ",")}</div>
        <div className={cx(styles.itemAmount, "fs-10 text-color-grey")}>{item.currency}</div>
      </div>
    </div>
  );
};

export const magenta = {
  magenta12: "#FF60F71F",
  magenta25: "#FADAF8",
  magenta32: "#FF60F752",
  magenta50: "#F6C2F4",
  magenta75: "#F59FF1",
  magenta100: "#D96BD3",
  magenta200: "#D53FCD",
  magenta300: "#C420BB",
  magenta400: "#B00DA7",
  magenta500: "#8E0587",
  magenta600: "#61005C",
};

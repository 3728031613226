import { useOverviewReleasedAndReceived } from "api/wms/hooks";
import { useOverviewReleasedAndReceivedListColumns } from "../utils/useOverviewReleasedAndReceivedListColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import styles from "../Overview.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useGenericTableFilters } from "components/common/genericTable/useGenericTableFilters";
import { queryString } from "utilities";
import { GenericTable } from "components/common/genericTable/GenericTable";
import { detailsListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { parseTableRowData } from "components/common/genericTable/utils";

export const OverviewReleasedAndReceived = () => {
  const { setDateFrom, setRowBy, filters } = useGenericTableFilters();
  const search = queryString.stringify(filters);
  const {
    data: itemsReleasedAndReceived,
    error,
    isLoading,
    isFetching,
  } = useOverviewReleasedAndReceived(search, { keepPreviousData: true });
  const columns = useOverviewReleasedAndReceivedListColumns(itemsReleasedAndReceived, filters);
  const normalizedRows = parseTableRowData(itemsReleasedAndReceived);

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <Typography fontSize="16" fontWeight="600">
          Meble i paczki
        </Typography>
        <GenericTable.RowBySelect rowBy={filters.rowBy} setRowBy={setRowBy} />
      </div>

      <div className={styles.tableWrapper}>
        <Table
          rows={normalizedRows}
          // @ts-ignore
          columns={columns}
          error={error}
          isLoading={isLoading || isFetching}
          uiSchema={detailsListUiSchema}
        />
      </div>
      <GenericTable.Footer rowBy={filters.rowBy} setDateFrom={setDateFrom} />
    </div>
  );
};

export const convertSecondsToHoursMinutesAndSeconds = (
  seconds: string | number,
  showSeconds?: boolean,
): string => {
  const sec = Number(seconds);
  const hours = Math.floor(sec / 3600);
  const remainingMinutes = Math.floor((sec % 3600) / 60);
  const remainingSeconds = sec % 60;

  let result = "";

  if (hours > 0) {
    result += `${hours}h `;
  }

  if (remainingMinutes > 0) {
    result += `${remainingMinutes}min `;
  }

  if (remainingSeconds > 0 && showSeconds !== undefined && showSeconds) {
    result += `${remainingSeconds}s`;
  }

  return result;
};

import { OrderPoint, WarehouseDeliveryDetails } from "api/orders/models";
import p1Img from "assets/images/mapMarkers/p1.png";
import p3Img from "assets/images/mapMarkers/p3.png";
import p2Img from "assets/images/mapMarkers/p2.png";
import p4Img from "assets/images/mapMarkers/p4.png";

import p5Img from "assets/images/mapMarkers/p5.png";
import p7Img from "assets/images/mapMarkers/p7.png";
import p6Img from "assets/images/mapMarkers/p6.png";
import p8Img from "assets/images/mapMarkers/p8.png";

import p9Img from "assets/images/mapMarkers/p9.png";
import p10Img from "assets/images/mapMarkers/p10.png";
import p11Img from "assets/images/mapMarkers/p11.png";
import p12Img from "assets/images/mapMarkers/p12.png";

import p13Img from "assets/images/mapMarkers/p13.png";
import p14Img from "assets/images/mapMarkers/p14.png";
import p15Img from "assets/images/mapMarkers/p15.png";
import p16Img from "assets/images/mapMarkers/p16.png";

import p17Img from "assets/images/mapMarkers/p17.png";
import p18Img from "assets/images/mapMarkers/p18.png";
import p19Img from "assets/images/mapMarkers/p19.png";
import p20Img from "assets/images/mapMarkers/p20.png";

import p21Img from "assets/images/mapMarkers/p21.png";
import p22Img from "assets/images/mapMarkers/p22.png";
import p23Img from "assets/images/mapMarkers/p23.png";
import p24Img from "assets/images/mapMarkers/p24.png";

import p25Img from "assets/images/mapMarkers/p25.png";
import p26Img from "assets/images/mapMarkers/p26.png";
import p27Img from "assets/images/mapMarkers/p27.png";
import p28Img from "assets/images/mapMarkers/p28.png";
import externalImg from "assets/images/p49.png";

// high priority - box
import pfn1Img from "assets/images/mapMarkers/pfn1.png";
import pfn2Img from "assets/images/mapMarkers/pfn2.png";
import pfn3Img from "assets/images/mapMarkers/pfn3.png";
import pfn4Img from "assets/images/mapMarkers/pfn4.png";
import pfn5Img from "assets/images/mapMarkers/pfn5.png";
import pfn6Img from "assets/images/mapMarkers/pfn6.png";

// high priority - upholstery
import pfu1Img from "assets/images/mapMarkers/pfu1.png";
import pfu2Img from "assets/images/mapMarkers/pfu2.png";
import pfu3Img from "assets/images/mapMarkers/pfu3.png";
import pfu4Img from "assets/images/mapMarkers/pfu4.png";
import pfu5Img from "assets/images/mapMarkers/pfu5.png";
import pfu6Img from "assets/images/mapMarkers/pfu6.png";

// critical priority - box
import psn1Img from "assets/images/mapMarkers/psn1.png";
import psn2Img from "assets/images/mapMarkers/psn2.png";
import psn3Img from "assets/images/mapMarkers/psn3.png";
import psn4Img from "assets/images/mapMarkers/psn4.png";
import psn5Img from "assets/images/mapMarkers/psn5.png";
import psn6Img from "assets/images/mapMarkers/psn6.png";

// critical priority - upholstery
import psu1Img from "assets/images/mapMarkers/psu1.png";
import psu2Img from "assets/images/mapMarkers/psu2.png";
import psu3Img from "assets/images/mapMarkers/psu3.png";
import psu4Img from "assets/images/mapMarkers/psu4.png";
import psu5Img from "assets/images/mapMarkers/psu5.png";
import psu6Img from "assets/images/mapMarkers/psu6.png";

// awaiting - normal priority
import awaitingNormal0 from "assets/images/mapMarkers/awaiting/awaiting0.svg";
import awaitingNormal1 from "assets/images/mapMarkers/awaiting/awaiting1.svg";
import awaitingNormal2 from "assets/images/mapMarkers/awaiting/awaiting2.svg";
import awaitingNormal3 from "assets/images/mapMarkers/awaiting/awaiting3.svg";
import awaitingNormal4 from "assets/images/mapMarkers/awaiting/awaiting4.svg";
import awaitingNormal5 from "assets/images/mapMarkers/awaiting/awaiting5.svg";
import awaitingNormal6 from "assets/images/mapMarkers/awaiting/awaiting6.svg";
import awaitingNormal7 from "assets/images/mapMarkers/awaiting/awaiting7.svg";

// awaiting - high priority
import awaitingHigh0 from "assets/images/mapMarkers/awaiting/awaiting0F.svg";
import awaitingHigh1 from "assets/images/mapMarkers/awaiting/awaiting1F.svg";
import awaitingHigh2 from "assets/images/mapMarkers/awaiting/awaiting2F.svg";
import awaitingHigh3 from "assets/images/mapMarkers/awaiting/awaiting3F.svg";
import awaitingHigh4 from "assets/images/mapMarkers/awaiting/awaiting4F.svg";
import awaitingHigh5 from "assets/images/mapMarkers/awaiting/awaiting5F.svg";
import awaitingHigh6 from "assets/images/mapMarkers/awaiting/awaiting6F.svg";
import awaitingHigh7 from "assets/images/mapMarkers/awaiting/awaiting7F.svg";

// awaiting - critical priority
import awaitingCritical0 from "assets/images/mapMarkers/awaiting/awaiting0S.svg";
import awaitingCritical1 from "assets/images/mapMarkers/awaiting/awaiting1S.svg";
import awaitingCritical2 from "assets/images/mapMarkers/awaiting/awaiting2S.svg";
import awaitingCritical3 from "assets/images/mapMarkers/awaiting/awaiting3S.svg";
import awaitingCritical4 from "assets/images/mapMarkers/awaiting/awaiting4S.svg";
import awaitingCritical5 from "assets/images/mapMarkers/awaiting/awaiting5S.svg";
import awaitingCritical6 from "assets/images/mapMarkers/awaiting/awaiting6S.svg";
import awaitingCritical7 from "assets/images/mapMarkers/awaiting/awaiting7S.svg";
import avgPace from "assets/images/mapMarkers/avg_pace.png";
import visibilityLock from "assets/images/mapMarkers/visibility_lock.png";

import { Assign } from "utility-types";

const awaitingMarkerIconsMap = {
  "<1": {
    normal: awaitingNormal0,
    high: awaitingHigh0,
    critical: awaitingCritical0,
  },
  "1": {
    normal: awaitingNormal1,
    high: awaitingHigh1,
    critical: awaitingCritical1,
  },
  "2": {
    normal: awaitingNormal2,
    high: awaitingHigh2,
    critical: awaitingCritical2,
  },
  "3": {
    normal: awaitingNormal3,
    high: awaitingHigh3,
    critical: awaitingCritical3,
  },
  "4": {
    normal: awaitingNormal4,
    high: awaitingHigh4,
    critical: awaitingCritical4,
  },
  "5": {
    normal: awaitingNormal5,
    high: awaitingHigh5,
    critical: awaitingCritical5,
  },
  "6": {
    normal: awaitingNormal6,
    high: awaitingHigh6,
    critical: awaitingCritical6,
  },
  "7-<": {
    normal: awaitingNormal7,
    high: awaitingHigh7,
    critical: awaitingCritical7,
  },
};

const markerIconsMap = {
  ">13": {
    box: {
      standard: {
        normal: p1Img,
        high: pfn2Img,
        critical: psn2Img,
      },
      reclamation: {
        normal: p3Img,
        high: p3Img,
        critical: p3Img,
      },
    },
    upholstery: {
      standard: {
        normal: p2Img,
        high: pfu2Img,
        critical: psu2Img,
      },
      reclamation: {
        normal: p4Img,
        high: p4Img,
        critical: p4Img,
      },
    },
  },
  "7-13": {
    box: {
      standard: {
        normal: p5Img,
        high: pfn2Img,
        critical: psn2Img,
      },
      reclamation: {
        normal: p7Img,
        high: p7Img,
        critical: p7Img,
      },
    },
    upholstery: {
      standard: {
        normal: p6Img,
        high: pfu2Img,
        critical: psu2Img,
      },
      reclamation: {
        normal: p8Img,
        high: p8Img,
        critical: p8Img,
      },
    },
  },
  "2-6": {
    box: {
      standard: {
        normal: p9Img,
        high: pfn6Img,
        critical: psn6Img,
      },
      reclamation: {
        normal: p11Img,
        high: p11Img,
        critical: p11Img,
      },
    },
    upholstery: {
      standard: {
        normal: p10Img,
        high: pfu6Img,
        critical: psu6Img,
      },
      reclamation: {
        normal: p12Img,
        high: p12Img,
        critical: p12Img,
      },
    },
  },
  "0-1": {
    box: {
      standard: {
        normal: p13Img,
        high: pfn4Img,
        critical: psn4Img,
      },
      reclamation: {
        normal: p15Img,
        high: p15Img,
        critical: p15Img,
      },
    },
    upholstery: {
      standard: {
        normal: p14Img,
        high: pfu5Img,
        critical: psu4Img,
      },
      reclamation: {
        normal: p16Img,
        high: p16Img,
        critical: p16Img,
      },
    },
  },
  "-7|-1": {
    box: {
      standard: {
        normal: p17Img,
        high: pfn5Img,
        critical: psn5Img,
      },
      reclamation: {
        normal: p19Img,
        high: p19Img,
        critical: p19Img,
      },
    },
    upholstery: {
      standard: {
        normal: p18Img,
        high: pfu4Img,
        critical: psu5Img,
      },
      reclamation: {
        normal: p20Img,
        high: p20Img,
        critical: p20Img,
      },
    },
  },
  "-14|-8": {
    box: {
      standard: {
        normal: p21Img,
        high: pfn3Img,
        critical: psn3Img,
      },
      reclamation: {
        normal: p23Img,
        high: p23Img,
        critical: p23Img,
      },
    },
    upholstery: {
      standard: {
        normal: p22Img,
        high: pfu3Img,
        critical: psu3Img,
      },
      reclamation: {
        normal: p24Img,
        high: p24Img,
        critical: p24Img,
      },
    },
  },
  "<-14": {
    box: {
      standard: {
        normal: p25Img,
        high: pfn1Img,
        critical: psn1Img,
      },
      reclamation: {
        normal: p27Img,
        high: p27Img,
        critical: p27Img,
      },
    },
    upholstery: {
      standard: {
        normal: p26Img,
        high: pfu1Img,
        critical: psu1Img,
      },
      reclamation: {
        normal: p28Img,
        high: p28Img,
        critical: p28Img,
      },
    },
  },
};

function getAwaitingMarkerIcon(
  location: Assign<
    Pick<
      OrderPoint,
      | "hasUpholstery"
      | "type"
      | "leftDays"
      | "priority"
      | "numberOfDaysFromCreatedDate"
      | "warehouseDeliveryDetails"
    >,
    { warehouseDeliveryDetails: WarehouseDeliveryDetails | null }
  >,
) {
  const priority =
    location.priority === "NORMAL"
      ? "normal"
      : location.priority === "CRITICAL"
      ? "critical"
      : "high";

  const days = location.warehouseDeliveryDetails?.daysLeftToDelivery!;

  if (days < 1) {
    return awaitingMarkerIconsMap["<1"][priority];
  }
  if (days === 1) {
    return awaitingMarkerIconsMap["1"][priority];
  }
  if (days === 2) {
    return awaitingMarkerIconsMap["2"][priority];
  }
  if (days === 3) {
    return awaitingMarkerIconsMap["3"][priority];
  }
  if (days === 4) {
    return awaitingMarkerIconsMap["4"][priority];
  }
  if (days === 5) {
    return awaitingMarkerIconsMap["5"][priority];
  }
  if (days === 6) {
    return awaitingMarkerIconsMap["6"][priority];
  }
  if (days >= 7) {
    return awaitingMarkerIconsMap["7-<"][priority];
  }
  return awaitingMarkerIconsMap["7-<"][priority];
}

function getStandardMarkerIcon(
  location: Pick<
    OrderPoint,
    "hasUpholstery" | "type" | "leftDays" | "priority" | "numberOfDaysFromCreatedDate"
  >,
) {
  const priority =
    location.priority === "NORMAL"
      ? "normal"
      : location.priority === "CRITICAL"
      ? "critical"
      : "high";
  const kind = location.hasUpholstery ? "upholstery" : "box";
  const type = location.type === "STANDARD" ? "standard" : "reclamation";
  const days =
    location.type === "STANDARD"
      ? location.leftDays
      : (location.numberOfDaysFromCreatedDate - 14) * -1;

  if (days >= 14) {
    return markerIconsMap[">13"][kind][type][priority];
  }
  if (days >= 7) {
    return markerIconsMap["7-13"][kind][type][priority];
  }
  if (days >= 2) {
    return markerIconsMap["2-6"][kind][type][priority];
  }
  if (days >= 0) {
    return markerIconsMap["0-1"][kind][type][priority];
  }
  if (days >= -7) {
    return markerIconsMap["-7|-1"][kind][type][priority];
  }
  if (days >= -14) {
    return markerIconsMap["-14|-8"][kind][type][priority];
  }
  return markerIconsMap["<-14"][kind][type][priority];
}

export function getMarkerIcon(
  location: Assign<
    Pick<
      OrderPoint,
      | "id"
      | "hasUpholstery"
      | "type"
      | "leftDays"
      | "priority"
      | "numberOfDaysFromCreatedDate"
      | "isHidden"
      | "hideUntilIssueIsSolved"
      | "hideOnMapTo"
    >,
    { warehouseDeliveryDetails: WarehouseDeliveryDetails | null }
  >,
) {
  if (location.isHidden) {
    if (location.hideOnMapTo) {
      return avgPace;
    }
    return visibilityLock;
  }
  if (!location.warehouseDeliveryDetails) return getStandardMarkerIcon(location);
  if (location.warehouseDeliveryDetails.isInWarehouse) return getStandardMarkerIcon(location);
  if (location.warehouseDeliveryDetails.date === null) return getStandardMarkerIcon(location);

  return getAwaitingMarkerIcon(location);
}

export function getMarkerIconForGroups(
  location: Pick<
    OrderPoint,
    | "hasUpholstery"
    | "type"
    | "leftDays"
    | "numberOfDaysFromCreatedDate"
    | "priority"
    | "isHidden"
    | "hideUntilIssueIsSolved"
    | "hideOnMapTo"
  > & {
    method: string;
  },
  isPinned: boolean,
) {
  if (location.isHidden) {
    if (location.hideOnMapTo) {
      return avgPace;
    }
    return visibilityLock;
  }
  if (isPinned) {
    return externalImg;
  }
  return getStandardMarkerIcon(location);
}

import { Table } from "@tanstack/react-table";
import { Assign } from "utility-types";
import { TableProps } from "../../types";
import { Cell } from "./components/cell/Cell";
import { Row } from "./components/row/Row";

export type BodyProps<T> = Assign<
  { table: Table<T> },
  Pick<TableProps<T>, "onRowClick" | "uiSchema" | "overrides">
>;

export const Body = <T,>({ table, onRowClick, overrides, uiSchema }: BodyProps<T>) => {
  const rows = table.getRowModel().rows;
  return (
    <div>
      {rows.map(row => (
        <Row key={row.id} table={table} onRowClick={onRowClick} overrides={overrides} row={row}>
          {row.getVisibleCells().map(cell => (
            <Cell key={cell.id} cell={cell} uiSchema={uiSchema} />
          ))}
        </Row>
      ))}
    </div>
  );
};

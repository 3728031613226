import { UserWithInitials } from "api/users/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "../ExternalManufacturerPanel.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  orderedBy: UserWithInitials[];
}

export const OrderedByAvatars = ({ orderedBy }: Props) => {
  const additionalUsersLeft = orderedBy.length - 5;

  return (
    <div className="d-flex align-items-center">
      {Boolean(orderedBy.length) ? (
        <div className="d-flex align-items-center">
          {orderedBy.slice(0, 5).map(user => (
            <div className={styles.avatarCover} key={user.id}>
              <Avatar size="sm" theme="light" user={user} />
            </div>
          ))}
          {additionalUsersLeft > 0 && (
            <Typography color="deepPurple400" fontSize="12" fontWeight="700">
              +{additionalUsersLeft}
            </Typography>
          )}
        </div>
      ) : (
        <EmptyValue />
      )}
    </div>
  );
};

import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import trolleyIcon from "assets/images/trolley.svg";
import { CarrierOrders } from "routes/logistics/CarrierOrders";
import { FilterList } from "components/common/moduleNavigation/components/filtersSection/filterList/FilterList";
import { FilterType } from "components/common/filters/types";

export const carrierOrders: ModuleLink = {
  url: "carrier-orders",
  label: "Zlecenia do przewoźnika",
  icon: trolleyIcon,
  subSection: (
    <FilterList
      filters={[
        {
          type: FilterType.SelectUser,
          kind: "carrier",
          label: "przewoźnik",
          multiple: true,
          name: "carrier",
          searchBy: "users",
        },
      ]}
    />
  ),
  routing: CarrierOrders,
};

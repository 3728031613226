import { useRouteConfirmMutation } from "api/logistics/routes/hooks";
import checkIcon from "assets/images/checkSmall.svg";
import { Route, RouteListItem } from "api/routes/models";
import { Button } from "components/common";
import styles from "../../RightPanel.module.css";
import cx from "classnames";

interface Props {
  route: Route | RouteListItem;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MarkRouteReadyCheckbox = ({ route, setShowLoader }: Props) => {
  const confirmRouteMutation = useRouteConfirmMutation(setShowLoader);

  return (
    <Button
      className={cx({
        [styles.btnReady]: !route.isDraft,
      })}
      disabled={route.status === "SETTLED" || confirmRouteMutation.isLoading}
      kind="checkbox"
      onMouseDown={e => e.stopPropagation()}
      onClick={e => {
        e.stopPropagation();
        confirmRouteMutation.mutate(
          {
            id: route.id,
            toUpdate: {
              isDraft: !route.isDraft,
            },
          },
          {
            onSettled: () => {
              setShowLoader(false);
            },
          },
        );
      }}
      size="square-xs"
    >
      <div className="btnBase btnBaseSmall">
        <img alt="gotowa" src={checkIcon} />
      </div>
    </Button>
  );
};

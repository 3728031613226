import { RouteTrackingDayPosition } from "api/call-center/routes/models";
import { Button } from "components/common";
import { StateLabel } from "components/common/stateLabel";
import { DayType } from "../RouteTrackingModal";

interface Props {
  dayType: DayType;
  position: RouteTrackingDayPosition;
}

export const DelaySms = ({ dayType, position }: Props) => {
  if (dayType === "FUTURE") return <div></div>;

  if (!position.delaySms.sent && position.isDelivered) {
    return <div>---</div>;
  }

  if (!position.delaySms.sent && !position.isDelivered) {
    return (
      <div>
        <Button kind="create">
          <div className="btnBase btnBaseSmall">Wyślij teraz</div>
        </Button>
      </div>
    );
  }

  if (position.delaySms.sent && position.delaySms.sentDate) {
    return (
      <div>
        <StateLabel kind="teal6">wysłano</StateLabel>
      </div>
    );
  }

  return (
    <div>
      <StateLabel kind="red4">błąd wysyłki</StateLabel>
    </div>
  );
};

import {
  getEmailMessagesForOrder,
  getSmsMessage,
  getSmsMessages,
  getSmsMessagesQuery,
  patchSmsMessage,
} from "api/messages/calls";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createPrimitiveHook, createPrimitivePaginatedHook } from "hooks/createPrimitiveHook";
import { createAsyncUpdateConnector } from "utilities";

export const useSmsMessages = createPrimitivePaginatedHook(getSmsMessages);
export const useSmsMessagesQuery = createPaginatedApiQuery(getSmsMessagesQuery);
export const useSmsMessage = createPrimitiveHook(getSmsMessage);

export const useUpdateSmsMessage = createAsyncUpdateConnector(patchSmsMessage);

export const useEmailMessagesForOrder = createPaginatedApiQuery(getEmailMessagesForOrder);

import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import personIcon from "assets/images/person4.svg";
import { Carriers } from "routes/logistics/Carriers";

export const carriers: ModuleLink = {
  url: "carriers",
  label: "Przewoźnicy",
  icon: personIcon,
  subSection: <></>,
  routing: Carriers,
};

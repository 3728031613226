import { BankTransactionDetails } from "api/bank-accounts/models";
import styles from "./MonthlySummary.module.css";
import cx from "classnames";

interface Props {
  summaryByMonth: BankTransactionDetails["summaryByMonth"];
}

export const MonthlySummary = ({ summaryByMonth }: Props) => {
  const ordersWithoutNull = summaryByMonth.filter(el => el.month !== null);
  const ordersByMonth = [...ordersWithoutNull].sort((a, b) => {
    // We have to do some weird stuff with dates here to sort it, because backend
    // gives us date in weird, non-standard format: 1-2022
    const [month1, year1] = a.month.split("-");
    const [month2, year2] = b.month.split("-");

    const date1 = [year1, `${month1.length === 1 ? "0" : ""}${month1}`];
    const date2 = [year2, `${month2.length === 1 ? "0" : ""}${month2}`];
    return date1 > date2 ? 1 : -1;
  });

  const orderAmount = (amount: number, currency: string) => {
    const [number, decimal] = amount.toFixed(2).split(".");
    return (
      <>
        <span className="text-color-darkgrey">{number}</span>
        <span className="fs-12 text-color-grey">
          .{decimal} {currency}
        </span>
      </>
    );
  };

  const largestColumnSize = (() => {
    const sorted = [...ordersByMonth].sort((a, b) => (a.orders.length > b.orders.length ? -1 : 1));
    return sorted[0]?.orders.length ?? 0;
  })();

  const hasOrders = ordersByMonth.length > 0;

  return (
    <div className="p-3 mt-3">
      <div>
        <h5>Zestawienie miesięczne</h5>
      </div>
      <div
        className={styles.grid}
        style={{
          gridTemplateColumns: `auto repeat(${ordersByMonth.length}, 1fr)`,
        }}
      >
        <div>
          <div className={cx(styles.cell, styles.bt, styles.bl, styles.br, styles.bb)}>Miesiąc</div>
          <div className={cx(styles.cell, styles.bl, styles.br, styles.bb)}>
            <div>
              Zamówienia
              {hasOrders && <div>&nbsp;</div>}
            </div>
            {hasOrders &&
              Array(largestColumnSize - 1)
                .fill(null)
                .map((_, index) => (
                  <div className={styles.cell} key={index}>
                    <div>&nbsp; &nbsp;</div>
                    <div>&nbsp; &nbsp;</div>
                  </div>
                ))}
          </div>
          <div className={cx(styles.cell, styles.bl, styles.br, styles.bb)}>Razem</div>
        </div>
        <div>
          {ordersByMonth.map(summary => {
            const date = new Date();
            date.setMonth(Number(summary.month.split("-")[0]) - 1);
            return (
              <div key={summary.month}>
                <div className={cx(styles.cell, styles.bb, styles.br, styles.bt)}>
                  {date.toLocaleString("default", { month: "long" })}
                </div>
                <div>
                  {summary.orders.map((order, index) => (
                    <div className={cx(styles.cell, styles.br)} key={order.signature}>
                      <span>
                        {index + 1}. {order.signature}
                      </span>{" "}
                      <div className={styles.separator} />
                      suma: &nbsp;{orderAmount(order.amount, order.currency)}
                      <br />
                      {order.receipt.externalId} ({order.receipt.issued})
                    </div>
                  ))}
                  {Array(largestColumnSize - summary.orders.length)
                    .fill(null)
                    .map((_, index) => (
                      <div className={cx(styles.cell, styles.br)} key={index}>
                        &nbsp; &nbsp;
                      </div>
                    ))}
                </div>
                <div className={cx(styles.cell, styles.bt, styles.br, styles.bb)}>
                  {summary.summary}zł
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

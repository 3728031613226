import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createApiQuery } from "hooks/createApiQuery";
import {
  getBankAccounts,
  getBankAccountTransactions,
  getBanks,
  getBankAccountTransaction,
} from "./calls";

export const useBanks = createApiQuery(getBanks);
export const useBankAccounts = createPaginatedApiQuery(getBankAccounts);
export const useBankAccountTransactions = createPaginatedApiQuery(getBankAccountTransactions);
export const useBankAccountTransaction = createApiQuery(getBankAccountTransaction);

import { wmsFileFactory } from "api/wms/calls";
import { Loading, LoadingDetails } from "api/wms/models";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";

export const useDownloadLoadingPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();

  async function handleDownloadLoadingPdf(loading: Loading | LoadingDetails) {
    if (!loading) throw new Error("An error occurred");
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = wmsFileFactory.loadingPdf(loading);
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    }
  }

  return handleDownloadLoadingPdf;
};

import calculateIcon from "assets/images/calculatePink.svg";
import accountancyIcon from "assets/images/support.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import favicon from "assets/images/favicons/fv_help.svg";

export const support: ModuleNavigation = {
  favicon,
  labelBackground: "#b65eff",
  label: "Wsparcie",
  iconBackground: "#431d87",
  icon: calculateIcon,
  menuIcon: accountancyIcon,
  name: "SUPPORT",
  url: "/support",
  navigationSections: [{ navigationList: [] }],
};

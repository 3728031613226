import { useQuery } from "hooks";
import { Autocomplete, AutocompleteAsyncHandler } from "components/utils";
import { getProductsLight } from "api/products/calls";
import { ProductLight } from "api/products/models";
import { DrawerSection } from "components/utils/drawer";

export const AutocompleteAsyncFilter = ({
  type,
}: {
  type: "excludeWithProducts" | "selectWithProducts";
}) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });

  function getObjectFilter(filter: string | undefined) {
    if (filter === undefined) return null;
    try {
      return JSON.parse(decodeURIComponent(filter));
    } catch (err) {
      return null;
    }
  }

  const productsQuery = getObjectFilter(query[type]);
  const selectedItem = productsQuery ? [{ id: productsQuery.id, name: productsQuery.name }] : [];

  const handleOnChange = (product: ProductLight[]) => {
    if (product[0]) {
      updateQuery({
        [type]: encodeURIComponent(JSON.stringify({ id: product[0].id, name: product[0].name })),
        page: 1,
      });
    } else {
      updateQuery({ [type]: "", page: 1 });
    }
  };

  return (
    <DrawerSection
      title={type === "excludeWithProducts" ? "Nie zawiera produktu" : "Zawiera produkt"}
    >
      <AutocompleteAsyncHandler fetchFrom={getProductsLight}>
        <Autocomplete
          selectedItems={selectedItem}
          placeholder="Szukaj produktu"
          onChange={handleOnChange}
          multiple={false}
        />
      </AutocompleteAsyncHandler>
    </DrawerSection>
  );
};

import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import palletIcon from "assets/images/favicons/fv_pallet.svg";
import favicon from "assets/images/favicons/fv_producer.svg";
import externalManufacturingIcon from "assets/images/pallet.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { manufacturerLineItems } from "./routes/manufacturerLineItems";

export const externalManufacturerPanel: ModuleNavigation = {
  favicon,
  labelBackground: colorPalette.pink200,
  label: "Panel producenta",
  iconBackground: colorPalette.pink600,
  icon: palletIcon,
  menuIcon: externalManufacturingIcon,
  name: "EXTERNAL_MANUFACTURER_PANEL",
  url: "/external-manufacturer-panel",
  navigationSections: [
    {
      navigationList: [manufacturerLineItems],
    },
  ],
};

import {
  ExecutionStatus,
  ExternalManufacturerLineItemsTab,
} from "api/external-manufacturing/models";
import { queryString } from "utilities";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  tab: ExternalManufacturerLineItemsTab;
};

const tabToQueryDict: Record<ExternalManufacturerLineItemsTab, Record<string, string>> = {
  [ExternalManufacturerLineItemsTab["to-do"]]: {
    executionStatus: ExecutionStatus.NOT_STARTED,
  },
  [ExternalManufacturerLineItemsTab["in-production"]]: {
    executionStatus: ExecutionStatus.IN_PROGRESS,
  },
  [ExternalManufacturerLineItemsTab["ready-to-deliver"]]: {
    executionStatus: ExecutionStatus.READY_TO_DELIVER,
  },
  [ExternalManufacturerLineItemsTab["picked-up"]]: {
    pickedUpBySeller: "true",
  },
  [ExternalManufacturerLineItemsTab["cancelled"]]: { isCancelled: "true" },
  [ExternalManufacturerLineItemsTab["all"]]: {
    executionStatus: "",
  },
};

export const getSearch = ({ query, tab }: GetSearchProps) => {
  if (tab === ExternalManufacturerLineItemsTab["picked-up"]) {
    return queryString.stringify({
      ...query,
      pickedUpBySeller: tabToQueryDict["picked-up"].pickedUpBySeller,
      format: "json",
    });
  }

  if (tab === ExternalManufacturerLineItemsTab["cancelled"]) {
    return queryString.stringify({
      ...query,
      isCancelled: tabToQueryDict["cancelled"].isCancelled,
      format: "json",
    });
  }

  const tabsQuery = {
    executionStatus: tabToQueryDict[tab].executionStatus,
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
    format: "json",
  });
};

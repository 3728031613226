import { FilterType } from "components/common/filters/types";
import { FilterList } from "components/common/moduleNavigation/components/filtersSection/filterList/FilterList";
import { useSelector } from "hooks";

export const WarehouseFinancialStateSubSection = () => {
  const warehouses = useSelector(store => store.partials.warehouses);

  const warehousesToPick: { label: string; value: string }[] = warehouses.map(warehouse => {
    return {
      label: warehouse.name,
      value: String(warehouse.id),
    };
  });

  return (
    <FilterList
      filters={[
        {
          type: FilterType.Select,
          kind: "label",
          label: "magazyn",
          multiple: true,
          name: "warehouses",
          options: warehousesToPick,
        },
        {
          type: FilterType.DateRange,
          label: "Przyjęto / Wydano",
          name: ["inAtFrom", "inAtTo"],
          additionalKeys: ["outAtFrom", "outAtTo"],
          showMonths: true,
        },
        {
          type: FilterType.Date,
          label: "data wartości magazynu",
          name: "inAt",
        },
      ]}
    />
  );
};

import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import { StateLabel } from "components/common/stateLabel";
import awaitingIcon from "assets/images/avgPace.svg";
import confirmedIcon from "assets/images/checkSmall.svg";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import styles from "../RouteOrdersList.module.css";
import { useMutation } from "hooks/useMutation";
import { callCenterRoutesKeys } from "api/call-center/routes/keys";
import { PartialOf } from "typeUtilities";
import { confirmOrderCollectionDateByMiloUser } from "api/orders/calls";
import { useSelector } from "hooks";

interface Props {
  order: CallCenterRouteOrderListItem;
}

export const ConfirmationStatus = ({ order }: Props) => {
  const me = useSelector(state => state.auth.user!);
  const confirmMutation = useMutation(
    ({ id }: { id: number; toUpdate: PartialOf<CallCenterRouteOrderListItem> }) =>
      confirmOrderCollectionDateByMiloUser(id),
    ({
      queryUtils: { handleMutate, handlePaginatedListUpdate, rollback, rollbackList },
      queryClient,
    }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevList = handlePaginatedListUpdate(
          callCenterRoutesKeys.routeOrder.list(),
          id,
          toUpdate,
        );
        const prevPanel = queryClient.getQueryData(callCenterRoutesKeys.routeOrder.details(id));
        if (prevPanel) {
          handleMutate(callCenterRoutesKeys.routeOrder.details(id), toUpdate);
        }
        return { prevList, prevPanel };
      },
      onError: (error, { id }, context) => {
        rollback(callCenterRoutesKeys.routeOrder.details(id), context?.prevPanel, error);
        rollbackList(callCenterRoutesKeys.routeOrder.list(), context?.prevList, id, error);
      },
    }),
  );

  if (order.hasSmsSent && !order.isDeliveryDateConfirm) {
    return (
      <div className="d-flex align-items-center gap-2">
        <StateLabel kind="yellow5">
          <img alt="oczekujące" src={awaitingIcon} style={{ height: "16px", width: "16px" }} />
          <span className="fw-700">Oczekuje</span>
        </StateLabel>
        <IconButton
          className={styles.confirmButton}
          icon={MdiCheck}
          variant="outline"
          disabled={confirmMutation.isLoading || order.isDeliveryDateConfirm}
          onMouseDown={e => {
            e.stopPropagation();
            confirmMutation.mutate({
              id: order.id,
              toUpdate: {
                isDeliveryDateConfirm: true,
                deliveryDateConfirmedBy: me,
                deliveryDateConfirmationSource: "INTERNAL",
                deliveryDateConfirmTimestamp: new Date().toJSON(),
              },
            });
          }}
        />
      </div>
    );
  }

  if (order.isDeliveryDateConfirm) {
    return (
      <div>
        <StateLabel kind="darkGreen">
          <img alt="potwierdzone" src={confirmedIcon} style={{ height: "16px", width: "16px" }} />
          <span className="fw-700">Potwierdzono</span>
        </StateLabel>
      </div>
    );
  }

  return <div className="fw-700">---</div>;
};

import { FLAVOR } from "CONSTANTS";
import { PartialsState } from "ducks/partials";
import { config as defaultConfig } from "../ownerConfig/default";
import { OwnerConfig } from "../ownerConfig/types";

let cfg: OwnerConfig | null = null;

type Name = NonNullable<PartialsState["owner"]>["name"];

function assignConfig(name: Name) {
  try {
    const { config } = require("../ownerConfig/" + name.toLowerCase());
    cfg = config;
  } catch (err) {
    cfg = defaultConfig;
  }
}

/**
 * Hook returns owner's config based on current flavor.
 */
export function useOwnerConfig() {
  const owner = process.env.REACT_APP_API?.split("Test")[0].toLowerCase();
  if (owner) {
    assignConfig(owner);
    return cfg![FLAVOR];
  } else {
    return defaultConfig[FLAVOR];
  }
}

import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";

import { SalesAccount } from "./models";
import { Pagination } from "../types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { salesAccountKeys } from "./keys";
import { parsePatchData } from "utilities/parsePatchData";
import { Assign } from "utility-types";

const api = tokenRefreshMiddleware(apiFetch);

export const getSalesAccounts = (search: string): ApiFetcher<Pagination<SalesAccount>> => ({
  key: salesAccountKeys.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/sales-accounts/items" + search,
    }),
});

export const getSalesAccount = (id: string): ApiFetcher<SalesAccount> => ({
  key: salesAccountKeys.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/sales-accounts/items/" + id,
    }),
});

export const postSalesAccount = (data: Partial<Omit<SalesAccount, "id">>) =>
  queryFetch<SalesAccount>({
    method: "POST",
    url: "/sales-accounts/items",
    data,
  });

export const patchSalesAccount = (data: Assign<Partial<SalesAccount>, { id: number }>) =>
  queryFetch<SalesAccount>({
    method: "PATCH",
    url: "/sales-accounts/items/" + data.id,
    data: parsePatchData(data),
  });

export const deleteSalesAccount = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/sales-accounts/items/" + id,
  });

export const getSalesAccountsB2b = (search: string): ApiFetcher<Pagination<SalesAccount>> => ({
  key: salesAccountKeys.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/sales-accounts/b2b/items" + search,
    }),
});

export const getSalesAccountB2b = (id: string): ApiFetcher<SalesAccount> => ({
  key: salesAccountKeys.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/sales-accounts/b2b/items/" + id,
    }),
});

export const patchSalesAccountB2b = (data: Assign<Partial<SalesAccount>, { id: number }>) =>
  queryFetch<SalesAccount>({
    method: "PATCH",
    url: "/sales-accounts/b2b/items/" + data.id,
    data: parsePatchData(data),
  });

export const postSalesAccountB2b = (data: Partial<Omit<SalesAccount, "id">>) =>
  queryFetch<SalesAccount>({
    method: "POST",
    url: "/sales-accounts/b2b/items",
    data,
  });

export const triggerDownloadOffersFromAllegro = (id: number) =>
  api<void>({
    method: "GET",
    url: `/allegro/${id}/add`,
  });

export const triggerDownloadOffersFromAmazon = () =>
  api<void>({
    method: "GET",
    url: "/amazon/get-offers",
  });

export const triggerDownloadOffersFromEbay = () =>
  api<void>({
    method: "GET",
    url: "/ebay/get-offers",
  });

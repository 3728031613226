import { Partials } from "api/other/models";
import { UserWithInitials } from "api/users/models";

export const normalizeUserDetails = (
  user: Partials["users"][number] | undefined,
): UserWithInitials | null => {
  if (user)
    return {
      avatar: user.avatar,
      firstName: user.firstName,
      id: user.id,
      initialsBackgroundColor: user.initialsBackgroundColor,
      initialsTextColor: user.initialsTextColor,
      lastName: user.lastName,
    };
  return null;
};

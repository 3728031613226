import { UnsentEmailPreview } from "api/external-manufacturing/models";
import { FailedResponseModal } from "components/miloDesignSystem/organisms/failedResponseModal/FailedResponseModal";
import { pluralize } from "utilities";

interface Props {
  close: () => void;
  preview: UnsentEmailPreview;
  sentEmails: number;
}

export const AskForSingleProductionItemsStatusReplyModal = ({
  close,
  preview,
  sentEmails,
}: Props) => {
  if (!preview) return null;

  const unsentEmailsNumber = Object.values(preview.unsentEmails).reduce((acc, values) => {
    acc += values.objects.length;
    return acc;
  }, 0);

  const successfullySentEmailsNumber = sentEmails - unsentEmailsNumber;

  return (
    <FailedResponseModal
      close={close}
      data={{
        success: {
          message:
            successfullySentEmailsNumber !== 0
              ? `Wysłano ${successfullySentEmailsNumber} ${pluralize.pl(
                  successfullySentEmailsNumber,
                  {
                    singular: "mail",
                    plural: "maile",
                    other: "maili",
                  },
                )} z prośbą o potwierdzenie`
              : "Brak pomyślnie wysłanych maili",
        },
        failed: {
          message:
            unsentEmailsNumber !== 0
              ? `Nie wysłano ${unsentEmailsNumber} ${pluralize.pl(unsentEmailsNumber, {
                  singular: "maila",
                  plural: "maili",
                  other: "maili",
                })}:`
              : "Brak niewysłanych maili",
          reasons: Object.values(preview.unsentEmails).map(reason => ({
            message: reason.message,
            signatures: reason.objects.map(object => object.signature),
          })),
        },
      }}
      title="Status wysyłki maili z prośbą o potwierdzenie"
    />
  );
};

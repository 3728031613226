import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import {
  ExecutionStatus,
  ExternalManufacturerItem,
  ExternalManufacturerLineItem,
  ExternalManufacturingOrder,
  OrderLineItemDetails,
  PriorityChoice,
  SendNotificationToRecipient,
  OrderLineItemListItem,
  CreateManufacturingOrderItem,
  AskForSingleProductionItemsStatus,
  RouteLineItem,
  AskForSingleProductionItemsStatusFiltered,
  UnsentEmailPreview,
  EmailsSchedule,
} from "./models";
import { externalManufacturingKeys } from "./keys";
import { PartialOf } from "typeUtilities";
import { dateFns, omit } from "utilities";

const getOrders = (search: string = ""): ApiFetcher<Pagination<ExternalManufacturingOrder>> => ({
  key: externalManufacturingKeys.main.manufacturingOrders.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/items" + search,
    }),
});

const getRouteLineItems = (search: string = ""): ApiFetcher<Pagination<RouteLineItem>> => ({
  key: externalManufacturingKeys.main.routeLineItems.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/line-items-with-production-schedule-status" + search,
    }),
});

const getManufacturingOrder = (id: UUID): ApiFetcher<ExternalManufacturingOrder> => ({
  key: externalManufacturingKeys.main.manufacturingOrders.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/items/" + id,
    }),
});

const getOrderLineItems = (search: string = ""): ApiFetcher<Pagination<OrderLineItemListItem>> => ({
  key: externalManufacturingKeys.main.orderLineItems.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/line-items" + search,
    }),
});

const getOrderLineItem = (id: UUID): ApiFetcher<OrderLineItemDetails> => ({
  key: externalManufacturingKeys.main.orderLineItems.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/external-manufacturing/orders/line-items/${id}`,
    }),
});

const patchOrderLineItem = (data: PartialOf<OrderLineItemDetails>, id: UUID | number) =>
  queryFetch<OrderLineItemDetails>({
    method: "PATCH",
    url: `/external-manufacturing/orders/line-items/${id}`,
    data,
  });

const patchRouteLineItem = (data: PartialOf<RouteLineItem>, id: UUID | number) =>
  queryFetch<RouteLineItem>({
    method: "PATCH",
    url: `/external-manufacturing/orders/line-items-with-production-schedule-status/${id}`,
    data,
  });

const getManufacturerItems = (
  search: string = "",
): ApiFetcher<Pagination<ExternalManufacturerItem>> => ({
  key: externalManufacturingKeys.externalManufacturer.manufacturingOrders.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/manufacturer-items" + search,
    }),
});

const getManufacturerItem = (id: UUID): ApiFetcher<ExternalManufacturerItem> => ({
  key: externalManufacturingKeys.externalManufacturer.manufacturingOrders.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/manufacturer-items/" + id,
    }),
});

const getEmailsScheduled = (search: string = ""): ApiFetcher<EmailsSchedule[]> => ({
  key: externalManufacturingKeys.main.emailsSchedule.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/emails-to-send/scheduled" + search,
    }),
});

const getManufacturerLineItems = (
  search: string = "",
): ApiFetcher<Pagination<ExternalManufacturerLineItem>> => ({
  key: externalManufacturingKeys.externalManufacturer.manufacturingLineItems.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/external-manufacturing/orders/manufacturer-line-items" + search,
    }),
});

const getManufacturerLineItem = (id: UUID): ApiFetcher<OrderLineItemDetails> => ({
  key: externalManufacturingKeys.externalManufacturer.manufacturingLineItems.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/external-manufacturing/orders/manufacturer-line-items/${id}`,
    }),
});

const patchManufacturerLineItem = (data: PartialOf<OrderLineItemDetails>, id: UUID | number) =>
  queryFetch<OrderLineItemDetails>({
    method: "PATCH",
    url: `/external-manufacturing/orders/manufacturer-line-items/${id}`,
    data,
  });

const bulkUpdateLineItems = (args: {
  ids: UUID[];
  pickedUpBySeller?: boolean;
  executionStatus?: ExecutionStatus;
  priority?: PriorityChoice;
  estimatedDeliveryDate?: string;
  pickupBySellerPlannedAt?: string;
  pickedUpAt?: string;
  hasConfirmedDeliveryDate?: boolean;
}) =>
  queryFetch<{ message: "ok" }>({
    method: "PATCH",
    url: `/external-manufacturing/orders/bulk-update-line-items?ids=${args.ids.join(",")}`,
    data: omit(args, "ids"),
  });

export const postNotificationToRecipient = (data: SendNotificationToRecipient) =>
  queryFetch<void>({
    method: "POST",
    url: "/external-manufacturing/notifications/notify-manufacturer-about-order",
    data,
  });

const postConfirmDeliveryDate = (id: UUID) =>
  queryFetch<{ message: "ok" }>({
    method: "POST",
    url: `/external-manufacturing/orders/confirm-delivery-date/${id}`,
  });

const postCreateManufacturingOrders = (data: { ids: number[] }) =>
  queryFetch<CreateManufacturingOrderItem[]>({
    method: "POST",
    url: "/orders/create-manufacturing-orders",
    data,
  });

const postCreateManufacturingOrdersFromItems = (data: CreateManufacturingOrderItem[]) =>
  queryFetch<void>({
    method: "POST",
    url: "/orders/create-manufacturing-orders-from-items",
    data,
  });

const postAskForSingleProductionItemsStatus = (data: AskForSingleProductionItemsStatus) =>
  queryFetch<UnsentEmailPreview>({
    method: "POST",
    url: "/external-manufacturing/notifications/ask-for-single-production-items-status",
    data,
  });

const postAskForSingleProductionItemsStatusFiltered = (
  search: string = "",
  data: AskForSingleProductionItemsStatusFiltered,
) =>
  queryFetch<UnsentEmailPreview>({
    method: "POST",
    url:
      "/external-manufacturing/notifications/ask-for-single-production-items-status-filtered" +
      search,
    data,
  });

const bulkUpdateExternalManufacturingMainLineItems = (args: {
  ids: UUID[];
  estimatedDeliveryDate?: string;
  hasConfirmedDeliveryDate?: boolean;
  pickupBySellerPlannedAt?: string | null;
  pickedUpAt?: string;
  pickedUpBySeller?: boolean;
  priority?: PriorityChoice;
}) =>
  queryFetch<{ message: "ok" }>({
    method: "PATCH",
    url: `/external-manufacturing/orders/milo-admin-bulk-update-line-items?ids=${args.ids.join(
      ",",
    )}`,
    data: omit(args, "ids"),
  });

export const externalManufacturingFileFactory = (() => {
  const getDate = () => {
    return dateFns.format(new Date(), "dd-MM-yyyy-HH-mm");
  };
  const formatSignature = (signature: string) => {
    return signature.replace(/\//g, "-");
  };

  return {
    externalManufacturingLineItemPdf: (
      externalManufacturingLineItem: Pick<OrderLineItemListItem, "id" | "signature">,
    ) => ({
      url: `/external-manufacturing/orders/production-line-items-labels?ids=${externalManufacturingLineItem.id}`,
      name: `${formatSignature(externalManufacturingLineItem.signature)}-${getDate()}`,
    }),
    externalManufacturingOrderPdf: (
      externalManufacturingOrder: Pick<ExternalManufacturingOrder, "id" | "signature">,
    ) => ({
      url: `/external-manufacturing/orders/production-line-items-labels?manufacturing_order=${externalManufacturingOrder.id}`,
      name: `${formatSignature(externalManufacturingOrder.signature)}-${getDate()}`,
    }),
  };
})();

export const externalManufacturingApi = {
  getOrders,
  getManufacturingOrder,
  getManufacturerItems,
  getManufacturerItem,
  bulkUpdateLineItems,
  getManufacturerLineItems,
  getManufacturerLineItem,
  patchManufacturerLineItem,
  postNotificationToRecipient,
  postConfirmDeliveryDate,
  patchOrderLineItem,
  getOrderLineItems,
  getOrderLineItem,
  postCreateManufacturingOrders,
  postCreateManufacturingOrdersFromItems,
  bulkUpdateExternalManufacturingMainLineItems,
  postAskForSingleProductionItemsStatus,
  getRouteLineItems,
  patchRouteLineItem,
  postAskForSingleProductionItemsStatusFiltered,
  getEmailsScheduled,
};

import { useCallCenterRoute } from "api/call-center/routes/hooks";
import { callCenterRoutesKeys } from "api/call-center/routes/keys";
import { CallCenterRouteDetails } from "api/call-center/routes/models";
import { useQueryClient } from "react-query";
import { match } from "react-router-dom";

export const useCallCenterRouteDetails = (isCallCenterRoute: match<{ routeId: string }> | null) => {
  const queryClient = useQueryClient();
  const callCenterRouteId = isCallCenterRoute?.params.routeId;

  const callCenterRouteData = callCenterRouteId
    ? queryClient.getQueryData<CallCenterRouteDetails>(
        callCenterRoutesKeys.route.details(String(callCenterRouteId)),
      )
    : null;

  const { data: fetchedCallCenterRoute, isLoading: callCenterRouteIsLoading } = useCallCenterRoute(
    { id: callCenterRouteId ? callCenterRouteId : "" },
    {
      enabled:
        Boolean(callCenterRouteId) &&
        (callCenterRouteData === undefined || callCenterRouteData === null),
    },
  );

  const callCenterRoute = callCenterRouteData ?? fetchedCallCenterRoute;

  return {
    callCenterRoute,
    callCenterRouteIsLoading,
    callCenterRouteId,
  };
};

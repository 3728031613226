import { Button } from "components/miloDesignSystem/atoms/button";
import { TradingDocumentItem } from "api/trading-documents/models";
import styles from "../../RightPanel.module.css";
import { AsyncInput } from "components/utils";
import { UUID } from "api/types";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { tradingDocumentsActions } from "api/trading-documents/actions";

interface Props {
  tradingDocumentId: UUID;
  tradingDocumentItem: TradingDocumentItem;
}

export const EditVatRateSection = ({ tradingDocumentId, tradingDocumentItem }: Props) => {
  const { setVatRateMutation, setShowInput, showInput } = tradingDocumentsActions.useSetVatRate(
    tradingDocumentId,
  );

  return (
    <div className="d-flex align-items-center justify-content-end">
      {!showInput ? (
        <Button
          onClick={event => {
            event.stopPropagation();
            setShowInput(true);
          }}
          size="small"
          variant="transparent"
        >
          <Typography fontSize="12" fontWeight="700">
            {tradingDocumentItem.vatRate}%
          </Typography>
        </Button>
      ) : (
        <div>
          <AsyncInput
            disabled={setVatRateMutation.isLoading}
            onChange={value => {
              setVatRateMutation.mutate({
                tradingDocumentItemsIds: [tradingDocumentItem.id],
                vatRate: value,
              });
            }}
            overwrites={{
              container: { className: styles.editVatRateInput },
              input: { className: styles.editVatRateInput },
            }}
            placeholder="VAT"
            type="number"
            value={tradingDocumentItem.vatRate}
          />
        </div>
      )}
    </div>
  );
};

import React from "react";
import { match, useHistory, useRouteMatch } from "react-router-dom";
import styles from "../moduleNavigation/ModuleNavigation.module.css";
import arrowBackIcon from "assets/images/arrowBackLight.svg";
import { Button } from "components/common";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { useQuery } from "hooks";
import { useCallCenterRouteDetails } from "./utils/useCallCenterRouteDetails";
import { useLogisticsRouteDetails } from "./utils/useLogisticsRouteDetails";
import { FLAVOR } from "CONSTANTS";
import { Button as DesignSystemButton } from "components/miloDesignSystem/atoms/button";
import { MdiCleaningService } from "components/miloDesignSystem/atoms/icons/MdiCleaningService";

interface Props {
  currentModuleUrl: string;
  isCallCenterRoute: match<{ routeId: string }> | null;
  isLogisticsRoute: match<{ routeId: string }> | null;
  isLogisticsRouteLineItems: match<{ routeId: string }> | null;
  setAreFiltersOpened: React.Dispatch<React.SetStateAction<boolean>>;
  subModule: ModuleLink;
}

export const FiltersSection = ({
  currentModuleUrl,
  isCallCenterRoute,
  isLogisticsRouteLineItems,
  isLogisticsRoute,
  setAreFiltersOpened,
  subModule,
}: Props) => {
  const { setQuery } = useQuery();
  const match = useRouteMatch(`${currentModuleUrl}/${subModule.url}`);
  const history = useHistory();
  const {
    callCenterRoute,
    callCenterRouteIsLoading,
    callCenterRouteId,
  } = useCallCenterRouteDetails(isCallCenterRoute);
  const { logisticsRoute, logisticsRouteIsLoading, logisticsRouteId } = useLogisticsRouteDetails(
    isLogisticsRoute,
  );

  if (!match) return null;

  const getLinkName = (): string => {
    if (isCallCenterRoute) {
      if (callCenterRoute) {
        return callCenterRoute.signature;
      } else if (callCenterRouteIsLoading) {
        return `${subModule.label} ...`;
      }
    }

    if (isLogisticsRoute) {
      if (logisticsRoute) {
        return logisticsRoute.signature;
      } else if (logisticsRouteIsLoading) {
        return `${subModule.label} ...`;
      }
    }

    return subModule.label;
  };

  return (
    <>
      {FLAVOR !== "externalManufacturing" && (
        <div className={styles.filtersSectionTitle}>
          <Button
            kind="transparent-white"
            className={styles.goBackBtn}
            onClick={() => {
              if (callCenterRoute) {
                setAreFiltersOpened(true);
                return history.push(
                  `${currentModuleUrl}/routes/list/all?panelId=${callCenterRoute.id}`,
                );
              } else if (callCenterRouteIsLoading) {
                setAreFiltersOpened(true);
                return history.push(
                  `${currentModuleUrl}/routes/list/all?panelId=${callCenterRouteId}`,
                );
              } else if (logisticsRoute) {
                setAreFiltersOpened(true);
                return history.push(
                  `${currentModuleUrl}/routes/list/all?panelId=${logisticsRoute.id}`,
                );
              } else if (logisticsRouteIsLoading) {
                setAreFiltersOpened(true);
                return history.push(
                  `${currentModuleUrl}/routes/list/all?panelId=${logisticsRouteId}`,
                );
              } else if (isLogisticsRouteLineItems) {
                setAreFiltersOpened(true);
                return history.push(
                  `/routes/list/active?panelId=${isLogisticsRouteLineItems!.params.routeId}`,
                );
              }
              setAreFiltersOpened(false);
              history.push(`${currentModuleUrl}/dashboard`);
            }}
            size="square-s"
          >
            <img alt="Cofnij" src={arrowBackIcon} style={{ height: "16px", width: "16px" }} />
          </Button>
          <div className={styles.icon}>
            {typeof subModule.icon === "function" ? (
              <subModule.icon size="17" color="neutralWhite48" />
            ) : (
              <img
                alt=""
                src={subModule.icon!}
                style={{ height: "17px", width: "16px", marginBottom: "1px" }}
              />
            )}
          </div>
          <div className={styles.linkName}>{getLinkName()}</div>
        </div>
      )}
      <div className={styles.filtersBody}>
        <div className={styles.filtersBodyTitle}>
          <div className={styles.filtersBodyTitleLabel}>Filtry</div>
          <DesignSystemButton
            className="text-uppercase"
            onClick={() => setQuery({})}
            size="small"
            startIcon={MdiCleaningService}
            theme="dark"
            variant="deepPurple"
          >
            Wyczyść filtry
          </DesignSystemButton>
        </div>
      </div>
      {subModule.subSection}
    </>
  );
};

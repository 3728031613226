import { useTradingDocument } from "api/trading-documents/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { UUID } from "api/types";
import { PageHeader } from "components/common";
import { Loader } from "components/utils/loader";
import { useInfiniteLogs } from "hooks/useInfinityLogs";
import { InvoiceHistory } from "pages/tradingDocuments/shared/components/invoiceHistory/InvoiceHistory";
import { useLocation, useParams } from "react-router";

type LocationState = { advance: Pick<TradingDocument, "signature"> } | undefined;

export const AdvanceInvoicesHistory = () => {
  const params = useParams<{ advanceId: UUID }>();
  const location = useLocation<LocationState>();
  const locationAdvance = location.state?.advance;
  const { data: fetchedAdvance } = useTradingDocument(
    { id: params.advanceId },
    { enabled: !Boolean(locationAdvance) },
  );
  const advance = locationAdvance ?? fetchedAdvance;
  const { logs, fetchMore, inProgress, hasMore } = useInfiniteLogs(
    params.advanceId,
    "tradingDocument",
  );
  const subtitle = advance?.signature ?? "";

  return (
    <div className="position-relative">
      {inProgress && <Loader module="finances" />}
      <PageHeader
        searchBar={false}
        subtitle={subtitle}
        title="Historia faktury zaliczkowej"
        viewLabel="ADVANCE_HISTORY"
      />
      <InvoiceHistory fetchMore={fetchMore} hasMore={hasMore} inProgress={inProgress} logs={logs} />
    </div>
  );
};

export const lime = {
  lime12: "#D0DB591F",
  lime25: "#F9FBE9",
  lime32: "#D0DB5952",
  lime50: "#F1F3C8",
  lime75: "#DFE884D8",
  lime100: "#BFDC62",
  lime200: "#AEC75D",
  lime300: "#A2BB3F",
  lime400: "#94A842",
  lime500: "#7A8D30",
  lime600: "#556125",
};

import { AvailableFilters, FilterType } from "./types";
import { FilterSelect } from "./filterComponents/filterSelect/FilterSelect";
import { FilterDateRange } from "./filterComponents/filterDateRange/FilterDateRange";
import { FilterDate } from "./filterComponents/filterDate/FilterDate";
import { FilterText } from "./filterComponents/filterText/FilterText";
import { FilterSearch } from "./filterComponents/filterSearch/FilterSearch";
import { FilterSelectUser } from "./filterComponents/filterSelectUser/FilterSelectUser";
import { FilterDateRangeWithOptions } from "./filterComponents/filterDateRangeWithOptions/FilterDateRangeWithOptions";
import { FilterAsyncSearch } from "./filterComponents/filterAsyncSearch/FilterAsyncSearch";
import { ApiFetcher } from "hooks/createApiQuery";
import { Pagination } from "api/types";
import { ApiMiddlewareResult } from "apiConnectors/fetchConnector";
import { FilterNumber } from "./filterComponents/filterNumber/FilterNumber";

interface Props<FF, T> {
  filter: AvailableFilters<FF, T>;
}

export const TypeBasedFilter = <
  FF extends (
    arg: string,
    abortToken?: string,
  ) => ApiFetcher<Pagination<any>> | ApiMiddlewareResult<Pagination<any>>,
  T extends string
>({
  filter,
}: Props<FF, T>) => {
  switch (filter.type) {
    case FilterType.Search: {
      return <FilterSearch {...filter} />;
    }

    case FilterType.AsyncSearch: {
      return <FilterAsyncSearch {...filter} />;
    }

    case FilterType.Select: {
      return <FilterSelect {...filter} />;
    }

    case FilterType.SelectUser: {
      return <FilterSelectUser {...filter} />;
    }

    case FilterType.DateRange: {
      return <FilterDateRange {...filter} />;
    }

    case FilterType.DateRangeWithOptions: {
      return <FilterDateRangeWithOptions {...filter} />;
    }

    case FilterType.Date: {
      return <FilterDate {...filter} />;
    }

    case FilterType.Text: {
      return <FilterText {...filter} />;
    }

    case FilterType.Number: {
      return <FilterNumber {...filter} />;
    }
    case FilterType.Custom: {
      return (
        <div>
          <filter.render />
        </div>
      );
    }
    default:
      const exhaustiveCheck: never = filter;
      throw new Error(`Unhandled color case: ${exhaustiveCheck}`);
  }
};

import { useState, useMemo } from "react";
import cx from "classnames";
import styles from "./FiltersDrawer.module.css";
import { DrawerSection } from "components/utils/drawer/Drawer";
import { RadioLabels } from "components/utils";
import { useQuery } from "hooks";
import { ToolbarSearchMultipleAttributeFilter } from "../toolbar/types";

export const SearchMultipleAttributeFilter = ({
  filter,
  query,
}: {
  filter: ToolbarSearchMultipleAttributeFilter;
  query: Record<string, string>;
}) => {
  const [inputValue, setInputValue] = useState("");
  const { updateQuery } = useQuery();

  const filteredItems = useMemo(
    () =>
      filter.options.filter(element => {
        return element.name.toLowerCase().includes(inputValue.toLowerCase());
      }),
    [inputValue, filter],
  );

  const handleSelectMultiple = ({ value }: any) => {
    const currentIds = query[filter.name];
    if (currentIds) {
      const isNew = currentIds.split(",").includes(String(value));

      if (isNew) {
        const newList = currentIds
          .split(",")
          .filter(el => el !== value)
          .join(",");

        updateQuery({ [filter.name]: newList, page: 1 });
      } else {
        updateQuery({ [filter.name]: `${currentIds}${value},`, page: 1 });
      }
    } else {
      updateQuery({ [filter.name]: `${value},`, page: 1 });
    }
  };

  return (
    <DrawerSection title={filter.label}>
      {filter.search && (
        <input
          type="search"
          value={inputValue}
          className={cx(styles.searchInput, "mb-1")}
          onChange={e => setInputValue(e.target.value)}
          placeholder="Szukaj"
        />
      )}
      <div className={cx(styles.customSection, "mb-2 pb-2")}>
        {filteredItems.length
          ? filteredItems.map(el => {
              return (
                <div className="mt-2" key={el.name}>
                  <RadioLabels
                    label={el.name}
                    name={el.name}
                    items={el.values.map(el => ({ id: el.value, name: el.label }))}
                    onChange={handleSelectMultiple}
                    multiple={true}
                    values={query[filter.name] ? query[filter.name].split(",") : undefined}
                  />
                </div>
              );
            })
          : "Brak wyszukań"}
      </div>
    </DrawerSection>
  );
};

import * as React from "react";
import cx from "classnames";
import styles from "./Label.module.css";
export type Colors =
  | "primary"
  | "blue"
  | "orange"
  | "yellow-orange"
  | "red-orange"
  | "yellow"
  | "pink"
  | "grey"
  | "grey-transparent-10"
  | "light-grey"
  | "red"
  | "dark-red"
  | "purple"
  | "blueviolet"
  | "green"
  | "darkblue"
  | "darkcyan"
  | "midblue"
  | "cadetblue"
  | "burlywood"
  | "transparent"
  | "custom"
  | "bright-turquoise-35"
  | "transparent-blue"
  | "transparent-orange"
  | "white";
interface Props {
  color: Colors;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

/**
 * @deprecated
 */
export const Label = ({ color, children, className = "", style }: Props) => {
  const classNames = cx(styles[`label-color-${color}`], styles["label"], className);
  return (
    <div className={classNames} style={style}>
      {children}
    </div>
  );
};

import { Button } from "components/common";
import { RightPanelHeader } from "components/utils/drawer";
import hidePanelIcon from "assets/images/keyboardTab.svg";

interface Props {
  close: () => void;
  title: string;
}

export const PanelHeader = ({ close, title }: Props) => {
  return (
    <RightPanelHeader className="px-3 py-2">
      <div className="d-flex align-items-center gap-2">
        <div className="body-16">{title}</div>
      </div>
      <div className="d-flex align-items-center justify-content-end gap-1">
        <Button kind="transparent-black" onClick={close} size="square-s">
          <div className="btnBase btnBaseSmall">
            <img
              alt="Zamknij panel"
              src={hidePanelIcon}
              style={{ height: "16px", width: "16px" }}
            />
          </div>
        </Button>
      </div>
    </RightPanelHeader>
  );
};

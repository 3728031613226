import { RouteOrder } from "api/routes/models";
import { Assign, Diff, Intersection } from "utility-types";
import styles from "../PointsList.module.css";
import routeIcon from "assets/images/route.svg";
import likeIcon from "assets/images/greenThumbUp.svg";
import cx from "classnames";
import { areConfirmedHoursInCollectionRange } from "../utils/areConfirmedHoursInCollectionRange";
import ReactTooltip from "react-tooltip";

interface Props {
  order: Assign<
    RouteOrder,
    {
      order: number | null;
    },
    Diff<
      RouteOrder,
      {
        order: number | null;
      }
    > &
      Intersection<
        {
          order: number | null;
        },
        RouteOrder
      > &
      Diff<
        {
          order: number | null;
        },
        RouteOrder
      >
  >;
}

export const CollectionHours = ({ order }: Props) => {
  const isConfirmedWithinRange = areConfirmedHoursInCollectionRange(
    order.maximumCollectionHour,
    order.minimumCollectionHour,
    order.maximumCollectionHourAtConfirmation,
    order.minimumCollectionHourAtConfirmation,
  );

  if (isConfirmedWithinRange === false) {
    return (
      <>
        <div className={styles.collectionHours} data-for={String(order.id)} data-tip>
          <img alt="" src={likeIcon} />
          <div className={styles.negativeCollectionHours}>
            {order.minimumCollectionHourAtConfirmation}&nbsp;-&nbsp;
            {order.maximumCollectionHourAtConfirmation}&nbsp;
          </div>
          <img alt="" src={routeIcon} />
          <div className={styles.routeHoursInvalid}>{order.minimumCollectionHour}</div>
          <div className={styles.routeHoursInvalid}>-</div>
          <div className={styles.routeHoursInvalid}>{order.maximumCollectionHour}</div>
        </div>
        <ReactTooltip
          className={styles.tooltip}
          id={String(order.id)}
          type="dark"
          arrowColor="#252728"
          delayShow={1000}
          effect="solid"
          place="top"
        >
          <div className={styles.tooltipContent}>
            kierowca będzie <span className="fw-700">&nbsp;w innym&nbsp;</span> niż potwierdzony
            przedział
          </div>
        </ReactTooltip>
      </>
    );
  }

  if (isConfirmedWithinRange === true) {
    return (
      <>
        <div
          className={cx(styles.collectionHours, styles.positiveCollectionHours)}
          data-for={String(order.id)}
          data-tip
        >
          <img alt="" src={likeIcon} />
          <div>{order.minimumCollectionHourAtConfirmation}</div>
          <div>-</div>
          <div>{order.maximumCollectionHourAtConfirmation}</div>
        </div>
        <ReactTooltip
          className={styles.tooltip}
          id={String(order.id)}
          type="dark"
          arrowColor="#252728"
          delayShow={1000}
          effect="solid"
          place="top"
        >
          <div className={styles.tooltipContent}>
            godziny są <span className="fw-700">&nbsp;zgodne&nbsp;</span> z dostarczeniem
          </div>
        </ReactTooltip>
      </>
    );
  }

  return (
    <>
      <div className={styles.collectionHours} data-for={String(order.id)} data-tip>
        <div>{order.minimumCollectionHour}</div>
        <div>-</div>
        <div>{order.maximumCollectionHour}</div>
      </div>
      <ReactTooltip
        className={styles.tooltip}
        id={String(order.id)}
        type="dark"
        arrowColor="#252728"
        delayShow={1000}
        effect="solid"
        place="top"
      >
        <div className={styles.tooltipContent}>
          kerowca będzie <span className="fw-700">&nbsp;w wyznaczonym przedziale,&nbsp;</span> ale
          przedział nie został potwierdzony
        </div>
      </ReactTooltip>
    </>
  );
};

import { isToday } from "date-fns";
import { dateFns } from "utilities";

export const getDateAbbreviation = (date: string) => {
  const formattedDate = new Date(date);

  if (isToday(formattedDate)) return "dzisiaj";

  return dateFns.format(formattedDate, "EEE");
};

import { deleteTradingDocumentItem } from "api/trading-documents/calls";
import { useTradingDocument } from "api/trading-documents/hooks";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { UUID } from "api/types";
import { useMutation } from "hooks/useMutation";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { assertIsDefined } from "utilities/assertIsDefined";

export const useRemoveTradingDocumentItem = (tradingDocumentId: UUID) => {
  const { handleMutate } = useTradingDocument({ id: tradingDocumentId }, { enabled: false });

  return withDeleteConfirmation(
    useMutation(
      ({ id }: { id: UUID; itemId: UUID; name: string; tradingDocumentId: UUID }) => {
        return deleteTradingDocumentItem(id);
      },
      ({ queryClient, toastr }) => ({
        onMutate: args => {
          return handleMutate(draft => {
            const itemToUpdate = draft.items.find(item => item.id === args.itemId);
            assertIsDefined(itemToUpdate);
            itemToUpdate.tradingDocumentItems = itemToUpdate.tradingDocumentItems.filter(
              tradingDocumentItem => tradingDocumentItem.id !== args.id,
            );
          });
        },
        onSuccess: (_, variables) => {
          queryClient.invalidateQueries(
            tradingDocumentsKeys.tradingDocument.details(variables.tradingDocumentId),
          );
          toastr.open({
            type: "success",
            title: "Udało się!",
            text: `Usunięto pozycję "${variables.name}"`,
          });
        },
        onError: (error, _, rollback) => {
          // @ts-ignore
          rollback(error);
        },
      }),
    ),
  )();
};

import { yup } from "utilities";

export const validationSchema = yup.object({
  firstName: yup.string().required("Imię jest wymagane"),
  lastName: yup.string().required("Nazwisko jest wymagane"),
  employedPlace: yup.string().required("Miejsce zatrudnienia jest wymagane"),
  email: yup.string().email("Niepoprawny format adresu e-mail").required("Email jest wymagany"),
  kind: yup.string().required("Typ pracownika jest wymagany"),
  phone: yup
    .string()
    .required("Numer telefonu jest wymagany")
    .matches(/^[0-9]+$/, "Numer musi składać się z cyfr")
    .min(9, "Numer telefonu jest zbyt krótki")
    .max(12, "Numer telefonu jest zbyt długi"),
});

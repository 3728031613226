import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { getCarrier, getCarriers, patchCarrier } from "./calls";
import { createApiQuery } from "hooks/createApiQuery";
import { useMutation } from "hooks/useMutation";
import { parsePatchData } from "utilities/parsePatchData";
import { PartialOf } from "typeUtilities";
import { CreateCarrier } from "./models";
import { carriersKeys } from "./keys";
import { assertIsDefined } from "utilities/assertIsDefined";

export const useCarriers = createPaginatedApiQuery(getCarriers);
export const useCarrier = createApiQuery(getCarrier);

export const useCarrierPatchMutation = () => {
  return useMutation(
    ({ id, toUpdate }: { id: number | string; toUpdate: PartialOf<CreateCarrier> }) => {
      return patchCarrier(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(
          carriersKeys.carriers.details(String(id)),
          toUpdate,
        );
        const prevList = queryUtils.handlePaginatedListUpdate(
          carriersKeys.carriers.list(),
          id,
          toUpdate,
        );
        return { prevList, prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(
          carriersKeys.carriers.details(String(id)),
          onMutateReturn.prevPanel,
          error,
        );
        queryUtils.rollbackList(carriersKeys.carriers.list(), onMutateReturn.prevList, id);
      },
    }),
  );
};

import styles from "../../shared/Logistics.module.css";
import cx from "classnames";
import { ColorLabel } from "components/common/colorLabel";
import { RouteListItem } from "api/routes/models";
import { AsyncInput } from "components/utils";
import { useRoutePatchMutation } from "api/logistics/routes/hooks";

interface Props {
  route: RouteListItem;
}

export const RoutePriority = ({ route }: Props) => {
  const patchPriorityMutation = useRoutePatchMutation();
  const patchPriorityOrderMutation = useRoutePatchMutation();

  return (
    <div
      className={styles.optionList}
      onMouseDown={e => e.stopPropagation()}
      style={{ left: "0", right: "initial", position: "absolute" }}
    >
      <div className="py-2">
        <div className="d-flex align-items-center px-3 py-2 gap-2">
          <label className={cx("d-inline-flex", styles.tag, styles.tagFill)}>
            <input
              disabled={patchPriorityMutation.isLoading}
              type="checkbox"
              checked={route.priority === "HIGH"}
              className={styles.tagInput}
              onClick={() =>
                patchPriorityMutation.mutate({ id: route.id, toUpdate: { priority: "HIGH" } })
              }
              value="tag"
            />
            <span className={styles.tagSelected}>
              <ColorLabel color="#e26b77" height={12} width={12} />A
            </span>
          </label>
          <label className={cx("d-inline-flex", styles.tag, styles.tagFill)}>
            <input
              disabled={patchPriorityMutation.isLoading}
              type="checkbox"
              checked={route.priority === "MEDIUM"}
              className={styles.tagInput}
              onClick={() =>
                patchPriorityMutation.mutate({ id: route.id, toUpdate: { priority: "MEDIUM" } })
              }
              value="tag"
            />
            <span className={styles.tagSelected}>
              <ColorLabel color="#ee9e4b" height={12} width={12} />B
            </span>
          </label>
          <label className={cx("d-inline-flex", styles.tag, styles.tagFill)}>
            <input
              disabled={patchPriorityMutation.isLoading}
              type="checkbox"
              checked={route.priority === "LOW"}
              className={styles.tagInput}
              value="tag"
              onClick={() =>
                patchPriorityMutation.mutate({ id: route.id, toUpdate: { priority: "LOW" } })
              }
            />
            <span className={styles.tagSelected}>
              <ColorLabel color="#e4b700" height={12} width={12} />C
            </span>
          </label>
        </div>
        <div className="px-3 py-2">
          <AsyncInput
            inProgress={patchPriorityOrderMutation.isLoading}
            disabled={patchPriorityOrderMutation.isLoading}
            overwrites={{ input: { className: styles.priorityOrder } }}
            label="Stopień"
            onChange={value => {
              if (value) {
                patchPriorityOrderMutation.mutate({
                  id: route.id,
                  toUpdate: { priorityOrder: value },
                });
              }
            }}
            type="number"
            value={route.priorityOrder ?? ""}
          />
        </div>
      </div>
    </div>
  );
};

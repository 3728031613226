import styles from "./NoResults.module.css";
import peryscopeIcon from "assets/images/peryscope.svg";
import cx from "classnames";

interface Props {
  className?: string;
  header?: string;
  img?: string;
  on?: boolean;
  overwrites?: {
    background?: { className?: string };
    container?: { style?: React.CSSProperties };
    textWrapper?: { className?: string };
    header?: { className?: string };
    img?: { style?: React.CSSProperties };
    subHeader?: { className?: string; fullText?: string };
  };
  subHeader?: string;
}

export const NoResults = ({
  className,
  header = "Niczego tutaj nie ma",
  img,
  on = true,
  overwrites = {},
  subHeader = "element do listy",
}: Props) => {
  if (on) {
    return (
      <div className={cx(styles.background, overwrites.background?.className || "")}>
        <div
          className={cx(
            styles.container,
            "d-flex flex-column align-items-center justify-content-center w-100",
            className,
          )}
          style={overwrites.container?.style}
        >
          <div
            className={cx(
              styles.textWrapper,
              "d-flex flex-column align-items-center justify-content-center gap-2 ",
              overwrites.textWrapper?.className || "",
            )}
          >
            <h2 className={cx(styles.header, overwrites.header?.className || "")}>{header}</h2>
            <p className={cx(styles.subHeader, overwrites.subHeader?.className || "")}>
              {overwrites.subHeader?.fullText || (
                <span>
                  Dodaj {subHeader} lub zmień <br />
                  kryteria wyszukiwania
                </span>
              )}
            </p>
          </div>
          <img alt="Brak elementów" src={img ? img : peryscopeIcon} style={overwrites.img?.style} />
        </div>
      </div>
    );
  }
  return null;
};

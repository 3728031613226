import { memo } from "react";
import { getPagination, noop, pluralize } from "utilities";
import { Spinner, StateProxy } from "components/utils";
import { useQuery } from "hooks";
import prevIcon from "assets/images/keyboardArrowLeft.svg";
import nextIcon from "assets/images/keyboardArrowRight.svg";
import { Button } from "components/common/buttonLegacy";
import styles from "./ImportInvoicesPagination.module.css";

interface Props {
  pagination: ReturnType<typeof getPagination>;
  onChange?: (page: number) => void;
  mode?: "auto" | "manual";
  page?: number;
  isFetching?: boolean;
}

export const ImportInvoicesPagination = memo(
  ({ pagination, onChange = noop, mode = "auto", page, isFetching = false }: Props) => {
    const { query, setQuery } = useQuery();
    const { limit, count, pageSize } = pagination;
    const debounceRequest = Number(page || query.page || 1) !== pagination.page;

    const handlePage = (page: number) => {
      if (mode === "auto") {
        setQuery({ ...query, page });
      }
      onChange(page);
    };

    if (!pagination.count) {
      return null;
    }

    return (
      <div className={styles.pagination}>
        <StateProxy
          state={page || Number(query.page) || 1}
          debounce={debounceRequest ? 1000 : undefined}
          onChange={handlePage}
        >
          {({ state, setState }) => (
            <>
              <div className="d-flex align-items-center ml-2">
                <div className="position-absolute">
                  <Spinner
                    overrides={{ boxSpinner: { className: styles.boxSpinner } }}
                    on={isFetching}
                    color="blue"
                    size="small"
                    position="absolute"
                    style={{ height: "0", top: 0, left: "-30px" }}
                  />
                </div>
                <span className="body-12">
                  {!pageSize || !count ? (
                    ""
                  ) : (
                    <strong>{state * pageSize - pageSize + 1}&nbsp;&nbsp;z&nbsp;&nbsp;</strong>
                  )}
                </span>
                {count && (
                  <div className="body-12">
                    {count}&nbsp;
                    {pluralize.pl(count, {
                      singular: "dokument",
                      plural: "dokumenty",
                      other: "dokumentów",
                    })}
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center ml-2">
                <Button
                  kind="create-transparent"
                  onClick={() => (state < 2 ? null : setState(s => s - 1))}
                  disabled={state <= 1}
                >
                  <img src={prevIcon} alt="Poprzednie" style={{ height: "16px", width: "16px" }} />
                </Button>
                <Button
                  kind="create-transparent"
                  onClick={() => (!limit || state >= limit ? null : setState(s => s + 1))}
                  disabled={!limit || state >= limit}
                >
                  <img src={nextIcon} alt="Następne" style={{ height: "16px", width: "16px" }} />
                </Button>
              </div>
            </>
          )}
        </StateProxy>
      </div>
    );
  },
);

import { convertDateToPolishMonthAbbreviations } from "utilities/convertDateToPolishMonthAbbreviations";

interface Props {
  date: string | null;
  time: string | null;
}

export const RouteDates = ({ date, time }: Props) => {
  if (!date && !time) {
    return (
      <div className="d-flex align-items-center gap-2 fw-700">
        <span className="text-black-6">brak godziny daty</span>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center gap-2 fw-700">
      {time ? (
        <div>{time.length === 8 ? time.slice(0, -3) : time},</div>
      ) : (
        <div className="text-black-6">brak godziny,</div>
      )}
      {date ? (
        <div>{convertDateToPolishMonthAbbreviations(date)}</div>
      ) : (
        <div className="text-black-6">brak daty</div>
      )}
    </div>
  );
};

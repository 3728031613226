import { Pagination } from "../types";
import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { Manufacturer } from "./models";

const api = tokenRefreshMiddleware(apiFetch);

export const getManufacturers = (search: string = "") =>
  api<Pagination<any>>({
    method: "GET",
    url: "/manufacturers/items" + search,
  });

export const postSendOrdersToManufacturer = (data: number[]) =>
  api<any>({
    method: "POST",
    url: "/manufacturers/send-selected-orders-to-manufacturer",
    data: { ids: data },
  });

export const getManufacturer = (id: number | string) =>
  api<Manufacturer>({
    method: "GET",
    url: "/manufacturers/items/" + id,
  });

export const patchManufacturer = (id: number | string, data: Partial<Manufacturer>) =>
  api<Manufacturer>({
    method: "PATCH",
    url: "/manufacturers/items/" + id,
    data,
  });

export const deleteManufacturer = (id: number | string) =>
  api<void>({
    method: "DELETE",
    url: "/manufacturers/items/" + id,
  });

export const postManufacturer = (
  data: Omit<Manufacturer, "id" | "productionOrders" | "isConnected" | "integrationToken">,
) =>
  api<Manufacturer>({
    method: "POST",
    url: "/manufacturers/items",
    data,
  });

export const manufacturerEnableConnection = (id: number | string) =>
  api<{ integrationToken: string; message: string; isConnected: boolean }>({
    method: "POST",
    url: `/manufacturers/items/${id}/enable-connection`,
  });

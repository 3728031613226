import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx } from "utilities";
import styles from "./Header.module.css";
import { Search } from "components/miloDesignSystem/molecules/search";
import { wmsLayoutApi } from "api/wms/layout/api";
import { useQuery, useSelector } from "hooks";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "react-query";

interface Props {
  refetchFillingStatus: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  selectedHall: number | null;
  setSelectedHall: React.Dispatch<React.SetStateAction<number | null>>;
  showFill: boolean;
  setShowFill: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Header = ({
  refetchFillingStatus,
  selectedHall,
  setSelectedHall,
  showFill,
  setShowFill,
}: Props) => {
  const halls = useSelector(store => store.partials.halls);
  const { setQuery } = useQuery();
  return (
    <div
      className={cx(
        "d-flex align-items-center justify-content-between borderBottomB px-3 pt-2",
        styles.header,
      )}
    >
      <div className="d-flex align-items-center">
        <Typography fontSize="20" fontWeight="700" color="neutralWhite100" className="pr-3">
          Magazyn
        </Typography>
        <Search
          textFieldProps={{ containerClassName: styles.input }}
          isNullable
          fetcherFn={wmsLayoutApi.getSearchCollections}
          onChange={searchCollection => {
            setQuery({ searchCollectionId: searchCollection?.id || "" });
            setShowFill(false);
          }}
          theme="dark"
        />
      </div>
      <div className="d-flex align-items-center gap-4">
        <Select
          items={halls.map(hall => ({
            text: hall.name,
            type: MenuItemType.TEXT,
            value: hall.id,
          }))}
          onChange={hall => {
            if (hall) {
              setSelectedHall(Number(hall));
            }
          }}
          selected={selectedHall || null}
          theme="dark"
        />
        <Checkbox
          checked={showFill}
          label="Pokaż zapełnienie magazynu"
          onChange={() => {
            if (!showFill) {
              refetchFillingStatus();
            }
            setShowFill(prev => !prev);
          }}
          override={{
            checkbox: styles.fillCheckbox,
            color: "neutralWhite100",
            fontSize: "16",
            fontWeight: "600",
            textClassName: styles.fillText,
          }}
        />
      </div>
    </div>
  );
};

import { useTradingDocument } from "api/trading-documents/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { UUID } from "api/types";
import { PageHeader } from "components/common";
import { Loader } from "components/utils/loader";
import { useInfiniteLogs } from "hooks/useInfinityLogs";
import { InvoiceHistory } from "pages/tradingDocuments/shared/components/invoiceHistory/InvoiceHistory";
import { useLocation, useParams } from "react-router";

type LocationState = { proforma: Pick<TradingDocument, "signature"> } | undefined;

export const ProformasHistory = () => {
  const params = useParams<{ proformaId: UUID }>();
  const location = useLocation<LocationState>();
  const locationProforma = location.state?.proforma;
  const { data: fetchedProforma } = useTradingDocument(
    { id: params.proformaId },
    { enabled: !Boolean(locationProforma) },
  );
  const proforma = locationProforma ?? fetchedProforma;
  const { logs, fetchMore, inProgress, hasMore } = useInfiniteLogs(
    params.proformaId,
    "tradingDocument",
  );
  const subtitle = proforma?.signature ?? "";

  return (
    <div className="position-relative">
      {inProgress && <Loader module="finances" />}
      <PageHeader
        searchBar={false}
        subtitle={subtitle}
        title="Historia proformy"
        viewLabel="PROFORMA_HISTORY"
      />
      <InvoiceHistory fetchMore={fetchMore} hasMore={hasMore} inProgress={inProgress} logs={logs} />
    </div>
  );
};

import { Shipment } from "api/shipping/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { MdiBox } from "components/miloDesignSystem/atoms/icons/MdiBox";
import { MdiCancel } from "components/miloDesignSystem/atoms/icons/MdiCancel";
import { MdiEmergencyHome } from "components/miloDesignSystem/atoms/icons/MdiEmergencyHome";
import { MdiPackageSend } from "components/miloDesignSystem/atoms/icons/MdiPackageSend";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../RightPanel.module.css";
import { logbookActions } from "hooks/apiPrimitives";
import { useFilters } from "hooks/useFilters";
import { LogKind } from "api/logbook/models";
import { dateFns } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiKeyboardArrowDown } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowDown";
import { useState } from "react";
import { MdiKeyboardArrowUp } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowUp";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";

interface Props {
  shipment: Shipment;
}

export const HistorySection = ({ shipment }: Props) => {
  const [showPreviousEvents, setShowPreviousEvents] = useState(false);
  const { searchParams } = useFilters({
    pageSize: "999",
    source: "SHIPMENT",
    sourceId: shipment.id,
  });
  const { data, isLoading } = logbookActions.useLogsQuery(searchParams);

  return (
    <div className="px-3 pb-2 flex-1">
      <Typography fontSize="14" fontWeight="600" color="neutralBlack48" className="mb-2">
        Historia przesyłki
      </Typography>
      {isLoading && <Spinner size={22} />}
      {(showPreviousEvents ? data : data.slice(0, 3)).map(log => (
        <div className="d-flex gap-3 my-2 align-items-start">
          <div className="position-relative d-inline">
            <Avatar size="md" theme="light" user={log.user} />
            <div className={styles.historyStatus}>{kindToIconDict[log.kind]}</div>
          </div>
          <div>
            <Typography fontSize="16" fontWeight="700" className="my-1">
              {log.user?.firstName} {log.user?.lastName}
            </Typography>
            <Typography fontSize="14" fontWeight="600">
              {log.description}
            </Typography>
            <Typography fontSize="12" fontWeight="700" color="neutralBlack48">
              {dateFns.formatRelative(new Date(log.created))}
            </Typography>
          </div>
        </div>
      ))}
      {data.length > 3 && (
        <Button
          size="small"
          variant="gray"
          endIcon={showPreviousEvents ? MdiKeyboardArrowUp : MdiKeyboardArrowDown}
          className="text-uppercase mt-1 mb-2"
          onClick={() => setShowPreviousEvents(prev => !prev)}
        >
          {showPreviousEvents ? "Ukryj" : "Pokaż"} wcześniejsze zdarzenia
        </Button>
      )}
    </div>
  );
};

const kindToIconDict: Record<LogKind, JSX.Element> = {
  [LogKind.ADDED]: <MdiBox size="14" color="info400" />,
  [LogKind.AUTO_UPDATED]: <MdiBox size="14" color="info400" />,
  [LogKind.CREATED]: <MdiBox size="14" color="info400" />,
  [LogKind.DELETED]: <MdiCancel size="14" color="warning400" />,
  [LogKind.DELIVERED]: <MdiBox size="14" color="info400" />,
  [LogKind.DOWNLOADED]: <MdiBox size="14" color="info400" />,
  [LogKind.FAILED]: <MdiEmergencyHome size="14" color="danger400" />,
  [LogKind.REMOVED]: <MdiCancel size="14" color="warning400" />,
  [LogKind.UPDATED]: <MdiPackageSend size="14" color="success400" />,
};

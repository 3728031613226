import { Priority, PriorityChoice } from "api/external-manufacturing/models";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  priority: Priority;
}

export const priorityDict: Record<
  PriorityChoice,
  { label: "A" | "B" | "C"; color: ColorPalette }
> = {
  [PriorityChoice.HIGH]: { label: "A", color: "red300" },
  [PriorityChoice.MEDIUM]: { label: "B", color: "orange300" },
  [PriorityChoice.LOW]: { label: "C", color: "blue300" },
};

export const PriorityLabel = ({ priority }: Props) => {
  return (
    <Typography color={priorityDict[priority].color} fontSize="14" fontWeight="700">
      {priorityDict[priority].label}
    </Typography>
  );
};

import { AssignedWhEntry } from "api/trading-documents/models";
import { Button } from "components/common";
import downIcon from "assets/images/keyboardArrowDown.svg";
import arrowBackIcon from "assets/images/arrowBack.svg";
import upIcon from "assets/images/keyboardArrowUp.svg";
import styles from "../../../ConnectInvoice.module.css";
import localStyles from "../ReceptionsSection.module.css";
import cx from "classnames";
import { useState } from "react";
import { RightPanelTableRow } from "components/utils/drawer";
import { whEntrySectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { getAnyErrorKey, handleDateField } from "utilities";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { useMutation } from "hooks/useMutation";
import { Pagination, UUID } from "api/types";
import { deleteWhEntryAggregate } from "api/trading-documents/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { useParams } from "react-router";
import { UrlParams } from "pages/tradingDocuments/connectInvoice/ConnectInvoice";

interface Props {
  search: string;
  whEntry: AssignedWhEntry;
}

export const WhEntry = ({ search, whEntry }: Props) => {
  const [showPackages, setShowPackages] = useState(true);
  const { tradingDocumentId } = useParams<UrlParams>();

  const removeWhEntryMutation = withDeleteConfirmation(
    useMutation(
      (data: { whEntryId: UUID }) => deleteWhEntryAggregate(data.whEntryId),
      ({ queryClient, toastr }) => ({
        onMutate: args => {
          const prevList: Pagination<AssignedWhEntry> | undefined = queryClient.getQueryData(
            tradingDocumentsKeys.invoiceAdmission.assignedWhEntries(search),
          );

          queryClient.setQueryData<Pagination<AssignedWhEntry>>(
            tradingDocumentsKeys.invoiceAdmission.assignedWhEntries(search),
            currentList => {
              assertIsDefined(currentList);
              return {
                ...currentList,
                results: currentList.results.filter(result => result.id !== args.whEntryId),
              };
            },
          );

          return prevList;
        },
        onSuccess: () => {
          toastr.open({
            type: "success",
            title: "Udało się!",
            text: "Usunięto połączenie",
          });
          queryClient.invalidateQueries(tradingDocumentsKeys.invoiceAdmission.assignedWhEntries());

          queryClient.invalidateQueries(
            tradingDocumentsKeys.invoiceAdmission.details(tradingDocumentId),
          );

          queryClient.invalidateQueries(tradingDocumentsKeys.invoiceAdmission.linkLines());
        },
        onError: (error, _, context) => {
          const prevList = context;
          queryClient.setQueryData<Pagination<AssignedWhEntry>>(
            tradingDocumentsKeys.invoiceAdmission.assignedWhEntries(search),
            currentList => {
              assertIsDefined(currentList);
              return {
                ...currentList,
                results: prevList!.results,
              };
            },
          );
          toastr.open({
            type: "warning",
            title: "Wymagane działanie",
            text: getAnyErrorKey(error),
          });
        },
      }),
    ),
    "Czy na pewno chcesz usunąć ten dokument? Spowoduje to odpięcie wszystkich paczek z tego dokumentu przypiętych do tej faktury",
  )();

  return (
    <div className="pb-3">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <div className="body-18">{whEntry.signature}</div>
          <IconButton
            icon={MdiDelete}
            onClick={() =>
              removeWhEntryMutation.mutate({
                whEntryId: whEntry.id,
              })
            }
            variant="gray"
          />
          <span className="separator" />
          <div className={styles.date}>{handleDateField(whEntry.whEntryClosedAt)}</div>
        </div>
        <div className="d-flex align-items-center gap-2">
          <Button disabled={true} kind="create">
            <div className="btnBase btnBaseSmall">
              <img alt="wyczyść" src={arrowBackIcon} style={{ height: "16px", width: "16px" }} />
              Przenieś do faktury
            </div>
          </Button>
          <Button
            kind="transparent-black"
            onClick={() => setShowPackages(prev => !prev)}
            size="square-s"
          >
            <div className="btnBase btnBaseSmall">
              {showPackages ? (
                <img alt="Zwiń" src={upIcon} style={{ height: "16px", width: "16px" }} />
              ) : (
                <img alt="Pokaż" src={downIcon} style={{ height: "16px", width: "16px" }} />
              )}
            </div>
          </Button>
        </div>
      </div>
      {showPackages && (
        <>
          <RightPanelTableRow
            className={localStyles.tableHeaderPZ}
            grid={whEntrySectionListConfig.grid}
            hover={false}
          >
            <div>#</div>
            <div>paczka</div>
            <div className="d-flex align-items-center justify-content-end">wykorzystane</div>
            <div className="d-flex align-items-center justify-content-end">pozostało</div>
            <div className="d-flex align-items-center justify-content-end">w sumie</div>
          </RightPanelTableRow>
          <Droppable droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {whEntry.items.map((_package, index) => (
                  <Draggable
                    draggableId={`${_package.id};${whEntry.id}`}
                    key={_package.id}
                    index={index}
                    isDragDisabled={_package.usedQuantity === _package.quantity}
                  >
                    {(provided, snapshot) => (
                      <div
                        key={_package.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <RightPanelTableRow
                          className={cx(localStyles.tableRowPZ, {
                            [localStyles.stripesBgGrey]:
                              _package.usedQuantity === _package.quantity,
                            [localStyles.inaccessible]: _package.usedQuantity === _package.quantity,
                            [localStyles.packageIsDragged]: snapshot.isDragging,
                          })}
                          grid={whEntrySectionListConfig.grid}
                          style={{ width: snapshot.isDragging ? "80%" : "100%" }}
                        >
                          <div>{index + 1}.</div>
                          <div>
                            <div className={styles.productName}>{_package.name}</div>
                          </div>
                          <div className="d-flex align-items-center justify-content-end">
                            {_package.usedQuantity}
                          </div>
                          <div className="d-flex align-items-center justify-content-end">
                            {_package.leftQuantity}
                          </div>
                          <div className="d-flex align-items-center justify-content-end">
                            {_package.quantity}&nbsp;szt.
                          </div>
                        </RightPanelTableRow>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </>
      )}
    </div>
  );
};

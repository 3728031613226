import { UserWithInitials } from "api/users/models";

export interface Log {
  id: number;
  order: number;
  category: {
    code: string;
    icon: string | null;
    id: number;
    name: string;
  } | null;
  user: UserWithInitials | null;
  action:
    | "created"
    | "updated"
    | "failed"
    | "auto_updated"
    | "assigned"
    | "address-change"
    | "unassigned"
    | "settled"
    | "status-changed"
    | "deleted"
    | "downloaded"
    | "xml_downloaded"
    | "delivered";
  createdWith: "MANUALLY" | "AUTOMATICALLY";
  created: string;
  kind: LogKind;
  description: string;
}

export enum LogKind {
  ADDED = 1,
  REMOVED = 2,
  CREATED = 3,
  DELETED = 4,
  UPDATED = 5,
  DOWNLOADED = 6,
  DELIVERED = 7,
  AUTO_UPDATED = 8,
  FAILED = 9,
}

import { RouteEmails } from "api/routes/models";
import arrowImg from "assets/images/526.svg";
import errorIcon from "assets/images/877.svg";
import cx from "classnames";
import styles from "../PointsSection.module.css";
import { OrderDetails } from "./ModalContent";
import { clickOutsideIgnoreClass } from "./RightPanel";

interface Props {
  openRightPanel: (orderDetails: OrderDetails) => void;
  order: RouteEmails["orders"][number];
}

export const OrderEmailConversation = ({ openRightPanel, order }: Props) => {
  const lastConfirmationMessageToClient = order.email;

  if (
    lastConfirmationMessageToClient?.failureReason &&
    lastConfirmationMessageToClient.status === "DECLINED"
  ) {
    return (
      <div className="d-flex flex-column">
        <div className={styles.error}>
          <img alt="" src={errorIcon} />
          {lastConfirmationMessageToClient?.failureReason || "Wystąpił błąd w wysyłaniu wiadomości"}
        </div>
        <div
          className={cx(clickOutsideIgnoreClass, "mt-2", styles.conversationBtn)}
          onClick={() =>
            openRightPanel({
              client: order.client,
              orderId: String(order.id),
              signature: order.signature,
            })
          }
        >
          Pokaż wszystkie <img alt="" src={arrowImg} />
        </div>
      </div>
    );
  }

  if (
    lastConfirmationMessageToClient?.failureReason.length === 0 &&
    lastConfirmationMessageToClient.status !== "DECLINED"
  ) {
    return (
      <div className={styles.message}>
        <span>{lastConfirmationMessageToClient.body}</span>
        <div
          className={cx(clickOutsideIgnoreClass, "mt-2", styles.conversationBtn)}
          onClick={() =>
            openRightPanel({
              client: order.client,
              orderId: String(order.id),
              signature: order.signature,
            })
          }
        >
          Pokaż wszystkie <img alt="" src={arrowImg} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.noMessage}>Nie wysłano wiadomości </div>
      <div
        className={cx(clickOutsideIgnoreClass, "mt-2", styles.conversationBtn)}
        onClick={() =>
          openRightPanel({
            client: order.client,
            orderId: String(order.id),
            signature: order.signature,
          })
        }
      >
        Pokaż wszystkie <img alt="" src={arrowImg} />
      </div>
    </div>
  );
};

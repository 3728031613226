import { TableFilters } from "components/common/genericTable/useGenericTableFilters";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { getDateColor } from "pages/wms/overview/utils/getDateColor";
import { getDateAbbreviation } from "pages/wms/warehousemanList/rightPanel/utils/getDateAbbreviation";
import { getMonthAbbreviation } from "pages/wms/warehousemanList/rightPanel/utils/getMonthAbbreviation";

interface Props {
  date: string;
  rowBy: TableFilters["rowBy"];
}

export const DatepointerDetails = ({ date, rowBy }: Props) => {
  if (rowBy === "DAY") {
    return (
      <Typography color={getDateColor(date, "DETAILS")} fontSize="12" fontWeight="600">
        {getDateAbbreviation(date)}
      </Typography>
    );
  }
  if (rowBy === "WEEK") {
    return (
      <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
        {getMonthAbbreviation(date)}
      </Typography>
    );
  }
  return (
    <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
      {date.split("-")[0]}
    </Typography>
  );
};

import { useDriverLocations } from "api/routes/hooks";
import { CommonError, Modal, Spinner } from "components/utils";
import plannedDelivery from "assets/images/46b.svg";
import distanceBetween from "assets/images/40g.svg";
import plannedDeliveryLight from "assets/images/46w.svg";
import distanceBetweenLight from "assets/images/distance_between_grey.svg";
import stopoverIcon from "assets/images/stopover_moon.svg";
import styles from "./DriverLocationModal.module.css";
import { OrderPosition } from "api/routes/models";
import { dateFns } from "utilities";
import React from "react";

interface Props {
  modal: {
    isOpen: boolean;
    close: () => void;
  };
  routeId: number;
}
interface StopoverTime {
  duration: number;
}

export const DriverLocationModal = ({ modal: { isOpen, close }, routeId }: Props) => {
  const { data: driverPositions, isLoading: inProgress, error } = useDriverLocations(routeId);

  const indexMap = driverPositions?.ordersPositions
    .filter(el => el.type === "order")
    .reduce((acc: Record<string, number>, el, index) => {
      acc[el.id] = index + 1;
      return acc;
    }, {});

  function StopoverTime(duration: StopoverTime) {
    const hours = Math.floor(duration.duration / 3600);
    const minutes = Math.floor((duration.duration - hours * 3600) / 60);

    return (
      <div>
        <span>
          {hours}h {minutes}m
        </span>
      </div>
    );
  }

  function OrderPositions(orderPosition: OrderPosition) {
    const order = driverPositions?.orders.find(element => {
      return String(element.id) === orderPosition.id;
    });

    return (
      <>
        <div
          key={orderPosition.id}
          className="d-flex align-items-start justify-content-start position-relative"
        >
          {orderPosition.type === "order" && order?.isVisited && order?.isDelivered ? (
            <div className={`${styles.blueDot} ${styles.dot}`} />
          ) : null}
          {orderPosition.type === "order" &&
          !order?.isVisited &&
          !order?.isDelivered &&
          order?.id !== driverPositions?.lastAndCurrentDeliveryPoint.currentDelivery?.id ? (
            <div className={`${styles.greyDot} ${styles.dot}`} />
          ) : null}
          {orderPosition.type === "order" &&
          (!order?.isVisited || !order?.isDelivered) &&
          order?.id === driverPositions?.lastAndCurrentDeliveryPoint.currentDelivery?.id ? (
            <div className={`${styles.purpleDot} ${styles.dot}`} />
          ) : null}
          {!order && orderPosition.type === "stopover" ? (
            <div className={`${styles.endRouteDot} ${styles.dot}`} />
          ) : null}
          <div className={styles.order}>
            {orderPosition.type === "stopover" && !order ? (
              <div className={styles.stopoverBox}>
                <div className="row">
                  <div className="col-1">
                    <img src={stopoverIcon} alt="" className="mr-1" />
                  </div>
                  <div className="col-11 pl-5">
                    <div className="row ">
                      <span className={styles.stopoverLabel}>Przerwa na postój dobowy</span>
                    </div>
                    <div className="row">
                      <div className={styles.stopoverPlannedTime}>
                        {orderPosition.duration ? (
                          <>
                            <img src={plannedDeliveryLight} alt="" />
                            <StopoverTime duration={orderPosition.duration} />
                          </>
                        ) : (
                          <span>brak przedziału czasowego postoju</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {orderPosition.type === "order" &&
            order?.id === driverPositions?.lastAndCurrentDeliveryPoint.currentDelivery?.id ? (
              <>
                <span className={styles.currentPositionTitle}>kolejny adres</span>
                <div className={`container ${styles.currentPositionBox}`}>
                  <div className="row">
                    <div className="col-1">
                      <div className={`d-flex ml-1 ${styles.positionalNumber}`}>
                        {indexMap ? <span>{indexMap[Number(orderPosition.id)]}</span> : null}
                      </div>
                    </div>
                    <div className="col-11">
                      <div className="row">
                        <span className={styles.currentPositionAddress}>
                          {order?.delivery.city} {order?.delivery.postCode},{" "}
                          {order?.delivery.street}
                        </span>
                      </div>
                      <div className="row">
                        <span className={styles.currentPositionOrderDetails}>
                          {order?.signature} <span className={styles.delimeter} />{" "}
                          <span className={styles.currentPositionNames}>
                            {order?.client.firstName} {order?.client.lastName}
                          </span>{" "}
                          <span className={styles.delimeter}></span>{" "}
                          <span>{order?.delivery.phone}</span>
                        </span>
                      </div>
                      <div className="row">
                        <div className={styles.plannedCollectionHours}>
                          <img src={distanceBetweenLight} alt="" className="mr-1" />
                          {Number(order?.delivery.distance) > 0 ? (
                            <span>{(Number(order?.delivery.distance) / 1000).toFixed(0)}km</span>
                          ) : (
                            <span>...</span>
                          )}
                        </div>
                        <div className={styles.plannedCollectionHours}>
                          {order?.delivery.minimumCollectionHour &&
                          order?.delivery.maximumCollectionHour ? (
                            <>
                              <img src={plannedDeliveryLight} alt="" className="mr-1" />
                              <span>
                                {order.delivery.minimumCollectionHour} -{" "}
                                {order.delivery.maximumCollectionHour}
                              </span>
                              <span className={styles.currentStopoverTime}>
                                postój {order.delivery.stopoverTime}m
                              </span>
                            </>
                          ) : (
                            <span>brak przedziału czasowego dostawy</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {orderPosition.type === "order" &&
            order?.id !== driverPositions?.lastAndCurrentDeliveryPoint.currentDelivery?.id ? (
              <div
                className={`container ${styles.driverPositionBox}`}
                style={{
                  backgroundColor: !order?.isVisited && !order?.isDelivered ? "#EEF1F1" : "#FFF",
                }}
              >
                <div className="row">
                  <div className="col-1">
                    <div className={`d-flex ml-1 ${styles.positionalNumber}`}>
                      {indexMap ? <span>{indexMap[Number(orderPosition.id)]}</span> : null}
                    </div>
                  </div>
                  <div className="col-11">
                    <div className="row">
                      <span>
                        {order?.delivery.city} {order?.delivery.postCode}, {order?.delivery.street}
                      </span>
                    </div>
                    <div className="row" style={{ marginTop: "2px" }}>
                      <span>
                        {order?.signature} <span className={styles.delimeter} />{" "}
                        {order?.client.firstName} {order?.client.lastName}{" "}
                        <span className={styles.delimeter} />{" "}
                        <span style={{ color: "#01C9D5" }}>{order?.delivery.phone}</span>
                      </span>
                    </div>
                    {order?.isVisited && order?.isDelivered ? (
                      <div className="row d-flex align-items-center" style={{ marginTop: "4px" }}>
                        <div className={styles.deliveryPointStatus}>
                          <span>Odwiedzono</span>
                        </div>
                        <div className={styles.deliveryPointStatus}>
                          <span>Doręczono</span>
                        </div>
                        {order?.deliveredDate && (
                          <span className="ml-1 fs-12 text-color-grey">
                            dostarczono:{" "}
                            {dateFns.format(new Date(order.deliveredDate), "dd.MM.yyyy HH:mm")}
                          </span>
                        )}
                      </div>
                    ) : (
                      <div className="row" style={{ marginTop: "4px" }}>
                        <div className={styles.plannedCollectionHours}>
                          <img src={distanceBetween} alt="" className="mr-1" />
                          {Number(order?.delivery.distance) > 0 ? (
                            <span>{(Number(order?.delivery.distance) / 1000).toFixed(0)}km</span>
                          ) : (
                            <span>...</span>
                          )}
                        </div>
                        <div className={styles.plannedCollectionHours}>
                          {order?.delivery.minimumCollectionHour &&
                          order?.delivery.maximumCollectionHour ? (
                            <>
                              <img src={plannedDelivery} alt="" className="mr-1" />
                              <span>
                                {order.delivery.minimumCollectionHour} -{" "}
                                {order.delivery.maximumCollectionHour}
                              </span>
                              <span className={styles.plannedStopoverTime}>
                                + {order.delivery.stopoverTime}m
                              </span>
                            </>
                          ) : (
                            <span>brak przedziału czasowego dostawy</span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      overrides={{ container: { className: styles.modal } }}
      size={{ all: { width: "50vw" } }}
    >
      <div>
        <h1 className="ml-0">Gdzie jest kierowca?</h1>
        <div>
          {error && <CommonError status={error.status} />}
          <Spinner
            on={inProgress}
            color="blue"
            size="big"
            text="trwa wczytywanie"
            className={styles.spinner}
          />
          <div className={styles.container}>
            {!inProgress ? (
              <div className={styles.group}>
                <div>
                  {driverPositions?.ordersPositions.map(orderPosition => {
                    return (
                      <React.Fragment key={orderPosition.id}>
                        {OrderPositions(orderPosition)}
                      </React.Fragment>
                    );
                  })}
                  <div className="d-flex">
                    <div className={`${styles.endRouteDot} ${styles.dot}`} />
                    <div className={styles.routeEndPoint}>
                      <span className={styles.routeEndPointLabel}>koniec trasy</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  );
};

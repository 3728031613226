import { BaseIcon } from "../icons/types";
import { Typography } from "../typography";
import styles from "./Chip.module.css";
import darkChipStyles from "./DarkChip.module.css";
import lightChipStyles from "./LightChip.module.css";
import { cx, noop } from "utilities";

const DEFAULT_ICON_SIZE = "14";

type ChipTheme = "light" | "dark";

type ChipVariant = "primary" | "secondary";

interface Props {
  disabled?: boolean;
  icon?: React.FunctionComponent<BaseIcon>;
  label: string;
  onClick: (status: boolean) => void;
  selected: boolean | string | null;
  theme?: ChipTheme;
  variant: ChipVariant;
}

export const Chip = ({
  disabled = false,
  icon: Icon,
  label,
  onClick = noop,
  selected,
  theme = "light",
  variant,
}: Props) => {
  const getVariantStyling = (): { className: string } => {
    if (disabled) return { className: "disabled" };
    if (selected)
      return {
        className: `${variant}-selected`,
      };
    return { className: `${variant}` };
  };

  return (
    <div
      className={cx(styles.chip, {
        [lightChipStyles[getVariantStyling().className]]: theme === "light",
        [darkChipStyles[getVariantStyling().className]]: theme === "dark",
      })}
      onClick={() => {
        if (!disabled) {
          onClick(!selected);
        }
      }}
    >
      {Boolean(Icon) && Icon !== undefined && <Icon size={DEFAULT_ICON_SIZE} />}
      <Typography color="inherit" fontSize="10" fontWeight="700">
        {label}
      </Typography>
    </div>
  );
};

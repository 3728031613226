import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../../RouteLineItems.module.css";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { EmailsSchedule } from "api/external-manufacturing/models";
import { useEmailsScheduleCardColumns } from "./useEmailsScheduleCardColumns";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { cx } from "utilities";

interface Props {
  close: () => void;
  isEmailScheduleOpened: boolean;
}

export const EmailsScheduleCard = ({ close, isEmailScheduleOpened }: Props) => {
  const { data: emailsSchedule, isLoading } = externalManufacturingActions.useEmailsSchedule("");
  const columns = useEmailsScheduleCardColumns();

  if (!isEmailScheduleOpened || isLoading || !emailsSchedule || !Boolean(emailsSchedule.length))
    return null;

  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>
        <Typography color="neutralWhite100" fontSize="18" fontWeight="700">
          Wysyłka zapytań o przygotowanie zleceń
        </Typography>
        <IconButton
          icon={<MdiClose color="neutralWhite100" size="16" />}
          onClick={close}
          variant="transparent"
        />
      </div>
      <div className={cx(styles.cardBody, styles.noWrap)}>
        <Table<EmailsSchedule>
          rows={emailsSchedule}
          columns={columns}
          isLoading={false}
          error={null}
          overrides={() => {
            return {
              header: {
                className: styles.darkHeader,
                color: "neutralWhite64",
              },
              row: () => {
                return {
                  className: styles.darkRow,
                };
              },
            };
          }}
          uiSchema={comfortableListUiSchema}
        />
      </div>
    </div>
  );
};

import { Loading } from "api/wms/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { isToday, isTomorrow, isYesterday } from "date-fns";
import { dateFns } from "utilities";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { calculateTimeDifference } from "pages/wms/unloadingsList/utils/calculateTimeDifference";

interface Props {
  fontSize: TypographyProps["fontSize"];
  fontWeight: TypographyProps["fontWeight"];
  loading: Loading;
}

export const LoadingStart = ({ fontSize, fontWeight, loading }: Props) => {
  if (loading.startedAt) {
    if (isToday(new Date(loading.startedAt))) {
      return (
        <Typography fontSize={fontSize} fontWeight={fontWeight}>
          {dateFns.format(new Date(loading.startedAt), "H:mm")}
        </Typography>
      );
    }
    if (isYesterday(new Date(loading.startedAt))) {
      return (
        <Typography fontSize={fontSize} fontWeight={fontWeight}>
          wczoraj, {dateFns.format(new Date(loading.startedAt), "H:mm")}
        </Typography>
      );
    }
    return (
      <Typography fontSize={fontSize} fontWeight={fontWeight}>
        {dateFns.format(new Date(loading.startedAt), "d.MM.yyyy")}
      </Typography>
    );
  }

  if (!loading.estimatedTimeOfDeparture)
    return (
      <Typography fontSize={fontSize} fontWeight={fontWeight}>
        ---
      </Typography>
    );

  if (isTomorrow(new Date(loading.estimatedTimeOfDeparture)))
    return <Tag label="jutro" variant="outline" />;

  return (
    <Tag
      label={calculateTimeDifference(loading.estimatedTimeOfDeparture).label}
      variant={calculateTimeDifference(loading.estimatedTimeOfDeparture).variant}
    />
  );
};

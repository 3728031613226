import {
  getProductionOrder,
  getProductionOrders,
  getUpholsteriesEmployee,
  getUpholsteriesEmployeeAllIndexes,
  getUpholsteriesEmployeeDetails,
  getUpholsteriesEmployees,
  getUpholsteriesEmployeesQuery,
} from "api/upholsteries/calls";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createPrimitivePaginatedHook, createPrimitiveHook } from "hooks/createPrimitiveHook";

export const useUpholsteriesEmployees = createPrimitivePaginatedHook(getUpholsteriesEmployees);
export const useUpholsteriesEmployeesQuery = createPaginatedApiQuery(getUpholsteriesEmployeesQuery);

export const useUpholsteriesEmployee = createPrimitiveHook(getUpholsteriesEmployee);
export const useUpholsteriesEmployeeDetails = createPrimitiveHook(getUpholsteriesEmployeeDetails);
export const useUpholsteriesEmployeeAllIndexes = createPrimitivePaginatedHook(
  getUpholsteriesEmployeeAllIndexes,
);

export const useProductionOrders = createPrimitivePaginatedHook(getProductionOrders);
export const useProductionOrder = createPrimitiveHook(getProductionOrder);

import { OrderMessagesSchema } from "api/orders/models";
import { MessagesSent } from "./MessagesSent";
import { User } from "ducks/auth";
import { MessagesReceived } from "./MessagesReceived";

interface Props {
  messagesData: OrderMessagesSchema["messages"][number][];
  me: User;
  clientAvatar: string | null;
}

export const Messages = ({ messagesData, me, clientAvatar }: Props) => {
  const { wayOfCommunication } = messagesData[0];

  if (wayOfCommunication === "OUTGOING") {
    return <MessagesSent messagesData={messagesData} />;
  }
  if (wayOfCommunication === "INCOMING") {
    return <MessagesReceived messagesData={messagesData} clientAvatar={clientAvatar} />;
  }
  return <></>;
};

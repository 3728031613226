import styles from "./NotFound.module.css";
import src404 from "assets/images/404.svg";
import { Link } from "react-router-dom";
import { Button } from "components/common";

export const NotFound = () => {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.imageContainer}>
          <img src={src404} alt="Nie znaleziono" className={styles.image} />
        </div>
        <h3 className={styles.title}>Halo, jest tam kto?...</h3>
        <p className={styles.description}>Hm, wygląda na to, że chyba nic tu nie ma. Chyba...</p>
        <Button as={Link} to="/" kind="primary">
          Wróć na Pulpit
        </Button>
      </div>
    </div>
  );
};

import {
  SingleItemQuantitiesForRoute,
  WarehouseReceivedStatus,
  WarehouseReleasedStatus,
} from "api/orders/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { useInfoWindow } from "../../hooks/useInfoWindow";
import {
  warehouseReceivedStatusToLabelDict,
  warehouseReleasedStatusToLabelDict,
} from "pages/orders/ordersList/rightPanel/quantitiesTables/tables/singleItemQuantitiesDetails/SingleItemQuantitiesDetails";
import { getStandardDateFormat } from "utilities";
import { ScheduleConfirmationStatus } from "api/external-manufacturing/models";
import { Tag, TagProps } from "components/miloDesignSystem/atoms/tag";
import styles from "../SingleItemQuantitiesForRoute.module.css";

export const scheduleConfirmationStatusVariant: Record<
  ScheduleConfirmationStatus,
  { label: string; variant: TagProps<string>["variant"] }
> = {
  CONFIRMED: { label: "Potwierdzono", variant: "success" },
  NOT_SET: { label: "Do ustalenia", variant: "info" },
  REJECTED: { label: "Odrzucono", variant: "warning" },
};

export const useProductsColumns = () => {
  const { toggleInfoWindow } = useInfoWindow();

  return useCreateTableColumns<SingleItemQuantitiesForRoute>(
    ({ columnHelper }) => {
      return [
        columnHelper.accessor(row => row.order, {
          header: "sygnatura",
          size: 110,
          cell: info => {
            const order: SingleItemQuantitiesForRoute["order"] = info.getValue();
            return (
              <div
                className={styles.orderSignature}
                onClick={() => toggleInfoWindow(order.id, order.delivery.point)}
              >
                <Typography color="inherit" fontSize="12" fontWeight="700" noWrap>
                  {order.signature}
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.text(row => row.index.product.name, {
          header: "produkt",
          size: 150,
        }),
        columnHelper.accessor(row => row.routePosition, {
          id: "routePosition",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              kolejność
            </Typography>
          ),
          size: 60,
          cell: info => {
            const routePosition: string = info.getValue();
            return (
              <Typography className="text-right w-100" fontSize="12" fontWeight="700">
                {routePosition}
              </Typography>
            );
          },
        }),
        columnHelper.accessor(row => row.statuses.warehouseReceived, {
          id: "warehouseReceived",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              przyjęto
            </Typography>
          ),
          size: 90,
          cell: info => {
            const warehouseReceived: WarehouseReceivedStatus = info.getValue();
            return (
              <div className="d-flex w-100 align-items-center justify-content-end">
                <Typography
                  color={
                    warehouseReceived === WarehouseReceivedStatus.RECEIVED
                      ? "success500"
                      : "neutralBlack88"
                  }
                  fontSize="12"
                  fontWeight="700"
                >
                  {warehouseReceivedStatusToLabelDict[warehouseReceived]}
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row.statuses.warehouseReleased, {
          id: "warehouseReleased",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              wydano
            </Typography>
          ),
          size: 90,
          cell: info => {
            const warehouseReleased: WarehouseReleasedStatus = info.getValue();
            return (
              <div className="d-flex w-100 align-items-center justify-content-end">
                <Typography
                  color={
                    warehouseReleased === WarehouseReleasedStatus.RELEASED
                      ? "success500"
                      : "neutralBlack88"
                  }
                  fontSize="12"
                  fontWeight="700"
                >
                  {warehouseReleasedStatusToLabelDict[warehouseReleased]}
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.text(
          row =>
            row.externalManufacturingInfo && row.externalManufacturingInfo.pickupBySellerPlannedAt
              ? getStandardDateFormat(row.externalManufacturingInfo.pickupBySellerPlannedAt)
              : null,
          {
            header: "data odbioru",
            size: 100,
            typographyProps: {
              color: "neutralBlack48",
            },
          },
        ),
        columnHelper.accessor(row => row.externalManufacturingInfo, {
          header: "potwierdzenie daty odb.",
          size: 135,
          cell: info => {
            const externalManufacturingInfo: SingleItemQuantitiesForRoute["externalManufacturingInfo"] = info.getValue();
            if (!externalManufacturingInfo) return <EmptyValue />;
            const status = externalManufacturingInfo.scheduleConfirmationStatus;
            return (
              <Tag
                label={scheduleConfirmationStatusVariant[status].label}
                type="outlined"
                variant={scheduleConfirmationStatusVariant[status].variant}
              />
            );
          },
        }),
      ];
    },
    {
      shouldDisplayIndexColumn: true,
    },
  );
};

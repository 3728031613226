import { PopoverMenu } from "components/utils/Popover";
import arrowDownIcon from "assets/images/keyboardArrowDown.svg";
import onCallIcon from "assets/images/phoneInTalk.svg";
import noCallIcon from "assets/images/call.svg";
import calledIcon from "assets/images/phoneForwardedTeal.svg";
import styles from "../../shared/CallCenter.module.css";
import cx from "classnames";
import { CallCenterRouteOrderListItem, callStatusDict } from "api/call-center/routes/models";
import { useRoutePatchMutation } from "api/call-center/routes/hooks";

interface Props {
  order: CallCenterRouteOrderListItem;
}

export const CallStatus = ({ order }: Props) => {
  const patchCallStatusMutation = useRoutePatchMutation();
  return (
    <PopoverMenu
      placement="bottom-start"
      triggerOffset={-1}
      target={btnProps => (
        <div
          className={cx(
            styles.callStatus,
            styles.callStatusSmall,
            "d-flex align-items-center justify-content-between gap-2 ml-n1",
          )}
          onMouseDown={e => e.stopPropagation()}
          role="button"
          {...btnProps}
        >
          <div className="d-flex align-items-center justify-content-between gap-3">
            <div>
              {order.callStatus === "CALLED" ? (
                <img className={styles.callStatusIcon} alt="dzwoniono" src={calledIcon} />
              ) : order.callStatus === "NO_CALL_YET" ? (
                <img className={styles.callStatusIcon} alt="brak" src={noCallIcon} />
              ) : (
                <img className={styles.callStatusIcon} alt="w trakcie" src={onCallIcon} />
              )}
            </div>
            <div className="d-flex justify-content-end ml-4">
              <img alt="Wybierz" src={arrowDownIcon} />
            </div>
          </div>
        </div>
      )}
    >
      {({ close }) => (
        <div
          className={styles.optionList}
          onMouseDown={e => e.stopPropagation()}
          style={{ left: "0", right: "initial", position: "absolute" }}
        >
          <div
            className={cx(styles.optionRow, "px-3 py-2", {
              [styles.optionSelected]: order.callStatus === "NO_CALL_YET",
            })}
            onClick={() => {
              patchCallStatusMutation.mutate({
                id: order.id,
                toUpdate: { callStatus: "NO_CALL_YET" },
              });
              close();
            }}
          >
            <div className="d-flex align-items-center gap-2">
              <div>
                <img alt="brak" src={noCallIcon} style={{ width: "16px" }} />
              </div>
              <div className="body-14-600">{callStatusDict["NO_CALL_YET"]}</div>
            </div>
          </div>
          <div
            className={cx(styles.optionRow, "px-3 py-2", {
              [styles.optionSelected]: order.callStatus === "ON_A_CALL",
            })}
            onClick={() => {
              patchCallStatusMutation.mutate({
                id: order.id,
                toUpdate: { callStatus: "ON_A_CALL" },
              });
              close();
            }}
          >
            <div className="d-flex align-items-center gap-2">
              <div>
                <img alt="w trakcie" src={onCallIcon} style={{ width: "16px" }} />
              </div>
              <div className="body-14-600">{callStatusDict["ON_A_CALL"]}</div>
            </div>
          </div>
          <div
            className={cx(styles.optionRow, "px-3 py-2", {
              [styles.optionSelected]: order.callStatus === "CALLED",
            })}
            onClick={() => {
              patchCallStatusMutation.mutate({
                id: order.id,
                toUpdate: { callStatus: "CALLED" },
              });
              close();
            }}
          >
            <div className="d-flex align-items-center gap-2">
              <div>
                <img alt="dzwoniono" src={calledIcon} style={{ width: "16px" }} />
              </div>
              <div className="body-14-600">{callStatusDict["CALLED"]}</div>
            </div>
          </div>
        </div>
      )}
    </PopoverMenu>
  );
};

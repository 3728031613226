export enum AutoAssignProcedure {
  EVEN = "EVEN",
  FILL = "FILL",
}

export enum AutoAssignOrderBy {
  PRIORITY = "priority",
  SOURCE_DEPARTURE = "source_departure",
}

export enum ExtraSlotMethod {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

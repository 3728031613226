import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import forumIcon from "assets/images/forum.svg";
import { CallCenterOrders } from "routes/CallCenterOrders";
import { FilterList } from "components/common/moduleNavigation/components/filtersSection/filterList/FilterList";
import { FilterType } from "components/common/filters/types";

export const orders: ModuleLink = {
  url: "orders",
  label: "Komunikacja z klientem",
  icon: forumIcon,
  subSection: (
    <FilterList
      filters={[
        {
          type: FilterType.Select,
          label: "Czy ma nieodczytane wiadomości?",
          name: "hasUnreadMessages",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
      ]}
    />
  ),
  routing: CallCenterOrders,
};

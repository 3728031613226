import { Route } from "components/utils";
import { Dashboard as WmsDashboard } from "pages/wms/dashboard/Dashboard";
import { NotFound } from "pages/notFound";
import { RouteComponentProps, Switch } from "react-router-dom";

export const Dashboard = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route component={WmsDashboard} exact={true} path={`${match.path}`} title="Milo - WMS" />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};

import { deletePurchaseInvoicePosition } from "api/trading-documents/calls";
import { useTradingDocument } from "api/trading-documents/hooks";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { UUID } from "api/types";
import { useMutation } from "hooks/useMutation";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { assertIsDefined } from "utilities/assertIsDefined";

export const useRemovePurchaseInvoicePosition = (
  itemId: string | number,
  tradingDocumentId: UUID,
  tradingDocumentItemId: UUID,
  tradingDocumentItemName: string,
) => {
  const { handleMutate } = useTradingDocument({ id: tradingDocumentId }, { enabled: false });

  return withDeleteConfirmation(
    useMutation(deletePurchaseInvoicePosition, ({ queryClient, toastr }) => ({
      onMutate: () => {
        return handleMutate(draft => {
          const itemToUpdate = draft.items.find(item => item.id === itemId);
          assertIsDefined(itemToUpdate);
          itemToUpdate.tradingDocumentItems = itemToUpdate.tradingDocumentItems.filter(
            tradingDocumentItem => tradingDocumentItem.id !== tradingDocumentItemId,
          );
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(tradingDocumentId),
        );
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto pozycję "${tradingDocumentItemName}"`,
        });
      },
      onError: (error, _, rollback) => {
        // @ts-ignore
        rollback(error);
      },
    })),
  )();
};

import { ForwardedRef } from "react";
import React from "react";
import { IconButtonProps, IconButtonSize } from "./types";
import styled from "@emotion/styled";
import { ButtonStyle, buttonVariants, disabledButtonVariants } from "../shared/buttonVariants";
import { colorPalette } from "../colorsPalette";
import { Spinner } from "../spinner";

const DEFAULT_ICON_SIZE = "16" as const;

const buttonSizes: Record<IconButtonSize, { [stylesProp: string]: string }> = {
  small: {
    height: "20px",
    width: "20px",
  },
  medium: {
    height: "26px",
    width: "26px",
  },
};

const StyledIconWrapper = styled.div<{
  variantStyles: {
    default: ButtonStyle;
    hover: ButtonStyle;
    active: ButtonStyle;
  };
}>`
  svg {
    fill: ${props => colorPalette[props.variantStyles.default.icon!]};
  }

  &:hover svg {
    fill: ${props => colorPalette[props.variantStyles.hover.icon!]};
  }

  &:active svg {
    fill: ${props => colorPalette[props.variantStyles.active.icon!]};
  }
`;

const StyledIconButton = styled.button<{
  cursorStyle: string;
  size: IconButtonSize;
  variantStyles: {
    default: ButtonStyle;
    hover: ButtonStyle;
    active: ButtonStyle;
  };
}>`
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  ${props => buttonSizes[props.size]}
  ${props => props.variantStyles.default}
  &:hover {
    ${props => props.variantStyles.hover};
  }
  &:active {
    ${props => props.variantStyles.active};
  }
  cursor: ${props => props.cursorStyle};
`;

export const IconButton = React.forwardRef(
  (
    {
      className,
      disabled = false,
      isLoading = false,
      icon,
      size = "medium",
      variant,
      theme = "light",
      ...rest
    }: IconButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const variantStyles = (() => {
      if (disabled && variant !== "success") {
        return disabledButtonVariants[theme];
      }
      return buttonVariants[variant][theme];
    })();

    const cursorStyle = (() => {
      if (disabled) return "not-allowed !important";
      if (isLoading) return "wait !important";
      return "pointer !important";
    })();

    return (
      <StyledIconWrapper variantStyles={variantStyles}>
        <StyledIconButton
          className={className}
          cursorStyle={cursorStyle}
          disabled={disabled || isLoading}
          ref={ref}
          size={size}
          type="button"
          variantStyles={variantStyles}
          {...rest}
        >
          <Icon icon={icon} isLoading={isLoading} theme={theme} variant={variant} />
        </StyledIconButton>
      </StyledIconWrapper>
    );
  },
);

const Icon = ({
  icon: Icon,
  isLoading,
  theme,
  variant,
}: Pick<IconButtonProps, "icon" | "isLoading" | "theme" | "variant">) => {
  if (isLoading)
    return (
      <Spinner
        color={buttonVariants[variant][theme!].default.icon!}
        size={Number(DEFAULT_ICON_SIZE)}
      />
    );
  if (Icon && typeof Icon === "function") return <Icon size={DEFAULT_ICON_SIZE} />;
  if (Icon) return Icon;
  return null;
};

import { Filters } from "../../AddEmployeeModal";
import cx from "classnames";
import localStyles from "./FiltersBar.module.css";
import { SearchField } from "components/utils/searchField/SearchField";
import { useQuery } from "hooks";
import { RadioLabels } from "components/utils";

interface Props {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

export const FiltersBar = ({ filters, setFilters }: Props) => {
  const { query, setQuery } = useQuery({ exclude: ["panelId"] });

  return (
    <div className={cx(localStyles.filters, "mb-3 d-flex align-items-center")}>
      <SearchField
        value={filters.search}
        onUpdate={value => {
          setFilters(filters => {
            return { ...filters, search: value };
          });
          setQuery({ ...query, page: "1" });
        }}
      />
      <span className={cx(localStyles.filtersLabel, "pt-1 mr-2 ml-3")}>typ pracownika:</span>
      <div className="mb-1">
        <RadioLabels label="" name="kind" onChange={() => {}}>
          <RadioLabels.Label
            className="mb-1 mr-1"
            type="text"
            key="UPHOLSTERER"
            onChange={() => {
              if (filters.kind === "UPHOLSTERER") {
                setFilters(filters => {
                  return { ...filters, kind: "" };
                });
                setQuery({ ...query, page: "1" });
              } else {
                setFilters(filters => {
                  return { ...filters, kind: "UPHOLSTERER" };
                });
                setQuery({ ...query, page: "1" });
              }
            }}
            allowUncheck={true}
            checked={filters.kind === "UPHOLSTERER"}
            label="tapicer"
            name="UPHOLSTERER"
          />
          <RadioLabels.Label
            className="mb-1 mr-1"
            type="text"
            key="SEAMSTRESS"
            onChange={() => {
              if (filters.kind === "SEAMSTRESS") {
                setFilters(filters => {
                  return { ...filters, kind: "" };
                });
                setQuery({ ...query, page: "1" });
              } else {
                setFilters(filters => {
                  return { ...filters, kind: "SEAMSTRESS" };
                });
                setQuery({ ...query, page: "1" });
              }
            }}
            allowUncheck={true}
            checked={filters.kind === "SEAMSTRESS"}
            label="szwacz"
            name="SEAMSTRESS"
          />
          <RadioLabels.Label
            className="mb-1 mr-1"
            type="text"
            key="CUTTER"
            onChange={() => {
              if (filters.kind === "CUTTER") {
                setFilters(filters => {
                  return { ...filters, kind: "" };
                });
                setQuery({ ...query, page: "1" });
              } else {
                setFilters(filters => {
                  return { ...filters, kind: "CUTTER" };
                });
                setQuery({ ...query, page: "1" });
              }
            }}
            allowUncheck={true}
            checked={filters.kind === "CUTTER"}
            label="krojczy"
            name="CUTTER"
          />
        </RadioLabels>
      </div>
    </div>
  );
};

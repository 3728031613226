import { Decimal, ISODateTime, UUID } from "api/types";

export enum LongHeldPackageTab {
  "with-orders" = "with-orders",
  "without-orders" = "without-orders",
}

export type LongHeldPackage = {
  id: number;
  created: ISODateTime;
  name: string;
  whCell: unknown | null;
  order: {
    signature: string;
    name: string;
    id: number;
  };
  position: number;
  location: string | number | null;
  weight: string;
  height: string;
  width: string;
  length: string;
  dateOfLastInvoice: ISODateTime | null;
  fee: number;
  supplier: {
    id: UUID;
    kind: string;
    name: string;
  } | null;
  supplierId: string;
  supplierKind: string;
  countBalance: number;
  totalPackagesInCollection: number;
};

export type Fee = {
  currency: string;
  id: number;
  dailyRate: Decimal | null;
  totalBalance: Decimal;
  days: number;
  paidBalance: Decimal;
  unpaidBalance: Decimal;
  startDate: ISODateTime | null;
  endDate: ISODateTime | null;
};

import { useField, useFormikContext } from "formik";
import { Select } from "../select";
import cx from "classnames";
import styles from "./FormSelect.module.css";

interface Props<Item> {
  label?: string;
  name: string;
  type?: string;
  items: Item[];
  defaultSelected?: string | number;
  selectedItem?: string | number | null | undefined;
  itemToSelection?: (arg: Item | null) => any;
  children?: never;
  width?: "auto" | "full";
  disabled?: boolean;
  overwrites?: {
    wrapper?: { className?: string };
    input?: { className?: string };
    button?: { className?: string };
    floatingLabel?: { className?: string };
  };
  placeholder?: string;
}

function defaultItemToSelection<Item>(item: Item) {
  return item;
}

export function FormSelect<Item extends { id?: any; name: string; [key: string]: any }>({
  label,
  children,
  type,
  items,
  defaultSelected,
  selectedItem,
  itemToSelection = defaultItemToSelection,
  width,
  overwrites = {},
  placeholder,
  disabled = false,
  ...props
}: Props<Item>) {
  const [, meta] = useField(props);
  const { setFieldValue } = useFormikContext<any>();
  return (
    <div className={cx(styles.selectWrapper, overwrites.wrapper?.className ?? "")}>
      <div className={styles.selectBox}>
        <span className={cx(styles.label, overwrites.floatingLabel?.className ?? "")}>{label}</span>
        <Select
          buttonClassName={overwrites.button?.className}
          onChange={item => setFieldValue(props.name, itemToSelection(item as Item | null))}
          items={items as Item[]}
          defaultSelected={defaultSelected}
          selectedItem={selectedItem ?? meta.value}
          width={width}
          disabled={disabled}
          placeholder={placeholder}
        />
      </div>
      {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </div>
  );
}

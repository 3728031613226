import { Route } from "api/routes/models";
import { UUID } from "api/types";
import { Button, PageHeader } from "components/common";
import { HistoryLogItem } from "components/common/historyLogItem";
import { Loader } from "components/utils/loader";
import { useRoute } from "hooks/apiPrimitives";
import { useInfiniteLogs } from "hooks/useInfinityLogs";
import { useLocation, useParams } from "react-router";
import darkPlusIcon from "assets/images/darkPlus.svg";
import styles from "./RouteHistory.module.css";

type LocationState = { route: Pick<Route, "signature"> } | undefined;

export const RouteHistory = () => {
  const params = useParams<{ routeId: UUID }>();
  const location = useLocation<LocationState>();

  const locationRoute = location.state?.route;

  const { data: fetchedRoute } = useRoute(params.routeId, { enabled: !Boolean(locationRoute) });

  const route = locationRoute ?? fetchedRoute;

  const { logs, fetchMore, inProgress, hasMore } = useInfiniteLogs(params.routeId, "route");

  const subtitle = route?.signature ?? "";

  return (
    <div className="position-relative">
      {inProgress && <Loader module="logistics" />}
      <PageHeader
        searchBar={false}
        subtitle={subtitle}
        title="Historia trasy"
        viewLabel="LOGISTICS_ROUTE_HISTORY"
      />

      <div className={styles.listWrapper}>
        <div className={styles.listGroup}>
          {logs.map(log => (
            <HistoryLogItem key={log.id} log={log} />
          ))}
        </div>
        <div className="d-flex mt-4 mb-3">
          {!inProgress && hasMore && (
            <Button kind="create" onClick={fetchMore}>
              <div className="btnBase btnBaseSmall">
                <img alt="" src={darkPlusIcon} />
                Wczytaj więcej
              </div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

import { ListOrder } from "api/orders/models";
import { getOrderBySignature } from "api/orders/calls";
import { NoContent } from "components/utils/noContent";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { tuplify } from "utilities";
import listEmptyIcon from "assets/images/el2.png";
import { getOrderStatus } from "utilities/getOrderStatus";

const useOrderBySignature = (signature: string) => {
  const [data, setData] = useState<ListOrder | null>(null);
  const [status, setStatus] = useState<"default" | "fetching" | "error">("default");
  useEffect(() => {
    async function getData() {
      setStatus("fetching");
      const [payload] = await getOrderBySignature(signature);
      if (payload && payload.results[0]) {
        setStatus("default");
        setData(payload.results[0]);
      } else {
        setStatus("error");
      }
    }
    getData();
  }, [signature]);
  return tuplify(data, status);
};

export const OrderStatus = ({ match }: RouteComponentProps<{ signature: string }>) => {
  const [order, status] = useOrderBySignature(match.params.signature);
  if (status === "error") return <NoContent header="Nie znaleziono" img={listEmptyIcon} />;
  if (!order) return null;
  const orderStatus = getOrderStatus(order.status);
  return (
    <div>
      <div className="mr-3">{order.signature}</div>
      status: <img src={orderStatus.img} alt="" />
      {orderStatus.name}
    </div>
  );
};

import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import routeIcon from "assets/images/route.svg";
import { Route } from "routes/logistics/Route";

export const route: ModuleLink = {
  url: "route",
  label: "Trasa",
  icon: routeIcon,
  subSection: <></>,
  routing: Route,
};

import * as React from "react";
import cx from "classnames";
import closeImg from "assets/images/10.svg";
import commentIcon from "assets/images/45g.svg";
import visibilityOnIcon from "assets/images/eye_on.svg";
import visibilityOffIcon from "assets/images/eye_off.svg";
import styles from "./OrderCommentsDrawer.module.css";
import { Button, CommentType } from "components/common";
import { CommonError, Spinner, StatusHandler } from "components/utils";
import { dateFns, getAnyErrorKey } from "utilities";
import { useToastr, useTrimmedArray } from "hooks";
import { useRouteViewState } from "../routeCreatorState";
import { useInfoWindow } from "../hooks/useInfoWindow";
import { Route, RouteOrdersComments } from "api/routes/models";
import { useRouteOrdersComments } from "hooks/apiPrimitives";
import { OrderComment } from "./OrderComment";
import { routeKeys } from "api/keys";
import { patchComment } from "api/orders/calls";
import { useQueryClient } from "react-query";
import { produce } from "immer";

interface Props {
  route: Route;
}

export const OrderCommentsDrawer = ({ route }: Props) => {
  const { openedInfoWindowData, toggleInfoWindow } = useInfoWindow();

  const isOrderCommentsDrawerOpen = useRouteViewState(
    "slave",
    store => store.isOrderCommentsDrawerOpen,
  );
  const actions = useRouteViewState("slave", store => store.actions);
  const isLoading = useRouteViewState("slave", state => state.isLoading);

  const { data, isLoading: inProgress, error } = useRouteOrdersComments(route.id, {
    enabled: isOrderCommentsDrawerOpen,
  });
  const orderCommentsDict: Record<string, RouteOrdersComments["orders"][number]> = (
    data?.orders || []
  ).reduce((acc, el) => {
    //@ts-ignore
    acc[el.id] = el;
    return acc;
  }, {});

  let pointOrderOnList = 0;
  return (
    <div className={cx(styles.container, { [styles.closed]: !isOrderCommentsDrawerOpen })}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center ">
          <img src={commentIcon} alt="" className={styles.commentIcon} />
          <div className={styles.label}>Komentarze</div>
        </div>
        <button
          className={styles.closeButton}
          onClick={() => actions.toggle("isOrderCommentsDrawerOpen")}
        >
          <img src={closeImg} alt="" />
        </button>
      </div>
      {error && (
        <div>
          <CommonError text={getAnyErrorKey(error)} />
        </div>
      )}
      {inProgress || !data ? (
        <Spinner color="blue" size="big" text="trwa wczytywanie" position="absolute" />
      ) : (
        <div>
          <div className={styles.group}>
            <div className={styles.routePoints}>
              {route.ordersPositions.map(item => {
                if (item.type === "order") {
                  pointOrderOnList = pointOrderOnList + 1;
                  const order = orderCommentsDict[item.id];
                  if (!order) return null;
                  return (
                    <div
                      key={order.id}
                      className="d-flex align-items-start justify-content-end position-relative"
                    >
                      <div className={cx(styles.counter, "fs-14")}>{pointOrderOnList}</div>
                      <div
                        className={cx(styles.order, {
                          [styles["selected"]]: openedInfoWindowData?.id === order.id,
                        })}
                        onClick={() => toggleInfoWindow(order.id, order.delivery.point)}
                      >
                        <OrderComment order={order} route={route} isLoading={isLoading} />
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const trim = 2;

function sortDateDesc(arr: CommentType[]) {
  return [...arr].sort((a, b) => (a.id > b.id ? 1 : -1));
}

export const Comments = ({
  comments,
  orderId,
  routeId,
}: {
  comments: CommentType[];
  orderId: number;
  routeId: number;
}) => {
  const [trimmedComments, { toggle, areTrimmed }] = useTrimmedArray(sortDateDesc(comments), trim);
  const queryClient = useQueryClient();
  const toastr = useToastr();
  const [, setValue] = React.useState("");
  const { closeInfoWindow } = useInfoWindow();

  const clear = () => setValue("");

  const handleCommentVisibility = async (comment: CommentType, isVisible: boolean) => {
    const [patchedComment, error] = await patchComment({
      id: comment.id,
      isOnlyVisibleForTeam: isVisible,
    });
    if (error) {
      toastr.open({
        type: "warning",
        title: getAnyErrorKey(error),
        text: "Nie udało się zaktualizować widoczności komentarza.",
      });
      return { success: false };
    } else if (patchedComment) {
      queryClient.setQueryData<RouteOrdersComments | undefined>(
        routeKeys.routeCreatorOrdersComments(String(routeId)),

        currentList => {
          if (!currentList) return;
          return produce(currentList, draft => {
            const orderToUpdate = draft.orders.find(el => el.id === orderId);
            const commentToUpdate = orderToUpdate?.comments.find(el => el.id === comment.id);
            if (commentToUpdate) {
              commentToUpdate.isOnlyVisibleForTeam = isVisible;
            }
          });
        },
      );
      closeInfoWindow();
      clear();
      return { success: true };
    }
  };

  return (
    <div>
      <span className="fs-12 mr-2 text-color-grey">Komentarze</span>
      {comments.length > trim && (
        <button
          className="btn-appear-link blue-link"
          type="button"
          onClick={e => {
            e.stopPropagation();
            toggle();
          }}
        >
          {areTrimmed ? (
            <span className="fs-12">Wcześniejsze komentarze: +{comments.length - trim}</span>
          ) : (
            <span className="fs-12">Ukryj wcześniejsze komentarze</span>
          )}
        </button>
      )}

      {trimmedComments.map(comment => (
        <div className="mb-2 d-flex" key={comment.id}>
          <div style={{ display: "block" }}>
            <div className="fs-16">{comment.body}</div>
            <div className="d-flex">
              <strong className="fs-12 mr-1">
                {comment.user.firstName} {comment.user.lastName}
              </strong>
              <span className="fs-12 text-color-grey">
                {dateFns.format(new Date(comment.created), "dd MMM yyyy, HH:mm")}
              </span>
            </div>
          </div>
          <div className="ml-auto">
            <StatusHandler>
              {helpers => (
                <Button
                  disabled={helpers.isFetching}
                  kind="secondary"
                  size="round-m"
                  onClick={e => {
                    e.stopPropagation();
                    handleCommentVisibility(comment, !comment.isOnlyVisibleForTeam);
                  }}
                >
                  {comment.isOnlyVisibleForTeam ? (
                    <img src={visibilityOnIcon} alt="" />
                  ) : (
                    <img src={visibilityOffIcon} alt="" />
                  )}
                </Button>
              )}
            </StatusHandler>
          </div>
        </div>
      ))}
    </div>
  );
};

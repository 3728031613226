import { Status } from "api/routes/models";
import { colorType, StateLabel } from "components/common/stateLabel";

interface Props {
  status: Status;
}

// TODO: Replace current kind's with the corrected colors

const statusConfig: Record<Status, { kind: colorType; label: string }> = {
  NEW: { kind: "lightBlue", label: "nowa" },
  ROUTE_READY: { kind: "darkGreen", label: "gotowa" },
  DEPARTED: { kind: "darkGreen", label: "w drodze" },
  FINISHED: { kind: "darkGreen", label: "zakończona" },
  SETTLED: { kind: "darkGreen", label: "rozliczona" },
};

export const RouteStatus = ({ status }: Props) => {
  return (
    <div className="fw-700">
      <StateLabel kind={statusConfig[status].kind}>{statusConfig[status].label}</StateLabel>
    </div>
  );
};

import styles from "./Switch.module.css";
import cx from "classnames";
import { SwitchProps } from "./types";
import { Typography } from "../typography";

export const Switch = ({
  checked,
  label = "",
  helperText,
  onChange,
  size = "small",
  disabled = false,
}: SwitchProps) => {
  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.disabled]: disabled,
        },
        styles[size],
      )}
    >
      <div className={styles.row}>
        <div
          className={cx({
            [styles.checkMark]: true,
            [styles.activeMark]: checked,
            [styles.disabled]: disabled,
          })}
        />
        <input
          onChange={() => onChange(!checked)}
          type="checkbox"
          disabled={disabled}
          checked={checked}
        />
        <div className={cx(styles.textWrapper, "d-flex flex-column align-items-center")}>
          {label && (
            <label
              onClick={() => {
                if (!disabled) {
                  onChange(!checked);
                }
              }}
              className={styles.label}
            >
              {label}
            </label>
          )}
          <Typography color="neutralBlack48" fontSize="10" fontWeight="600">
            {helperText}
          </Typography>
        </div>
      </div>
    </div>
  );
};

import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import trolleyIcon from "assets/images/trolley.svg";
import { Pickings } from "routes/wms/Pickings";

export const pickings: ModuleLink = {
  url: "pickings",
  label: "Pickingi",
  icon: trolleyIcon,
  subSection: <></>,
  routing: Pickings,
};

import { ordersKeys } from "api/keys";
import { removeDeliveryConfirmationForOrders } from "api/routes/calls";
import { routesKeys } from "api/routes/keys";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";

export const useRemoveDeliveryConfirmation = () => {
  return useMutation(
    ({ id }: { id: number; minimumHour: string; maximumHour: string; route: number }) =>
      removeDeliveryConfirmationForOrders({ orders: [id] }),
    ({ toastr, queryClient }) => ({
      onSuccess: (_, args) => {
        queryClient.invalidateQueries(ordersKeys.orderForRoute(String(args.id)));
        queryClient.invalidateQueries(routesKeys.route(String(args.route)));
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto godziny potwierdzenia "${args.minimumHour} - ${args.maximumHour}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

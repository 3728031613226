import { Unloading } from "api/wms/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { UnloadingStatus } from "./components/UnloadingStatus";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { UserWithInitials } from "api/users/models";
import styles from "./CargoUnloadsSection.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { UnloadingEstimatedTimeOfArrival } from "./components/UnloadingEstimatedTimeOfArrival";
import { LinkToPage } from "components/utils/linkToPage";

export const useUnloadingsColumns = () =>
  useCreateTableColumns<Unloading>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "sygnatura",
        size: 130,
        cell: info => {
          const unloading = info.getValue();
          return (
            <LinkToPage
              content={unloading.signature}
              url={`/wms/unloadings/list/all?panelId=${unloading.id}`}
            />
          );
        },
      }),
      columnHelper.text(
        row =>
          row.suppliers && Boolean(row.suppliers.length)
            ? row.suppliers.map(supplier => supplier.name).join(", ")
            : null,
        {
          header: "kontrahent/produc.",
          size: 110,
        },
      ),
      columnHelper.text(row => row.description, {
        header: "nazwa",
        size: 120,
      }),
      columnHelper.accessor(row => row, {
        header: "status",
        size: 110,
        cell: info => {
          return <UnloadingStatus unloading={info.getValue()} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "data pl. przyjazdu",
        size: 100,
        cell: info => {
          return <UnloadingEstimatedTimeOfArrival unloading={info.getValue()} />;
        },
      }),
      columnHelper.text(row => (row.ramp ? row.ramp.name : null), {
        header: "rampa",
        size: 40,
      }),
      columnHelper.text(
        row => {
          return `${Number(row.totalProgress)}%`;
        },
        {
          header: "postęp",
          size: 45,
        },
      ),
      columnHelper.text(
        row => {
          return `${Number(row.scanningProgress)}%`;
        },
        {
          header: "skanowanie",
          size: 70,
          typographyProps: {
            color: "deepPurple400",
          },
        },
      ),
      columnHelper.text(
        row => {
          return `${Number(row.locationProgress)}%`;
        },
        {
          header: "lokalizowanie",
          size: 75,
          typographyProps: {
            color: "blue300",
          },
        },
      ),
      columnHelper.accessor(row => row.collaborators, {
        header: "zaangażowani",
        size: 80,
        cell: info => {
          const collaborators: UserWithInitials[] = info.getValue();
          return (
            <div className="d-flex align-items-center">
              {Boolean(collaborators.length) ? (
                collaborators.map(user => (
                  <div className={styles.avatarCover} key={user.id}>
                    <Avatar size="xs" theme="light" user={user} />
                  </div>
                ))
              ) : (
                <Typography fontSize="12" fontWeight="700">
                  ---
                </Typography>
              )}
            </div>
          );
        },
      }),
    ];
  });

import { queryFetch } from "apiConnectors/queryFetch";
import { Carrier, CreateCarrier } from "./models";
import { ApiFetcher } from "hooks/createApiQuery";
import { carriersKeys } from "./keys";
import { Pagination } from "api/types";
import { PartialOf } from "typeUtilities";

export const getCarriers = (search: string = ""): ApiFetcher<Pagination<Carrier>> => ({
  key: carriersKeys.carriers.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/carriers/items" + search,
    }),
});

export const getCarrier = (id: string | number): ApiFetcher<Carrier> => ({
  key: carriersKeys.carriers.details(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/carriers/items/" + id,
    }),
});

export const postCarrier = (data: CreateCarrier) =>
  queryFetch<Carrier>({
    method: "POST",
    url: "/carriers/items",
    data,
  });

export const patchCarrier = (data: PartialOf<CreateCarrier>, id: string | number) =>
  queryFetch<Carrier>({
    method: "PATCH",
    url: "/carriers/items/" + id,
    data,
  });

export const deleteCarrier = (id: string | number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/carriers/items/" + id,
  });

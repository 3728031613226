import { Unloading, statusDict } from "api/wms/models";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { combineDateFormat } from "pages/wms/unloadingsList/utils/combineDateFormat";
import { isUnloadingLate } from "pages/wms/unloadingsList/utils/isUnloadingLate";

interface Props {
  unloading: Unloading;
}

export const UnloadingStatus = ({ unloading }: Props) => {
  const combinedDate = combineDateFormat(
    unloading.estimatedDateOfArrivalDate,
    unloading.estimatedDateOfArrivalTime,
  );
  if (combinedDate && isUnloadingLate(String(combinedDate)))
    return <Tag startIcon={MdiPoint} label="Opóźniony" variant="danger" />;
  return (
    <Tag
      startIcon={MdiPoint}
      label={statusDict[unloading.status].label}
      variant={statusDict[unloading.status].variant}
    />
  );
};

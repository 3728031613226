import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { ConnectInvoice } from "pages/tradingDocuments/connectInvoice/ConnectInvoice";
import { EditTradingDocument } from "pages/tradingDocuments/editTradingDocument/EditTradingDocument";
import { PurchaseInvoiceHistory } from "pages/tradingDocuments/purchaseInvoicesList/history/PurchaseInvoiceHistory";
import { PurchaseInvoicesList } from "pages/tradingDocuments/purchaseInvoicesList/PurchaseInvoicesList";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const PurchaseInvoices = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={PurchaseInvoiceHistory}
        exact={true}
        path={`${match.path}/:purchaseInvoiceId/history`}
        title="Milo - historia faktury zakupowej"
      />
      <Route
        component={PurchaseInvoicesList}
        exact={true}
        path={`${match.path}/list/:tab`}
        title="Milo - faktury zakupowe"
      />
      <Route
        component={EditTradingDocument}
        exact={true}
        path={`${match.path}/:tradingDocumentId/edit`}
        title="Milo - edytuj fakturę zakupową"
      />
      <Route
        component={ConnectInvoice}
        exact={true}
        path={`${match.path}/connect-invoice/:tradingDocumentId`}
        title="Milo - połącz fakturę z PZ"
      />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};

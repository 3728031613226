import { useState, useCallback } from "react";

/**
 * Just toggle, but it takes and saves some state on open; state is cleared on close.
 */
export function useStatefulToggle<State>() {
  const [state, setState] = useState<State | null>(null);
  const close = useCallback(() => setState(null), []);
  const open = useCallback((order: State) => setState(order), []);
  const isOpen = Boolean(state);
  return { isOpen, open, close, state };
}

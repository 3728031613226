import React from "react";
import { ForwardedRef } from "react";
import styles from "./TextField.module.css";
import cx from "classnames";
import { Size, TextFieldProps, Theme } from "./types";
import { Typography } from "../typography";
import { ColorPalette } from "../colorsPalette";

const colorSelector: Record<Theme, ColorPalette> = {
  light: "neutralBlack88",
  dark: "neutralWhite48",
};

export const TextField = React.forwardRef(
  (
    {
      containerClassName,
      inputClassName,
      disabled,
      endIcon: EndIcon,
      error,
      helperText,
      label,
      readOnly,
      startIcon: StartIcon,
      EndInputSection,
      theme = Theme.LIGHT,
      size = Size.DEFAULT,
      ...rest
    }: TextFieldProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const baseStyles = {
      [styles.textFieldInput]: true,
      [styles[size!]]: true,
      [styles[theme!]]: !disabled && !readOnly,
    };
    const disabledStyles = {
      [styles.disabledLight]: disabled && theme === Theme.LIGHT,
      [styles.disabledDark]: disabled && theme === Theme.DARK,
    };

    const readOnlyStyles = {
      [styles.readOnlyLight]: readOnly && theme === Theme.LIGHT,
      [styles.readOnlyDark]: readOnly && theme === Theme.DARK,
    };

    return (
      <>
        <div
          tabIndex={-1}
          className={cx(
            baseStyles,
            {
              ...disabledStyles,
              ...readOnlyStyles,
              "cursor-not-allowed": disabled || readOnly,
              [styles.error]: error,
            },
            containerClassName,
          )}
        >
          {label && <Label size={size} label={label} theme={theme} />}
          {StartIcon && typeof StartIcon === "function" ? (
            <StartIcon color={colorSelector[theme]} size={DEFAULT_ICON_SIZE} />
          ) : StartIcon ? (
            StartIcon
          ) : null}
          <input
            tabIndex={-1}
            ref={ref}
            className={cx(
              styles.defaultInput,
              {
                [styles.defaultInputLight]: theme === Theme.LIGHT,
                [styles.defaultInputDark]: theme === Theme.DARK,
              },
              inputClassName,
            )}
            disabled={readOnly || disabled || false}
            {...rest}
          />
          {EndInputSection}
          {EndIcon && typeof EndIcon === "function" ? (
            <EndIcon color={colorSelector[theme]} size={DEFAULT_ICON_SIZE} />
          ) : EndIcon ? (
            EndIcon
          ) : null}
        </div>

        {error && typeof error === "string" && (
          <Typography className="mt-1" color="danger500" fontSize="10" fontWeight="500">
            {error}
          </Typography>
        )}
        {helperText && <HelperText helperText={helperText} theme={theme} disabled={disabled} />}
      </>
    );
  },
);

const Label = ({ label, theme, size }: Pick<TextFieldProps, "label" | "theme" | "size">) => {
  const labelColor = {
    [styles.lightLabel]: theme === Theme.LIGHT,
    [styles.darkLabel]: theme === Theme.DARK,
  };

  const labelSize = {
    [styles.smallLabel]: size === "small",
    [styles.defaultLabel]: size === "default",
  };

  return (
    <Typography
      color={theme === "light" ? "neutralBlack48" : "neutralWhite48"}
      fontSize="10"
      fontWeight="700"
      className={cx(styles.label, labelColor, labelSize)}
    >
      {label}
    </Typography>
  );
};

const HelperText = ({
  helperText,
  theme,
  disabled,
}: Pick<TextFieldProps, "helperText" | "disabled" | "theme">) => {
  const color: ColorPalette = (() => {
    if (theme === Theme.LIGHT) {
      return disabled ? "neutralBlack24" : "neutralBlack48";
    }

    return disabled ? "neutralWhite24" : "neutralWhite48";
  })();

  return (
    <Typography className="mt-1" color={color} fontSize="10" fontWeight="500">
      {helperText}
    </Typography>
  );
};

const DEFAULT_ICON_SIZE = "18";

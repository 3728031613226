import { useLocation, useParams } from "react-router";
import { useQuery, useStateModal, useToggle } from "hooks";
import { cx, getStandardDateFormat, pluralize, queryString } from "utilities";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiNewReleases } from "components/miloDesignSystem/atoms/icons/MdiNewReleases";
import { MdiContactSupport } from "components/miloDesignSystem/atoms/icons/MdiContactSupport";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  DrawerHelpers,
  getTablePropsBasedOnDrawerHelpers,
} from "components/utils/drawerHelpers/DrawerHelpers";
import { Table } from "components/miloDesignSystem/molecules/table";
import {
  NotificationType,
  RouteLineItem,
  ScheduleConfirmationStatus,
  ScheduleEmailStatus,
  UnsentEmailPreview,
} from "api/external-manufacturing/models";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useState } from "react";
import { HighlightedRow } from "api/other/models";
import { handleMultipleRowsHighlight } from "utilities/handleMultipleRowsHighlight";
import styles from "./RouteLineItems.module.css";
import { useRouteLineItemsColumns } from "./useRouteLineItemsColumns";
import { FailedResponseModal } from "components/miloDesignSystem/organisms/failedResponseModal/FailedResponseModal";
import { useLogisticsRouteSimplified } from "api/logistics/routes/hooks";
import { TableToolbarActions } from "./components/TableToolbarActions";
import { EmailsScheduleCard } from "./components/emailsScheduleCard/EmailsScheduleCard";

type LocationState = { routeSignature: string };

export const RouteLineItems = () => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const params = useParams<{ routeId: string }>();
  const location = useLocation<LocationState>();
  const routeSignature = location.state?.routeSignature;
  const search = queryString.stringify({ ...query, route: params.routeId });
  const {
    data: routeLineItems,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = externalManufacturingActions.useRouteLineItems(search);
  const { data: routeDetails, isLoading: isRouteDetailsLoading } = useLogisticsRouteSimplified({
    id: params.routeId,
  });
  const [highlightedRows, setHighlightedRows] = useState<HighlightedRow[]>([]);
  const columns = useRouteLineItemsColumns(routeDetails);

  const notificationForNewMutation = externalManufacturingActions.useAskForSingleProductionItemsStatusFiltered();
  const notificationForAllMutation = externalManufacturingActions.useAskForSingleProductionItemsStatusFiltered();
  const askForSingleProductionItemsStatusReplyModal = useStateModal<{
    preview: UnsentEmailPreview;
  }>();
  const emailScheduleCard = useToggle(true);

  return (
    <PageWrapper>
      <PageHeader
        bottomBarButtons={
          <div className="d-flex align-items-center px-0 gap-1">
            <Button
              className="text-uppercase"
              isLoading={notificationForNewMutation.isLoading}
              onClick={() =>
                notificationForNewMutation.mutate(
                  {
                    search: queryString.stringify({
                      scheduleEmailStatus: ScheduleEmailStatus.NOT_SENT,
                    }),
                    data: {
                      route: Number(params.routeId),
                      notificationType: NotificationType.ASK_FOR_STATUS,
                    },
                  },
                  {
                    onSuccess: payload => {
                      askForSingleProductionItemsStatusReplyModal.open({
                        preview: payload,
                      });
                    },
                  },
                )
              }
              size="small"
              startIcon={MdiNewReleases}
              variant="gray"
            >
              Wyślij zapytanie: o nowo dodane
            </Button>
            <Button
              className="text-uppercase"
              isLoading={notificationForAllMutation.isLoading}
              onClick={() =>
                notificationForAllMutation.mutate(
                  {
                    search: "",
                    data: {
                      route: Number(params.routeId),
                      notificationType: NotificationType.ASK_FOR_STATUS,
                    },
                  },
                  {
                    onSuccess: payload => {
                      askForSingleProductionItemsStatusReplyModal.open({
                        preview: payload,
                      });
                    },
                  },
                )
              }
              size="small"
              startIcon={MdiContactSupport}
              variant="gray"
            >
              Wyślij zapytanie: o wszystkie
            </Button>
          </div>
        }
        title={
          <div className="d-flex align-items-center">
            <Typography fontSize="20" fontWeight="700">
              Zlecenia na trasę {routeSignature ?? routeDetails?.signature}
            </Typography>
            {routeDetails && routeDetails.loadingDate && (
              <div className="d-flex align-items-center gap-1 mr-2 ml-3">
                <Typography color="neutralBlack88" fontSize="12" fontWeight="400">
                  data załadunku:
                </Typography>
                <Typography color="neutralBlack88" fontSize="12" fontWeight="700">
                  {getStandardDateFormat(routeDetails.loadingDate)}
                </Typography>
              </div>
            )}
            {routeDetails && routeDetails.departureDate && (
              <div className="d-flex align-items-center gap-1">
                <Typography color="neutralBlack88" fontSize="12" fontWeight="400">
                  data wyjazdu:
                </Typography>
                <Typography color="neutralBlack88" fontSize="12" fontWeight="700">
                  {getStandardDateFormat(routeDetails.departureDate)}
                </Typography>
              </div>
            )}
          </div>
        }
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        viewLabel="LOGISTICS_ROUTE_LINE_ITEMS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between position-relative">
        <DrawerHelpers name="logisticsRouteLineItems">
          {drawerHelpers => (
            <Table<RouteLineItem>
              rows={routeLineItems}
              columns={columns}
              onPaginationChange={paginationState => {
                updateQuery({ ...query, page: paginationState.pageIndex });
              }}
              isLoading={isLoading || isPreviousData || isRouteDetailsLoading}
              error={error}
              pagination={pagination}
              uiSchema={mainListUiSchema}
              onRowClick={(rowId, e) => {
                const index = routeLineItems.findIndex(e => e.id === rowId);
                handleMultipleRowsHighlight(
                  e,
                  highlightedRows,
                  rowId,
                  index,
                  setHighlightedRows,
                  () => drawerHelpers.togglePanel(rowId),
                  routeLineItems,
                );
              }}
              overrides={() => {
                return {
                  row: row => {
                    const className = getTablePropsBasedOnDrawerHelpers(drawerHelpers).overrides;

                    if (highlightedRows.find(r => r.id === row.id)) {
                      return {
                        className: styles.selected,
                      };
                    }
                    if (
                      row.scheduleConfirmationStatus === ScheduleConfirmationStatus.REJECTED &&
                      className
                    ) {
                      return {
                        className: cx(styles.rejected, className().row?.(row).className),
                      };
                    }
                    if (
                      row.scheduleConfirmationStatus === ScheduleConfirmationStatus.CONFIRMED &&
                      className
                    ) {
                      return {
                        className: cx(styles.confirmed, className().row?.(row).className),
                      };
                    }
                    if (className)
                      return {
                        className: className().row?.(row).className,
                      };
                    return getTablePropsBasedOnDrawerHelpers(drawerHelpers)?.overrides!().row?.(
                      row,
                    )!;
                  },
                };
              }}
            />
          )}
        </DrawerHelpers>
        <TableToolbarActions
          highlightedRows={highlightedRows}
          setHighlightedRows={setHighlightedRows}
        />
      </div>
      {askForSingleProductionItemsStatusReplyModal.isOpen &&
        askForSingleProductionItemsStatusReplyModal.state && (
          <FailedResponseModal
            close={askForSingleProductionItemsStatusReplyModal.close}
            data={{
              success: {
                message:
                  getSentEmailsNumber(askForSingleProductionItemsStatusReplyModal.state.preview) !==
                  0
                    ? `Wysłano ${getSentEmailsNumber(
                        askForSingleProductionItemsStatusReplyModal.state.preview,
                      )} ${pluralize.pl(
                        getSentEmailsNumber(
                          askForSingleProductionItemsStatusReplyModal.state.preview,
                        ),
                        {
                          singular: "mail",
                          plural: "maile",
                          other: "maili",
                        },
                      )} z prośbą o potwierdzenie`
                    : "Brak pomyślnie wysłanych maili",
              },
              failed: {
                message:
                  getUnsentEmailsNumber(
                    askForSingleProductionItemsStatusReplyModal.state.preview,
                  ) !== 0
                    ? `Nie wysłano ${getUnsentEmailsNumber(
                        askForSingleProductionItemsStatusReplyModal.state.preview,
                      )} ${pluralize.pl(
                        getUnsentEmailsNumber(
                          askForSingleProductionItemsStatusReplyModal.state.preview,
                        ),
                        {
                          singular: "maila",
                          plural: "maili",
                          other: "maili",
                        },
                      )}:`
                    : "Brak niewysłanych maili",
                reasons: Object.values(
                  askForSingleProductionItemsStatusReplyModal.state.preview.unsentEmails,
                ).map(reason => ({
                  message: reason.message,
                  signatures: reason.objects.map(object => object.signature),
                })),
              },
            }}
            title="Status wysyłki maili z prośbą o potwierdzenie"
          />
        )}
      <EmailsScheduleCard
        close={emailScheduleCard.close}
        isEmailScheduleOpened={emailScheduleCard.isOpen}
      />
    </PageWrapper>
  );
};

const getUnsentEmailsNumber = (preview: UnsentEmailPreview) => {
  return Object.values(preview.unsentEmails).reduce((acc, values) => {
    acc += values.objects.length;
    return acc;
  }, 0);
};

const getSentEmailsNumber = (preview: UnsentEmailPreview) => {
  return preview.sentEmails.objects.length;
};

import { graphhopper } from "api/graphhopper/graphhopper";
import { Route } from "api/routes/models";
import { AsyncInput } from "components/utils";
import { useToastr } from "hooks";
import { useRoutePutMutation } from "hooks/apiPrimitives";
import { useEffect, useState } from "react";
import styles from "./RouteSpeed.module.css";
import { useRouteViewState } from "pages/routes/creator/routeCreatorState";
import {
  getFullRouteCoords,
  getOrdersPositionsBasedOnGraphhopper,
  getPoints,
} from "pages/routes/creator/utils";

export const RouteSpeed = ({ route }: { route: Route }) => {
  const putMutation = useRoutePutMutation();
  const actions = useRouteViewState("slave", state => state.actions);
  const isLoading = useRouteViewState("slave", state => state.isLoading);

  const [value, setValue] = useState(route.averageSpeed);
  useEffect(() => {
    setValue(route.averageSpeed);
  }, [route.averageSpeed]);

  const toastr = useToastr();

  const handleUpdate = async (averageSpeed: string | null) => {
    const startingPointLngLat = [route.startingPoint.point.lng, route.startingPoint.point.lat];
    actions.openLoader("Trwa zmiana średniej prędkości");
    const newPoints = getPoints(route.ordersPositions);

    const points = getFullRouteCoords(route, newPoints, startingPointLngLat);

    const payload = await graphhopper.route({
      points,
      vehicle: route.vehicleType,
      includeLastPointInOptimization: route.includeLastPointInOptimization,
    });

    if (payload) {
      const returnToStartingPointDistance = String(payload.points[newPoints.length].distance);
      const returnToStartingPointTime = String(payload.points[newPoints.length].time);

      const ordersPositions = getOrdersPositionsBasedOnGraphhopper(
        route.ordersPositions,
        payload.points,
      );

      putMutation.mutate({
        data: {
          length: payload.distance,
          operation: null,
          returnToStartingPointDistance,
          averageSpeed,
          returnToStartingPointTime,
          ordersPositions,
          shouldCalculateAverageSpeed: true,
        },
        route: route.id,
      });
    } else {
      actions.closeLoader();
      toastr.open({
        type: "failure",
        title: "Nie udało się zmienić typu pojazdu",
        text: "",
      });
    }
  };

  return (
    <AsyncInput
      look="common"
      label="Średnia prędkość (km/h)"
      type="number"
      inProgress={putMutation.isLoading}
      disabled={putMutation.isLoading || isLoading}
      onChange={el => setValue(el)}
      onBlur={el => handleUpdate(el.target.value || null)}
      value={value ? String(value) : ""}
      placeholder="Automatycznie"
      overwrites={{
        input: { className: styles.input },
        floatingLabel: { className: "fs-10" },
      }}
    />
  );
};

import { routeActions } from "api/routes/actions";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CommonError } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useDeliveryPointsLinksColumns } from "./useDeliveryPointsLinksColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import { RouteDeliveryPointsLinks } from "api/routes/models";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";

interface Props {
  close: () => void;
  route: number;
}

export const DeliveryPointsLinksModal = ({ close, route }: Props) => {
  const { data: links, error, isLoading } = routeActions.useRouteDeliveryPointsLinks(route);
  const columns = useDeliveryPointsLinksColumns();

  if (isLoading) return null;

  if (error)
    return (
      <Modal
        close={close}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Linki do mapy dla punktów dostawy trasy
          </Typography>
        }
        width={700}
      >
        <div className="p-4">
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  assertIsDefined(links);

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Linki do mapy dla punktów dostawy trasy
        </Typography>
      }
      width={700}
    >
      <div className="p-3">
        <Table<RouteDeliveryPointsLinks>
          rows={links}
          columns={columns}
          isLoading={isLoading}
          error={error}
          uiSchema={comfortableListUiSchema}
        />
      </div>
    </Modal>
  );
};

import { Modal, StatusHandler, StatusHandlerHelpers } from "components/utils";
import localStyles from "./ConfirmationDateModal.module.css";
import cx from "classnames";
import { Button } from "components/common";
import { useState } from "react";
import { ConfirmationDateOptions, DeadlineDayOptions } from "api/milo-settings/models";
import { PostDeadline } from "api/routes/models";
import { AddCustomOption } from "./AddCustomOption";
import { getIndicativeDate } from "./utils";

interface Props {
  close: () => void;
  handleSendSms: (
    message: string | null,
    helpers: StatusHandlerHelpers,
    force: {
      resendSms: boolean;
      sendSms: boolean;
      resendUnconfirmed: boolean;
    },
    confirmationDeadline?: PostDeadline | null,
  ) => Promise<void>;
  options: ConfirmationDateOptions[];
}

const dayOptions: Record<DeadlineDayOptions, string> = {
  TODAY: "Dzisiaj",
  TOMORROW: "Jutro",
  AFTER_TOMORROW: "Pojutrze",
  NEAREST_MONDAY: "Następny poniedziałek",
  NEAREST_FRIDAY: "Następny piątek",
};

export const ConfirmationDateModal = ({ close, handleSendSms, options }: Props) => {
  const [allOptions, setAllOptions] = useState(options);
  const [dayOption, setDayOption] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [picked, setPicked] = useState<ConfirmationDateOptions | null>(null);
  const [time, setTime] = useState("");

  return (
    <Modal close={close} isOpen={true} overrides={{ container: { className: localStyles.modal } }}>
      <div>
        <div className={cx(localStyles.title, "mt-2 mb-4")}>
          Do kiedy możliwe będzie potwierdzenie zamówienia?
        </div>

        <div className="text-color-grey mb-3">Wybierz jedną z opcji lub dodaj własną:</div>

        <div className={cx(localStyles.optionBox, "mb-4")}>
          {allOptions.length > 0 &&
            allOptions.map(option => {
              return (
                <div
                  className={cx(
                    localStyles.option,
                    "d-flex align-items-center justify-content-center mb-2",
                    {
                      [localStyles.optionActive]: option.id === picked?.id,
                    },
                  )}
                  key={option.id}
                  onClick={() => {
                    if (option.id === picked?.id) {
                      setPicked(null);
                    } else {
                      setPicked(option);
                    }
                  }}
                >
                  <span>
                    {dayOptions[option.deadlineDayOption]}
                    <span className="text-color-grey fs-14">
                      &nbsp;({getIndicativeDate(option.deadlineDayOption)})
                    </span>
                  </span>
                  <i className={localStyles.divider}></i>
                  <span>{option.deadlineTime.slice(0, 5)}</span>
                </div>
              );
            })}
        </div>

        <AddCustomOption
          allOptions={allOptions}
          dayOption={dayOption}
          dayOptions={dayOptions}
          isOpen={isOpen}
          setAllOptions={setAllOptions}
          setDayOption={setDayOption}
          setIsOpen={setIsOpen}
          setPicked={setPicked}
          setTime={setTime}
          time={time}
        />

        <div className={cx(localStyles.buttonBox, "d-flex align-items-center")}>
          <StatusHandler>
            {helpers => (
              <Button
                kind="secondary-grey"
                onClick={() => {
                  handleSendSms(null, helpers, {
                    resendSms: false,
                    sendSms: false,
                    resendUnconfirmed: false,
                  });
                }}
              >
                Pomiń i wyślij SMS
              </Button>
            )}
          </StatusHandler>
          <StatusHandler>
            {helpers => (
              <Button
                disabled={helpers.isFetching || picked === null}
                kind="primary"
                onClick={() => {
                  if (picked) {
                    handleSendSms(
                      null,
                      helpers,
                      {
                        resendSms: false,
                        sendSms: false,
                        resendUnconfirmed: false,
                      },
                      { option: picked.deadlineDayOption, time: picked.deadlineTime.slice(0, 5) },
                    );
                  }
                }}
              >
                Wyślij SMS z datą potwierdzenia
              </Button>
            )}
          </StatusHandler>
        </div>
      </div>
    </Modal>
  );
};

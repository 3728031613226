import { Route, RouteSynchronize } from "api/routes/models";
import { DrawerContent, DrawerRight } from "components/utils/drawer";
import styles from "../RightPanel.module.css";
import { routeActions } from "api/routes/actions";
import { getAnyErrorKey } from "utilities";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useSynchronizeRouteColumns } from "./useSynchronizeRouteColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";

interface Props {
  route: Route;
}

export const SynchronizeRoute = ({ route }: Props) => {
  const { data: orders, error, isLoading } = routeActions.useRouteSynchronizeDetails(route.id);
  const synchronizeRouteMutation = routeActions.useRouteSynchronize();
  const columns = useSynchronizeRouteColumns();

  if (error) {
    return (
      <DrawerRight className={styles.drawer}>
        <CommonError text={getAnyErrorKey(error)} status={error.status} />;
      </DrawerRight>
    );
  }

  if (!orders) {
    return (
      <DrawerRight className={styles.drawer}>
        <MockupLoader on={isLoading} type="drawer" />
      </DrawerRight>
    );
  }

  return (
    <DrawerRight key={route.id} className={styles.drawer}>
      <DrawerContent>
        <DisabledOpacity disabled={isLoading}>
          <div className={styles.unsynchronizedCard}>
            <div className={styles.unsynchronizedCardDetails}>
              <Typography
                className={styles.cabinFont}
                color="danger600"
                fontSize="20"
                fontWeight="700"
              >
                Niespójność między zamówieniami i punktami trasy
              </Typography>
            </div>
            <div className="py-2 px-2">
              <Table<RouteSynchronize>
                rows={orders}
                columns={columns}
                isLoading={isLoading}
                error={error}
                uiSchema={comfortableListUiSchema}
              />
            </div>
            <div className="d-flex align-items-center gap-2 px-4 py-2">
              <Button
                className="text-uppercase"
                onClick={() => synchronizeRouteMutation.mutate(route.id)}
                size="small"
                variant="deepPurple"
              >
                Synchronizuj
              </Button>
              <Typography color="neutralBlack48" fontSize="14" fontWeight="400" noWrap>
                Kliknij, by przywrócić {orders.length > 1 ? "zamówienia" : "zamówienie"} do puli
              </Typography>
              {synchronizeRouteMutation.isLoading && <Spinner size={16} />}
            </div>
          </div>
        </DisabledOpacity>
      </DrawerContent>
    </DrawerRight>
  );
};

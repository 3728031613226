import { postInvoiceAdmissionPackage } from "api/trading-documents/calls";
import { useInvoiceAdmission } from "api/trading-documents/hooks";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import {
  AssignedWhEntry,
  AssignPackageToWhEntryAggregate,
  InvoiceAdmissionLinkLine,
} from "api/trading-documents/models";
import { Pagination, UUID } from "api/types";
import { CommonError, Spinner } from "components/utils";
import cuid from "cuid";
import { useQuery, useToastr } from "hooks";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { QueryClient, useQueryClient } from "react-query";
import { RouteComponentProps, useParams } from "react-router";
import { getAnyErrorKey, queryString } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { InvoiceSection } from "./components/invoiceSection/InvoiceSection";
import { ListHeader } from "./components/ListHeader";
import { ReceptionsSection } from "./components/receptionsSection/ReceptionsSection";
import styles from "./ConnectInvoice.module.css";

export interface UrlParams {
  tradingDocumentId: UUID;
}

interface AddPackageProps {
  destinationId: UUID;
  sourceId: UUID;
}

export const ConnectInvoice = (props: RouteComponentProps<UrlParams>) => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const { tradingDocumentId } = useParams<UrlParams>();
  const queryClient = useQueryClient();
  const toastr = useToastr();

  const { data: invoiceAdmission, error, isLoading } = useInvoiceAdmission(
    { id: tradingDocumentId },
    { enabled: Boolean(tradingDocumentId) },
  );

  const whEntrySearch = invoiceAdmission
    ? queryString.stringify({
        invoiceAdmissionLink: invoiceAdmission.id,
        pageSize: 999,
      })
    : "";

  const linkLineSearch = invoiceAdmission
    ? queryString.stringify({
        ...query,
        tradingDocument: invoiceAdmission.tradingDocument,
      })
    : "";

  const handleRollback = (context: unknown, queryClient: QueryClient) => {
    queryClient.setQueryData<Pagination<InvoiceAdmissionLinkLine>>(
      tradingDocumentsKeys.invoiceAdmission.linkLines(linkLineSearch),
      currentList => {
        assertIsDefined(currentList);
        return {
          ...currentList,
          // @ts-ignore
          results: context.results,
        };
      },
    );
  };

  const addPackageOptions = useMutationOptions(
    ({ sourceId, destinationId }: AddPackageProps) => {
      const [packageId, whEntryId] = sourceId.split(";");
      const initialRequestData: AssignPackageToWhEntryAggregate = {
        whEntryAggregateItem: packageId,
        name: "",
        package: 0,
        quantity: 1,
        amount: "0.00",
        item: destinationId,
      };
      const whEntries = queryClient.getQueryData<Pagination<AssignedWhEntry>>(
        tradingDocumentsKeys.invoiceAdmission.assignedWhEntries(whEntrySearch),
      );

      if (whEntries) {
        const whEntry = whEntries.results.find(whEntry => whEntry.id === whEntryId);
        const whEntryPackage = whEntry?.items.find(_package => _package.id === packageId);

        if (whEntryPackage) {
          return postInvoiceAdmissionPackage({
            ...initialRequestData,
            name: whEntryPackage.name,
            package: whEntryPackage.package,
          });
        }
      }
      return postInvoiceAdmissionPackage(initialRequestData);
    },
    ({ queryClient, toastr }) => ({
      onMutate: args => {
        const [packageId, whEntryId] = args.sourceId.split(";");
        const whEntries = queryClient.getQueryData<Pagination<AssignedWhEntry>>(
          tradingDocumentsKeys.invoiceAdmission.assignedWhEntries(whEntrySearch),
        );
        const prevList = queryClient.getQueryData<Pagination<InvoiceAdmissionLinkLine>>(
          tradingDocumentsKeys.invoiceAdmission.linkLines(linkLineSearch),
        );
        const temporaryPackageId = cuid();

        if (whEntries) {
          const whEntry = whEntries.results.find(whEntry => whEntry.id === whEntryId);
          const whEntryPackage = whEntry?.items.find(_package => _package.id === packageId);

          if (whEntryPackage) {
            queryClient.setQueryData<Pagination<InvoiceAdmissionLinkLine>>(
              tradingDocumentsKeys.invoiceAdmission.linkLines(linkLineSearch),
              currentList => {
                assertIsDefined(currentList);
                return {
                  ...currentList,
                  results: currentList.results.map(_linkLine => {
                    if (_linkLine.id === args.destinationId) {
                      return {
                        ..._linkLine,
                        packages: [
                          ..._linkLine.packages,
                          {
                            amount: 0,
                            createdAt: "",
                            id: temporaryPackageId,
                            name: whEntryPackage.name,
                            package: whEntryPackage.package,
                            quantity: 0,
                          },
                        ],
                      };
                    }
                    return _linkLine;
                  }),
                };
              },
            );
          }
        }

        return { prevList, temporaryPackageId };
      },
      onSuccess: (payload, _, context: any) => {
        queryClient.setQueryData<Pagination<InvoiceAdmissionLinkLine>>(
          tradingDocumentsKeys.invoiceAdmission.linkLines(linkLineSearch),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(_linkLine => {
                if (_linkLine.id === payload.item) {
                  return {
                    ..._linkLine,
                    packages: _linkLine.packages.map(_package => {
                      if (_package.id === context.temporaryPackageId) {
                        return {
                          ..._package,
                          id: payload.id,
                          createdAt: payload.createdAt,
                        };
                      }
                      return _package;
                    }),
                  };
                }
                return _linkLine;
              }),
            };
          },
        );

        queryClient.setQueryData<Pagination<AssignedWhEntry>>(
          tradingDocumentsKeys.invoiceAdmission.assignedWhEntries(whEntrySearch),
          currentList => {
            assertIsDefined(currentList);
            return {
              ...currentList,
              results: currentList.results.map(_whEntry => {
                const whEntryPackage = _whEntry.items.find(
                  _package => _package.id === payload.itemUsed.id,
                );
                if (whEntryPackage !== undefined) {
                  return {
                    ..._whEntry,
                    items: _whEntry.items.map(_package => {
                      if (_package.id === whEntryPackage.id) {
                        return {
                          ..._package,
                          leftQuantity: payload.itemUsed.leftQuantity,
                          usedQuantity: payload.itemUsed.usedQuantity,
                        };
                      }
                      return _package;
                    }),
                  };
                }
                return _whEntry;
              }),
            };
          },
        );

        queryClient.invalidateQueries(
          tradingDocumentsKeys.invoiceAdmission.linkLines(linkLineSearch),
        );
      },
      onError: (error, _, context: any) => {
        handleRollback(context.prevList, queryClient);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const addPackageMutation = useMutation(addPackageOptions.mutationFn, addPackageOptions);

  const handleObjectDropped = (result: DropResult): void => {
    if (!result.destination) return;

    if (
      !addPackageMutation.isLoading &&
      result.destination &&
      result.source &&
      result.destination.droppableId !== result.draggableId
    ) {
      const [packageId] = result.draggableId.split(";");

      const linkLines = queryClient.getQueryData<Pagination<InvoiceAdmissionLinkLine>>(
        tradingDocumentsKeys.invoiceAdmission.linkLines(linkLineSearch),
      );

      const linkLine = linkLines?.results.find(
        linkLine => linkLine.id === result.destination?.droppableId,
      );

      // Fix this condition - ID's will not be the same on both sides
      // use 'package' or demand new field to be serialized
      const linkLinePackage = linkLine?.packages.find(_package => _package.id === packageId);

      if (linkLinePackage !== undefined) {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: "Ta paczka już została dodana",
        });
        return;
      } else {
        addPackageMutation.mutate({
          sourceId: result.draggableId,
          destinationId: result.destination.droppableId,
        });
      }
    }
  };

  if (!invoiceAdmission) return null;

  if (error) {
    return (
      <div>
        <ListHeader invoiceAdmission={invoiceAdmission} />
        <div className="mt-4">
          <CommonError status={error._httpStatus_} />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <ListHeader invoiceAdmission={invoiceAdmission} />
        <div className="mt-4">
          <Spinner
            color="blue"
            position="absolute"
            size="big"
            text="trwa wczytywanie danych faktury"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="position-relative h-100">
      <ListHeader invoiceAdmission={invoiceAdmission} />
      <div className={styles.mainContent}>
        <div className={styles.mainContentInner}>
          <DragDropContext onDragEnd={handleObjectDropped}>
            <InvoiceSection invoiceAdmission={invoiceAdmission} />
            <ReceptionsSection invoiceAdmission={invoiceAdmission} />
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

import styles from "./LocationFiltersBar.module.css";

interface Props {
  img: string;
  updateCheckbox: (val: boolean) => void;
  isChecked: boolean;
}
export const TimeGroupBtn = ({ isChecked, updateCheckbox, img }: Props) => {
  return (
    <label className={styles["version-box-option"]}>
      <input
        type="checkbox"
        name="points"
        checked={isChecked}
        onChange={e => updateCheckbox(e.target.checked)}
      />
      <img src={img} alt="" />
    </label>
  );
};

import deepMergeLib from "deepmerge";
import { DeepPartial } from "utility-types";

/**
 * Deeply merges objects.
 * @example
 * deepMerge({}, {a: {b: 1}}, {a: {c: 2}})
 * // {a: {b: 1, c: 2}}
 */
export function deepMerge<T extends object>(...objects: DeepPartial<T>[]): T {
  const overwriteMerge = (destinationArray: any[], sourceArray: any[]) => sourceArray;
  return deepMergeLib.all([{}, ...objects], { arrayMerge: overwriteMerge }) as T;
}

import styles from "./ProgressBar.module.css";
import { ProgressBarProps } from "./types";

export const ProgressBar = ({ maxWidth = 100, progress }: ProgressBarProps) => {
  return (
    <div className={styles.bar} style={{ maxWidth }}>
      <div className={styles.progress} style={{ width: `${progress}%` }} />
    </div>
  );
};

import { useEffect, useReducer, Reducer, useRef, useState, useMemo, useCallback } from "react";
import { tuplify, getPagination } from "utilities";
import cuid from "cuid";
import {
  InferPaginationItem,
  InferPaginationCounterName,
  InferArgsType,
  InferResult,
} from "typeUtilities";

type Counter<Name extends string> = { salesAccountId: number } & { [K in Name]: number };

type ExtendedAction = { type: string; payload?: any };
type Action<A extends ExtendedAction> =
  | { type: "SAVE"; payload: any }
  | { type: "CLEAR"; payload?: undefined }
  | A;
export type PaginationType = ReturnType<typeof getPagination>;

type CounterComparator<A, B> = A extends string ? B[] : null;
type Meta = {
  error: {
    [key: string]: string;
  } | null;
  inProgress: boolean;
  httpStatus: number | undefined;
};

interface Settings<T extends any, EA> {
  /** Define if refetch should be skipped; default false. */
  skip?: boolean;
  /** Define if result should be cleared when skipped; default false. */
  clearSkip?: boolean;
  /** Define if result should be cached; default false. */
  cache?: boolean;
  reducer?: Reducer<T, EA>;
}

function mapObjectAtob<T extends Record<string, any>, U extends Partial<T>>(
  keySource: U,
  valueSource: T,
): T {
  const res: Partial<T> = {};
  (Object.keys(keySource) as (keyof T)[]).forEach(key => {
    res[key] = valueSource[key];
  });
  return res as T;
}

function createReducer<T, EA extends ExtendedAction>(extended?: Reducer<T, EA>) {
  return function reducer(state: T, action: Action<EA>) {
    switch (action.type) {
      case "SAVE":
        return action.payload;
      case "CLEAR":
        return null;
      default:
        return extended && state ? extended(state, action as EA) : state;
    }
  };
}
export function createPrimitiveHook<T extends (...args: any[]) => Promise<any>>(func: T) {
  function useHook<EA extends ExtendedAction>(
    ...allParams: [...InferArgsType<T>, Settings<InferResult<T>, EA>]
  ) {
    const [settings] = allParams.slice(-1) as [Settings<InferResult<T>, EA>];
    const params = allParams.slice(0, -1) as [InferArgsType<T>];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoParams = useMemo(() => params, params);

    // const cache = useRef<Record<NonNullable<TSearch>, any>>({} as any);
    // const lastSearch = useRef<NonNullable<TSearch>>("" as any);
    const initialState: InferResult<T> | null = null;
    const abortToken = useRef(cuid());
    const isMounted = useRef(true);

    const [refetchTrigger, setRefetchTrigger] = useState(0);

    const triggerFetch = useCallback(() => {
      setRefetchTrigger(s => s + 1);
      // if (search) {
      //   delete cache.current[search];
      // }
    }, []);

    const skip = settings.skip ?? false;
    const clearSkip = settings.clearSkip ?? false;
    const cacheEnabled = settings.cache ?? false;
    const extendedReducer = settings.reducer;

    const reducer = useMemo(() => createReducer<InferResult<T>, EA>(extendedReducer), [
      extendedReducer,
    ]);
    // const [result_, mainDispatch_] = useReducer(reducer, initialState);

    const [result, setState] = useState<InferResult<T> | null>(initialState);
    const mainDispatch = useCallback(
      (action: Action<EA>) => {
        setState(s => reducer(s!, action));
      },
      [setState, reducer],
    );

    const [meta, setMeta] = useState<Meta>({
      error: null,
      inProgress: false,
      httpStatus: undefined,
    });
    const updateMeta = useCallback((args: Partial<Meta>) => setMeta(s => ({ ...s, ...args })), []);

    const dispatch = useCallback(
      (arg: Action<EA>) => {
        if (!isMounted.current) return;
        mainDispatch(arg);
      },
      [mainDispatch],
    ) as typeof mainDispatch;

    const publicDispatch = useCallback(
      (arg: Action<EA>) => {
        if (!isMounted.current) return;
        // delete cache.current[lastSearch.current];
        mainDispatch(arg);
      },
      [mainDispatch],
    ) as typeof mainDispatch;

    const update = useCallback(
      (
        toUpdate:
          | Partial<NonNullable<InferResult<T>>>
          | ((s: NonNullable<InferResult<T>>) => NonNullable<InferResult<T>>),
      ) => {
        // delete cache[key];
        let prevData: InferResult<T> | null;

        if (typeof toUpdate === "function") {
          setState(s => {
            const newData = toUpdate(s!);
            prevData = newData ? mapObjectAtob(newData, s!) : null;

            return newData;
          });
        } else {
          setState(s => {
            prevData = mapObjectAtob({ ...s!, ...toUpdate }, s!);
            return { ...s!, ...toUpdate };
          });
        }
        //rollback
        return () => setState(s => prevData);
      },
      [],
    );

    // const [linkedGetResult, { inProgress, error }] = useAsyncStatus(func);
    useEffect(() => {
      if (skip) {
        if (clearSkip) {
          dispatch({ type: "SAVE", payload: null });
        }
        return;
      }
      async function fetchFunc() {
        // lastSearch.current = param;
        // if (cacheEnabled && cache.current[param]) {
        //   dispatch({ type: "SAVE", payload: cache.current[param] });
        //   return;
        // }
        updateMeta({ inProgress: true });
        const [payload, error, { status }] = await func(...memoParams, abortToken.current);
        if (!isMounted.current) return;

        if (payload) {
          // if (cacheEnabled) {
          //   cache.current[param] = payload;
          // }
          updateMeta({ inProgress: false, error: null, httpStatus: status });
          dispatch({ type: "SAVE", payload });
        } else if (error) {
          updateMeta({ inProgress: false, error, httpStatus: status });
        }
      }
      if (!skip) {
        fetchFunc();
      }
    }, [skip, clearSkip, memoParams, dispatch, cacheEnabled, updateMeta, refetchTrigger]);

    // const invalidateCurrentCache = useCallback(() => {
    //   delete cache.current[lastSearch.current];
    // }, []);

    useEffect(() => {
      return () => {
        isMounted.current = false;
      };
    }, []);

    const res = clearSkip && skip ? null : (result as InferResult<T> | null);
    return tuplify(res, {
      inProgress: meta.inProgress,
      error: meta.error,
      httpStatus: meta.httpStatus,
      dispatch: publicDispatch,
      triggerFetch,
      update,
      // invalidateCurrentCache,
    });
  }
  return useHook;
}

export function createPrimitivePaginatedHook<
  T extends (...args: any[]) => Promise<any>,
  Name extends InferPaginationCounterName<InferResult<T>>,
  Item extends InferPaginationItem<InferResult<T>>
>(func: T) {
  function useHook<EA extends ExtendedAction = any>(
    ...allParams: [...InferArgsType<T>, Settings<InferResult<T>["results"], EA>]
  ) {
    const [settings] = allParams.slice(-1) as [Settings<InferResult<T>["results"], EA>];
    const params = allParams.slice(0, -1) as [InferArgsType<T>];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoParams = useMemo(() => params, params);
    const extendedReducer = settings.reducer;

    const cache = useRef<Record<string, any>>({});
    const lastSearch = useRef("");
    const initialState: InferResult<T>["results"] = [];
    const abortToken = useRef(cuid());
    const isMounted = useRef(true);
    const [pagination, setPagination] = useState<PaginationType>(getPagination(null));
    const [counters, setCounters] = useState<CounterComparator<Name, Counter<Name>>>();
    const [meta, setMeta] = useState<Meta>({
      error: null,
      inProgress: false,
      httpStatus: undefined,
    });
    const updateMeta = useCallback((args: Partial<Meta>) => {
      if (isMounted.current) {
        setMeta(s => ({ ...s, ...args }));
      }
    }, []);
    const isPristine = useRef<boolean>(true);

    const skip = settings.skip ?? false;
    const clearSkip = settings.clearSkip ?? false;
    const cacheEnabled = settings.cache ?? false;

    const reducer = useMemo(() => createReducer<Item[], EA>(extendedReducer), [extendedReducer]);
    const [result, mainDispatch] = useReducer(reducer, initialState);

    const dispatch = useCallback((arg: Action<EA>) => {
      if (!isMounted.current) return;
      mainDispatch(arg);
    }, []) as typeof mainDispatch;

    const publicDispatch = useCallback((arg: Action<EA>) => {
      if (!isMounted.current) return;
      delete cache.current[lastSearch.current];
      mainDispatch(arg);
    }, []) as typeof mainDispatch;

    const [refetchTrigger, setRefetchTrigger] = useState(0);

    const triggerFetch = useCallback(() => {
      setRefetchTrigger(s => s + 1);
      // if (search) {
      //   delete cache.current[search];
      // }
    }, []);

    useEffect(() => {
      if (skip) {
        if (clearSkip) {
          dispatch({ type: "SAVE", payload: [] });
          setPagination(getPagination(null));
        }
        return;
      }
      async function fetchFunc() {
        // lastSearch.current = search;
        if (clearSkip) {
          dispatch({ type: "SAVE", payload: [] });
        }
        // if (cacheEnabled && cache.current[search]) {
        //   dispatch({ type: "SAVE", payload: cache.current[search].results });
        //   setPagination(getPagination(cache.current[search]));
        //   return;
        // }
        updateMeta({ inProgress: true });

        const [payload, error, { status }] = await func(...memoParams, abortToken.current);
        if (!isMounted.current) return;

        isPristine.current = false;
        if (payload) {
          // if (cacheEnabled) {
          //   cache.current[search] = payload;
          // }
          updateMeta({ inProgress: false, error: null, httpStatus: status });
          // @ts-ignore
          dispatch({ type: "SAVE", payload: payload.results });
          setPagination(getPagination(payload));
          // @ts-ignore
          setCounters(payload.counters);
        } else if (error) {
          updateMeta({ inProgress: false, error, httpStatus: status });
        }
      }
      if (!skip) {
        fetchFunc();
      }
    }, [skip, memoParams, refetchTrigger, clearSkip, dispatch, cacheEnabled, updateMeta]);

    useEffect(() => {
      return () => {
        isMounted.current = false;
      };
    }, []);

    const res =
      clearSkip && skip ? ([] as InferResult<T>["results"]) : (result as InferResult<T>["results"]);
    return tuplify(res, {
      inProgress: meta.inProgress,
      error: meta.error,
      httpStatus: meta.httpStatus,
      dispatch: publicDispatch,
      pagination,
      counters,
      triggerFetch,
      isPristine: isPristine.current,
    });
  }
  return useHook;
}

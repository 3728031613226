import cx from "classnames";
import styles from "./FiltersDrawer.module.css";
import { DrawerSection } from "components/utils/drawer/Drawer";
import { Checkbox } from "components/utils";
import { useQuery } from "hooks";
import { ToolbarSearchMultipleFilter } from "../toolbar/types";

export const SearchMultipleFilter = ({
  filter,
  query,
}: {
  filter: ToolbarSearchMultipleFilter;
  query: Record<string, string>;
}) => {
  const { updateQuery } = useQuery();

  const handleSelectMultiple = (value: string) => {
    const currentIds = query[filter.name];
    if (currentIds) {
      const isNew = currentIds.split(",").includes(String(value));

      if (isNew) {
        const newList = currentIds
          .split(",")
          .filter(el => el !== value)
          .join(",");

        updateQuery({ [filter.name]: newList, page: 1 });
      } else {
        updateQuery({ [filter.name]: `${currentIds}${value},`, page: 1 });
      }
    } else {
      updateQuery({ [filter.name]: `${value},`, page: 1 });
    }
  };

  return (
    <DrawerSection title={filter.label}>
      <div className={cx(styles.customSection, "mb-2 pb-2")}>
        {filter.options.map(el => {
          return (
            <div className="mt-2" key={el.label + el.value}>
              <Checkbox
                checked={
                  query[filter.name] ? query[filter.name].split(",").includes(el.value) : false
                }
                onChange={e => handleSelectMultiple(el.value)}
                name=""
                label={el.label}
              />
            </div>
          );
        })}
      </div>
    </DrawerSection>
  );
};

import { useMutation } from "hooks/useMutation";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { deleteCarrier } from "./calls";
import { carriersKeys } from "./keys";
import { getAnyErrorKey } from "utilities";
import { Carrier } from "./models";
import { useRedux } from "hooks";

const useDeleteCarrier = (carrier: Carrier, close: () => void) => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  return withDeleteConfirmation(
    useMutation(deleteCarrier, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries(carriersKeys.carriers.list());
        refetchPartials();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto przewoźnika "${carrier.name}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć przewoźnika?",
  )();
};

export const carrierActions = {
  useDeleteCarrier,
};

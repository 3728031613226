import { Button } from "components/common";
import plusIcon from "assets/images/20c.svg";
import React from "react";
import { TimePicker } from "components/utils/timePicker";
import { Select } from "components/utils";
import localStyles from "./ConfirmationDateModal.module.css";
import { isReadyToPost } from "./utils";
import cuid from "cuid";
import { ConfirmationDateOptions, DeadlineDayOptions } from "api/milo-settings/models";

interface Props {
  allOptions: ConfirmationDateOptions[];
  dayOption: string;
  dayOptions: Record<DeadlineDayOptions, string>;
  isOpen: boolean;
  setAllOptions: React.Dispatch<React.SetStateAction<ConfirmationDateOptions[]>>;
  setDayOption: React.Dispatch<React.SetStateAction<string>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPicked: React.Dispatch<React.SetStateAction<ConfirmationDateOptions | null>>;
  setTime: React.Dispatch<React.SetStateAction<string>>;
  time: string;
}

export const AddCustomOption = ({
  allOptions,
  dayOption,
  dayOptions,
  isOpen,
  setAllOptions,
  setDayOption,
  setIsOpen,
  setPicked,
  setTime,
  time,
}: Props) => {
  return (
    <div className="mb-5">
      {!isOpen && (
        <Button
          kind="secondary-grey"
          onClick={() => {
            setIsOpen(true);
            setPicked(null);
          }}
          size="small"
        >
          <div className="d-flex align-items-center">
            <img alt="" className="mr-1" src={plusIcon} />
            <span>Dodaj własną opcję</span>
          </div>
        </Button>
      )}
      {isOpen && (
        <div className="d-flex align-items-center">
          <TimePicker onBlur={value => setTime(value)} value={time || ""} />
          <Select
            items={[
              { id: "TODAY", name: "Dzisiaj" },
              { id: "TOMORROW", name: "Jutro" },
              { id: "AFTER_TOMORROW", name: "Pojutrze" },
              { id: "NEAREST_MONDAY", name: "Najbliższy poniedziałek" },
              { id: "NEAREST_FRIDAY", name: "Najbliższy piątek" },
            ]}
            onChange={value => {
              if (value) {
                setDayOption(value.id);
              }
            }}
            overwrites={{ button: { className: localStyles.selectHeight } }}
            placeholder="Wybierz dzień"
            selectedItem={dayOption ?? null}
          />
          <Button
            className="ml-2 mb-1"
            disabled={isReadyToPost(dayOption, dayOptions, time)}
            kind="secondary-grey"
            onClick={() => {
              const customOption = { id: cuid(), deadlineTime: time, deadlineDayOption: dayOption };
              // @ts-ignore
              setAllOptions(prevOptions => {
                return [...prevOptions, customOption];
              });
              // @ts-ignore
              setPicked(customOption);
              setTime("");
              setDayOption("");
              setIsOpen(false);
            }}
            size="round-m"
          >
            <img alt="Dodaj" src={plusIcon} />
          </Button>
        </div>
      )}
    </div>
  );
};

import { createPaginatedKey } from "api/keys";

export const manufacturingNewKeys = {
  manufacturingPlans: createPaginatedKey("manufacturingPlans"),
  manufacturingPlan: (id: string) => ["manufacturingPlan", id],
  manufacturingSchemas: createPaginatedKey("manufacturingSchemas"),
  manufacturingSchema: (id: string) => ["manufacturingSchema", id],
  manufacturingStages: createPaginatedKey("manufacturingStages"),
  manufacturingStage: (id: string) => ["manufacturingStage", id],
  manufacturingEmployees: createPaginatedKey("manufacturingEmployees"),
  manufacturingEmployee: (id: string) => ["manufacturingEmployee", id],
};

import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader } from "components/utils/drawer";
import { useQuery, useStateModal } from "hooks";
import { Button } from "components/common";
import downloadPdfIcon from "assets/images/download_pdf.svg";
import hidePanelIcon from "assets/images/keyboardTab.svg";
import { DOMAIN } from "ENDPOINTS";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import cx from "classnames";
import { MoreInfoButton } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/MoreInfoButton";
import { CreateCorrectionModalState } from "api/trading-documents/models";
import { CreateCorrectionManuallyModal } from "pages/tradingDocuments/salesInvoicesLIst/rightPanel/components/createCorrectionManuallyModal/CreateCorrectionManuallyModal";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
}

export const PanelHeader = ({ close, isScrolledOver }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: receipt } = useTradingDocument({ id: panelId });
  const createCorrectionManuallyModal = useStateModal<CreateCorrectionModalState | null>();

  if (!receipt) return null;

  return (
    <>
      <RightPanelHeader
        className={cx({
          slidingPanelHeaderShadow: isScrolledOver,
          panelHeaderDuringSlide: isScrolledOver,
        })}
      >
        {isScrolledOver ? (
          <div className="slidingPanelHeader">
            <div className="slidingPanelHeaderContent d-flex align-items-center gap-2">
              <ConfirmCheckbox tradingDocument={receipt} />
              <div>Paragon&nbsp;{receipt.signature}</div>
            </div>
          </div>
        ) : (
          <ConfirmTradingDocument tradingDocument={receipt} />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          <Button
            kind="transparent-black"
            size="square-s"
            rel="noopener noreferrer"
            href={`${DOMAIN}${receipt.tradingDocumentPdf?.pdf}`}
            target="_blank"
            as="a"
            title="Podgląd PDF paragonu"
          >
            <div className="btnBase btnBaseSmall">
              <img alt="Pobierz PDF" src={downloadPdfIcon} />
            </div>
          </Button>
          <MoreInfoButton close={close} invoice={receipt} panelId={panelId} />
          <span className="divider line-divider" />
          <Button kind="transparent-black" onClick={close} size="square-s">
            <div className="btnBase btnBaseSmall">
              <img
                alt="Zamknij panel"
                src={hidePanelIcon}
                style={{ height: "16px", width: "16px" }}
              />
            </div>
          </Button>
        </div>
      </RightPanelHeader>
      <CreateCorrectionManuallyModal
        close={createCorrectionManuallyModal.close}
        isOpen={createCorrectionManuallyModal.isOpen}
        state={createCorrectionManuallyModal.state}
      />
    </>
  );
};

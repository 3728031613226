import {
  TradingDocumentNotification,
  tradingDocumentNotificationActionDict,
  tradingDocumentNotificationStatusDict,
} from "api/trading-documents/models";
import { RightPanelTableRow } from "components/utils/drawer";
import { notificationSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { dateFns } from "utilities";
import localStyles from "../NotificationSection.module.css";
import styles from "../../../../TradingDocumentsLists.module.css";
import cx from "classnames";
import { InitialsAvatar } from "components/utils/userAvatar/InitialsAvatar";

interface Props {
  notification: TradingDocumentNotification;
  position: number;
}

export const Notification = ({ notification, position }: Props) => {
  return (
    <RightPanelTableRow grid={notificationSectionListConfig.grid}>
      <div className="fw-700">{position}.</div>
      <div>
        <div className={localStyles.notificationAction}>
          {tradingDocumentNotificationActionDict[notification.action]}
        </div>
      </div>
      <div>
        <div>
          <div className={localStyles.date}>
            {notification.date ? dateFns.format(new Date(notification.date), "dd.MM.yyyy") : "--"}
          </div>
        </div>
      </div>
      <div className="fs-12 fw-700">{notification.recipient}</div>
      <div
        className={cx("fs-12 fw-700", {
          "text-teal-6": notification.status === "OPENED",
          "text-red-6": notification.status === "DECLINED",
          "text-teal-8": notification.status === "CLICKED_LINK",
          "text-deep-purple-5": notification.status === "SENT",
        })}
      >
        {tradingDocumentNotificationStatusDict[notification.status]}
      </div>
      <div>
        {notification.sentBy && (
          <div className="d-flex align-items-center gap-1">
            {notification.sentBy.avatar ? (
              <div className={cx(styles.avatar, styles.avatarSmall)}>
                <img alt="avatar" src={notification.sentBy.avatar} />
              </div>
            ) : (
              <InitialsAvatar
                className={cx(styles.avatar, styles.avatarSmall)}
                firstName={notification.sentBy.firstName}
                lastName={notification.sentBy.lastName}
                style={{ margin: 0 }}
              />
            )}
            <div className={styles.assignee}>
              {notification.sentBy.firstName} {notification.sentBy.lastName}
            </div>
          </div>
        )}
      </div>
    </RightPanelTableRow>
  );
};

import { Button, PageHeader } from "components/common";
import downloadFileIcon from "assets/images/downloadFile.svg";
import uploadFileIcon from "assets/images/uploadFile.svg";
import visibilityOffIcon from "assets/images/eye_off.svg";
import areaChartIcon from "assets/images/areaChart.svg";
import call_missed from "assets/images/call_missed.svg";
import darkPlusIcon from "assets/images/darkPlus.svg";
import cx from "classnames";
import styles from "../OrdersList.module.css";
import {
  callStatusFiltersToPick,
  smsConfirmedStatusFiltersToPick,
  smsSentStatusFiltersToPick,
} from "CONSTANTS";
import { FilterRoundMultiSelect } from "pages/callCenter/routeOrdersList/components/FilterRoundMultiSelect";
import { FilterSquareMultiSelect } from "pages/callCenter/routeOrdersList/components/FilterSquareMultiSelect";
import { FilterSquareSingleSelect } from "pages/callCenter/routeOrdersList/components/FilterSquareSingleSelect";

export const ListHeader = () => {
  return (
    <div>
      <PageHeader
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Importuj plik"
                  src={uploadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Pobierz plik"
                  src={downloadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <span className="line-divider lineDividerMargin" />
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Wyłącz widoczność"
                  src={visibilityOffIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img alt="Wykres" src={areaChartIcon} style={{ height: "16px", width: "16px" }} />
              </div>
            </Button>
          </div>
        }
        createButton={{
          alt: "Koperta",
          img: darkPlusIcon,
          label: "Utwórz zamówienie",
          onClick: () => {},
        }}
        searchInput={{
          label: "Szukaj wśród zamówień, klientów i tras",
          tags: [],
        }}
        bottomBarButtons={
          <div className="d-flex align-items-center px-0 gap-1 ">
            <FilterRoundMultiSelect
              name="callStatuses"
              options={callStatusFiltersToPick}
              multiple={true}
            />
            <div className={cx("line-divider", styles.divider)}></div>
            <FilterSquareMultiSelect
              name="smsStatus"
              options={smsSentStatusFiltersToPick}
              multiple={true}
            />
            <div className={cx("line-divider", styles.divider)}></div>
            <FilterSquareMultiSelect
              name="isDeliveryDateConfirmed"
              options={smsConfirmedStatusFiltersToPick}
              multiple={true}
            />
            <div className={cx("line-divider", styles.divider)}></div>
            <FilterSquareSingleSelect
              name="isDeliveryDateRejected"
              iconSrc={call_missed}
              label="odrzucone"
            />
          </div>
        }
        viewLabel="CALL_CENTER_ORDERS"
      />
    </div>
  );
};

import { createContext, useContext, memo, useState, useCallback, useMemo, useRef } from "react";
import * as React from "react";
import { Toastr } from "./Toastr";
import cuid from "cuid";
import styles from "./Toastr.module.css";
import { useRedux } from "hooks";

interface Props {
  children: React.ReactNode;
}

export interface ToastSubscription {
  id: string;
  title: string;
  text: string | React.ReactElement;
  type: "success" | "failure" | "neutral" | "warning";
}
export interface Toastr {
  open: (arg: Omit<ToastSubscription, "id">) => void;
}

export const context = createContext<{ open: (arg: Omit<ToastSubscription, "id">) => void }>({
  open: arg => {},
});

export const ToastrController = memo(({ children }: Props) => {
  const [subscriptions, setSubscriptions] = useState<Record<string, ToastSubscription>>({});
  const timeouts = useRef<Record<string, NodeJS.Timeout>>({});
  const [dispatch, { toasters }] = useRedux();
  const unsubscribe = useCallback(
    (id: string) =>
      setSubscriptions(s => {
        const newState = { ...s };
        delete newState[id];
        return newState;
      }),
    [],
  );

  const subscribe = useCallback(
    (subscription: Omit<ToastSubscription, "id">) => {
      const id = cuid();
      timeouts.current[id] = setTimeout(() => {
        unsubscribe(id);
      }, 10000);
      setSubscriptions(s => {
        return {
          ...s,
          [id]: { ...subscription, id },
        };
      });
      // Check if text is string and not a react component,
      // because store can store only serializable values
      if (typeof subscription.text === "string") {
        dispatch(toasters.add({ ...subscription, id }));
      }
    },
    [unsubscribe, dispatch, toasters],
  );

  const clearHideTimeout = useCallback((id: string) => {
    clearTimeout(timeouts.current[id]);
  }, []);

  const lazyUnsubscribe = useCallback(
    (id: string) => {
      clearTimeout(timeouts.current[id]);
      timeouts.current[id] = setTimeout(() => {
        unsubscribe(id);
      }, 2000);
    },
    [unsubscribe],
  );

  const contextValue = useMemo(() => ({ open: subscribe }), [subscribe]);
  return (
    <>
      <context.Provider value={contextValue}>{children}</context.Provider>
      <div className={styles.container}>
        {Object.values(subscriptions).map(subscription => (
          <Toastr
            className="mb-2"
            key={subscription.id}
            type={subscription.type}
            title={subscription.title}
            text={subscription.text}
            clearHideTimeout={() => clearHideTimeout(subscription.id)}
            lazyUnsubscribe={() => lazyUnsubscribe(subscription.id)}
          />
        ))}
      </div>
    </>
  );
});

export const useToastr = () => {
  const toastr = useContext(context);
  return useMemo(
    () => ({
      open: ({ text, title, type }: Omit<ToastSubscription, "id">) => {
        toastr.open({ text, title, type });
      },
    }),
    [toastr],
  );
};

import { CallCenterDashboardIncompleteRoute } from "api/call-center/routes/models";
import { calculateDaysDifference } from "pages/callCenter/dashboard/utils/calculateDaysDifference";
import { pluralize } from "utilities";

interface Props {
  incompleteRoute: CallCenterDashboardIncompleteRoute;
}

export const DepartureDateRange = ({ incompleteRoute }: Props) => {
  if (!incompleteRoute.departureDate) return <div>---</div>;

  const daysDifference = calculateDaysDifference(incompleteRoute.departureDate);

  if (daysDifference > 0) {
    const positiveDaysDifference = Math.abs(daysDifference);

    return (
      <div className="fw-700">
        za {positiveDaysDifference}{" "}
        {pluralize.pl(positiveDaysDifference, {
          singular: "dzień",
          plural: "dni",
          other: "dni",
        })}
      </div>
    );
  }

  if (daysDifference === 0) {
    return <div className="fw-700">dzisiaj</div>;
  }

  const positiveDaysDifference = Math.abs(daysDifference);

  return (
    <div className="fw-700">
      {positiveDaysDifference}{" "}
      {pluralize.pl(positiveDaysDifference, {
        singular: "dzień",
        plural: "dni",
        other: "dni",
      })}{" "}
      temu
    </div>
  );
};

import { capitalizeFirstLetter } from "utilities";
import { CreateCorrectionPosition } from "../CreateCorrectionManuallyModal";
import { FieldName, InitialFieldName } from "../components/WholeAmountToPay";

export const getSinglePositionAmountForSummary = (
  fieldName: FieldName,
  position: CreateCorrectionPosition,
) => {
  if (position.initialQuantity === position.quantity) {
    return (
      (position[fieldName] -
        position[`initial${capitalizeFirstLetter(fieldName) as InitialFieldName}`]) *
      position.quantity
    );
  }
  if (position.initialQuantity > position.quantity) {
    return (
      (position.quantity - position.initialQuantity) *
        position[`initial${capitalizeFirstLetter(fieldName) as InitialFieldName}`] +
      (position[fieldName] -
        position[`initial${capitalizeFirstLetter(fieldName) as InitialFieldName}`]) *
        (position.initialQuantity - position.quantity)
    );
  }
  return 0;
};

import { KeyboardEvent, useState, useMemo, createRef } from "react";
import styles from "./CodeInput.module.css";
import cx from "classnames";
import { useFormikContext } from "formik";
import { Values } from "../ResetPassword";
import { useDidMount } from "hooks";

interface Props {
  numberOfCodeDigits: number;
  placeholder: string;
}

export const CodeInput = ({ numberOfCodeDigits, placeholder }: Props) => {
  const { values, setFieldValue } = useFormikContext<Values>();
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const codeInputRef = createRef<HTMLInputElement>();
  const itemsRef = useMemo(
    () => new Array(numberOfCodeDigits).fill(null).map(() => createRef<HTMLDivElement>()),
    [numberOfCodeDigits],
  );
  const getItem = (index: number) => itemsRef[index]?.current;
  const focusItem = (index: number): void => getItem(index)?.focus();
  const blurItem = (index: number): void => getItem(index)?.blur();

  useDidMount(() => {
    focusItem(0);
  });

  const onItemFocus = (index: number) => () => {
    setActiveIndex(index);
    if (codeInputRef.current) codeInputRef.current.focus();
  };

  const onInputKeyUp = ({ key }: KeyboardEvent) => {
    const newValue = [...values.codeValue];
    const nextIndex = activeIndex + 1;
    const prevIndex = activeIndex - 1;

    const codeInput = codeInputRef.current;
    const currentItem = getItem(activeIndex);

    const isLast = nextIndex === numberOfCodeDigits;
    const isDeleting = key === "Delete" || key === "Backspace";

    // keep items focus in sync
    onItemFocus(activeIndex);

    // on delete, replace the current value
    // and focus on the previous item
    if (isDeleting) {
      newValue[activeIndex] = placeholder;
      setFieldValue("codeValue", newValue);
      if (activeIndex > 0) {
        setActiveIndex(prevIndex);
        focusItem(prevIndex);
      }
      return;
    }

    // if the key pressed is not a number
    // don't do anything
    if (Number.isNaN(Number(key))) return;
    // reset the current value
    // and set the new one
    if (codeInput) codeInput.value = "";
    newValue[activeIndex] = key;
    setFieldValue("codeValue", newValue);
    if (!isLast) {
      setActiveIndex(nextIndex);
      focusItem(nextIndex);
      return;
    }
    if (codeInput) codeInput.blur();
    if (currentItem) currentItem.blur();
    setActiveIndex(-1);
  };

  const onInputBlur = () => {
    blurItem(activeIndex);
    setActiveIndex(-1);
  };

  return (
    <div className={styles.container}>
      <input
        ref={codeInputRef}
        className={styles.input}
        type="text"
        inputMode="decimal"
        onKeyUp={onInputKeyUp}
        onBlur={onInputBlur}
        // some browsers ignore "off" so we need to put some random string to handle all browsers
        autoComplete="LoremIpsumDolorSitAmet"
      />
      {itemsRef.map((ref, i) => (
        <div
          key={i}
          ref={ref}
          role="button"
          tabIndex={0}
          className={cx({ [styles.isActive]: i === activeIndex }, styles.item)}
          onFocus={onItemFocus(i)}
        >
          {values.codeValue[i] || placeholder}
        </div>
      ))}
    </div>
  );
};

import { TradingDocumentItem } from "api/trading-documents/models";
import { UUID } from "api/types";
import { Button } from "components/common";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { AsyncInput } from "components/utils";
import styles from "../RightPanel.module.css";
import { useEditTradingDocumentItemValue } from "./hooks/useEditTradingDocumentItemValue";

interface Props {
  tradingDocumentId: UUID;
  tradingDocumentItem: TradingDocumentItem;
}

export const EditAmountWithTaxSection = ({ tradingDocumentId, tradingDocumentItem }: Props) => {
  const { editItemValueMutation, setShowInput, showInput } = useEditTradingDocumentItemValue(
    "amountWithTax",
    tradingDocumentId,
  );

  return (
    <div className="d-flex align-items-center justify-content-end ml-2">
      {!showInput ? (
        <Button
          kind="transparent-black"
          onClick={e => {
            e.stopPropagation();
            setShowInput(true);
          }}
        >
          <div className="btnBase btnBaseSmall body-12">
            <FinanceAmountFormat value={tradingDocumentItem.amountWithTax} />
          </div>
        </Button>
      ) : (
        <div>
          <AsyncInput
            disabled={editItemValueMutation.isLoading}
            onChange={value => {
              editItemValueMutation.mutate({
                id: tradingDocumentItem.id,
                amountWithTax: value,
              });
            }}
            overwrites={{
              container: { className: styles.editAmountWithoutTaxInput },
              input: { className: styles.editAmountWithoutTaxInput },
            }}
            placeholder="cena brutto"
            type="number"
            value={tradingDocumentItem.amountWithTax}
          />
        </div>
      )}
    </div>
  );
};

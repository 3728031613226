import { MyImportInvoicesTab } from "api/trading-documents/models";

export const getTabs = (): { label: string; name: string }[] => {
  return [
    { label: "Wszystkie", name: "all" },
    { label: "Opłacone", name: "paid" },
    { label: "Nieopłacone", name: "not-paid" },
  ];
};

export const getMyImportTabs = (): { label: string; name: MyImportInvoicesTab }[] => {
  return [
    { label: "Wszystkie", name: "all" },
    { label: "Otwarte", name: "opened" },
    { label: "Zamknięte", name: "closed" },
  ];
};

import { trackingStatusDict } from "api/routes/models";
import { PingLabel } from "components/common/pingLabel";
import { CustomModal } from "components/utils/customModal";
import styles from "./RouteTrackingModal.module.css";
import { TimeFilter } from "./components/TimeFilter";
import { useCallCenterRouteTracking, useRouteTrackingMutation } from "api/call-center/routes/hooks";
import {
  CallCenterDashboardDepartedRoute,
  CallCenterRouteListItem,
} from "api/call-center/routes/models";
import { Loader } from "components/utils/loader";
import { CommonError } from "components/utils";
import { RoutePositionsList } from "./components/RoutePositionsList";
import { useFilters } from "hooks/useFilters";
import { getDaysBasedOnFilters } from "./utils/getDaysBasedOnFilters";
import { DepartureDelay } from "./components/DepartureDelay";
import { getAnyErrorKey } from "utilities";

interface Props {
  close: () => void;
  route: CallCenterRouteListItem | CallCenterDashboardDepartedRoute;
}

export type DayType = "CURRENT" | "FUTURE" | "FINISHED";

export interface RouteTrackingFilters {
  search: string;
}

const initialFilters: RouteTrackingFilters = { search: "" };

export const RouteTrackingModal = ({ close, route }: Props) => {
  const { data: routeTracking, error, isLoading } = useCallCenterRouteTracking(route.id);
  const { filters, setFilter } = useFilters<RouteTrackingFilters>(initialFilters);
  const patchDepartureTime = useRouteTrackingMutation();
  const patchRealDepartureTime = useRouteTrackingMutation();

  if (isLoading) {
    return (
      <div>
        <Loader module="callCenter" />
      </div>
    );
  }

  if (error) {
    return (
      <CustomModal
        close={close}
        isOpen
        overrides={{
          container: { className: styles.modalContainer },
        }}
        title=""
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </CustomModal>
    );
  }

  if (!routeTracking) return null;

  const currentDays = getDaysBasedOnFilters(routeTracking.days.current, filters);
  const futureDays = getDaysBasedOnFilters(routeTracking.days.future, filters);
  const finishedDays = getDaysBasedOnFilters(routeTracking.days.finished, filters);

  return (
    <CustomModal
      close={close}
      isOpen
      overrides={{
        container: { className: styles.modalContainer },
      }}
      titleContent={
        <div className="d-flex align-items-center justify-content-between gap-1">
          <div className="d-flex align-items-center justify-content-end gap-3">
            <div className="d-flex align-items-center gap-2">
              <h2 className={styles.modalTitle}>{routeTracking.signature}</h2>
              <div className="body-14-600 text-black-6">{routeTracking.name}</div>
              {(routeTracking.status === "DEPARTED" ||
                routeTracking.status === "FINISHED" ||
                routeTracking.status === "SETTLED") && (
                <PingLabel
                  kind={routeTracking.trackingStatus}
                  label={trackingStatusDict[routeTracking.trackingStatus]}
                />
              )}
              {(routeTracking.status === "NEW" || routeTracking.status === "ROUTE_READY") && (
                <PingLabel kind="NO_EFFECT" label="Nierozpoczęta" />
              )}
            </div>
            <div className="line-divider" />
            {routeTracking.car ? (
              <div className="body-14-600">
                {routeTracking.car.brand}{" "}
                <span className="fw-800">{routeTracking.car.registrationNumber}</span>
              </div>
            ) : (
              <div className="body-14-600">nie wybrano samochodu</div>
            )}
            <div className="line-divider" />
            <div className="d-flex align-items-center gap-2">
              <TimeFilter
                disabled={patchDepartureTime.isLoading}
                label="Spodziewany czas wyjazdu"
                onChange={event => {
                  if (event && event.target) {
                    patchDepartureTime.mutate({
                      id: routeTracking.id,
                      toUpdate: {
                        departureTime: event.target.value,
                      },
                    });
                  }
                }}
                value={routeTracking.departureTime}
              />
              <TimeFilter
                disabled={patchRealDepartureTime.isLoading}
                label="Rzeczywisty czas wyjazdu"
                onChange={event => {
                  if (event && event.target) {
                    patchRealDepartureTime.mutate({
                      id: routeTracking.id,
                      toUpdate: {
                        realDepartureTime: event.target.value,
                      },
                    });
                  }
                }}
                value={routeTracking.realDepartureTime}
              />
              <DepartureDelay departureDelay={routeTracking.departureDelay} />
            </div>
          </div>
        </div>
      }
    >
      <>
        <div className="d-flex align-items-center px-3 pt-2">
          <div className={styles.search}>
            <input
              onChange={e => {
                setFilter("search", e.target.value);
              }}
              placeholder="Szukaj wśród zamówień i klientów"
              style={{ maxWidth: "600px" }}
              type="search"
              value={filters.search}
            />
          </div>
        </div>
        <RoutePositionsList
          days={currentDays}
          dayType="CURRENT"
          filters={filters}
          routeTrackingStatus={routeTracking.trackingStatus}
        />
        <RoutePositionsList
          className="pb-3"
          days={futureDays}
          dayType="FUTURE"
          filters={filters}
          routeTrackingStatus={routeTracking.trackingStatus}
        />
        <RoutePositionsList
          days={finishedDays}
          dayType="FINISHED"
          filters={filters}
          routeTrackingStatus={routeTracking.trackingStatus}
        />
      </>
    </CustomModal>
  );
};

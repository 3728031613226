import { Table } from "@tanstack/react-table";
import { useEffect, useLayoutEffect } from "react";
import { TABLE_FILL_WIDTH_ID, TABLE_PADDING_HORIZONTAL } from "../../constants";
import { useIsInitializing } from "./utils";
import { TableProps } from "../../types";

export const useTableFillWidth = <T>(
  table: Table<T>,
  containerRef: React.RefObject<HTMLDivElement>,
  setIsInitializing: ReturnType<typeof useIsInitializing>["setIsInitializing"],
  rows: TableProps<T>["rows"],
) => {
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        const containerWidth = entry.contentRect.width;
        setFillColumnWidth(table, containerWidth);
      }
    });
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (!containerRef.current) return;
    const containerWidth = containerRef.current?.clientWidth!;
    setFillColumnWidth(table, containerWidth);
    setIsInitializing(false);
  }, [table, containerRef, setIsInitializing, rows]);
};

function setFillColumnWidth<T>(table: Table<T>, containerWidth: number) {
  const tableColumnWidth = getTableWidth(table);

  const fillColumnWidth = getFillColumnWidth(containerWidth, tableColumnWidth);

  table?.setColumnSizing({ [TABLE_FILL_WIDTH_ID]: fillColumnWidth });
}

function getFillColumnWidth(containerWidth: number, tableColumnWidth: number) {
  const shouldHideFillWidthColumn = containerWidth < tableColumnWidth;
  if (shouldHideFillWidthColumn) {
    return 0;
  }
  return containerWidth - tableColumnWidth;
}

function getTableWidth<T>(table: Table<T>) {
  const width = table.getTotalSize() + TABLE_PADDING_HORIZONTAL;
  const fillWidthColumnWidth = table.getColumn(TABLE_FILL_WIDTH_ID)?.getSize() || 0;
  return width - fillWidthColumnWidth;
}

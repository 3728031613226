import { FilterType } from "components/common/filters/types";
import { FilterList } from "components/common/moduleNavigation/components/filtersSection/filterList/FilterList";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiShelves } from "components/miloDesignSystem/atoms/icons/MdiShelves";
import { LongHeldPackagesRouting } from "routes/wms/LongHeldPackagesRouting";

export const longHeldPackages: ModuleLink = {
  url: "long-held-packages",
  label: "Meble długo trzymane w magazynie",
  icon: MdiShelves,
  subSection: (
    <FilterList
      filters={[
        {
          type: FilterType.Search,
          label: "kontrahent",
          name: "supplierId",
          searchBy: "customers",
        },
        {
          type: FilterType.Number,
          label: "l. przeterminowanych dni",
          placeholder: "0",
          name: "days",
        },
      ]}
    />
  ),
  routing: LongHeldPackagesRouting,
};

import { Button, PageHeader } from "components/common";
import downloadFileIcon from "assets/images/downloadFile.svg";
import uploadFileIcon from "assets/images/uploadFile.svg";
import visibilityOffIcon from "assets/images/eye_off.svg";
import areaChartIcon from "assets/images/areaChart.svg";
import { getTabs } from "../utils/getTabs";
import { routes } from "components/common/moduleNavigation/moduleConfig/callCenter/routes/routes";

export const ListHeader = () => {
  return (
    <div>
      <PageHeader
        tabs={{
          list: getTabs(),
          routesRoot: `call-center/${routes.url}`,
          urlSpan: "list",
        }}
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Importuj plik"
                  src={uploadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Pobierz plik"
                  src={downloadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <span className="line-divider lineDividerMargin" />
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Wyłącz widoczność"
                  src={visibilityOffIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img alt="Wykres" src={areaChartIcon} style={{ height: "16px", width: "16px" }} />
              </div>
            </Button>
          </div>
        }
        createButton={{ label: "Utwórz trasę", onClick: () => {} }}
        searchInput={{
          label: "Szukaj wśród faktur, zamówień, klientów i tras",
          tags: [],
        }}
        viewLabel="CALL_CENTER_ROUTES"
      />
    </div>
  );
};

import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import routeIcon from "assets/images/route.svg";
import { RouteLineItems } from "routes/logistics/RouteLineItems";

export const routeLineItems: ModuleLink = {
  url: "route-line-items",
  label: "Zlecenia na trasie",
  icon: routeIcon,
  subSection: <></>,
  routing: RouteLineItems,
  isAccessible: false,
};

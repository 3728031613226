import { RightPanelHeader } from "components/utils/drawer";
import { Button } from "components/common";
import hidePanelIcon from "assets/images/keyboardTab.svg";
import planDetails from "assets/images/planDetails.svg";
import downloadPdfIcon from "assets/images/download_pdf.svg";
import cx from "classnames";
import { ManufacturingPlan } from "api/manufacturingNew/models";
import checkIcon from "assets/images/check_small.svg";
import whiteCheckIcon from "assets/images/checkSmallWhite.svg";
import { useManufacturingPlanOnListPatchMutation } from "api/manufacturingNew/hooks";
import { CheckboxGreen } from "components/utils/checkboxGreen";
import { MoreInfoButton } from "components/common/moreInfoButton/MoreInfoButton";
import popUpStyles from "components/common/moreInfoButton/MoreInfoButton.module.css";
import { Link } from "react-router-dom";
import { useMutation } from "hooks/useMutation";
import { deleteManufacturingPlan } from "api/manufacturingNew/calls";
import deleteRed from "assets/images/delete_red.svg";
import { useConfirmModal } from "hooks";
import { useQueryClient } from "react-query";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
  manufacturingPlan: ManufacturingPlan;
}

export const PanelHeader = ({ close, isScrolledOver, manufacturingPlan }: Props) => {
  const patchMutation = useManufacturingPlanOnListPatchMutation();
  const queryClient = useQueryClient();
  const confirmModal = useConfirmModal();
  const deleteMutation = useMutation(deleteManufacturingPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries(manufacturingNewKeys.manufacturingPlans());
      close();
    },
  });

  return (
    <RightPanelHeader
      className={cx({
        slidingPanelHeaderShadow: isScrolledOver,
      })}
    >
      <div className="d-flex w-100 align-items-center">
        {isScrolledOver && (
          <div className="slidingPanelHeader">
            <div className="slidingPanelHeaderContent nowrap d-flex align-items-center gap-2">
              <CheckboxGreen
                checked={manufacturingPlan.isConfirmed}
                disabled={patchMutation.isLoading}
                name="isRequired"
                onChange={() => {
                  patchMutation.mutate({
                    id: manufacturingPlan.id,
                    toUpdate: { isConfirmed: !manufacturingPlan.isConfirmed },
                  });
                }}
              />
              <div>{manufacturingPlan.signature}</div>
            </div>
          </div>
        )}

        {!isScrolledOver && (
          <Button
            disabled={patchMutation.isLoading}
            kind={manufacturingPlan.isConfirmed ? "tealGreen" : "confirm"}
            onClick={() => {
              patchMutation.mutate({
                id: manufacturingPlan.id,
                toUpdate: { isConfirmed: !manufacturingPlan.isConfirmed },
              });
            }}
            size="btnSizeSmall"
          >
            <div className="btnBase btnBaseSmall">
              {!manufacturingPlan.isConfirmed ? (
                <img alt="Gotowe" src={checkIcon} style={{ height: "16px", width: "16px" }} />
              ) : (
                <img alt="Gotowe" src={whiteCheckIcon} style={{ height: "16px", width: "16px" }} />
              )}
              <div className="fs-10">
                {!manufacturingPlan.isConfirmed ? "Zatwierdź" : "Zatwierdzono"}
              </div>
            </div>
          </Button>
        )}

        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <Button
            kind="transparent-black"
            size="square-s"
            rel="noopener noreferrer"
            href="/"
            target="_blank"
            as="a"
            title="hm"
          >
            <div className="btnBase btnBaseSmall">
              <img alt="Pobierz PDF" src={downloadPdfIcon} />
            </div>
          </Button>
          <MoreInfoButton
            children={
              <>
                <div className={cx("px-3 py-2 w-100", popUpStyles.optionRow)}>
                  <Link to={`/production-plans/details/${manufacturingPlan.id}`}>
                    <div className="d-flex align-items-center gap-2">
                      <img src={planDetails} alt="Szczegóły planu" />
                      <div className="body-14-600">Szczegóły planu</div>
                    </div>
                  </Link>
                </div>
                <button
                  disabled={deleteMutation.isLoading}
                  onClick={() => {
                    confirmModal.open({
                      text: `Czy na pewno chcesz usunąć plan ${manufacturingPlan.signature}?`,
                      confirmText: "Usuń",
                      callback: () => deleteMutation.mutate(manufacturingPlan.id),
                    });
                  }}
                  className={cx("px-3 py-2 w-100", popUpStyles.optionRow)}
                >
                  <div className="d-flex align-items-center justify-content-between gap-2">
                    <div className="d-flex align-items-center gap-2">
                      <img src={deleteRed} alt="Usuń" />
                      <div className="body-14-600 text-red-4">Usuń</div>
                    </div>
                  </div>
                </button>
              </>
            }
          />

          <span className="divider line-divider" />
          <Button kind="transparent-black" onClick={close} size="square-s">
            <div className="btnBase btnBaseSmall">
              <img
                alt="Zamknij panel"
                src={hidePanelIcon}
                style={{ height: "16px", width: "16px" }}
              />
            </div>
          </Button>
        </div>
      </div>
    </RightPanelHeader>
  );
};

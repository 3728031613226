import styles from "./ModuleList.module.css";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const ModuleList = ({ children, className, style }: Props) => {
  return (
    <div className={cx(className, styles.moduleListTable)} style={style}>
      <div className={styles.hideScrollbar} style={{ overflowX: "scroll" }}>
        {children}
      </div>
    </div>
  );
};

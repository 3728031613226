import { ManufacturingStage } from "api/manufacturingNew/models";
import { Button } from "components/common";
import { RightPanelSection } from "components/utils/drawer";
import { useState } from "react";
import { PreviousStage } from "./PreviousStage";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { AddPreviousStage } from "./AddPreviousStage";

interface Props {
  manufacturingStage: ManufacturingStage;
}

export const PreviousStagesSection = ({ manufacturingStage }: Props) => {
  const [isAdding, setIsAdding] = useState(false);

  return (
    <RightPanelSection title="Pokazuj statusy wskazanych etapów poprzedzających:">
      <div className="pb-3">
        {manufacturingStage.settings.visiblePreviousStages.map(previousStage => (
          <PreviousStage
            key={previousStage.id}
            manufacturingStageId={manufacturingStage.id}
            previousStage={previousStage}
          />
        ))}
        {!isAdding ? (
          <div className="py-2">
            <Button kind="create" onClick={() => setIsAdding(true)}>
              <div className="btnBase btnBaseSmall btnBase16">
                <img alt="Dodaj" src={darkPlusIcon} />
                Dodaj etap
              </div>
            </Button>
          </div>
        ) : (
          <AddPreviousStage
            panelId={manufacturingStage.id}
            position={
              manufacturingStage.settings.visiblePreviousStages.length > 0
                ? Math.max(
                    ...manufacturingStage.settings.visiblePreviousStages.map(
                      previousStage => previousStage.position,
                    ),
                  ) + 1
                : 0
            }
            setIsAdding={setIsAdding}
            setting={manufacturingStage.settings.id}
            visiblePreviousStages={manufacturingStage.settings.visiblePreviousStages}
          />
        )}
      </div>
    </RightPanelSection>
  );
};

import { MoreDataCounterWithToolTip } from "components/common/moreDataCounter";
import styles from "./AttributesList.module.css";
import cx from "classnames";

export type AttributeObject = {
  attribute: string;
  value: string;
  [key: string]: any;
};
interface AttributesListProps {
  attributes: AttributeObject[];
  maxCharactersNumForAllItems: number;
  id: number;
}

export const AttributesList = ({
  attributes,
  maxCharactersNumForAllItems,
}: AttributesListProps) => {
  // if attributes characters together overextend maxCharactersNumForAllItems  , put them into wrappedAttributes and show under toolTip, return attributes in specific feature style
  const [shownAttributes, wrappedAttributes] = attributeManager(
    attributes,
    maxCharactersNumForAllItems,
  );
  const wrappedAttributesPreparedString = wrappedAtributesStringHandler(wrappedAttributes);

  return (
    <>
      {shownAttributes.map(attribute => {
        return (
          <div key={attribute.attribute} className={cx("nowrap", styles.attribute)}>
            <span className="fs-10 text-black-6">{attribute.attribute}</span>
            <span className="fs-10">{attribute.value}</span>
          </div>
        );
      })}
      {wrappedAttributes.length > 0 && (
        <MoreDataCounterWithToolTip
          counter={wrappedAttributes.length}
          toolTipString={wrappedAttributesPreparedString}
        />
      )}
    </>
  );
};

export const attributeManager = (
  attributes: AttributeObject[],
  maxCharactersNumForAllItems: number,
) => {
  let allAttributesCharactersCount = 0;
  const wrappedAttributes: AttributeObject[] = [];

  attributes.forEach(attribute => {
    let temporaryCountStorage =
      allAttributesCharactersCount + attribute.attribute.length + attribute.value.length;
    if (temporaryCountStorage > maxCharactersNumForAllItems) {
      wrappedAttributes.push(attribute);
      attributes = attributes.filter(attributeFilter => attributeFilter !== attribute);
      return;
    } else {
      allAttributesCharactersCount = temporaryCountStorage;
    }
  });
  return [attributes, wrappedAttributes];
};

const wrappedAtributesStringHandler = (wrappedAttributes: AttributeObject[]) => {
  return wrappedAttributes.length > 0
    ? wrappedAttributes.map(attribute => attribute.attribute + ": " + attribute.value).join(", ")
    : null;
};

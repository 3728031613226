import { useRef, useState, useEffect, useCallback } from "react";
import * as React from "react";
import styles from "./ClickSessionMemorizer.module.css";

/**
 * Memorizes clicked elements in session
 */
export const ClickSessionMemorizer = ({
  children,
  className = "",
  sessionId,
}: {
  className?: string;
  children: (params: {
    setClicked: (id: number) => void;
    isClicked: (id: number) => boolean;
    clickedClassName: string;
  }) => React.ReactElement;
  sessionId: string;
}) => {
  function getInitialState() {
    let storageToInit = {};
    const data = sessionStorage.getItem(sessionId);
    if (data) {
      try {
        storageToInit = JSON.parse(data);
      } catch (err) {
        console.error(err);
      }
    }
    return storageToInit;
  }
  const initialMount = useRef(true);
  const [storage, setStorage] = useState<Record<number, boolean>>(getInitialState);
  const saveItem = useCallback((id: number) => setStorage(s => ({ ...s, [id]: true })), []);
  const isClicked = useCallback((id: number) => storage[id] === true, [storage]);

  useEffect(() => {
    if (initialMount.current) return;
    sessionStorage.setItem(sessionId, JSON.stringify(storage));
  }, [storage, sessionId]);

  useEffect(() => {
    initialMount.current = false;
  }, []);

  return children({ isClicked, setClicked: saveItem, clickedClassName: styles.clickedClassName });
};

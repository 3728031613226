import { Pagination } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { CommentType, PostComment } from "./models";
import { commentsKeys } from "./keys";
import { Assign } from "utility-types";
import { parsePatchData } from "utilities/parsePatchData";

export const getComments = (getUrlWithParams: string = ""): ApiFetcher<Pagination<CommentType>> => ({
  key: commentsKeys.comments.list(getUrlWithParams),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: getUrlWithParams,
    }),
});

export const postComment = ({ data, endpointUrl }: { data: PostComment; endpointUrl: string }) => {
  return queryFetch<PostComment>({
    method: "POST",
    url: endpointUrl,
    data: parsePatchData(data),
  });
};

export const patchComment = ({
  data,
  endpointUrl,
}: {
  data: Assign<Partial<CommentType>, { id: string }>;
  endpointUrl: string;
}) =>
  queryFetch<CommentType>({
    method: "PATCH",
    url: `${endpointUrl}/${data.id}`,
    data,
  });

export const deleteComment = ({ id, endpointUrl }: { id: string; endpointUrl: string }) =>
  queryFetch({
    method: "DELETE",
    url: `${endpointUrl}/${id}`,
  });

import { useQuery } from "hooks";
import { RightPanel } from "./rightPanel/RightPanel";
import { getSearch } from "./utils/getSearch";
import { useManufacturingSchemas } from "api/manufacturingNew/hooks";
import { ListHeader } from "./components/ListHeader";
import { Table } from "components/miloDesignSystem/molecules/table";
import { ManufacturingSchema } from "api/manufacturingNew/models";
import { useManufacturingSchemasListColumns } from "./useManufacturingSchemasListColumns";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";

const useSearchParams = () => {
  const { query, setQuery, updateQuery } = useQuery({ exclude: ["panelId"] });
  return { searchParams: query, setQuery, updateQuery };
};

export const ManufacturingSchemasList = () => {
  const { searchParams, updateQuery } = useSearchParams();
  const search = getSearch({ query: searchParams });
  const { data: manufacturingSchemas, error, isLoading, pagination } = useManufacturingSchemas(
    search,
  );

  const columns = useManufacturingSchemasListColumns();

  return (
    <PageWrapper>
      <ListHeader />

      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<ManufacturingSchema>
          rows={manufacturingSchemas}
          columns={columns}
          onPaginationChange={paginationState =>
            updateQuery({ ...searchParams, page: paginationState.pageIndex })
          }
          isLoading={isLoading}
          error={error}
          pagination={pagination}
          onRowClick={id =>
            updateQuery({
              ...searchParams,
              panelId: id === searchParams.panelId ? "" : id,
            })
          }
          uiSchema={mainListUiSchema}
        />

        <RightPanel />
      </div>
    </PageWrapper>
  );
};

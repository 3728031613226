import { useCallback } from "react";
import { useRedux, useSelector } from "hooks";
import styles from "./SingleItemQuantitiesForRoute.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiBottomPanelOpen } from "components/miloDesignSystem/atoms/icons/MdiBottomPanelOpen";
import { cx } from "utilities";
import { ProductsList } from "./productsList/ProductsList";
import { Route } from "api/routes/models";

interface Props {
  route: Route;
}

export const SingleItemQuantitiesForRoute = ({ route }: Props) => {
  const [dispatch, { ui }] = useRedux();
  const isCategoriesPanelOpen = useSelector(store => store.ui.areCategoriesOnRouteOpen);
  const isSingleItemQuantitesPanelOpen = useSelector(
    store => store.ui.isSingleItemQuantitiesPanelOpen,
  );
  const toggleCategoriesPanel = useCallback(() => dispatch(ui.toggleCategoriesOnRoutes()), [
    dispatch,
    ui,
  ]);
  const toggleSingleItemQuantitiesPanel = useCallback(() => {
    dispatch(ui.toggleSingleItemQuantitiesPanel());
    if (isCategoriesPanelOpen) toggleCategoriesPanel();
  }, [dispatch, ui, isCategoriesPanelOpen, toggleCategoriesPanel]);

  if (!isSingleItemQuantitesPanelOpen) {
    return (
      <div
        className={cx(styles.wrapper, {
          [styles.openWrapper]: !isCategoriesPanelOpen,
        })}
      >
        <div className="d-flex w-100 align-items-center justify-content-between">
          <Typography color="neutralWhite100" fontSize="18" fontWeight="700">
            Meble na trasie
          </Typography>
          <IconButton
            icon={<MdiBottomPanelOpen color="neutralWhite100" size="16" />}
            onClick={toggleSingleItemQuantitiesPanel}
            variant="transparent"
          />
        </div>
      </div>
    );
  }

  return (
    <ProductsList
      route={route.id}
      toggleSingleItemQuantitiesPanel={toggleSingleItemQuantitiesPanel}
    />
  );
};

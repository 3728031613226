import { Route } from "components/utils";
import { RouteLineItems as RouteLineItemsList } from "pages/logistics/routeLineItems/RouteLineItems";
import { NotFound } from "pages/notFound";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const RouteLineItems = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={RouteLineItemsList}
        exact={true}
        path={`${match.path}/:routeId`}
        shouldReRenderTitle={false}
        title="Milo - zlecenia na trasę"
      />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};

import { useCallback, useMemo } from "react";
import styles from "./RoutePointsCategories.module.css";
import cx from "classnames";
import packageIcon from "assets/images/49w.svg";
import { OrderPointsForRoute } from "api/orders/models";
import { useQuery, useRedux, useSelector } from "hooks";
import { Gallery } from "components/common/gallery/Gallery";

interface Props {
  routePoints: OrderPointsForRoute;
}

function Group({
  imgSrc,
  label,
  value,
  id,
}: {
  imgSrc?: string;
  label: string;
  value: string | number;
  id?: number;
}) {
  const { query, updateQuery } = useQuery();

  const handleSelectMultiple = () => {
    if (!id) return;

    const currentIds = query.productCategory;
    if (currentIds) {
      const isNew = currentIds.split(",").includes(String(id));

      if (isNew) {
        const newList = currentIds
          .split(",")
          .filter(el => Number(el) !== id)
          .join(",");

        updateQuery({ productCategory: newList, page: 1 });
      } else {
        updateQuery({ productCategory: `${currentIds}${id},`, page: 1 });
      }
    } else {
      updateQuery({ productCategory: `${id},`, page: 1 });
    }
  };
  const isActive = query.productCategory?.split(",").includes(String(id));

  return (
    <div className={styles.group} onClick={handleSelectMultiple}>
      <div className={styles.label}>
        {imgSrc && <img src={imgSrc} alt="" />}
        <span className={cx({ "ml-0": !imgSrc, [styles.active]: isActive })}>{label}</span>
      </div>
      <div className="d-flex align-items-center">
        <div className={cx(styles.value, { "ml-0": !imgSrc })}>{value} szt.</div>
      </div>
    </div>
  );
}

export const RoutePointsCategories = ({ routePoints }: Props) => {
  const [dispatch, { ui }] = useRedux();
  const isOpen = useSelector(state => state.ui.areCategoriesOnRouteOpen);
  const isSingleItemQuantitesPanelOpen = useSelector(
    store => store.ui.isSingleItemQuantitiesPanelOpen,
  );
  const toggleSingleItemQuantitiesPanel = useCallback(
    () => dispatch(ui.toggleSingleItemQuantitiesPanel()),
    [dispatch, ui],
  );
  const toggle = useCallback(() => {
    dispatch(ui.toggleCategoriesOnRoutes());
    if (isSingleItemQuantitesPanelOpen) toggleSingleItemQuantitiesPanel();
  }, [dispatch, ui, isSingleItemQuantitesPanelOpen, toggleSingleItemQuantitiesPanel]);

  const availableCategoriesList = useSelector(
    state => state.partials.configuration.productCategoriesToCount,
  );

  const hasMapCategoriesCountersService = useSelector(
    state => state.partials.configuration.hasMapCategoriesCountersService,
  );

  const categoriesListWithCount = availableCategoriesList.map(cat => {
    const categoryCount = routePoints.categories.find(({ id }) => id === cat.id);
    return {
      ...cat,
      count: categoryCount?.count || 0,
    };
  });

  const countryPoints = useMemo(() => {
    const points: Record<string, number> = {};
    routePoints.results.forEach(cat => {
      if (!points[cat.countryName]) {
        points[cat.countryName] = 0;
      }
      points[cat.countryName] = points[cat.countryName] + 1;
    });
    return Object.entries(points).map(([name, count], index) => ({ name, count, id: index }));
  }, [routePoints]);

  return (
    <div
      className={cx(styles.wrapper, {
        [styles.openWrapper]: isOpen && hasMapCategoriesCountersService,
      })}
    >
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <span className={cx("mr-1 text-color-white", styles.number)}>{routePoints.count}</span>
          <span className={cx("mr-1", styles.text)}>
            <img src={packageIcon} className={styles.packageIcon} alt="" /> na mapie
          </span>
        </div>
        <Gallery width={180}>
          {countryPoints.map(cat => (
            <Group key={cat.id} label={cat.name} value={cat.count} />
          ))}
        </Gallery>
      </div>

      {hasMapCategoriesCountersService && <div className={styles.border}></div>}
      {isOpen && Boolean(categoriesListWithCount.length) && hasMapCategoriesCountersService && (
        <Gallery>
          {categoriesListWithCount.map(cat => (
            <Group
              key={cat.id}
              id={cat.id}
              label={cat.name}
              value={cat.count}
              imgSrc={cat.picture}
            />
          ))}
        </Gallery>
      )}
      {isOpen && hasMapCategoriesCountersService && <div className={styles.border}></div>}
      {Boolean(categoriesListWithCount.length) && hasMapCategoriesCountersService && (
        <div className={styles.toggleIcon} onClick={toggle}>
          <div className={styles.iconContainer}>
            <div className={styles.first}></div>
            <div className={styles.middle}></div>
            <div className={styles.last}></div>
          </div>
          {!isOpen && <span className={cx(styles.text, "ml-2 fs-14")}>Pokaż kategorie</span>}
        </div>
      )}
    </div>
  );
};

import { MyImportInvoicesTab } from "api/trading-documents/models";
import { queryString } from "utilities";

type GetSearchProps = {
  tab: MyImportInvoicesTab;
  query: {
    [x: string]: string;
  };
};

export const getSearch = ({ tab, query }: GetSearchProps): string => {
  const tabsQuery = {
    reviewStatus: tab === "opened" ? "OPEN" : tab === "closed" ? "CLOSED" : "",
  };
  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

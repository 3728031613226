import { createColumnHelper } from "@tanstack/react-table";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { OverviewReleasedAndReceivedListColumns } from "api/wms/models";
import { useCallback, useMemo } from "react";
import { GenericTable } from "typeUtilities";
import { TableFilters } from "components/common/genericTable/useGenericTableFilters";
import { formatGenericTableDate } from "components/common/genericTable/utils";
import { DatepointerDetails } from "pages/wms/shared/components/DatepointerDetails";
import { getDatepointerColor } from "pages/wms/shared/utils/getDatepointerColor";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useToastr } from "hooks";
import { wmsFileFactory } from "api/wms/calls";
import { fileDownloader } from "fileDownloader";
import { getAnyErrorKey } from "utilities";
import { MdiDownloadCsv } from "components/miloDesignSystem/atoms/icons/MdiDownloadCsv";

const columnHelper = createColumnHelper<
  GenericTable<OverviewReleasedAndReceivedListColumns>["rows"][number]
>();

export const useOverviewReleasedAndReceivedListColumns = (
  tableData: GenericTable<OverviewReleasedAndReceivedListColumns> | null,
  filters: TableFilters,
) => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const toastr = useToastr();

  const handleDownloadCsv = useCallback(
    async (date: string) => {
      const tstr = downloadFeedbackToastr.open({ type: "csv", calculateProgress: false });
      const { url, name } = wmsFileFactory.inOutReport(date, date);
      const response = await fileDownloader({
        url,
        name,
        type: "csv",
      });

      if (response.status === "success") {
        tstr.lazyClose();
      } else if (response.error) {
        tstr.lazyClose();
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(response.error),
        });
      }
    },
    [downloadFeedbackToastr, toastr],
  );

  return useMemo(() => {
    if (!tableData) return [];
    return [
      columnHelper.accessor(row => row.DATEPOINTER.value, {
        header: "data",
        size: 60,
        cell: info => (
          <Typography
            color={getDatepointerColor(info.getValue(), filters.rowBy)}
            fontSize="12"
            fontWeight="600"
          >
            {formatGenericTableDate(tableData.rowsBy, info.getValue())}
          </Typography>
        ),
      }),
      columnHelper.accessor(row => row.DATEPOINTER.value, {
        id: "DATEPOINTER_DAYNAME_RELEASED",
        header: "",
        size: 40,
        cell: info => <DatepointerDetails date={info.getValue()} rowBy={filters.rowBy} />,
      }),
      columnHelper.group({
        id: "received",
        header: () => (
          <Typography fontSize="14" fontWeight="700">
            Przyjęcia
          </Typography>
        ),
        columns: [
          columnHelper.accessor(row => row.RECEIVED_INDEXES.value, {
            id: "RECEIVED_INDEXES",
            header: () => (
              <Typography
                className="text-right w-100"
                color="neutralBlack48"
                fontSize="12"
                fontWeight="400"
              >
                {tableData.columns.find(
                  column =>
                    column.identifier === OverviewReleasedAndReceivedListColumns.RECEIVED_INDEXES,
                )?.name || ""}
              </Typography>
            ),
            size: 65,
            cell: info => (
              <Typography className="text-right w-100" fontSize="12" fontWeight="700">
                {info.getValue() || "---"}
              </Typography>
            ),
          }),
          columnHelper.accessor(row => row.RECEIVED_PACKAGES.value, {
            id: "RECEIVED_PACKAGES",
            header: () => (
              <Typography
                className="text-right w-100"
                color="neutralBlack48"
                fontSize="12"
                fontWeight="400"
              >
                {tableData.columns.find(
                  column =>
                    column.identifier === OverviewReleasedAndReceivedListColumns.RECEIVED_PACKAGES,
                )?.name || ""}
              </Typography>
            ),
            size: 65,
            cell: info => (
              <Typography className="text-right w-100" fontSize="12" fontWeight="700">
                {info.getValue() || "---"}
              </Typography>
            ),
          }),
        ],
      }),

      columnHelper.group({
        id: "released",
        header: () => (
          <Typography fontSize="14" fontWeight="700">
            Wydania
          </Typography>
        ),
        columns: [
          columnHelper.accessor(row => row.RELEASED_INDEXES.value, {
            id: "RELEASED_INDEXES",
            header: () => (
              <Typography
                className="text-right w-100"
                color="neutralBlack48"
                fontSize="12"
                fontWeight="400"
              >
                {tableData.columns.find(
                  column =>
                    column.identifier === OverviewReleasedAndReceivedListColumns.RELEASED_INDEXES,
                )?.name || ""}
              </Typography>
            ),
            size: 65,
            cell: info => (
              <Typography className="text-right w-100" fontSize="12" fontWeight="700">
                {info.getValue() || "---"}
              </Typography>
            ),
          }),
          columnHelper.accessor(row => row.RELEASED_PACKAGES.value, {
            id: "RELEASED_PACKAGES",
            header: () => (
              <Typography
                className="text-right w-100"
                color="neutralBlack48"
                fontSize="12"
                fontWeight="400"
              >
                {tableData.columns.find(
                  column =>
                    column.identifier === OverviewReleasedAndReceivedListColumns.RELEASED_PACKAGES,
                )?.name || ""}
              </Typography>
            ),
            size: 65,
            cell: info => (
              <Typography className="text-right w-100" fontSize="12" fontWeight="700">
                {info.getValue() || "---"}
              </Typography>
            ),
          }),
        ],
      }),
      columnHelper.accessor(row => row.DATEPOINTER.value, {
        header: " ",
        size: 60,
        cell: info => {
          const date = info.getValue();
          return (
            <div className="d-flex justify-content-center flex-1">
              <IconButton
                icon={MdiDownloadCsv}
                onClick={async event => {
                  event.stopPropagation();
                  handleDownloadCsv(date);
                }}
                size="small"
                variant="gray"
              />
            </div>
          );
        },
      }),
    ];
  }, [tableData, filters.rowBy, handleDownloadCsv]);
};

import * as React from "react";
import cx from "classnames";
import styles from "./Form.module.css";

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}
const FieldsetLabel = ({ children, className = "", style }: Props) => {
  return (
    <div className={cx(className, styles.fieldsetLabel)} style={style}>
      {children}
    </div>
  );
};
const SectionLabel = ({ children, className = "", style }: Props) => {
  return (
    <div className={cx(className, styles.sectionLabel)} style={style}>
      {children}
    </div>
  );
};
const Sections = ({ children, className = "", style }: Props) => {
  return (
    <div className={cx(className, styles.sections)} style={style}>
      {children}
    </div>
  );
};
const Card = ({ children, className = "", style }: Props) => {
  return (
    <div className={cx(className, styles.card)} style={style}>
      {children}
    </div>
  );
};

/**
 * Set of form layout components
 */
export const Form = {
  SectionLabel,
  Sections,
  Card,
  FieldsetLabel,
};

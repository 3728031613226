import {
  OrderForCreatingInvoice,
  OrderPositionForCreatingInvoice,
} from "api/trading-documents/models";

export const getAllPositions = (orders: OrderForCreatingInvoice[]) => {
  return orders.reduce((allPositions, order) => {
    const positionsWithOrderSignature = order.positions.map(position => ({
      ...position,
      orderSignature: order.orderSignature,
    }));
    return allPositions.concat(positionsWithOrderSignature);
  }, [] as (OrderPositionForCreatingInvoice & { orderSignature: string })[]);
};

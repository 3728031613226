import { createColumnHelper } from "@tanstack/react-table";
import {
  DailySummaryDirection,
  DailySummaryReceivedAndReleasedIdentifiers,
  dailySummaryDirectionDict,
} from "api/wms/models";
import { TableFilters } from "components/common/genericTable/useGenericTableFilters";
import { formatGenericTableDate } from "components/common/genericTable/utils";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { getDateColor } from "pages/wms/overview/utils/getDateColor";
import { useMemo } from "react";
import { GenericTable } from "typeUtilities";
import { getDateAbbreviation } from "../../utils/getDateAbbreviation";

const columnHelper = createColumnHelper<
  GenericTable<DailySummaryReceivedAndReleasedIdentifiers>["rows"][number]
>();
const dynamicColumnHelper = createColumnHelper<GenericTable<string>["rows"][number]>();

export const useDailySummaryReceivedAndReleasedColumns = (
  tableData: GenericTable<DailySummaryReceivedAndReleasedIdentifiers> | null,
  filters: TableFilters,
) => {
  return useMemo(() => {
    const dynamicColumns = tableData
      ? tableData.columns.filter(column => !isNaN(Number(column.identifier)))
      : [];

    if (!tableData) return [];
    if (!tableData.rows.length) return [];
    return [
      columnHelper.accessor(row => row.DATEPOINTER.value, {
        header:
          tableData.columns.find(
            column => column.identifier === DailySummaryReceivedAndReleasedIdentifiers.DATEPOINTER,
          )?.name || "",
        size: 78,
        cell: info => (
          <Typography
            color={filters.rowBy === "DAY" ? getDateColor(info.getValue()) : "neutralBlack100"}
            fontSize="12"
            fontWeight="600"
          >
            {formatGenericTableDate(tableData.rowsBy, info.getValue())}
          </Typography>
        ),
      }),
      columnHelper.accessor(row => row.DATEPOINTER.value, {
        id: "DATEPOINTER_DAYNAME_RELEASED",
        header: "",
        size: 48,
        cell: info => {
          if (filters.rowBy !== "DAY") return null;
          return (
            <Typography color={getDateColor(info.getValue())} fontSize="12" fontWeight="600">
              {getDateAbbreviation(info.getValue())}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.DIRECTION.value, {
        id: "DIRECTION",
        header: "",
        size: 65,
        cell: info => {
          return (
            <Typography fontSize="14" fontWeight="700">
              {dailySummaryDirectionDict[(info.getValue() as unknown) as DailySummaryDirection]}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.INDEXES_SUM.value, {
        id: "INDEXES_SUM",
        header: () => {
          return (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              {tableData.columns.find(
                column =>
                  column.identifier === DailySummaryReceivedAndReleasedIdentifiers.INDEXES_SUM,
              )?.name || ""}
            </Typography>
          );
        },
        size: 88,
        cell: info => (
          <Typography className="text-right w-100" fontSize="12" fontWeight="700">
            {info.getValue() || "---"}
          </Typography>
        ),
      }),
      columnHelper.accessor(row => row.PACKAGES_SUM.value, {
        id: "PACKAGES_SUM",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            {tableData.columns.find(
              column =>
                column.identifier === DailySummaryReceivedAndReleasedIdentifiers.PACKAGES_SUM,
            )?.name || ""}
          </Typography>
        ),
        size: 88,
        cell: info => (
          <Typography className="text-right w-100" fontSize="12" fontWeight="700">
            {info.getValue() || "---"}
          </Typography>
        ),
      }),
      ...dynamicColumns.map(dynamicColumn => {
        return dynamicColumnHelper.accessor(row => row[dynamicColumn.identifier].value, {
          id: dynamicColumn.identifier,
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              {tableData.columns.find(column => column.identifier === dynamicColumn.identifier)
                ?.name || ""}
            </Typography>
          ),
          size: 73,
          cell: info => (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {info.getValue() || "---"}
            </Typography>
          ),
        });
      }),
      columnHelper.accessor(row => row.OTHERS.value, {
        id: "OTHERS",
        header: () => {
          return (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              {tableData.columns.find(
                column => column.identifier === DailySummaryReceivedAndReleasedIdentifiers.OTHERS,
              )?.name || ""}
            </Typography>
          );
        },
        size: 73,
        cell: info => (
          <Typography className="text-right w-100" fontSize="12" fontWeight="700">
            {info.getValue() || "---"}
          </Typography>
        ),
      }),
    ];
  }, [filters.rowBy, tableData]);
};

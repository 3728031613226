import { useEffect, useRef } from "react";
import { useField, useFormikContext } from "formik";
import { usePrevious } from "hooks";

/**
 * Component is a hacky workaround to set/clear externally controlled fields errors.
 * It requires form field to exist in form, even if it's controlled externally (but it can be static undefined).
 */
export const FormikExternalFieldErrorHandler = ({ name, isValid, message }: any) => {
  const values = { name };
  const initialRender = useRef(true);
  const [, meta] = useField<typeof values>({ name });
  const formikBag = useFormikContext<typeof values>();
  const { setFieldError, setFieldTouched } = formikBag;
  const wasValidBefore = usePrevious(isValid);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (!wasValidBefore && isValid) {
      setFieldError(name, "");
      if (!meta.touched) {
        setFieldTouched(name);
      }
    } else if (wasValidBefore && !isValid) {
      setFieldError(name, message);
      if (!meta.touched) {
        setFieldTouched(name);
      }
    }
  }, [isValid, name, message, setFieldError, setFieldTouched, meta.touched, wasValidBefore]);

  return null;
};

import { dateFns } from "utilities";

export function formatDate(date?: string | Date | null, displayFormat?: string) {
  const dateFormat = displayFormat ?? "yyyy-MM-dd";
  if (!date) return "";
  if (date instanceof Date) {
    return dateFns.format(date, dateFormat);
  } else {
    return dateFns.format(new Date(date), dateFormat);
  }
}

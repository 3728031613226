import { useState } from "react";
import * as React from "react";
import CopyToClipboardComponent from "react-copy-to-clipboard";
import styles from "./ToClipboard.module.css";
import copySignature from "assets/images/156.svg";
import copyUrl from "assets/images/158.svg";
import cx from "classnames";
import { Button, KindType, SizeType } from "components/common";

interface BaseProps {
  size?: SizeType;
  kind?: KindType;
  text: string;
  title?: string;
  children?: React.ReactNode;
}

interface ToClipBoardProps extends BaseProps {
  imgSrc: string;
}

interface BtnProps extends BaseProps {
  className?: string;
  stopPropagation?: boolean;
}

/**
 * @deprecated
 */
export const UrlToClipboard = ({ pathname }: { pathname?: string }) => (
  <ToClipboard
    title="Skopiuj adres strony"
    text={pathname ? `${window.location.origin}${pathname}` : window.location.href}
    imgSrc={copyUrl}
  />
);

/**
 * @deprecated
 */
export const TextToClipboard = ({ text, children }: Pick<BaseProps, "children" | "text">) => (
  <ToClipboard title="Skopiuj sygnaturę" text={text} imgSrc={copySignature}>
    {children}
  </ToClipboard>
);

/**
 * @deprecated
 */
export const ToClipboard = ({ children, text = "", title, imgSrc }: ToClipBoardProps) => {
  const [isAnimating, setIsAnimating] = useState(false);

  return (
    <div className={cx(styles.container)} title={title}>
      {children}
      <CopyToClipboardComponent text={text}>
        <div
          className={cx("ml-1 position-relative", styles.icon)}
          onClick={() => setIsAnimating(true)}
        >
          <img src={imgSrc} alt="kopiuj" />
          <div
            className={cx(styles.checkIcon, { [styles.successCheckAnimation]: isAnimating })}
            onAnimationEnd={() => setIsAnimating(false)}
          ></div>
        </div>
      </CopyToClipboardComponent>
    </div>
  );
};

/**
 * @deprecated
 */
export const TextToClipboardBtn = ({
  kind = "secondary-grey",
  size = "rounded",
  children,
  text = "",
  title,
  className,
  stopPropagation = false,
}: BtnProps) => {
  const [isAnimating, setIsAnimating] = useState(false);

  return (
    <div className={cx(className, styles.container, "position-relative ")} title={title}>
      <CopyToClipboardComponent text={text}>
        <Button kind={kind} size={size} type="button" className="btn">
          <div
            onClick={() => setIsAnimating(true)}
            onMouseDown={e => {
              stopPropagation && e.stopPropagation();
            }}
            className={cx("ml-1 d-flex justify-content-center", styles.icon)}
          >
            <img src={copySignature} alt="kopiuj" />
          </div>
          <div
            className={cx(styles.checkIcon, { [styles.successCheckAnimation]: isAnimating })}
            onAnimationEnd={() => setIsAnimating(false)}
          ></div>
          {children}
        </Button>
      </CopyToClipboardComponent>
    </div>
  );
};

import styles from "./InvoicePagination.module.css";
import cx from "classnames";
import arrowLeft from "assets/images/keyboardArrowLeft.svg";
import arrowRight from "assets/images/keyboardArrowRight.svg";
import { getPagination, noop } from "utilities";
import { memo } from "react";
import { useQuery } from "components/utils/queryProvider/useQuery";
import { StateProxy } from "components/utils";
import { ArrowButton } from "./components/ArrowButton";

interface Props {
  pagination: ReturnType<typeof getPagination>;
  page?: number;
  hasPadding?: boolean;
  isFetching?: boolean;
  mode?: "auto" | "manual";
  onChange?: (page: number) => void;
  overrides?: { wrapper?: { className?: string } };
  position?: "absolute" | "relative";
}

export const InvoicePagination = memo(
  ({
    pagination,
    mode = "auto",
    onChange = noop,
    hasPadding = true,
    page,
    isFetching = false,
    overrides = {},
    position = "absolute",
  }: Props) => {
    const { query, setQuery } = useQuery();
    const { limit, count, pageSize } = pagination;
    const debounceRequest = Number(page || query.page || 1) !== pagination.page;

    const handlePage = (page: number) => {
      if (mode === "auto") {
        setQuery({ ...query, page });
      }
      onChange(page);
    };
    if (!pagination.count) return null;
    const paginationNotNeeded = !pagination.pageSize || pagination.count <= pagination.pageSize;
    return (
      <div
        className={cx("d-flex align-items-center", overrides.wrapper?.className, {
          "px-3 py-2": position === "absolute",
          [styles.pagination]: position === "absolute",
          [styles.relativePagination]: position === "relative",
          "py-2 gap-2": position === "relative" && hasPadding,
        })}
      >
        <StateProxy
          state={page || Number(query.page) || 1}
          debounce={debounceRequest ? 1000 : undefined}
          onChange={handlePage}
        >
          {({ state, setState }) => (
            <>
              <div className={cx(styles.paginationPages, "d-flex align-items-center gap-1 mr-2")}>
                <div className="d-flex align-items-center">
                  <ArrowButton
                    alt="Strona poprzednia"
                    onClick={() => (state < 2 ? null : setState(s => s - 1))}
                    disabled={state <= 1 || paginationNotNeeded}
                    src={arrowLeft}
                  />
                  <ArrowButton
                    alt="Strona następna"
                    onClick={() => (!limit || state >= limit ? null : setState(s => s + 1))}
                    disabled={!limit || state >= limit || paginationNotNeeded}
                    src={arrowRight}
                  />
                </div>

                <span className="d-inline-flex mr-2">Wyniki</span>

                {!pageSize || !count ? (
                  "--"
                ) : (
                  <strong>
                    {state * pageSize - pageSize + 1}-
                    {state * pageSize > count ? count : state * pageSize}
                  </strong>
                )}
                <span>/&nbsp;&nbsp;{count}</span>
              </div>
            </>
          )}
        </StateProxy>
      </div>
    );
  },
);

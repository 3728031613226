import { Unloading, UnloadingDetails } from "api/wms/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { format, isToday, isYesterday } from "date-fns";
import { dateFns } from "utilities";

interface Props {
  fontSize: TypographyProps["fontSize"];
  fontWeight: TypographyProps["fontWeight"];
  unloading: Unloading | UnloadingDetails;
}

export const UnloadingFinish = ({ fontSize, fontWeight, unloading }: Props) => {
  if (!unloading.finishedAt)
    return (
      <Typography fontSize={fontSize} fontWeight={fontWeight}>
        ---
      </Typography>
    );

  if (unloading.finishedAt) {
    if (isToday(new Date(unloading.finishedAt))) {
      return (
        <Typography fontSize={fontSize} fontWeight={fontWeight}>
          {format(new Date(unloading.finishedAt), "H:mm")}
        </Typography>
      );
    }
    if (isYesterday(new Date(unloading.finishedAt))) {
      return (
        <Typography fontSize={fontSize} fontWeight={fontWeight}>
          wczoraj, {dateFns.format(new Date(unloading.finishedAt), "H:mm")}
        </Typography>
      );
    }
    return (
      <Typography fontSize={fontSize} fontWeight={fontWeight}>
        {dateFns.format(new Date(unloading.finishedAt), "d.MM.yyyy")}
      </Typography>
    );
  }

  return null;
};

import { useMemo } from "react";
import { useQuery } from "hooks";

export const useInfoWindow = () => {
  const { query, updateQuery } = useQuery();
  const { infoWindowPoint } = query;

  const toggleInfoWindow = (
    orderId: number,
    point: {
      lat: number;
      lng: number;
    },
  ) => {
    if (infoWindowPoint === JSON.stringify({ point, id: orderId })) {
      updateQuery({ infoWindowPoint: "" });
    } else {
      updateQuery({
        infoWindowPoint: JSON.stringify({ point, id: orderId }),
      });
    }
  };

  const openedInfoWindowData = useMemo<
    | {
        id: number;
        point: {
          lat: number;
          lng: number;
        };
      }
    | undefined
  >(() => {
    return infoWindowPoint ? JSON.parse(infoWindowPoint) : undefined;
  }, [infoWindowPoint]);

  const closeInfoWindow = () => {
    updateQuery({ infoWindowPoint: "" });
  };

  return { toggleInfoWindow, openedInfoWindowData, closeInfoWindow };
};

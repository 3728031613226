import { RadioLabels } from "components/utils";
import cx from "classnames";
import styles from "../AddRoutesFiltersModal.module.css";
import React from "react";
import { DatePicker } from "components/utils/datePicker";
import { dateFns } from "utilities";

interface Props {
  departureDate: string;
  isNull: boolean;
  setDepartureDate: React.Dispatch<React.SetStateAction<string>>;
  setIsNull: React.Dispatch<React.SetStateAction<boolean>>;
}

const isNullOptions: { id: "true" | "false"; name: string }[] = [
  { id: "true", name: "Nie przypięte" },
  { id: "false", name: "Przypięte" },
];

export const AdditionalRoutesFilters = ({
  departureDate,
  isNull,
  setDepartureDate,
  setIsNull,
}: Props) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="d-flex align-items-center justify-content-center mr-2">
        <span className={cx(styles.checkedNumberLabel, "pt-1 mr-2")}>produkty:</span>
        <RadioLabels
          items={isNullOptions}
          label=""
          name="isNull"
          onChange={({ value }) =>
            setIsNull(() => {
              if (value === "true") {
                return true;
              } else {
                return false;
              }
            })
          }
          value={String(isNull)}
        />
      </div>
      <span className={cx(styles.checkedNumberLabel, "pt-1")}>data wyjazdu:</span>
      <DatePicker
        className="d-inline-flex pt-1"
        onChange={date => {
          if (!date) {
            setDepartureDate("");
          } else {
            setDepartureDate(dateFns.format(new Date(date), "yyyy-MM-dd"));
          }
        }}
        overwrites={{ popup: { className: styles.datePickerPopup } }}
        value={departureDate}
      />
    </div>
  );
};

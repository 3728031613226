import { InputQuery } from "components/utils/queryProvider";
import { setMonthCheck } from "./setMonthCheck";

export const handleCheckedMonth = (
  dateRanges: string[] | string | null,
  startDateName: string,
  endDateName: string,
  name: string,
  queryStartDate: string,
  queryEndDate: string,
  monthsToDisplay: {
    checked: boolean;
    key: string;
    label: string;
    name: string;
    position: number;
    value: string[];
  }[],
  setMonthsToDisplay: React.Dispatch<
    React.SetStateAction<
      {
        checked: boolean;
        key: string;
        label: string;
        name: string;
        position: number;
        value: string[];
      }[]
    >
  >,
  updateQuery: (arg: InputQuery) => void,
  additionalKeys: [string, string] | undefined,
) => {
  const [additionalStartDate, additionalEndDate] = additionalKeys ? additionalKeys : ["", ""];

  if (!dateRanges) return;

  const [startDate, endDate] = typeof dateRanges === "string" ? dateRanges.split(",") : dateRanges;

  setMonthCheck(false, name, setMonthsToDisplay);

  if (endDate === queryEndDate) {
    const currentMonth = monthsToDisplay.find(month => month.name === name);

    if (currentMonth) {
      handleEndDateChange(
        currentMonth,
        monthsToDisplay,
        startDateName,
        endDateName,
        updateQuery,
        additionalStartDate,
        additionalEndDate,
      );
    }
  }

  if (startDate === queryStartDate) {
    const currentMonth = monthsToDisplay.find(month => month.name === name);
    if (currentMonth) {
      handleStartDateChange(
        currentMonth,
        monthsToDisplay,
        startDateName,
        endDateName,
        updateQuery,
        additionalStartDate,
        additionalEndDate,
      );
    }
  }
};

const handleStartDateChange = (
  currentMonth: {
    checked: boolean;
    key: string;
    label: string;
    name: string;
    position: number;
    value: string[];
  },
  monthsToDisplay: {
    checked: boolean;
    key: string;
    label: string;
    name: string;
    position: number;
    value: string[];
  }[],
  startDateName: string,
  endDateName: string,
  updateQuery: (arg: InputQuery) => void,
  additionalStartDate: string,
  additionalEndDate: string,
) => {
  let nextCheckedMonthStartDate: string = "";
  let isCurrentMonth = true;
  if (additionalStartDate.length > 0 && additionalEndDate.length > 0) {
    for (const month of monthsToDisplay.reverse()) {
      if (isCurrentMonth && month.name === currentMonth.name) {
        isCurrentMonth = false;
      }

      if (!isCurrentMonth && month.checked && month.name !== currentMonth.name) {
        nextCheckedMonthStartDate = month.value[0];
        updateQuery({
          [startDateName]: nextCheckedMonthStartDate,
          [additionalStartDate]: nextCheckedMonthStartDate,
          page: 1,
        });
        break;
      } else if (month.name === monthsToDisplay[monthsToDisplay.length - 1].name) {
        if (monthsToDisplay.filter(month => month.checked).length === 1) {
          updateQuery({
            [startDateName]: "",
            [additionalStartDate]: "",
            [endDateName]: "",
            [additionalEndDate]: "",
            page: 1,
          });
        } else {
          updateQuery({
            [startDateName]: "",
            [additionalStartDate]: "",
            page: 1,
          });
        }
      }
    }
  } else {
    for (const month of monthsToDisplay.reverse()) {
      if (isCurrentMonth && month.name === currentMonth.name) {
        isCurrentMonth = false;
      }

      if (!isCurrentMonth && month.checked && month.name !== currentMonth.name) {
        nextCheckedMonthStartDate = month.value[0];
        updateQuery({
          [startDateName]: nextCheckedMonthStartDate,
          page: 1,
        });
        break;
      } else if (month.name === monthsToDisplay[monthsToDisplay.length - 1].name) {
        if (monthsToDisplay.filter(month => month.checked).length === 1) {
          updateQuery({
            [startDateName]: "",
            [endDateName]: "",
            page: 1,
          });
        } else {
          updateQuery({
            [startDateName]: "",
            page: 1,
          });
        }
      }
    }
  }
};

const handleEndDateChange = (
  currentMonth: {
    checked: boolean;
    key: string;
    label: string;
    name: string;
    position: number;
    value: string[];
  },
  monthsToDisplay: {
    checked: boolean;
    key: string;
    label: string;
    name: string;
    position: number;
    value: string[];
  }[],
  startDateName: string,
  endDateName: string,
  updateQuery: (arg: InputQuery) => void,
  additionalStartDate: string,
  additionalEndDate: string,
) => {
  let previousCheckedMonthEndDate = "";
  let isCurrentMonth = true;

  if (additionalStartDate.length > 0 && additionalEndDate.length > 0) {
    for (const month of monthsToDisplay) {
      if (isCurrentMonth && month.name === currentMonth.name) {
        isCurrentMonth = false;
      }

      if (!isCurrentMonth && month.checked && month.name !== currentMonth.name) {
        previousCheckedMonthEndDate = month.value[1];
        updateQuery({
          [endDateName]: previousCheckedMonthEndDate,
          [additionalEndDate]: previousCheckedMonthEndDate,
          page: 1,
        });
        break;
      } else if (month.name === monthsToDisplay[monthsToDisplay.length - 1].name) {
        if (monthsToDisplay.filter(month => month.checked).length === 1) {
          updateQuery({
            [startDateName]: "",
            [additionalStartDate]: "",
            [endDateName]: "",
            [additionalEndDate]: "",
            page: 1,
          });
        } else {
          updateQuery({
            [endDateName]: "",
            [additionalEndDate]: "",
            page: 1,
          });
        }
      }
    }
  } else {
    for (const month of monthsToDisplay) {
      if (isCurrentMonth && month.name === currentMonth.name) {
        isCurrentMonth = false;
      }

      if (!isCurrentMonth && month.checked && month.name !== currentMonth.name) {
        previousCheckedMonthEndDate = month.value[1];
        updateQuery({
          [endDateName]: previousCheckedMonthEndDate,
          page: 1,
        });
        break;
      } else if (month.name === monthsToDisplay[monthsToDisplay.length - 1].name) {
        if (monthsToDisplay.filter(month => month.checked).length === 1) {
          updateQuery({
            [startDateName]: "",
            [endDateName]: "",
            page: 1,
          });
        } else {
          updateQuery({
            [endDateName]: "",
            page: 1,
          });
        }
      }
    }
  }
};

import { Route } from "api/routes/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../RightPanel.module.css";
import cx from "classnames";
import { InitialsAvatar } from "components/utils/userAvatar/InitialsAvatar";
import { convertDateToPolishMonthAbbreviations } from "utilities/convertDateToPolishMonthAbbreviations";
import { useRoutePatchMutation } from "api/logistics/routes/hooks";
import { DatePicker } from "components/utils/datePicker";
import { dateFns } from "utilities";
import { useRoute, useRoutePutMutation } from "hooks/apiPrimitives";
import { useQuery, useToastr } from "hooks";
import { updateDeliveryMutation } from "../utils/updateDeliveryMutation";
import { TimePicker } from "components/utils/timePicker";

interface Props {
  route: Route;
}

export const GeneralInfoSection = ({ route }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const routePutMutation = useRoutePutMutation();
  const { refetch } = useRoute(panelId);
  const toastr = useToastr();

  const loadingDateMutation = useRoutePatchMutation();
  const loadingTimeMutation = useRoutePatchMutation();
  const departureDateMutation = useRoutePatchMutation();
  const departureTimeMutation = useRoutePatchMutation();
  const statusMutation = useRoutePatchMutation();

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;

    if (selectedValue !== "NEW" && selectedValue !== "SETTLED" && selectedValue !== "FINISHED") {
      statusMutation.mutate({
        id: route.id,
        toUpdate: { status: selectedValue as "DEPARTED" | "ROUTE_READY" },
      });
    }
  };

  return (
    <>
      <RightPanelSection>
        <InfoLabel className={styles.createdByContainer} title="utworzono przez">
          <div className="d-flex align-items-center gap-1">
            {route.createdBy ? (
              <>
                {route.createdBy.avatar ? (
                  <div className={cx(styles.avatar, styles.avatarSmall)}>
                    <img alt="avatar" src={route.createdBy.avatar} />
                  </div>
                ) : (
                  <InitialsAvatar
                    className={cx(styles.avatar, styles.avatarSmall, styles.initialsColor)}
                    firstName={route.createdBy.firstName}
                    lastName={route.createdBy.lastName}
                    style={{ margin: 0 }}
                  />
                )}
                <div className="overflow">
                  {route.createdBy.firstName} {route.createdBy.lastName}
                </div>
              </>
            ) : (
              <div className="fw-700">---</div>
            )}
            <div className="fw-700 fs-12">
              {route.created ? convertDateToPolishMonthAbbreviations(route.created) : "---"}
            </div>
          </div>
        </InfoLabel>
      </RightPanelSection>
      <RightPanelSection>
        <InfoLabel className="pb-3" title="data i godzina załadunku">
          <div className="d-flex align-items-center gap-2">
            <div className="position-relative ml-n1">
              <DatePicker
                className={styles.formHeight}
                disabled={loadingDateMutation.isLoading}
                label="data"
                look="common"
                onChange={date => {
                  if (!date) {
                    loadingDateMutation.mutate({
                      id: route.id,
                      toUpdate: { loadingDate: null },
                    });
                  } else {
                    loadingDateMutation.mutate({
                      id: route.id,
                      toUpdate: { loadingDate: dateFns.format(new Date(date), "yyyy-MM-dd") },
                    });
                  }
                }}
                overwrites={{
                  container: { className: styles.formHeight },
                  label: { className: styles.datePickerLabel },
                }}
                value={route.loadingDate || ""}
              />
            </div>
            <div className="position-relative">
              <TimePicker
                disabled={loadingTimeMutation.isLoading}
                inProgress={loadingTimeMutation.isLoading}
                label="godzina"
                look="common"
                onBlur={time =>
                  loadingTimeMutation.mutate({
                    id: route.id,
                    toUpdate: { departureTime: time || null },
                  })
                }
                overwrites={{
                  container: { className: styles.formHeight },
                  input: { className: styles.formHeight },
                }}
                value={route.loadingTime || ""}
              />
            </div>
          </div>
        </InfoLabel>
        <InfoLabel title="data i godzina wyjazdu">
          <div className="d-flex align-items-center gap-2">
            <div className="position-relative ml-n1">
              <DatePicker
                className={styles.formHeight}
                disabled={departureDateMutation.isLoading}
                label="data"
                look="common"
                onChange={date => {
                  if (!date) {
                    if (route.departureTime) {
                      updateDeliveryMutation(
                        route.departureTime,
                        null,
                        refetch,
                        route,
                        routePutMutation,
                        toastr,
                      );
                    } else {
                      departureDateMutation.mutate({
                        id: route.id,
                        toUpdate: { departureDate: null },
                      });
                    }
                  } else {
                    if (route.departureTime) {
                      updateDeliveryMutation(
                        route.departureTime,
                        dateFns.format(new Date(date), "yyyy-MM-dd"),
                        refetch,
                        route,
                        routePutMutation,
                        toastr,
                      );
                    } else {
                      departureDateMutation.mutate({
                        id: route.id,
                        toUpdate: { departureDate: dateFns.format(new Date(date), "yyyy-MM-dd") },
                      });
                    }
                  }
                }}
                overwrites={{
                  container: { className: styles.formHeight },
                  label: { className: styles.datePickerLabel },
                }}
                value={route.departureDate || ""}
              />
            </div>
            <div className="position-relative">
              <TimePicker
                disabled={departureTimeMutation.isLoading}
                inProgress={departureTimeMutation.isLoading}
                label="godzina"
                look="common"
                onBlur={time => {
                  if (route.departureDate) {
                    updateDeliveryMutation(
                      time || null,
                      route.departureDate,
                      refetch,
                      route,
                      routePutMutation,
                      toastr,
                    );
                  } else {
                    departureTimeMutation.mutate({
                      id: route.id,
                      toUpdate: { departureTime: time || null },
                    });
                  }
                }}
                overwrites={{
                  container: { className: styles.formHeight },
                  input: { className: styles.formHeight },
                }}
                value={route.departureTime || ""}
              />
            </div>
          </div>
        </InfoLabel>
        <InfoLabel title="status">
          <div className={cx(styles.select, styles.selectSmall, "ml-n1")}>
            <select id="" name="status" onChange={handleStatusChange} value={route.status}>
              <option disabled value="NEW">
                Nowa
              </option>
              <option value="ROUTE_READY">Gotowa</option>
              <option value="DEPARTED">W drodze</option>
              <option disabled value="FINISHED">
                Ukończona
              </option>
              <option disabled value="SETTLED">
                Rozliczona
              </option>
            </select>
          </div>
        </InfoLabel>
      </RightPanelSection>
    </>
  );
};

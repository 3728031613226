import { RefObject, useEffect, useState } from "react";

export const useKeyPress = function(targetKey: string, ref: RefObject<HTMLInputElement>) {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = ({ key }: { key: string }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };

    const upHandler = ({ key }: { key: string }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    ref.current?.addEventListener("keydown", downHandler);
    ref.current?.addEventListener("keyup", upHandler);

    return () => {
      ref.current?.removeEventListener("keydown", downHandler);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ref.current?.removeEventListener("keyup", upHandler);
    };
  }, [targetKey, ref]);

  return keyPressed;
};

import { configureStore } from "@reduxjs/toolkit";
import { rootReducer, Store } from "ducks";
import { initialState as toastersInitialState } from "ducks/toasters";
import immer from "immer";
import localforage from "localforage";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import { listenerMiddleware, startAppListeners } from "./storeListener";

export type AppDispatch = typeof store.dispatch;

const migrations = {
  0: (state: Store) => state,
  1: (state: Store) => {
    // dead migration, left because it's not allowed to decrement persist version
    return state;
  },
  2: (state: Store) => {
    return immer(state, draft => {
      draft.toasters = toastersInitialState;
    });
  },
  3: (state: Store) => {
    // dead migration, left because it's not allowed to decrement persist version
    return state;
  },
  /**
   * DO NOT create migrations for blacklisted parts of the state here! They have their own config
   */
};

// 'toastr' should be always on blacklist, we do not want to cache that!
const persistConfig = {
  key: "root",
  blacklist: ["partials", "ui"], // blacklisted reducers have their own persist config
  storage: localforage,
  version: 3,
  migrate: createMigrate(migrations as any, { debug: false }),
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }).prepend(listenerMiddleware.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

startAppListeners();

export const persistor = persistStore(store);

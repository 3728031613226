import styles from "../../WarehouseFinancialState.module.css";
import cx from "classnames";
import { usePackagesInOrOutSummary } from "api/trading-documents/hooks";
import { dateFns, queryString } from "utilities";
import { getDateThirtyDaysBeforeToday } from "../../utils/getDateThirtyDaysBeforeToday";
import { useFilters } from "hooks/useFilters";
import { Loader } from "components/utils/loader";
import { CommonError, RightPanelHandler } from "components/utils";
import { InvoicePagination } from "components/common/invoicePagination/InvoicePagination";
import { PackageInRow } from "./PackageInRow";
import { NoResults } from "components/utils/noResults";
import { ListDrawerWrapper } from "components/utils/drawer";
import { RightPanel } from "./rightPanel/RightPanel";
import { useQuery, useToastr } from "hooks";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import csvDownloadIcon from "assets/images/downloadCsv.svg";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { Button } from "components/common";
import ReactTooltip from "react-tooltip";
import { handleCsvDownload } from "../../utils/handleCsvDownload";

export type PackagesInFilters = {
  page: string;
  search: string;
  inAtFrom: string;
  inAtTo: string;
};

const initialFilters: PackagesInFilters = {
  page: "1",
  search: "",
  inAtFrom: getDateThirtyDaysBeforeToday(),
  inAtTo: dateFns.format(new Date(), "yyyy-MM-dd"),
};

export const PackagesInList = () => {
  const { query } = useQuery();
  const { warehouses, inAtFrom, inAtTo } = query;
  const { setFilter, filters } = useFilters<PackagesInFilters>(initialFilters);
  const location = useLocation();
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const toastr = useToastr();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const warehouses = searchParams.get("warehouses");
    const inAtFrom = searchParams.get("inAtFrom");
    const inAtTo = searchParams.get("inAtTo");

    if (warehouses || inAtFrom || inAtTo) {
      setFilter("page", "1");
    }
  }, [location.search, setFilter]);

  const search = queryString.stringify({ ...filters, warehouses, inAtFrom, inAtTo });
  const { data: packagesIn, error, isFetching, isLoading, pagination } = usePackagesInOrOutSummary(
    search,
  );

  const noResults = !packagesIn.length && !isLoading && !error;

  if (error) {
    return (
      <div className="w-100 pb-3">
        <div className={styles.listTable}>
          <h3 className={cx(styles.listHeader, "pt-3 pb-1 px-3")}>
            Przyjęto przez ostatnie 30 dni
          </h3>
          <div className="mt-4">
            <CommonError status={error._httpStatus_} />
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="w-100 pb-3">
        <Loader module="finances" />
      </div>
    );
  }

  return (
    <div className="w-100 pb-3">
      {isFetching && <Loader module="finances" />}
      <RightPanelHandler name="warehousePackagesInSummary" overrittenPanelKey="packageIn">
        {({ close, isActive, isHighlighted, togglePanel }) => (
          <ListDrawerWrapper>
            <div className={styles.listTable}>
              <h3 className={cx(styles.listHeader, "pt-3 pb-1 px-3")}>
                Przyjęto przez ostatnie 30 dni
              </h3>
              <div className="d-flex align-items-center justify-content-between px-3 pb-2">
                <div className={styles.search} style={{ maxWidth: "600px", minWidth: "320px" }}>
                  <input
                    type="search"
                    onChange={event => {
                      setFilter("search", event.target.value);
                      setFilter("page", "1");
                    }}
                    placeholder="Szukaj przyjętych paczek..."
                  />
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    kind="transparent-black"
                    onClick={() => {
                      handleCsvDownload(
                        downloadFeedbackToastr,
                        getCsvDownloadFileName(query),
                        getCsvDownloadQueryParams(query),
                        toastr,
                      );
                    }}
                    size="square-s"
                  >
                    <div className="btnBase btnBaseSmall" data-tip data-for="wh-in-csv">
                      <img alt="pobierz csv" src={csvDownloadIcon} />
                    </div>
                  </Button>
                  <ReactTooltip
                    className={styles.customTooltip}
                    id="wh-in-csv"
                    place="top"
                    effect="solid"
                    arrowColor="transparent"
                    offset={{ top: -10 }}
                  >
                    Pobierz CSV dla przyjętych paczek
                  </ReactTooltip>
                  {packagesIn.length > 0 ? (
                    <InvoicePagination
                      mode="manual"
                      onChange={page => {
                        setFilter("page", String(page));
                      }}
                      overrides={{ wrapper: { className: styles.paginationBackground } }}
                      page={Number(filters.page)}
                      pagination={pagination}
                      position="relative"
                    />
                  ) : (
                    <div style={{ height: "42px" }} />
                  )}
                </div>
              </div>
              <div className={cx(styles.tableHeader, styles.tableHeaderWarehouseState)}>
                <div>#</div>
                <div>nazwa paczki</div>
                <div>kod</div>
                <div>przyjęcie</div>
                <div className="d-flex align-items-center justify-content-end">ostatnia cena</div>
                <div className="d-flex align-items-center justify-content-end">przyjęto</div>
                <div className="d-flex align-items-center justify-content-end">suma</div>
              </div>
              {noResults ? (
                <NoResults
                  on={noResults}
                  overwrites={{ background: { className: styles.noResult } }}
                  subHeader="przyjęte paczki"
                />
              ) : (
                <div
                  className={cx(styles.list, "w-100")}
                  style={{ maxHeight: "50vh", height: "initial" }}
                >
                  {packagesIn.map((packageIn, index) => (
                    <PackageInRow
                      index={index}
                      isActive={isActive}
                      isHighlighted={isHighlighted}
                      key={`${packageIn.id}-in`}
                      packageIn={packageIn}
                      togglePanel={togglePanel}
                    />
                  ))}
                </div>
              )}
            </div>
            <RightPanel close={close} />
          </ListDrawerWrapper>
        )}
      </RightPanelHandler>
    </div>
  );
};

const getCsvDownloadQueryParams = (query: { [x: string]: string }): string => {
  return queryString.stringify({
    inAtFrom: query["inAtFrom"],
    inAtTo: query["inAtTo"],
  });
};

const getCsvDownloadFileName = (query: { [x: string]: string }): string => {
  const inAtFrom = query["inAtFrom"] ? query["inAtFrom"] : "";
  const inAtTo = query["inAtTo"] ? query["inAtTo"] : "";
  if (inAtFrom && inAtTo) return `csv-przyjęcie-${inAtFrom}-${inAtTo}`;
  return `csv-przyjęcie`;
};

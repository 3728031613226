import styles from "../../../../shared/CallCenter.module.css";
import localStyles from "../../../Dashboard.module.css";
import cx from "classnames";
import routeIcon from "assets/images/route.svg";
import { CallCenterDashboardIncompleteRoute } from "api/call-center/routes/models";
import { Button } from "components/common";
import { DepartureDateRange } from "./DepartureDateRange";
import { LinkToPage } from "components/utils/linkToPage";
import { Link } from "react-router-dom";

interface Props {
  incompleteRoute: CallCenterDashboardIncompleteRoute;
}

export const IncompleteRoute = ({ incompleteRoute }: Props) => {
  return (
    <div className={cx(localStyles.tableRow, localStyles.tableRowBokIncompleteRoutes)}>
      <div className={styles.overflow}>
        <LinkToPage
          content={incompleteRoute.signature}
          url={`/call-center/route/${incompleteRoute.id}/orders`}
        />
      </div>
      <DepartureDateRange incompleteRoute={incompleteRoute} />
      <div className="d-flex justify-content-end text-red-4">
        {incompleteRoute.ordersNotConfirmedCount}
      </div>
      <div className="d-flex justify-content-end">{incompleteRoute.ordersConfirmedCount}</div>
      <Button
        as={Link}
        kind="create-medium-transparent"
        onClick={e => {
          e.stopPropagation();
        }}
        style={{ height: "26px" }}
        to={`/call-center/route/${incompleteRoute.id}/orders`}
      >
        <div className="btnBase btnBaseSmall">
          <img alt="" src={routeIcon} />
          <div className={styles.routeTrackingBtn}>{String("Szczegóły").toUpperCase()}</div>
        </div>
      </Button>
    </div>
  );
};

import styles from "../RightPanel.module.css";
import cx from "classnames";
import { TradingDocument } from "api/trading-documents/models";
import { handleDateField } from "utilities";
import { Spinner } from "components/utils";
import { RightPanelSection } from "components/utils/drawer";
import { useViesSectionActions } from "./hooks/useViesSection";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiRestartAlt } from "components/miloDesignSystem/atoms/icons/MdiRestartAlt";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { viesStatusDict } from "api/trading-documents/constants";

interface Props {
  tradingDocument: TradingDocument;
}

export const ViesSection = ({ tradingDocument }: Props) => {
  const { viesStatusMutation, whiteListStatusMutation } = useViesSectionActions(tradingDocument.id);

  return (
    <RightPanelSection>
      <div className="d-flex gap-5 py-3 borderTop borderBottom">
        <div>
          <div className="d-flex align-items-center gap-2 pb-2">
            <Typography className={cx(styles.viesTitle, "pl-0")} fontSize="16" fontWeight="700">
              VIES
            </Typography>
            <IconButton
              icon={MdiRestartAlt}
              onClick={() => viesStatusMutation.mutate({ id: tradingDocument.id })}
              variant="gray"
            />
            <Spinner
              className={styles.tinySpinner}
              on={viesStatusMutation.isLoading}
              size="small"
              color="blue"
            />
          </div>
          <div className="d-flex align-items-center body-10 gap-2">
            <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
              ostatnio sprawdzony:
            </Typography>
            <Typography fontSize="10" fontWeight="500">
              {handleDateField(tradingDocument.viesCheckedAt)}
            </Typography>
          </div>
          <div className="d-flex align-items-center body-10 gap-2">
            <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
              wówczas:
            </Typography>
            <Typography fontSize="10" fontWeight="500">
              {tradingDocument.viesValidStatus
                ? viesStatusDict[tradingDocument.viesValidStatus]
                : "---"}
            </Typography>
          </div>
        </div>
        {tradingDocument.countryCode === "PL" && (
          <div>
            <div className="d-flex align-items-center gap-2 pb-2">
              <Typography className={cx(styles.viesTitle, "pl-0")} fontSize="16" fontWeight="700">
                Biała lista
              </Typography>
              <IconButton
                icon={MdiRestartAlt}
                onClick={() => whiteListStatusMutation.mutate({ id: tradingDocument.id })}
                variant="gray"
              />
              <Spinner
                className={styles.tinySpinner}
                on={whiteListStatusMutation.isLoading}
                size="small"
                color="blue"
              />
            </div>
            <div className="d-flex align-items-center body-10 gap-2">
              <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                ostatnio sprawdzony:
              </Typography>
              <Typography fontSize="10" fontWeight="500">
                {handleDateField(tradingDocument.whiteListCheckedAt)}
              </Typography>
            </div>
            <div className="d-flex align-items-center body-10 gap-2">
              <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                wówczas:
              </Typography>
              <Typography fontSize="10" fontWeight="500">
                {tradingDocument.whiteListValidStatus
                  ? viesStatusDict[tradingDocument.whiteListValidStatus]
                  : "---"}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </RightPanelSection>
  );
};

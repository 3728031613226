import { getPeriodsToDisplay } from "components/common/downloadSalesReports/utils/getPeriodsToDisplay";
import { isDateChipChecked } from "components/common/downloadSalesReports/utils/isDateChipChecked";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Chip } from "components/miloDesignSystem/atoms/chip";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DatePicker } from "components/utils/datePicker";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { endOfMonth } from "date-fns";
import { fileDownloader } from "fileDownloader";
import { useToastr } from "hooks";
import { useState } from "react";
import { dateFns, getAnyErrorKey, getIsoDateFormat } from "utilities";
import styles from "./DownloadRoutesReportModal.module.css";
import { routeFileFactory } from "api/routes/calls";

interface Props {
  close: () => void;
}

export const DownloadRoutesReportModal = ({ close }: Props) => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const toastr = useToastr();
  const today = new Date();
  const [startDate, setStartDate] = useState(
    dateFns.format(dateFns.startOfMonth(dateFns.subMonths(today, 1)), "yyyy-MM-dd"),
  );
  const [endDate, setEndDate] = useState(
    dateFns.format(endOfMonth(dateFns.subMonths(today, 1)), "yyyy-MM-dd"),
  );
  const periodsToDisplay = getPeriodsToDisplay();

  const handleDownloadRoutesReportPdf = async () => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf", calculateProgress: false });

    const { url, name } = routeFileFactory.routesReport(startDate, endDate);
    const response = await fileDownloader({
      url,
      name,
      type: "pdf",
    });

    if (response.status === "success") {
      tstr.lazyClose();
      close();
    } else if (response.error) {
      tstr.lazyClose();
      toastr.open({
        type: response.httpStatus === 400 ? "warning" : "failure",
        title: response.httpStatus === 400 ? "Wymagane działanie" : "Oj, coś nie tak...",
        text: getAnyErrorKey(response.error),
      });
    }
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Pobierz raport z tras
        </Typography>
      }
      width={480}
    >
      <div className="p-3">
        <div className="pb-1">
          <Typography className="mb-3" fontSize="14" fontWeight="700">
            Data wysłania trasy
          </Typography>
          <div className="d-flex gap-1">
            <div className="position-relative mr-1">
              <span className={styles.label}>Od:</span>
              <DatePicker
                placeholder="Wybierz"
                value={startDate}
                selectsStart
                removeDate={false}
                tabIndex={-1}
                look="common"
                startDate={startDate ? new Date(startDate) : null}
                endDate={startDate ? new Date(startDate) : null}
                maxDate={endDate ? new Date(endDate) : null}
                onChange={date => {
                  if (!date) return;
                  setStartDate(getIsoDateFormat(date));
                }}
              />
            </div>
            <div className="position-relative">
              <span className={styles.label}>Do:</span>
              <DatePicker
                placeholder="Wybierz"
                tabIndex={-1}
                removeDate={false}
                value={endDate}
                selectsEnd
                look="common"
                startDate={startDate ? new Date(startDate) : null}
                endDate={endDate ? new Date(endDate) : null}
                minDate={startDate ? new Date(startDate) : null}
                onChange={date => {
                  if (!date) return;
                  setEndDate(getIsoDateFormat(date));
                }}
              />
            </div>
          </div>
        </div>
        <div className="mb-3 mt-2 d-flex align-items-center gap-2">
          {periodsToDisplay.map(period => (
            <Chip
              label={period.label}
              onClick={() => {
                if (isDateChipChecked(startDate, endDate, period.startDate, period.endDate)) {
                  setStartDate("");
                  setEndDate("");
                } else {
                  setStartDate(period.startDate);
                  setEndDate(period.endDate);
                }
              }}
              selected={isDateChipChecked(startDate, endDate, period.startDate, period.endDate)}
              variant="primary"
            />
          ))}
        </div>
        <div className="d-flex align-items-center gap-3 pt-3 borderTop">
          <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
            Anuluj
          </Button>
          <Button
            className="text-uppercase"
            onClick={handleDownloadRoutesReportPdf}
            size="medium"
            variant="deepPurple"
          >
            Pobierz raport
          </Button>
        </div>
      </div>
    </Modal>
  );
};

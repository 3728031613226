export function arrayToDict<
  ArrElement extends { [N in Name]: number | string },
  Name extends string = "id"
>(arr: ArrElement[], options: { name: Name } = { name: "id" as Name }) {
  const name = options.name ?? "id";
  return arr.reduce((acc: Record<number | string, ArrElement>, el) => {
    acc[el[name]] = el;
    return acc;
  }, {});
}

import { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./component.module.css";

export default function SliderRail({ getRailProps }) {
  return (
    <Fragment>
      <div className={styles.railOuterStyle} {...getRailProps()} />
      <div className={styles.railInnerStyle} />
    </Fragment>
  );
}

SliderRail.propTypes = {
  getRailProps: PropTypes.func.isRequired,
};

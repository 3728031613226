import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { dateFns } from "utilities";
import { WayfairPdfLang } from "./models";

const api = tokenRefreshMiddleware(apiFetch);

interface WayFairPost {
  salesAccount: number;
  orders: number[];
  pickupDate: string;
  label: boolean;
  packingSlip: boolean;
  billOfLading: boolean;
}

export const requestWayFairDocuments = (data: WayFairPost) =>
  api<{ message: string }>({
    method: "POST",
    url: "/wayfair/request-documents",
    data,
  });

export const wayFairFileFactory = (() => {
  function formatSignature(signature: string) {
    return signature.replace(/\//g, "-");
  }
  function getDate() {
    return dateFns.format(new Date(), "dd-MM-yyyy-HH-mm");
  }

  return {
    wayFairDocuments: (search: string, signature: string) => ({
      url: `/wayfair/get-documents${search}`,
      name: `${formatSignature(signature)}${getDate()}`,
    }),
    wayFairPDF: (type: "group" | "route", id: number, lang: WayfairPdfLang) => ({
      url: `/wayfair/get-wayfair-document/${type}/${id}/lang=${lang}`,
      name: `${id}${getDate()}`,
    }),
  };
})();

export const getWayFairDocumentsStatus = (search: string) =>
  api<{ message: string; isReady: boolean; missing: string }>({
    method: "GET",
    url: "/wayfair/get-documents-status" + search,
  });

export const getWayFairDocuments = (search: string) =>
  api<ArrayBuffer | { message: string; isReady: boolean; missing: string }>({
    method: "GET",
    url: "/wayfair/get-documents" + search,
    headers: {
      "Content-Type": "application/pdf",
    },
    responseType: "arraybuffer",
  });

import { ConfirmationDateOptions } from "api/milo-settings/models";
import { Button } from "components/common";
import { Modal, StatusHandler } from "components/utils";
import { useState } from "react";
import { ConfirmResendingModal, dayOptions, SmsModal, Toastr } from "api/logistics/routes/models";
import { AddCustomOption } from "pages/routes/routesList/rightPanel/pointsSection/confirmationDateModal/AddCustomOption";
import { ConfirmationDateBody } from "./shared/ConfirmationDateBody";
import { sendEmailToAll } from "../../utils/sendEmailToAll";
import { ToggleHookState } from "hooks";
import { Route } from "api/routes/models";
import styles from "./ConfirmationDateModals.module.css";

interface Props {
  confirmResendingModal: ConfirmResendingModal;
  emailForAllConfirmationModal: ToggleHookState;
  options: ConfirmationDateOptions[];
  route: Route;
  smsModal: SmsModal;
  toastr: Toastr;
}

export const EmailForAllConfirmationDateModal = ({
  confirmResendingModal,
  emailForAllConfirmationModal,
  options,
  route,
  smsModal,
  toastr,
}: Props) => {
  const [allOptions, setAllOptions] = useState(options);
  const [dayOption, setDayOption] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [picked, setPicked] = useState<ConfirmationDateOptions | null>(null);
  const [time, setTime] = useState("");

  return (
    <Modal
      close={emailForAllConfirmationModal.close}
      isOpen={true}
      overrides={{ container: { className: styles.modal } }}
    >
      <div>
        <ConfirmationDateBody allOptions={allOptions} picked={picked} setPicked={setPicked} />

        <AddCustomOption
          allOptions={allOptions}
          dayOption={dayOption}
          dayOptions={dayOptions}
          isOpen={isOpen}
          setAllOptions={setAllOptions}
          setDayOption={setDayOption}
          setIsOpen={setIsOpen}
          setPicked={setPicked}
          setTime={setTime}
          time={time}
        />

        <div className="d-flex align-items-center gap-2">
          <StatusHandler>
            {helpers => (
              <Button
                kind="secondary-grey"
                onClick={() => {
                  sendEmailToAll(
                    null,
                    confirmResendingModal,
                    emailForAllConfirmationModal,
                    helpers,
                    route,
                    smsModal,
                    toastr,
                  );
                }}
              >
                Pomiń i wyślij Email
              </Button>
            )}
          </StatusHandler>
          <StatusHandler>
            {helpers => (
              <Button
                disabled={helpers.isFetching || picked === null}
                kind="primary"
                onClick={() => {
                  if (picked) {
                    sendEmailToAll(
                      {
                        option: picked.deadlineDayOption,
                        time: picked.deadlineTime.slice(0, 5),
                      },
                      confirmResendingModal,
                      emailForAllConfirmationModal,
                      helpers,
                      route,
                      smsModal,
                      toastr,
                    );
                  }
                }}
              >
                Wyślij Email z datą potwierdzenia
              </Button>
            )}
          </StatusHandler>
        </div>
      </div>
    </Modal>
  );
};

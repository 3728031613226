import { Header } from "./components/Header";
import styles from "./WarehouseFinancialState.module.css";
import cx from "classnames";
import { PackagesInList } from "./components/packagesInList/PackagesInList";
import { PackagesOutList } from "./components/packagesOutList/PackagesOutList";

export const WarehouseFinancialState = () => {
  return (
    <div className="position-relative">
      <Header />
      <div className={styles.mainContent}>
        <div className={cx(styles.mainContentInner, "flex-wrap")}>
          <div className={cx(styles.contentScroll, "d-flex flex-wrap w-100")}>
            <PackagesInList />
            <PackagesOutList />
          </div>
        </div>
      </div>
    </div>
  );
};

import { Route } from "api/routes/models";
import { RightPanelTableRow } from "components/utils/drawer";
import { routePointsSectionListConfig } from "pages/logistics/shared/utils/panelTablesConfig";
import { orderArrayByIdSchema } from "utilities";
import { pickOrdersIdsFromOrdersPositions } from "../../utils/pickOrdersIdsFromOrdersPositions";
import { RoutePoint } from "./RoutePoint";
import localStyles from "./RoutePoints.module.css";

interface Props {
  route: Route;
}

export const RoutePoints = ({ route }: Props) => {
  return (
    <div className="pb-3">
      <RightPanelTableRow
        className={localStyles.tableHeaderRoutePoint}
        grid={routePointsSectionListConfig.grid}
        hover={false}
      >
        <div>#</div>
        <div>adres | kilent</div>
        <div>zamówienie</div>
        <div>konto</div>
        <div>czas dostawy</div>
        <div>powiadomienie</div>
        <div className="d-flex align-items-center justify-content-center">odbiór</div>
        <div className="d-flex align-items-center">dokumenty</div>
      </RightPanelTableRow>
      {orderArrayByIdSchema(
        route.orders,
        pickOrdersIdsFromOrdersPositions(route.ordersPositions),
      ).map((order, index) => (
        <RoutePoint index={index} key={order.id} order={order} />
      ))}
    </div>
  );
};

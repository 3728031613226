import { useFilters } from "hooks/useFilters";
import { queryString } from "utilities";

export type CargoUnloadsFilters = {
  page: string;
  pageSize: string | number;
  search: string;
};

const initialFilters: CargoUnloadsFilters = {
  page: "1",
  pageSize: "7",
  search: "",
};

export const useSearch = () => {
  const { filters } = useFilters<CargoUnloadsFilters>(initialFilters);
  return queryString.stringify(filters);
};

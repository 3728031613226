import { Plurals } from "smart-plurals";

interface CasesPL {
  singular: any;
  plural: any;
  other: any;
}
/**
 * Pluralizes words based on some amount.
 * @example
 * pluralize.pl(1, { singular: "element", plural: "elementy", other: "elementów" })
 */
export const pluralize = (function() {
  function langRulesFactory(code: string, dict: string[]) {
    const languageRule: (num: number, dict: string[]) => keyof CasesPL = Plurals.getRule(code);
    function returnValue(number: number, cases: CasesPL) {
      return cases[languageRule(number, dict)];
    }
    // pin dict to returned function. It is used in TransProvider fot better error handling
    returnValue.dict = dict;
    return returnValue;
  }
  return {
    pl: langRulesFactory("pl", ["singular", "plural", "other"]),
    en: langRulesFactory("en", ["singular", "plural"]),
  };
})();

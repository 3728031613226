import { RightPanelHeader } from "components/utils/drawer";
import { Button } from "components/common";
import checkIcon from "assets/images/check_small.svg";
import arrowDownIcon from "assets/images/keyboardArrowDown.svg";
import styles from "../RightPanel.module.css";
import { PopoverMenu } from "components/utils/Popover";
import { RadioOption, RadioOptions } from "components/common/radioOptions";
import {
  InvoiceReviewer,
  InvoiceReviewerStatus,
  PurchaseInvoiceToReview,
} from "api/trading-documents/models";
import { useQuery, useSelector } from "hooks";
import { User } from "ducks/auth";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import { patchReviewStatus } from "api/trading-documents/calls";
import { getAnyErrorKey, queryString } from "utilities";
import { QueryClient } from "react-query";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Pagination } from "api/types";

interface Props {
  invoiceToReview: PurchaseInvoiceToReview;
}

export const PanelHeader = ({ invoiceToReview }: Props) => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const pageSize = { pageSize: 1 };
  const search = queryString.stringify({ ...query, ...pageSize });
  const me = useSelector(store => store.auth.user!);

  const handleRollback = (context: unknown, queryClient: QueryClient) => {
    queryClient.setQueryData<Pagination<PurchaseInvoiceToReview>>(
      tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
      currentList => {
        assertIsDefined(currentList);
        return {
          ...currentList,
          // @ts-ignore
          results: context.results,
        };
      },
    );
  };

  const updateReviewStatusOptions = useMutationOptions(
    ({ data }: { data: { status: InvoiceReviewerStatus | string } }) => {
      const dataWithTradingDocumentId = { ...data, tradingDocument: invoiceToReview.id };
      return patchReviewStatus(dataWithTradingDocumentId);
    },
    ({ toastr, queryClient }) => ({
      onMutate: args => {
        const prevList = queryClient.getQueryData(
          tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
        );

        queryClient.setQueryData<Pagination<PurchaseInvoiceToReview>>(
          tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
          // @ts-ignore
          currentList => {
            assertIsDefined(currentList);

            return {
              ...currentList,
              results: currentList.results.map((result, index) => {
                if (index === 0) {
                  return {
                    ...result,
                    reviewers: result.reviewers.map(reviewer => {
                      if (reviewer.user.id === me.id) {
                        return { ...reviewer, status: args.data.status };
                      }
                      return reviewer;
                    }),
                  };
                }
                return result;
              }),
            };
          },
        );

        return prevList;
      },
      onSuccess: () => {
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Pomyślnie zmieniono status recenzji",
        });
      },
      onError: (error, _, context) => {
        handleRollback(context, queryClient);
        toastr.open({
          type: "warning",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const updateReviewStatusMutation = useMutation(
    updateReviewStatusOptions.mutationFn,
    updateReviewStatusOptions,
  );

  const submitReviewStatus = (value: string) => {
    updateReviewStatusMutation.mutate({ data: { status: value } });
  };

  return (
    <RightPanelHeader>
      <div className="d-flex align-items-center justify-content-end gap-1 w-100">
        <div style={{ position: "relative" }}>
          <PopoverMenu
            target={btnProps => (
              <div role="button" {...btnProps}>
                <Button className={styles.approvalBtn} kind="create">
                  <div className="btnBase btnBaseSmall">
                    <img alt="Gotowe" src={checkIcon} style={{ height: "16px", width: "16px" }} />
                    Zaakceptuj fakturę
                    <img alt="Rozwiń" src={arrowDownIcon} />
                  </div>
                </Button>
              </div>
            )}
          >
            {({ close }) => (
              <RadioOptions
                btnDisabled={!isLoggedUserInReviewers(me, invoiceToReview.reviewers)}
                close={close}
                options={options}
                onSubmit={value => submitReviewStatus(String(value))}
              />
            )}
          </PopoverMenu>
        </div>
      </div>
    </RightPanelHeader>
  );
};

const isLoggedUserInReviewers = (user: User | null, reviewers: InvoiceReviewer[]): boolean => {
  if (user) {
    return reviewers.some(reviewer => reviewer.user.id === user.id);
  }
  return false;
};

const options: RadioOption[] = [
  { name: "Zaakceptuj", label: "Zaakceptuj importowaną fakturę", value: "ACCEPTED" },
  {
    name: "Zarządaj zmian",
    label: "Faktura wymaga zmian przed zatwierdzeniem",
    value: "CHANGES_REQUESTED",
  },
];

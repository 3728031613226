import { EmailStatus, Notification, NotificationAction } from "api/external-manufacturing/models";
import { UserWithInitials } from "api/users/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Tag, TagProps } from "components/miloDesignSystem/atoms/tag";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./NotificationsSection.module.css";
import { cx, getStandardDateFormat } from "utilities";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

const actionDict: Record<NotificationAction, string> = {
  REQUEST_PRODUCTION: "zlecenie produkcji",
  REMIND_ABOUT_DEADLINE: "przypomnienie o terminie",
  ASK_FOR_STATUS: "pytanie o status",
};

const notificationStatusDict: Record<
  EmailStatus,
  { label: string; variant: TagProps<string>["variant"] }
> = {
  OPENED: { label: "Odczytana", variant: "success" },
  SENT: { label: "Wysłana", variant: "deepPurple400" },
  DECLINED: { label: "Błąd wysyłki", variant: "danger" },
  CLICKED_LINK: { label: "Odczytana", variant: "success" },
};

export const useNotificationsColumns = () => {
  return useCreateTableColumns<Notification>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => actionDict[row.action], {
          header: "akcja",
          size: 110,
        }),
        columnHelper.text(row => (row.date ? getStandardDateFormat(row.date) : null), {
          header: "data",
          size: 80,
          typographyProps: {
            color: "neutralBlack48",
          },
        }),
        columnHelper.text(row => row.recipient, {
          header: "na adres",
          size: 270,
        }),
        columnHelper.accessor(row => row, {
          header: "wysyłka",
          size: 80,
          cell: info => {
            const notification: Notification = info.getValue();
            if (
              !notification.status ||
              (notification.status && !Boolean(notification.status.length))
            )
              return <EmptyValue />;
            if (notification.status === EmailStatus.DECLINED && Boolean(notification.error.length))
              return (
                <Tooltip title={notification.error}>
                  <div className="d-flex align-items-center">
                    <Tag
                      label={notificationStatusDict[notification.status].label}
                      variant={notificationStatusDict[notification.status].variant}
                    />
                  </div>
                </Tooltip>
              );
            return (
              <Tag
                label={notificationStatusDict[notification.status].label}
                variant={notificationStatusDict[notification.status].variant}
              />
            );
          },
        }),
        columnHelper.accessor(row => row.sentBy, {
          header: "przez kogo",
          size: 80,
          cell: info => {
            const user: UserWithInitials | null = info.getValue();
            if (!user) return <EmptyValue />;
            return (
              <div className={cx("d-flex align-items-center gap-1", styles.senderContainer)}>
                <Avatar size="sm" theme="light" user={user} />
                <div className="d-flex align-items-center gap-1">
                  <Typography className={styles.senderName} fontSize="12" fontWeight="600" noWrap>
                    {user.firstName}
                  </Typography>
                  <Typography fontSize="12" fontWeight="600" noWrap>
                    {user.lastName.charAt(0)}.
                  </Typography>
                </div>
              </div>
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};

import { queryFetch } from "apiConnectors/queryFetch";
import { createMutationHook } from "utilities/createMutationHook";
import { HelpdeskIssuePostPayload, HelpdeskIssueResponse } from "./models";

export const useHelpdeskIssueMutation = createMutationHook((data: HelpdeskIssuePostPayload) =>
  queryFetch<HelpdeskIssueResponse>({
    method: "POST",
    url: "/tasks/items",
    origin: "https://helpdesk.api.imilo.pl/",
    data,
  }),
);

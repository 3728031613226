import { correctionReasonsMap, TradingDocument } from "api/trading-documents/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import cx from "classnames";
import styles from "../RightPanel.module.css";
import React from "react";
import { AsyncInput } from "components/utils";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { patchTradingDocument } from "api/trading-documents/calls";
import { useMutation, useMutationOptions } from "hooks/useMutation";

interface Props {
  correctionInvoice: TradingDocument;
}

export const MasterDocumentSection = ({ correctionInvoice }: Props) => {
  const updateTradingDocumentOptions = useMutationOptions(
    patchTradingDocument,
    ({ queryUtils }) => ({
      onMutate: toUpdate => {
        const prevDetails = queryUtils.handleMutate(
          tradingDocumentsKeys.tradingDocument.details(String(toUpdate.id)),
          toUpdate,
        );

        const prevList = queryUtils.handlePaginatedListUpdate(
          tradingDocumentsKeys.tradingDocument.list(),
          toUpdate.id,
          toUpdate,
        );

        return { prevDetails, prevList };
      },
      onError: (error, toUpdate, context) => {
        queryUtils.rollback(
          tradingDocumentsKeys.tradingDocument.details(String(toUpdate.id)),
          // @ts-ignore
          context.prevDetails,
          error,
        );
        queryUtils.rollbackList(
          tradingDocumentsKeys.tradingDocument.list(),
          // @ts-ignore
          context.prevList,
          toUpdate.id,
        );
      },
    }),
  );

  const correctionReasonMutation = useMutation(
    updateTradingDocumentOptions.mutationFn,
    updateTradingDocumentOptions,
  );

  return (
    <RightPanelSection>
      <InfoLabel title="do dokumentu">
        {correctionInvoice.masterDocument && (
          <strong className={cx("body-16-800")}>
            {correctionInvoice.masterDocument.signature
              ? correctionInvoice.masterDocument.signature
              : "--"}
          </strong>
        )}
      </InfoLabel>
      <InfoLabel title="z datą wystawienia">
        <div className="body-16">
          {correctionInvoice.masterDocument?.invoiceIssueDate
            ? correctionInvoice.masterDocument?.invoiceIssueDate
            : "--"}
        </div>
      </InfoLabel>
      <InfoLabel title="z datą dostawy">
        <div className="body-16">
          {correctionInvoice.masterDocument?.invoiceDeliveryDate
            ? correctionInvoice.masterDocument?.invoiceDeliveryDate
            : "--"}
        </div>
      </InfoLabel>
      <InfoLabel title="przyczyna">
        <div className={cx("body-14 fw-800")}>
          {correctionInvoice.reasons.length > 0
            ? correctionInvoice.reasons.map((reason, index) => (
                <React.Fragment key={index}>
                  {correctionInvoice.reasons.length > index + 1 ? (
                    <>{correctionReasonsMap[reason.field][reason.action]},&nbsp;</>
                  ) : (
                    <>{correctionReasonsMap[reason.field][reason.action]}</>
                  )}
                </React.Fragment>
              ))
            : "--"}
        </div>
      </InfoLabel>
      <InfoLabel title="powód korekty">
        <AsyncInput
          disabled={correctionReasonMutation.isLoading}
          inProgress={correctionReasonMutation.isLoading}
          onChange={value =>
            correctionReasonMutation.mutate({
              id: correctionInvoice.id,
              correctionReason: value,
            })
          }
          overwrites={{ container: { className: styles.correctionReason } }}
          placeholder="powód korekty"
          value={correctionInvoice.correctionReason}
        />
      </InfoLabel>
      {/* TODO: Sign document section */}
    </RightPanelSection>
  );
};

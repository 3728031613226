import { UUID } from "api/types";
import { TradingDocumentStatus } from "api/trading-documents/models";
import popUpstyles from "pages/tradingDocuments/shared/TradingDocumentsLists.module.css";
import deleteRed from "assets/images/delete_red.svg";
import cx from "classnames";
import { useSelector } from "hooks";
import { tradingDocumentsActions } from "api/trading-documents/actions";

interface Props {
  close: () => void;
  tradingDocumentId: UUID;
  tradingDocumentSignature: string;
  tradingDocumentStatus: TradingDocumentStatus;
}

export const DeleteTradingDocumentBtn = ({
  close,
  tradingDocumentId,
  tradingDocumentSignature,
  tradingDocumentStatus,
}: Props) => {
  const me = useSelector(store => store.auth.user!);
  const deleteTradingDocumentMutation = tradingDocumentsActions.useDeleteTradingDocument(
    close,
    tradingDocumentSignature,
  );

  return (
    <button
      disabled={
        deleteTradingDocumentMutation.isLoading ||
        (tradingDocumentStatus === "CONFIRMED" && me.type !== "developer")
      }
      onClick={() => deleteTradingDocumentMutation.mutate(tradingDocumentId)}
      title={
        tradingDocumentStatus === "CONFIRMED" && me.type !== "developer"
          ? "Nie można usunąć zatwierdzonej faktury"
          : "Usuń fakturę"
      }
      className={cx(" px-3 py-2 w-100", popUpstyles.optionRow)}
    >
      <div className="d-flex align-items-center justify-content-between gap-2">
        <div className="d-flex align-items-center gap-2">
          <img src={deleteRed} alt="Usuń" />
          <div className="body-14-600 text-red-4">Usuń</div>
        </div>
      </div>
    </button>
  );
};

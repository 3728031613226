import styles from "./LinkLineSection.module.css";
import cx from "classnames";
import { InvoiceAdmissionLinkLine } from "api/trading-documents/models";
import { LinkLine } from "./LinkLine";
import { UUID } from "api/types";

interface Props {
  linkItems: InvoiceAdmissionLinkLine[];
  search: string;
  tradingDocument: UUID;
}

export const LinkLineSection = ({ linkItems, search, tradingDocument }: Props) => {
  return (
    <div className="px-3 py-2">
      <div className={styles.tableSection}>
        <div className="d-flex align-items-start w-100 pb-2">
          <div className="pt-1 pb-3 w-100">
            <div className={cx(styles.tableHeader, styles.tableHeaderMatchPackages)}>
              <div>#</div>
              <div>nazwa towaru</div>
              <div className="d-flex align-items-center justify-content-end">liczba</div>
              <div className="d-flex align-items-center justify-content-end">cena netto</div>
              <div className="d-flex align-items-center justify-content-end">wartość netto</div>
              <div>
                <div className={styles.packagesGroup}>
                  <div>paczka</div>
                  <div>liczba</div>
                  <div className="d-flex align-items-center justify-content-end textAlignRight">
                    cena netto za sztukę
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.tableBody}>
              {linkItems.map((linkLine, index) => (
                <LinkLine
                  key={index}
                  linkLine={linkLine}
                  position={index + 1}
                  search={search}
                  tradingDocument={tradingDocument}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

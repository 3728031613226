import { TradingDocument } from "api/trading-documents/models";
import { RightPanelSection, RightPanelTableRow } from "components/utils/drawer";
import { correspondingDocumentsSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { CorrespondingDocument } from "./components/CorrespondingDocument";

interface Props {
  tradingDocument: TradingDocument;
}

export const CorrespondingDocumentSection = ({ tradingDocument }: Props) => {
  return (
    <RightPanelSection title="Dokumenty powiązane">
      <div className="pb-3">
        <RightPanelTableRow grid={correspondingDocumentsSectionListConfig.grid} hover={false}>
          <div>#</div>
          <div>nr dokumentu</div>
        </RightPanelTableRow>
        <div>
          {tradingDocument.correspondingDocuments.map((correspondingDocument, index) => (
            <CorrespondingDocument
              correspondingDocument={correspondingDocument}
              key={correspondingDocument.id}
              position={index + 1}
            />
          ))}
        </div>
      </div>
    </RightPanelSection>
  );
};

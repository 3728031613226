import { RampLoadingStatus } from "api/routes/models";
import { Attributes } from "components/common/attributes/Attributes";
import styles from "./RampLoadingStatusModal.module.css";
import cx from "classnames";
import greenCheckIcon from "assets/images/7c.svg";

interface Props {
  index: RampLoadingStatus;
}

export const IndexItem = ({ index }: Props) => {
  return (
    <div className="row border-bottom">
      <div className="col-1 pr-0 d-flex align-items-center">
        {index.collected === index.requested && (
          <img alt="Sukces" src={greenCheckIcon} style={{ height: "24px", width: "24px" }} />
        )}
      </div>
      <div className="col-7 pl-0 pt-1 pb-1 d-flex align-items-center">
        <div>
          <div className={styles.indexName}>{index.index.product.name}</div>
          {index.index.attributes.length > 0 && (
            <Attributes
              attributes={index.index.attributes.map(attribute => {
                return {
                  id: attribute.id,
                  name: attribute.attribute,
                  value: attribute.value,
                };
              })}
            />
          )}
        </div>
      </div>
      <div
        className={cx(
          styles.colBackground,
          "col-2 d-flex align-items-center justify-content-center",
        )}
      >
        {index.requested}
      </div>
      <div className="col-2 d-flex align-items-center justify-content-center">
        {index.collected}
      </div>
    </div>
  );
};

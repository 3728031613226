import { useTradingDocument } from "api/trading-documents/hooks";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { CurrencyConvertSection } from "pages/tradingDocuments/salesInvoicesLIst/rightPanel/components/currencyConvertSection/CurrencyConvertSection";
import { AmountSection } from "pages/tradingDocuments/shared/components/rightPanel/amountSection/AmountSection";
import { AssignAccountancyAccount } from "pages/tradingDocuments/shared/components/rightPanel/assignAccountancyAccount/AssignAccountancyAccount";
import { CorrespondingDocumentSection } from "pages/tradingDocuments/shared/components/rightPanel/correspondingDocumentsSection/CorrespondingDocumentSection";
import { GeneralInfoSection } from "pages/tradingDocuments/shared/components/rightPanel/generalInfoSection/GeneralInfoSection";
import { ViesSection } from "pages/tradingDocuments/shared/components/rightPanel/generalInfoSection/ViesSection";
import { ItemsSummarySection } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/ItemsSummarySection";
import { NotificationSection } from "pages/tradingDocuments/shared/components/rightPanel/notificationSection/NotificationSection";
import { PaymentSection } from "pages/tradingDocuments/shared/components/rightPanel/paymentSection/PaymentSection";
import { RecipientInfoSection } from "pages/tradingDocuments/shared/components/rightPanel/recipientInfoSection/RecipientInfoSection";
import { RemindersSection } from "pages/tradingDocuments/shared/components/rightPanel/remindersSection/RemindersSection";
import { TitleSection } from "pages/tradingDocuments/shared/components/rightPanel/titleSection/TitleSection";
import { useEffect, useRef, useState } from "react";
import { PanelHeader } from "./components/PanelHeader";

interface Props {
  close: () => void;
}

export const RightPanel = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const [isScrolledOver, setIsScrolledOver] = useState(false);
  const { data: proforma, error, isLoading } = useTradingDocument(
    { id: panelId },
    { enabled: Boolean(panelId) },
  );

  useEffect(() => {
    setIsScrolledOver(false);
  }, [panelId]);

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!proforma) return null;

  return (
    <DrawerRightPanel key={proforma.id}>
      <PanelHeader close={close} isScrolledOver={isScrolledOver} />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody
          myRef={clearCommentsRef}
          setIsScrolledOver={setIsScrolledOver}
          hasCommentsModule
        >
          <TitleSection tradingDocument={proforma} />
          <GeneralInfoSection tradingDocument={proforma} />
          <AmountSection tradingDocument={proforma} />
          <ViesSection tradingDocument={proforma} />
          {proforma.items.length > 0 && <ItemsSummarySection tradingDocument={proforma} />}
          {proforma.currency !== "PLN" && <CurrencyConvertSection salesInvoice={proforma} />}
          <PaymentSection tradingDocument={proforma} />
          <NotificationSection tradingDocument={proforma} />
          {proforma.correspondingDocuments.length > 0 && (
            <CorrespondingDocumentSection tradingDocument={proforma} />
          )}
          <RemindersSection tradingDocument={proforma} />
          <AssignAccountancyAccount tradingDocument={proforma} />
          <RecipientInfoSection tradingDocument={proforma} />
        </RightPanelBody>
        <CommentsGenericSection
          outsideRef={clearCommentsRef}
          paramName="tradingDocument"
          endpointUrl="/finances/comments/items"
          commentedObject={proforma}
        />
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};

export const purple = {
  purple12: "#CB89FF1F",
  purple25: "#F8DEFF",
  purple32: "#CB89FF52",
  purple50: "#F5CCFF",
  purple75: "#E8ABFB",
  purple100: "#D087E7",
  purple200: "#B073DF",
  purple300: "#9438DF",
  purple400: "#802AC7",
  purple500: "#6C2399",
  purple600: "#49106B",
};

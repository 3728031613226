import boxImg from "assets/images/23w.svg";
import carImg from "assets/images/24w.svg";
import cx from "classnames";
import { ErrorMessage, StatusHandler } from "components/utils";
import { DatePicker } from "components/utils/datePicker";
import { TimePicker } from "components/utils/timePicker";
import { useQuery, useToastr } from "hooks";
import { dateFns } from "utilities";
import styles from "./DatesSection.module.css";
import { graphhopper } from "api/graphhopper/graphhopper";
import { useRoutePatchMutation, useRoutePutMutation } from "hooks/apiPrimitives";
import {
  getFullRouteCoords,
  getOrdersPositionsBasedOnGraphhopper,
  getPoints,
} from "pages/routes/creator/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useRouteHook } from "../../hooks";

export const DatesSection = () => {
  const toastr = useToastr();
  const routePutMutation = useRoutePutMutation();
  const routePatchMutation = useRoutePatchMutation();
  const { query } = useQuery();
  const { panelId } = query;

  const { data: route, refetch } = useRouteHook(panelId);
  assertIsDefined(route);

  const updateDeliveryMutation = async (
    departureTime: string | null,
    departureDate: string | null,
  ) => {
    const startingPointLngLat = [route.startingPoint.point.lng, route.startingPoint.point.lat];
    const newPoints = getPoints(route.ordersPositions);

    const points = getFullRouteCoords(route, newPoints, startingPointLngLat);

    const payload = await graphhopper.route({
      points,
      vehicle: route.vehicleType,
      includeLastPointInOptimization: route.includeLastPointInOptimization,
    });

    if (payload) {
      const returnToStartingPointDistance = String(payload.points[route.orders.length].distance);
      const returnToStartingPointTime = String(payload.points[route.orders.length].time);

      const ordersPositions = getOrdersPositionsBasedOnGraphhopper(
        route.ordersPositions,
        payload.points,
      );

      const shouldRemoveFirstArrival =
        departureTime !== route.departureTime || departureDate !== route.departureDate
          ? true
          : false;

      routePutMutation.mutate(
        {
          data: {
            length: payload.distance,
            operation: null,
            ordersPositions,
            departureDate,
            departureTime,
            firstArrivalTime: shouldRemoveFirstArrival ? null : undefined,
            firstArrivalDate: shouldRemoveFirstArrival ? null : undefined,
            returnToStartingPointDistance,
            returnToStartingPointTime,
            shouldCalculateAverageSpeed: true,
          },
          route: route.id,
        },
        {
          onSettled: () => {
            refetch();
          },
        },
      );
    } else {
      toastr.open({
        type: "failure",
        title: "Nie udało się przypiąć zamówienia",
        text: "",
      });
    }
  };

  return (
    <>
      <div className={styles["group-label"]}>
        <img src={boxImg} alt="" />
        <span>załadunek</span>
      </div>

      <div className={cx("d-flex align-items-center mb-2", styles.inputBox)}>
        <div>
          <div className="d-flex align-items-center">
            <StatusHandler>
              {helpers => (
                <>
                  <TimePicker
                    disabled={helpers.isFetching}
                    inProgress={helpers.isFetching}
                    overwrites={{
                      input: { className: styles.input },
                      container: { className: styles.inputContainer },
                    }}
                    value={route.loadingTime || ""}
                    onBlur={value =>
                      routePatchMutation.mutate({
                        id: route.id,
                        toUpdate: { loadingTime: value || null },
                      })
                    }
                  />
                  {helpers.errors.message && (
                    <ErrorMessage type="text" text={helpers.errors.message} />
                  )}
                </>
              )}
            </StatusHandler>
          </div>
        </div>
        <div>
          <div className="d-flex align-items-center">
            <StatusHandler>
              {helpers => (
                <div>
                  <DatePicker
                    className={cx(styles.input, "d-inline-flex ml-2")}
                    disabled={helpers.isFetching}
                    overwrites={{ popup: { className: styles.datePickerPopup } }}
                    value={route.loadingDate || ""}
                    onChange={date => {
                      if (!date) {
                        routePatchMutation.mutate({
                          id: route.id,
                          toUpdate: { loadingDate: null },
                        });
                      } else {
                        routePatchMutation.mutate({
                          id: route.id,
                          toUpdate: { loadingDate: dateFns.format(new Date(date), "yyyy-MM-dd") },
                        });
                      }
                    }}
                  />
                  {helpers.errors.message && (
                    <ErrorMessage type="text" text={helpers.errors.message} />
                  )}
                </div>
              )}
            </StatusHandler>
          </div>
        </div>
      </div>
      <div className={styles["group-label"]}>
        <img src={carImg} alt="" />
        <span>wyjazd</span>
      </div>
      <div className={cx("d-flex align-items-center mb-2", styles.inputBox)}>
        <div>
          <div className="d-flex align-items-center">
            <StatusHandler>
              {helpers => (
                <>
                  <TimePicker
                    disabled={helpers.isFetching}
                    inProgress={helpers.isFetching}
                    overwrites={{
                      input: { className: styles.input },
                      container: { className: styles.inputContainer },
                    }}
                    value={route.departureTime || ""}
                    onBlur={value => {
                      if (route.departureDate) {
                        updateDeliveryMutation(value || null, route.departureDate);
                      } else {
                        routePatchMutation.mutate({
                          id: route.id,
                          toUpdate: { departureTime: value || null },
                        });
                      }
                    }}
                  />
                  {helpers.errors.message && (
                    <ErrorMessage type="text" text={helpers.errors.message} />
                  )}
                </>
              )}
            </StatusHandler>
          </div>
        </div>
        <div>
          <div className="d-flex align-items-center">
            <StatusHandler>
              {helpers => (
                <div>
                  <DatePicker
                    className={cx(styles.input, "d-inline-flex ml-2")}
                    disabled={helpers.isFetching}
                    overwrites={{ popup: { className: styles.datePickerPopup } }}
                    value={route.departureDate || ""}
                    onChange={date => {
                      if (!date) {
                        if (route.departureTime) {
                          updateDeliveryMutation(route.departureTime, null);
                        } else {
                          routePatchMutation.mutate({
                            id: route.id,
                            toUpdate: { departureDate: null },
                          });
                        }
                      } else {
                        if (route.departureTime) {
                          updateDeliveryMutation(
                            route.departureTime,
                            dateFns.format(new Date(date), "yyyy-MM-dd"),
                          );
                        } else {
                          routePatchMutation.mutate({
                            id: route.id,
                            toUpdate: {
                              departureDate: dateFns.format(new Date(date), "yyyy-MM-dd"),
                            },
                          });
                        }
                      }
                    }}
                  />
                  {helpers.errors.message && (
                    <ErrorMessage type="text" text={helpers.errors.message} />
                  )}
                </div>
              )}
            </StatusHandler>
          </div>
        </div>
      </div>
    </>
  );
};

import { Header } from "./components/Header";
import styles from "./Dashboard.module.css";
import cx from "classnames";
import { SalesSection } from "./components/salesSection/SalesSection";
import { PurchaseSection } from "./components/purchaseSection/PurchaseSection";
import { IncomeSection } from "./components/incomeSection/IncomeSection";
import { ExpensesSection } from "./components/expensesSection/ExpensesSection";
import { DocumentsTraySection } from "./components/documentsTraySection/DocumentsTraySection";
import { CashFlowSection } from "./components/cashFlowSection/CashFlowSection";

export const Dashboard = () => {
  return (
    <div className="position-relative">
      <Header />
      <div className={styles.mainContent}>
        <div className={cx(styles.mainContentInner, "flex-wrap")}>
          <div className={cx(styles.scrollableContent, "d-flex flex-wrap w-100 pt-3")}>
            <div className="col-12 col-xl-6 col-xxl-8 pr-3 pr-xl-0">
              <SalesSection />
              <PurchaseSection />
              <div className="d-flex flex-wrap w-100">
                <div className="col-12 col-xxl-6 px-0 pr-xxl-3">
                  <IncomeSection />
                </div>
                <div className="col-12 col-xxl-6 px-0 pr-xl-0">
                  <ExpensesSection />
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-xxl-4 pr-xl-3">
              <DocumentsTraySection />
              <CashFlowSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { RouteLineItem, ScheduleConfirmationStatus } from "api/external-manufacturing/models";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Select } from "components/miloDesignSystem/molecules/select";

interface Props {
  routeLineItem: RouteLineItem;
}

const scheduleConfirmationStatusDict: Record<ScheduleConfirmationStatus, string> = {
  NOT_SET: "Do ustalenia",
  CONFIRMED: "Potwierdzono",
  REJECTED: "Odrzucono",
};

const scheduleConfirmationStatusOptions: {
  value: string;
  text: string;
  type: MenuItemType;
}[] = Object.entries(scheduleConfirmationStatusDict).map(([value, text]) => ({
  value,
  text,
  type: MenuItemType.TEXT,
}));

export const EmailConfirmationStatus = ({ routeLineItem }: Props) => {
  const scheduleConfirmationStatusMutation = externalManufacturingActions.usePatchRouteLineItem();

  return (
    <div onClick={event => event.stopPropagation()}>
      <Select
        items={scheduleConfirmationStatusOptions}
        onChange={status => {
          if (status) {
            scheduleConfirmationStatusMutation.mutate({
              id: routeLineItem.id,
              toUpdate: {
                scheduleConfirmationStatus: status as ScheduleConfirmationStatus,
              },
            });
          }
        }}
        selected={routeLineItem.scheduleConfirmationStatus || null}
      />
    </div>
  );
};

import { EmailMessage as EmailMessageType } from "api/messages/models";
import checkBlue from "assets/images/7b.svg";
import checkGrey from "assets/images/7lg.svg";
import errorIcon from "assets/images/877.svg";
import cx from "classnames";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { useState } from "react";
import { dateFns } from "utilities";
import { Button } from "..";
import styles from "./OrderEmails.module.css";

interface Props {
  data: EmailMessageType;
  firstName: string;
  lastName: string;
}

const statusDict: Record<EmailMessageType["status"], string> = {
  OPENED: "odczytano",
  SENT: "wysłano",
  DECLINED: "nie wysłano",
  CLICKED_LINK: "odczytano",
};

export const EmailMessage = ({ data, firstName, lastName }: Props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={cx(styles.item, {
        [styles.reversed]: data.emailType === "INCOMING",
        [styles.error]: data.failureReason,
      })}
    >
      <div className={styles.content}>
        <div className={styles.date}>
          <div className={styles.readStatus}>
            {data.status === "OPENED" ||
              (data.status === "CLICKED_LINK" && (
                <div>
                  <div>
                    <img alt="Otwarto" src={checkBlue} className="mr-1" />
                    <span className={cx(styles.readStatusText, "mr-2")}>odczytano </span>
                  </div>
                  <div className="ml-3">
                    {data.modifiedAt &&
                      dateFns.format(new Date(data.modifiedAt), "d MMM yyyy HH:mm")}
                  </div>
                </div>
              ))}
            {data.status === "SENT" && (
              <div className="d-flex align-items-center">
                <img alt="" src={checkGrey} className="mr-1" />
                <span>nie odczytano</span>
              </div>
            )}
          </div>
          <div className={styles.sentStatus}>
            <div className={styles.sentDate}>
              {data.status !== "DECLINED" ? (
                <div className="mr-2">{statusDict["SENT"]} </div>
              ) : (
                <div className="mr-2">{statusDict["DECLINED"]} </div>
              )}
              {data.created && dateFns.format(new Date(data.created), "d MMM yyyy HH:mm")}
            </div>
            <Button
              kind="secondary-grey"
              size="small"
              className={cx(styles.expandBtn, "ml-2")}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? "Zwiń" : "Pokaż całość"}
            </Button>
          </div>
        </div>
        <div className={cx(styles.body, { [styles.bodyCollapse]: !expanded })}>{data.body}</div>
        <div className={styles.errorMsg}>
          <img alt="" src={errorIcon} />
          {data.failureReason}
        </div>
      </div>
      <div className={styles.msgNameInfo}>
        {data.createdBy ? (
          <>
            <AvatarOrInitials
              avatarSrc={data.createdBy.avatar}
              firstName={data.createdBy.firstName}
              lastName={data.createdBy.lastName}
              className={styles.avatar}
            />
            <div
              className={styles.msgName}
            >{`${data.createdBy.firstName} ${data.createdBy.lastName}`}</div>
            <span className={cx(styles.emailAddress, "mr-2 ml-2")}>{data.emailFrom}</span>
          </>
        ) : (
          <>
            <AvatarOrInitials
              avatarSrc={null}
              firstName={firstName}
              lastName={lastName}
              className={styles.avatar}
            />
            <div className={styles.msgName}>{`${firstName} ${lastName}`}</div>
          </>
        )}
      </div>
    </div>
  );
};

import { Button } from "components/common";
import { PopupMenu } from "components/common/popupMenu";
import sendIcon from "assets/images/send.svg";
import mailIcon from "assets/images/mail.svg";
import smsIcon from "assets/images/sms.svg";
import chatIcon from "assets/images/chatAddOn.svg";
import plusIcon from "assets/images/add.svg";
import arrowDownIcon from "assets/images/keyboardArrowDown.svg";
import popUpStyles from "pages/tradingDocuments/shared/TradingDocumentsLists.module.css";
import styles from "../../../RightPanel.module.css";
import cx from "classnames";
import {
  useConfirmModal,
  useSelector,
  useSettings,
  useStateModal,
  useToastr,
  useToggle,
} from "hooks";
import { Route } from "api/routes/models";
import { StatusHandler } from "components/utils";
import { sendSmsToAll } from "../utils/sendSmsToAll";
import { SmsToAllConfirmation } from "./confirmationDateModals/SmsToAllConfirmation";
import { sendEmailToAll } from "../utils/sendEmailToAll";
import { EmailForAllConfirmationDateModal } from "./confirmationDateModals/EmailToAllConfirmation";
import { CustomSMSModal } from "components/common/customSMSModal";
import { assertIsDefined } from "utilities/assertIsDefined";

interface Props {
  route: Route;
}

export const SendNotificationPopUp = ({ route }: Props) => {
  const toastr = useToastr();

  const emailForAllConfirmationDateModal = useToggle();

  const smsToAllConfirmationModal = useToggle();
  const singleSmsConfirmationDateModal = useToggle();
  const smsModal = useStateModal<string>();

  const confirmResendingModal = useConfirmModal();

  const { transport } = useSettings();
  const options = transport.options;
  const hasDeadlineConfirmationDate = transport.hasDeadlineConfirmationDate;
  const hasSmsService = useSelector(store => store.partials.configuration.hasSmsService);

  return (
    <>
      <div className="position-relative">
        <PopupMenu
          customButton={
            <Button
              data-for="notification-options"
              data-event="click"
              data-event-off="mouseleave"
              data-tip="2"
              kind="create"
              style={{ maxHeight: "26px" }}
            >
              <div className="btnBase btnBase16 btnBaseSmall">
                <img alt="" src={sendIcon} />
                Wyślij powiadomienia do wszystkich
              </div>
              <div className="btnBase btnBaseSmall borderLeft px-1">
                <img alt="" src={arrowDownIcon} />
              </div>
            </Button>
          }
          customStyles={popUpStyles}
          dataFor="notification-options"
        >
          <div className={styles.notificationsOptionList}>
            <div className={cx("px-3 py-2", styles.optionRow)}>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <div className="d-flex align-items-center gap-2">
                  <img src={mailIcon} alt="email" />
                  <div className="body-14-600">Powiadomienie email</div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <StatusHandler>
                    {helpers => (
                      <Button
                        disabled={helpers.isFetching}
                        kind="create"
                        onClick={() => {
                          if (!hasDeadlineConfirmationDate) {
                            sendEmailToAll(
                              null,
                              confirmResendingModal,
                              emailForAllConfirmationDateModal,
                              helpers,
                              route,
                              smsModal,
                              toastr,
                            );
                          } else {
                            emailForAllConfirmationDateModal.open();
                          }
                        }}
                      >
                        <div className="btnBase btnBase16 btnBaseSmall">
                          <img alt="" src={mailIcon} />
                          Wyślij
                        </div>
                      </Button>
                    )}
                  </StatusHandler>
                </div>
              </div>
            </div>

            <div className={cx("px-3 py-2", styles.optionRow)}>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <div className="d-flex align-items-center gap-2">
                  <img src={smsIcon} alt="sms" />
                  <div className="body-14-600">Powiadomienie SMS</div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <StatusHandler>
                    {helpers => (
                      <Button
                        disabled={!hasSmsService || helpers.isFetching}
                        kind="create"
                        onClick={() => {
                          if (!hasDeadlineConfirmationDate) {
                            sendSmsToAll(
                              smsToAllConfirmationModal,
                              null,
                              confirmResendingModal,
                              {
                                resendSms: false,
                                sendSms: false,
                                resendUnconfirmed: false,
                              },
                              helpers,
                              null,
                              route,
                              singleSmsConfirmationDateModal,
                              smsModal,
                              toastr,
                            );
                          } else {
                            smsToAllConfirmationModal.open();
                          }
                        }}
                      >
                        <div className="btnBase btnBase16 btnBaseSmall">
                          <img alt="" src={smsIcon} />
                          Wyślij
                        </div>
                      </Button>
                    )}
                  </StatusHandler>
                </div>
              </div>
            </div>

            <div className={cx("px-3 py-2", styles.optionRow)}>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <div className="d-flex align-items-center gap-2">
                  <img src={chatIcon} alt="własny sms" />
                  <div className="body-14-600">Własne powiadomienie SMS</div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <Button
                    disabled={!hasSmsService}
                    kind="create"
                    onClick={() => smsModal.open("ROUTE")}
                  >
                    <div className="btnBase btnBase16 btnBaseSmall">
                      <img alt="" src={plusIcon} />
                      Utwórz i wyślij
                    </div>
                  </Button>
                  <CustomSMSModal
                    close={smsModal.close}
                    isOpen={smsModal.isOpen}
                    kind={smsModal.state}
                    onSubmit={(message, helpers, force, confirmationDeadline) => {
                      assertIsDefined(force);
                      sendSmsToAll(
                        smsToAllConfirmationModal,
                        confirmationDeadline,
                        confirmResendingModal,
                        force,
                        helpers,
                        message,
                        route,
                        singleSmsConfirmationDateModal,
                        smsModal,
                        toastr,
                      );
                    }}
                    options={options}
                  />
                </div>
              </div>
            </div>
          </div>
        </PopupMenu>
      </div>
      {emailForAllConfirmationDateModal.isOpen && (
        <EmailForAllConfirmationDateModal
          confirmResendingModal={confirmResendingModal}
          emailForAllConfirmationModal={emailForAllConfirmationDateModal}
          options={options}
          route={route}
          smsModal={smsModal}
          toastr={toastr}
        />
      )}
      {smsToAllConfirmationModal.isOpen && (
        <SmsToAllConfirmation
          confirmResendingModal={confirmResendingModal}
          options={options}
          route={route}
          singleSmsConfirmationDateModal={singleSmsConfirmationDateModal}
          smsModal={smsModal}
          smsToAllConfirmationModal={smsToAllConfirmationModal}
        />
      )}
    </>
  );
};

import {
  getIndex,
  getIndexAssignments,
  getIndexes,
  getIndexesFulfillment,
} from "api/indexes/calls";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createPrimitiveHook, createPrimitivePaginatedHook } from "hooks/createPrimitiveHook";

export const useIndex = createPrimitiveHook(getIndex);
export const useIndexes = createPrimitivePaginatedHook(getIndexes);
export const useIndexAssignments = createPrimitiveHook(getIndexAssignments);

export const useIndexesFulfillment = createPaginatedApiQuery(getIndexesFulfillment);

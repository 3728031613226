import { Component } from "react";
import onClickOutside from "react-onclickoutside";

class ClickOutsideListener extends Component<{
  onClickOutside: () => void;
  outsideClickIgnoreClass?: string;
}> {
  handleClickOutside = (e: any) => {
    const { onClickOutside } = this.props;
    if (onClickOutside) {
      onClickOutside();
    }
  };
  render() {
    return this.props.children;
  }
}
export const ClickOutsideHandler = onClickOutside(ClickOutsideListener);

import { Partials } from "api/other/models";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { useCombobox, useMultipleSelection } from "downshift";
import crossIcon from "assets/images/11.svg";
import whitePlusIcon from "assets/images/125w.svg";
import { useQuery, useSelector } from "hooks";
import { useState } from "react";
import { FilterSelectUserType } from "../../types";
import styles from "./FilterSelectUser.module.css";
import cx from "classnames";
import { highlightTextFragment } from "utilities/highlightTextFragment";

interface NormalizedData {
  avatar: string | null;
  firstName: string;
  id: number;
  lastName: string;
  name: string;
}

export const FilterSelectUser = <T extends string>({
  kind,
  multiple,
  name,
  searchBy,
}: FilterSelectUserType<T>) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const options = useSelector(store => store.partials[searchBy]);
  const carOptions = useSelector(store => store.partials.cars);
  const carrierOptions = useSelector(store => store.partials.carriers);
  const [inputValue, setInputValue] = useState("");

  const normalizedData: NormalizedData[] = {
    user: () => normalizeData(options),
    car: () => normalizeCarData(carOptions),
    carrier: () => normalizeCarrierData(carrierOptions),
  }[kind]();

  const usersToPick = normalizedData.filter(
    option => !inputValue || option.name.toLowerCase().includes(inputValue.toLowerCase()),
  );

  const selectedItems = (() => {
    const selectedIds = query[name]?.split(",");
    if (!selectedIds?.length) return [];
    return normalizedData.reduce<NormalizedData[]>((acc, user) => {
      if (selectedIds?.includes(String(user.id))) acc.push(user);
      return acc;
    }, []);
  })();

  const { getSelectedItemProps, getDropdownProps } = useMultipleSelection({
    selectedItems,
  });

  const {
    isOpen,
    openMenu,
    closeMenu,
    getComboboxProps,
    getMenuProps,
    getInputProps,
    getItemProps,
  } = useCombobox({
    items: usersToPick,
    itemToString(item) {
      return item ? item.name : "";
    },
    defaultHighlightedIndex: 0,
    selectedItem: null,
    inputValue,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
            highlightedIndex: 0,
          };
        default:
          return changes;
      }
    },
    onStateChange({ inputValue, type, selectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            const { id } = selectedItem;
            const applitedIds = query[name]?.split(",");

            if (applitedIds?.includes(String(id))) {
              removeSelection(String(id));
            } else {
              select(String(id));
            }

            setInputValue("");
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue || "");

          break;
        default:
          break;
      }
    },
  });

  const removeSelection = (id: string) => {
    const newFilters = query[name]
      ?.split(",")
      ?.filter(appliedValue => {
        return appliedValue !== id;
      })
      .join(",");

    updateQuery({ [name]: newFilters, page: 1 });
  };

  const select = (id: string) => {
    const currentValues = query[name] || [];

    if (multiple) {
      if (currentValues.length === 0) {
        updateQuery({ [name]: [id].join(""), page: 1 });
      } else {
        updateQuery({ [name]: [currentValues, id].join(","), page: 1 });
      }
      return;
    }
    updateQuery({ [name]: id, page: 1 });
  };

  return (
    <div
      className={cx(styles.labelContainer, "flex-wrap", {
        [styles.labelContainerInSearchMode]: isOpen,
      })}
      {...getComboboxProps()}
    >
      {selectedItems.map((userForRender, index) => {
        return (
          <div
            className={styles.selectedUser}
            key={`selected-user-${index}`}
            {...getSelectedItemProps({
              selectedItem: userForRender,
              index,
            })}
            onClick={e => e.stopPropagation()}
          >
            {kind !== "car" && (
              <AvatarOrInitials
                avatarSrc={userForRender.avatar}
                className={styles.avatar}
                firstName={userForRender.firstName}
                lastName={userForRender.lastName}
              />
            )}
            <div>{userForRender.name}</div>

            <div
              className={styles.removeSelect}
              onClick={() => removeSelection(String(userForRender.id))}
            >
              <img alt="Usuń" src={crossIcon} />
            </div>
          </div>
        );
      })}
      {!isOpen && (
        <div>
          <button
            className={styles.addBtn}
            onClick={() => {
              openMenu();
            }}
          >
            <img alt="Dodaj" src={whitePlusIcon} />
          </button>
        </div>
      )}
      {isOpen && (
        <div className={styles.searchInput}>
          <input
            {...getInputProps(
              getDropdownProps({
                preventKeyAction: isOpen,
              }),
            )}
            placeholder="Szukaj"
            className={styles.input}
          />
          <button
            aria-label="toggle menu"
            onClick={() => {
              if (inputValue) {
                setInputValue("");
                closeMenu();
                return;
              }
              openMenu();
            }}
            type="button"
          >
            {inputValue && <img alt="Wyczyść" src={crossIcon} />}
          </button>
          <ul
            className={cx(styles.menuList, {
              [styles.hiddenMenuList]: !(isOpen && usersToPick.length),
            })}
            {...getMenuProps()}
          >
            {isOpen &&
              usersToPick.map((user, index) => (
                <li
                  className={styles.menuItem}
                  key={`${user.id}${index}`}
                  {...getItemProps({ item: user, index })}
                >
                  <label
                    htmlFor={String(user.id)}
                    className={cx({
                      checkMark: true,
                      [styles.checkMark]: true,
                      [styles.checkMarkActive]: query[name]?.includes(String(user.id)),
                    })}
                  />
                  {kind !== "car" && (
                    <AvatarOrInitials
                      avatarSrc={user.avatar}
                      className={styles.avatar}
                      firstName={user.firstName}
                      lastName={user.lastName}
                    />
                  )}
                  <span>{highlightTextFragment(inputValue, user.name, user.id)}</span>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const normalizeData = (
  data: Partials["standardUsers"] | Partials["drivers"] | Partials["users"],
): NormalizedData[] => {
  return data.map(standardUser => {
    return {
      avatar: standardUser.avatar,
      firstName: standardUser.firstName,
      id: standardUser.id,
      lastName: standardUser.lastName,
      name: `${standardUser.firstName} ${standardUser.lastName}`,
    };
  });
};

const normalizeCarData = (data: Partials["cars"]): NormalizedData[] => {
  return data.map(car => {
    return {
      avatar: "",
      firstName: car.registrationNumber,
      id: car.id,
      lastName: "",
      name: car.registrationNumber,
    };
  });
};

const normalizeCarrierData = (data: Partials["carriers"]): NormalizedData[] => {
  return data.map(carrier => {
    return {
      avatar: "",
      firstName: carrier.name,
      id: carrier.id,
      lastName: "",
      name: carrier.name,
    };
  });
};

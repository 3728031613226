export const neutralBlack = {
  neutralBlack4: "#0000000A",
  neutralBlack7: "#00000012",
  neutralBlack12: "#0000001F",
  neutralBlack16: "#00000029",
  neutralBlack20: "#00000033",
  neutralBlack24: "#0000003D",
  neutralBlack32: "#00000052",
  neutralBlack48: "#0000007A",
  neutralBlack64: "#000000A3",
  neutralBlack76: "#000000C2",
  neutralBlack88: "#000000E0",
  neutralBlack100: "#000000",
};

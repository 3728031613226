import { TradingDocument } from "api/trading-documents/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  tradingDocument: TradingDocument;
}

export const RecipientInfoSection = ({ tradingDocument }: Props) => {
  return (
    <RightPanelSection title="Nabywca">
      <div className="pb-3">
        {tradingDocument.recipientCompanyName && (
          <Typography className="pb-1" fontSize="18" fontWeight="700">
            {tradingDocument.recipientCompanyName}
          </Typography>
        )}
        {tradingDocument.recipientStreet && (
          <Typography className="pb-1" fontSize="14" fontWeight="500">
            {tradingDocument.recipientStreet}
          </Typography>
        )}
        {(tradingDocument.recipientPostCode || tradingDocument.recipientCity) && (
          <Typography className="pb-1" fontSize="14" fontWeight="500">
            {tradingDocument.recipientPostCode}&nbsp;{tradingDocument.recipientCity}
          </Typography>
        )}
        {tradingDocument.recipientBankAccountNumber && (
          <Typography className="pb-1" fontSize="14" fontWeight="500">
            {tradingDocument.recipientBankAccountNumber}
          </Typography>
        )}
        {tradingDocument.recipientTaxId && (
          <Typography className="pb-1" fontSize="14" fontWeight="500">
            NIP:&nbsp;{tradingDocument.recipientTaxId}
          </Typography>
        )}
      </div>
    </RightPanelSection>
  );
};

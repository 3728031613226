import cx from "classnames";
import { DroppableStateSnapshot } from "react-beautiful-dnd";
import localStyles from "./ManufacturingItem.module.css";

interface Props {
  snapshot: DroppableStateSnapshot;
}

export const EmptyManufacturingItem = ({ snapshot }: Props) => {
  return (
    <div
      className={cx(localStyles.item, localStyles.itemPlaceholder, {
        [localStyles.packagesGroupPlaceholderDrag]: snapshot.isDraggingOver,
      })}
    >
      <div className="d-flex align-items-center">
        <div className={cx(localStyles.itemSlot, localStyles.itemSlotSmall)} />
        <div>Przeciągnij i upuść indeks tutaj</div>
      </div>
      <div className="d-flex align-items-center">
        <div className={cx(localStyles.itemSlot, localStyles.itemSlotSmall)} />
        <div className={cx(localStyles.itemSlot, localStyles.itemSlotSmall)} />
        <div className={cx(localStyles.itemSlot, localStyles.itemSlotMedium)} />
      </div>
    </div>
  );
};

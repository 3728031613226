import { DialogOverlay, DialogContent } from "@reach/dialog";
import { animated, useTransition } from "react-spring";
import Media from "react-media";
import cx from "classnames";
import closeIcon from "assets/images/11.svg";
import styles from "./Modal.module.css";

interface Props {
  children: JSX.Element;
  isOpen: boolean;
  close: () => void;
  showCloseBtn?: boolean;
  animated?: boolean;
  size?:
    | {
        all?: { width: string };
        xlg?: { width: string };
        lg?: { width: string };
        md?: { width: string };
        sm?: { width: string };
      }
    | "auto";
  overrides?: { container?: { className: string } };
}

function getWidth(
  matches: { xlg: boolean; lg: boolean; md: boolean; sm: boolean },
  size: Props["size"],
) {
  if (size === "auto") return "auto";
  if (size) {
    if (matches.xlg) return size.xlg?.width || size.all?.width || "50vw";
    if (matches.lg) return size.lg?.width || size.all?.width || "60vw";
    if (matches.md) return size.md?.width || size.all?.width || "70vw";
    if (matches.sm) return size.sm?.width || size.all?.width || "80vw";
  }
  return "50vw";
}

const mediaQueries = {
  sm: "(max-width: 599px)",
  md: "(min-width: 600px) and (max-width: 1199px)",
  lg: "(min-width: 1200px) and (max-width: 1399px)",
  xlg: "(min-width: 1400px)",
};

const AnimatedDialogOverlay = animated(DialogOverlay);
const AnimatedDialogContent = animated(DialogContent);

export function Modal({
  isOpen,
  close,
  children,
  size,
  showCloseBtn = true,
  overrides = {},
  animated: isAnimated = true,
}: Props) {
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0, top: -10 },
    enter: { opacity: 1, top: 0 },
    leave: { opacity: 0, top: 10 },
    config: { duration: isAnimated ? 150 : 0 },
  });

  return (
    <div>
      {transitions.map(
        ({ item, key, props: animationStyles }: any) =>
          item && (
            <AnimatedDialogOverlay
              style={{
                opacity: animationStyles.opacity,
                zIndex: 21, // incremented from 6 because Autocomplete item was above,
                display: size === "auto" ? "flex" : undefined,
              }}
              onDismiss={close}
              key={key}
            >
              <Media queries={mediaQueries}>
                {matches => (
                  <AnimatedDialogContent
                    aria-label="Wybieranie lokalizacji zamówienia"
                    style={{
                      width: getWidth(matches, size),
                      ...(size === "auto" ? { margin: "auto" } : {}),
                      transform: animationStyles.top.interpolate(
                        (value: number) => `translate3d(0px, ${value}px, 0px)`,
                      ),
                      border: "1px solid rgba(0, 0, 0, 0.5)",
                      boxShadow:
                        "0 2px 4px 3px rgba(0, 0, 0, 0.2), 0 8px 8px 5px rgba(0, 0, 0, 0.1)",
                      borderRadius: 10,
                    }}
                    className={overrides.container?.className}
                  >
                    <button
                      type="button"
                      className={cx(styles.closeBtn, "btn")}
                      onClick={close}
                      style={{ display: showCloseBtn ? "block" : "none" }}
                    >
                      <img src={closeIcon} alt="zamknij" />
                    </button>
                    {children}
                  </AnimatedDialogContent>
                )}
              </Media>
            </AnimatedDialogOverlay>
          ),
      )}
    </div>
  );
}

import { memo } from "react";
import bellIcon from "assets/images/12.svg";
import cx from "classnames";
import styles from "../Header.module.css";
import { ClickOutsideHandler } from "components/utils";
import { Toastr } from "../../../utils/toastr/Toastr";
import { dateFns } from "utilities";
import { useSelector, useToggle } from "hooks";
import cuid from "cuid";
import notificationIcon from "assets/images/88.svg";

const clickOutsideIgnoreClass = "ignore-outside-click-" + cuid();

export const ToasterDropdown = memo(() => {
  const { isOpen, toggle, close } = useToggle(false);
  const toastrMessages = useSelector(store => store.toasters);

  return (
    <div className="position-relative">
      <div
        className={cx("mr-1", styles.btnCircular, clickOutsideIgnoreClass)}
        role="button"
        onClick={toggle}
      >
        <img src={bellIcon} alt="Notyfikacje" />
      </div>
      {isOpen && (
        <ClickOutsideHandler
          onClickOutside={close}
          outsideClickIgnoreClass={clickOutsideIgnoreClass}
        >
          <div
            className={cx(styles.dropDown, styles.toasterDropdown)}
            style={{
              display: isOpen ? "block" : "none",
            }}
          >
            {toastrMessages.map(toastr => (
              <div key={toastr.id} className={styles.toasterWrapper}>
                <span className={styles.toasterDate}>
                  {dateFns.format(new Date(toastr.timestamp), "dd MMM yyyy HH:mm")}
                </span>
                <Toastr type={toastr.type} title={toastr.title} text={toastr.text} />
              </div>
            ))}
            {toastrMessages.length === 0 && (
              <div className={styles.emptyList}>
                <img src={notificationIcon} alt="" />
                <span>Brak powiadomień</span>
              </div>
            )}
          </div>
        </ClickOutsideHandler>
      )}
    </div>
  );
});

import closeImg from "assets/images/10.svg";
import { ClickOutsideHandler } from "components/utils";
import styles from "./ConversationModal.module.css";
import { Button as ButtonLegacy } from "components/common/buttonLegacy";
import { OrderMessages } from "components/common/OrderMessages/OrderMessages";

export const clickOutsideIgnoreClass = "click-outside-ignore-class-right-panel";
export const RightPanel = ({ order, close }: { order: number; close: () => void }) => {
  return (
    <ClickOutsideHandler onClickOutside={close} outsideClickIgnoreClass={clickOutsideIgnoreClass}>
      <div className={styles.rightPanel}>
        <OrderMessages
          kind="ROUTE"
          id={String(order)}
          closeButton={
            <ButtonLegacy kind="secondary" size="round-s" onClick={close}>
              <img alt="" src={closeImg} />
            </ButtonLegacy>
          }
        />
      </div>
    </ClickOutsideHandler>
  );
};

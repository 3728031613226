import { TradingDocument } from "api/trading-documents/models";
import { Button } from "components/common";
import { useState } from "react";
import editIcon from "assets/images/edit.svg";
import styles from "../../EditTradingDocument.module.css";
import cx from "classnames";
import { Seller } from "api/sellers/models";

interface Props {
  setIsSellerChanging: React.Dispatch<React.SetStateAction<boolean>>;
  tradingDocument: TradingDocument;
}

export const SellerPreview = ({ setIsSellerChanging, tradingDocument }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="d-flex align-items-start w-100 row">
      {isExpanded ? (
        <div className={cx(styles.clientBody, "col-6 pr-0 pt-1")}>
          <div>
            <div className="mb-2">{tradingDocument.seller?.companyName}</div>
            <div
              className={cx("d-flex align-items-center", {
                "mb-2":
                  tradingDocument.seller?.firstName !== "" ||
                  tradingDocument.seller?.lastName !== "",
              })}
            >
              <div>{tradingDocument.seller?.firstName}&nbsp;</div>
              <div>{tradingDocument.seller?.lastName}</div>
            </div>
            <div
              className={cx({
                "mb-2": tradingDocument.seller?.address !== "",
              })}
            >
              {tradingDocument.seller?.address}
            </div>
            <div
              className={cx("d-flex align-items-center", {
                "mb-2":
                  tradingDocument.seller?.postCode !== "" || tradingDocument.seller?.city !== "",
              })}
            >
              <div>{tradingDocument.seller?.postCode}&nbsp;</div>
              <div>{tradingDocument.seller?.city}</div>
            </div>
            <div
              className={cx("d-flex align-items-center", {
                "mb-2":
                  tradingDocument.seller?.countryCode !== undefined ||
                  tradingDocument.seller?.taxId !== "",
              })}
            >
              <div>NIP:&nbsp;</div>
              <div>{tradingDocument.seller?.countryCode}&nbsp;</div>
              <div>{tradingDocument.seller?.taxId}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className={cx(styles.clientBody, styles.clientBodyCollapse, "col-6 pr-0 pt-1")}>
          {tradingDocument.seller ? (
            <>
              {Object.values(possibleSellerFields(tradingDocument.seller)).find(
                value => value !== "",
              )}
              ...
            </>
          ) : (
            <div></div>
          )}
        </div>
      )}
      <div className="col-4 pl-0 pr-5 d-flex align-items-center justify-content-center">
        <Button
          kind="secondary"
          size="small"
          className={cx(styles.expandBtn, "ml-1")}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "Zwiń" : "Pokaż więcej"}
        </Button>
      </div>
      <div className="col-2 pr-5 pl-0 d-flex justify-content-center">
        <Button
          className={styles.editDataBtn}
          kind="secondary-grey"
          onClick={() => setIsSellerChanging(prev => !prev)}
          size="small"
        >
          <div className={cx(styles.editDataBtnContent, "d-flex align-items-center")}>
            <img alt="Zmień" src={editIcon} style={{ height: "16px", width: "16px" }} />
            <span>Zmień sprzedawcę</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

const possibleSellerFields = (seller: Seller) => {
  return {
    companyName: seller.companyName,
    firstName: seller.firstName,
    lastName: seller.lastName,
    address: seller.address,
    postCode: seller.postCode,
    city: seller.city,
    countryCode: seller.countryCode,
    taxId: seller.taxId,
  };
};

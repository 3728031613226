import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import {
  DrawerRightPanel,
  RightPanelBody,
  RightPanelHeader,
  RightPanelSection,
} from "components/utils/drawer";
import { useQuery } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { assertIsDefined } from "utilities/assertIsDefined";
import { shippingActions } from "api/shipping/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiCheckSmall } from "components/miloDesignSystem/atoms/icons/MdiCheckSmall";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AuthorizationStatus } from "api/shipping/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { cx, getStandardDateFormat, getStandardTimeFormat } from "utilities";
import { MdiEmergencyHome } from "components/miloDesignSystem/atoms/icons/MdiEmergencyHome";
import { InfoLabel } from "components/common/infoLabel";
import styles from "./RightPanel.module.css";
import { GeneralInfoSection } from "./components/GeneralInfoSection";
import { DefaultOptionsSection } from "./components/DefaultOptionsSection";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";

export const RightPanel = () => {
  const { query } = useQuery();
  const { close } = useDrawer("shippingCourier");
  const { panelId } = query;
  const { data: courier, error, isLoading } = shippingActions.useShippingCourier(Number(panelId), {
    enabled: Boolean(panelId),
  });

  const shippingAuthorizeMutation = shippingActions.usePostShippingAuthorize();
  const shippingCheckConnectionMutation = shippingActions.usePostShippingCheckConnection();

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(courier);

  return (
    <DrawerRightPanel className="position-relative" key={panelId}>
      <RightPanelHeader>
        {courier.authorizationStatus === AuthorizationStatus.SUCCESS ? (
          <Tag startIcon={MdiCheckSmall} label="Autoryzowano" variant="success" type="outlined" />
        ) : (
          <Button
            size="small"
            variant="outline"
            startIcon={MdiCheckSmall}
            className="text-uppercase"
            onClick={() => shippingAuthorizeMutation.mutate(courier.id)}
          >
            Autoryzuj
          </Button>
        )}
        <div className="d-flex align-items-center justify-content-end flex-1">
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </RightPanelHeader>
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody>
          <RightPanelSection padding="px-3 pt-2 pb-3">
            <div className="d-flex align-items-center">
              <Typography fontSize="26" fontWeight="700">
                {courier.name}
              </Typography>
            </div>
            <div className="d-flex align-items-center gap-2 mt-2">
              <Button
                size="small"
                variant="gray"
                className="text-uppercase"
                onClick={() => shippingCheckConnectionMutation.mutate(courier.id)}
              >
                Sprawdź połączenie
              </Button>
              {courier.authorizationStatus === AuthorizationStatus.SUCCESS ? (
                <Tag startIcon={MdiCheckSmall} label="Połączono" variant="success" />
              ) : (
                <Tag startIcon={MdiEmergencyHome} label="Nie połączono" variant="danger" />
              )}
              {courier.checkedConnectionAt && (
                <div className="d-flex align-items-center gap-1">
                  <Typography fontSize="12" fontWeight="600" color="neutralBlack48">
                    ostatnio sprawdzono:
                  </Typography>
                  <Typography fontSize="12" fontWeight="600">
                    {getStandardDateFormat(courier.checkedConnectionAt)}
                  </Typography>
                  <Typography fontSize="12" fontWeight="600" color="neutralBlack48">
                    {getStandardTimeFormat(courier.checkedConnectionAt)}
                  </Typography>
                </div>
              )}
            </div>
            {courier.authorizationStatus === AuthorizationStatus.FAILURE && (
              <InfoLabel title="wystąpił następujący błąd" className="mt-2">
                <div className={cx(styles.errorCustomTag, "flex-1")}>
                  <Typography fontSize="12" fontWeight="800" color="danger500">
                    {courier.authorizationStatusError}
                  </Typography>
                </div>
              </InfoLabel>
            )}
          </RightPanelSection>
          <GeneralInfoSection courier={courier} />
          <DefaultOptionsSection courier={courier} />
        </RightPanelBody>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};

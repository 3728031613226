import { ConfirmResendingModal, SmsModal, Toastr } from "api/logistics/routes/models";
import { postRouteSms } from "api/routes/calls";
import {
  NoPhoneError,
  PostDeadline,
  PostRouteSmsErrorResponse,
  Route,
  SmsError,
} from "api/routes/models";
import { StatusHandlerHelpers } from "components/utils";
import { ToggleHookState } from "hooks";
import { getAnyErrorKey } from "utilities";
import styles from "../../../RightPanel.module.css";

export const sendSmsToAll = async (
  confirmationDateModal: ToggleHookState,
  confirmationDeadline: PostDeadline | null = null,
  confirmResendingModal: ConfirmResendingModal,
  force: { resendSms: boolean; sendSms: boolean; resendUnconfirmed: boolean },
  helpers: StatusHandlerHelpers,
  message: string | null,
  route: Route,
  singleSmsConfirmationDateModal: ToggleHookState,
  smsModal: SmsModal,
  toastr: Toastr,
) => {
  if (!route) return;
  helpers.startFetching();
  const [, error, { rawError }] = await postRouteSms(route.id, {
    message,
    force,
    deadline: confirmationDeadline,
  });
  helpers.stopFetching();
  if (!error) {
    toastr.open({
      type: "success",
      title: "Udało się!",
      text: "Wiadomość została wysłana",
    });
    smsModal.close();
    confirmationDateModal.close();
    singleSmsConfirmationDateModal.close();
  } else {
    const typedRawError = rawError as PostRouteSmsErrorResponse;

    if (Array.isArray(rawError)) {
      const filteredErrorMessages = typedRawError.filter(
        el => el.error !== "noPhone" && el.error !== "resendSms",
      ) as SmsError[];

      if (filteredErrorMessages.length) {
        const errorMessages = filteredErrorMessages.map(el => el.message);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: (
            <div>
              {errorMessages.map(message => (
                <div>{message}</div>
              ))}
            </div>
          ),
        });
        return;
      }

      const noPhoneError = typedRawError.find(el => el.error === "noPhone") as
        | NoPhoneError
        | undefined;
      const resendSmsError = typedRawError.find(el => el.error === "resendSms") as
        | SmsError
        | undefined;

      if (noPhoneError && resendSmsError) {
        confirmResendingModal.open({
          text: (
            <div className="mb-4">
              <div className={styles.errorLabel}>1. Brak numerów telefonu</div>
              <div className="text-color-grey">Poniższe zamówienia nie mają numeru telefonu:</div>
              {noPhoneError.orders.map((signature, index) => (
                <span key={signature}>
                  {signature}
                  {index !== noPhoneError.orders.length + 1 && ","}{" "}
                </span>
              ))}
              <div className="mb-4">
                Czy pomimo tego chcesz wysłać wiadomość SMS do pozostałych zamówień?
              </div>
              <div className={styles.errorLabel}>2. Powtórne powiadomienie</div>
              <div>{resendSmsError.message}</div>
            </div>
          ),
          confirmText: "Wyślij wiadomość",
          callback: () =>
            sendSmsToAll(
              confirmationDateModal,
              confirmationDeadline,
              confirmResendingModal,
              {
                resendSms: true,
                sendSms: true,
                resendUnconfirmed: force.resendUnconfirmed,
              },
              helpers,
              message,
              route,
              singleSmsConfirmationDateModal,
              smsModal,
              toastr,
            ),
        });
        return;
      }

      if (noPhoneError) {
        confirmResendingModal.open({
          text: (
            <div>
              <div className={styles.errorLabel}>Brak numerów telefonu</div>
              <div className="text-color-grey">Poniższe zamówienia nie mają numeru telefonu:</div>
              {noPhoneError.orders.map((signature, index) => (
                <span>
                  {signature}
                  {index !== noPhoneError.orders.length + 1 && ","}{" "}
                </span>
              ))}
              <div className="mb-4">
                Czy pomimo tego chcesz wysłać wiadomość SMS do pozostałych zamówień?
              </div>
            </div>
          ),
          confirmText: "Wyślij wiadomość",
          callback: () =>
            sendSmsToAll(
              confirmationDateModal,
              null,
              confirmResendingModal,
              {
                resendSms: false,
                sendSms: true,
                resendUnconfirmed: force.resendUnconfirmed,
              },
              helpers,
              message,
              route,
              singleSmsConfirmationDateModal,
              smsModal,
              toastr,
            ),
        });
        return;
      }

      if (resendSmsError) {
        confirmResendingModal.open({
          text: (
            <div className="mb-3">
              <div className={styles.errorLabel}>Powtórne powiadomienie</div>
              <div>{resendSmsError.message}</div>
            </div>
          ),
          confirmText: "Wyślij wiadomość",
          callback: () =>
            sendSmsToAll(
              confirmationDateModal,
              confirmationDeadline,
              confirmResendingModal,
              {
                resendSms: true,
                sendSms: false,
                resendUnconfirmed: force.resendUnconfirmed,
              },
              helpers,
              message,
              route,
              singleSmsConfirmationDateModal,
              smsModal,
              toastr,
            ),
        });
        return;
      }
    } else {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    }
  }
};

import { useRedux } from "hooks";
import { useMutation } from "hooks/useMutation";
import { PartialOf } from "typeUtilities";
import { UUID } from "api/types";
import { parsePatchData } from "utilities/parsePatchData";
import { assertIsDefined } from "utilities/assertIsDefined";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createApiQuery } from "hooks/createApiQuery";
import { getAnyErrorKey } from "utilities";
import { FormikHelpers } from "formik";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { wmsRampApi } from "./api";
import { CreateRamp, RampListItem } from "./models";
import { wmsRampKeys } from "./keys";

const useGetRamps = createPaginatedApiQuery(wmsRampApi.getRamps);
const useGetRamp = createApiQuery(wmsRampApi.getRamp);

const usePatchRamp = () => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  return useMutation(
    ({ id, toUpdate }: { id: UUID | number; toUpdate: PartialOf<RampListItem> }) => {
      return wmsRampApi.patchRamp(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(wmsRampKeys.details(String(id)), toUpdate);
        const prevList = queryUtils.handlePaginatedListUpdate(wmsRampKeys.list(), id, toUpdate);
        return { prevList, prevPanel };
      },
      onSuccess: () => {
        refetchPartials();
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(wmsRampKeys.details(String(id)), onMutateReturn.prevPanel, error);
        queryUtils.rollbackList(wmsRampKeys.list(), onMutateReturn.prevList, id);
      },
    }),
  );
};

const usePostRamp = (close: () => void) => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  const createRamp = useMutation(wmsRampApi.postRamp, ({ toastr, queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(wmsRampKeys.list());
      close();
      refetchPartials();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Dodano rampę",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

  const handleSubmit = (values: CreateRamp, actions: FormikHelpers<CreateRamp>) => {
    createRamp.mutate(values, {
      onSuccess: () => {
        actions.setSubmitting(false);
      },
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

const useDeleteRamp = (close: () => void, ramp: RampListItem) => {
  return withDeleteConfirmation(
    useMutation(wmsRampApi.deleteRamp, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries(wmsRampKeys.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto rampę "${ramp.name}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć tę rampę?",
  )();
};

export const wmsRampActions = {
  useGetRamps,
  useGetRamp,
  usePostRamp,
  usePatchRamp,
  useDeleteRamp,
};

import localStyles from "./EmployeeKind.module.css";
import cx from "classnames";
import { EmployeeKind as Kind, employeeKindConfigDict } from "CONSTANTS";

interface Props {
  employeeKind: Kind;
}


export const EmployeeKind = ({ employeeKind }: Props) => {
  return (
    <div
      className={cx(localStyles.employeeKindLabel, "p-2", {
        [localStyles.upholstererLabel]: employeeKind === "UPHOLSTERER",
        [localStyles.seamstressLabel]: employeeKind === "SEAMSTRESS",
        [localStyles.cutterLabel]: employeeKind === "CUTTER",
      })}
    >
      <span>{employeeKindConfigDict[employeeKind].name}</span>
    </div>
  );
};

import cuid from "cuid";
import avatar1 from "assets/images/avatar1.svg";
import avatar2 from "assets/images/avatar2.svg";
import avatar3 from "assets/images/avatar3.svg";
import avatar4 from "assets/images/avatar4.svg";
import avatar5 from "assets/images/avatar5.svg";
import avatar6 from "assets/images/avatar6.svg";
import avatar7 from "assets/images/avatar7.svg";
import avatar8 from "assets/images/avatar8.svg";
import avatar9 from "assets/images/avatar9.svg";
import avatar10 from "assets/images/avatar10.svg";
import avatar11 from "assets/images/avatar11.svg";
import avatar12 from "assets/images/avatar12.svg";
import avatar13 from "assets/images/avatar13.svg";
import styles from "./AvatarPicker.module.css";

interface Props {
  name?: string;
  checked: string;
  onChange: (value: string) => void;
}

const avatars = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  avatar11,
  avatar12,
  avatar13,
];

export const AvatarPicker = ({ name = cuid(), checked, onChange }: Props) => {
  return (
    <div className="d-flex align-items-center flex-wrap">
      {avatars.map(avatar => (
        <label className={styles.item} key={avatar}>
          <input
            type="radio"
            name={name}
            onChange={e => onChange(avatar)}
            checked={checked === avatar}
          />
          <img src={avatar} alt="" />
        </label>
      ))}
    </div>
  );
};

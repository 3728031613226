import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { RampsList } from "pages/wms/ramps/RampsList";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const Ramps = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={RampsList}
        exact={true}
        path={`${match.path}/list/:tab`}
        title="Milo - WMS - rampy"
      />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};

import { deleteRoute } from "api/logistics/routes/calls";
import { routesKeys } from "api/routes/keys";
import { Route } from "api/routes/models";
import { useQuery, useToastr } from "hooks";
import { useMutation } from "hooks/useMutation";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { RouteEditedHandler } from "pages/routes/shared/RouteEditedHandler";
import { getAnyErrorKey } from "utilities";

interface Props {
  route: Route;
}

export const useDeleteRoute = ({ route }: Props) => {
  const { updateQuery } = useQuery();
  const toastr = useToastr();

  const deleteMutation = withDeleteConfirmation(
    useMutation(deleteRoute, ({ queryClient }) => ({
      onSuccess: () => {
        updateQuery({ panelId: "" });
        queryClient.invalidateQueries(routesKeys.routes());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto trasę ${route.signature}`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
      onMutate: () => {
        RouteEditedHandler(route.id);
      },
    })),
  )();

  return deleteMutation;
};

export const green = {
  green12: "#67AC5B1F",
  green25: "#EAF5EA",
  green32: "#67AC5B52",
  green50: "#CEE5CB",
  green75: "#AFD4AB",
  green100: "#87D47E",
  green200: "#6FC765",
  green300: "#54B14A",
  green400: "#3DA232",
  green500: "#348E2B",
  green600: "#1E6617",
};

import { CurrentlyPrintedReceipt } from "api/trading-documents/models";
import { Button } from "components/common";
import { CommonError } from "components/utils";
import { CustomModal } from "components/utils/customModal";
import { RightPanelTableRow } from "components/utils/drawer";
import { ErrorType } from "hooks/createPaginatedQuery";
import { currentlyPrintedReceiptsSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import styles from "./PrintingStatusModal.module.css";
import cx from "classnames";
import { dateFns } from "utilities";

interface Props {
  close: () => void;
  error?: ErrorType | null;
  receipts?: CurrentlyPrintedReceipt[];
  title: string;
}

export const PrintingStatusModal = ({ close, error, receipts, title }: Props) => {
  if (error) {
    <CustomModal
      close={close}
      isOpen
      overrides={{ container: { className: styles.modalContainer } }}
      title="Teraz w druku"
    >
      <div className="mt-4">
        <CommonError status={error._httpStatus_} />
      </div>
    </CustomModal>;
  }

  if (receipts) {
    return (
      <CustomModal
        close={close}
        isOpen
        overrides={{ container: { className: styles.modalContainer } }}
        title={title}
      >
        <>
          <div className="pb-3">
            <RightPanelTableRow
              grid={currentlyPrintedReceiptsSectionListConfig.grid}
              hover={false}
              style={{ backgroundColor: "#fbfbfb" }}
            >
              <div>#</div>
              <div>nr dokumentu</div>
              <div>zamówienie</div>
              <div>klient</div>
              <div>kto zlecił?</div>
              <div>kiedy zlecono?</div>
            </RightPanelTableRow>
            {receipts.map((receipt, index) => (
              <RightPanelTableRow
                grid={currentlyPrintedReceiptsSectionListConfig.grid}
                key={receipt.id}
              >
                <div className="fw-700">{index + 1}.</div>
                <div className="body-14-600">{receipt.signature}</div>
                <div className={cx(styles.overflow, "fw-700")}>
                  {receipt.orders.map(order => (
                    <div>{order}</div>
                  ))}
                </div>
                <div className={cx(styles.overflow, "body-14 fw-700")}>
                  {receipt.recipientFirstName} {receipt.recipientLastName}
                </div>
                {receipt.printingRequestedBy ? (
                  <div className={cx(styles.overflow, "body-14 fw-700")}>
                    {receipt.printingRequestedBy?.firstName} {receipt.printingRequestedBy?.lastName}
                  </div>
                ) : (
                  <div className="body-14 fw-700">--</div>
                )}
                {receipt.printingRequestedAt ? (
                  <div className="text-black-6">
                    {dateFns.format(new Date(receipt.printingRequestedAt), "dd.MM.yyyy, HH:mm")}
                  </div>
                ) : (
                  <div className="body-14 fw-700 text-black-6">--</div>
                )}
              </RightPanelTableRow>
            ))}
          </div>
          <div className="d-flex align-items-center gap-3 p-3 borderTop">
            <Button kind="submit-medium" onClick={close}>
              <div className="btnBaseMedium btnBase">ZAMKNIJ</div>
            </Button>
          </div>
        </>
      </CustomModal>
    );
  }

  return null;
};

import { RoutesTab } from "api/logistics/routes/models";
import { queryString } from "utilities";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  tab: RoutesTab;
};

export const getSearch = ({ query, tab }: GetSearchProps) => {
  const tabsQuery = {
    status:
      tab === "new"
        ? "NEW"
        : tab === "route-ready"
        ? "ROUTE_READY"
        : tab === "departed"
        ? "DEPARTED"
        : tab === "finished"
        ? "FINISHED"
        : tab === "settled"
        ? "SETTLED"
        : "",
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

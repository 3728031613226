import { CURRENCY_TYPE, CountryCode } from "CONSTANTS";
import { ISODateTime, UUID } from "api/types";
import { UserWithInitials } from "api/users/models";
import { Assign } from "utility-types";

export type CargoUnloadTab = "not-started" | "on-going" | "finished" | "all";

export type CargoUnloadStatus = "NOT_STARTED" | "STARTED" | "FINISHED";

export type DailySummaryDirection = "IN" | "OUT";

export enum UnloadingTab {
  "not-started" = "not-started",
  "in-progress" = "in-progress",
  "finished" = "finished",
  "all" = "",
}

export enum UnloadingStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
  CANCELLED = "CANCELLED",
}

export enum UnloadingLineItemType {
  ORDER = "ORDER",
  INDEX = "INDEX",
  PACKAGE = "PACKAGE",
}

export enum WarehouseWorkerOccupation {
  WAREHOUSEMAN = "WAREHOUSEMAN",
  PICKER = "PICKER",
  PACKER = "PACKER",
}

export enum PackagesReceivedAndReleasedPerMonthIdentifiers {
  DATEPOINTER = "DATEPOINTER",
  RECEIVED_INDEXES = "RECEIVED_INDEXES",
  RECEIVED_PACKAGES = "RECEIVED_PACKAGES",
  RECEIVED_PACKAGES_PER_DAY_AVERAGE = "RECEIVED_PACKAGES_PER_DAY_AVERAGE",
  RELEASED_INDEXES = "RELEASED_INDEXES",
  RELEASED_PACKAGES = "RELEASED_PACKAGES",
  RELEASED_PACKAGES_PER_DAY_AVERAGE = "RELEASED_PACKAGES_PER_DAY_AVERAGE",
  REST_DAYS_COUNT = "REST_DAYS_COUNT",
}

export enum PackagesReceivedAndReleasedPerDayIdentifiers {
  DATEPOINTER = "DATEPOINTER",
  RECEIVED_INDEXES = "RECEIVED_INDEXES",
  RECEIVED_PACKAGES = "RECEIVED_PACKAGES",
  RELEASED_INDEXES = "RELEASED_INDEXES",
  RELEASED_PACKAGES = "RELEASED_PACKAGES",
  VERBOSE = "VERBOSE",
}

export enum ReleasedOrReceivedPerCategoryIdentifiers {
  DATEPOINTER = "DATEPOINTER",
  VERBOSE = "VERBOSE",
  OTHERS = "OTHERS",
}

export enum DailySummaryReceivedAndReleasedIdentifiers {
  DATEPOINTER = "DATEPOINTER",
  DIRECTION = "DIRECTION",
  PACKAGES_SUM = "PACKAGES_SUM",
  INDEXES_SUM = "INDEXES_SUM",
  OTHERS = "OTHERS",
}

export enum PickingsTabs {
  "not-started" = "not-started",
  "in-progress" = "in-progress",
  "finished" = "finished",
  "all" = "",
}

export enum PickingStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}

export enum PackageStockStatus {
  NOT_IO_STOCK = "NOT_IN_STOCK",
  IN_STOCK = "IN_STOCK",
}

export enum LoadingsTab {
  "not-started" = "not-started",
  "in-progress" = "in-progress",
  "finished" = "finished",
  "all" = "",
}

export enum LoadingStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}

export enum PickingPackageStatus {
  IN_STOCK = "IN_STOCK",
  ON_THE_WAY = "ON_THE_WAY",
  ON_RAMP = "ON_RAMP",
  PICKED_UP = "PICKED_UP",
}

export enum LoadingPackageStatus {
  ON_RAMP = "ON_RAMP",
  DURING_LOADING = "DURING_LOADING",
  PICKED_UP = "PICKED_UP",
  LOADED = "LOADED",
  LOCALIZED = "LOCALIZED",
}

export interface Buyer {
  firstName: string;
  lastName: string;
}

export interface Address {
  city: string;
  countryCode: CountryCode;
  postCode: string;
  street: string;
}

export interface BaseRamp {
  id: number;
  name: string;
}

export interface BaseSeller {
  id: number;
  name: string;
}

export interface NumberOfPickedUpPackages {
  onRamp: number;
  total: number;
}

export interface InventoryUser {
  id: number;
  joinedAt: string | null;
  participation: number;
}

export interface Picking {
  collaborators: UserWithInitials[];
  duration: string;
  estimatedTimeOfLoading: string | null;
  finishedAt: string | null;
  id: UUID;
  numberOfPickedUp: NumberOfPickedUpPackages;
  pauseEndedAt: string | null;
  pauseStartedAt: string | null;
  progress: {
    onTheWay: number;
    total: number;
  };
  qrCode: string;
  ramp: BaseRamp | null;
  scanningProgress: string;
  signature: string;
  startedAt: string | null;
  status: PickingStatus;
  suppliers: string[];
  totalProgress: string;
}

export interface PickingLineItem {
  address: Address;
  buyer: Buyer;
  collaborators: UserWithInitials[];
  id: UUID;
  isCancelled: boolean;
  locations: string[];
  name: string;
  numberOfPickedUp: NumberOfPickedUpPackages;
  onRampAt: string | null;
  picking: UUID;
  position: number;
  seller: BaseSeller;
  signature: string;
  sourceExternalId: string;
  status: PickingStatus;
}

export interface PickingDetails extends Picking {
  createdAt: string;
  createdBy: UserWithInitials;
  description: string;
  estimatedTimeOfDeparture: string | null;
  estimatedTimeOfPickingDate: string | null;
  estimatedTimeOfPickingTime: string | null;
}

export interface LastPickedOrder {
  collaborators: UserWithInitials[];
  id: UUID;
  locations: string[];
  name: string;
  onRampAt: string | null;
  picking: {
    id: UUID;
    signature: string;
  };
  ramp: BaseRamp | null;
  signature: string;
  seller: BaseSeller;
}

export interface LastLoadedOrder {
  address: Address;
  buyer: Buyer;
  collaborators: UserWithInitials[];
  id: UUID;
  loadedAt: string | null;
  loading: {
    id: UUID;
    signature: string;
  };
  name: string;
  ramp: BaseRamp | null;
  seller: BaseSeller;
  signature: string;
}

export interface NormalizedPickingPackageGroup {
  collaborator: UserWithInitials | null;
  id: UUID;
  location: string | null;
  name: string;
  pickedUpAt: string | null;
  placeOnRampAt: string | null;
  status: PickingPackageStatus | null;
}

export interface PickingPackageGroup {
  id: UUID;
  name: string;
  packages: {
    collaborator: UserWithInitials | null;
    id: UUID;
    location: string;
    name: string;
    pickedUpAt: string | null;
    placeOnRampAt: string | null;
    status: PickingPackageStatus;
  }[];
  status: PickingStatus;
}

export interface PickingLineItemDetails
  extends Omit<UnloadingLineItemDetails, "packagesGroups" | "suppliers" | "type" | "unloading"> {
  packagesGroups: PickingPackageGroup[];
  picking: {
    id: UUID;
    ramp: BaseRamp | null;
    signature: string;
    status: PickingStatus;
  };
}

export interface PickingGroupPackage {
  id: UUID;
  location: string;
  name: string;
  pickedUpAt: string | null;
  placeOnRampAt: string | null;
  status: PickingPackageStatus;
}

export interface PickingGroup {
  id: UUID;
  signature: string;
  position: number;
  status: UnloadingStatus;
  address: Address;
  seller: BaseSeller;
  buyer: Buyer;
  packages: PickingGroupPackage[];
}

export interface PickingWorkspaceInventory {
  picking: {
    signature: string;
    status: UnloadingStatus;
    totalProgress: number;
    ramp: BaseRamp | null;
  };
  pickingGroups: PickingGroup[];
  user: InventoryUser;
}

export interface NormalizedLoadingPackageGroup {
  collaborator: UserWithInitials | null;
  id: UUID;
  location: string | null;
  name: string;
  pickedUpAt: ISODateTime | null;
  loadedAt: ISODateTime | null;
  placeOnRampAt: ISODateTime | null;
  status: LoadingPackageStatus | null;
}

export interface LoadingPackageGroup {
  id: UUID;
  name: string;
  packages: {
    collaborator: UserWithInitials | null;
    id: UUID;
    loadedAt: string | null;
    location: string;
    name: string;
    pickedUpAt: string | null;
    placeOnRampAt: string | null;
    status: LoadingPackageStatus;
    uniqueCode: string;
  }[];
  status: LoadingStatus;
}

export interface LoadingLineItemDetails {
  address: Address;
  buyer: Buyer;
  createdAt: string;
  id: UUID;
  isCancelled: boolean;
  loadedAt: string;
  loading: {
    id: UUID;
    ramp: BaseRamp | null;
    signature: string;
    status: LoadingStatus;
  };
  name: string;
  packagesGroups: LoadingPackageGroup[];
  position: number;
  ramp: BaseRamp | null;
  signature: string;
  status: LoadingStatus;
  updatedAt: string | null;
}

export const dailySummaryDirectionDict: Record<DailySummaryDirection, string> = {
  IN: "Przyjęcia",
  OUT: "Wydania",
};

export const statusDict: Record<
  UnloadingStatus,
  { label: string; variant: "success" | "outline" | "info" | "warning" }
> = {
  NOT_STARTED: { label: "nierozpoczęto", variant: "outline" },
  IN_PROGRESS: { label: "w trakcie", variant: "info" },
  FINISHED: { label: "zakończono", variant: "success" },
  CANCELLED: { label: "anulowano", variant: "warning" },
};

export interface CargoUnloadRamp {
  code: string;
  codeBackgroundColor: string;
  codeTextColor: string;
  id: UUID;
  name: string;
}

export interface CargoUnload {
  customers: {
    id: string | number;
    name: string;
  }[];
  estimatedTimeOfArrival: string | null;
  finishedAt: string | null;
  id: UUID;
  orders: {};
  pauseEnd: string | null;
  pauseStart: string | null;
  ramp: CargoUnloadRamp | null;
  route: {};
  signature: string;
  startedAt: string | null;
  status: CargoUnloadStatus;
  users: any[];
}

export interface UnloadingLineItem {
  address: Assign<Address, { number: string }>;
  collaborators: UserWithInitials[];
  estimatedTimeOfArrival: string | null;
  id: UUID;
  inStockAt: string | null;
  isCancelled: boolean;
  locations: string[];
  name: string;
  numberOfPackageGroups: {
    inStock: number;
    total: number;
  };
  position: number;
  signature: string;
  sourceExternalId: string;
  status: UnloadingStatus;
  suppliers: {
    id: number;
    name: string;
    type: string;
  } | null;
  type: UnloadingLineItemType;
  unloading: UUID;
}

export interface UnloadingPackageGroup {
  id: UUID;
  name: string;
  status: UnloadingStatus;
  packages: {
    id: UUID;
    name: string;
    location: string;
    stockStatus: PackageStockStatus;
    addedToStockAt: string | null;
    collaborator: UserWithInitials;
  }[];
}

export type AttributesState = {
  attributeId: number;
  valueId: number | null;
  picture: string | null;
}[];

export interface AddLineItemToUnloadingPayload {
  id: UUID;
  unloadingId: UUID;
  customerId: number;
  indexId: number | null;
  quantity: number;
  productElements: {
    cuid: string;
    id: number;
    amount: string;
    name: string;
    attributesState: AttributesState;
    quantity: number;
    productSetCode: string;
    currency: CURRENCY_TYPE;
    product: number;
    index: number | null;
    note: string;
  }[];
}

export interface NormalizedUnloadingPackageGroup {
  addedToStockAt: string | null;
  collaborator: UserWithInitials | null;
  id: UUID;
  location: string | null;
  name: string;
  stockStatus: PackageStockStatus | null;
  subRows?: NormalizedUnloadingPackageGroup[];
}

export interface UnloadingLineItemDetails {
  address: Address;
  buyer: Buyer;
  createdAt: string;
  id: UUID;
  isCancelled: boolean;
  locations: string[];
  name: string;
  packagesGroups: UnloadingPackageGroup[];
  position: number;
  signature: string;
  status: UnloadingStatus;
  suppliers: string[];
  type: UnloadingLineItemType;
  unloading: {
    id: UUID;
    signature: string;
    status: UnloadingStatus;
    ramp: BaseRamp | null;
  };
  updatedAt: string | null;
}

export interface Unloading {
  actualTimeOfArrival: string | null;
  collaborators: UserWithInitials[];
  description: string;
  duration: string;
  estimatedTimeOfArrival: string | null;
  estimatedDateOfArrivalDate: string | null;
  estimatedDateOfArrivalTime: string | null;
  finishedAt: string | null;
  id: UUID;
  locationProgress: string;
  pauseEndedAt: string | null;
  pauseStartedAt: string | null;
  ramp: BaseRamp | null;
  scanningProgress: string;
  signature: string;
  startedAt: string | null;
  status: UnloadingStatus;
  suppliers:
    | {
        id: number | string;
        name: string;
        type: string;
      }[]
    | null;
  totalProgress: string;
}

export interface UnloadingDetails extends Unloading {
  qrCode: string;
}

export interface LastUnloadedGroup {
  collaborators: UserWithInitials[];
  id: UUID;
  inStockAt: string | null;
  locations: string[];
  signature: string;
  suppliers:
    | {
        id: number | string;
        name: string;
        type: string;
      }[]
    | null;
  unloading: {
    id: UUID;
    signature: string;
  };
}

export interface AddOrdersToUnloading {
  unloadingId: UUID;
  ordersIds: string[];
}

export interface WorkspaceInventoryHeader {
  signature: string;
  ramp: BaseRamp | null;
  source: "Załadunek" | "Rozładunek" | "Picking";
  status: UnloadingStatus;
  joinedAt: string | null;
  totalProgress: number;
  participation: number;
}

export interface UnloadingGroupPackage {
  addedToStockAt: string | null;
  collaborator: UserWithInitials | null;
  id: UUID;
  location: string;
  name: string;
  stockStatus: PackageStockStatus;
}

export interface UnloadingGroup {
  id: UUID;
  signature: string;
  position: number;
  status: UnloadingStatus;
  address: Address;
  packages: UnloadingGroupPackage[];
  suppliers: {
    id: string | number;
    name: string;
    type: string;
  }[];
  userId: number;
}

export interface UnloadingWorkspaceInventory {
  unloading: {
    signature: string;
    status: UnloadingStatus;
    totalProgress: number;
    ramp: BaseRamp | null;
  };
  unloadingGroups: UnloadingGroup[];
  user: InventoryUser;
}

export interface LoadingGroupPackage {
  id: UUID;
  loadedAt: string | null;
  location: string;
  name: string;
  pickedUpAt: string | null;
  placeOnRampAt: string | null;
  status: LoadingPackageStatus;
}

export interface LoadingGroup {
  id: UUID;
  signature: string;
  position: number;
  status: UnloadingStatus;
  address: Address;
  seller: BaseSeller;
  buyer: Buyer;
  packages: LoadingGroupPackage[];
}

export interface LoadingWorkspaceInventory {
  loading: {
    signature: string;
    status: UnloadingStatus;
    totalProgress: number;
    ramp: BaseRamp | null;
  };
  loadingGroups: LoadingGroup[];
  user: InventoryUser;
}

export interface Loading {
  collaborators: UserWithInitials[];
  createdAt: string;
  createdBy: UserWithInitials;
  description: string;
  duration: string | null;
  car: {
    id: number;
    brand: string;
    model: string;
    registrationNumber: string;
  } | null;
  estimatedTimeOfDeparture: string | null;
  estimatedTimeOfLoadingDate: string | null;
  estimatedTimeOfLoadingTime: string | null;
  finishedAt: string | null;
  id: UUID;
  loadingGroupsCounters: {
    completed: number;
    total: number;
  };
  pauseEndedAt: string | null;
  pauseStartedAt: string | null;
  qrCode: string;
  ramp: BaseRamp | null;
  sellers: BaseSeller[];
  signature: string;
  startedAt: string | null;
  status: LoadingStatus;
  totalProgress: number;
  updatedAt: string | null;
}

export interface LoadingDetails extends Loading {}

export interface LoadingLineItem {
  address: Address;
  buyer: Buyer;
  collaborators: UserWithInitials[];
  id: UUID;
  isCancelled: boolean;
  loadedAt: string | null;
  loading: UUID;
  loadingPackagesCounters: {
    loaded: number;
    total: number;
  };
  name: string;
  position: number;
  seller: BaseSeller;
  signature: string;
  sourceExternalId: string;
  status: LoadingStatus;
  totalProgress: number;
}

export type UnacceptedOrderReason = "noIndex" | "nonExistent";

export const unacceptedOrderReasonDict: Record<UnacceptedOrderReason, string> = {
  noIndex: "Brak indeksów w zamówieniu",
  nonExistent: "Brak w systemie",
};

export interface AddOrdersToUnloadingResponse {
  addedToUnloading: number[] | string[];
  inDifferentUnloading: {
    id: number | string;
    unloading: UUID[];
  }[];
  missingIndexes: number[] | string[];
  missingOrders: number[] | string[];
  missingPackages: number[] | string[];
}

export interface WarehouseWorkerListItem {
  monthlyFurnitureCount: {
    IN?: number;
    OUT?: number;
  };
  monthlyPackageCount: {
    IN?: number;
    OUT?: number;
  };
  firstName: string;
  id: number;
  isActive: boolean;
  lastName: string;
  occupation: WarehouseWorkerOccupation | "";
}

export interface WarehouseWorkerDetails
  extends Pick<
    WarehouseWorkerListItem,
    "firstName" | "id" | "isActive" | "lastName" | "occupation"
  > {
  email: string;
}

export interface CreateWarehouseman {
  email: string;
  firstName: string;
  lastName: string;
  occupation: WarehouseWorkerOccupation | "";
  phone: string;
}

export enum OverviewReleasedAndReceivedListColumns {
  DATEPOINTER = "DATEPOINTER",
  VERBOSE = "VERBOSE",
  RECEIVED_INDEXES = "RECEIVED_INDEXES",
  RECEIVED_PACKAGES = "RECEIVED_PACKAGES",
  RELEASED_INDEXES = "RELEASED_INDEXES",
  RELEASED_PACKAGES = "RELEASED_PACKAGES",
}

export enum OverviewReceivedByProductCategory {
  DATEPOINTER = "DATEPOINTER",
  VERBOSE = "VERBOSE",
  OTHERS = "OTHERS",
}

export enum OverviewReleasedByProductCategory {
  DATEPOINTER = "DATEPOINTER",
  VERBOSE = "VERBOSE",
  OTHERS = "OTHERS",
}

export enum OverviewReleasedForCustomer {
  DATEPOINTER = "DATEPOINTER",
  VERBOSE = "VERBOSE",
  CUSTOMER = "CUSTOMER",
  OTHERS = "OTHERS",
}

export enum OverviewReceivedForCustomer {
  DATEPOINTER = "DATEPOINTER",
  VERBOSE = "VERBOSE",
  CUSTOMER = "CUSTOMER",
  OTHERS = "OTHERS",
}

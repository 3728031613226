import { memo } from "react";
import { AutocompleteAsyncHandler, Autocomplete } from "components/utils";
import styles from "./PointsList.module.css";
import { getOrdersItemsSearch } from "api/orders/calls";
import { OrderItemsSearch } from "api/orders/models";
import cx from "classnames";
import { useInfoWindow } from "../../hooks/useInfoWindow";
import { useHistory, useParams } from "react-router";

export const SearchBar = memo(() => {
  const { toggleInfoWindow } = useInfoWindow();
  const history = useHistory();
  const params = useParams<{ routeId: string }>();

  return (
    <div className={styles.searchBarContainer}>
      <AutocompleteAsyncHandler
        debounce={1000}
        fetchFrom={getOrdersItemsSearch}
        transform={(order: OrderItemsSearch) => ({
          id: order.id,
          delivery: order.delivery,
          route: order.route,
          name: (
            <div
              className={cx(
                "d-flex align-items-center justify-content-between position-relative",
                styles.searchBarItem,
                { [styles.currentRoute]: order.route?.id === Number(params.routeId) },
              )}
            >
              <div
                className={cx("d-flex flex-column", {
                  [styles.disabled]: order.route && order.route?.id !== Number(params.routeId),
                })}
              >
                <div className={cx("fs-12", styles.searchBarAddress)}>
                  {order.delivery.postCode} {order.delivery.city},
                  <span className={cx("ml-1", { "text-color-grey": !order.route })}>
                    {order.delivery.street}
                  </span>
                </div>
                <div className={cx("fs-14", styles.searchBarAddress)}>
                  <span className={styles.routeSignature}>
                    {order.signature}
                    {order.externalId ? (
                      <span className="ml-1 text-color-grey">({order.externalId})</span>
                    ) : null}
                  </span>
                  <span className="ml-1">
                    {order.customer && !order.customer.hasDropShipping ? (
                      <span
                        className="label mr-1"
                        style={{
                          backgroundColor: order.customer.color,
                        }}
                      >
                        <span
                          style={{
                            color: order.customer.textColor,
                          }}
                        >
                          {order.customer.name}
                        </span>
                      </span>
                    ) : (
                      <span className={cx({ "text-color-grey": !order.route }, "fs-14")}>
                        {order.delivery.firstName} {order.delivery.lastName}
                      </span>
                    )}
                  </span>
                </div>
              </div>
              {order.route && (
                <div className={styles.routeSignatureWrapper}>
                  <div
                    className={cx(styles.routeLabel, "fs-10 nowrap")}
                    style={{ background: order.route.color }}
                    onClick={() => {
                      history.push(`/routes/creator/${order.route!.id}`);
                    }}
                  >
                    {order.route.signature}
                  </div>
                  <div className="fs-10">
                    {order.route?.id === Number(params.routeId) && "Aktualna trasa"}
                  </div>
                </div>
              )}
            </div>
          ),
        })}
      >
        <Autocomplete
          orientation="top"
          selectedItems={[]}
          placeholder="Szukaj..."
          overrides={{
            input: { className: styles.searchInput },
            list: { className: styles.searchBarList },
            item: { className: styles.item },
          }}
          onChange={(val: any) => {
            if (val[0] && (val[0].route?.id === Number(params.routeId) || !val[0].route)) {
              toggleInfoWindow(val[0].id, val[0].delivery.point);
            }
          }}
          multiple={false}
        />
      </AutocompleteAsyncHandler>
    </div>
  );
});

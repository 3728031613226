import styles from "./shared.module.css";
import cx from "classnames";

interface Props<T extends string | string[]> {
  name: string;
  checked: boolean;
  value: T;
  handleChange: (value: T | null, name: string, checked?: boolean) => void;
  multiple?: boolean;
  label: string;
  icon?: string;
  content?: React.ReactNode;
}
export const Chip = <T extends string | string[]>({
  name,
  checked,
  handleChange,
  value,
  multiple,
  label,
  icon,
  content,
}: Props<T>) => {
  return (
    <label className={cx("option mr-1 d-inline-flex p0", styles.labelOption)}>
      <input
        type={multiple ? "checkbox" : "radio"}
        name={name}
        checked={checked}
        value={value ?? ""}
        onClick={() => {
          if (checked) {
            handleChange(value, name, checked);
          }
        }}
        onChange={e => handleChange(e.target.value as T, name)}
        className={styles.labelInput}
      />
      <span
        className={cx(styles.labelRadiomark, {
          [styles.labelRadiomarkIcon]: Boolean(icon),
          [styles.checked]: Boolean(checked),
        })}
      >
        {content && content}
        {icon && <img src={icon} alt={label} />}
        {label}
      </span>
    </label>
  );
};

import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import routeIcon from "assets/images/route.svg";
import { CallCenterRoute } from "routes/CallCenterRoute";
import { FilterList } from "components/common/moduleNavigation/components/filtersSection/filterList/FilterList";
import { FilterType } from "components/common/filters/types";

export const route: ModuleLink = {
  url: "route",
  label: "Trasa",
  icon: routeIcon,
  subSection: (
    <FilterList
      filters={[
        {
          type: FilterType.Select,
          label: "Czy ma nieodczytane wiadomości?",
          name: "hasUnreadMessages",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
      ]}
    />
  ),
  routing: CallCenterRoute,
};

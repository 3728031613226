import { useQuery } from "hooks";
import styles from "../RouteOrdersList.module.css";
import cx from "classnames";

interface FilterSquareMultiSelectType {
  name: string;
  options: { icon?: string; value: string; label: string }[];
  multiple?: boolean;
  overwrites?: {
    label?: { className?: string };
  };
}
type Option = FilterSquareMultiSelectType["options"][number];

export const FilterSquareMultiSelect = ({
  name,
  options,
  overwrites = {},
  multiple,
}: FilterSquareMultiSelectType) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const value: Option["value"] = query[name];

  const handleChange = (optionValue: Option["value"] | null) => {
    if (optionValue && !multiple) {
      const appliedFilters = value?.split(",").filter(Boolean);
      if (appliedFilters?.includes(optionValue)) {
        updateQuery({ [name]: "", page: 1 });
        return appliedFilters?.filter(appliedValue => appliedValue !== optionValue).join(",");
      }
      return updateQuery({ [name]: optionValue, page: 1 });
    }
    if (optionValue && multiple) {
      let values = (() => {
        const appliedFilters = value?.split(",").filter(Boolean);
        if (appliedFilters?.includes(optionValue)) {
          return appliedFilters?.filter(appliedValue => appliedValue !== optionValue).join(",");
        }
        let returnValue = [...(appliedFilters || []), optionValue].join(",");
        return returnValue;
      })();
      updateQuery({ [name]: values, page: 1 });
      return;
    }

    updateQuery({ [name]: "", page: 1 });
  };

  return (
    <>
      {options.map(option => {
        let checked = value === option.value || value?.includes(option.value);
        if (!value) checked = false;

        return (
          <label
            className={cx("d-inline-flex", styles.tag, styles.tagFill, overwrites.label?.className)}
          >
            <input
              checked={checked}
              onClick={() => {
                if (checked) {
                  handleChange(null);
                }
              }}
              onChange={() => {
                handleChange(option.value);
              }}
              type="checkbox"
              className={styles.tagInput}
            />
            <span className={styles.tagSelected}>
              {option.icon && <img src={option.icon} alt="" className="imgSX" />}
              {option.label}
            </span>
          </label>
        );
      })}
    </>
  );
};

import { useWarehouseTotalValueSummary } from "api/trading-documents/hooks";
import { Button, PageHeader } from "components/common";
import downloadFileIcon from "assets/images/downloadFile.svg";
import uploadFileIcon from "assets/images/uploadFile.svg";
import visibilityOffIcon from "assets/images/eye_off.svg";
import areaChartIcon from "assets/images/areaChart.svg";
import { Loader } from "components/utils/loader";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import styles from "../WarehouseFinancialState.module.css";
import { useQuery } from "hooks";
import { queryString } from "utilities";

export const Header = () => {
  const { query } = useQuery();
  const { warehouses, inAt } = query;
  const { data: warehouseTotalValue, error, isLoading } = useWarehouseTotalValueSummary(
    queryString.stringify({ warehouses, inAt }),
  );

  return (
    <div>
      {isLoading && <Loader module="finances" />}
      <PageHeader
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Pobierz plik"
                  src={downloadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Importuj plik"
                  src={uploadFileIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <span className="line-divider" />
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Wyłącz widoczność"
                  src={visibilityOffIcon}
                  style={{ height: "16px", width: "16px" }}
                />
              </div>
            </Button>
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall">
                <img alt="Wykres" src={areaChartIcon} style={{ height: "16px", width: "16px" }} />
              </div>
            </Button>
          </div>
        }
        bottomBarTitle={<div style={{ height: "48px" }} />}
        searchBar={false}
        title={
          warehouseTotalValue && !error && warehouseTotalValue.whValueSummary ? (
            <>
              Wartość magazynu:
              <div className="d-flex align-items-center fw-800">
                <FinanceAmountFormat
                  decimalClassName={styles.summaryAmountDecimal}
                  value={warehouseTotalValue.whValueSummary}
                />{" "}
                PLN
              </div>
            </>
          ) : (
            <>
              Wartość magazynu:
              <span className="fw-800">brak danych</span>
            </>
          )
        }
        viewLabel="WAREHOUSE_FINANCIAL_STATE"
      />
    </div>
  );
};

import { ReactNode } from "react";
import * as React from "react";
import styles from "./Checkbox.module.css";
import cuid from "cuid";
import cx from "classnames";

interface Props {
  className?: string;
  checked: boolean;
  onChange: (status: boolean) => void;
  disabled?: boolean;
  label?: ReactNode;
  name: string;
  overwrites?: {
    label?: { className?: string };
    wrapper?: { className?: string; style?: React.CSSProperties };
  };
}

/**
 * @deprecated
 */
export const Checkbox = ({
  className = "",
  checked,
  onChange,
  disabled,
  label,
  name,
  overwrites = {},
  ...props
}: Props &
  Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "onChange"
  >) => {
  const id = React.useMemo(() => cuid(), []);
  return (
    <div
      className={cx(styles.container, className, overwrites.wrapper?.className ?? "", {
        [styles.disabled]: disabled,
      })}
      style={overwrites.wrapper?.style}
    >
      <label
        htmlFor={id}
        className={cx(overwrites.label?.className ?? "", {
          checkMark: true,
          [styles.checkMark]: true,
          [styles.checkMarkActive]: checked,
        })}
      />
      <input
        id={id}
        name={name}
        onChange={() => onChange(!checked)}
        type="checkbox"
        className={styles.input}
        checked={checked}
        disabled={disabled}
        {...props}
      />
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export const setMonthCheck = (
  check: boolean,
  name: string,
  setMonthsToDisplay: React.Dispatch<
    React.SetStateAction<
      {
        checked: boolean;
        key: string;
        label: string;
        name: string;
        position: number;
        value: string[];
      }[]
    >
  >,
) => {
  setMonthsToDisplay(months => {
    const currentMonth = months.find(month => month.name === name);
    if (currentMonth) {
      const updatedMonth = {
        ...months[months.indexOf(currentMonth)],
        checked: check,
      };

      const newMonths = [...months];
      newMonths[months.indexOf(currentMonth)] = updatedMonth;

      const sortedMonths = [...newMonths].sort((a, b) => a.position - b.position);

      return sortedMonths;
    }
    return months;
  });
};

import { Button } from "components/common";
import { RadioOption, RadioOptions } from "components/common/radioOptions";
import { RightPanelHeader } from "components/utils/drawer";
import { PopoverMenu } from "components/utils/Popover";
import checkIcon from "assets/images/check_small.svg";
import arrowDownIcon from "assets/images/keyboardArrowDown.svg";
import { User } from "ducks/auth";
import { useSelector } from "hooks";
import styles from "../ReceptionsSection.module.css";
import { InvoiceAdmission, InvoiceReviewer } from "api/trading-documents/models";
import { ConfirmReception } from "../../confirmReception/ConfirmReception";

interface Props {
  invoiceAdmission: InvoiceAdmission;
}

export const PanelHeader = ({ invoiceAdmission }: Props) => {
  const me = useSelector(store => store.auth.user!);

  return (
    <RightPanelHeader className={styles.panelHeader}>
      <ConfirmReception invoiceAdmission={invoiceAdmission} />
      <div className="d-flex align-items-center justify-content-end gap-1 w-100">
        <div style={{ position: "relative" }}>
          <PopoverMenu
            target={btnProps => (
              <div role="button" {...btnProps}>
                <Button className={styles.approvalBtn} kind="create">
                  <div className="btnBase btnBaseSmall">
                    <img alt="Gotowe" src={checkIcon} style={{ height: "16px", width: "16px" }} />
                    Zaakceptuj fakturę
                    <img alt="Rozwiń" src={arrowDownIcon} />
                  </div>
                </Button>
              </div>
            )}
          >
            {({ close }) => (
              <RadioOptions
                btnDisabled={!isLoggedUserInReviewers(me, invoiceAdmission.reviewers)}
                close={close}
                options={options}
                // onSubmit={value => submitReviewStatus(String(value))}
                onSubmit={() => {}}
              />
            )}
          </PopoverMenu>
        </div>
      </div>
    </RightPanelHeader>
  );
};

const isLoggedUserInReviewers = (user: User | null, reviewers: InvoiceReviewer[]): boolean => {
  if (user) {
    return reviewers.some(reviewer => reviewer.user.id === user.id);
  }
  return false;
};

const options: RadioOption[] = [
  { name: "Zaakceptuj", label: "Zaakceptuj importowaną fakturę", value: "ACCEPTED" },
  {
    name: "Zarządaj zmian",
    label: "Faktura wymaga zmian przed zatwierdzeniem",
    value: "CHANGES_REQUESTED",
  },
];

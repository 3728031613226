import { useShelfItemDetails } from "api/new-production-plans/hooks";
import {
  ProductionPlan,
  SelectedSource,
  ShelfManufacturingItem,
} from "api/new-production-plans/models";
import { queryString } from "utilities";
import cx from "classnames";
import localStyles from "./ShelfItemDetails.module.css";
import { ManufacturingItem } from "pages/productionPlans/productionPlanDetails/subcomponents/employeeRow/subcomponents/employeeWorkingDay/subcomponents/manufacturingItem/ManufacturingItem";
import { CommonError, Pagination, Spinner } from "components/utils";
import { Droppable } from "react-beautiful-dnd";

interface Props {
  filterValue: number | null;
  header: string;
  origin: string;
  productionPlan: ProductionPlan;
  search: string;
  selectedSources: SelectedSource[];
  setKeyStrings: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  setShelfItemHeight: React.Dispatch<React.SetStateAction<number>>;
  shelfHeight: number;
}

export const ShelfItemDetails = ({
  filterValue,
  header,
  origin,
  productionPlan,
  search,
  selectedSources,
  setKeyStrings,
  setShelfItemHeight,
  shelfHeight,
}: Props) => {
  const parsedSearch = queryString.parse(search);
  const detailedSearch = queryString.stringify({
    groupByFilter: header !== "Pozostałe" ? origin : parsedSearch.groupBy,
    groupByValue: filterValue ? filterValue : "",
    groupUniqueIdentifier: filterValue ? filterValue : "null",
  });
  const mergedFilters = queryString.merge([search, detailedSearch]);
  const hackSearch = `${productionPlan.id}$${mergedFilters}`;
  const { data: shelfItemDetails, error, isLoading, pagination } = useShelfItemDetails(hackSearch, {
    onSuccess: payload => {
      const manufacturingWorkingUnits = payload as ShelfManufacturingItem[];

      setKeyStrings(prevKeyStrings => {
        const keyString = filterValue ? String(filterValue) : "null";

        return {
          ...prevKeyStrings,
          [keyString]: mergedFilters,
        };
      });

      setShelfItemHeight(prev => {
        const itemsHeight = 54.55 * (manufacturingWorkingUnits.length + 1);
        if (shelfHeight > itemsHeight) {
          return shelfHeight;
        }
        return itemsHeight;
      });
    },
  });

  if (error) {
    <CommonError status={error._httpStatus_} />;
  }

  return (
    <div
      className={cx(localStyles.shelfManufacturingItems, {
        "mt-1": shelfItemDetails.length > 0,
      })}
    >
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner color="blue" size="big" on={isLoading} text="trwa wczytywanie" />
        </div>
      )}
      {!isLoading && shelfItemDetails.length === 0 && (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <span style={{ color: "#808080" }}>Wszystkie produkty zostały przypisane</span>
        </div>
      )}
      {shelfItemDetails.length > 0 && (
        <Droppable droppableId={`shelf$${filterValue ? String(filterValue) : "null"}`}>
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {shelfItemDetails.map((shelfManufacturingItem, index) => (
                <ManufacturingItem
                  index={index}
                  key={shelfManufacturingItem.id}
                  manufacturingItem={shelfManufacturingItem}
                  selectedSources={selectedSources}
                  source="SHELF"
                />
              ))}
              <Pagination pagination={pagination} sticky />
            </div>
          )}
        </Droppable>
      )}
    </div>
  );
};

import { postAutoAssignManufacturingWorkingUnits } from "api/new-production-plans/calls";
import { productionPlansKeys } from "api/new-production-plans/keys";
import { AutoAssignPayload } from "api/new-production-plans/models";
import { UUID } from "api/types";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import { Assign } from "utility-types";

export const useAutoAssignManufacturingWorkingUnits = () => {
  return useMutation(
    ({ filters, group, id, rules }: Assign<AutoAssignPayload, { id: UUID }>) => {
      if (group) {
        return postAutoAssignManufacturingWorkingUnits({ filters, group, rules }, id);
      }
      return postAutoAssignManufacturingWorkingUnits({ filters, rules }, id);
    },
    ({ queryClient, toastr }) => ({
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          productionPlansKeys.productionPlanSchedule(String(variables.id)),
        );
        queryClient.invalidateQueries(productionPlansKeys.shelfItemDetails());
        queryClient.invalidateQueries(productionPlansKeys.shelfItems(String(variables.id)));
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

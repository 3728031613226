import { format, subDays } from "date-fns";
import { pl } from "date-fns/locale";

export const getLastFiveDaysForFilters = (): { label: string; value: string }[] => {
  const labels = ["dzisiaj", "wczoraj", "przedwczoraj", "trzy dni temu", "cztery dni temu"];
  const currentDate = new Date();

  return labels.map((label, index) => {
    let date;
    if (index === 0) {
      date = currentDate; // Today
    } else if (index === 1) {
      date = subDays(currentDate, 1); // Yesterday
    } else {
      date = subDays(currentDate, index);
    }

    const formattedDate = format(date, "yyyy-MM-dd");
    const dayName = format(date, "EEEE", { locale: pl });
    return {
      label: index === 0 ? "dzisiaj" : index === 1 ? "wczoraj" : dayName.toLowerCase(),
      value: formattedDate,
    };
  });
};

import { AvailableFilters } from "components/common/filters/types";
import cx from "classnames";
import { TypeBasedFilter } from "components/common/filters/TypeBasedFilter";
import styles from "./FilterList.module.css";
import { ApiFetcher } from "hooks/createApiQuery";
import { Pagination } from "api/types";
import { ApiMiddlewareResult } from "apiConnectors/fetchConnector";

interface Props<FF, T> {
  filters: AvailableFilters<FF, T>[];
}

export const FilterList = <
  FF extends (
    arg: string,
    abortToken?: string,
  ) => ApiFetcher<Pagination<any>> | ApiMiddlewareResult<Pagination<any>>,
  T extends string
>({
  filters,
}: Props<FF, T>) => {
  return (
    <div className={styles.filtersBody}>
      {filters.map((filter, index) => (
        <div className={cx(styles.wrapper, { "border-0": index === 0 })} key={filter.label}>
          <p className={styles.label}>{filter.label}</p>
          <TypeBasedFilter filter={filter} />
        </div>
      ))}
    </div>
  );
};

import { useAutomaticSmsScheduleForRoute } from "api/routes/hooks";
import { CommonError, Spinner } from "components/utils";
import { queryString } from "utilities";
import styles from "./AutomaticSmsScheduleModal.module.css";
import { NoResults } from "components/utils/noResults";
import { SmsSchedule } from "./SmsSchedule";
import { CustomModal } from "components/utils/customModal";
import { RightPanelTableRow } from "components/utils/drawer";
import { automaticSmsScheduleSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";

interface Props {
  close: () => void;
  routeId: number;
}

export const AutomaticSmsScheduleModal = ({ close, routeId }: Props) => {
  const search = queryString.stringify({ route: routeId });
  const { data: smsSchedules, error, isLoading } = useAutomaticSmsScheduleForRoute(search);

  const noResults = !smsSchedules.length && !isLoading && !error;

  return (
    <CustomModal
      close={close}
      isOpen
      overrides={{ container: { className: styles.modal } }}
      title="Harmonogram automatycznie wysyłanych SMS"
    >
      <div>
        <div>
          {error && <CommonError status={error.status} />}
          <Spinner
            on={isLoading}
            color="blue"
            size="big"
            text="trwa wczytywanie harmonogramu"
            className={styles.spinner}
          />
          <RightPanelTableRow
            grid={automaticSmsScheduleSectionListConfig.grid}
            hover={false}
            style={{ backgroundColor: "#fbfbfb" }}
          >
            <div>data wysyłki</div>
            <div>czas wysyłki</div>
            <div className="d-flex justify-content-end">wysłano pomyślnie</div>
            <div className="d-flex justify-content-end">błędy</div>
            <div className="d-flex justify-content-center">status</div>
          </RightPanelTableRow>
          {!noResults &&
            smsSchedules.map(smsSchedule => (
              <SmsSchedule key={smsSchedule.id} smsSchedule={smsSchedule} />
            ))}
          {noResults && (
            <NoResults
              on={noResults}
              overwrites={{
                background: {
                  className: styles.noResultsOverwrite,
                },
              }}
              subHeader="trasy"
            />
          )}
        </div>
      </div>
    </CustomModal>
  );
};

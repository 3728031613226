import { UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";

const postStartLoading = (data: { loadingId: UUID }) =>
  queryFetch<void>({
    method: "POST",
    url: `/wms/loading/web/start-loading`,
    data,
  });

const postFinishLoading = (data: { loadingId: UUID }) =>
  queryFetch<void>({
    method: "POST",
    url: `/wms/loading/web/finish-loading`,
    data,
  });

export const wmsLoadingApi = {
  postStartLoading,
  postFinishLoading,
};

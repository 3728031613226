import { ExternalManufacturingOrder } from "api/external-manufacturing/models";
import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { EstimatedTimeOfSendingEmail } from "pages/externalManufacturerPanel/shared/components/EstimatedTimeOfSendingEmail";

interface Props {
  manufacturingOrder: ExternalManufacturingOrder;
}

export const GeneralInfoSection = ({ manufacturingOrder }: Props) => {
  return (
    <RightPanelSection padding="px-3 pt-0 pb-2">
      <InfoLabel title="producent">
        <Typography fontSize="14" fontWeight="700">
          {manufacturingOrder.manufacturer.name}
        </Typography>
      </InfoLabel>
      <InfoLabel title="planowana wysyłka">
        <EstimatedTimeOfSendingEmail
          estimatedTimeOfSendingEmail={manufacturingOrder.estimatedTimeOfSendingEmail}
          fontSize="16"
          fontWeight="700"
        />
      </InfoLabel>
    </RightPanelSection>
  );
};

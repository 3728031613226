import { UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { UpdateStatusPayload } from "../models";

const postProformaForOrder = (data: { orderId: number }) =>
  queryFetch<"ok">({
    method: "POST",
    url: "/finances/trading-documents/create-proforma",
    data,
  });

const patchProformaStatus = (data: { tradingDocument: UUID }) =>
  queryFetch<UpdateStatusPayload>({
    method: "PATCH",
    url: "/finances/trading-documents/confirm-proforma-invoice",
    data,
  });

const createSalesInvoiceFromProforma = (id: UUID) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/trading-documents/create-sales-invoice-from-proforma/" + id,
  });

export const proformasApi = {
  postProformaForOrder,
  patchProformaStatus,
  createSalesInvoiceFromProforma,
};

import { wmsRampActions } from "api/wms/ramps/actions";
import { RampListItem } from "api/wms/ramps/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader } from "components/utils/drawer";
import { DOMAIN } from "ENDPOINTS";

interface Props {
  close: () => void;
  ramp: RampListItem;
}

export const PanelHeader = ({ close, ramp }: Props) => {
  const deleteMutation = wmsRampActions.useDeleteRamp(close, ramp);

  return (
    <RightPanelHeader>
      <div className="d-flex align-items-center justify-content-end flex-1">
        <Tooltip title="Podgląd etykiety rampy">
          <IconButton
            icon={<MdiQrCode size="16" />}
            onClick={() => window.open(`${DOMAIN}wms/ramps/get-labels/${ramp.id}`, "_blank")}
            variant="transparent"
          />
        </Tooltip>
        <Menu
          disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
          menuItems={[
            {
              type: MenuItemType.TEXT,
              options: {
                color: "red100",
                iconStart: MdiDelete,
              },
              onClick: () => deleteMutation.mutate(ramp.id),
              text: "Usuń",
            },
          ]}
        />
        <span className="divider line-divider" />
        <Tooltip title="Zamknij panel">
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </Tooltip>
      </div>
    </RightPanelHeader>
  );
};

import { AddPurchaseInvoicePosition } from "api/trading-documents/models";
import { UUID } from "api/types";

export const getPurchasePositionInitialValues = (
  tradingDocumentOrder: UUID,
  maxDisplayPosition: number,
): AddPurchaseInvoicePosition => {
  return {
    name: "",
    vatRate: 0,
    tradingDocumentOrder: tradingDocumentOrder,
    unit: "szt.",
    displayPosition: maxDisplayPosition + 1,
    amountWithoutTax: 0,
    quantity: 1,
    discount: 0,
    note: "",
    codeCnPkwiu: "",
    verboseAttributesValues: "",
  };
};

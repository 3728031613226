import { useUnloading } from "api/wms/hooks";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { assertIsDefined } from "utilities/assertIsDefined";
import { PanelHeader } from "./components/PanelHeader";
import { GeneralInfoSection } from "./components/generalInfoSection/GeneralInfoSection";
import { TitleSection } from "./components/titleSection/TitleSection";
import { ProgressSection } from "./components/progressSection/ProgressSection";
import { OrdersSection } from "./components/ordersSection/OrdersSection";
import { useDrawer } from "hooks/useDrawer";

export const RightPanel = () => {
  const { query } = useQuery();
  const { close } = useDrawer("wmsUnloadings");
  const { panelId } = query;
  const { data: unloading, error, isLoading } = useUnloading(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(unloading);

  return (
    <DrawerRightPanel className="position-relative" key={panelId}>
      <PanelHeader close={close} unloading={unloading} />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody>
          <TitleSection unloading={unloading} />
          <GeneralInfoSection unloading={unloading} />
          <ProgressSection unloading={unloading} />
          <OrdersSection unloading={unloading} />
        </RightPanelBody>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};

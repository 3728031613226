import { useSummaryPerOutPackage } from "api/trading-documents/hooks";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { PanelHeader } from "pages/tradingDocuments/warehouseFinancialState/shared/rightPanel/PanelHeader";
import { TitleSection } from "pages/tradingDocuments/warehouseFinancialState/shared/rightPanel/TitleSection";
import { WhEntriesSection } from "pages/tradingDocuments/warehouseFinancialState/shared/rightPanel/WhEntriesSection";
import styles from "../../../WarehouseFinancialState.module.css";
import cx from "classnames";

interface Props {
  close: () => void;
}

export const RightPanel = ({ close }: Props) => {
  const { query } = useQuery();
  const { packageOut } = query;
  const keys = Object.keys(query);

  const { data: summaryPerPackage, error, isLoading } = useSummaryPerOutPackage(
    { id: packageOut },
    {
      enabled: Boolean(packageOut),
    },
  );

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!summaryPerPackage) return null;

  return (
    <DrawerRightPanel
      className={cx(styles.rightPanelPositioning, {
        [styles.rightPanelVisibility]: keys.indexOf("packageOut") > keys.indexOf("packageIn"),
      })}
      key={summaryPerPackage.id}
    >
      <PanelHeader close={close} title="Wydana paczka" />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody>
          <TitleSection internalId={summaryPerPackage.internalId} title={summaryPerPackage.name} />
          <WhEntriesSection summary={summaryPerPackage} title="Wydania" />
        </RightPanelBody>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};

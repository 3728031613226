import { memo } from "react";
import { getPagination, noop } from "utilities";
import { Spinner, StateProxy } from "components/utils";
import { useQuery } from "hooks";
import prevIcon from "assets/images/keyboardArrowLeft.svg";
import nextIcon from "assets/images/keyboardArrowRight.svg";
import { Button } from "components/common/buttonLegacy";
import styles from "./PanelPagination.module.css";
import cx from "classnames";

interface Props {
  pagination: ReturnType<typeof getPagination>;
  onChange?: (page: number) => void;
  mode?: "auto" | "manual";
  page?: number;
  isFetching?: boolean;
}

export const PanelPagination = memo(
  ({ pagination, onChange = noop, mode = "auto", page, isFetching = false }: Props) => {
    const { query, setQuery } = useQuery();
    const { limit, count, pageSize } = pagination;
    const debounceRequest = Number(page || query.page || 1) !== pagination.page;

    const handlePage = (page: number) => {
      if (mode === "auto") {
        setQuery({ ...query, page });
      }
      onChange(page);
    };

    if (!pagination.count) {
      return null;
    }

    return (
      <div className={styles.pagination}>
        <StateProxy
          state={page || Number(query.page) || 1}
          debounce={debounceRequest ? 1000 : undefined}
          onChange={handlePage}
        >
          {({ state, setState }) => (
            <div className="d-flex align-items-center justify-content-between gap-2">
              <div className="d-flex align-items-center">
                <Button
                  kind="create-transparent"
                  onClick={() => (state < 2 ? null : setState(s => s - 1))}
                  disabled={state <= 1}
                >
                  <img src={prevIcon} alt="Poprzednie" style={{ height: "16px", width: "16px" }} />
                </Button>
                <Button
                  kind="create-transparent"
                  onClick={() => (!limit || state >= limit ? null : setState(s => s + 1))}
                  disabled={!limit || state >= limit}
                >
                  <img src={nextIcon} alt="Następne" style={{ height: "16px", width: "16px" }} />
                </Button>
              </div>
              <div className={cx(styles.paginationPages, "d-flex align-items-center gap-1 mr-2")}>
                <div className="position-absolute">
                  <Spinner
                    overrides={{ boxSpinner: { className: styles.boxSpinner } }}
                    on={isFetching}
                    color="blue"
                    size="small"
                    position="absolute"
                    style={{ height: "0", top: 0, left: "-30px" }}
                  />
                </div>

                <span className="d-inline-flex">Wyniki</span>

                {!pageSize || !count ? (
                  "--"
                ) : (
                  <strong className="text-color-black">
                    {state * pageSize - pageSize + 1}-
                    {state * pageSize > count ? count : state * pageSize}
                  </strong>
                )}
                {count && <div className="body-12">/ {count}</div>}
              </div>
            </div>
          )}
        </StateProxy>
      </div>
    );
  },
);

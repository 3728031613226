import { wmsLayoutConstants } from "api/wms/layout/constants";
import { FieldDetails } from "api/wms/layout/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelHeader } from "components/utils/drawer";

interface Props {
  close: () => void;
  field: FieldDetails;
}

export const PanelHeader = ({ close, field }: Props) => {
  return (
    <RightPanelHeader>
      <div className="d-flex align-items-center gap-3">
        <Typography fontSize="18" fontWeight="700">
          {wmsLayoutConstants.fieldKindDict[field.wmsKind]} {field.name}
        </Typography>
        <div className="d-flex align-items-center gap-1">
          <Typography fontSize="14" fontWeight="600">
            Strefa:
          </Typography>
          <Typography fontSize="14" fontWeight="700">
            {field.area && Boolean(field.area.name) ? `${field.area.name}` : "---"}
          </Typography>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end flex-1">
        <Tooltip title="Pobierz etykietę">
          <IconButton icon={MdiQrCode} variant="transparent" />
        </Tooltip>
        <span className="divider line-divider" />
        <Tooltip title="Zamknij panel">
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </Tooltip>
      </div>
    </RightPanelHeader>
  );
};

import { WarehouseWorkerDetails } from "api/wms/models";
import { useFilters } from "hooks/useFilters";

type GroupBy = "PRODUCT_CATEGORY";

type WarehouseWorkerFilters = {
  userId: number;
  groupBy: GroupBy;
};

export const useWarehouseWorkerFilters = (warehouseWorker: WarehouseWorkerDetails) => {
  return useFilters<WarehouseWorkerFilters>({
    userId: warehouseWorker.id,
    groupBy: "PRODUCT_CATEGORY",
  });
};

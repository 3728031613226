import { useQuery } from "hooks";
import { getSearch } from "./utils/getSearch";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListBigUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { getTabs } from "./utils/getTabs";
import { useLongHeldPackagesColumns } from "./useLongHeldPackagesColumns";
import { longHeldPackages } from "components/common/moduleNavigation/moduleConfig/wms/routes/longHeldPackages";
import { uniquePackagesAction } from "api/unique-packages/actions";
import { RouteComponentProps } from "react-router";
import { LongHeldPackageTab } from "api/unique-packages/models";
import { useEffect } from "react";

export const LongHeldPackages = ({ match }: RouteComponentProps<{ tab: LongHeldPackageTab }>) => {
  const { tab } = match.params;
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const {
    data: loadings,
    error,
    isPreviousData,
    isLoading,
    isFetching,
    pagination,
    refetch,
  } = uniquePackagesAction.useLongHeldPackages(search);

  const columns = useLongHeldPackagesColumns(tab);

  useEffect(() => {
    refetch();
  }, [tab, refetch]);

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `wms/${longHeldPackages.url}`,
          urlSpan: "list",
        }}
        viewLabel="LONG_HELD_PACKAGES"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<any>
          rows={loadings}
          columns={columns}
          onPaginationChange={paginationState => {
            updateQuery({ ...query, page: paginationState.pageIndex });
          }}
          isLoading={isLoading || isFetching || isPreviousData}
          error={error}
          pagination={pagination}
          uiSchema={mainListBigUiSchema}
        />
      </div>
    </PageWrapper>
  );
};

import { Product } from "api/products/models";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useProductColumns = (
  isProductSelected: (id: string | number) => boolean,
  setProductSelectionStatus: (id: string | number) => void,
  areSomeProductsSelected: boolean,
  setAllProductsAsSelected: () => void,
  areAllSelected: boolean,
) => {
  return useCreateTableColumns<Product>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        id: "selectOrders",
        header: () => {
          return (
            <div className="d-flex align-items-end">
              <Checkbox
                checked={areAllSelected}
                indeterminate={areSomeProductsSelected}
                onChange={setAllProductsAsSelected}
              />
            </div>
          );
        },
        size: 25,
        cell: info => {
          const product = info.getValue();
          return (
            <Checkbox
              checked={isProductSelected(product.id)}
              onChange={() => setProductSelectionStatus(product.id)}
            />
          );
        },
      }),
      columnHelper.text(row => row.name, {
        header: "nazwa produktu",
        size: 300,
      }),
      columnHelper.text(row => (row.manufacturer ? row.manufacturer.name : null), {
        header: "producent",
        size: 130,
      }),
    ];
  });
};

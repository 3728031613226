import { Route } from "api/routes/models";
import { RightPanelSection } from "components/utils/drawer";
import { NotificationsPanel } from "./components/NotificationsPanel";
import styles from "../../RightPanel.module.css";
import { RoutePoints } from "./components/routePoints/RoutePoints";

interface Props {
  route: Route;
}

export const DeliverySection = ({ route }: Props) => {
  return (
    <RightPanelSection title="Miejsca dostawy" titleClassName={styles.notificationSectionTitle}>
      <NotificationsPanel route={route} />
      <RoutePoints route={route} />
    </RightPanelSection>
  );
};

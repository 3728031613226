import { Toastr } from "api/logistics/routes/models";
import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";
import { getAnyErrorKey } from "utilities";

export const handleCsvDownload = async (
  downloadFeedbackToastr: ReturnType<typeof useDownloadFeedbackToastr>,
  fileName: string,
  search: string,
  toastr: Toastr,
) => {
  if (!fileName) throw new Error("An error occurred");
  const tstr = downloadFeedbackToastr.open({ type: "csv", calculateProgress: false });
  const { url, name } = tradingDocumentFileFactory.whFinancesCsv(search, fileName);
  const response = await fileDownloader({
    url,
    name,
    type: "csv",
  });
  if (response.status === "success") {
    tstr.lazyClose();
  }
  if (response.status === "failure") {
    tstr.close();
    toastr.open({
      type: "warning",
      title: "Wymagane działanie",
      text: getAnyErrorKey(response.error),
    });
  }
};

import { Module } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import styles from "./Loader.module.css";
import cx from "classnames";
import { moduleConfig } from "components/common/moduleNavigation/moduleConfig";

interface Props {
  animationDuration?: string;
  className?: string;
  module: Module;
}

export const Loader = ({ animationDuration, className, module }: Props) => {
  const loaderBackground = moduleConfig[module]?.labelBackground;
  return (
    <div className={cx(styles.loaderBarWrapper, className)}>
      <div className={styles.loaderBar}>
        <div
          className={cx(styles.loaderBarFill, animationDuration)}
          style={{ background: loaderBackground }}
        />
      </div>
    </div>
  );
};

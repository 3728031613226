export function formatAttributes(
  attributes: string,
): { name: string; value: string; id: number | string }[] {
  const stringAttributes = attributes.split(";");
  return stringAttributes
    .filter(attribute => attribute.length > 0)
    .map(attribute => {
      const [name, value] = attribute.split(":");
      return { name, value, id: name + value };
    });
}

import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useEffect, useRef, useState } from "react";
import { useManufacturingPlan } from "api/manufacturingNew/hooks";
import { PanelHeader } from "./components/PanelHeader";
import { GeneralInfoSection } from "./components/GeneralInfoSection";
import { AssignedUsers } from "./components/AssignedUsers";
import { Days } from "./components/Days";
import { Transport } from "./components/Transport";
import { Orders } from "./components/Orders";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";

interface Props {
  close: () => void;
}

export const RightPanel = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const [isScrolledOver, setIsScrolledOver] = useState(false);
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const { data: manufacturingPlan, error, isLoading } = useManufacturingPlan(panelId);

  useEffect(() => {
    setIsScrolledOver(false);
  }, [panelId]);

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!manufacturingPlan) return null;

  return (
    <DrawerRightPanel key={manufacturingPlan.id}>
      <PanelHeader
        manufacturingPlan={manufacturingPlan}
        close={close}
        isScrolledOver={isScrolledOver}
      />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody
          myRef={clearCommentsRef}
          setIsScrolledOver={setIsScrolledOver}
          hasCommentsModule
        >
          <GeneralInfoSection manufacturingPlan={manufacturingPlan} />
          <AssignedUsers manufacturingPlan={manufacturingPlan} />
          <Days manufacturingPlan={manufacturingPlan} />
          <Transport manufacturingPlan={manufacturingPlan} />
          <Orders manufacturingPlan={manufacturingPlan} />
        </RightPanelBody>
        <CommentsGenericSection
          commentedObject={manufacturingPlan}
          paramName="stageManufacturingPlan"
          endpointUrl="/manufacturing/stage-production-plans/comments"
        />
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};

import { CarrierOrder } from "api/logistics/carrierOrders/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader } from "components/utils/drawer";
import { cx, queryString } from "utilities";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DOMAIN } from "ENDPOINTS";
import { MdiViewPdf } from "components/miloDesignSystem/atoms/icons/MdiViewPdf";

interface Props {
  carrierOrder: CarrierOrder;
  close: () => void;
  isScrolledOver: boolean;
}

export const PanelHeader = ({ carrierOrder, close, isScrolledOver }: Props) => {
  return (
    <>
      <RightPanelHeader
        className={cx({
          slidingPanelHeaderShadow: isScrolledOver,
        })}
      >
        {isScrolledOver && (
          <div className="slidingPanelHeader">
            <Typography className="slidingPanelHeaderContent" fontSize="16" fontWeight="700" noWrap>
              {carrierOrder.route.signature}
            </Typography>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <Tooltip title="Podgląd PDF ze zleceniem przewozu">
            <IconButton
              icon={<MdiViewPdf size="22" />}
              onClick={() => {
                window.open(
                  `${DOMAIN}carrier-orders/pdf${queryString.stringify({
                    routesIds: carrierOrder.route.id,
                  })}`,
                  "_blank",
                );
              }}
              variant="transparent"
            />
          </Tooltip>
          <span className="divider line-divider" />
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </RightPanelHeader>
    </>
  );
};

import { getProduct, getProductsLight } from "api/products/calls";
import { Product, ProductLight, ProductValue } from "api/products/models";
import { useToastr } from "hooks";
import React, { useState } from "react";
import { getAnyErrorKey } from "utilities";
import {
  Autocomplete,
  AutocompleteAsyncHandler,
  Spinner,
  StatusHandler,
  Switch,
} from "components/utils";
import { FormRadioLabels } from "components/utils/formRadioLabels";
import { isFabric } from "typePredicates";
import { Form } from "components/common/form/Form";
import { AttributeAccordion } from "components/utils/attributeAccordion/AttributeAccordion";
import { Button } from "components/common";
import { DrawerSection } from "components/utils/drawer";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "./ProductFilterModal.module.css";
import { PickAllAttributes } from "./PickAllAttributes";

interface Props {
  addAttributeValue: (productId: number, value: number) => void;
  addProduct: (product: Product) => void;
  editMode: boolean;
  product: Product | null;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setProduct: React.Dispatch<React.SetStateAction<Product | null>>;
  toggleAttributeValue: (productId: number, value: number) => void;
  toggleIsProductExcluded: (productId: number) => void;
  pickedProducts:
    | Record<number, { selectedValues: number[]; isProductExcluded: boolean; product: Product }>
    | undefined;
}

export const ProductForm = ({
  addAttributeValue,
  addProduct,
  editMode,
  product,
  setEditMode,
  setProduct,
  toggleAttributeValue,
  toggleIsProductExcluded,
  pickedProducts,
}: Props) => {
  const [inProgress, setInProgress] = useState(false);
  const toastr = useToastr();

  async function handleSearchProductOnChange(product: ProductLight) {
    if (product) {
      setEditMode(false);
      setProduct(null);
      setInProgress(true);
      const [payload, error] = await getProduct(product.id);
      if (payload) {
        setInProgress(false);
        setProduct(payload);
        addProduct(payload);
      } else if (error) {
        setInProgress(false);
        toastr.open({
          type: "failure",
          title: "Oj, coś nie tak.",
          text: getAnyErrorKey(error),
        });
      }
    }
  }

  const closeForm = () => {
    setEditMode(false);
    setProduct(null);
  };

  const areAllChecked = (productId: number, values: ProductValue[]) => {
    const selectedValues = pickedProducts?.[productId]?.selectedValues || [];
    return values.every(el => selectedValues.includes(el.id));
  };

  return (
    <>
      <div className={styles.searchProductContainer}>
        <AutocompleteAsyncHandler fetchFrom={getProductsLight}>
          <Autocomplete
            showSelectedItems={product && !editMode ? true : false}
            placeholder="Szukaj produktu"
            onChange={(products: ProductLight[]) => {
              if (products.length > 0) {
                handleSearchProductOnChange(products[0]);
              } else {
                assertIsDefined(product);
                closeForm();
              }
            }}
            multiple={false}
            overrides={{ input: { className: styles.searchProductInput } }}
          />
        </AutocompleteAsyncHandler>
      </div>
      {product && pickedProducts ? (
        <>
          <div className="mt-3">
            <Switch
              name="isProductExcluded"
              checked={pickedProducts[product.id]?.isProductExcluded}
              label={
                pickedProducts[product.id]?.isProductExcluded
                  ? "Produkt wykluczony"
                  : "Zawiera produkt"
              }
              onChange={() => {
                toggleIsProductExcluded(product.id);
              }}
            />
          </div>
          {product.attributes.length ? (
            <div className={styles.attributesHeader}>
              <span>Cechy indeksu</span>
            </div>
          ) : (
            <div className="mb-2" />
          )}
          <>
            {product.attributes.map(attribute => {
              if (attribute.kind === "PICTURE") {
                return (
                  <div className="d-flex" key={attribute.id} data-testid="attribute">
                    <FormRadioLabels label={attribute.name} multiple={true}>
                      <div>
                        <div className="mb-3">
                          <PickAllAttributes
                            addAttributeValue={addAttributeValue}
                            areAllChecked={areAllChecked(
                              product.id,
                              attribute.values.filter(el => el.isAssignableToIndex),
                            )}
                            productId={product.id}
                            toggleAttributeValue={toggleAttributeValue}
                            values={attribute.values.filter(el => el.isAssignableToIndex)}
                          />
                        </div>
                        {attribute.values
                          .filter(el => el.isAssignableToIndex)
                          .map(value => (
                            <FormRadioLabels.Label
                              key={value.id}
                              label={value.name}
                              name={`attribute-${attribute.id}`}
                              value={value.id}
                              checked={
                                pickedProducts![product.id]?.selectedValues.filter(
                                  el => el === value.id,
                                ).length > 0
                              }
                              onChange={({ value }) => {
                                toggleAttributeValue(product.id, value);
                              }}
                              type="image"
                              img={value.picture}
                              className="mb-1 mr-1"
                            />
                          ))}
                      </div>
                    </FormRadioLabels>
                  </div>
                );
              } else if (isFabric(attribute)) {
                return (
                  <div key={attribute.id} data-testid="attribute">
                    <hr className="hr" />
                    <Form.FieldsetLabel className="mt-3">{attribute.name}</Form.FieldsetLabel>
                    {attribute.categories
                      .filter(category => category.values.some(val => val.isAssignableToIndex))
                      .map(category => {
                        const hasSelectedValue = category.values.some(
                          val =>
                            val.id ===
                            pickedProducts![product.id]?.selectedValues.find(el => el === val.id),
                        );

                        return (
                          <div key={category.name}>
                            <div className="mt-3 mb-1">
                              <PickAllAttributes
                                addAttributeValue={addAttributeValue}
                                areAllChecked={areAllChecked(
                                  product.id,
                                  category.values.filter(el => el.isAssignableToIndex),
                                )}
                                productId={product.id}
                                toggleAttributeValue={toggleAttributeValue}
                                values={category.values.filter(el => el.isAssignableToIndex)}
                              />
                            </div>
                            <AttributeAccordion
                              label={category.name}
                              enabled={true}
                              isSelected={hasSelectedValue}
                            >
                              <div className="d-flex" key={attribute.id}>
                                <FormRadioLabels multiple={true}>
                                  {category.values
                                    .filter(el => el.isAssignableToIndex)
                                    .map(value => (
                                      <>
                                        <FormRadioLabels.Label
                                          key={value.id}
                                          label={value.name}
                                          name={`attribute-${attribute.id}`}
                                          value={value.id}
                                          checked={
                                            pickedProducts![product.id]?.selectedValues.filter(
                                              el => el === value.id,
                                            ).length > 0
                                          }
                                          onChange={({ value }) => {
                                            toggleAttributeValue(product.id, value);
                                          }}
                                          type="image"
                                          img={value.picture}
                                          className="mb-1 mr-1"
                                        />
                                      </>
                                    ))}
                                </FormRadioLabels>
                              </div>
                            </AttributeAccordion>
                          </div>
                        );
                      })}
                  </div>
                );
              }
              return (
                <div key={attribute.id} data-testid="attribute">
                  <FormRadioLabels label={attribute.name} multiple={true}>
                    <div>
                      <div className="mb-3">
                        <PickAllAttributes
                          addAttributeValue={addAttributeValue}
                          areAllChecked={areAllChecked(
                            product.id,
                            attribute.values.filter(el => el.isAssignableToIndex),
                          )}
                          productId={product.id}
                          toggleAttributeValue={toggleAttributeValue}
                          values={attribute.values.filter(el => el.isAssignableToIndex)}
                        />
                      </div>
                      {attribute.values
                        .filter(el => el.isAssignableToIndex)
                        .map(value => (
                          <FormRadioLabels.Label
                            key={value.id}
                            onChange={({ value }) => {
                              toggleAttributeValue(product.id, value);
                            }}
                            checked={
                              pickedProducts![product.id]?.selectedValues.filter(
                                el => el === value.id,
                              ).length > 0
                            }
                            label={value.name}
                            value={value.id}
                            name={`attribute-${attribute.id}`}
                          />
                        ))}
                    </div>
                  </FormRadioLabels>
                </div>
              );
            })}
          </>
          <div className="mt-1 mb-2 mr-1 d-flex justify-content-end align-items-center">
            <StatusHandler>
              {helpers => (
                <Button
                  kind="secondary-stroke"
                  size="small"
                  disabled={helpers.isFetching}
                  onClick={closeForm}
                  className={`${styles.filtersBtn}`}
                >
                  <div className="d-flex align-items-center">Zamknij</div>
                </Button>
              )}
            </StatusHandler>
          </div>
          <DrawerSection className={`mb-1 ${styles.noPadding}`} title=" "></DrawerSection>
        </>
      ) : (
        <Spinner on={inProgress} color="blue" size="small" />
      )}
    </>
  );
};

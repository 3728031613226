import { RouteTrackingDayPosition } from "api/call-center/routes/models";
import { StateLabel } from "components/common/stateLabel";
import { dateFns } from "utilities";
import styles from "../RouteTrackingModal.module.css";
import { compareDeliveryTimeWithPlannedDelivery } from "../utils/compareDeliveryTimeWithPlannedDelivery";

interface Props {
  position: RouteTrackingDayPosition;
}

export const RealDeliveryDate = ({ position }: Props) => {
  return (
    <div>
      {position.deliveredDate ? (
        <>
          {compareDeliveryTimeWithPlannedDelivery(
            position.deliveryTime.min,
            position.deliveryTime.max,
            position.deliveredDate,
          ) === "ON_TRACK" ? (
            <StateLabel className={styles.realDeliveryLabel} kind="darkGreen">
              <div>{dateFns.format(new Date(position.deliveredDate), "H:mm")}</div>
            </StateLabel>
          ) : compareDeliveryTimeWithPlannedDelivery(
              position.deliveryTime.min,
              position.deliveryTime.max,
              position.deliveredDate,
            ) === "AT_RISK" ? (
            <StateLabel className={styles.realDeliveryLabel} kind="darkYellowBlack">
              <div>{dateFns.format(new Date(position.deliveredDate), "H:mm")}</div>
            </StateLabel>
          ) : compareDeliveryTimeWithPlannedDelivery(
              position.deliveryTime.min,
              position.deliveryTime.max,
              position.deliveredDate,
            ) === "OFF_TRACK" ? (
            <StateLabel className={styles.realDeliveryLabel} kind="bgRed4">
              <div>{dateFns.format(new Date(position.deliveredDate), "H:mm")}</div>
            </StateLabel>
          ) : (
            <div>{dateFns.format(new Date(position.deliveredDate), "H:mm")}</div>
          )}
        </>
      ) : (
        <div>---</div>
      )}
    </div>
  );
};

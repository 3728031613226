import logoImg from "assets/images/logo1.svg";
import { useSelector } from "hooks";
import styles from "./AppLoader.module.css";

export const AppLoader = () => {
  const isAppLoading = useSelector(state => state.ui.isAppLoading);

  if (!isAppLoading) return null;
  return (
    <div className={styles.loaderWrapper}>
      <div className={styles.loaderContent}>
        <div className={styles.firmLogo}>
          <img className={styles.logoImage} src={logoImg} alt="logo" />
        </div>
        <div className={styles.loadingBar}>
          <div className={styles.bar}></div>
        </div>
        <div className={styles.loadingText}>
          <h4>Wczytywanie podstawowych danych</h4>
          <p>To potrwa tylko chwilę</p>
        </div>
      </div>
    </div>
  );
};

import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import chair from "assets/images/moduleNavigationIcons/chair.svg";
import { Dashboard } from "routes/reports/Dashboard";

export const products: ModuleLink = {
  url: "products",
  label: "Produkty",
  icon: chair,
  subSection: <></>,
  routing: Dashboard,
  isAccessible: false,
};

import { decamelizeObject } from "utilities/decamelize";
import { GRAPHHOPPER_KEY } from "CONSTANTS";

/**
 * Limit of waypoints that can be calculated by graphhopper / google maps Directions Service
 */
export const QUERY_LIMIT = 70;
// switching to google doesn't work yet. If you want to switch, please un-comment Directions component in Map component and remove the button for manual calculating.
export const DIRECTIONS_SERVICE: "graphhopper" | "google" = "graphhopper";

interface routeData {
  points: number[][];
  vehicle: Vehicle;
  includeLastPointInOptimization: boolean;
}

type Vehicle = "small_truck" | "car" | "truck";

export interface OptimizationOutput {
  distance: number;
  waypointsOrder: number[];
  waypointsMeta: { distance: number; duration: number }[];
  polyline: { lat: number; lng: number }[][];
  returnToStartingPoint: { distance: number; duration: number };
}

interface OptimizationInputPayload {
  configuration?: {
    routing?: {
      calcPoints?: boolean;
      considerTraffic?: boolean;
      snapPreventions?: ("motorway" | "trunk" | "bridge" | "ford" | "tunnel" | "ferry")[];
    };
  };
  objectives: {
    type: "min" | "min-max";
    value: "completion_time" | "vehicles" | "activities" | "transport_time";
  }[];
  services: { id: string; address: { locationId: string; lat: number; lon: number } }[];
  vehicle: {
    vehicleId: string;
    typeId: Vehicle;
    startAddress: {
      locationId: string;
      lat: number;
      lon: number;
    };
    return_to_depot: boolean;
    // earliest_start: 1598918400000,
    // latest_end: 1604016000000,
    // max_jobs: 3,
  };
}

const defaultConfig = {
  configuration: {
    routing: {
      calcPoints: true,
      considerTraffic: true,
    },
  },
  vehicleTypes: [
    {
      type_id: "small_truck",
      capacity: [1500],
      profile: "small_truck",
    },
    {
      type_id: "car",
      capacity: [1000],
      profile: "car",
    },
    {
      type_id: "truck",
      capacity: [14000],
      profile: "truck",
    },
  ],
  objectives: [
    {
      type: "min",
      value: "completion_time",
    },
  ],
};

function getOptimization(data: OptimizationInputPayload) {
  return new Promise<OptimizationOutput | void>(async (resolve, reject) => {
    fetch(`https://graphhopper.com/api/1/vrp?key=${GRAPHHOPPER_KEY}`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(
        decamelizeObject({
          configuration: {
            routing: {
              ...defaultConfig.configuration.routing,
              ...data.configuration?.routing,
              returnSnappedWaypoints:
                data.configuration?.routing?.snapPreventions &&
                data.configuration.routing.snapPreventions.length > 0,
            },
          },
          vehicles: [data.vehicle],
          vehicle_types: defaultConfig.vehicleTypes,
          objectives: data.objectives ?? defaultConfig.objectives,
          services: data.services,
        }),
      ),
    }).then(
      async res => {
        if (res.ok) {
          const responseData = await res.json();
          const activities = responseData.solution.routes[0].activities;
          const waypoints = data.vehicle.return_to_depot
            ? activities.slice(1, activities.length - 1)
            : activities.slice(1);
          const slices = activities.slice(1, activities.length);

          function getWaypointsMeta(wpSlices: { distance: number; driving_time: number }[]) {
            return wpSlices.map((waypoint, index) => ({
              // distances between waypoints are summarized, we need to split them
              distance: waypoint.distance - (wpSlices[index - 1]?.distance ?? 0),
              duration: waypoint.driving_time - (wpSlices[index - 1]?.driving_time ?? 0),
            }));
          }

          const returnToStartingPoint = calculateReturnToStartingPoint(activities);
          function calculateReturnToStartingPoint(_activities: any[]) {
            const prevLastSlice = _activities[_activities.length - 2];
            const lastSlice = _activities[_activities.length - 1];
            return {
              distance: lastSlice.distance - prevLastSlice.distance,
              duration: lastSlice.driving_time - prevLastSlice.driving_time,
            };
          }

          resolve({
            distance: responseData.solution.distance,
            waypointsOrder: waypoints.map((el: any) => el.location_id),
            waypointsMeta: getWaypointsMeta(slices),
            returnToStartingPoint,
            polyline: responseData.solution.routes[0].points.map((point: Point) => {
              if (point.type === "LineString") {
                return point.coordinates.map(([lng, lat]: [number, number]) => ({ lat, lng }));
              } else if (point.type === "Point") {
                return { lng: point.coordinates[0], lat: point.coordinates[1] };
              } else {
                throw new Error("Unexpected graphhopper point type");
              }
            }),
          });
        } else {
          resolve();
        }
      },
      err => {
        console.error(err);
        resolve();
      },
    );
  });
}
type Point = PointTypes["LineString"] | PointTypes["Point"];
type PointTypes = {
  LineString: {
    coordinates: [number, number][];
    type: "LineString";
  };
  Point: {
    coordinates: [number, number];
    type: "Point";
  };
};

interface RawRouteOutput {
  paths: {
    points: {
      coordinates: [number, number][];
    };
  }[];
}

interface RouteOutput {
  points: { distance: number; time: number }[];
  polyline: {
    lat: number;
    lng: number;
  }[];
  distance: number;
  time: number;
}

const utils = {
  pointToPolyline: (data: RawRouteOutput) =>
    data.paths[0].points.coordinates.map(([lng, lat]: [number, number]) => ({ lat, lng })),
};

function postRoute(data: routeData) {
  return new Promise<RouteOutput | void>(async (resolve, reject) => {
    fetch(`https://graphhopper.com/api/1/route?key=${GRAPHHOPPER_KEY}`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(
        decamelizeObject({
          ...data,
          points_encoded: false,
        }),
      ),
    }).then(
      async res => {
        if (res.ok) {
          const responseData = await res.json();
          const points = (() => {
            const instructions: any[] = responseData.paths[0].instructions;
            const orderPointsSum: { distance: number; time: number }[] = [];
            let _currentOrder = { distance: 0, time: 0 };

            // Our distance and time values base on driving instructions. We need to pull those data from there.
            instructions.forEach((el, index) => {
              if (el.text.includes("Waypoint") || index === instructions.length - 1) {
                const point = {
                  distance: Math.round(_currentOrder.distance),
                  time: Math.round(_currentOrder.time),
                };
                orderPointsSum.push(point);
                _currentOrder = { distance: 0, time: 0 };
              } else {
                _currentOrder.distance += el.distance;
                _currentOrder.time += el.time / 1000;
              }
            });
            return orderPointsSum;
          })();

          return resolve({
            polyline: utils.pointToPolyline(responseData),
            points: data.includeLastPointInOptimization
              ? points
              : [...points, { distance: 0, time: 0 }],
            distance: Math.round(responseData.paths[0].distance),
            time: Math.round(responseData.paths[0].time / 1000),
          });
        } else {
          resolve();
        }
      },
      err => {
        console.error(err);
        resolve();
      },
    );
  });
}

export const graphhopper = {
  optimize: getOptimization,
  route: postRoute,
};

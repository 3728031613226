import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody, RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useEffect, useRef, useState } from "react";
import { useCallCenterRouteOrder } from "api/call-center/routes/hooks";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { MessagesWithCustomer } from "pages/callCenter/shared/messagesWithCustomer/MessagesWithCustomer";
import { PanelHeader } from "pages/callCenter/shared/PanelHeader";
import { GeneralInfoSection } from "pages/callCenter/shared/GeneralInfoSection";
import { OrderSignature } from "pages/callCenter/shared/OrderSignature";
import { InfoLabel } from "components/common/infoLabel";
import { LinkToPage } from "components/utils/linkToPage";

interface Props {
  close: () => void;
}

export const RightPanel = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const [isScrolledOver, setIsScrolledOver] = useState(false);
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const { data: routeOrder, error, isLoading } = useCallCenterRouteOrder(Number(panelId), {
    enabled: Boolean(panelId),
  });

  useEffect(() => {
    setIsScrolledOver(false);
  }, [panelId]);

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!routeOrder) return null;

  return (
    <DrawerRightPanel key={routeOrder.id}>
      <PanelHeader
        routeOrder={routeOrder}
        panelId={panelId}
        close={close}
        isScrolledOver={isScrolledOver}
      />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody
          myRef={clearCommentsRef}
          setIsScrolledOver={setIsScrolledOver}
          hasCommentsModule
        >
          <RightPanelSection>
            <div className="d-flex align-items-center justify-content-between">
              <div className="w-100">
                <OrderSignature routeOrder={routeOrder} />
                <InfoLabel title="trasa">
                  {routeOrder.route ? (
                    <LinkToPage
                      content={<>{routeOrder.route.signature}</>}
                      stopPropagation
                      url={`/call-center/route/${routeOrder.route.id}/orders`}
                      overrides={{
                        link: { className: "fs-14" },
                      }}
                    />
                  ) : (
                    "---"
                  )}
                </InfoLabel>
                <GeneralInfoSection routeOrder={routeOrder} />
              </div>
            </div>
          </RightPanelSection>
          <hr />
          <MessagesWithCustomer />
          <hr />
        </RightPanelBody>
        <CommentsGenericSection
          outsideRef={clearCommentsRef}
          commentedObject={{ ...routeOrder, id: String(routeOrder.id) }}
          paramName="order"
          endpointUrl="/orders/comments"
        />
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};

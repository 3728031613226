import { createColumnHelper, ColumnDef, ColumnHelper } from "@tanstack/react-table";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { PropsWithChildren, useMemo } from "react";
import { textColumn } from "./columns/textColumn";
import { dateColumn } from "./columns/dateColumn";
import { numberColumn } from "./columns/numberColumn";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { accessorColumn } from "./columns/accessorColumn";

interface Options {
  shouldDisplayIndexColumn?: boolean;
}

interface EmptyValueProps {
  className?: string;
  color?: ColorPalette;
  fontSize?: TypographyProps["fontSize"];
  fontWeight?: TypographyProps["fontWeight"];
}

export type ColumnHelperArgs<T> = {
  accessor: typeof accessorColumn;
  display: ColumnHelper<T>["display"];
  group: ColumnHelper<T>["group"];
  text: typeof textColumn;
  date: typeof dateColumn;
  number: typeof numberColumn;
};

export function useCreateTableColumns<T>(
  getColumns: (args: { columnHelper: ColumnHelperArgs<T> }) => ColumnDef<T>[],
  options?: Options,
) {
  return useMemo(() => {
    const baseColumnHelper = createColumnHelper<T>();
    const columns = getColumns({
      columnHelper: {
        ...baseColumnHelper,
        accessor: baseColumnHelper.accessor as typeof accessorColumn,
        text: textColumn,
        date: dateColumn,
        number: numberColumn,
      },
    });
    if (options?.shouldDisplayIndexColumn) {
      return [
        baseColumnHelper.display({
          header: "#",
          size: 20,
          cell: props => (
            <BaseTableTypography>
              {props.row.depth ? "" : `${props.row.index + 1}.`}
            </BaseTableTypography>
          ),
        }),
        ...columns,
      ];
    }

    return columns;
  }, [getColumns, options?.shouldDisplayIndexColumn]);
}

const BaseTableTypography = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Typography fontSize="12" fontWeight="700">
      {children}
    </Typography>
  );
};

export const EmptyValue = ({
  className,
  color = "neutralBlack100",
  fontSize = "12",
  fontWeight = "600",
}: EmptyValueProps) => (
  <Typography className={className} color={color} fontSize={fontSize} fontWeight={fontWeight}>
    {TABLE_EMPTY_VALUE}
  </Typography>
);
export const TABLE_EMPTY_VALUE = "---";

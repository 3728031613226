import { Header } from "./components/Header";
import { OverviewReleasedAndReceived } from "./components/OverviewReleasedAndReceived";
import { OverviewReceivedByProductCategory } from "./components/OverviewReceivedByProductCategory";
import { OverviewReleasedForCustomer } from "./components/OverviewReleasedForCustomer";
import { OverviewReleasedByProductCategory } from "./components/OverviewReleasedByProductCategory";
import { OverviewReceivedForCustomer } from "./components/OverviewReceivedForCustomer";

export const Overview = () => {
  return (
    <div className="position-relative overflow-auto h-100">
      <Header />
      <div className="d-flex gap-3 flex-wrap py-2 px-3">
        
        <OverviewReleasedAndReceived />
        <OverviewReceivedByProductCategory />
        <OverviewReleasedByProductCategory />
      </div>
      <div className="d-flex gap-3 flex-wrap py-2 px-3">
        <OverviewReceivedForCustomer />
        <OverviewReleasedForCustomer />
      </div>
    </div>
  );
};

import { createPaginatedKey } from "api/keys";

export const tradingDocumentsKeys = {
  tradingDocument: {
    list: createPaginatedKey("tradingDocuments"),
    details: (id: string) => ["tradingDocument", id],
  },
  lightTradingDocument: {
    list: createPaginatedKey("lightTradingDocument"),
  },
  purchaseInvoicesToReview: {
    list: createPaginatedKey("purchaseInvoicesToReview"),
    myInvoices: createPaginatedKey("myPurchaseInvoicesToReview"),
  },
  invoiceAdmission: {
    details: (id: string) => ["invoiceAdmission", id],
    linkLines: createPaginatedKey("linkLines"),
    whEntryAggregates: createPaginatedKey("whEntryAggregates"),
    assignedWhEntries: createPaginatedKey("assignedWhEntries"),
  },
  printedReceipts: {
    currentlyPrinted: createPaginatedKey("currentlyPrintedReceipts"),
    lastPrinted: createPaginatedKey("lastPrintedReceipts"),
  },
  warehouseFinancialState: {
    warehouseTotalValueSummary: (search: string) => ["warehouseTotalValueSummary", search],
    packagesInOrOutSummary: createPaginatedKey("packagesInOrOutSummary"),
    summaryPerInPackage: (id: string) => ["summaryPerInPackage", id],
    summaryPerOutPackage: (id: string) => ["summaryPerOutPackage", id],
  },
  financesAmountSummary: {
    details: (search: string) => ["financesAmountSummary", search],
  },
};
